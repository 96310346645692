import React from 'react';

import './index.scss';
import { Icon } from '../../../../hapyak-ui-toolkit';
import { stopEvent, toSentenceCase } from '../../../../services/utils';

const axes = ['Y', 'X'];
const dimensions = ['height', 'width'];
const cursors = ['row-resize', 'col-resize'];
const directions = ['top', 'left'];
const secondaryDirections = ['right', 'bottom'];
const paddings = ['paddingTop', 'paddingLeft'];

type State = any;

type ExpandableContainerSectionProps = {
  draggable: boolean;
  first: boolean;
  handle: any;
  handleSize: () => void;
  isMax: boolean;
  last: any;
  min: number;
  nested: any;
  onChange: (e: any) => void;
  onDragStart: (e: any) => void;
  position: string;
  simple: boolean;
  size: string;
  section: any;
  toggleExpand: () => void;
  vertical: boolean;
};

export class ExpandableContainerSection extends React.Component<ExpandableContainerSectionProps, State> {
  ghostHandleRef: any;
  handleRef: any;
  handleSize: any;
  type = this.props.vertical ? 0 : 1;
  cursor = cursors[this.type];
  primaryDimension = dimensions[this.type];
  secondaryDimension = dimensions[(this.type + 1) % 2];
  axis = axes[this.type];
  clientAxis = `client${this.axis}`;
  layerAxis = `layer${this.axis}`;
  direction = directions[this.type];
  oppositeDirection = directions[(this.type + 1) % 2];
  secondaryDirection = secondaryDirections[this.type];
  clientDim = `client${toSentenceCase(this.secondaryDimension)}`;
  offsetDir = `offset${toSentenceCase(this.oppositeDirection)}`;
  padding = paddings[this.type];

  constructor (props: ExpandableContainerSectionProps) {
    super(props);
    this.handleSize = props.handleSize;
    this.state = { dragStarted: false, isDragging: false, positionOffset: 0, position: 0, positionChanged: false };
    this.handleRef = React.createRef();
    this.ghostHandleRef = React.createRef();
  }

  get notDraggable () {
    const { draggable, first } = this.props;
    return !draggable || first;
  }

  get style () {
    const { position, simple, size } = this.props;

    return {
      [this.padding]: simple && this.notDraggable ? '' : this.handleSize + 'px',
      [this.primaryDimension]: size + '%',
      [this.direction]: position + '%',
      [this.secondaryDimension]: '100%',
    };
  }

  get handleStyle () {
    return {
      [this.primaryDimension]: this.handleSize + 'px',
      [this.secondaryDimension]: '100%',
      [this.direction]: 0,
      [this.secondaryDirection]: 0,
      cursor: this.notDraggable ? '' : this.cursor,
    };
  }

  get ghostHandleStyle (): React.CSSProperties {
    const { section } = this.props;
    const { positionChanged } = this.state;
    const parent = this.handleRef.current.parentNode;
    const dim = this.secondaryDimension;

    return {
      [dim]: parent[this.clientDim] + 'px',
      [this.secondaryDirection]: 'auto',
      [this.oppositeDirection]: (section.offset || 0) + parent[this.offsetDir] + 'px',
      opacity: 0.7,
      display: positionChanged ? 'block' : 'none',
      position: 'fixed',
    };
  }

  onMouseDown = (e: any) => {
    const { onDragStart } = this.props;
    if (this.notDraggable) return;
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
    // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
    document.body.setAttribute('data-dragging', true);
    const positionOffset = e.nativeEvent[this.layerAxis];
    this.setState({ dragStarted: true, positionOffset, positionChanged: false });

    onDragStart(e[this.clientAxis]);
  };

  onMouseMove = (e: any) => {
    const ghost = this.ghostHandleRef.current;
    const { positionOffset } = this.state;

    this.setState({ positionChanged: true });

    if (ghost) {
      const pos = e[this.clientAxis] - positionOffset;
      ghost.style[this.direction] = pos + 'px';
    }
  };

  onMouseUp = (e: any) => {
    if (this.notDraggable) return;
    if (!this.state.dragStarted) return;
    const { onChange } = this.props;
    onChange(e[this.clientAxis]);

    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
    document.body.removeAttribute('data-dragging');
    this.setState({ dragStarted: false, isDragging: false });
    window.dispatchEvent(new Event('resize')); // trigger resize
  };

  stopPropagation = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  get ghostHandle () {
    return this.getHandle(this.ghostHandleRef, this.ghostHandleStyle);
  }

  getHandle = (ref: any, style: React.CSSProperties = {}) => {
    const { isMax, simple, handle } = this.props;

    if (simple && this.notDraggable) return null;

    const className = `hy-expandable-container-handle ${isMax ? 'hy-expandable-container-handle-max' : ''}`;

    return (
      <div
        ref={ref}
        className={className}
        onMouseDown={this.onMouseDown}
        onDoubleClick={this.toggleExpand}
        style={{ ...this.handleStyle, ...style }}
      >
        {handle || this.handleLines}
      </div>
    );
  };

  get handleLines () {
    return (
      <div className='hy-expandable-container-handle-lines hy-center'>
        <div />
        <div />
        <div />
      </div>
    );
  }

  toggleExpand = (e: any) => {
    stopEvent(e);
    const { isMax, toggleExpand } = this.props;
    if (isMax) return;
    toggleExpand();
  };

  get collapsedOverlayStyle () {
    const { first } = this.props;

    return {
      [this.direction]: first ? 0 : this.handleSize + 'px',
    };
  }

  get collapsedOverlay () {
    const { first, min, simple, size, toggleExpand } = this.props;
    const overflow = simple && +size <= min + 1;
    if (!overflow) return null;

    return (
      <div
        className='hy-absolute hy-expandable-container-section-collapsed-overlay'
        onClick={toggleExpand}
        style={this.collapsedOverlayStyle}
      >
        <Icon fitted size='big' name={first ? 'angle double right' : 'angle double left'} />
      </div>
    );
  }

  render () {
    const { section } = this.props;
    const { dragStarted } = this.state;

    return (
      <div className='hy-expandable-container-section' style={this.style}>
        {section.render()}
        {this.collapsedOverlay}
        {this.getHandle(this.handleRef)}
        {dragStarted && this.ghostHandle}
      </div>
    );
  }
}
