import _ from 'lodash';

import { ContentBelow } from './ContentBelow';
import { NewselaTemplate } from './NewselaTemplate';
import { PlayerWithOverlays } from './PlayerWithOverlays';
import { SideMenu } from './SideMenu';
import { VerticalVideo } from './VerticalVideo';
import { VideoOnly } from './VideoOnly';

// source of truth for template ids:
const _templateMap = {
  1: NewselaTemplate,
  2: PlayerWithOverlays,
  3: SideMenu,
  4: ContentBelow,
  5: VerticalVideo,
  6: VideoOnly,
};

// with ids attached to layout object:
export const templateMap = _.forEach(_templateMap, (template, id) => {
  (template as any).id = +id;
  (template.preview as any).id = +id;
});
