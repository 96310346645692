import './index.scss';

import React, { Component } from 'react';

import _ from 'lodash';

import { stopEvent } from '../../../services/utils';
import { TextInputProps } from '../../../types/layout/forms';
import { Form, Input } from '../../index';

type State = any;

type CustomInputProps = TextInputProps & {
  disabled: boolean;
  forceFocus: boolean;
  forceResetValue: boolean;
  inline: boolean;
  outline: boolean;
  startDisabled: boolean;
  width: string;
};

export class CustomInput extends Component<CustomInputProps, State> {
  input: any;
  constructor (props: CustomInputProps) {
    super(props);
    this.input = React.createRef();
    this.state = { value: props.value };
  }

  componentDidUpdate (prevProps: CustomInputProps) {
    // reset value from outside of component
    if (prevProps.forceResetValue !== this.props.forceResetValue) {
      this.setState({ value: this.props.value });
    }

    if (prevProps.forceFocus !== this.props.forceFocus) {
      // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
      this.focus();
    }
  }

  get formStyle () {
    const { inline, width = '' } = this.props;
    return { width, display: inline ? 'inline-block' : '' };
  }

  get isActiveElement () {
    const input = _.get(this.input, 'current');
    return document.activeElement === input;
  }

  focus = (e: any) => {
    if (!this.isActiveElement) {
      const input = this.input.current;
      input.focus();
      input.select();
    }
  };

  onChange = (e: any) => {
    this.setState({ value: e.target.value });
  };

  onBlur = () => {
    const { onBlur } = this.props;
    const { value } = this.state;
    if (typeof onBlur === 'function') onBlur(value);
  };

  render () {
    const { disabled, inline, placeholder, outline, startDisabled } = this.props;
    const { value } = this.state;

    return (
      <Form style={this.formStyle} className='hy-input-form'>
        <Input
          style={{ opacity: 1 }}
          ref={this.input}
          type='text'
          className={`hy-input ${outline ? 'hy-outline' : ''} ${inline ? 'hy-inline' : ''}`}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onDoubleClick={stopEvent}
          onMouseLeave={stopEvent}
          placeholder={placeholder}
          value={value}
          disabled={startDisabled && disabled}
        />
        {!this.isActiveElement && startDisabled && (
          <div
            onClick={this.focus}
            onDoubleClick={stopEvent}
            onMouseLeave={stopEvent}
            style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}
          />
        )}
      </Form>
    );
  }
}
