import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';

import { JobError } from 'errors/JobError';

import DEFAULTS from '../../DEFAULTS.json';
import { comm } from '../comm';
import logger from '../logger';
import sessionClient from '../sessionClient';
import signingService from '../signingService';
import { stateController } from '../stateController';
import { resolvePlatformHost } from '../utils';

const RECONNECT_TIME = 10000; // ms
const { SUCCESS_STATUS, FAILURE_STATUS } = DEFAULTS;

export const COLLECTION_ITEM_TYPES = {
  ANNOTATION: 'annotation',
  BUNDLE: 'bundle',
};

const getMetaProps = () => {
  const { project } = stateController.getCurrentData();

  return {
    projectId: project.id,
    groupId: project.groupId,
    userId: sessionClient.getCurrentUser(),
  };
};

const formatRequest = (token: any, url: any, method = 'get', data = null) => {
  return {
    url,
    method,
    withCredentials: true,
    headers: { Authorization: `Bearer ${token.token}` },
    data,
  };
};

const handleValidationError = (err: any) => {
  const { error, validationError } = _.get(err, 'response.data', {});

  if (error && validationError) {
    let message;

    try {
      message = JSON.parse(error); // contains ajv error data in non-prod envs
    } catch (jsonError) {
      message = error;
    }

    logger.error('Validation Error: ', message);
  }

  comm.trigger('showToast', 'Some content may not have saved properly');

  throw err;
};

export const getProject = (projectId: any) => {
  return sessionClient.getServiceAccessToken({ projectId }).then((token) => {
    const url = `${resolvePlatformHost()}/back-end/storage/projects/${projectId}`;
    return axios(formatRequest(token, url))
      .then((result) => {
        return _.get(result, 'data.body.properties', {});
      })
      .catch((error: Error) => {
        logger.warn('getProject ERROR:', error);
        return null;
      });
  });
};

export const getProjectSummary = async (projectId: any) => {
  const token = await sessionClient.getServiceAccessToken({ projectId });
  const url = `${resolvePlatformHost()}/back-end/storage/projects/${projectId}/summary`;
  const result = await axios(formatRequest(token, url));
  return _.get(result, 'data.body.properties', null);
};

export const fetchGroupContent = () => {
  return sessionClient.getServiceAccessToken().then((token) => {
    const url = `${resolvePlatformHost()}/back-end/storage/folders?recursive=true&projects=true`;
    return axios(formatRequest(token, url))
      .then((result) => {
        return _.get(result, 'data.body.items', []);
      })
      .catch((error: Error) => {
        logger.warn('listProjects ERROR:', error);
        return [];
      });
  });
};

export const listProjects = () => {
  return sessionClient.getServiceAccessToken().then((token) => {
    const url = `${resolvePlatformHost()}/back-end/storage/projects?limit=3`;
    return axios(formatRequest(token, url))
      .then((result) => {
        return _.get(result, 'data.body.projects', []);
      })
      .catch((error: Error) => {
        logger.warn('listProjects ERROR:', error);
        return [];
      });
  });
};

export const listPublishedProjects = (id = '') => {
  return sessionClient.getServiceAccessToken().then((token) => {
    const url = `${resolvePlatformHost()}/back-end/storage/published-project-id?reportingId=${id}`;
    return axios(formatRequest(token, url))
      .then((result) => {
        return _.get(result, 'data.items', []);
      })
      .catch((error: Error) => {
        logger.warn('listProjects ERROR:', error);
        return [];
      });
  });
};

export const getUploadAuthorization = async ({
  key
}: any) => {
  const token = await sessionClient.getServiceAccessToken();

  const endpoint = '/back-end/upload/get-authorized-destination';

  const url = `${resolvePlatformHost()}${endpoint}?key=${key}`;
  const result = await axios(formatRequest(token, url));

  return _.get(result, 'data.body.signature');
};

export const createProject = (data: any, overwrite: any) => {
  const projectId = _.get(data, 'project.id');
  return sessionClient.getServiceAccessToken({ projectId }).then((token) => {
    const url = `${resolvePlatformHost()}/back-end/storage/projects`;
    // @ts-expect-error TS(2345): Argument of type '{ properties: any; overwrite: an... Remove this comment to see the full error message
    return axios(formatRequest(token, url, 'post', { properties: data, overwrite }))
      .catch(handleValidationError)
      .then((result) => {
        return _.get(result, 'data.body.properties', {});
      });
  });
};

export const putProject = (data: any, projectId: any, merge: any) => {
  projectId = projectId || _.get(data, 'project.id');

  return sessionClient.getServiceAccessToken({ projectId }).then((token) => {
    const url = `${resolvePlatformHost()}/back-end/storage/projects/${projectId}`;
    // @ts-expect-error TS(2345): Argument of type '{ merge: any; properties: any; }... Remove this comment to see the full error message
    return axios(formatRequest(token, url, 'put', { merge, properties: data }))
      .catch(handleValidationError)
      .then((result) => {
        return _.get(result, 'data.body.properties', {});
      });
  });
};

export const deleteProject = (projectId: any) => {
  return sessionClient.getServiceAccessToken().then((token) => {
    const url = `${resolvePlatformHost()}/back-end/storage/projects/${projectId}`;
    return axios(formatRequest(token, url, 'delete'))
      .then((result) => {
        return _.get(result, 'data.body', {});
      })
      .catch((error: Error) => {
        console.error(`ERR.platformService.deleteProject: ${error.message}`);
        return {};
      });
  });
};

export const createFolder = (data: any) => {
  return sessionClient.getServiceAccessToken().then((token) => {
    const url = `${resolvePlatformHost()}/back-end/storage/folders`;
    // @ts-expect-error TS(2345): Argument of type '{ properties: any; }' is not ass... Remove this comment to see the full error message
    return axios(formatRequest(token, url, 'post', { properties: data })).then((result) => {
      return _.get(result, 'data.body.properties', {});
    });
  });
};

export const putFolder = (data: any) => {
  return sessionClient.getServiceAccessToken().then((token) => {
    const url = `${resolvePlatformHost()}/back-end/storage/folders/${data.id}`;
    // @ts-expect-error TS(2345): Argument of type '{ properties: any; }' is not ass... Remove this comment to see the full error message
    return axios(formatRequest(token, url, 'put', { properties: data })).then((result) => {
      return _.get(result, 'data.body.properties', {});
    });
  });
};

export const deleteFolder = (id: any) => {
  return sessionClient.getServiceAccessToken().then((token) => {
    const url = `${resolvePlatformHost()}/back-end/storage/folders/${id}`;
    return axios(formatRequest(token, url, 'delete')).then((result) => {
      return _.get(result, 'data.body', {});
    });
  });
};

export const getCurrentBundle = async () => {
  const { projectId } = getMetaProps();

  const token = await sessionClient.getServiceAccessToken();
  const url = `${resolvePlatformHost()}/back-end/storage/projects/${projectId}/bundle/current`;
  return axios(formatRequest(token, url));
};

export const createCollectionItem = async (type: any, properties = {}) => {
  const meta = getMetaProps();
  const { projectId } = meta;
  properties = { ...meta, ...properties };

  const token = await sessionClient.getServiceAccessToken();
  const url = `${resolvePlatformHost()}/back-end/storage/projects/${projectId}/${type}`;
  // @ts-expect-error TS(2345): Argument of type '{ properties: {}; }' is not assi... Remove this comment to see the full error message
  return axios(formatRequest(token, url, 'post', { properties })).catch(handleValidationError);
};

export const putCollectionItem = async (type: any, properties = {} as any) => {
  const meta = getMetaProps();
  const { projectId } = meta;
  const { id } = properties;
  properties = { ...meta, type, ...properties };

  const token = await sessionClient.getServiceAccessToken();
  const url = `${resolvePlatformHost()}/back-end/storage/projects/${projectId}/${type}/${id}`;
  return axios(formatRequest(token, url, 'put', { properties } as any)).catch(handleValidationError);
};

export const deleteCollectionItem = async (type: any, id: any) => {
  const { projectId } = getMetaProps();

  const token = await sessionClient.getServiceAccessToken();
  const url = `${resolvePlatformHost()}/back-end/storage/projects/${projectId}/${type}/${id}`;
  return axios(formatRequest(token, url, 'delete'));
};

export const getSchemaDefaults = async () => {
  // returns: { schema: 'schema_n', defaults: <DEFAULT_PROJECT_JSON_OR_ACTUAL_JSON_SCHEMA> }
  // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
  const token = await sessionClient.getGroupServiceAccessToken();
  const url = `${resolvePlatformHost()}/back-end/validation/project`;
  const result = await axios(formatRequest(token, url, 'get'));
  return _.get(result, 'data.body.defaults');
};

export const validateAndMigrate = async (data: any) => {
  const token = await sessionClient.getGroupServiceAccessToken({ projectId: _.get(data, 'project.id') });
  const url = `${resolvePlatformHost()}/back-end/validation/project`;

  try {
    // @ts-expect-error TS(2345): Argument of type '{ properties: any; }' is not ass... Remove this comment to see the full error message
    const result = await axios(formatRequest(token, url, 'post', { properties: data }));
    return _.get(result, 'data.body.properties', {});
  } catch (e) {
    return null;
  }
};

export const getReportingIds = async () => {
  try {
    const token = await sessionClient.getServiceAccessToken();
    const url = `${resolvePlatformHost()}/back-end/storage/reporting-id`;
    const response = await axios(formatRequest(token, url));
    return _.get(response, 'data.items', []);
  } catch (error) {
    logger.log('Error fetching reportingId list error:', error);
    return null;
  }
};

export const getReportingId = async (id = null) => {
  try {
    const token = await sessionClient.getServiceAccessToken();
    const url = `${resolvePlatformHost()}/back-end/storage/reporting-id/${id}`;
    const response = await axios(formatRequest(token, url));
    return _.get(response, 'data.items', []);
  } catch (error) {
    logger.log('Error fetching reportingId', error);
    return null;
  }
};

export const getFilteredReports = async ({ reportingId = '', reportType = '', filters = {} }) => {
  try {
    const token = await sessionClient.getServiceAccessToken();
    const url = `${resolvePlatformHost()}/back-end/storage/report-results/search`;
    const data = { reportingId, reportType, filters };
    // @ts-expect-error TS(2345): Argument of type '{ data: { reportingId: string; r... Remove this comment to see the full error message
    const response = await axios(formatRequest(token, url, 'post', { data }));
    return _.get(response, 'data.items', []);
  } catch (error) {
    logger.log('Error fetching filtered report list : ', error);
    return null;
  }
};

export const generateReport = async ({
  reportingId = '',
  reportType,
  reportAccessUrl = '',
  start = '',
  end = '',
  projectIds = [],
  respondentIds = []
}: any) => {
  try {
    const token = await sessionClient.getServiceAccessToken();
    const url = `${resolvePlatformHost()}/back-end/jobs`;

    const data = {
      command: 'jobType:StepFunction:GenerateReport',
      data: {
        reportingId,
        reportType,
        reportAccessUrl,
        filters: {
          projectIds,
          respondentIds,
          start,
          end,
        },
      },
    };

    // @ts-expect-error TS(2345): Argument of type '{ command: string; data: { repor... Remove this comment to see the full error message
    return await axios(formatRequest(token, url, 'post', data));
  } catch (error) {
    logger.log('Error fetching filtered report list : ', error);
  }
};

export const openGoogleDrive = async ({
  id
}: any): Promise<AxiosResponse> => {
  const token = await sessionClient.getServiceAccessToken();
  const url = `${resolvePlatformHost()}/back-end/googledrive/open?id=${id}`;
  return axios(formatRequest(token, url));
};

export const publishGoogleDrive = async ({
  location,
  name,
  thumbnail
}: any) => {
  const { token } = await sessionClient.getServiceAccessToken();
  const { data } = await axios({
    url: `${resolvePlatformHost()}/back-end/googledrive/publish`,
    method: 'post',
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { location, name, thumbnail },
  });

  return data;
};

export const viewGoogleDrive = async ({
  id,
  code
}: any): Promise<AxiosResponse> => {
  // it's intentional that we don't send authorization with this request as the user may not have a HapYak login
  // we perform verification on Platform to ensure the user is authorized to view the published project
  return axios({
    url: `${resolvePlatformHost()}/front-end/googledrive/view?id=${id}&code=${code}`,
    method: 'get',
    withCredentials: true,
  });
};

export const installGoogleDrive = async ({
  code
}: any) => {
  const { hostname, pathname } = document.location;
  const callbackURL = `https://${hostname}${pathname}`;
  const { data } = await axios({
    url: `${resolvePlatformHost()}/front-end/googledrive/install`,
    method: 'get',
    withCredentials: true,
    params: {
      callbackURL,
      code,
    },
  });

  return data;
};

function errorDidOccur (responseData = {} as any) {
  const { error, status } = responseData;
  return error || status === FAILURE_STATUS;
}

async function handleError (processName: any, responseData: any) {
  await resetProcess(processName);
  comm.trigger('processFailed', processName, responseData);
}

// Processes: Localize
export const startProcess = async (process: any, payload: any, transaction = {}) => {
  logger.log(`${process} payload: `, payload);
  const platformHost = resolvePlatformHost();
  const token = await sessionClient.getServiceAccessToken();

  if (process === DEFAULTS.TRANSCODE_REMOTE_UPLOAD_KEY) {
    await stateController.updateProject('processes', { [process]: payload.videoUrl });
  }
  comm.trigger('processInitiated', process);

  return new Promise(async (resolve, reject) => {
    const url = `${platformHost}/back-end/jobs`;
    const response = await axios(
      // @ts-expect-error TS(2345): Argument of type '{ command: string; data: any; tr... Remove this comment to see the full error message
      formatRequest(token, url, 'post', {
        command: `jobType:StepFunction:${process}`,
        data: payload,
        transaction,
      })
    );

    if (response.status === 200 && !errorDidOccur(response.data)) {
      const statusUrl = _.get(response, 'data.statusUrl');
      await stateController.updateProject('processes', { [process]: statusUrl });
      comm.trigger('processStarted', process);
      resolve(response.data);
    } else {
      await handleError(process, response.data);
      reject(response);
    }
  });
};

export const pollForProcessResults = async (process: any) => {
  return axios
    .get(signingService.sign(getProcessResultsUrl(process)))
    .then(async ({ data = null }) => {
      logger.log(`Polling for ${process}:`, data);

      if (data && data.status === SUCCESS_STATUS) {
        await resetProcess(process);
        comm.trigger('processComplete', process, data);
        return data.data;
      } else if (!data || errorDidOccur(data)) {
        await handleError(process, data);
        return { error: data.error || `Unknown error occurred during ${process}` };
      } else {
        comm.trigger('processUpdate', process, data);
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          // call base class method "reconnect" which contains the 'prevent' logic
          resolve(pollForProcessResults(process));
        }, RECONNECT_TIME);
      });
    })
    .catch((error: JobError) => {
      logger.error('ERR.pollForProcessResults: Could not fetch results.json', error);
    });
};

export const getProcessResultsUrl = (process: any) => {
  const processes = stateController.getCurrentData('processes') || {};
  return processes[process];
};

export const resetProcess = (process: any) => {
  return stateController.updateProject('processes', { [process]: null });
};

export const cancelLocalization = () => {
  resetProcess(DEFAULTS.LOCALIZE_PLATFORM_KEY);
  comm.trigger('processCanceled', DEFAULTS.LOCALIZE_PLATFORM_KEY);
};

export const isLocalizationActive = () => getProcessResultsUrl(DEFAULTS.LOCALIZE_PLATFORM_KEY);

export const getPlatformProcesses = () => stateController.getCurrentData('processes') || {};

export const isTranscodeActive = () => getProcessResultsUrl(DEFAULTS.TRANSCODE_KEY);
