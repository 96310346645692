const S3_BUCKET_SAMPLE_ASSETS = process.env.REACT_APP_S3_BUCKET_SAMPLE_ASSETS || 'newsela-video-asset-storage-dev';

export const horizontalSampleVideo = {
  formats: {
    mp4: `https://${S3_BUCKET_SAMPLE_ASSETS}.s3.amazonaws.com/samples/HorizontalSample/horizontal_sample_tools.mp4`,
    m3u8: `https://${S3_BUCKET_SAMPLE_ASSETS}.s3.amazonaws.com/samples/HorizontalSample/horizontal_sample_tools.m3u8`,
  },
  duration: 90000,
  width: 1920,
  height: 1080,
  aspectRatio: 1.7777777777777777,
  isControlled: true,
  name: 'Sample Video',
  posterImage: `https://${S3_BUCKET_SAMPLE_ASSETS}.s3.amazonaws.com/samples/HorizontalSample/Horizontal_poster.jpg`,
};
export const verticalSampleVideo = {
  formats: {
    mp4: `https://${S3_BUCKET_SAMPLE_ASSETS}.s3.amazonaws.com/samples/VerticalSample/vertical_sample_tools.mp4`,
    m3u8: `https://${S3_BUCKET_SAMPLE_ASSETS}.s3.amazonaws.com/samples/VerticalSample/vertical_sample_tools.m3u8`,
  },
  duration: 50000,
  width: 607,
  height: 1080,
  aspectRatio: 0.937037037037037,
  isControlled: true,
  name: 'Vertical Sample Video',
  posterImage: `https://${S3_BUCKET_SAMPLE_ASSETS}.s3.amazonaws.com/samples/VerticalSample/vertical_poster.jpg`,
};
