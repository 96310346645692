import React from 'react';

import { Embed } from 'types/publishing';

import { formatPlayerFilePath } from '../../../../services/packagingService';
import { assetService } from '../../../../services/stateController';
import { resolvePlayerHost } from '../../../../services/utils';
import { loadScipt } from '../../../../sharedUtils';
import { BaseEmbed } from './BaseEmbed';
import { getLocatorString } from './embedUtils';
const hy = (window as any).hy;

type Params = {
  src: string;
  title?: string | null;
  description?: string | null;
  thumbnail?: string | null;
  language?: string | null;
  variables: [] | null;
  width: string | null;
  height: string | null;
}

const WHITE_LISTED_PARAMS = [
  'textLocation',
  'playButton',
  'captions',
  'loop',
  'autoplay',
  'start',
  'seo',
  'refId',
  'presentation',
];

export class CustomTagEmbed extends BaseEmbed {
  _render = (includeScript: any): JSX.Element => {
    const { baseUrl, embed = {} as Embed, preview } = this.definition;
    const { embedVariables = {}, showTitle, title, showDescription, description, dimensions, sourceType } = embed;
    const isFixed = dimensions === 'fixed';

    const params: Params = {
      src: `${baseUrl}config.json`,
      title: showTitle ? title : null,
      description: showDescription ? description : null,
      thumbnail: embed.thumbnail ? assetService.getImage(embed.thumbnail).src : null,
      language: embed.language ? embed.language : null,
      variables: Object.keys(embedVariables).length ? embedVariables : null,
      width: isFixed ? embed.width + 'px' : null,
      height: isFixed ? embed.height + 'px' : null,
    };

    WHITE_LISTED_PARAMS.forEach((param) => {
      if (![undefined, null, ''].includes(embed[(param as keyof Embed)])) {
        params[(param as keyof Params)] = embed[(param as keyof Embed)];
      }
    });

    const scrubFalsy = (acc: any, key: any) => {
      const _acc = acc;
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (![undefined, null].includes(params[key])) _acc[key] = params[key];
      return _acc;
    };

    const refinedParams = Object.keys(params).reduce(scrubFalsy, {});
    let customTagHelper = formatPlayerFilePath(baseUrl, 'embed.js');
    let generatedEmbed = hy.generateCustomTagString(refinedParams, baseUrl + 'player/');

    if (sourceType === 'dynamic') {
      const [major] = hy.version.split('.');
      const versionedBase = resolvePlayerHost(major) + '/';

      refinedParams.src = getLocatorString();
      customTagHelper = versionedBase + 'embed.js';
      generatedEmbed = hy.generateCustomTagString(refinedParams, versionedBase);
    }

    loadScipt(customTagHelper);

    const embedPreview = (
      <div className='hy-custom-tag-embed' dangerouslySetInnerHTML={{ __html: generatedEmbed }} />
    );

    return preview ? embedPreview : generatedEmbed;
  };
}
