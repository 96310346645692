import React from 'react';

import { stateController } from '../../../../services/stateController';
import { getCollections } from '../../../../services/toolRelationshipService';
import { TOOLS } from '../../../../services/toolService';
import { Annotation } from '../../../../types/annotations';
import { findAssociatedQuizAnnotationIds } from '../../UXEditor/AnnotationEditor/QuizBuilder/quizBuilderUtils';
import { ArbEditor } from '../ArbEditor';
import { MultiTimeAnnotationList } from '../MultiTimeAnnotationList';
import { sculptAnnotationForTableDisplay, sortByAnnotationType, sortByEvaluatedTime } from './arbAnnotationEditorUtils';

// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
const getToolTypeByName = (name: string) => TOOLS?.[name]?.defaults?.type;
const menuAnnotations = ['quiz', 'chapterMenu', 'contentExplorer'].map(getToolTypeByName);

export class ArbAnnotationEditor extends ArbEditor {
  organizeTableItems = (data: any) => {
    const getChapterIds = (a: Annotation) => getCollections(a);
    const getQuizIds = (a: Annotation) => [a.id, ...findAssociatedQuizAnnotationIds(a, '', undefined, true)];
    const findAssociatedIds = (a: Annotation) => a.type === 'quiz' ? getQuizIds(a) : getChapterIds(a);

    const isCollectable = (a: Annotation) => menuAnnotations.includes(a.type);

    const projectAnnotations = stateController.getCurrentData('ux').annotations;
    const collectibleIds = projectAnnotations
      .filter(isCollectable)
      .map(findAssociatedIds)
      .flat();

    const filteredData = data.filter((a: any) => !collectibleIds.includes(a.identifier));
    const itemizedCollections = collectibleIds.map((id: string) => data.find((a: any) => a.identifier === id)).filter(Boolean);

    return [...filteredData, ...itemizedCollections]
      .map(sculptAnnotationForTableDisplay)
      .sort(sortByEvaluatedTime)
      .sort(sortByAnnotationType);
  };

  listComponent = ({
    data,
    isEditingTranslation,
    duration
  }: any) => {
    const { lexileLevel, language, type } = this.props;

    return (
      <MultiTimeAnnotationList
        showType
        // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
        items={this.organizeTableItems(data, isEditingTranslation)}
        data={data}
        isEditingTranslation={isEditingTranslation}
        showTranslation
        duration={duration}
        updateItems={this.updateItems}
        lexileLevel={lexileLevel}
        language={language}
        type={type}
      />
    );
  };
}
