import React, { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from 'react';

import { Chip, ChipGroup } from '@newsela/angelou';
import './MaturityRange.scss';

import { Maturity, MaturityLevel, MaturityRange } from 'types/project';

export type MaturityObj = Maturity & {
  setActive: Dispatch<SetStateAction<boolean>>;
  isActive?: boolean;
};

type MaturityRangeSelectorProps = {
  range: MaturityRange;
  setRange: (value: MaturityRange) => void;
}

export const MaturityRangeSelector = ({ range, setRange }: MaturityRangeSelectorProps) => {
  const [leActive, setLeActive] = useState(false);
  const [ueActive, setUeActive] = useState(false);
  const [msActive, setMsActive] = useState(false);
  const [hsActive, setHsActive] = useState(false);

  const le: MaturityObj = {
    level: 'LE',
    description: 'Lower Elementary',
    gradeMax: 3,
    gradeMin: 2,
    setActive: setLeActive,
    isActive: leActive
  };
  const ue: MaturityObj = {
    level: 'UE',
    description: 'Upper Elementary',
    gradeMax: 5,
    gradeMin: 4,
    setActive: setUeActive,
    isActive: ueActive
  };
  const ms: MaturityObj = {
    level: 'MS',
    description: 'Middle School',
    gradeMax: 8,
    gradeMin: 6,
    setActive: setMsActive,
    isActive: msActive
  };
  const hs: MaturityObj = {
    level: 'HS',
    description: 'High School',
    gradeMax: 12,
    gradeMin: 9,
    setActive: setHsActive,
    isActive: hsActive
  };
  const levels = [le, ue, ms, hs];

  const findMaturityLevel = (level: MaturityLevel | undefined): MaturityObj | undefined =>
    levels.find((maturityLevelObj) => maturityLevelObj.level === level);

  const elementIndex = (level: MaturityLevel | undefined): number => {
    const element = findMaturityLevel(level);
    return element ? levels.indexOf(element) : -1;
  };

  const firstActiveElementIndex = (): number => {
    const first = levels.find((obj: MaturityObj) => obj.isActive);
    return first ? elementIndex(first.level) : 0;
  };

  const addItemToRange = (value: MaturityObj) => {
    const firstSelectedElement = levels[firstActiveElementIndex()];
    if (firstSelectedElement && levels.indexOf(value) > firstActiveElementIndex()) {
      setRange([firstSelectedElement, value]);
    }
    if (firstSelectedElement && levels.indexOf(value) < firstActiveElementIndex()) {
      setRange([value, firstSelectedElement]);
    }
    if (range.length === 0) {
      setRange([value, value]);
    }
    value.setActive(true);
  };

  const amplifyRange = (value: MaturityObj) => {
    if (elementIndex(value.level) > elementIndex(range[1]?.level)) {
      setRange([range[0], value]);
      value.setActive(true);
    }
    if (elementIndex(value.level) < elementIndex(range[1]?.level)) {
      setRange([value, range[1]]);
      value.setActive(true);
    }
  };

  const reduceRange = (value: MaturityObj) => {
    const leftDistance = elementIndex(value.level) - elementIndex(range[0]?.level);
    const rightDistance = elementIndex(range[1]?.level) - elementIndex(value.level);
    if (rightDistance <= leftDistance) {
      setRange([range[0], value]);
    } else {
      setRange([value, range[1]]);
    }
  };

  const isInRange = (value: MaturityObj) => {
    return value.level === range[0]?.level || value.level === range[1]?.level;
  };

  const removeItemFromRange = (value: MaturityObj) => {
    value.setActive(false);
    let r: MaturityRange = [];
    if (range[0]?.level === value.level) {
      r = [range[1], range[1]];
    }
    if (range[1]?.level === value.level) {
      r = [range[0], range[0]];
    }
    if (range[0]?.level === value.level && range[1]?.level === value.level) {
      r = [];
    }
    setRange(r);
  };

  const rangeElements = () => {
    if (range.length === 0) {
      return 0;
    }
    if (range.length === 1 || range[0] === range[1]) {
      return 1;
    }
    return 2;
  };

  const activeElements = () => {
    levels.forEach((maturityObj) => {
      if (!isInRange(maturityObj)) maturityObj.setActive(false);
    });
    if (rangeElements() === 1) {
      const element = levels.find((o) => o.level === range[0]?.level);
      element?.setActive(true);
    }
    if (rangeElements() >= 2) {
      const r0 = elementIndex(range[0]?.level);
      const r1 = elementIndex(range[1]?.level);
      levels.forEach((o, i) => {
        if (i >= r0 && i <= r1) {
          o.setActive(true);
        }
      });
    }
  };

  useEffect(() => {
    activeElements();
  }, [range]);

  const setMaturityRange = (_event: unknown, value: string) => {
    const maturityLevel = findMaturityLevel(value as MaturityLevel);
    if (!maturityLevel) {
      return;
    }
    if (isInRange(maturityLevel)) {
      removeItemFromRange(maturityLevel);
    } else {
      if (rangeElements() <= 1) {
        addItemToRange(maturityLevel);
      } else {
        if (maturityLevel.isActive) {
          reduceRange(maturityLevel);
        } else {
          amplifyRange(maturityLevel);
        }
      }
    }
  };

  const className = (state: boolean) => {
    return state ? { root: 'maturity-range-chip-active' } : undefined;
  };

  return (
    <div>
      <div className='maturity'>Maturity range</div>
      <ChipGroup groupName='MaturityRange' chipType={ChipGroup.chipTypes.filter} onChipEvent={setMaturityRange} size={ChipGroup.chipSizes.small}>
        <Chip text={le.level} value={le.level} active={leActive} __classNameFor={className(leActive)} />
        <Chip text={ue.level} value={ue.level} active={ueActive} __classNameFor={className(ueActive)} />
        <Chip text={ms.level} value={ms.level} active={msActive} __classNameFor={className(msActive)} />
        <Chip text={hs.level} value={hs.level} active={hsActive} __classNameFor={className(hsActive)} />
      </ChipGroup>
    </div>
  );
};
