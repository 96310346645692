import React from 'react';

import { media } from '../../../../services/mediaController';
import hms from '../../../../services/time';
import { VideoMetadataListItem } from './VideoMetadataListItem';

type VideoMetadataProps = {
  transcoding?: boolean;
};

export class VideoMetadata extends React.Component<VideoMetadataProps> {
  formatAspectRatio = (aspectRatio: any) => {
    const numerator = Math.round(aspectRatio * 10) / 10;
    const denominator = 1; // TODO: display closest common aspect ratio as well as actual aspect ratio
    return `${numerator} : ${denominator}`;
  };

  get videoProperties () {
    const { transcoding } = this.props;
    const { width, height, aspectRatio, duration, created } = media.video;

    return [
      ['Created', hms.formatDateTime(created)],
      ['Duration', !transcoding && duration ? hms.secondsToHMS(duration / 1000, true) : ''],
      ['Resolution', !transcoding ? `${width} x ${height}` : ''],
      ['Aspect Ratio', !transcoding ? this.formatAspectRatio(aspectRatio) : ''],
    ];
  }

  get formattedVideoMetadata () {
    return this.videoProperties.map(([key, value]) => <VideoMetadataListItem key={key} name={key} value={value} />);
  }

  render () {
    return <ul style={{ listStyleType: 'none' }}>{this.formattedVideoMetadata}</ul>;
  }
}
