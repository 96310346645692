import React from 'react';

import { SemanticCOLORS } from 'semantic-ui-react';
import { IconProps } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';

import { Icon, Message } from '../../../hapyak-ui-toolkit';

type RunningStatusProps = {
  children?: any;
  color?: SemanticCOLORS;
  content: string;
  header?: string;
  icon?: IconProps;
  inline?: boolean;
  negative?: boolean;
  size?: any;
};

export class RunningStatus extends React.Component<RunningStatusProps> {
  get inline () {
    const { content, icon, size, children } = this.props;
    return (
      <Message
        color='blue'
        size={size}
        content={
          <div>
            <Icon name='sync alternate' {...icon} />
            <span className='hy-animation-ellipsis'>{content}</span>
            {children}
          </div>
        }
      />
    );
  }

  get message () {
    const { children, ...props } = this.props;

    return (
      <Message compact {...props}>
        {children}
      </Message>
    );
  }

  render () {
    const { inline } = this.props;
    return inline ? this.inline : this.message;
  }
}
