import React, { Component } from 'react';

import { Grid } from '../../../../../../hapyak-ui-toolkit';
import { stateController } from '../../../../../../services/stateController';
import { addAnnotationWithAssociations, updateAnnotation } from '../../../../../../services/toolService';
import { Annotation, AnnotationProperties, CreateAnnotationResponse } from '../../../../../../types/annotations';
import { QuestionType } from '../../../../../../types/questions';
import { incrementNewAnswerDefaults, QUIZ_ANSWER_TOOL_TYPE } from '../questionService';
import { QuizAnswersAsAnnotations } from '../QuizAnswersAsAnnotations';
import { formatAnswersForInputTable, formatAnswersFromInputTable } from '../quizBuilderUtils';
import { QuizAnswerTable } from './QuizAnswerTable';
import { ShortAnswerEditor } from './ShortAnswerEditor';

type QuizAnswerEditorListProps = {
  addAnswerButton: any;
  annotation?: Annotation;
  annotations: Annotation[];
  answerIds: string[];
  appliedTo: any;
  deleteAnnotation: (id: string, annotations: Annotation[]) => any;
  edit: any;
  editing: any;
  questionType: QuestionType;
  quizAnnotation: Annotation;
  setFromObject: any;
  setProperty: any;
  updatedAnnotations?: any;
};
export class QuizAnswerEditorList extends Component<QuizAnswerEditorListProps> {
  formatData: any;
  saveAnnotations = (annotations: Annotation[]) => {
    stateController.updateProject('ux', { annotations });
  };

  addAnswer = (): Annotation => {
    const { updatedAnnotations, annotation } = this.createAnnotationWithDefaults();
    this.saveAnnotations(updatedAnnotations || []);
    return annotation || {} as Annotation;
  };

  createAnnotationWithDefaults = (): CreateAnnotationResponse => {
    const { appliedTo = {}, answerIds = [], questionType } = this.props;
    if (!appliedTo.id) return {} as CreateAnnotationResponse;

    const newAnswerOverrides = incrementNewAnswerDefaults(questionType, answerIds.length + 1);

    return addAnnotationWithAssociations({
      appliesTo: appliedTo.id,
      toolType: QUIZ_ANSWER_TOOL_TYPE,
      overrides: newAnswerOverrides,
    });
  };

  onDelete = (id: string, annotations: Annotation[]) => {
    const { deleteAnnotation } = this.props;
    if (typeof deleteAnnotation === 'function') deleteAnnotation(id, annotations);
  };

  persistChange = (data: any) => {
    const { annotations } = this.props;
    try {
      const updatedAnnotationData = formatAnswersFromInputTable(data, annotations);
      this.saveAnnotations(updatedAnnotationData);
    } catch (e) {
      console.error('Error persisting quiz answers', e);
    }
  };

  handleShortAnswerEditorChange = (annotationId: string, properties: AnnotationProperties) => {
    updateAnnotation(annotationId, { properties: properties });
  };

  get items () {
    const { answerIds, annotations, editing = {} } = this.props;
    const { placeholders = {} } = editing;
    return formatAnswersForInputTable(answerIds, annotations, placeholders);
  }

  get customEditor () {
    const {
      answerIds = [],
      annotations = [],
      editing = {},
      quizAnnotation,
      edit,
      annotation: appliesTo,
    } = this.props;
    const { editor } = editing;
    const annotation = annotations.find((a: Annotation) => answerIds.includes(a.id));

    if (editor === 'shortAnswerEditor' && annotation) {
      return (
        <ShortAnswerEditor annotation={annotation} onChange={this.handleShortAnswerEditorChange} />
      );
    } else if (editor === 'answersAsAnnotations') {
      return (
        <QuizAnswersAsAnnotations
          quizAnnotation={quizAnnotation}
          annotation={annotation}
          annotations={annotations}
          persistChange={this.persistChange}
          appliesTo={appliesTo}
          onDelete={this.onDelete}
          edit={edit}
          answerIds={answerIds}
          createAnnotationWithDefaults={this.createAnnotationWithDefaults}
        />
      );
    }

    return null;
  }

  get defaultEditor () {
    const { annotations, answerIds = [], addAnswerButton, editing = {}, questionType = '' as QuestionType } = this.props;
    const {
      addOrRemoveDisabled,
      hasOtherOption,
      requiredProperties = [],
      nonEditableProperties = [],
      inputValueType = '',
    } = editing;
    const secondaryAddContent = hasOtherOption ? 'Add "Other"' : null;

    if (!answerIds || !answerIds.length) {
      return <Grid className='hy-margin-top hy-margin-bottom'>{addAnswerButton}</Grid>;
    }

    const removeEnabled = !addOrRemoveDisabled && answerIds.length > 2;

    return (
      <QuizAnswerTable
        questionType={questionType}
        answerIds={answerIds}
        removeEnabled={removeEnabled}
        addOrRemoveDisabled={addOrRemoveDisabled}
        items={this.items}
        createAnnotationWithDefaults={this.createAnnotationWithDefaults}
        requiredProperties={requiredProperties}
        nonEditableProperties={nonEditableProperties}
        persistChange={this.persistChange}
        onAdd={this.addAnswer}
        onDelete={this.onDelete}
        annotations={annotations}
        secondaryAddContent={secondaryAddContent}
        inputValueType={inputValueType}
      />
    );
  }

  render () {
    const { editing = {} } = this.props;
    const { editor } = editing;

    return editor === 'default' ? this.defaultEditor : this.customEditor;
  }
}
