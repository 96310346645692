import defaults from '../../../../DEFAULTS.json';
import { toPercent, toPixels } from '../../../../services/utils';
const { MIN_ANNOTATION_DURATION } = defaults;

export const move = (data: any, clientX: any) => {
  let { mouseX, x, w, _x, _w, edge } = data;
  const amount = clientX - mouseX;

  if (edge === 'left') {
    // drag start
    w = _w - amount;
    x = _x + amount;
  } else if (edge === 'right') {
    // drag end
    w = _w + amount;
  } else {
    // drag duration
    x = _x + amount;
  }

  return { x, w };
};

export const clampPosition = (data: any, boundsWidth: any, dimensions: any) => {
  let { x, w } = dimensions;
  const { _x, _w, clampLeft, clampRight, edge } = data;
  const right = x + w;
  let clamped = false;

  if (x < 0) {
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'boolean'.
    clamped = 'long';
    x = 0;
    if (edge === 'left') {
      w = _w + _x;
    }
  } else if (right > boundsWidth) {
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'boolean'.
    clamped = 'long';
    if (edge === 'right') {
      w = boundsWidth - x;
      x = boundsWidth - w;
    } else {
      x = boundsWidth - w;
      w = boundsWidth - x;
    }
  }

  if (edge === 'left' && x > clampRight) {
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'boolean'.
    clamped = 'short';
    x = clampRight;
    w = right - x;
  } else if (edge === 'right' && right < clampLeft) {
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'boolean'.
    clamped = 'short';
    w = clampLeft - x;
  }

  return { clamped, dimensions: { x, w } };
};

export const getLatestDimensions = (data: any) => {
  const { x, w } = data;
  return { _x: x, _w: w };
};

export const getPositioningData = (data: any, boundsWidth: any) => {
  const { start, end, shortestDuration, duration } = data;
  const leftPercent = toPercent(start, duration);
  const widthPercent = toPercent(end - start, duration);
  const leftClampPercent = toPercent(end - shortestDuration + MIN_ANNOTATION_DURATION, duration);
  const rightClampPercent = toPercent(start + shortestDuration - MIN_ANNOTATION_DURATION, duration);

  const x = toPixels(leftPercent, boundsWidth);
  const w = toPixels(widthPercent, boundsWidth);

  return {
    start,
    end,
    x,
    _x: x,
    mouseX: null,
    w,
    _w: w,
    clampLeft: toPixels(leftClampPercent, boundsWidth),
    clampRight: toPixels(rightClampPercent, boundsWidth),
    clamped: null,
    boundsWidth,
  };
};

export const toBoxAsPercents = (data: any, boundsWidth: any) => {
  const { _x, _w, x, w } = data;
  const deltaWidth = _w - w;
  const deltaLeft = _x - x;

  return {
    width: toPercent(w, boundsWidth),
    left: toPercent(x, boundsWidth),
    right: toPercent(x + w, boundsWidth),
    deltaWidth: toPercent(deltaWidth, boundsWidth),
    deltaLeft: toPercent(deltaLeft, boundsWidth),
    deltaRight: toPercent(deltaLeft + deltaWidth, boundsWidth),
  };
};

export const noDragOccurred = (data: any) => {
  const { _x, _w, x, w } = data;
  return x === _x && w === _w;
};
