import './index.scss';
import './PublishingPreparation.scss';

import React, { Component } from 'react';

import _ from 'lodash';

import { JobError } from 'errors/JobError';

import DEFAULTS from '../../../DEFAULTS.json';
import { Button, Confirm, CustomStepTabs, Grid, Header, Image, Message } from '../../../hapyak-ui-toolkit';
import sherpa from '../../../img/sherpa-robot-by-kyle-basic.png';
import { comm } from '../../../services/comm';
import { groupConfig } from '../../../services/configurationService';
import { media } from '../../../services/mediaController';
import { formatBundleTransaction } from '../../../services/monetaryTransactionService';
import { ProcessRunner } from '../../../services/processRunner';
import { PublisherFactory } from '../../../services/publishing/PublisherFactory';
import { assetService, stateController } from '../../../services/stateController';
import { getUpdateTabCallbackKey, isRegex } from '../../../services/utils';
import { Bundle, Publishing, VideoTypeOption } from '../../../types/publishing';
import ErrorBanner from '../AngelouComponents/ErrorBanner';
import WarningBanner from '../AngelouComponents/WarningBanner';
import { NonReliantPublishButton } from '../CommonComponents/NonReliantPublishButton';
import { Checklist } from './Checklist';
import { DownloadToggle } from './DownloadToggle';
import { LandingPageToggle } from './LandingPageToggle';
import { MetaDataOptions } from './MetaDataOptions';
import { OptimizationOptions } from './OptimizationOptions';
import { PublishDestinationPicker } from './PublishDestinationPicker';
import { BUNDLE_PROPS, PUBLISH_SETTINGS_BLACKLIST, VIDEO_TYPE_OPTIONS } from './publishingOptions';
import { PublishSummary } from './PublishSummary';
import { RestrictionOptions } from './RestrictionOptions';
import { SeoOptions } from './SeoOptions';
import { SeoToggle } from './SeoToggle';

type State = any;

type PublishingPreparationProps = Publishing & {
  getPublishingOptions: any;
  match?: any;
  packagingOptions: any;
  setPublishing: any;
  onPublished: any;
  bundle: Bundle;
};
export class PublishingPreparation extends Component<PublishingPreparationProps, State> {
  publisherInstance = null;

  constructor (props: PublishingPreparationProps) {
    super(props);

    this.state = {
      publishInitiated: false,
      bundleProps: this.getBundleProps(this.props),
      bundleDisplay: '',
      loading: false,
      selectedActions: Object.keys(this.tabNames).map((tabName) => {
        return {
          title: tabName,
          sections: [],
          tabIndex: null,
        };
      }),
    };
  }

  getDisplayVal = (val = {}) => (val as any).display && (val as any).display.toLowerCase();

  componentDidMount () {
    this.attemptReconnect();
    this.updatePublishingOptions();
    this.updateActiveTab(0);
    comm.register('stopPackageCreation', this.stopPackageCreation);
  }

  componentWillUnmount () {
    comm.unregister('stopPackageCreation', this.stopPackageCreation);
  }

  componentDidUpdate (previousProps: PublishingPreparationProps, previousState: State, snapshot: any) {
    if (previousState.bundleProps.packaging === this.bundleProps.packaging) return;
    this.updatePublishingOptions();
  }

  onSelectedActionChange = (tabName: string, data: any) => {
    const { selectedActions } = this.state;

    const _selectedActions = selectedActions.map((selected: any) => {
      if (selected.title !== tabName) return selected;
      return {
        ...selected,
        sections: data,
      };
    });

    this.setState({ selectedActions: _selectedActions });
  };

  updatePublishingOptions = () => {
    const { getPublishingOptions } = this.props;
    const enabledPublishingOptions = getPublishingOptions(this.packagingOption)?.filter((opt: any) => !opt.disabled);
    const option = enabledPublishingOptions?.[0];
    if (option && !option.disabled) {
      // first chosen by default
      this.updateValue('publishTo', option.value);
    }
  };

  saveChanges = () => {
    stateController.updateProject('publishing', { ...this.bundleProps });
  };

  updateValue = (key: any, value: any) => {
    return new Promise((resolve) => {
      this.setState(
        {
          bundleProps: {
            ...this.bundleProps,
            [key]: value,
          },
        }
      );
      resolve(true);
    });
  };

  updateValues = (data = {}) => {
    const props = { ...this.bundleProps, ...data };
    return new Promise((resolve) => {
      this.setState({ bundleProps: props });
      resolve(true);
    });
  };

  handleChange = (key: any, e: any, {
    value
  }: any) => {
    this.updateValue(key, value);
  };

  get packagingOption () {
    const { packaging } = this.bundleProps;
    const { packagingOptions } = this.props;
    return packagingOptions.find((option: any) => option.key === packaging);
  }

  get publishingOption () {
    const { publishTo } = this.bundleProps;
    const { getPublishingOptions } = this.props;
    return getPublishingOptions(this.packagingOption).find((option: any) => option.key === publishTo);
  }

  setPublishing = (isPublishing: any) => {
    const { setPublishing } = this.props;
    if (typeof setPublishing === 'function') setPublishing(isPublishing);
  };

  get Publisher () {
    return PublisherFactory.create(this.bundleProps.publishTo);
  }

  getBundleProps = (props: any) => {
    return _.pick(props, BUNDLE_PROPS);
  };

  get bundleProps () {
    return this.state.bundleProps;
  }

  get transactionData () {
    const selected = this.selectedActionsFiltered;
    const projectId = stateController.getCurrentData().project.id;
    return formatBundleTransaction(selected, projectId);
  }

  get description () {
    const description = stateController.getCurrentData().project?.description || '';
    if (description.trim().length === 0) {
      return '';
    }
    return description;
  }

  get hasVideoSrc (): boolean {
    const video = assetService.getVideosArray()[0] || {};
    if (Object.keys(video).length === 0 || video.formats?.mp4.length === 0 || video.formats?.mp4.includes('horizontal_sample_tools')) {
      return false;
    }
    return true;
  }

  initiatePublishing = () => this.setState({ publishInitiated: true });
  cancelInitiation = () => this.setState({ publishInitiated: false });

  makePublisherInstance = () => {
    this.setPublishing(true);
    this.publisherInstance = new this.Publisher({ ...this.bundleProps }, this.transactionData);
    return this.publisherInstance;
  };

  createPackage = () => {
    this.toggleLoadState();
    this.cancelInitiation();
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    this.makePublisherInstance()
      .publish()
      .then(this.attemptReconnect)
      .catch(this.onPackageError);
  };

  toggleLoadState = () => {
    // toggle load state for react component to assess change
    this.setState({ loading: true }, () => {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    });
  };

  attemptReconnect = () => {
    const bundle = assetService.getCurrentBundle();
    if (bundle.statusUrl) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      this.makePublisherInstance()
        .reconnect()
        .then(this.onPackageUpdated)
        .catch(this.onPackageError);
    }
  };

  onPackageError = (err: JobError) => {
    const { onPublished } = this.props;

    if (typeof onPublished === 'function') {
      onPublished(err).then(this.packagingProcessComplete);
    } else {
      this.packagingProcessComplete();
    }
  };

  stopPackageCreation = () => this.packagingProcessComplete();

  haltPublisher = () => this.publisherInstance && (this.publisherInstance as any).stop();

  onPackageUpdated = (update: any) => {
    if (update && !update.error) {
      const { onPublished } = this.props;
      if (typeof onPublished === 'function') onPublished().then(this.packagingProcessComplete);
    }
  };

  packagingProcessComplete = () => {
    ProcessRunner.stop(DEFAULTS.PACKAGING_KEY);
    this.haltPublisher();
    this.setPublishing(false);

    const { updateBundle } = this.props;
    if (typeof updateBundle === 'function') {
      return updateBundle();
    }
  };

  get videoTypeOptions (): VideoTypeOption[] {
    // apply configuration / provisioning here
    return VIDEO_TYPE_OPTIONS.map((opt) => {
      const disabled = !this.availableFormats.includes(opt.value);
      return {
        ...opt,
        disabled,
        text: disabled ? `${opt.text} [missing]` : opt.text,
      };
    });
  }

  get availableFormats () {
    const formats = media.formats;
    return _.keys(formats).filter((f) => !!formats[f]);
  }

  get whitelistInvalid () {
    return this.bundleProps.whitelist.some((item: any) => !isRegex(item));
  }

  get disabled () {
    const { videoType } = this.bundleProps;
    const { isPublishing } = this.props;

    return (
      this.whitelistInvalid ||
            isPublishing ||
            (this.canPublishPublicly && !this.publishingOption) ||
            !this.availableFormats.includes(videoType)
    );
  }

  get canPublishPublicly () {
    return groupConfig.allow('publishing.targets.aws-s3');
  }

  get disableIfEmblazoningAndDubbing () {
    const { bundleProps } = this.state;
    return !!bundleProps.dub && !!_.get(bundleProps, 'emblazonLanguages.length');
  }

  get publishButton () {
    const { isPublishing, bundle } = this.props;

    return (
      <NonReliantPublishButton
        disabled={this.disableIfEmblazoningAndDubbing || !this.description || !this.hasVideoSrc}
        onClick={isPublishing ? this.initiatePublishing : this.createPackage}
        content={bundle ? 'Update' : 'Publish'}
      />
    );
  }

  get stopPackagingButton () {
    const { isPublishing } = this.props;
    return <Button disabled={!isPublishing} onClick={this.stopPackageCreation} negative content='Cancel' />;
  }

  get headerMessage () {
    return (
      <Message style={{ background: '#FFF' }}>
        <Grid>
          <Grid.Row columns='equal'>
            <Grid.Column>
              <Image style={{ height: '85px', width: 'auto', margin: 'auto' }} size='tiny' src={sherpa} />
            </Grid.Column>
            <Grid.Column width={14} verticalAlign='middle'>
              <Header>
                Publish your project, add restrictions, and optimize your output... right here.
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Message>
    );
  }

  get hostingTabContent () {
    const destinations = this.props.getPublishingOptions(this.packagingOption);
    const hostingAttrs = {
      bundleProps: this.bundleProps,
      saveChanges: this.saveChanges,
      updateValue: this.updateValue,
      handleChange: this.handleChange,
    };

    return (
      <Grid columns={2} padded={false}>
        <Grid.Column style={{ paddingTop: 0 }}>
          <Header>Select your actions</Header>
          <div className='hy-margin-top'>
            <OptimizationOptions
              {...hostingAttrs}
              videoTypeOptions={this.videoTypeOptions}
              handleChange={this.handleChange}
              onSelectedActionChange={this.onSelectedActionChange}
              tabName={this.tabNames.hosting}
            />
          </div>
        </Grid.Column>
        <Grid.Column style={{ paddingTop: 0 }}>
          <Header>Select your hosting</Header>
          <div>
            <PublishDestinationPicker {...hostingAttrs} className='hy-margin-top' options={destinations} />
          </div>

          <div>
            {/* TS-CONVERSION removed: unused className='hy-margin-top' */}
            <DownloadToggle
              {...hostingAttrs}
              forceTrue={!this.canPublishPublicly}
            />
          </div>

          <div>
            <LandingPageToggle
              {...hostingAttrs}
              hidden={this.publishSettingHidden('includeLandingPage')}
              className='hy-margin-top'
            />
          </div>
        </Grid.Column>
      </Grid>
    );
  }

  publishSettingHidden (settingName = '') {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return (PUBLISH_SETTINGS_BLACKLIST[this.bundleProps.publishTo] || []).includes(settingName);
  }

  get publishSettingsSummary () {
    const { seoVideoObjectSchema } = this.bundleProps;
    const { restrictionsEnabled } = stateController.getCurrentData('publishing');
    const { settingsBlacklist = [], text } = this.publishDestination;

    const summaryItems = {
      hosting: {
        title: text,
        active: this.canPublishPublicly,
      },
      restrictions: {
        title: 'Restrictions enabled',
        active: restrictionsEnabled,
      },
      seo: {
        title: 'SEO enabled',
        active: seoVideoObjectSchema,
      },
    };

    return Object.keys(summaryItems)
      .filter((key) => !settingsBlacklist.includes(key))
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      .map((key) => summaryItems[key]);
  }

  get selectedActionsFiltered () {
    const { selectedActions } = this.state;

    return selectedActions
      .filter((item: any) => item.sections.length)
      .map((selected: any) => {
        if (selected.sections.length === 0) return {};

        return {
          title: _.capitalize(selected.title),
          lineItems: selected.sections[0].lineItems.filter((item: any) => item.selected),
        };
      });
  }

  get publishingTabContent () {
    const { bundle } = this.props;
    const { loading } = this.state;

    return (
      <section className='publish-workflow-container'>
        <div className='publish-workflow-section processing'>
          <Header>Processing</Header>
          <div className='hy-margin-top'>
            <PublishSummary selectedActions={this.selectedActionsFiltered} loading={loading} />
          </div>
        </div>
        <div className='publish-workflow-section hosting'>
          <Header>Hosting</Header>
          <div>
            <Checklist items={this.publishSettingsSummary} />
          </div>
        </div>
        <div className='publish-workflow-section create'>
          <Header>{bundle ? 'Update' : 'Create'}</Header>
          <div>
            <MetaDataOptions
              project={stateController.getCurrentData().project}
              bundleProps={this.bundleProps}
              videoTypeOptions={this.videoTypeOptions}
              saveChanges={this.saveChanges}
              updateValue={this.updateValue}
              handleChange={this.handleChange}
            />
            {this.errorMessage()}
            <div className='hy-margin-top'>{this.publishButton}</div>
            {this.disableIfEmblazoningAndDubbing && (
              <WarningBanner
                body='Publishing with Emblazoning and Dubbing is not currently supported. Please uncheck one option.'
              />
            )}
          </div>
        </div>
      </section>
    );
  }

  errorMessage = (): JSX.Element | undefined => {
    const errorMsgArray = [];
    const descriptionMsg = (<li key={0}>Project description is required.</li>);
    const videoMsg = (<li key={1}>Video file is required.</li>);

    if (!this.description) {
      errorMsgArray.push(descriptionMsg);
    }

    if (!this.hasVideoSrc) {
      errorMsgArray.push(videoMsg);
    }

    if (errorMsgArray.length > 0) {
      const preamble = `This project has ${errorMsgArray.length} error${errorMsgArray.length > 1 ? 's' : ''}. Project cannot upload to Alexandria until th${errorMsgArray.length > 1 ? 'ese' : 'is'} error${errorMsgArray.length > 1 ? 's' : ''} ${errorMsgArray.length > 1 ? 'are' : 'is'} fixed:`;
      const bannerBodyArray = [preamble, ...errorMsgArray];

      return (
        <ErrorBanner
          body={bannerBodyArray}
          validationError
        />
      );
    }
  };

  get tabNames () {
    return {
      hosting: 'hosting',
      restrictions: 'restrictions',
      seo: 'seo',
      publish: 'publish',
    };
  }

  get tabContent () {
    const { settingsBlacklist = [] } = this.publishDestination;

    return [
      {
        name: this.tabNames.hosting,
        items: [this.hostingTabContent],
      },
      {
        name: this.tabNames.restrictions,
        items: [
          <RestrictionOptions
            bundleProps={this.bundleProps}
            videoTypeOptions={this.videoTypeOptions}
            saveChanges={this.saveChanges}
            updateValue={this.updateValue}
            handleChange={this.handleChange}
            key='RestrictionOptions'
          />,
        ],
      },
      {
        name: this.tabNames.seo,
        items: [
          <SeoToggle
            // @ts-expect-error TS(2769): No overload matches this call.
            style={{ marginLeft: '20px' }}
            bundleProps={this.bundleProps}
            saveChanges={this.saveChanges}
            updateValue={this.updateValue}
            key='SeoToggle'
          />,
          <SeoOptions
            key='SeoOptions'
            bundleProps={this.bundleProps}
            handleChange={this.handleChange}
            saveChanges={this.saveChanges}
            updateValue={this.updateValue}
            updateValues={this.updateValues}
            videoTypeOptions={this.videoTypeOptions}
          />,
        ],
      },
      {
        name: this.tabNames.publish,
        items: [this.publishingTabContent],
      },
    ].filter((content) => !settingsBlacklist.includes(content.name));
  }

  get publishDestination () {
    const destinations = this.props.getPublishingOptions(this.packagingOption);
    return destinations?.find((d: any) => d.key === this.bundleProps.publishTo) || {};
  }

  updateActiveTab = (idx: any) => {
    this.setState({ tabIndex: idx }, () => {
      this.setState({ nextTab: null });
    });
  };

  render () {
    const { tabIndex, publishInitiated } = this.state;
    const { isPublishing } = this.props;

    return (
      <div>
        {this.headerMessage}
        <CustomStepTabs
          wrap
          ignorePreferences
          forceTabIndex={tabIndex}
          tabContent={this.tabContent}
          paginate
          type={DEFAULTS.PUBLISHING_PREPARATION_TABS}
          updateTabCallbackKey={getUpdateTabCallbackKey(DEFAULTS.PUBLISHING_PREPARATION_TABS)}
        />
        <Confirm
          header='Cancel Current Job?'
          content='Do you want to cancel and start a new job with your current settings?'
          cancelButton='Continue Current Job'
          confirmButton='Start a New Job'
          open={isPublishing && publishInitiated}
          onConfirm={this.createPackage}
          onCancel={this.cancelInitiation}
        />
      </div>
    );
  }
}
