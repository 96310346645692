import { AllTargets } from '../../layouts/AllTargets';
import { horizontalSampleVideo } from '../defaults/sampleVideos';
import { preview } from './preview';

export const PlayerWithOverlays = {
  defaultAnnotations: [],
  layout: AllTargets,
  preview,
  video: horizontalSampleVideo,
};
