import React from 'react';

import { Combobox } from '@newsela/angelou';

import { GradingRule, GradingRuleOption } from '../../../../../../types/quizUtilityTypes';

import '../index.scss';

type QuizGradingRuleDropdownProps = {
  options: GradingRuleOption[];
  setValue: (gradingRule: GradingRule) => void;
  value: GradingRuleOption;
};

export const QuizGradingRuleDropdown = ({
  options,
  setValue,
  value
}: QuizGradingRuleDropdownProps) => {
  const [defaultRule] = options;

  return (
    <div className='grading-rule-dropdown'>
      <Combobox
        items={options}
        itemToString={(option: GradingRuleOption | null) => option?.text || ''}
        label='Grading Rule'
        selectedItem={value || defaultRule}
        onSelectedItemChange={(selectEvent: any) => setValue(selectEvent.selectedItem)}
      />
    </div>
  );
};
