import './ExternalPreview.scss';
import React, { Component } from 'react';

import { PreviewSection } from './index';

import { comm } from '../../services/comm';
import { Video } from '../Video';

type State = any;

type ExternalPreviewProps = {
  config: any;
};

export class ExternalPreview extends Component<ExternalPreviewProps, State> {
  player: any;
  playerInitState: any;
  previewSection: any;
  constructor (props: ExternalPreviewProps) {
    super(props);
    this.state = {};
    this.playerInitState = {};
    window.addEventListener('message', this.onMessage);
    comm.register('onPlayerAvailable', this.onPlayerAvailable);
    this.sendMessage({ event: 'previewReady' });
  }

  onMessage = ({
    data
  }: any) => {
    switch (data.event) {
      case 'configAvailable':
        this.playerInitState = data.initialState;
        this.setState({ config: data.config });
        break;

      case 'configUpdate':
        this.setState({ config: data.config }, () => {
          comm.trigger('reloadPlayer');
        });

        break;

      case 'snapshotComplete':
        if (this.previewSection) {
          this.previewSection.setState({ loading: false });
        }

        break;

          // no default
    }
  };

  onPlayerAvailable = (player: any) => {
    const { currentTime, language } = this.playerInitState;

    this.player = player;

    if (typeof currentTime !== 'undefined') {
      this.player.currentTime = currentTime;
    }

    if (typeof language !== 'undefined') {
      this.player.language = language;
    }

    this.player.eventBus.on('playerEvent.timeupdate', this.onTimeUpdate);
    this.player.eventBus.on('applicationEvent.setState', this.onStateChange);
  };

  onTimeUpdate = () => {
    const { currentTime } = this.player;

    this.sendMessage({
      event: 'timeUpdate',
      currentTime,
    });
  };

  onStateChange = (stateName: any, language: any) => {
    if (stateName === 'language') {
      this.sendMessage({
        event: 'changeLanguage',
        language,
      });
    }
  };

  sendMessage = (message: any) => window.postMessage(message, '*');

  initiateSnapshot = () => {
    const currentTime = (this.player && this.player.currentTime) || 0;

    const snapshotMessage = {
      event: 'takeSnapshot',
      currentTime,
    };

    this.sendMessage(snapshotMessage);
  };

  render () {
    const previewConfig = {
      config: this.state.config,
      snapshotHandler: this.initiateSnapshot,
      ref: (instance: any) => { this.previewSection = instance; }
    };

    return <ExternalPreviewSection {...previewConfig} />;
  }
}

export class ExternalPreviewSection extends PreviewSection {
  videoInstance: any;
  constructor (props: any) {
    super(props);
    // @ts-expect-error TS(2542): Index signature in type 'Readonly<any>' only permi... Remove this comment to see the full error message
    this.state.previewExpanded = true;
  }

  // @ts-expect-error TS(2416): Property 'videoComponent' in type 'ExternalPreview... Remove this comment to see the full error message
  get videoComponent () {
    const { config } = this;

    if (config) {
      const attrs = {
        config,
        preview: true,
        ref: (instance: any) => { this.videoInstance = instance; }
      };

      return <ExternalVideo {...attrs} />;
    }

    return null;
  }

  expand = () => window.close();

  get config () {
    const { config } = this.props;

    return (
      config && {
        ...config,
        display: {
          ...config.display,
          // since we disable animation in the base `expand` method
          // we need to apply it by default in the external preview
          // since it's always expanded
          animate: false,
        },
      }
    );
  }
}

export class ExternalVideo extends Video {
  el: any;
  get config () {
    return (this.props.config || {
      annotations: [],
    });
  }

  render () {
    return (
      <div>
        <style>
          {`
                        body {
                            background-color: black
                        }

                        #root, #root > div {
                            outline: none;
                        }
                    `}
        </style>
        <div ref={this.el} className='videoPreviewContainer' style={{ fontSize: '14px' }} />
      </div>
    );
  }
}
