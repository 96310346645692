import React from 'react';

import { arbService } from '../../../../services/translation/arbService';
import { TranslationEditor } from '../index';
import { MultiTimeAnnotationList } from '../MultiTimeAnnotationList';

export class ArbEditor extends TranslationEditor {
  retrieveFileContents = async (src: any) => {
    return await arbService.parseFile(src);
  };

  dedupeItems = (data: any, isEditingTranslation: any) => {
    const deduped = {};
    const groupBy = isEditingTranslation ? 'translatedText' : 'text';
    const defaultGroupBy = 'text';

    data.forEach((item: any) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const groupByObject = deduped[item[groupBy]];

      if (groupByObject) {
        groupByObject.startTimes.push(item.start);
        groupByObject.items.push(item);
      } else {
        const id = item[groupBy] ? item[groupBy] : item[defaultGroupBy];
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        deduped[id] = {
          identifier: id,
          startTimes: [item.start],
          text: item.text,
          translatedText: item.translatedText,
          items: [item],
        };
      }
    });

    return deduped;
  };

  reformatItems = (data: any, updatedData: any) => {
    let modifiedData = [...data];
    const ids: any = [];
    const findItem = (id: any) => modifiedData.find((item) => item.identifier === id);

    updatedData.forEach((item: any) => {
      item.ids.forEach((id: any) => {
        const updatedItem = findItem(id);
        ids.push(id);
        updatedItem.text = item.text;
        updatedItem.translatedText = item.translatedText;
      });
    });

    const hasId = (item: any) => ids.includes(item.identifier);
    modifiedData = modifiedData.filter(hasId);

    return modifiedData;
  };

  updateItems = (data: any, updatedData: any) => {
    const formattedData = this.reformatItems(data, updatedData);
    this.updateData(formattedData);
  };

  manipulate = (data: any, isEditingTranslation: any) => {
    const deduped = this.dedupeItems(data, isEditingTranslation);
    const formattedArray: any = [];

    Object.keys(deduped).forEach((item) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const target = deduped[item];
      formattedArray.push({
        identifier: target.identifier,
        startTimes: target.startTimes,
        translatedText: target.translatedText,
        text: target.text,
        ids: target.items.map((item: any) => item.identifier),
      });
    });

    return formattedArray;
  };

  mergeByProperty = (prop: any, baseArray: any, translatedArray: any) => {
    return baseArray.map((item: any) => {
      const translatedItem = translatedArray.find((itemTwo: any) => item[prop] === itemTwo[prop]);
      return {
        ...item,
        translatedText: translatedItem ? translatedItem.text : '',
      };
    });
  };

  sever = (data: any) => {
    return {
      baseLanguage: data.map((cue: any) => {
        const { translatedText, ...filteredData } = cue;
        return filteredData;
      }),
      translatedLanguage: data.map((cue: any) => {
        const { text, translatedText, ...filteredData } = cue;
        filteredData.text = translatedText;
        return filteredData;
      }),
    };
  };

  // @ts-expect-error TS(2416): Property 'listComponent' in type 'ArbEditor' is no... Remove this comment to see the full error message
  listComponent = ({
    data,
    isEditingTranslation,
    duration
  }: any) => {
    return (
      <MultiTimeAnnotationList
        items={this.manipulate(data, isEditingTranslation)}
        data={data}
        isEditingTranslation={isEditingTranslation}
        showTranslation
        duration={duration}
        updateItems={this.updateItems}
      />
    );
  };
}
