import modifiers from './modifiers';

const {
  QuizModifier,
  QuizSectionModifier,
  QuizQuestionModifier,
  QuizAnswerModifier,
  QuizNextModifier,
  QuizBackModifier,
  QuizRetryModifier,
  QuizSubmitModifier,
  QuizProgressModifier,
  QuizFinalMessage,
  QuizConfirmation,
} = modifiers;

const quizModifier = new QuizModifier();
const quizSection = new QuizSectionModifier();
const quizQuestion = new QuizQuestionModifier();
const quizAnswer = new QuizAnswerModifier();
const quizNext = new QuizNextModifier();
const quizBack = new QuizBackModifier();
const quizRetry = new QuizRetryModifier();
const quizSubmit = new QuizSubmitModifier();
const quizProgress = new QuizProgressModifier();
const quizFinalMessage = new QuizFinalMessage();
const quizConfirmation = new QuizConfirmation();

export const getToolTypeModifierForPlayer = (type: any) => {
  const map = {
    quiz: quizModifier,
    question: quizModifier,
    quizSection: quizSection,
    quizQuestion: quizQuestion,
    quizAnswer: quizAnswer,
    quizNext: quizNext,
    quizBack: quizBack,
    quizRetry: quizRetry,
    quizSubmit: quizSubmit,
    quizProgress: quizProgress,
    quizFinalMessage: quizFinalMessage,
    quizConfirmation: quizConfirmation,
  };

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return (type && map[type]) || {};
};
