import _ from 'lodash';

import { Annotation } from '../types/annotations';
import { findAnnotationById } from './toolService';

const DELETE_COUNT = 0; // do not delete spliced array items

const addItem = (arr = [], id: any, atIndex: any) => {
  // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
  if (arr.includes(id)) {
    return arr;
  }

  if (_.isNumber(atIndex)) {
    // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    arr.splice(atIndex, DELETE_COUNT, id);
    return arr;
  }

  return [...arr, id];
};

const removeItem = (arr = [], id: any) => {
  return [...arr.filter((arrItem) => arrItem !== id)];
};

export const updateAnnotationCollectionRelationship = ({
  annotation,
  refAnnotation,
  operation,
  atIndex
}: any) => {
  const { toolType } = refAnnotation;
  const refId = refAnnotation.id;
  const collection = (annotation.collections && annotation.collections[toolType]) || [];

  const collectionRef = operation === 'add' ? addItem(collection, refId, atIndex) : removeItem(collection, refId);

  return {
    ...annotation,
    collections: {
      ...annotation.collections,
      [toolType]: collectionRef,
    },
  };
};

export const updateAppliesToRelationship = ({
  annotation,
  refAnnotation,
  operation,
  atIndex
}: any) => {
  const { appliesTo } = refAnnotation;
  const { id } = annotation;
  const updatedAppliesTo = operation === 'add' ? addItem(appliesTo, id, atIndex) : removeItem(appliesTo, id);

  return {
    ...refAnnotation,
    appliesTo: updatedAppliesTo,
  };
};

const modifyCollectionRelationships = ({
  id,
  refId,
  projectAnnotations,
  operation,
  atIndex
}: any) => {
  const mainAnnotation = { ...findAnnotationById(id, projectAnnotations) };
  const referenceAnnotation = { ...findAnnotationById(refId, projectAnnotations) };

  if (!mainAnnotation.id || !referenceAnnotation.id) return;

  const inputs = {
    annotation: mainAnnotation,
    refAnnotation: referenceAnnotation,
    operation,
    atIndex,
  };

  const updatedAnnotation = updateAnnotationCollectionRelationship({ ...inputs });

  const updatedRefAnnotation = updateAppliesToRelationship({ ...inputs });

  return [updatedAnnotation, updatedRefAnnotation];
};

export const addCollectionRelationshipToAnnotation = (id: any, refId: any, projectAnnotations?: Annotation[], atIndex?: any) => {
  return modifyCollectionRelationships({ id, refId, projectAnnotations, operation: 'add', atIndex });
};

// disassociate annotation references
export const removeSpecifiedReferenceToAnnotation = (id: any, parentId: any, projectAnnotations?: Annotation[]) => {
  const refAnnotation = { ...findAnnotationById(id, projectAnnotations) };

  if (!refAnnotation.appliesTo.length) return [refAnnotation];

  let annotation = findAnnotationById(parentId, projectAnnotations);

  annotation = updateAnnotationCollectionRelationship({
    annotation,
    refAnnotation,
    operation: 'delete',
  });

  refAnnotation.appliesTo = [...refAnnotation.appliesTo.filter((id: string) => id !== parentId)]; // empty applies to

  return [annotation, refAnnotation];
};

// remove all available annotation references
export const removeAvailableReferencesToAnnotation = (id: string, projectAnnotations: Annotation[]) => {
  const refAnnotation = { ...findAnnotationById(id, projectAnnotations) };
  const { collections = {} } = refAnnotation;
  const collectionIds = [..._.uniq(Object.values(collections).flat())] as string[];

  const removeAppliesToRelationships = () => {
    return refAnnotation.appliesTo.map((annotationId: string) => {
      const annotation = findAnnotationById(annotationId, projectAnnotations);

      return updateAnnotationCollectionRelationship({
        annotation,
        refAnnotation,
        operation: 'delete',
      });
    });
  };

  const removeCollectionRelationships = () => {
    return collectionIds.map((annotationId: string) => {
      const annotation = findAnnotationById(annotationId, projectAnnotations);

      return updateAppliesToRelationship({
        refAnnotation: annotation,
        annotation: refAnnotation,
        operation: 'delete',
      });
    });
  };

  const hasAppliesToRelationships = !!_.get(refAnnotation, 'appliesTo.length');
  const hasCollectionRelationships = !!_.get(collectionIds, 'length');

  const updatedAppliesToAnnotations = hasAppliesToRelationships ? removeAppliesToRelationships() : [];
  const updatedCollectionAnnotations = hasCollectionRelationships ? removeCollectionRelationships() : [];

  // Reset `appliesTo` and `collections` bc we deleted references to other annotations
  refAnnotation.appliesTo = [];
  refAnnotation.collections = {};

  return [...updatedAppliesToAnnotations, ...updatedCollectionAnnotations, refAnnotation];
};

export const getCollections = (annotation?: Annotation): string[] => {
  if (!annotation || !annotation.collections) return [];
  return [...new Set(Object.values(annotation.collections).flat())] as string[];
};

export const cascadeFindAssociatedAnnotations = (id: any, projectAnnotations: Annotation[]) => {
  const findAllCollectionDecendents = (id: any) => {
    const collectionDescendants: any = [];

    const traceDescendants = (node: any) => {
      node.forEach((id: any) => {
        collectionDescendants.push(id);

        const annotation = findAnnotationById(id, projectAnnotations);
        const collection = getCollections(annotation);
        if (collection.length) traceDescendants(collection);
      });
    };

    const annotation = findAnnotationById(id, projectAnnotations);
    const collection = getCollections(annotation);
    traceDescendants(collection);

    return collectionDescendants;
  };

  return findAllCollectionDecendents(id);
};

export const removeAppliesToAssociationFromTargetA = (id?: string, annotations?: Annotation[]) => {
  if (!id || !annotations) return;
  const annotation = annotations.find((a: Annotation) => a.id === id);
  if (!annotation) return;
  const { content: aAsTargetId } = annotation;
  const targetAnnotation = annotations.find((a: Annotation) => a.id === aAsTargetId);
  if (targetAnnotation && targetAnnotation.appliesTo) {
    targetAnnotation.appliesTo = [...targetAnnotation.appliesTo].filter((aId) => aId !== id);
  }
  return { annotations, annotation: targetAnnotation };
};

const CHAPTER_MENU = 'chapterMenu';
const CHAPTER = 'chapter';
const QUIZ = 'quiz';
const QUESTION = 'question';
const QUIZ_SECTION = 'quizSection';
const QUIZ_QUESTION = 'quizQuestion';
const QUIZ_ANSWER = 'quizAnswer';

export const toolTypeCollects = (toolType: string) => {
  const mapToolType = {
    [CHAPTER_MENU]: [CHAPTER],
    [QUIZ]: [QUIZ_SECTION],
    [QUESTION]: [QUIZ_SECTION],
    [QUIZ_SECTION]: [QUIZ_QUESTION],
    [QUIZ_QUESTION]: [QUIZ_ANSWER],
  };

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return mapToolType[toolType] || [];
};

const REQUIRES_REMOVAL_OF_COLLECTIONS = [QUIZ, QUESTION, QUIZ_SECTION, QUIZ_QUESTION, QUIZ_ANSWER];
export const requiresRemovalOfCollections = (toolType: any) => REQUIRES_REMOVAL_OF_COLLECTIONS.includes(toolType);
