import React from 'react';

import { Button } from '@newsela/angelou';
import { Confirm, ConfirmProps } from 'semantic-ui-react';

type State = any;

type ConfirmModalProps = ConfirmProps & {
  buttonProps?: any;
  className?: string;
  primary?: boolean;
  confirmCallback?: () => void;
  cancelCallback?: () => void;
}

class ConfirmModal extends React.Component<ConfirmModalProps, State> {
  constructor (props: ConfirmModalProps) {
    super(props);

    this.state = {
      open: props.open || false,
    };
  }

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleCancel = () => {
    this.props.cancelCallback && this.props.cancelCallback();
    this.handleClose();
  };

  handleConfirm = () => {
    this.props.confirmCallback && this.props.confirmCallback();
    this.handleClose();
  };

  style = {
    display: 'inline-block',
  };

  render () {
    const { buttonProps, className = '', confirmCallback, cancelCallback, ...props } = this.props; // remove props that shouldn't be included
    const confirmProps = { ...props };
    delete confirmProps.primary;

    return (
      <div className={`"hy-confirm-modal" ${className}`} style={this.style}>
        <Button
          onClick={this.handleOpen}
          {...buttonProps}
        >
          {buttonProps?.content}
        </Button>
        <Confirm
          open={this.state.open}
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
          {...confirmProps}
        />
      </div>
    );
  }
}

export { ConfirmModal };
