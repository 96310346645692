import React, { Component } from 'react';

import _ from 'lodash';

import { media } from '../../../services/mediaController';
import { LocalizationType } from '../../../types/localization';
import ErrorBanner from '../AngelouComponents/ErrorBanner';

const fileIsReady = (ar = []) => ar.every((item) => (item as any).start >= 0);

type TranslationEditorState = any;

type TranslationEditorProps = {
  allowsBaseFileCreation: any;
  baseLanguage?: any;
  baseObject: any;
  isEditingTranslation: boolean;
  language: any;
  lexileLevel: any;
  onEditorUpdate: any;
  onUpdate: any;
  translatedLanguage?: any;
  translatedObject: any;
  type: LocalizationType;
};

export class TranslationEditor extends Component<TranslationEditorProps, TranslationEditorState> {
  fetchFileTimeout: any;
  save: any;
  constructor (props: TranslationEditorProps) {
    super(props);
    this.fetchFileTimeout = null;
    this.state = {
      baseLanguage: [],
      translatedLanguage: [],
      errorMessage: null,
      errorReportMessage: null
    };
  }

  async componentDidMount () {
    this.fetchFiles();
  }

  componentDidUpdate (prevProps: TranslationEditorProps) {
    if (_.get(prevProps, 'translatedObject.src') !== this.translatedObject.src) {
      this.fetchFiles();
    }
  }

  componentWillUnmount () {
    if (this.fetchFileTimeout) {
      clearTimeout(this.fetchFileTimeout);
    }
  }

  shouldRetry = (fileData: any) => {
    return !fileIsReady(fileData) && !this.fetchFileTimeout;
  };

  retryFileFetch = () => {
    // Work around for file access issue HAP-10553; we believe 1 second is sufficient
    this.fetchFileTimeout = setTimeout(() => {
      this.fetchFiles();
    }, 1000);
  };

  fillEmptyTranslatedLanguageWithBaseValues = (baseLanguage: any, translatedLanguage: any) => {
    const filledTranslated = baseLanguage.map((cue: any) => {
      const match = translatedLanguage.find((elem: any) => elem.identifier === cue.identifier);

      if (match && match.text) {
        return match;
      }

      return { ...cue };
    });

    return filledTranslated;
  };

  async fetchFiles () {
    const { isEditingTranslation } = this.props;
    const baseSrc = this.baseObject.src;
    const translatedSrc = this.translatedObject.src;

    if (!baseSrc && !translatedSrc) return null;

    try {
      const baseLanguage = await this.retrieveFileContents(baseSrc);

      if (this.shouldRetry(baseLanguage)) {
        this.retryFileFetch();
        return;
      }

      let translatedLanguage = [];
      if (translatedSrc) {
        if (isEditingTranslation) {
          // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'any[]'.
          translatedLanguage = await this.retrieveFileContents(translatedSrc);
          translatedLanguage = this.fillEmptyTranslatedLanguageWithBaseValues(
            baseLanguage,
            translatedLanguage
          );
        } else {
          translatedLanguage = (baseLanguage as any).map((cue: any) => {
            const { text, translatedText, ...filteredData } = cue;
            filteredData.text = '';
            return filteredData;
          });
        }
      } else {
        if (isEditingTranslation) {
          translatedLanguage = [...baseLanguage];
        }
      }

      this.setLanguageData(baseLanguage, translatedLanguage);
    } catch (error: any) {
      const errorMessage = `Error: ${error.message}. To start again, delete this file.`;
      this.setLanguageData({ errorMessage });
    }
  }

  setLanguageData = (baseLanguage: any, translatedLanguage?: any) => {
    const { errorMessage = translatedLanguage.errorMessage } = baseLanguage;
    let languageData = {};

    if (errorMessage) {
      languageData = { baseLanguage: [], translatedLanguage: [], errorMessage };
    } else {
      languageData = { baseLanguage, translatedLanguage };
    }

    this.setState(languageData);

    const { onEditorUpdate } = this.props;
    if (onEditorUpdate) onEditorUpdate(languageData);
  };

  mergeByProperty = (prop: any, baseArray: any, translatedArray: any) => {
    return [];
  };

  get baseObject () {
    return this.props.baseObject || {};
  }

  get translatedObject () {
    return this.props.translatedObject || {};
  }

  sever = (data: any): any => {
    return {};
  };

  updateData = (data: any) => {
    return new Promise((resolve) => {
      const { onUpdate } = this.props;
      const { baseLanguage, translatedLanguage } = this.sever(data);
      if (onUpdate) onUpdate({ baseLanguage, translatedLanguage });
      // @ts-expect-error TS(2345): Argument of type '(value: unknown) => void' is not... Remove this comment to see the full error message
      this.setState({ baseLanguage, translatedLanguage }, resolve);
    });
  };

  get rows () {
    const { baseLanguage, translatedLanguage } = this.state;
    const { isEditingTranslation, allowsBaseFileCreation } = this.props;

    if (!baseLanguage.length && !allowsBaseFileCreation) return null;

    const mergedFileData = this.mergeByProperty('identifier', baseLanguage, translatedLanguage);

    return this.listComponent({
      data: mergedFileData,
      isEditingTranslation,
      duration: media.video.duration,
      updateData: this.updateData,
      save: this.save,
    });
  }

  retrieveFileContents = async (src: any) => {
    return '';
  };

  listComponent = ({
    data,
    isEditingTranslation,
    duration,
    updateData,
    save
  }: any) => {
    return '';
  };

  render () {
    const { errorMessage, error } = this.state;

    return (
      <div>
        {!errorMessage ? null : <ErrorBanner body={errorMessage} error={error} />}
        {this.rows}
      </div>
    );
  }
}
