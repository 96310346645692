import React from 'react';

import { StreamSVG } from '@newsela/angelou';
import { Dropdown, DropdownProps, Input, Label, LabelProps, Popup } from 'semantic-ui-react';

type CustomDropdownProps = DropdownProps & {
  popup?: JSX.Element | null;
};
export const CustomDropdown = (customDropdownProps: CustomDropdownProps) => {
  const { popup, error, label, fluid = false, ...props } = customDropdownProps;

  const labelProps: LabelProps = { basic: true, content: label };
  if (error) labelProps.color = 'red';

  const labeled = () => {
    if (popup) {
      return (
        <>
          <Popup
            trigger={
              <StreamSVG />
            }
            content={popup}
          />
          <Input className='labeled' fluid>
            <Label {...labelProps} />
            <Dropdown
              {...props}
              floating
              fluid={fluid}
            />
          </Input>
        </>
      );
    }

    return (
      <Input className='labeled' fluid>
        <Label {...labelProps} />
        <Dropdown
          {...props}
          floating
          fluid={fluid}
        />
      </Input>
    );
  };

  const notLabeled = () => {
    if (popup) {
      return (
        <>
          <Popup
            trigger={
              <StreamSVG />
            }
            content={popup}
          />
          <Dropdown
            {...props}
            fluid={fluid}
          />
        </>
      );
    }

    return (
      <Dropdown
        {...props}
        fluid={fluid}
      />
    );
  };

  return (
    label
      ? labeled()
      : notLabeled()
  );
};
