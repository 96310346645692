import React from 'react';

import { ImageAssetList } from '../CommonComponents/AssetList/ImageAssetsList';
import { VideoAssetList } from '../CommonComponents/AssetList/VideoAssetList';
import { BasicSection } from '../CommonComponents/Menu/BasicSection';

class AssetsEditor extends React.Component {
  render () {
    return (
      <div className='hy-scrollable'>
        <BasicSection
          renderedContent={[
            {
              title: 'Videos',
              renderedContent: <VideoAssetList />,
            },
            {
              title: 'Images',
              renderedContent: <ImageAssetList />,
            },
          ]}
        />
      </div>
    );
  }
}

export { AssetsEditor };
