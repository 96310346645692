import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';

export function initializeDatadog () {
  const rumConfig: RumInitConfiguration = {
    applicationId: process.env.DATADOG_RUM_APPLICATION_ID || '',
    clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN || '',
    site: 'datadoghq.com',
    service: process.env.DATADOG_RUM_SERVICE_NAME || 'studio-dev',
    env: process.env.DATADOG_RUM_ENV || 'dev',
    // Specify a version number to identify the deployed version of your application in Datadog
    // for npm start, use latest dev tag to set process.env.DRONE_TAG using git tag-last-dev
    version: process.env.DATADOG_RUM_VERSION || 'latest',
    sampleRate:
      process.env.DATADOG_RUM_SAMPLE_RATE !== undefined
        ? Number(process.env.DATADOG_RUM_SAMPLE_RATE)
        : 100,
    sessionReplaySampleRate:
      process.env.DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE !== undefined
        ? Number(process.env.DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE)
        : 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    tracingSampleRate:
      process.env.DATADOG_RUM_TRACE_SAMPLE_RATE !== undefined
        ? Number(process.env.DATADOG_RUM_TRACE_SAMPLE_RATE)
        : 100,
    allowedTracingOrigins: [/https:\/\/interactive-video-platform.*newsela.*\.com/],
    defaultPrivacyLevel: 'allow'
  };

  datadogRum.init(rumConfig);
  datadogRum.startSessionReplayRecording();
}
