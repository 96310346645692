import React, { Component } from 'react';

import _ from 'lodash';

import { Dropdown } from '../../../../../hapyak-ui-toolkit';
import { media } from '../../../../../services/mediaController';
import { stateController } from '../../../../../services/stateController';
import { addAnnotationWithAssociations, TOOLS } from '../../../../../services/toolService';
import { toSentenceCase } from '../../../../../services/utils';
import { Annotation } from '../../../../../types/annotations';
import { ToolType } from '../../../../../types/tools';
import { toOptionComplex } from '../../ActionsEditor/utils';
import { WHITE_LISTED_ANNOTATIONS_AS_ANSWERS } from './questionService';

type QuickAddNewAnswerAsAnnotationDropdownProps = {
  createAnnotationWithDefaults: any;
  quizAnnotation: Annotation;
};

export class QuickAddNewAnswerAsAnnotationDropdown extends Component<QuickAddNewAnswerAsAnnotationDropdownProps> {
  addNewAnnotation = (toolType: ToolType) => {
    const { createAnnotationWithDefaults, quizAnnotation = {} as Annotation } = this.props;
    const { start = 0, end = media.duration } = quizAnnotation;

    const res = createAnnotationWithDefaults();

    const target = _.get(TOOLS, `[${toolType}].targets[0]`);

    const stagedSelectedAnnotation = addAnnotationWithAssociations({
      appliesTo: res.annotation.id,
      toolType,
      annotations: res.updatedAnnotations,
      overrides: { target, start, end },
    });

    const answerAnnotationId = res.annotation.id;
    const answerAnnotation = stagedSelectedAnnotation.updatedAnnotations?.find((a) => a.id === answerAnnotationId) || {} as Annotation;
    answerAnnotation.content = stagedSelectedAnnotation.annotation.id;
    answerAnnotation.properties.value = answerAnnotation.internal.name;

    stateController.updateProject('ux', {
      annotations: stagedSelectedAnnotation.updatedAnnotations,
    });
  };

  render () {
    const tools = WHITE_LISTED_ANNOTATIONS_AS_ANSWERS.map((tool) => toOptionComplex(tool, toSentenceCase(tool)));

    return (
      <Dropdown text='Add New Annotation' icon='plus' floating labeled button className='icon'>
        <Dropdown.Menu>
          {tools.map((option) => (
            <Dropdown.Item
              // @ts-expect-error TS(2783): 'key' is specified more than once, so this usage w... Remove this comment to see the full error message
              key={option.value}
              {...option}
              onClick={this.addNewAnnotation.bind(this, option.value)}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
