import * as semanticComponents from 'semantic-ui-react'; // https://react.semantic-ui.com/collections/grid/

import { AutoBlurCheckbox } from './Forms/AutoBlurCheckbox';
import { TinyMCEEditor } from './TinyMCE/TinyMCEEditor';

// re-export semantic-ui-react components so we can modify or replace these if needed;
const Button = semanticComponents.Button;
const Card = semanticComponents.Card;
const Confirm = semanticComponents.Confirm;
const Dropdown = semanticComponents.Dropdown;
const Form = semanticComponents.Form;
const Grid = semanticComponents.Grid;
const Header = semanticComponents.Header;
const Icon = semanticComponents.Icon;
const Image = semanticComponents.Image;
const Input = semanticComponents.Input;
const Label = semanticComponents.Label;
const Menu = semanticComponents.Menu;
const Message = semanticComponents.Message;
const Modal = semanticComponents.Modal;
const Pagination = semanticComponents.Pagination;
const Placeholder = semanticComponents.Placeholder;
const Popup = semanticComponents.Popup;
const Segment = semanticComponents.Segment;
const Select = semanticComponents.Select;
const Tab = semanticComponents.Tab;
const Table = semanticComponents.Table;
const TextArea = semanticComponents.TextArea;
const Step = semanticComponents.Step;

// pass through semantic-ui-react components:
export {
  Button,
  Card,
  Confirm,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Label,
  Menu,
  Message,
  Modal,
  Pagination,
  Placeholder,
  Popup,
  Segment,
  Select,
  Tab,
  Table,
  TextArea,
  Step,
};

// overridden semantic-ui-react components:
export { Section } from './Forms/Section';
export { AutoBlurCheckbox as Checkbox };

export { InfoIcon } from './Forms/InfoIcon';
export { CustomDropdown } from './Forms/Dropdown';
export { ColorPicker } from './Forms/ColorPicker'; // https://casesandberg.github.io/react-color/
export { ConfirmModal } from './ConfirmModal';
export { TinyMCEEditor };
export { CustomDraggable } from './DND/CustomDraggable';
export { Draggable } from './DND/Draggable';
export { DropZone } from './DND/DropZone';
export { CustomInput } from './Forms/CustomInput';
export { CustomTextArea } from './Forms/CustomTextArea';
export { CustomStepTabs } from './Tabs/CustomStepTabs';
export { FileBrowser } from './FileBrowser';
export { SearchBox } from './SearchBox';
