const DEFAULTS = {
  width: 8.5,
  height: 11,
  margin: 0.75,
  imageMargin: 0.25,
};

const settings = { ...DEFAULTS };

const getPdfSettings = (scale: any, inches?: any): any => {
  const _settings = {};
  Object.keys(settings).forEach((key) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    let value = settings[key] * scale;
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
    if (inches) value += 'in';
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    _settings[key] = value;
  });
  return _settings;
};

export { getPdfSettings };
