import React from 'react';

import { JobError } from '../../errors/JobError';
import { gaConfig, ReactGA } from '../../services/gaConfig';
import { ChildrenProps } from '../../types/utils';
import ErrorBanner from '../editors/AngelouComponents/ErrorBanner';

type State = any;

class ErrorBoundary extends React.Component<ChildrenProps, State> {
  constructor (props: ChildrenProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    };
  }

  componentDidCatch (error: JobError, info: any) {
    ReactGA.exception(
      Object.assign(gaConfig.Analytics.Exception, {
        description: `errorName: ${error.name} / errorInfo: ${info && info.componentStack}`,
      })

    );

    ReactGA.event(
      Object.assign(gaConfig.Analytics.Exception, {
        description: `errorName: ${error.name} / errorInfo: ${info && info.componentStack}`,
        label: `errorName: ${error.name} / errorInfo: ${info && info.componentStack}`,
      })
    );
    this.setState({
      hasError: true,
      error: error
    });
  }

  render () {
    const { error } = this.state;
    if (this.state.hasError) {
      return (
        <ErrorBanner
          title={`Error: ${error.name}`}
          body={`Something went wrong. ${error.message}`}
          error={error}
        />
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
