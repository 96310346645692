import '../../AssetsEditor/ProjectTemplate.scss';

import React from 'react';

import { Checkbox, GridLayout } from '@newsela/angelou';

import { Project } from 'types/project';

import { Grid, Image, Input } from '../../../../hapyak-ui-toolkit';
import { getLayouts, getLayoutTargetSizes } from '../../../../layouts';
import { stateController } from '../../../../services/stateController';
import { Annotation } from '../../../../types/annotations';
import { ProjectTemplateCard } from '../../AssetsEditor/ProjectTemplateCard';
import { BasicSection } from '../../CommonComponents/Menu/BasicSection';
import { LayoutConfirmationModal } from '../../LayoutConfirmationModal';
import { SizingEditor } from '../../SizingEditor';

type State = any;

type LayoutOptionsProps = {
  layout: any;
  project: Project;
  showPanels: any;
  toggleShowPanels: any;
};

export class LayoutOptions extends React.Component<LayoutOptionsProps, State> {
  constructor (props: LayoutOptionsProps) {
    super(props);
    const { layout } = stateController.getCurrentData('project').display;
    const { physicalWidth } = layout;

    this.state = {
      layoutEditMode: false,
      layoutEditor: {},
      physicalWidth: physicalWidth || this.defaultBaseWidth,
    };
  }

  get templates () {
    return getLayouts().map((layout, idx) => {
      const { preview } = layout;

      return (
        <ProjectTemplateCard
          preview={preview}
          onClick={this.handleTemplateClick}
          key={idx}
        />
      );
    });
  }

  handleTemplateClick = ({
    preview
  }: any) => {
    const {
      project: { display },
    } = this.props;
    const annotations = stateController.getCurrentData('ux').annotations;
    const currentAdjacentAnnotations = annotations.filter((a: Annotation) => !!a.target);
    const layout = getLayouts().find((layout) => layout.id === preview.id);
    const newAvailableTargets = layout.layoutTargets;
    const names = newAvailableTargets.map((t: any) => t.name);
    const annotationsToUpdateLocationFor = currentAdjacentAnnotations.filter((a: Annotation) => {
      const { target } = a;
      return names && !names.includes(target);
    });

    this.setState({
      layoutEditMode: true,
      layoutEditor: {
        currentTargets: display.layout,
        proposedTargets: getLayoutTargetSizes(layout.layoutTargets),
        chosenLayout: preview.id,
        annotationsToUpdateLocationFor,
      },
    });
  };

  handleMenuReset = () => this.setState({ layoutEditMode: false, layoutEditor: {} });

  onBaseWidthChange = (e: any, data: any) => {
    this.setState({ physicalWidth: data.value });
  };

  get defaultBaseWidth () {
    return (window as any).hy.BASE_WIDTH;
  }

  onBaseWidthBlur = () => {
    const { physicalWidth } = this.state;
    const { layout = {} } = stateController.getCurrentData('project').display;
    const previousWidth = (layout && layout.physicalWidth) || this.defaultBaseWidth;

    if (!physicalWidth) {
      this.setState({
        physicalWidth: previousWidth || this.defaultBaseWidth,
      });
    }

    this.setPhysicalWidth(physicalWidth || previousWidth);
  };

  setPhysicalWidth = (physicalWidth: any) => {
    const { layout } = this.props.project.display;

    stateController.updateProject('project', { display: { layout: { ...layout, physicalWidth } } });
  };

  get baseWidthInput () {
    const { physicalWidth } = this.state;

    return (
      <Input
        label='Base Width'
        labelPosition='left'
        style={{ width: '37.2%' }}
        onBlur={this.onBaseWidthBlur}
        onChange={this.onBaseWidthChange}
        fluid
        type='number'
        value={physicalWidth}
      />
    );
  }

  get layoutSection () {
    const { layoutEditMode, layoutEditor } = this.state;
    const { project, layout, showPanels } = this.props;
    const { display } = project;
    const {
      preview: { description = '' },
    } = layout;
    const rowStyle = { padding: 0 };

    return (
      <div>
        <BasicSection renderedContent={[
          {
            title: 'Dimensions',
            renderedContent: (
              <Grid stackable className='hy-form-field'>
                <Grid.Row>{this.baseWidthInput}</Grid.Row>
              </Grid>
            ),
          },
          {
            title: `Current Template: ${description}`,
            renderedContent: (
              <Grid stackable className='hy-form-field'>
                <Checkbox
                  checked={showPanels}
                  label='Show panels'
                  onChange={this.props.toggleShowPanels}
                  __cssFor={{
                    root: {
                      padding: '12px 0'
                    }
                  }}
                />
                <Grid.Row style={rowStyle}>
                  <Grid.Column style={rowStyle} width={6}>
                    <Grid.Row style={{ marginBottom: '10px' }}>Panels:</Grid.Row>
                    <Grid.Row>
                      <SizingEditor display={display} layout={layout} hideNoun />
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Image style={{ maxWidth: '200px', margin: 'auto' }} src={layout.preview.image} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            ),
          },
          {
            title: 'Change Template',
            renderedContent: null
          }
        ]}
        />
        {this.templates.length > 0 &&
          <GridLayout __classNameFor={{ root: 'project-template-3-col-grid' }}>
            {this.templates}
          </GridLayout>}
        {layoutEditMode && <LayoutConfirmationModal {...layoutEditor} handleMenuReset={this.handleMenuReset} />}
      </div>
    );
  }

  render () {
    return <>{this.layoutSection}</>;
  }
}
