import './AngelouLoadingSpinner.scss';

import React from 'react';

import { LoadingSpinner } from '@newsela/angelou';

export const AngelouLoadingSpinner = ({
  fullScreenDimmer = true,
  text = 'Loading',
  transparent = true,
  inverted = true
}) => {
  return (
    <div className={
      fullScreenDimmer
        ? inverted
          ? 'full-screen-dimmer inverted'
          : 'full-screen-dimmer'
        : ''
    }
    >
      <div className={transparent ? 'loading-spinner' : 'loading-spinner white-bg'}>
        <LoadingSpinner
          title='Loading'
          __cssFor={{
            root: {
              alignSelf: 'center',
              display: 'flex'
            }
          }}
        />
        <p className={inverted ? 'black-text' : 'white-text'}>{text}</p>
      </div>
    </div>
  );
};
