import React from 'react';

import { SemanticICONS } from 'semantic-ui-react';

import { Icon, Popup } from '../../../../hapyak-ui-toolkit';

type ToolbarButtonProps = {
  active?: boolean;
  alt?: string | boolean;
  disabled?: boolean;
  icon: SemanticICONS;
  onClick: () => void;
  tooltip: string;
};

export class ToolbarButton extends React.Component<ToolbarButtonProps> {
  onClick = () => {
    const { disabled, onClick } = this.props;
    if (!disabled && typeof onClick === 'function') onClick();
  };

  render () {
    const { active, alt, disabled, icon, tooltip } = this.props;
    const className = `hy-toolbar-button ${disabled ? 'hy-toolbar-button-disabled' : ''}`;
    const activeClassName = `hy-toolbar-button-active${alt ? '-alt' : ''}`;

    return (
      <Popup
        trigger={
          <div className={`${className} ${active ? activeClassName : ''}`} onClick={this.onClick}>
            <Icon name={icon} fitted />
          </div>
        }
        content={tooltip}
        position='right center'
        inverted
      />
    );
  }
}
