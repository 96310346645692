import React from 'react';

import { vttService } from '../../../../services/translation/vttService';
import { CaptionsList } from '../../AccessibilityEditor/CaptionsList';
import { TranslationEditor } from '../index';

export class VttEditor extends TranslationEditor {
  retrieveFileContents = async (src: any) => {
    return await vttService.parseFile(src);
  };

  mergeByProperty = (prop: any, baseArray: any, translatedArray: any) => {
    return baseArray.map((item: any) => {
      const translatedItem = translatedArray.find((itemTwo: any) => item[prop] === itemTwo[prop]);
      return {
        ...item,
        start: translatedItem ? translatedItem.start : item.start,
        end: translatedItem ? translatedItem.end : item.end,
        translatedText: translatedItem ? translatedItem.text : '',
      };
    });
  };

  sever = (data: any) => {
    const { isEditingTranslation } = this.props;
    const { baseLanguage } = this.state;

    let baseLangRet = baseLanguage;
    if (!isEditingTranslation) {
      baseLangRet = data.map((cue: any) => {
        const { translatedText, ...filteredData } = cue;
        return filteredData;
      });
    }

    return {
      baseLanguage: baseLangRet,
      translatedLanguage: data.map((cue: any) => {
        const { text, translatedText, ...filteredData } = cue;
        filteredData.text = translatedText;
        return filteredData;
      }),
    };
  };

  // @ts-expect-error TS(2416): Property 'listComponent' in type 'VttEditor' is no... Remove this comment to see the full error message
  listComponent = ({
    data,
    isEditingTranslation,
    duration,
    updateData,
    save
  }: any) => {
    return (
      <CaptionsList
        cues={data}
        isEditingTranslation={isEditingTranslation}
        hideAdditionalAction
        showTranslation
        duration={duration}
        updateCues={updateData}
        save={save}
      />
    );
  };
}
