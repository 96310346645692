import React from 'react';

import { comm } from '../../services/comm';
import { media } from '../../services/mediaController';

type State = any;

type VideoStateMessageProps = {
  message: string;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
};

export class VideoStateMessage extends React.Component<VideoStateMessageProps, State> {
  constructor (props: VideoStateMessageProps) {
    super(props);

    this.state = {
      show: !this.isMp4,
    };
  }

  componentDidMount () {
    comm.register('updateMp4', this.updateMp4);
  }

  componentWillUnmount () {
    comm.unregister('updateMp4', this.updateMp4);
  }

  get isMp4 () {
    return media.formats.mp4;
  }

  updateMp4 = () => {
    this.setState({ show: !this.isMp4 });
  };

  get style (): React.CSSProperties {
    return {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
      backgroundColor: '#000',
    };
  }

  get textStyle (): React.CSSProperties {
    return {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      color: '#fff',
      fontSize: '24px',
    };
  }

  render () {
    const { message } = this.props;

    if (!this.state.show) return null;

    return (
      <div style={this.style}>
        <div style={this.textStyle} className='hy-animation-ellipsis'>
          {message}
        </div>
      </div>
    );
  }
}
