import _ from 'lodash';
import mime from 'mime';

import DEFAULTS from '../DEFAULTS.json';
import sessionClient from './sessionClient';

function clamp (min: any, max: any, val: any) {
  return Math.min(max, Math.max(min, val));
}

function getDropdownOptionsFromObject (obj: any, textKey: any) {
  const options: any = [];

  Object.keys(obj).forEach((key) => {
    const data = obj[key];

    options.push({
      key: key,
      text: textKey ? data[textKey] : data, // retrieve a sub prop?
      value: key,
    });
  });

  return options;
}

const toOption = (v: string, options?: any) => ({
  key: v,
  value: v,
  text: toSentenceCase(v, options)
});

function deepEquals (a: any, b: any) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function resolvePlatformHost () {
  // if (process.env.REACT_APP_USE_LOCAL_DYNAMODB) {
  //     return 'https://platform-dev.hapyak.com/development/api/v1';
  // }
  //
  return 'https://' + process.env.REACT_APP_PLATFORM_HOST;
}

export function resolveAppHost () {
  return 'https://' + process.env.REACT_APP_HOST;
}

export const resolvePlayer = () => {
  const resolved = {
    origin: `${process.env.REACT_APP_PLAYER_ORIGIN}/video-player`,
    cloudfront: `${process.env.REACT_APP_PLAYER_CLOUDFRONT_HOST}/video-player`,
  };

  if (sessionClient.isNewselaUser()) {
    resolved.origin = `${process.env.REACT_APP_NEWSELA_PLAYER_ORIGIN}/video-player`;
    resolved.cloudfront = `${process.env.REACT_APP_NEWSELA_PLAYER_CLOUDFRONT}/video-player`;
  }

  return resolved;
};

export function resolvePlayerHost (version: any) {
  const { cloudfront } = resolvePlayer();

  // if (version) {
  //     return `https://${cloudfront}/v${version}`;
  // }

  return 'https://' + cloudfront;
}

export function resolvePlayerOrigin (version: any) {
  const { origin } = resolvePlayer();

  // if (version) {
  //     return `https://${origin}/v${version}`;
  // }

  return 'https://' + origin;
}

const splitUnderscore = (str: any) => {
  return str.split(/(?=[_])/).map((word: any) => word.replace(/_/g, ''));
};

export const trimEmptyItems = (list: any) => list.map((val: any) => val.trim ? val.trim() : val);

function splitCamelCase (str: string) {
  return `${str}`.split(/(?=[A-Z])/);
}

function toSentenceCase (str: any, options: { split?: any } = {}) {
  const { split } = options;
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const splitOn = splitOnMap[split] || splitCamelCase;

  return splitOn(str)
    .map((s: string, idx: number) => {
      s = s.toLowerCase();
      return idx === 0 ? s.charAt(0).toUpperCase() + s.slice(1).trim() : s;
    })
    .join(' ');
}

export const removeDoubleSpaces = (str: string) => {
  return str.split('  ').map((word: string) => word.trim()).join(' ');
};

export const toHyphenated = (str: string) => {
  return toSentenceCase(str)
    .toLowerCase()
    .split(' ')
    .join('-');
};

const splitOnMap = {
  camelCase: splitCamelCase,
  underscore: splitUnderscore,
};

function downloadToComputer (href: any, name: any) {
  const link = document.createElement('a');
  link.href = href;
  link.download = name.replace(/\s/g, '');
  link.target = '_blank';
  link.rel = 'noopener noreferrer'; // security for target="_blank"
  link.click();
}

function interpolate (str: any, params: any) {
  if (typeof str !== 'string') return;
  const keys = Object.keys(params);

  keys.forEach((key) => {
    const variable = '${' + key + '}';
    const split = str.split(variable);
    str = split.join(params[key]);
  });

  return str;
}

export const toSeconds = (n: any, duration: any) => {
  const num = +n.replace('%', '');
  return (num / 100) * duration;
};

export const toPercent = (n: number, max: number): string => {
  if (!n || !max) return '0%';
  return 100 * (n / max) + '%';
};

export const toPixels = (n: any, max: any) => {
  if (!n || !max) return 0;

  if (typeof n === 'string') {
    if (n.includes('%')) return toDecimal(n) * max;
    if (n.includes('px')) return +n.replace('px', '');
  }

  return n;
};

export const toDecimal = (n: any) => {
  return +n.replace('%', '') / 100;
};

export const sortByNumber = (a: any, b: any) => {
  return +a < +b ? -1 : +a > +b ? 1 : 0;
};

export const isRegex = (str: any) => {
  try {
    return new RegExp(str);
  } catch (e) {
    return false;
  }
};

export const getUpdateTabCallbackKey = (key: any) => {
  return `${DEFAULTS.UPDATE_TAB_KEY}${_.camelCase(key)}`;
};

export const stopEvent = (e: any) => {
  e.stopPropagation();
  e.preventDefault();
};

export const getFileName = (src: string): string => {
  const nameWithExtension = splitFolderAndFile(src)[1];
  return nameWithExtension?.split('.')[0] || '';
};

export const splitFolderAndFile = (src: string): string[] => {
  const split = src?.split('/') || [];
  const file = split?.splice(-1) || [];
  return [split?.join('/'), file?.[0]];
};

export const getExtension = (src: string): string => {
  const withoutParams = src?.split('?')[0];
  return withoutParams?.split('.').slice(-1)[0] || '';
};

export const getSearch = (url: string): string => {
  return url?.split('?')[1];
};

export const getMimeType = (ext: string) => {
  const mimeExceptions = {
    m3u8: 'application/x-mpegURL',
    arb: 'application/json',
  };

  if (ext in mimeExceptions) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return mimeExceptions[ext];
  }

  return mime.getType(ext) || 'application/octet-stream';
};

export const parseQueryString = (url: string) => {
  return new URLSearchParams(getSearch(url));
};

export const serialize = (subject: any) => {
  let serialized = {};

  try {
    serialized = JSON.parse(JSON.stringify(subject));
  } catch (e) {
    console.warn('error serializing', e);
  } finally {
    const serial = function () {
      return serialized;
    };
    serial();
  }
};

export const convertToS3URL = (url: any) => {
  if (url.includes('s3://')) {
    return url; // assume no changes needed
  }
  if (!url.includes('.s3.')) {
    return null;
  }
  const parsed = new URL(getAbsoluteUrl(url));
  const bucket = parsed.host.split('.')[0];
  return `s3://${bucket}${parsed.pathname}`;
};

// deal with relative urls ('//sub.domain.com')
export const getAbsoluteUrl = (url: any) => {
  const a = document.createElement('a');
  a.href = url;
  return a.href;
};

export const overrideCurrentAnnotationActions = (copy: any) => {
  const { id = '', actions } = copy;

  if (!id) {
    return;
  }

  copy.actions = actions.map((a: any) => {
    const { args = {} } = a;
    if (!args.isSelf) {
      return a;
    }
    args.value = id;
    return a;
  });

  return copy;
};

export {
  clamp,
  downloadToComputer,
  toOption,
  getDropdownOptionsFromObject,
  deepEquals,
  interpolate,
  toSentenceCase,
  splitCamelCase,
};
