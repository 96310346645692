import React, { Component } from 'react';

import { Button } from '../../../../hapyak-ui-toolkit';

type State = any;

type FilterButtonProps = {
  handleClick: (e: any, data: any) => void;
  isActive: boolean;
  text: string;
};

class FilterButton extends Component<FilterButtonProps, State> {
  constructor (props: FilterButtonProps) {
    super(props);
    this.state = { isActive: false };
  }

  componentDidMount () {
    const { isActive } = this.props;
    this.setState({ isActive });
  }

  onClick = (evt: any, data: any) => {
    const { handleClick } = this.props;
    if (typeof handleClick === 'function') handleClick(evt, data);
    this.setState({ isActive: true });
  };

  render () {
    const { text, isActive } = this.props;

    return (
      <Button primary={isActive} compact size='mini' onClick={this.onClick}>
        {text}
      </Button>
    );
  }
}

export { FilterButton };
