import '../index.scss';

import React, { useState, useEffect } from 'react';

import { assetService } from '../../../../../../services/stateController';
import { Annotation, AnnotationProperties } from '../../../../../../types/annotations';
import { AngelouInput } from '../../../../AngelouComponents/AngelouInput';
import { ImagePicker } from '../../../../CommonComponents/ImagePicker';
import { MAX_CHARACTER_INPUT_LENGTH } from '../questionService';

type ShortAnswerEditorProps = {
  annotation: Annotation;
  onChange: (annotationId: string, properties: AnnotationProperties) => void;
};

export const ShortAnswerEditor = ({
  annotation,
  onChange
}: ShortAnswerEditorProps) => {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [thumbnail, setThumbnail] = useState('');

  useEffect(() => {
    const validationChangedMinMaxValues = validateMinMax(min, max);

    if (!validationChangedMinMaxValues || thumbnail !== annotation.properties.thumbnail) {
      const newAnnotationProperties: AnnotationProperties = {
        ...annotation.properties,
        min: +min,
        max: +max,
        thumbnail: thumbnail,
      };

      onChange(annotation.id, newAnnotationProperties);
    }
  }, [max, min, thumbnail]);

  useEffect(() => {
    if (annotation) {
      const { min = 0, max = 0, thumbnail = '' } = annotation.properties;

      setMax(max);
      setMin(min);
      setThumbnail(thumbnail);
    }
  }, []);

  const validateMinMax = (min: number, max: number) => {
    const newMax = +min > +max ? +min + 1 : +max;
    let newMin = +min;

    if (newMax < newMin) {
      const tempMin = newMax - 1;
      newMin = tempMin < 0 ? 0 : tempMin;
    }

    if (newMin !== +min || newMax !== +max) {
      setMin(newMin);
      setMax(newMax);
      return true;
    }

    return false;
  };

  const shortAnswerImagePicker = () => {
    return (
      <ImagePicker
        messageAsIcon
        triggerAsLink
        label={false}
        image={assetService.getImage(thumbnail)}
        setImage={(image) => setThumbnail(image.id)}
      />
    );
  };

  return (
    <div className='quiz-answer-table'>
      <div className='min-max-input-row'>
        <AngelouInput
          min={0}
          label='Min character'
          value={min}
          maxLength={MAX_CHARACTER_INPUT_LENGTH}
          placeholder='Min'
          inputType='number'
          setValue={setMin}
          css={{
            root: {
              maxWidth: '112px',
              marginRight: '12px'
            }
          }}
        />
        <AngelouInput
          min={0}
          label='Max character'
          value={max}
          maxLength={MAX_CHARACTER_INPUT_LENGTH}
          placeholder='Max'
          inputType='number'
          setValue={setMax}
          css={{
            root: {
              maxWidth: '112px',
              marginLeft: '12px'
            }
          }}
        />
      </div>
    </div>
  );
};
