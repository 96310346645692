import _ from 'lodash';

import { layoutMap } from './layoutMap';

const DEFAULT_ID = 1;

const filterForTargets = (section: any) => section._target;

export const targetNameFormatted = (name: any) => {
  const ADJACENT_TARGET_PREFIX = 'adjacent';
  const label = name.replace(new RegExp(ADJACENT_TARGET_PREFIX), '');
  return `${_.startCase(label)} %`;
};

export const getLayout = (id: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const layout = layoutMap[id] || layoutMap[DEFAULT_ID];
  return JSON.parse(JSON.stringify(layout));
};

export const getLayouts = () => {
  return Object.keys(layoutMap).map(getLayout);
};

export const getSizableLayoutTargets = (layoutTargets: any) => {
  return layoutTargets.filter((section: any) => !!section._dimension);
};

export const getLayoutTargetSize = (layoutTarget: any) => {
  return layoutTarget[layoutTarget._dimension];
};

export const getLayoutTargets = (id: any) => {
  return getLayout(id).layoutTargets.filter(filterForTargets);
};

const toPlayerRelativePercent = (layoutTargets: any, overrides: any, name: any) => {
  const group = getDimensionalGroup(layoutTargets, name);
  const groupPercent = group.reduce((sum: any, _name: any) => sum + overrides[_name], 0);
  const playerPercent = 1 - groupPercent;
  const ratio = playerPercent / 100;
  return overrides[name] / ratio;
};

export const prepareLayoutForPlayer = (layoutTargets: any, overrides: any) => {
  return layoutTargets.map((layoutTarget: any) => {
    const { _dimension, name } = layoutTarget || {};
    const overrideValue = overrides[name];

    if (_dimension && overrideValue) {
      layoutTarget[_dimension] = toPlayerRelativePercent(layoutTargets, overrides, name);
    }

    const omissions = Object.keys(layoutTarget).filter((key) => key.startsWith('_'));
    return _.omit(layoutTarget, omissions); // remove props that start with "_"    });
  });
};

export const ADJACENT_TARGETS = ['adjacent_top', 'adjacent_bottom', 'adjacent_left', 'adjacent_right'];

export const getLayoutTargetSizes = (layoutTargets: any) => {
  // set all standard targets to 0, then override with layout target values
  const sizes = ADJACENT_TARGETS.reduce((obj, item) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    obj[item] = 0;
    return obj;
  }, {});

  getSizableLayoutTargets(layoutTargets).forEach((layoutTarget: any) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    sizes[layoutTarget.name] = getLayoutTargetSize(layoutTarget);
  });

  return sizes;
};

export const getDimensionalGroup = (layoutTargets: any, name: any) => {
  const dimensionalGroups = getDimensionalGroups(layoutTargets);
  const layoutTarget = layoutTargets.find((target: any) => target.name === name);
  const dimension = layoutTarget._dimension;
  return dimensionalGroups[dimension] || [];
};

export const getDimensionalGroups = (layoutTargets: any) => {
  // returns object, keyed by _dimension, with values equal to an array of target names that use that dimension
  return getSizableLayoutTargets(layoutTargets).reduce((groups: any, layoutTarget: any) => {
    groups[layoutTarget._dimension] = [...(groups[layoutTarget._dimension] || []), layoutTarget.name];
    return groups;
  }, {});
};
