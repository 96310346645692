import hash from 'hash-sum';
import _ from 'lodash';

import { clamp } from './utils';

const DEFAULT_LIMIT = 100;

export class Historian {
  // singleton used to communicate across components
  static LIMIT: any;

  _position: any;
  defaultState: any;
  history: any;

  constructor (defaultState = {}, limit = DEFAULT_LIMIT) {
    Historian.LIMIT = limit;
    this.reset(defaultState);
  }

  // Helpers:
  get length () {
    return this.history.length;
  }

  clamp = (val: any) => {
    return clamp(0, this.length - 1, val);
  };

  get position () {
    return this.clamp(this._position);
  }

  isEntryNew = (entry: any) => {
    return hash(this.getCurrentEntry()) !== hash(entry);
  };

  // CRUD:
  getEntry = (position: any) => {
    return _.cloneDeep(this.history[position]);
  };

  // create
  newEntry = (newData: any) => {
    const entry = _.cloneDeep(newData);
    if (!this.isEntryNew(entry)) return;

    const pos = this.position + 1;
    this.history.splice(pos, this.length - pos, entry);
    if (this.length > Historian.LIMIT) this.history.shift();
    this._position = this.length - 1;
  };

  // update
  replaceEntry = (position: any, newData: any) => {
    this.history[position] = _.cloneDeep(newData);
  };

  updateEntry = (position: any, path: any, value: any) => {
    this.replaceEntry(position, {
      ...this.getEntry(position),
      [path]: value,
    });
  };

  updateAllEntries = (path: any, value: any) => {
    this.history.forEach((entry: any, position: any) => {
      this.updateEntry(position, path, value);
    });
  };

  // delete
  reset = (defaultState: any) => {
    this.defaultState = this.defaultState || defaultState;
    this.history = [this.defaultState];
    this._position = 0;
  };

  // Current entry:
  getCurrentEntry = () => {
    return this.getEntry(this.position);
  };

  replaceCurrentEntry = (entry: any) => {
    this.replaceEntry(this.position, entry);
  };

  updateCurrentEntry = (path: any, value: any) => {
    this.updateEntry(this.position, path, value);
  };

  // Navigation:

  undo = () => {
    this.bumpHistory(-1);
  };

  redo = () => {
    this.bumpHistory(1);
  };

  bumpHistory = (dir: any) => {
    this._position = this.position + dir;
  };

  get canUndo () {
    return this.position > 0;
  }

  get canRedo () {
    return this.position < this.length - 1;
  }
}
