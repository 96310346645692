import React from 'react';

import hash from 'hash-sum';

import { ISO_DROPDOWN_OPTIONS } from '../../components/editors/AssetsEditor/isoLanguageCodes';
import DEFAULTS from '../../DEFAULTS.json';
import { Icon } from '../../hapyak-ui-toolkit';
import { LocalizationType } from '../../types/localization';
import { VTT } from '../../types/vtt';
import { LEXILE_LEVEL_OPTIONS } from '../lexileService';
import { media } from '../mediaController';
import { assetService } from '../stateController';
import { toSentenceCase } from '../utils';
import { arbService } from './arbService';
import { vttService } from './vttService';

export const getFileType = (type: LocalizationType) => {
  const fileTypeMap = {
    [DEFAULTS.ARB_ANNOTATION_TEXT]: 'arb',
    [DEFAULTS.ARB_VIDEO_TEXT]: 'arb',
    [DEFAULTS.VTT_CAPTIONS]: 'vtt',
  };

  return fileTypeMap[type];
};

export const getService = (type: LocalizationType) => {
  const fileTypeMap = {
    [DEFAULTS.ARB_ANNOTATION_TEXT]: arbService,
    [DEFAULTS.ARB_VIDEO_TEXT]: arbService,
    [DEFAULTS.VTT_CAPTIONS]: vttService,
  };

  return fileTypeMap[type];
};

export const hashData = (data: any, props: any) => {
  const hashString = (data: any) => props.map((i: any) => data && data[i]).join('');
  const { baseLanguage, translatedLanguage } = data;
  const hashedValues = (data: any) => hashString(data);
  const baseData = baseLanguage.map(hashedValues);
  const translatedData = translatedLanguage.map(hashedValues);
  return hash([baseData, translatedData].flat());
};

export const createOrderedLanguageOptions = (language: any, languages: any) => {
  return createOrderedAssetOptions('language', language, languages, ISO_DROPDOWN_OPTIONS);
};

export const createOrderedLexileLevelOptions = (lexileLevel: any, lexileLevels: any) => {
  return createOrderedAssetOptions('lexileLevel', lexileLevel, lexileLevels, LEXILE_LEVEL_OPTIONS);
};

export const createOrderedAssetOptions = (property: any, selectedValue: any, uploadedFileValues: any, allFileOptions: any) => {
  const videoPropertyValue = media.video[property];
  const hasAsset = (isoItem: any) => uploadedFileValues.includes(isoItem.value) ? -1 : 0;
  const isNative = (isoItem: any) => isoItem.value === videoPropertyValue ? -1 : 0;

  const arbs = assetService.getArbArray();
  const vtts = assetService.getVttArray();

  const assetsTypeMap = {
    captions: vtts,
    annotationText: arbs.filter((a: any) => a.type === DEFAULTS.ARB_ANNOTATION_TEXT),
    videoText: arbs.filter((a: any) => a.type === DEFAULTS.ARB_VIDEO_TEXT),
  };

  if (!uploadedFileValues) uploadedFileValues = [...arbs, ...vtts].map((file) => file[property]);

  return allFileOptions
    .sort(hasAsset)
    .sort(isNative)
    .map((item: any) => {
      const { value } = item;

      if (!uploadedFileValues.includes(value)) return item;

      const videoIcon = <Icon style={{ margin: '0 0 0 5px' }} name='video' color='green' />;

      return {
        ...item,
        description: (
          <span style={{ fontSize: '10px' }}>
            {getFileTypesForProperty(assetsTypeMap, property, value).join(', ')}
            {value === videoPropertyValue && videoIcon}
          </span>
        ),
      };
    });
};

const getFileTypesForProperty = (assetsTypeMap: any, property: any, value: any) => {
  return Object.keys(assetsTypeMap).reduce((typesArray, key) => {
    const assets = assetsTypeMap[key];

    if (assets.find((a: any) => a[property] === value)) {
      // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      typesArray.push(toSentenceCase(key));
    }

    return typesArray;
  }, []);
};

export const syncFiles = async (files: any, fileType: any, service: any, baseFileSrc: any, projectId: any, lexileLevel: any) => {
  const baseItems = await service.parseFile(baseFileSrc);

  const baseItemData = baseItems.map((item: any) => {
    const { identifier, start, end, styles } = item;
    return { identifier, start, end, styles };
  });

  for (const file of files) {
    const { src, language, videoId, type } = file;

    let translatedItems = await service.parseFile(src);

    translatedItems = service.updateItemData(translatedItems, baseItemData);

    const translatedFile = await service.createFileFromData({ src, items: translatedItems });

    const srcLocation = await service.upload({
      projectId,
      items: translatedItems,
      file: translatedFile,
      selectedType: type,
      selectedLanguage: language,
      existingSrc: !!src,
      lexileLevel,
    });

    // TODO: lexileLevel when syncing files
    service.setAsset({ language, type, videoId, src: srcLocation });
  }

  return true;
};

export const getTranscriptsFromVTT = (vttArr: VTT[]) => {
  if (!vttArr.length) return [];

  return vttArr.map((vtt: VTT) => ({
    lexileLevel: vtt.lexileLevel,
    language: vtt.language
  }));
};
