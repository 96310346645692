import './index.scss';

import React, { Component } from 'react';

import { AxiosResponse } from 'axios';
import _ from 'lodash';

import { Embed } from 'types/publishing';

import DEFAULTS from '../../../DEFAULTS.json';
import { JobError } from '../../../errors/JobError';
import { CustomStepTabs } from '../../../hapyak-ui-toolkit';
import { comm } from '../../../services/comm';
import { groupConfig } from '../../../services/configurationService';
import { isBundleOutDated } from '../../../services/packagingService';
import { getCurrentBundle } from '../../../services/persistence/platformService';
import { assetService } from '../../../services/stateController';
import { getUpdateTabCallbackKey } from '../../../services/utils';
import ErrorBanner from '../AngelouComponents/ErrorBanner';
import { PublishingDataTable } from '../AssetsEditor/PublishingDataTable';
import { CancelableStatus } from '../CommonComponents/CancelableStatus';
import { EmbedStyleAndLayoutSection } from './EmbedStyleAndLayoutSection';
import { PACKAGING_OPTIONS, PUBLISH_TO_OPTIONS } from './publishingOptions';
import { PublishingPreparation } from './PublishingPreparation';

const TABS = {
  PREPARE: {
    name: 'Prepare',
    tabIndex: 0,
  },
  EMBED: {
    name: 'Output',
    tabIndex: 1,
  },
};

type State = any;

type PackagingEditorProps = {
  embed: Embed;
};

export class PackagingEditor extends Component<PackagingEditorProps, State> {
  constructor (props: PackagingEditorProps) {
    super(props);

    this.state = {
      isPublishing: false,
      error: null,
      bundleId: assetService.getCurrentBundle().id,
      packageModalOpen: false,
      tabIndex: null,
    };
  }

  get bundle () {
    const bundle = assetService.getBundle(this.state.bundleId);
    return bundle.packaging ? bundle : null;
  }

  async componentDidMount () {
    if (!this.state.bundleID) await this.fetch();
  }

  fetch = async () => {
    return new Promise((resolve) => {
      this.setState({ loading: true }, async () => {
        try {
          const { data }: AxiosResponse = await getCurrentBundle();
          const bundle = data.body.properties;
          // @ts-expect-error TS(2345): Argument of type '(value: unknown) => void' is not... Remove this comment to see the full error message
          this.setState({ bundle, loading: false, firstLoad: false }, resolve);
        } catch (error) {}
      });
    });
  };

  updateBundle = () => {
    // force reset the bundle prop
    return new Promise((resolve) => {
      this.setState({ bundleId: null }, () => {
        // @ts-expect-error TS(2345): Argument of type '(value: unknown) => void' is not... Remove this comment to see the full error message
        this.setState({ bundleId: assetService.getCurrentBundle().id }, resolve);
      });
    });
  };

  chooseBundle = (e: any, {
    value
  }: any) => {
    this.setState({ bundleId: value });
  };

  handleDismissError = () => this.setState({ error: null });

  setPublishing = (isPublishing: any) => {
    const updates = { isPublishing };

    if (isPublishing) {
      (updates as any).error = null;
    }

    this.setState(updates);
  };

  get packagingOptions () {
    // apply configuration / provisioning here
    return PACKAGING_OPTIONS.filter((option) => {
      return !!this.getPublishingOptions(option).filter((opt) => !opt.disabled).length;
    });
  }

  createPublishingOption = (key: any, target: any) => {
    const { displayName = '', settingsBlacklist = [] } = target.default;
    return { key, text: displayName, value: key, settingsBlacklist };
  };

  get configuredPublishingOptions () {
    // add custom publishing options
    const configuredTargets = groupConfig.get('publishing.targets', {}); // what is in configuration
    const provisionedTargets = groupConfig.allow('publishing.targets', {}); // what is in provisioning
    const allowedTargets = _.keys(configuredTargets).filter((key) => !!provisionedTargets[key]); // what is true in provisioning
    return allowedTargets.map((key) => this.createPublishingOption(key, configuredTargets[key]));
  }

  getPublishingOptions = (packagingOption = {}) => {
    // apply configuration / provisioning here
    const availableOptions = (packagingOption as any).publishing || [];
    let defaultOptions = PUBLISH_TO_OPTIONS.filter((option) => availableOptions.includes(option.key));

    if ((packagingOption as any).extendable) {
      // @ts-expect-error TS(2322): Type '({ key: string; text: string; value: string;... Remove this comment to see the full error message
      defaultOptions = [...this.configuredPublishingOptions, ...defaultOptions]; // add to beginning, as these are the most likely to be used
    }

    return defaultOptions;
  };

  get bundleOutdated () {
    return isBundleOutDated(this.bundle);
  }

  handleNewPackageClick = () => {
    this.updateActiveTab(TABS.PREPARE.tabIndex);
  };

  get canShowEmbed () {
    return this.bundle && (this.bundle.customSrc || this.bundle.src);
  }

  onPublished = (error: JobError) => {
    return new Promise((resolve) => {
      if (error) {
        this.setState({ error });
        resolve(true);
        return;
      }

      // Do not acknowledge event if not publishing
      if (this.state.isPublishing === false) {
        resolve(true);
        return;
      }

      this.updateActiveTab(TABS.EMBED.tabIndex).then(resolve);
    });
  };

  updateActiveTab = (idx: any) => {
    return new Promise((resolve) => {
      this.setState({ tabIndex: idx }, () => {
        // @ts-expect-error TS(2345): Argument of type '(value: unknown) => void' is not... Remove this comment to see the full error message
        this.setState({ tabIndex: null }, resolve);
      });
    });
  };

  get tabContent () {
    const { isPublishing } = this.state;
    const { embed } = this.props;

    return [
      {
        name: TABS.PREPARE.name,
        items: [
          <div key='prepare' style={{ marginTop: '20px' }}>
            <PublishingPreparation
              updateBundle={this.updateBundle}
              isPublishing={isPublishing}
              setPublishing={this.setPublishing}
              onPublished={this.onPublished}
              packagingOptions={this.packagingOptions}
              getPublishingOptions={this.getPublishingOptions}
              bundle={this.bundle}
              bundleOutdated={this.bundleOutdated}
              {...this.props}
            />
          </div>,
        ],
      },
      {
        name: TABS.EMBED.name,
        items: [
          <div key={TABS.EMBED.name}>
            <PublishingDataTable
              bundles={this.bundle || this.state.bundle}
              updateBundle={this.updateBundle}
              isPublishing={isPublishing}
              handleNewPackageClick={this.handleNewPackageClick}
              bundleOutdated={this.bundleOutdated}
            />
            {this.canShowEmbed && (
              <EmbedStyleAndLayoutSection
                embed={embed}
                disabled={isPublishing}
                packagingOptions={this.packagingOptions}
                getPublishingOptions={this.getPublishingOptions}
                chooseBundle={this.chooseBundle}
                bundle={this.bundle}
                bundleOutdated={this.bundleOutdated}
                isPublishing={isPublishing}
              />
            )}
          </div>,
        ],
      },
    ];
  }

  render () {
    const { tabIndex, isPublishing, error } = this.state;
    const statusOptions = {
      active: isPublishing,
      onCancel: () => comm.trigger('stopPackageCreation'),
      processDescription: 'Processing',
    };

    return (
      <div className='hy-scrollable'>
        {error ? (
          <ErrorBanner
            title='Upload to Alexandria failed'
            body={error.message}
            error={error}
            onClose={this.handleDismissError}
          />
        ) : null}
        <CancelableStatus {...statusOptions} />
        <CustomStepTabs
          forceTabIndex={tabIndex}
          tabContent={this.tabContent}
          type={DEFAULTS.PUBLISHING_STEP_TABS}
          updateTabCallbackKey={getUpdateTabCallbackKey(DEFAULTS.PUBLISHING_STEP_TABS)}
        />
      </div>
    );
  }
}
