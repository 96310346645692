import './scss/_global.scss';

import React from 'react';

import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { AngelouLoadingSpinner } from './components/editors/AngelouComponents/AngelouLoadingSpinner';
import { HomeScreen } from './components/editors/AssetsEditor/HomeScreen';
import { StoryboardPreview } from './components/editors/StoryboardEditor/StoryboardPreview/StoryboardPreview';
import { GoogleDriveHomeScreen } from './components/GoogleDrive/GoogleDriveHomeScreen';
import { GoogleDriveInstaller } from './components/GoogleDrive/GoogleDriveInstaller';
import { GoogleDriveOpenHandler } from './components/GoogleDrive/GoogleDriveOpenHandler';
import { GoogleDriveViewHandler } from './components/GoogleDrive/GoogleDriveViewHandler';
import { IdleTimeout } from './components/IdleTimeout';
import { LoginPage } from './components/LoginPage';
import { PageNotFound } from './components/pages/PageNotFound';
import { ExternalPreview } from './components/PreviewSection/ExternalPreview';
import { ChooseGroupModal } from './components/User/ChooseGroupModal';
import DEFAULTS from './DEFAULTS.json';
import { MainEditor } from './MainEditor';
import routes from './routes.json';
import { comm } from './services/comm';
import { groupConfig } from './services/configurationService';
import createDebugger from './services/debugger';
import { ReactGA } from './services/gaConfig';
import logger from './services/logger';
import { isPDFPreviewRoute } from './services/navUtils';
import { persistenceClientFactory } from './services/persistence/persistenceClientFactory';
import sessionClient from './services/sessionClient';
import { stateController } from './services/stateController';
import { EditorRoute } from './types/utils';

const debuggerService = createDebugger();

const publicRoutes = [
  DEFAULTS.STORYBOARD_PREVIEW_PATH,
  DEFAULTS.GOOGLE_DRIVE_VIEW,
  DEFAULTS.LOGIN_PAGE,
];

type State = any;

type AppProps = {
  location: any;
  history: any;
  match: any;
}

class App extends React.Component<AppProps, State> {
  onDuration: any;
  constructor (props: AppProps) {
    super(props);

    this.state = {
      configLoaded: false,
      updatesMade: 0,
      showChooseGroupModal: false,
      session: { user: null },
    };
  }

  async componentDidMount () {
    sessionClient.start();
    comm.register('sessionEvent', this.updateSession);
    comm.register('updateQueryString', this.updateQueryString);
    comm.register('onDuration', this.onDuration);
    comm.register('updateAppState', this.updateAppState);

    if (this.ignoreSessionCheck) {
      this.handleConfig();
    } else {
      groupConfig.requestConfig().finally(this.handleConfig);
    }
  }

  componentWillUnmount () {
    sessionClient.stop();
    comm.unregister('sessionEvent', this.updateSession);
    comm.unregister('updateQueryString', this.updateQueryString);
    comm.unregister('onDuration', this.onDuration);
    comm.unregister('updateAppState', this.updateAppState);
  }

  handleConfig = async () => {
    const { location } = this.props;
    persistenceClientFactory.setClient(groupConfig, location);
    stateController.setupHistorian();
    this.setState({ configLoaded: true });
    debuggerService.send({ event: { name: debuggerService.events.CONFIG_UPDATED, payload: groupConfig } });
  };

  updateQueryString = () => {
    const params = new URLSearchParams(this.props.location.search.replace('?', ''));
    // remove remotestate query param
    if (params.has('remotestate')) {
      params.delete('remotestate');
    }
    this.props.history.replace({ search: params.toString() });
  };

  updateAppState = () => {
    this.setState({ updatesMade: this.state.updatesMade + 1 });
  };

  get ignoreSessionCheck () {
    return publicRoutes.includes(this.props.location.pathname);
  }

  updateSession = (eventType: any, newSession: any) => {
    const { session } = this.state;

    if (this.ignoreSessionCheck) {
      return;
    }

    debuggerService.send({ event: { name: debuggerService.events.SESSION_UPDATED, payload: newSession } });

    if (eventType === 'logged-in') {
      const newUser = newSession.user && newSession.user.user;
      const oldUser = session.user && session.user.user;

      if (newUser !== oldUser) {
        if (process.env.REACT_APP_DEBUG) {
          logger.log('USER CHANGED FROM', oldUser, 'TO', newUser);
        }
        this.setState({ session: newSession }); // only update state if user has changed?
        ReactGA.set({ userId: `${'' + (newUser || oldUser)}` });
      }
    }

    if (eventType === 'logged-out') {
      if (process.env.REACT_APP_DEBUG) {
        logger.log('LOGOUT:', eventType, newSession);
      }
      this.setState({ session: null });
      window.location.href = `https://${process.env.REACT_APP_HOST}/login`;
    }
  };

  render () {
    const { configLoaded, session } = this.state;
    const { location, history, match } = this.props;
    const currentPathname = location.pathname;
    const user = sessionClient.getCurrentUser();
    const group = sessionClient.getCurrentGroup();

    if (!configLoaded) return <AngelouLoadingSpinner />;

    if (!this.ignoreSessionCheck) {
      if (!user) {
        // Render nothing if we don't have a valid session + logged in user
        return null;
      }

      if (!group.id && sessionClient.groupSelectionAllowed(location)) {
        // Require group choice if no group selected
        return <ChooseGroupModal />;
      }

      if (!persistenceClientFactory.isClientSupportedForRoute({ location })) {
        return <ChooseGroupModal modalTitle='Please select a group with persistence enabled' />;
      }
    }

    const currentRoute = routes.find((r: any) => currentPathname.includes(r.path)) as EditorRoute;

    stateController.setRouteName(currentPathname);

    if (isPDFPreviewRoute(location)) {
      return <StoryboardPreview />;
    }
    return (
      <div>
        <IdleTimeout />
        <Switch>
          <Route
            exact
            path={'/' + DEFAULTS.HOME_PATH}
            render={() => <HomeScreen location={location} history={history} session={session} />}
          />
          <Route exact path={DEFAULTS.STORYBOARD_PREVIEW_PATH} component={StoryboardPreview} />
          <Route exact path={DEFAULTS.EXTERNAL_PREVIEW_PATH} component={ExternalPreview} />
          <Route exact path={DEFAULTS.GOOGLE_DRIVE_OPEN} component={GoogleDriveOpenHandler} />
          <Route exact path={DEFAULTS.GOOGLE_DRIVE_VIEW} component={GoogleDriveViewHandler} />
          <Route
            exact
            path={DEFAULTS.GOOGLE_DRIVE_NEW}
            render={() => <GoogleDriveHomeScreen location={location} session={session} history={history} />}
          />
          <Route exact path={DEFAULTS.GOOGLE_DRIVE_INSTALLER} component={GoogleDriveInstaller} />
          <Route exact path={DEFAULTS.LOGIN_PAGE} component={LoginPage} />

          {group.id && currentRoute && (
            <MainEditor
              session={session}
              currentRoute={currentRoute}
              location={location}
              match={match}
              debuggerService={debuggerService}
            />
          )}

          <Route exact path='/' render={() => <Redirect to={DEFAULTS.HOME_PATH} />} />
          <Route component={PageNotFound} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
