import React, { useEffect, useState } from 'react';

import { Button, CheckCircleFilledSVG, CircleHollowSVG, Icon } from '@newsela/angelou';

import { Annotation } from '../../../../../../types/annotations';

type QuizAnswerCorrectCheckButtonProps = {
  annotation: Annotation;
  onChange: (a: Annotation, isChecked: boolean) => void;
}

export const QuizAnswerCorrectCheckButton = ({
  annotation,
  onChange,
}: QuizAnswerCorrectCheckButtonProps) => {
  const [isChecked, setIsChecked] = useState(annotation.properties.correct || false);

  const toggleIsChecked = () => {
    setIsChecked((isChecked) => !isChecked);
  };

  useEffect(() => {
    onChange(annotation, isChecked);
  }, [isChecked]);

  return (
    <Button
      ariaProps={{ 'aria-label': 'correct answer button' }}
      legacy_flavor={Button.legacy_flavor.incognito}
      onClick={toggleIsChecked}
      __cssFor={{
        root: {
          padding: '12px',
          height: '48px',
          width: '48px'
        }
      }}
    >
      {isChecked &&
        <Icon
          SvgComponent={CheckCircleFilledSVG}
          isDecorative={false}
          title='is correct'
          __cssFor={{
            root: {
              width: '2rem',
              height: '2rem',
              circle: {
                stroke: '#0F8670',
                fill: '#0F8670'
              }
            }
          }}
        />}
      {!isChecked &&
        <Icon
          SvgComponent={CircleHollowSVG}
          isDecorative={false}
          title='is not correct'
          __cssFor={{
            root: {
              width: '2rem',
              height: '2rem'
            }
          }}
        />}
    </Button>
  );
};
