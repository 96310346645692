import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { Action } from 'types/actions';
import { Project } from 'types/project';

import { replaceComplexActions } from '../components/editors/UXEditor/ActionsEditor/inverserized';
import DEFAULTS from '../DEFAULTS.json';
import { getLayout, prepareLayoutForPlayer } from '../layouts';
import { Annotation } from '../types/annotations';
import { annotationService } from './annotationService';
import { LEXILE_LEVEL_DEFAULT } from './lexileService';
import { media } from './mediaController';
import { filterAnnotationsForPlayer, modifyAnnotationForPlayer, orderQuizAnnotationsForPlayer } from './quizUtils';
import { applyResponsiveLayoutChanges } from './responsiveEmbed';
import sessionClient from './sessionClient';
import { assetService, stateController } from './stateController';
import { StyleService } from './styleService';
import { trimEmptyItems } from './utils';
import { findMaturityRangeMinMax, getMaturityRangeForPublication, removeStartExpanded } from './videoConfigUtils';

const replaceAnnotationAssetIds = (annotations: Annotation[]) => {
  // swap out asset ids for asset sources
  return annotations.map((a: Annotation) => {
    const modified = {} as Annotation;

    const thumbnail = assetService.getImage(a.thumbnail).src;
    if (thumbnail) modified.thumbnail = thumbnail;

    if (a.type === 'image') {
      const image = assetService.getImage(a.content).src;
      if (image) modified.content = image;
    }

    if (a.type === 'stylesheet') {
      const css = assetService.getCss(a.id).src;
      if (css) modified.content = css;
    }

    if (['quizSection', 'quizQuestion', 'quizAnswer'].includes(a.toolType) && _.get(a, 'properties.thumbnail')) {
      modified.properties = {
        ...a.properties,
        thumbnail: assetService.getImage(a.properties.thumbnail).src,
      };
    }

    return { ...a, ...modified };
  });
};

// accepts ALREADY TRANSFORMED VIDEO CONFIG. "project" is not equivalent to Tools App's data.project
export const replaceAssetIds = (config: any) => {
  const { project, annotations } = config;

  return {
    ...config,
    project: {
      ...project,
      posterImage: assetService.getImage(project.posterImage).src,
      thumbnail: assetService.getImage(project.thumbnail).src,
    },
    annotations: replaceAnnotationAssetIds(annotations),
  };
};

const getControlsAnnotation = (player: any, localizedAssets = []) => {
  const colors = {
    text: player.textColor,
    background: player.backgroundColor,
    progressbar: player.progressBarColor,
  };

  const playbackRateOptions = player.showPlayBackRateButton ? { playbackRates: [0.5, 0.75, 1, 2] } : {};
  const languages = _.uniq(localizedAssets.map((asset) => (asset as any).language));
  const levels = _.uniq(localizedAssets.map((asset) => (asset as any).lexileLevel));

  const properties = {
    theme: player.theme,
    colors: StyleService.replaceBrandingVariables(colors),
    buttons: {
      showLargePlayButton: player.showLargePlayButton,
      controlBar: player.showControlBar,
      playButton: player.showPlayButton,
      volumeIndicator: player.showVolumeIndicator,
      fullscreen: player.showFullscreenToggle,
      progressbar: player.showProgressBar,
      volume: player.showVolume,
      currenttime: player.showCurrentTime,
      closedcaption: player.showClosedCaptionButton,
      download: player.showDownloadButton,
      usersentiment: player.showLikeDislikeButtons,
      rewind: player.showRewindButton,
      share: player.showShareButton,
      level: player.showLevelButton && levels.length > 1,
      showPlayBackRateButton: player.showPlayBackRateButton,
      language: player.showLanguageButton && languages.length > 1,
      controls_settings: true,
    },
    options: {
      largeMobileControls: player.largeMobileControls,
      ...playbackRateOptions,
    },
  };

  if (localizedAssets.length) {
    (properties as any).languages = languages;
    (properties as any).levels = levels;
  }

  if (localizedAssets.length) {
    (properties as any).levels = _.uniq(localizedAssets.map((asset) => (asset as any).lexileLevel));
  }

  return {
    type: 'controls',
    id: 2000,
    target: 'player',
    properties,
  };
};

const getAudioDescription = (audioDescriptions: any, videoLanguage: any) => {
  const defaultLanguageAudioDescription = getDefaultLanguageAsset(audioDescriptions, videoLanguage);

  if (!defaultLanguageAudioDescription) return null;

  return {
    type: 'audiodescription',
    target: 'player',
    id: 4000,
    content: defaultLanguageAudioDescription.src,
    contentType: 'url',
    variations: audioDescriptions.map(createLangLevelVariations),
  };
};

const getClosedCaptions = (vtts: any, videoLanguage: any) => {
  const defaultLanguageVtt = getDefaultLanguageAsset(vtts, videoLanguage);

  if (!defaultLanguageVtt) return null;

  return {
    type: 'closedcaptions',
    target: 'player',
    id: 3000,
    content: defaultLanguageVtt.src,
    contentType: 'url',
    variations: vtts.map(createLangLevelVariations),
  };
};

const getSmartCaptions = (vtts = [], videoLanguage: any, annotations: Annotation[]) => {
  const defaultLanguageVtt = getDefaultLanguageAsset(vtts, videoLanguage);
  const smartCaptionsAnnotation = annotations.filter((a: Annotation) => {
    return a.type === 'smartcaptions';
  });

  if (!defaultLanguageVtt || !smartCaptionsAnnotation.length) return null;

  return smartCaptionsAnnotation.map((a: Annotation) => {
    const captionsVariations = vtts.map(createLangLevelVariations);

    return {
      ...a,
      content: defaultLanguageVtt.src || '',
      variations: a.variations ? [...a.variations, ...captionsVariations] : captionsVariations,
    };
  });
};

const getDefaultLanguageAsset = (assets: any, videoLanguage: any) => {
  if (!assets.length) return null;
  const defaultLanguageAsset = assets.find(
    (asset: any) => asset.language === videoLanguage && asset.lexileLevel === LEXILE_LEVEL_DEFAULT
  );
  return defaultLanguageAsset || assets[0];
};

const createLangLevelVariations = (asset: any, hiddenLanguage: any) => {
  const isBaseAsset = asset.language === hiddenLanguage && asset.lexileLevel === LEXILE_LEVEL_DEFAULT;

  return {
    conditions: [
      {
        state: 'language',
        value: asset.language,
        assertion: 'equal',
      },
      {
        state: 'level',
        value: asset.lexileLevel || LEXILE_LEVEL_DEFAULT,
        assertion: 'equal',
      },
    ],
    changes: {
      content: isBaseAsset ? null : asset.src,
    },
  };
};

const getConditionalSettingsProvider = (video: any, arbs: any, type: any) => {
  if (!arbs.length) return null;

  const filtered = arbs.filter((arb: any) => arb.type === type);

  const showDefault = localStorage.getItem('hapyak_developer_mode') === 'true'; // developer mode shows all language, even the default

  const defaultAsset = getDefaultLanguageAsset(filtered, video.language);
  const variations = filtered.map((asset: any) => createLangLevelVariations(asset, !showDefault && video.language));
  const content = showDefault ? defaultAsset && defaultAsset.src : null;

  return {
    type: 'conditionalsettingsprovider',
    id: `conditionalsettingsprovider_${type.replaceAll(' ', '')}`,
    target: 'player',
    content,
    contentType: 'url',
    styles: {},
    properties: {},
    variations,
  };
};

const prepareLayout = (project: Project, video = {}) => {
  const layout = getLayout(project.layoutId);
  const layoutOverrides = _.get(project, 'display.layout', {});
  let overridden = prepareLayoutForPlayer(layout.layoutTargets, layoutOverrides);
  const { physicalWidth = null } = layoutOverrides;

  if ((video as any).width && (video as any).height) {
    const aspect = ((video as any).height / (video as any).width) * 100;
    overridden = overridden.map((target: any) => {
      if (target.name === 'player') {
        target.aspect = aspect;
        target.physicalWidth = physicalWidth || target.physicalWidth;
      }
      return target;
    });
  }

  return overridden;
};

const omitSmartCaptions = (annotations: Annotation[]) => {
  return annotations.filter((a: Annotation) => a.type !== 'smartcaptions');
};

export const getStylesheetAnnotation = () => {
  const { src = '', id } = assetService.getCssArray()[0] || {};

  if (!src) {
    return;
  }

  return {
    type: 'stylesheet',
    target: 'player',
    contentType: 'url',
    content: src,
    id: id,
  };
};

const shouldOmitClosedCaptions = (annotations: Annotation[]) => {
  const shouldOmit = annotations.some((a: Annotation) => {
    const { type, target } = a;

    return a && type === 'smartcaptions' && target === 'player';
  });

  return shouldOmit;
};
const projectContainsPowerWords = (annotations: Annotation[]) => {
  return annotations.some((annotation: Annotation) => annotation.type === 'powerwords' && annotation.content);
};

const getDefinitionProviderAnnotation = (video: any, annotations: Annotation[]) => {
  const annotationTextAnnotation =
        annotations.filter((annotation: Annotation) => annotation.id === 'conditionalsettingsprovider_AnnotationText')[0] || null;

  // since, for now, power words are only included in transcript, vtts should be used as the basis for creating defintion provider config
  const projectId = stateController.getCurrentData().project.id;

  return {
    type: 'definitionprovider',
    id: 5000,
    target: 'player',
    content: `./powerwords/powerwords-${projectId}-${video.language}-MAX.arb`, // predefined url, as definition arbs are built in the publishing step
    variations: annotationTextAnnotation?.variations?.map(getDefinitionProviderVariations) || [],
  };
};

const getDefinitionProviderVariations = (variation = {} as any) => {
  const projectId = stateController.getCurrentData().project.id;
  const { conditions = [] } = variation;

  const { level, language } = conditions.reduce((collected: any, c: any) => {
    if (c.state === 'level') {
      collected.level = c.value;
    } else if (c.state === 'language') {
      collected.language = c.value;
    }
    return collected;
  }, {});

  return {
    conditions: conditions,
    changes: {
      content: `./powerwords/powerwords-${projectId}-${language}-${level}.arb`,
    },
  };
};

const overrideAnnotationEndTime = (annotations: Annotation[]) => {
  const overridable = annotations.filter((a: any) => a.endTimeOmitted);
  overridable.forEach((a: any) => {
    a.end = null;
  });
};

export const prepareConfigFromTools = (forPackaging: any, isPreviewInstance: any) => {
  const data = stateController.getCurrentData();
  const video = media.video;
  const { player, ux, project, publishing, assets } = data;
  const arbs = assetService.getArbArray();
  const vtts = assetService.getVttArray();
  const audioDescriptions = assetService.getAudioDescriptionArray();
  const bundle = assetService.getCurrentBundle();
  const { id: packageId } = bundle;

  const uxAnnotations = omitSmartCaptions(ux.annotations);
  const nonEditable = annotationService.nonEditableAnnotations;
  const nonEditableAnnotations = (forPackaging || isPreviewInstance) && nonEditable ? nonEditable : [];
  const annotationLanguageProvider = getConditionalSettingsProvider(video, arbs, DEFAULTS.ARB_ANNOTATION_TEXT);
  const videoTextLanguageProvider = getConditionalSettingsProvider(video, arbs, DEFAULTS.ARB_VIDEO_TEXT);
  const closedCaptionsAnnotation = getClosedCaptions(vtts, video.language);
  const audioDescriptionAnnotation = getAudioDescription(audioDescriptions, video.language);
  // @ts-expect-error TS(2554): Expected 0 arguments, but got 2.
  const stylesheetAnnotation = getStylesheetAnnotation(assets.css, project.id);
  const smartCaptionsAnnotation = getSmartCaptions(vtts, video.language, ux.annotations) || [];

  overrideAnnotationEndTime(uxAnnotations);

  let annotations = [
    ...uxAnnotations,
    // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    getControlsAnnotation(player, [...arbs, ...vtts]),
    !shouldOmitClosedCaptions(ux.annotations) ? closedCaptionsAnnotation : null,
    audioDescriptionAnnotation,
    annotationLanguageProvider,
    videoTextLanguageProvider,
    ...(videoTextLanguageProvider ? nonEditableAnnotations : []),
    stylesheetAnnotation,
    ...smartCaptionsAnnotation, // account for there being multiple smartcaptions
  ].filter(Boolean);

  annotations = orderQuizAnnotationsForPlayer(annotations);

  const filteredAnnotations = annotations.filter((annotation) => {
    return filterAnnotationsForPlayer(annotation, annotations);
  });

  const updateAppliesToRelationship = ({
    annotation,
    progressIndicator,
    appliesTo = []
  }: any) => {
    if (progressIndicator && annotation.type === 'quiz') {
      annotation.appliesTo = [...appliesTo, progressIndicator.id];
    }

    return annotation;
  };

  const progressIndicator = annotations.find((a) => a.toolType === 'progressIndicator');

  const updatedAnnotations = filteredAnnotations.map((annotation) => {
    if (!annotation.toolType) return annotation;

    let _annotation = { ...annotation };
    _annotation = modifyAnnotationForPlayer(_annotation, annotations);

    const styles = StyleService.replaceBrandingVariables(_annotation.styles);
    const actions = _annotation.actions.map((action: Action) => replaceComplexActions(action));

    let { properties, appliesTo = [] } = _annotation;
    properties = forPackaging || isPreviewInstance ? properties : removeStartExpanded(properties);

    _annotation = updateAppliesToRelationship({ annotation: _annotation, progressIndicator, appliesTo });

    return { ..._annotation, styles, properties, actions: _.flattenDepth(actions, 1) };
  });

  const {
    thumbnail,
    whitelist,
    expirationDate,
    playerNotAuthorizedMessaging,
    unauthorizedMessageFormat,
    videoType,
    seoTitle,
    seoDescription,
    seoUploadDate,
    seoThumbnail,
    seoVideoObjectSchema,
  } = publishing;
  const group = sessionClient.getCurrentGroup();
  const formats = video.formats;
  const available = media.bestAvailableFormat;
  const videoSource = forPackaging ? formats[videoType] || available : available;
  const processedWhitelist = trimEmptyItems(whitelist).filter((item: any) => !!item);

  const config = {
    version: (window as any).hy.version,
    display: {
      layout: prepareLayout(project, video),
      video: {},
    },
    project: {
      thumbnail,
      id: project.id,
      posterImage: project.posterImage,
      title: project.title,
      tags: project.tags,
      refId: project.refId,
      alexandriaId: project.alexandriaId,
      description: project.description,
      transcript: project.transcript,
      seo: {
        ...project.seo,
        enabled: seoVideoObjectSchema,
        title: seoTitle,
        description: seoDescription,
        uploadDate: seoUploadDate,
        thumbnail: assetService.getImage(seoThumbnail).src,
      },
      duration: video.duration,
      videoSource,
      videoId: video.id,
      videoTitle: video.title || '',
      whitelist: processedWhitelist,
      expirationDate,
      playerNotAuthorizedMessaging,
      unauthorizedMessageFormat,
      gradeBand: project.gradeBand,
      maturityRange: findMaturityRangeMinMax(project.maturityRange),
      maturityRangeDetail: getMaturityRangeForPublication(project.maturityRange),
    },
    group: {
      affiliate: group.affiliateId,
      customer: group.customerId,
      id: group.id,
      name: group.name,
      parent: group.parentId,
    },
    analytics: {
      modules: [],
    },
    package: {
      packageId,
    },
    annotations: updatedAnnotations,
    state: {
      language: video.language,
      level: LEXILE_LEVEL_DEFAULT,
    },
  };

  if (isPreviewInstance) {
    return applyResponsiveLayoutChanges(config);
  }

  return config;
};

export const prepareConfigFromPlayer = () => {
  const data = stateController.getCurrentData();
  const { project, publishing } = data;
  const { title, description, seo, posterImage } = project;
  const {
    whitelist,
    expirationDate,
    playerNotAuthorizedMessaging,
    unauthorizedMessageFormat,
    seoTitle,
    seoDescription,
    seoUploadDate,
  } = publishing;
  const { duration } = media.video;
  const posterImageAsset = assetService.getImage(posterImage);
  const overrides = media.previewInstance.getStateOverrides();
  overrides.annotations = overrides.annotations.filter((a: Annotation) => a.type !== 'closedcaptions'); // remove closed captions
  overrides.annotations = overrides.annotations.map((a: Annotation) => {
    if (a.content === '') {
      // normalize unset content
      delete a.content;
    }

    if (a.properties && a.properties.dataAttrs) {
      // normalize dataAttrs
      delete a.properties.dataAttrs;
    }

    return a;
  });

  overrides.project = {
    ...overrides.project,
    posterImage: posterImageAsset && posterImageAsset.src,
    title,
    description,
    duration,
    whitelist,
    expirationDate,
    playerNotAuthorizedMessaging,
    unauthorizedMessageFormat,
    seo,
    seoTitle,
    seoDescription,
    seoUploadDate,
  };

  overrides.display = {
    layout: prepareLayout(project),
    video: {},
  };

  return overrides;
};

export const prepareForNewselaPublishing = (config: any) => {
  config.annotations = collectActivities(config.annotations);
  config.annotations = moveToExternalPanels(config.annotations);

  if (projectContainsPowerWords(config.annotations)) {
    const definitionProviderAnnotation = getDefinitionProviderAnnotation(media.video, config.annotations);
    config.annotations.push(definitionProviderAnnotation);
  }

  return config;
};

export const collectActivities = (annotations: Annotation[] = []) => {
  const activityPanelAnnotation = createActivitiesPanel();

  // the effect of this change is that quizzes will only render within the
  // activities panel annotation now
  return annotations
    .map((a) => {
      if ((a as any).type === 'quiz') {
        const internalAppliesTo = [...(a as any).appliesTo];
        const internalTarget = (a as any).target;

        (a as any).appliesTo = ((a as any).appliesTo || []).concat(activityPanelAnnotation.id);
        (a as any).target = '';
        (a as any).variations = [
          ...(a as any).variations,
          addFullscreenChanges({
            target: internalTarget,
            appliesTo: internalAppliesTo,
          }),
        ];
      }

      return a;
    })
    // @ts-expect-error TS(2769): No overload matches this call.
    .concat(activityPanelAnnotation);
};

export const moveToExternalPanels = (annotations = []) => {
  return annotations.map((a) => {
    if ((a as any).type === 'activitiespanel') {
      (a as any).target = '#player_activities';
    }

    if ((a as any).type === 'smartcaptions' && (a as any).target !== 'player') {
      const internalTarget = (a as any).target;
      (a as any).target = '#player_transcript';

      (a as any).variations = [
        ...(a as any).variations,
        addFullscreenChanges({
          target: internalTarget,
        }),
      ];
    }

    return a;
  });
};

export const addFullscreenChanges = (changes = {}) => {
  return {
    conditions: [
      {
        state: 'fullscreen',
        assertion: 'equal',
        value: true,
      },
    ],
    changes,
  };
};

export const createActivitiesPanel = () => ({
  id: uuid(),
  type: 'activitiespanel',
  target: 'adjacent_right', // will be moved to "external panel" almost immediately
  content: 'Activities Panel',
  scalable: false,
  styles: {},
  properties: {},
  variations: [addFullscreenChanges({ mode: 'invisible' })],
});
