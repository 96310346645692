import './index.scss';

import React from 'react';

import { Nav } from '../../Nav';
import { ProjectStarter } from './ProjectStarter';

type HomeScreenProps = {
  history: any;
  location: Location;
  session: any;
};

class HomeScreen extends React.Component<HomeScreenProps> {
  render () {
    const { location, session } = this.props;

    return (
      <div className='hy-homescreen hy-page-layout hy-absolute'>
        <Nav location={location} session={session} />

        <div className='hy-app-area' style={{ overflowY: 'scroll' }}>
          {this.projectStarter}
        </div>
      </div>
    );
  }

  get projectStarter () {
    const { location, history, session } = this.props;
    return (
      <ProjectStarter
        location={location}
        history={history}
        groupId={session.user.group.id}
      />
    );
  }
}

export { HomeScreen };
