import _ from 'lodash';

import hms from './time';

const ENTRY_TYPE = 'debit';
const LINE_ITEM_TYPE = 'lineitem';

const FILTER_BY_VALUE = 0; // STOP GAP FOR LINE ITEMS <= 0
const GENERATE_PROCESS_NOT_REQ = 0;
const GENERATE_PROCESS_REQ = 1;

const lineItemTotal = (items: any) => {
  return items.reduce((acc: any, curr: any) => {
    return acc + +curr.amount;
  }, 0);
};

const formatLineItem = (quantity: any, unitAmount: any, description: any) => {
  return {
    type: LINE_ITEM_TYPE,
    amount: unitAmount * quantity,
    quantity,
    unitAmount,
    description,
  };
};

const formatTransaction = (title: any, amount: any, projectId: any, lineItems: any) => {
  return {
    title,
    description: title,
    entryType: ENTRY_TYPE,
    amount,
    projectId,
    lineitems: lineItems,
  };
};

const createGenerateLineItems = ({
  types,
  duration
}: any) => {
  return types
    .map((type: any) => {
      const name = _.capitalize(type.name);
      const gName = `${name} Generate : Base File`;
      const gQty = type.name === 'annotations' ? GENERATE_PROCESS_NOT_REQ : GENERATE_PROCESS_REQ;

      const generateLineItem = type.generate === true ? formatLineItem(gQty, duration, gName) : false;
      return [generateLineItem];
    })
    .flat()
    .filter((item: any) => !!item && item.amount > FILTER_BY_VALUE);
};

const createTranslateLineItems = ({
  duration,
  languageCount,
  languages
}: any) => {
  const amount = duration * languageCount;

  return [
    {
      amount,
      description: 'Translation to : Languages : ' + languages.join(', '),
      quantity: languageCount,
      type: 'lineitem',
      unitAmount: duration,
    },
  ];
};

export const formatLocalizeTransaction = (data: any, media: any, projectId: any, type: any) => {
  const { languages = [], types } = data;
  const isTranslationTransaction = type === 'translate';
  const languageCount = languages.length;
  const duration = hms.msToNextMinute(media.duration);

  const lineItemData = { types, duration, languageCount, languages };
  const lineItems = isTranslationTransaction
    ? createTranslateLineItems(lineItemData)
    : createGenerateLineItems(lineItemData);

  const totalAmount = lineItemTotal(lineItems);

  return formatTransaction(`Localize ${type} process`, totalAmount, projectId, lineItems);
};

export const formatBundleTransaction = (items: any, projectId: any) => {
  const lineItems = items
    .map((item: any) => {
      return item.lineItems.map((line: any) => {
        return formatLineItem(line.units, line.amount, line.description);
      });
    })
    .flat();

  const totalAmount = lineItemTotal(lineItems);

  return formatTransaction('Bundle Process', totalAmount, projectId, lineItems);
};
