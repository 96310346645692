import React from 'react';

import { Grid, Input, Header, Popup, Icon } from '../../../hapyak-ui-toolkit';
import { stateController, assetService } from '../../../services/stateController';
import { toPercent } from '../../../services/utils';
import { VideoTypeOption } from '../../../types/publishing';
import { ImagePicker } from '../CommonComponents/ImagePicker/index';
import { DateInput } from './DateInput';
import { PackagingOptions, PackagingOptionsProps } from './PackagingOptions';
import './index.scss';

type SeoOptionsProps = PackagingOptionsProps & {
  setThumbnailAspectRatio?: (value: string) => void;
  videoTypeOptions: VideoTypeOption[];
  updateValues: (values: any) => Promise<any>;
}

export class SeoOptions extends PackagingOptions<SeoOptionsProps> {
  componentDidMount () {
    this.setDefaultProperties();
  }

  get seoIsEnabled () {
    const { seoVideoObjectSchema } = this.bundleProps;
    return !!seoVideoObjectSchema;
  }

  setDefaultProperties = () => {
    const { updateValues } = this.props;
    const { seoTitle, seoDescription, seoUploadDate, seoThumbnail } = this.bundleProps;
    const { title = '' } = stateController.getCurrentData('project');

    const defaultProps = {};

    if (!seoTitle) {
      (defaultProps as any).seoTitle = title;
    }
    if (!seoDescription) {
      (defaultProps as any).seoDescription = title;
    }
    if (!seoUploadDate) {
      (defaultProps as any).seoUploadDate = Date.now();
    }
    if (!seoThumbnail) {
      (defaultProps as any).seoThumbnail = assetService.getImage(seoThumbnail);
    }

    updateValues(defaultProps).then(this.saveChanges);
  };

  propOrDefault = (original: any, fallback: any) => (original === undefined ? fallback : original);

  get seoInputs () {
    const { seoTitle, seoDescription } = this.bundleProps;
    const { title = '' } = stateController.getCurrentData('project');

    return [
      {
        placeholder: 'Name',
        onChangeValue: 'seoTitle',
        label: 'Name',
        value: this.propOrDefault(seoTitle, title),
        editable: true,
        disabled: !this.seoIsEnabled,
      },
      {
        placeholder: 'Description',
        onChangeValue: 'seoDescription',
        label: 'Description',
        value: this.propOrDefault(seoDescription, title),
        editable: true,
        disabled: !this.seoIsEnabled,
      },
    ];
  }

  onThumbnailChange = (image: any) => {
    this.updateValue('seoThumbnail', image.id).then(this.saveChanges);
  };

  setThumbnailAspectRatio = (aspectRatio: any) => {
    const { setThumbnailAspectRatio } = this.props;
    if (typeof setThumbnailAspectRatio === 'function') {
      setThumbnailAspectRatio(toPercent(1, aspectRatio));
    }
  };

  get imagePicker () {
    const { seoThumbnail } = this.bundleProps;

    return (
      <ImagePicker
        label='SEO Thumbnail'
        image={assetService.getImage(seoThumbnail)}
        setImage={this.onThumbnailChange}
        setAspectRatio={this.setThumbnailAspectRatio}
        disabled={!this.seoIsEnabled}
      />
    );
  }

  handleDateChange = ({
    date
  }: any) => {
    this.updateValue('seoUploadDate', date).then(this.saveChanges);
  };

  get dateInput () {
    const { seoUploadDate } = this.bundleProps;
    const now = Date.now();
    const date = this.propOrDefault(seoUploadDate, now);
    return (
      <DateInput
        disabled={!this.seoIsEnabled}
        label='Upload Date'
        className='hy-margin-top'
        startDate={date}
        handleChange={this.handleDateChange}
      />
    );
  }

  get expirationDate () {
    return this.bundleProps.expirationDate;
  }

  get restrictionExpirationInputElement () {
    // view only input
    // @ts-expect-error TS(2769): No overload matches this call.
    return <DateInput disabled label='SEO Expiration Date' startDate={this.expirationDate} />;
  }

  get requiredInputs () {
    const inputs = this.seoInputs.map((item, idx) => {
      const { placeholder, onChangeValue, label, value, disabled } = item;

      return (
        <Input
          key={idx}
          className='hy-margin-top'
          placeholder={placeholder}
          onChange={this.handleChange.bind(this, onChangeValue)}
          onBlur={this.saveChanges}
          width={6}
          label={label}
          value={value}
          disabled={disabled}
        />
      );
    });

    return [inputs, this.restrictionExpirationInputElement, this.imagePicker];
  }

  get optionalInputs () {
    return [this.dateInput];
  }

  composedInputs = ({
    inputType
  }: any) => {
    const inputs = inputType === 'required' ? this.requiredInputs : this.optionalInputs;

    return inputs.map((input, idx) => {
      return (
        <Grid.Row key={idx} className='hy-margin-bottom'>
          {input}
        </Grid.Row>
      );
    });
  };

  render () {
    const requiredComposedInputs = this.composedInputs({ inputType: 'required' });
    const optionalComposedInputs = this.composedInputs({ inputType: 'optional' });

    return (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Grid.Row>
              <Header>
                Required{' '}
                <Popup
                  content='If required fields are empty, optimization may be invalid.'
                  trigger={
                    <Icon
                      style={{ fontSize: '0.75em', marginTop: '-12px' }}
                      color='grey'
                      name='info circle'
                    />
                  }
                />
              </Header>
            </Grid.Row>
            {requiredComposedInputs}
          </Grid.Column>
          <Grid.Column>
            <Grid.Row>
              <Header content='Optional' />
            </Grid.Row>
            {optionalComposedInputs}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
