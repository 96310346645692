import React from 'react';

import { Icon, Label, Placeholder } from '../../../../hapyak-ui-toolkit';
import { assetService } from '../../../../services/stateController';
import hms from '../../../../services/time';
import { DeviceWrapper } from '../../../PreviewSection/DeviceWrapper';
import { getPdfSettings } from '../pageSettings';

type State = any;

type StoryboardOptionProps = {
  image: any;
  description: any;
  scale: any;
  width: number;
  height: number;
  index: number;
  onClick: any;
  time: any;
  device: any;
  portrait: any;
  handleImageLoad: () => void | undefined;
};

class StoryboardOption extends React.Component<StoryboardOptionProps, State> {
  content: any;
  image: any;
  ruler: any;
  constructor (props: StoryboardOptionProps) {
    super(props);
    this.ruler = React.createRef();
    this.content = React.createRef();
    this.image = React.createRef();
    this.state = { loaded: false, pageCount: 1, imgHasError: false };
  }

  UNSAFE_componentWillReceiveProps (nextProps: any) {
    const { image, description } = this.props;
    if (nextProps.image !== image) {
      this.setState({ loaded: false });
    }

    if (nextProps.description !== description) {
      requestAnimationFrame(this.setPageCount);
    }
  }

  get wrapperStyle () {
    const { imageMargin } = getPdfSettings(1, true);

    return {
      display: 'block',
      width: '100%',
      backgroundColor: '#eee',
      padding: imageMargin,
      marginBottom: imageMargin,
      WebkitUserDrag: 'none',
    };
  }

  get style () {
    const { scale } = this.props;
    const { width, height } = getPdfSettings(scale, true);

    return {
      backgroundColor: '#fff',
      width,
      height,
      position: 'relative',
      overflow: 'hidden',
      boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.3)',
    };
  }

  get imageStyle () {
    return {
      width: '100%',
      WebkitUserDrag: 'none',
    };
  }

  get pageNumber () {
    const { index } = this.props;

    return (
      <Label className='hy-pdf-page-index' circular size='large'>
        {index + 1}
      </Label>
    );
  }

  get timeDisplay () {
    const { time } = this.props;

    return (
      <Label className='hy-pdf-time-display' circular size='large'>
        {hms.secondsToHMS(time)}
      </Label>
    );
  }

  get scaledStyle () {
    const { loaded } = this.state;
    const { scale } = this.props;
    const { width, height, margin } = getPdfSettings(1);
    return {
      width: width - 2 * margin + 'in',
      height: height + 'in',
      margin: margin * scale + 'in',
      transform: `scale(${loaded ? scale : 0})`,
      transformOrigin: '0 0',
      position: 'absolute',
      top: 0,
      left: 0,
    };
  }

  handleImageLoad = () => {
    this.setState({ loaded: true }, () => {
      if (typeof this.props.handleImageLoad === 'function') this.props.handleImageLoad();
      this.setPageCount();
    });
  };

  onClick = (e: any) => {
    const { onClick } = this.props;
    if (typeof onClick === 'function') onClick(e);
  };

  getContentHeight = () => {
    const contentHeight = this.content.current.getBoundingClientRect().height;
    const imageHeight = this.image.current.getBoundingClientRect().height;
    return contentHeight + imageHeight;
  };

  setPageCount = () => {
    const { scale } = this.props;

    const inchesToPixels = this.ruler.current.getBoundingClientRect().width; // 1 inch to pixels as measured on device
    const { height, margin } = getPdfSettings(scale);
    const contentHeight = this.getContentHeight();
    const allowedContentHeight = (height - margin * 2) * inchesToPixels;
    const pageCount = Math.ceil(contentHeight / allowedContentHeight); // new page for any overflowing content

    this.setState({ pageCount });
  };

  get pageCountIcon () {
    const { pageCount } = this.state;
    if (!(pageCount > 1)) {
      return null;
    }

    return (
      <Label circular className='hy-pdf-page-count' size='large'>
        <Icon name='copy outline' flipped='horizontally' />
        {pageCount}
      </Label>
    );
  }

  get secondPage () {
    const position = {
      position: 'absolute',
      top: '10px',
      left: '10px',
    };

    // @ts-expect-error TS(2322): Type '{ position: string; top: string; left: strin... Remove this comment to see the full error message
    return <div style={{ ...this.style, ...position }} />;
  }

  hasQueryString = (qString: any) => {
    const pattern = /\?.+=.*/g;
    return pattern.test(qString);
  };

  createRetryInterval = (callback: any, interval: any, maxRetriesToRun: any) => {
    for (let i = 0; i < maxRetriesToRun; i++) {
      if (i < 10) continue;
      setTimeout(callback, +Math.pow(1.2, i).toFixed(2) * 1000);
    }
  };

  onError = (error: any) => {
    const { imgHasError } = this.state;
    this.setState({ imgHasError: true });

    if (imgHasError) return;

    const trgt = error.currentTarget;
    const imgSrc = trgt.src;

    this.createRetryInterval(
      () => {
        const hyCacheBust = 'hyCacheBust=' + new Date().getTime();
        const queryAppendage = this.hasQueryString(imgSrc) ? '&' : '?';
        const newImg = imgSrc + queryAppendage + hyCacheBust;
        trgt.src = newImg;
      },
      7000,
      25
    );
  };

  render () {
    const { description, image, index } = this.props;
    const { loaded, pageCount } = this.state;
    const placeholder = (
      <Placeholder style={{ height: '100%', width: '100%' }}>
        <Placeholder.Image />
      </Placeholder>
    );
    const { device, portrait, scale } = this.props;
    const imageSource = assetService.getImage(image).src;

    return (
      <div onClick={this.onClick}>
        {pageCount > 1 && this.secondPage}
        {/* @ts-expect-error TS(2322): Type '{ backgroundColor: string; width: any; heigh... Remove this comment to see the full error message */}
        <div style={this.style}>
          <div ref={this.ruler} style={{ width: '1in' }} />
          {!loaded && placeholder}

          {/* @ts-expect-error TS(2322): Type '{ width: string; height: string; margin: str... Remove this comment to see the full error message */}
          <div style={{ ...this.scaledStyle }}>
            <div style={this.wrapperStyle}>
              <DeviceWrapper device={device} portrait={portrait} downscale={scale}>
                <img
                  ref={this.image}
                  alt={`Storyboard screen ${index}`}
                  src={imageSource}
                  style={this.imageStyle}
                  onLoad={this.handleImageLoad}
                  onError={this.onError}
                />
              </DeviceWrapper>
            </div>
            <p
              className='hy-pdf-index-text-content'
              ref={this.content}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>

          <div className='hy-pdf-option-icons'>
            {this.pageNumber}
            {this.timeDisplay}
            {this.pageCountIcon}
          </div>
          <div className='hy-content-fader' />
        </div>
      </div>
    );
  }
}

export { StoryboardOption };
