import React from 'react';

import { comm } from '../../services/comm';
import { gaConfig, ReactGA } from '../../services/gaConfig';
import { isTranscodeActive } from '../../services/persistence/platformService';
import visService from '../../services/visService';

type EditorProps = {
  component: any;
};

class Editor extends React.Component<EditorProps> {
  pageLayoutSection: any;
  constructor (props: any) {
    super(props);

    this.pageLayoutSection = React.createRef();
  }

  componentDidMount () {
    if (isTranscodeActive()) {
      visService.monitorTranscodeUpdates();
    }

    this.pageLayoutSection.current.addEventListener('transitionend', this.transitionEnd);
  }

  componentWillUnmount () {
    this.pageLayoutSection.current.removeEventListener('transitionend', this.transitionEnd);
  }

  transitionEnd = () => {
    comm.trigger('pageLayoutTransitionEnd');
  };

  expand = (full: boolean, e: any) => {
    e.stopPropagation();
    comm.trigger('expandPreview', full ? 'editor' : null);
    ReactGA.event(gaConfig.Analytics.Portal.Expand);
  };

  render () {
    const { component } = this.props;
    const className = 'hy-page-layout-editor hy-page-layout-section';

    return (
      <div className={className} ref={this.pageLayoutSection}>
        {component}
      </div>
    );
  }
}

export { Editor };
