import React from 'react';

import { Button, Icon, Label, Popup } from '../../../../hapyak-ui-toolkit';
// TODO: look into tsconfig path aliases
import { ImageMetadata, ImagePickerProps } from '../../../../types/assets';
import { AddImageModal } from './AddImageModal';
import './index.scss';

const ctaClass = 'hy-thumbnail-preview-container';
const CTA_TEXT = 'Select an image';

type State = any;

export class ImagePicker extends React.Component<ImagePickerProps, State> {
  constructor (props: ImagePickerProps) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClose = () => this.setState({ open: false });
  handleOpen = () => this.setState({ open: true });

  setImage = (image: ImageMetadata) => {
    if (this.props.setImage) {
      this.props.setImage(image);
    }
    this.handleClose();
  };

  get hasLabel () {
    const { label } = this.props;
    return label !== false;
  }

  get ctaClass () {
    return ctaClass + (this.hasLabel ? '' : ' hy-no-label');
  }

  get imageThumbnail () {
    const { image } = this.props;
    return image ? (
      <div className={this.ctaClass}>
        <img src={image.src} alt='embed thumbnail' style={{ maxHeight: '100px' }} />
      </div>
    ) : this.button;
  }

  get button () {
    const { disabled = false } = this.props;

    return <Button disabled={disabled} content={disabled ? 'Unset' : CTA_TEXT} className={this.ctaClass} />;
  }

  get label () {
    const { label = 'Image', description } = this.props;

    return label === false ? null : (
      <Label className='hy-thumbnail-label'>
        {label}
        <span className='hy-thumbnail-label-description'>{description}</span>
      </Label>
    );
  }

  get defaultTrigger () {
    const { disabled = false, image } = this.props;
    const onClick: (() => void) | undefined = disabled ? undefined : this.handleOpen;

    return (
      <div className={`ui labeled input ${disabled ? 'hy-thumbnail-disabled' : ''}`} onClick={onClick}>
        {this.label}
        {image ? this.imageThumbnail : this.button}
      </div>
    );
  }

  // TODO: TriggerAsLinkProps?
  get triggerAsLink () {
    const {
      disabled = false,
      image,
      type = '',
      messageAsIcon = false,
      style = {},
      previewPosition,
      size = 'large',
    } = this.props;
    const onClick = disabled ? null : this.handleOpen;
    const message = `${image ? ' change' : ' upload'} ${type}${image ? '' : ' to start'}`;
    const position = previewPosition || 'bottom left';
    const visual = messageAsIcon ? <Icon size={size} name='image' /> : message;
    const triggerLink = (
      // @ts-expect-error TS(2322): Type '(() => void) | null' is not assignable to ty... Remove this comment to see the full error message
      <div className='hy-link' onClick={onClick}>
        {visual}
      </div>
    );

    return (
      <Popup
        position={position}
        pinned
        style={image ? style : {}}
        content={image ? this.imageThumbnail : CTA_TEXT}
        trigger={triggerLink}
      />
    );
  }

  get trigger () {
    const { triggerAsLink = false } = this.props;
    return triggerAsLink ? this.triggerAsLink : this.defaultTrigger;
  }

  render () {
    // TODO: do we need an intersection type for all props or is this the baseType that all props extend?
    const { images, browse, fromUrl, type, className = '', isCustomSrc } = this.props;
    const { open } = this.state;

    return (
      <div className={`hy-thumbnail-modal ${className}`}>
        {this.trigger}
        {open && (
          <AddImageModal
            browse={browse}
            fromUrl={fromUrl}
            setImage={this.setImage}
            close={this.handleClose}
            images={images}
            type={type}
            isCustomSrc={isCustomSrc}
          />
        )}
      </div>
    );
  }
}
