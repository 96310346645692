const defaultActions = [
  'addClass',
  'applyGate',
  'confirmAction',
  'disableSeek',
  'enableSeek',
  'enterFullscreen',
  'exitFullscreen',
  'hideAnnotation',
  'pause',
  'play',
  'releaseGate',
  'removeClass',
  'sendData',
  'setClass',
  'setPanel',
  'setVariables',
  'showAnnotation',
  'toAnnotation',
  'toggleAnnotation',
  'toLink',
  'toTime',
  'updateScore',
];

const AFTER_SECTION_ACTIONS = ['nextSection', 'goToSection', 'submitForm'];

export const PERMITTED_ACTIONS = {
  afterSection: AFTER_SECTION_ACTIONS,
  atEndTime: [...defaultActions],
  atStartTime: [...defaultActions],
  onActivate: [...defaultActions],
  onClick: [...defaultActions],
  onComplete: [...defaultActions],
  onConfirm: [...defaultActions],
  onDblClick: [...defaultActions],
  onDeactivate: [...defaultActions],
  onFail: [...defaultActions],
  onGate: ['toTime', 'toStart', 'toEnd', 'toSeekStart'],
  onHover: ['toggleClass', 'togglePlayback', 'toggleVisibility'],
  onLinkClose: [...defaultActions],
  onLinkOpen: [...defaultActions],
  onMouseDown: [...defaultActions],
  onMouseEnter: [...defaultActions],
  onMouseLeave: [...defaultActions],
  onMouseMove: [...defaultActions],
  onMouseUp: [...defaultActions],
  onPass: [...defaultActions],
  onSpecificAnswer: AFTER_SECTION_ACTIONS,
  onSubmit: [...defaultActions],
  question_onSpecificAnswer: [...defaultActions],
};
