import './index.scss';

import React from 'react';

import _ from 'lodash';

import { Dropdown, Grid } from '../../../../hapyak-ui-toolkit';
import { getCollections } from '../../../../services/toolRelationshipService';
import { toOption } from '../../../../services/utils';
import { Annotation } from '../../../../types/annotations';
import { findAssociatedQuizAnnotation } from '../AnnotationEditor/QuizBuilder/quizBuilderUtils';
import { ActionInputs } from './ActionInputs';
import { actionMap } from './actionMap';
import {
  createQuizActionDropdownOptions,
  findSupportedActionsByToolType,
  findSupportedEventsByToolType,
  getSectionOptionsForQuiz,
  supportsConditionals,
} from './utils';

export class QuizActionInputs extends ActionInputs {
  get assessedEvent () {
    const { event } = this.state;
    return event;
  }

  setCondition = (type: any, e: any, {
    value
  }: any) => {
    let additionalChanges = {};

    if (type === 'quizQuestion') {
      additionalChanges = { quizAnswer: 'correct' };
    }

    this.update({
      condition: {
        ...this.state.condition,
        [type]: value,
        ...additionalChanges
      }
    });
  };

  get toSectionOptions () {
    const { annotation = {} as Annotation } = this.props;
    return getSectionOptionsForQuiz(annotation);
  }

  componentDidUpdate () {
    if (!this.displayConditionals) return;

    const { condition = {} } = this.state;
    const { quizQuestion } = condition;
    const questions = this.quizSectionQuestions;
    const value = _.get(questions, '[0].value', '');

    if (quizQuestion || !value) return;

    this.setCondition('quizQuestion', null, { value });
  }

  get setupContext () {
    const { annotation = {} as Annotation, annotations = [] } = this.props;
    return { annotation, annotations };
  }

  get quizSectionQuestions () {
    const { annotation, annotations } = this.setupContext;
    const quizQuestionIds = getCollections(annotation);
    const isQuestion = (a: Annotation) => quizQuestionIds.includes(a.id);
    const filteredQuizQuestionAnnotations = annotations.filter(isQuestion);
    return createQuizActionDropdownOptions(annotation.id, filteredQuizQuestionAnnotations, 'content');
  }

  get quizAnswers () {
    const { annotation, annotations } = this.setupContext;
    const { condition = {} } = this.state;
    const { quizQuestion = '' } = condition;

    if (!quizQuestion) {
      return [];
    }

    const quizQuestionAnnotation = annotations.find((a: Annotation) => a.id === quizQuestion);
    const quizAnswerIds = getCollections(quizQuestionAnnotation);
    const quizAnswerAnnotations = annotations.filter((a: Annotation) => quizAnswerIds.includes(a.id));
    const defaultAnswers = ['correct', 'incorrect'].map(toOption);
    const quizAnswers = createQuizActionDropdownOptions(annotation.id, quizAnswerAnnotations, 'properties.value');
    return [...defaultAnswers, ...quizAnswers];
  }

  get supportedEvents () {
    const { annotation = {} as Annotation } = this.props;
    const quiz = findAssociatedQuizAnnotation(annotation.id);
    if (!quiz) return;
    const context = { hasQuizRelation: !!quiz.id, parentToolType: quiz.toolType };
    return findSupportedEventsByToolType(annotation.toolType, context);
  }

  get supportedActions () {
    const { annotation = {} as Annotation } = this.props;
    const quiz = findAssociatedQuizAnnotation(annotation.id);
    if (!quiz) return;
    return findSupportedActionsByToolType(annotation.toolType, !!quiz.id, quiz.toolType);
  }

  // @ts-expect-error TS(2611): 'permitsAnnotationConditionals' is defined as a pr... Remove this comment to see the full error message
  get permitsAnnotationConditionals () {
    const { annotation = {} as Annotation } = this.props;
    const hasAnswers = this.quizSectionQuestions.length > 0;
    return supportsConditionals(annotation) && hasAnswers;
  }

  // @ts-expect-error TS(2611): 'displayConditionals' is defined as a property in ... Remove this comment to see the full error message
  get displayConditionals () {
    const { event } = this.state;
    return this.permitsAnnotationConditionals && event === 'onSpecificAnswer';
  }

  render () {
    const { event, action, args, condition } = this.state;
    const { quizQuestion, quizAnswer } = condition;
    const argumentInputs = action && this.getActionArgInputs(action, args);
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const mappedActionTarget = (actionMap[action] || '').toUpperCase();
    const columns = this.displayConditionals ? 5 : 3;

    return this.wrap(
      <div>
        <Grid className='hy-input-labels' columns={columns}>
          <Grid.Row style={{ paddingBottom: 0 }}>
            <Grid.Column>WHEN THIS HAPPENS</Grid.Column>
            {this.displayConditionals && <Grid.Column>AND QUESTION IS</Grid.Column>}
            {this.displayConditionals && <Grid.Column>AND ANSWER IS</Grid.Column>}
            <Grid.Column>DO THIS</Grid.Column>
            <Grid.Column>{mappedActionTarget}</Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={columns}>
          <Grid.Row>
            <Grid.Column>
              <Dropdown
                search
                selection
                fluid
                label='Event'
                value={event}
                disabled={this.events.length < 2}
                options={this.events}
                onChange={this.setEvent}
              />
            </Grid.Column>
            {this.displayConditionals && (
              <Grid.Column>
                <Dropdown
                  search
                  selection
                  fluid
                  label='Answer To'
                  value={quizQuestion}
                  options={this.quizSectionQuestions}
                  onChange={this.setCondition.bind(this, 'quizQuestion')}
                />
              </Grid.Column>
            )}
            {this.displayConditionals && (
              <Grid.Column>
                <Dropdown
                  search
                  selection
                  fluid
                  label='Is'
                  placeholder='Select Answer'
                  value={quizAnswer}
                  options={this.quizAnswers}
                  onChange={this.setCondition.bind(this, 'quizAnswer')}
                />
              </Grid.Column>
            )}
            <Grid.Column>
              <Dropdown
                search
                selection
                fluid
                label='Action'
                value={action}
                options={this.actions}
                onChange={this.setAction}
              />
            </Grid.Column>
            <Grid.Column>{argumentInputs}</Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
