import React from 'react';

import './index.scss';
import { SemanticICONS } from 'semantic-ui-react';

import { comm } from '../../../../services/comm';
import { toSentenceCase } from '../../../../services/utils';
import { ToolbarButton } from './ToolbarButton';

type EditorToolbarProps = {
  parentRef: any;
  selecting: boolean;
  selectionTool: string;
  setSelectionTool: (selectionTool: string) => void;
  time: number;
};

export class EditorToolbar extends React.Component<EditorToolbarProps> {
  get selectionToolDefinitions () {
    return [
      {
        name: 'rectangle',
        shortcut: 'r',
        icon: 'square outline',
      },
      {
        name: 'ellipse',
        shortcut: 'e',
        icon: 'circle outline',
      },
      {
        name: 'lasso',
        shortcut: 'l',
        icon: 'laravel', // TODO: NEED BETTER ICON
      },
    ];
  }

  get selectionTools () {
    return this.selectionToolDefinitions.map((tool) => {
      const { selectionTool } = this.props;
      const selected = selectionTool === tool.name;
      const tooltip = `${toSentenceCase(tool.name)} [ ${tool.shortcut} ]`;
      return (<ToolbarButton key={tool.name} tooltip={tooltip} active={selected} icon={tool.icon as SemanticICONS} onClick={this.props.setSelectionTool.bind(this, tool.name)} />);
    });
  }

  clearSelection = () => {
    comm.trigger('selectAnnotations', []);
  };

  render () {
    const { selecting } = this.props;

    return (
      <div className='hy-toolbar hy-editor-toolbar'>
        {this.selectionTools}
        <ToolbarButton
          alt
          active={selecting}
          disabled={!selecting}
          icon='ban'
          tooltip='Clear Selection [ esc ]'
          onClick={this.clearSelection}
        />
      </div>
    );
  }
}
