import React from 'react';

import { Button, Icon, TrashFilledSVG } from '@newsela/angelou';

type TrashButtonProps = {
  disabled?: boolean;
  handleClick: any;
  trashIconStyle?: Record<string, any>;
};

export const TrashButton = ({
  disabled,
  handleClick,
}: TrashButtonProps) => {
  return (
    <Button
      ariaProps={{ 'aria-label': 'delete item' }}
      disabled={disabled}
      legacy_flavor={Button.legacy_flavor.incognito}
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        handleClick(event);
      }}
      __cssFor={{
        root: {
          width: '2rem',
          height: '2rem',
          path: {
            stroke: '#c32113',
            fill: 'none',
            strokeWidth: '3'
          }
        }
      }}
    >
      <Icon SvgComponent={TrashFilledSVG} isDecorative={false} title='delete item' />
    </Button>
  );
};
