import React from 'react';

import './index.scss';
import DEFAULTS from '../../../../DEFAULTS.json';
import { stateController } from '../../../../services/stateController';
import { StoryboardOptionPreview } from './StoryboardOptionPreview';

type State = any;

type StoryboardPreviewProps = {
  items?: any;
  readyNumber?: number;
  totalNumber?: number;
};

class StoryboardPreview extends React.Component<StoryboardPreviewProps, State> {
  items: any;
  readyNumber: any;
  totalNumber: any;
  constructor (props: StoryboardPreviewProps) {
    super(props);
    this.readyNumber = 0;

    this.state = {
      stateLoaded: false,
      sayCheese: false,
    };

    setTimeout(this.markReady, 15 * 1000); // after 15 seconds, force the "sayCheeseId" div to show as a fallback if there are any broken image links
  }

  async componentDidMount () {
    await stateController.loadRemoteState();
    this.items = stateController.getCurrentData().storyboard.items;
    this.totalNumber = this.items.length;
    this.setState({ stateLoaded: true });
  }

  handlePageLoad = () => {
    this.readyNumber += 1;
    if (this.readyNumber >= this.totalNumber) {
      this.markReady();
    }
  };

  markReady = () => {
    this.setState({ sayCheese: true });
  };

  render () {
    if (!this.state.stateLoaded) return null;

    const displayItems = this.items.map((item: any, index: any) => {
      return (
        <StoryboardOptionPreview
          key={item.image}
          index={index}
          {...item}
          handlePageLoad={this.handlePageLoad}
          preview
        />
      );
    });

    return (
      <div>
        {this.state.sayCheese && <div id={DEFAULTS.sayCheeseId} />}
        {displayItems}
      </div>
    );
  }
}

export { StoryboardPreview };
