import React from 'react';

import { ModalProps } from 'semantic-ui-react';

import { JobError } from '../../../errors/JobError';
import { Button, Modal, ConfirmModal, CustomDropdown } from '../../../hapyak-ui-toolkit';
import './index.scss';
import { LEXILE_LEVEL_OPTIONS } from '../../../services/lexileService';
import { assetService } from '../../../services/stateController';
import { toOption } from '../../../services/utils';
import ErrorBanner from '../AngelouComponents/ErrorBanner';
import WarningBanner from '../AngelouComponents/WarningBanner';
import { BasicSection } from '../CommonComponents/Menu/BasicSection/index';
import { RunningStatus } from '../CommonComponents/RunningStatus';
import { ISO_DROPDOWN_OPTIONS } from './isoLanguageCodes';

type State = any;

type AccessibilityUploadModalProps = ModalProps & {
  afterUpload?: (...args: any) => void;
  beforeUpload?: (...args: any) => void;
  forceOpen: boolean;
  lexileLevel?: string;
  onClose: () => void;
  projectId?: string;
  selected: any;
  type: string;
};

export class AccessibilityUploadModal extends React.Component<AccessibilityUploadModalProps, State> {
  assessFile: any;
  fileUploadComponent: any;
  forceLanguage: any;
  forceLexileLevel: any;
  startUpload: any;
  constructor (props: AccessibilityUploadModalProps) {
    super(props);
    this.state = { ...this.getInitialState(props) };
  }

  getInitialState = (props: any) => {
    return {
      open: false,
      uploading: false,
      fileAvailable: false,
      fileLanguage: this.forceLanguage || 'en',
      file: {},
      fileLocale: '',
      lexileLevel: this.forceLexileLevel || props.lexileLevel || 'MAX',
      error: null,
    };
  };

  get selectedLexileLevel () {
    const { lexileLevel } = this.state;
    return lexileLevel || this.props.lexileLevel;
  }

  get willOverwrite () {
    const { type } = this.props;
    const iso = this.selectedLanguage;

    return assetService.getArbArray().find((a: any) => {
      return a.type === type && a.language === iso && a.lexileLevel === this.selectedLexileLevel;
    });
  }

  open = () => this.setState({ open: true });

  close = () => {
    const { onClose } = this.props;
    this.setState(this.getInitialState(this.props));
    if (onClose) onClose();
  };

  beforeUpload = (...args: any[]) => {
    const { beforeUpload } = this.props;
    if (typeof beforeUpload === 'function') beforeUpload(...args);
  };

  afterUpload = (...args: any[]) => {
    const isoLanguageCode = this.selectedLanguage;
    const { afterUpload } = this.props;

    this.setState({ loading: false });

    if (typeof afterUpload === 'function') {
      afterUpload({ args: { ...args }, language: isoLanguageCode, lexileLevel: this.selectedLexileLevel });
    }

    this.close();
  };

  handleFileChange = ({
    file
  }: any) => {
    const hasFile = file && file.name && file.size;
    this.setState({ fileAvailable: hasFile, file, error: null }, this.assessFile);
  };

  handleToggle = (key: any) => {
    this.setState({ [key]: !this.state[key] });
  };

  get uploadingMessage () {
    return (
      <RunningStatus
        icon={{ name: 'upload' }}
        header="Don't go!"
        content='Stay put for a moment while we upload your file.'
        color='blue'
      />
    );
  }

  get confirmProps () {
    return {
      header: 'Overwrite data?',
      content: `The "${this.selectedLanguage}" file will be overwritten. Are you sure you want to continue?`,
    };
  }

  onUploadError = (error: JobError) => {
    this.setState({
      ...this.getInitialState(this.props),
      error: error,
    });
  };

  onDropdownChange = (type: any, e: any, {
    value
  }: any) => {
    this.setState({ [type]: value });
  };

  get selectedLanguage () {
    const { fileLanguage } = this.state;
    const { selected = {} } = this.props;
    if (selected.language) return selected.language;
    return fileLanguage;
  }

  get wrongLanguageWarning () {
    const { fileLocale } = this.state;

    if (!this.selectedLanguage || !fileLocale) return null;

    if (this.selectedLanguage === fileLocale) return null;

    return (
      <>
        <WarningBanner
          title={"File language doesn't match selected langauge"}
          body='If you continue, the language you have selected will be used.'
        />
      </>
    );
  }

  render () {
    const { forceOpen, header = '', trigger, selected, type, message } = this.props;
    const { open, uploading, fileAvailable, error } = this.state;
    const displayConfirm = !!this.willOverwrite;
    const language = this.selectedLanguage;
    const buttonProps = {
      disabled: uploading || !fileAvailable || !language,
      loading: uploading,
      content: 'Upload and continue',
      primary: true,
    };

    return (
      <Modal
        open={open || forceOpen}
        className='hy-thumbnail-modal'
        size='tiny'
        trigger={trigger}
        onClose={this.close}
        closeOnEscape={!uploading}
        closeOnDimmerClick={!uploading}
      >
        {header && <Modal.Header>{header}</Modal.Header>}
        <Modal.Content>
          <Modal.Description>
            <BasicSection
              sectionPadding={false}
              renderedContent={[
                {
                  renderedContent: (
                    <div className='hy-form-field'>
                      <div className='hy-margin-bottom'>
                        <CustomDropdown
                          selection
                          search
                          value={type}
                          label='Type'
                          disabled
                          options={[toOption(type)]}
                        />
                      </div>

                      <div className='hy-margin-bottom'>
                        <CustomDropdown
                          selection
                          search
                          value={this.selectedLexileLevel}
                          label='Lexile Level'
                          disabled={!!this.forceLexileLevel || (selected && !!selected.id)}
                          onChange={this.onDropdownChange.bind(this, 'lexileLevel')}
                          options={LEXILE_LEVEL_OPTIONS}
                        />
                      </div>
                      <div className='hy-margin-bottom'>
                        <CustomDropdown
                          selection
                          search
                          value={language}
                          label='Language'
                          disabled={!!this.forceLanguage || (selected && !!selected.id)}
                          onChange={this.onDropdownChange.bind(this, 'fileLanguage')}
                          options={ISO_DROPDOWN_OPTIONS}
                        />
                      </div>
                      {this.fileUploadComponent}
                    </div>
                  ),
                },
              ]}
            />
            {this.wrongLanguageWarning}
            {uploading && this.uploadingMessage}
            {error &&
              <ErrorBanner
                title='Upload error'
                body='There was a problem uploading your file.'
                error={error}
              />}
            {message}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={uploading} content='Cancel' onClick={this.close} />
          {displayConfirm && (
            <ConfirmModal
              primary
              className='hy-margin-left'
              buttonProps={buttonProps}
              confirmCallback={this.startUpload}
              {...this.confirmProps}
            />
          )}
          {!displayConfirm && <Button {...buttonProps} onClick={this.startUpload} />}
        </Modal.Actions>
      </Modal>
    );
  }
}
