import _ from 'lodash';

import DEFAULTS from '../../DEFAULTS.json';
import routes from '../../routes.json';
import { toPath } from '../navUtils';
import { deleteSave, loadFromLocalStorage, updateLocalStorage } from '../saveUtils';
import { stateController } from '../stateController';
import { attemptImport, getExportJSON } from '../stateUtils';
import { PersistenceClient } from './persistenceClient';

// abstraction of project crud api
export class LocalPersistenceClient extends PersistenceClient {
  isLocal = true;

  // Project CRUD:
  create = (data: any) => {
    return Promise.resolve(data);
  };

  update = () => {
    updateLocalStorage({ [DEFAULTS.PROJECT_KEY]: getExportJSON() });
  };

  // @ts-expect-error TS(2416): Property 'read' in type 'LocalPersistenceClient' i... Remove this comment to see the full error message
  read = (match: any) => {
    if (!routes.map((r: any) => r.path).includes(match.url)) {
      return null;
    }

    return attemptImport(this._loadProject()); // "attemptImport" to see if we CAN import this project
  };

  // @ts-expect-error TS(2416): Property 'list' in type 'LocalPersistenceClient' i... Remove this comment to see the full error message
  list = () => {
    const localProject = this._loadProject();
    return Promise.resolve([localProject]);
  };

  // @ts-expect-error TS(2416): Property 'importProject' in type 'LocalPersistence... Remove this comment to see the full error message
  importProject = (data: any) => {
    deleteSave();
    stateController.setCurrentData(data);
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
    toPath(this.getUXPath());
  };

  // Utils:
  getUXPath = (projectId: any) => {
    return DEFAULTS.UX_PATH;
  };

  getPath = (match: any, route: any) => {
    return route.path; // never keep intermediary path
  };

  getPathPrefix = () => {
    return '';
  };

  // Internal Utils:
  _loadProject = () => {
    return _.get(loadFromLocalStorage(), DEFAULTS.PROJECT_KEY, null);
  };
}
