import React from 'react';

import { Previewer } from 'pagedjs'; // we may want to look at the polyfill if browser support for the npm package is lacking

import { Placeholder } from '../../../../hapyak-ui-toolkit';
import { assetService } from '../../../../services/stateController';
import { DeviceWrapper } from '../../../PreviewSection/DeviceWrapper';
import { getPdfSettings } from '../pageSettings';

type State = any;

type StoryboardOptionPreviewProps = {
  contentElement: any;
  paged: any;
  pages: any;
  image: any;
  description: any;
  onCLick: any;
  index: any;
  device: any;
  portrait: any;
  onClick: any;
  handlePageLoad: () => void | undefined;
};

class StoryboardOptionPreview extends React.Component<StoryboardOptionPreviewProps, State> {
  contentElement: any;
  paged: any;
  pages: any;
  constructor (props: StoryboardOptionPreviewProps) {
    super(props);
    this.paged = new Previewer();
    this.contentElement = React.createRef();
    this.pages = React.createRef();
    this.state = { loadedPage: false, numPages: 1 };
  }

  UNSAFE_componentWillReceiveProps (nextProps: any) {
    const { image, description } = this.props;
    if (nextProps.image !== image || nextProps.description !== description) {
      this.setState({ loadedPage: false });
    }
  }

  get wrapperStyle () {
    const { imageMargin } = getPdfSettings(1, true);

    return {
      display: 'block',
      width: '100%',
      backgroundColor: '#eee',
      padding: imageMargin,
      marginBottom: imageMargin,
      WebkitUserDrag: 'none',
    };
  }

  handleImageLoad = () => {
    this.pages.current.innerHTML = '';
    this.paged = new Previewer();
    this.paged.preview(this.contentElement.current.innerHTML, ['./paged.css'], this.pages.current).then((flow: any) => {
      this.setState({ loadedPage: true, numPages: flow._total }, () => {
        if (typeof this.props.handlePageLoad === 'function') this.props.handlePageLoad();
      });
    });
  };

  onClick = (e: any) => {
    const { onClick } = this.props;
    if (typeof onClick === 'function') onClick(e);
  };

  get content () {
    const { index, device, portrait, image, description } = this.props;

    return (
      <div ref={this.contentElement} style={{ transform: 'scale(0)', position: 'absolute' }}>
        <div style={this.wrapperStyle}>
          <DeviceWrapper device={device} portrait={portrait} downscale={1}>
            <img
              alt={`Storyboard screen ${index}`}
              src={assetService.getImage(image).src}
              style={{ width: '100%' }}
              onLoad={this.handleImageLoad}
            />
          </DeviceWrapper>
        </div>
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    );
  }

  render () {
    const { loadedPage } = this.state;
    const placeholder = (
      <Placeholder style={{ backgroundColor: '#fff' }}>
        <Placeholder.Image />
      </Placeholder>
    );

    return (
      <div onClick={this.onClick} className='hy-page-break-before'>
        {!loadedPage && placeholder}
        {!loadedPage && this.content}
        <div ref={this.pages} style={{ position: 'relative', opacity: loadedPage ? 1 : 0 }} />
      </div>
    );
  }
}

export { StoryboardOptionPreview };
