import axios from 'axios';
import _ from 'lodash';

import { Bundle } from 'types/publishing';

import { JobError } from '../../../errors/JobError';
import { groupConfig } from '../../configurationService';
import { LEXILE_LEVEL_DEFAULT } from '../../lexileService';
import logger from '../../logger';
import { media } from '../../mediaController';
import { requestAssetsList } from '../../packagingService';
import sessionClient from '../../sessionClient';
import signingService from '../../signingService';
import { assetService, stateController } from '../../stateController';
import { saveJSON } from '../../stateUtils';
import { getTranscriptsFromVTT } from '../../translation/utils';
import { convertToS3URL, resolvePlatformHost } from '../../utils';
import { Publisher } from '../Publisher';

const RECONNECT_TIME = 10000; // ms

const createDataservicesPayload = (lists: any, bundleProps: Bundle, bundleId: any, provider: any) => {
  const { downloadable, publishTo } = bundleProps;

  const payload = {
    operations: [
      {
        name: 'publish',
        parameters: {
          publishingUUID: bundleId,
          bundling: {
            provider: 'null',
            zip: downloadable,
            publishTo,
          },
          hosting: {
            provider: provider || 'aws-s3',
          },
        },
      },
    ],
  };

  logger.log('payload:', payload);

  return payload;
};

const getPublishedLocation = (token: any) => {
  return _.get(token, 'payload.publishingRoot', 'PACKAGE_LOCATION_UNDEFINED');
};

const packageForAWS = async (token: any, payload: any, transaction = {}) => {
  return new Promise(async (resolve, reject) => {
    const platformHost = resolvePlatformHost();

    logger.log('PLATFORM PAYLOAD: %o', payload);

    try {
      const response = await axios({
        url: `${platformHost}/back-end/jobs`,
        method: 'post',
        withCredentials: true,
        data: {
          command: 'jobType:StepFunction:Bundle',
          data: payload,
          transaction,
        },
        headers: {
          Authorization: `Bearer ${token.token}`,
        },
      });

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response);
      }
    } catch (err) {
      reject(err);
    }
  });
};

const createBundlePayload = async (token: any, lists: any, bundleProps: Bundle) => {
  const assetListResponse = await saveJSON('assetList', { list: lists.list, videos: lists.videos });
  const tokenParameters = _.get(token, 'payload.operations[0].parameters');

  const { bundleName } = bundleProps;

  return {
    assetListUrl: assetListResponse.data,
    bundling: tokenParameters.bundling,
    dubbing: getDubbingObject(bundleProps),
    emblazoning: getEmblazoningObject(bundleProps, lists.signedPlayerConfig),
    hosting: tokenParameters.hosting,
    nativeLanguage: media.video.language,
    nativeLexileLevel: media.video.lexileLevel || LEXILE_LEVEL_DEFAULT,
    publishingRoot: getPublishedLocation(token),
    publishingUUID: tokenParameters.publishingUUID, // bundling, hosting
    alexandriaObject: getAlexandriaObject(token, lists),
    transcoding: getTranscodingObject(lists.videos[0].watermark),
    bundleName,
  };
};

const getEmblazoningObject = (bundleProps: Bundle, projectConfig: any) => {
  const languages = bundleProps.emblazonLanguages || [];

  if (!projectConfig || !languages.length) return null;

  const { duration, height, width } = media.video;

  return {
    languages,
    projectConfig,
    videoMetadata: {
      width,
      height,
      frameRate: 30, // TODO: get from transcode results
      duration,
    },
  };
};

const getDubbingObject = (bundleProps: Bundle) => {
  if (!bundleProps.dub) return null;

  const vtts = assetService.getVttArray().map((vtt: any) => {
    const { language, lexileLevel, src } = vtt;
    return { language, lexileLevel, src: signingService.unsign(src) };
  });

  if (!vtts.length) return null;

  return { vtts };
};
const getAlexandriaObject = (token: any, lists: any) => {
  const { duration } = media.video;
  const state = stateController.getCurrentData();
  const publishingRoot = token.payload.publishingRoot; // ends with slash
  const configSrc = `${publishingRoot}${lists.list[0].dest.replace('./', '')}`;
  const imgSrc = `${publishingRoot}${lists.images[0].dest.replace('./', '')}`;
  const description = _.get(state, 'project.description');
  const gradeBand = _.get(state, 'project.gradeBand');
  const name = _.get(state, 'project.title');
  const tags = _.get(state, 'project.tags');
  const alexandriaId = _.get(state, 'project.alexandriaId');
  const maturityRange = _.get(state, 'project.maturityRange');
  const transcripts = getTranscriptsFromVTT(assetService.getVttArray());
  const pushToAlexandriaIsEnabled = _.get(
    groupConfig,
    'configuration.authoring.hapyakTools.pushToAlexandriaEnabled',
    false
  );

  return {
    alexandriaId,
    configSrc,
    imgSrc,
    description,
    gradeBand,
    tags,
    pushToAlexandriaIsEnabled,
    maturityRange,
    transcripts,
    videoMetadata: {
      duration,
      name,
    },
  };
};

const getTranscodingObject = (watermark: any) => {
  if (!watermark) return null;
  return { watermarks: [{ s3Uri: convertToS3URL(watermark) }] };
};

export class AwsS3Publisher extends Publisher {
  provider: any;
  // @ts-expect-error TS(2416): Property '_publish' in type 'AwsS3Publisher' is no... Remove this comment to see the full error message
  _publish = async (bundleId: string) => {
    const lists = await requestAssetsList(stateController.getCurrentData(), this.bundleProps);
    this.setBundledImages(lists.images);
    const token = await sessionClient.getServiceAccessToken(
      createDataservicesPayload(lists, this.bundleProps, bundleId, this.provider)
    );
    const input = await createBundlePayload(token, lists, this.bundleProps);
    const state = stateController.getCurrentData();
    state.configSource = input.alexandriaObject.configSrc;
    state.landingPage = input.alexandriaObject.configSrc.replace('config.json', '');
    stateController.setCurrentData(state);
    return packageForAWS(token, input, this.transactionData);
  };

  _reconnect = async () => {
    const bundle = assetService.getCurrentBundle();
    const statusUrl = bundle.statusUrl;

    if (!statusUrl) {
      return;
    }

    return axios
      .get(signingService.sign(statusUrl))
      .then(({ data = null }) => {
        if (data && data.status === 'SUCCESS') {
          return data.data;
        } else if (!data || data.error || data.status === 'FAILURE') {
          console.error('data.error', data.error);
          throw new JobError(data?.error?.message || 'Error creating bundle', data?.traceId);
        }

        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(this._reconnect());
          }, RECONNECT_TIME);
        });
      })
      .catch((error) => {
        logger.log('Could not fetch results.json: %o', error);
        throw error;
      });
  };

  _stop = () => {
    const bundle = assetService.getCurrentBundle();
    if (bundle.statusUrl) {
      this.saveBundleWithoutStatusUrl(bundle);
    }
    return Promise.resolve();
  };
}
