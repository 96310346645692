import { v4 as uuid } from 'uuid';

export const createPauseEvent = (event = 'onActivate') => {
  return {
    event,
    action: 'pause',
    args: {},
  };
};

export const createPlayAction = (event = 'onSubmit') => {
  return {
    id: 'action_' + uuid(),
    event,
    action: 'play',
    args: {},
  };
};

export const createHideQuizAction = (quizId: any, event = 'onClick') => {
  return {
    event,
    action: 'hideAnnotation',
    args: {
      value: quizId,
    },
    condition: {},
  };
};

export const createSubmitQuizAction = (quizId: any) => {
  return {
    event: 'onClick',
    action: 'submitForm',
    args: {
      value: quizId,
    },
  };
};

export const createGoToAnnotationAction = (path: any) => {
  return {
    event: 'onClick',
    action: 'toAnnotation',
    args: {
      value: path,
    },
  };
};

export const createReleaseAnnotationGateAction = ({
  quizId,
  event = 'onClick'
}: any) => {
  return {
    id: 'action_' + uuid(),
    event,
    action: 'releaseGate',
    args: {
      value: quizId,
    },
  };
};

export const createQuizFinalSubmitActions = ({
  quizId
}: any) => {
  return [createHideQuizAction(quizId), createSubmitQuizAction(quizId)];
};

export const createDefaultQuizActions = ({
  quizId,
  evaluatedQuiz = false
}: any) => {
  const quizSpecificEvents = evaluatedQuiz ? ['onPass', 'onFail'] : ['onSubmit'];
  const events = quizSpecificEvents.map((event) => {
    return createReleaseAnnotationGateAction({ quizId, event });
  });

  return [...events, createPauseEvent('onActivate')];
};
