import React, { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from 'react';

import { Button, ChevronLeftSVG, GridLayout, TextArea } from '@newsela/angelou';
import { Menu, Tab } from 'semantic-ui-react';

import { VideoAsset } from 'types/assets';
import { MaturityRange } from 'types/project';

import { comm } from '../../../services/comm';
import { LEXILE_LEVEL_DEFAULT } from '../../../services/lexileService';
import NewProjectService from '../../../services/newProjectService';
import { stateController } from '../../../services/stateController';
import { AngelouInput } from '../AngelouComponents/AngelouInput';
import { VideoUploadComponent } from '../CommonComponents/VideoUploadComponent';
import { AccessibilityUploadComponent } from './AccessibilityUploadComponent';
import { MaturityRangeSelector } from './MaturityRangeSelector';
import { ProjectTemplate } from './ProjectTemplate';

type NewProjectFormProps = {
  setWorking: Dispatch<SetStateAction<boolean>>;
  setProjectId: Dispatch<SetStateAction<string>>;
  setActive: MutableRefObject<null> | Dispatch<any> | { isActive: any; closeModal: () => void; onEsc: (e: any) => void; triggerRef: MutableRefObject<null>; }
  setModalTitle: Dispatch<SetStateAction<string>>;
  setErrorMsg: Dispatch<SetStateAction<string>>;
  errorMsg: string;
  setHasVideo: Dispatch<SetStateAction<boolean>>;
  setHasCaptions: Dispatch<SetStateAction<boolean>>;
  setHasAudioDescription: Dispatch<SetStateAction<boolean>>;
};

export const NewProjectForm = ({
  setWorking,
  setProjectId,
  setActive,
  setModalTitle,
  setErrorMsg,
  errorMsg,
  setHasVideo,
  setHasCaptions,
  setHasAudioDescription
}: NewProjectFormProps) => {
  const [templateId, setTemplateId] = useState(1);
  const [isEnabled, setIsEnabled] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  const [buttonTitle, setButtonTitle] = useState('Next: Attach Video');
  const [uploadFromMyComputer, setUploadFromMyComputer] = useState(false);
  const [file, setFile] = useState<VideoAsset>();
  const [videoLanguage, setVideoLanguage] = useState({
    value: 'en',
    label: 'English [en]'
  });
  const [captionLanguage, setCaptionLanguage] = useState({
    value: 'en',
    label: 'English [en]'
  });
  const [audioLanguage, setAudioLanguage] = useState({
    value: 'en',
    label: 'English [en]'
  });
  const [lexileLevelObject, setLexileLevelObject] = useState({
    label: LEXILE_LEVEL_DEFAULT,
    value: LEXILE_LEVEL_DEFAULT
  });
  const [vttFile, setVttFile] = useState();
  const hasCaptionFile = () => {
    return !!vttFile;
  };
  const [mp3File, setMp3File] = useState();
  const [vttFileName, setVttFileName] = useState('');
  const [mp3FileName, setMp3FileName] = useState('');
  const [videoFilename, setVideoFilename] = useState('');
  const [maturityRange, setRange] = useState<MaturityRange>([]);
  const hasAudioFile = () => {
    return !!mp3File;
  };

  comm.register('processStarted', NewProjectService.processStarted);
  comm.register('processComplete', NewProjectService.processComplete);

  useEffect(() => {
    setModalTitle(title);

    switch (activeIndex) {
      case 0: setButtonTitle('Next: Attach Video');
        break;
      case 1: setButtonTitle('Next: Accessibility');
        break;
      case 2: setButtonTitle('Create Project');
        break;
    }
  }, [title, activeIndex]);

  const newProjectFlow = async () => {
    setWorking(true);

    await NewProjectService.createProject(
      templateId,
      { title, description, maturityRange },
      setProjectId,
      setWorking,
      setErrorMsg
    );

    const { id } = stateController.getCurrentData('project');
    if (hasCaptionFile()) {
      setHasCaptions(true);
      await NewProjectService.uploadVttFile(id, vttFile, captionLanguage.value, lexileLevelObject.value);
    }
    if (hasAudioFile()) {
      setHasAudioDescription(true);
      await NewProjectService.uploadMp3File(mp3File, audioLanguage.value, lexileLevelObject.value);
    }
    if (uploadFromMyComputer && file !== undefined) {
      setHasVideo(true);
      await NewProjectService.uploadVideo(id, file, videoLanguage.value, lexileLevelObject.value);
    } else {
      setWorking(false);
      return;
    }

    await NewProjectService.setVideo();
  };

  const formElements = [
    [
      <AngelouInput
        key={0}
        label='Project/video name (required)'
        errorMsg='Project/video name is required'
        isRequired
        value={title}
        setValue={setTitle}
        isFilled={setIsEnabled}
      />
    ],
    [
      <TextArea
        key={2}
        label='Description'
        isRequired={false}
        value={description}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setDescription(e.target.value);
        }}
        autoAdjustHeight
      />
    ],
    [
      <MaturityRangeSelector setRange={setRange} range={maturityRange} key={4} />
    ],
    [
      <div id='template-title' key={5}>
        Project template (required)
      </div>
    ],
    [
      <ProjectTemplate
        key={6}
        setTemplateId={setTemplateId}
        templateId={templateId}
      />
    ]

  ];

  const videoMenuItem = () => {
    return isEnabled ? '2. Video' : <Menu.Item key={21} disabled>2. Video</Menu.Item>;
  };

  const accessibilityMenuItem = () => {
    return isEnabled ? '3. Accessibility' : <Menu.Item key={22} disabled>3. Accessibility</Menu.Item>;
  };

  const onTabChange = (newIndex: string | number | undefined): void => {
    if (typeof newIndex === 'undefined') {
      newIndex = 0;
    }
    if (typeof newIndex === 'string') {
      newIndex = parseInt(newIndex);
    }
    setActiveIndex(newIndex);
  };

  const tabs = () => {
    return (
      <Tab
        activeIndex={activeIndex}
        menu={{ borderless: true }}
        renderActiveOnly
        onTabChange={(e, { activeIndex }) => onTabChange(activeIndex)}
        panes={[
          {
            menuItem: '1. Details',
            render: () => (
              <Tab.Pane key={1}>
                <div className='tab-inner'>
                  {
                    formElements.map((formElement, idx) => {
                      return (
                        <GridLayout key={idx} __classNameFor={{ root: 'grid-layout-min-width-300px' }}>
                          {formElement}
                        </GridLayout>
                      );
                    })
                  }
                </div>
              </Tab.Pane>
            ),
          },
          {
            menuItem: videoMenuItem(),
            render: () => (
              <Tab.Pane key={2}>
                <div className='tab-inner'>
                  <VideoUploadComponent
                    uploadFromMyComputer={uploadFromMyComputer}
                    videoLanguage={videoLanguage}
                    toggleUploadFromMyComputer={() => {
                      setUploadFromMyComputer(true);
                    }}
                    onLanguageChange={(e: any) => { setVideoLanguage(e.selectedItem); }}
                    handleFileChange={({ file = {} as VideoAsset }) => { setFile(file); }}
                    afterUpload={() => null}
                    beforeUpload={() => null}
                    videoFilename={videoFilename}
                    setVideoFilename={setVideoFilename}
                  />
                </div>
              </Tab.Pane>
            ),
          },
          {
            menuItem: accessibilityMenuItem(),
            render: () => (
              <Tab.Pane key={3}>
                <div className='tab-inner'>
                  <AccessibilityUploadComponent
                    audioLanguage={audioLanguage}
                    captionLanguage={captionLanguage}
                    hasAudioFile={hasAudioFile}
                    hasCaptionFile={hasCaptionFile}
                    lexileLevelObject={lexileLevelObject}
                    setAudioLanguage={setAudioLanguage}
                    setCaptionLanguage={setCaptionLanguage}
                    setLexileLevelObject={setLexileLevelObject}
                    setMp3File={(file: any) => { file = { file }; setMp3File(file); }}
                    setVttFile={(file: any) => { file = { file }; setVttFile(file); }}
                    vttFileName={vttFileName}
                    setVttFileName={setVttFileName}
                    mp3FileName={mp3FileName}
                    setMp3FileName={setMp3FileName}
                  />
                </div>
              </Tab.Pane>
            ),
          },
        ]}
      />
    );
  };

  const errorItem = [
    [
      <p key={0} className='errorMsg'>{errorMsg}</p>
    ]
  ];

  return (
    <div className='create-project-modal'>
      <h1 id='modal-title'>Create a project</h1>
      <div className='body'>
        {tabs()}
        {errorMsg && <GridLayout>{errorItem[0]}</GridLayout>}
      </div>
      <div className='footer'>
        {(activeIndex === 1 || activeIndex === 2) &&
          <div className='f-horizontal-separator-back'>
            <Button
              legacy_flavor={Button.legacy_flavor.solid}
              legacy_statusColor={Button.legacy_statusColor.tertiary}
              __classNameFor={{ root: 'tertiary' }}
              legacy_size={Button.legacy_size.medium}
              onClick={() => setActiveIndex(activeIndex - 1)}
              disabled={!isEnabled}
            >
              <ChevronLeftSVG /> Back
            </Button>
          </div>}
        <div className='f-inner'>
          <div className='f-horizontal-separator'>
            <Button
              legacy_flavor={Button.legacy_flavor.solid}
              legacy_statusColor={Button.legacy_statusColor.tertiary}
              __classNameFor={{ root: 'tertiary' }}
              legacy_size={Button.legacy_size.medium}
              onClick={() => { if (typeof setActive === 'function') setActive(false); }}
            >
              Cancel
            </Button>
          </div>
          <div className='f-horizontal-separator'>
            <Button
              legacy_flavor={Button.legacy_flavor.solid}
              legacy_statusColor={Button.legacy_statusColor.primary}
              legacy_size={Button.legacy_size.medium}
              onClick={async () => {
                switch (activeIndex) {
                  case 0: setActiveIndex(1);
                    break;
                  case 1: setActiveIndex(2);
                    break;
                  case 2: await newProjectFlow();
                    break;
                }
              }}
              disabled={!isEnabled}
            >
              {buttonTitle}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
