import { v4 as uuid } from 'uuid';

import { Action } from 'types/actions';

const _newActionId = () => 'action_' + uuid();

export const COMPLEX_EVENTS = [
  {
    complexEventName: 'onHover',
    canCauseComplexActions: ['togglePlayback', 'toggleClass', 'toggleVisibility'],
    mappedMonoEvents: ['onMouseEnter', 'onMouseLeave'],
  },
];

export const COMPLEX_ACTIONS = [
  {
    complexActionName: 'togglePlayback',
    mappedMonoActions: ['pause', 'play'],
  },
  {
    complexActionName: 'toggleClass',
    mappedMonoActions: ['addClass', 'removeClass'],
  },
  {
    complexActionName: 'toggleVisibility',
    mappedMonoActions: ['hideAnnotation', 'showAnnotation'],
  },
];

export const getComplexEvent = ({
  event
}: any) => {
  return COMPLEX_EVENTS.find((evt) => evt.complexEventName === event);
};

const getComplexAction = ({
  action
}: any) => {
  return COMPLEX_ACTIONS.find((act) => act.complexActionName === action);
};

export const replaceComplexActions = (action: Action) => {
  const complexEvent = getComplexEvent({ event: action.event });

  if (!complexEvent) return action;

  const complexAction = getComplexAction({ action: action.action });
  const mappedEventToAction = complexEvent.mappedMonoEvents.map((evt, idx) => {
    return {
      id: _newActionId(),
      event: evt,
      action: complexAction?.mappedMonoActions[idx],
      args: action.args,
    };
  });

  return mappedEventToAction;
};
