import React from 'react';

import defaults from '../../DEFAULTS.json';
import { comm } from '../../services/comm';
import sessionClient from '../../services/sessionClient';
import { HomeScreen } from '../editors/AssetsEditor/HomeScreen';
import { ProjectStarter } from '../editors/AssetsEditor/ProjectStarter';
const { DRIVE_QUERY_LOCATION } = defaults;

class GoogleDriveHomeScreen extends HomeScreen {
  constructor (...args: any[]) {
    // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
    super(...args);
    comm.register('sessionEvent', this.onSessionEvent);
  }

  get projectStarter () {
    const { props } = super.projectStarter;
    return <ProjectStarter {...props} showRecent={false} />;
  }

  componentDidMount () {
    const { history, location } = this.props;

    if (location.search) {
      localStorage.setItem(DRIVE_QUERY_LOCATION, location.search);
      history.replace({ search: '' });
    }
  }

  onSessionEvent = (eventName: any) => {
    if (eventName === 'logged-in' && !sessionClient.getUsingGoogleDrive()) {
      // if a user is at this route,they MUST be logged in with
      // Google Drive to be able to create a new project
      sessionClient.logout();
    }
  };
}

export { GoogleDriveHomeScreen };
