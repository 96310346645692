import React from 'react';

import { Table } from '../../../../hapyak-ui-toolkit';
import { VideoFormats } from './VideoFormats';
import { VideoMetadata } from './VideoMetadata';

type VideoAssetRowProps = {
  src: string;
}

export const VideoAssetRow = (props: VideoAssetRowProps) => {
  const { src } = props;

  return (
    <Table.Row>
      <Table.Cell>
        <video src={src} controls width='100%' />
      </Table.Cell>
      <Table.Cell textAlign='left' verticalAlign='top'>
        <VideoFormats />
      </Table.Cell>
      <Table.Cell textAlign='left' verticalAlign='top'>
        <ul style={{ listStyleType: 'none' }}>
          <VideoMetadata />
        </ul>
      </Table.Cell>
    </Table.Row>
  );
};
