import defaults from '../layoutConfig.json';
const { HORIZONTAL_DEFAULT, VERTICAL_DEFAULT } = defaults;

const hy = (window as any).hy;

export const layoutTargets = [
  {
    name: 'root',
    layout: hy.LAYOUT_TYPES.STACK,
    direction: hy.LAYOUT_ORIENTATION.VERTICAL,
  },
  {
    name: 'hidden',
    parent: 'root',
    height: 0,
    width: 0,
    _hidden: true,
    _target: true,
  },
  {
    name: hy.ANNOTATION_TARGETS.ADJACENT_TOP,
    parent: 'root',
    layout: hy.LAYOUT_TYPES.FILL,
    height: VERTICAL_DEFAULT,
    _dimension: 'height',
    _direction: 'top',
    _target: true,
  },
  {
    name: 'middle',
    parent: 'root',
    layout: hy.LAYOUT_TYPES.STACK,
    direction: hy.LAYOUT_ORIENTATION.HORIZONTAL,
  },
  {
    name: hy.ANNOTATION_TARGETS.ADJACENT_LEFT,
    parent: 'middle',
    layout: hy.LAYOUT_TYPES.FILL,
    width: HORIZONTAL_DEFAULT,
    _dimension: 'width',
    _direction: 'left',
    _target: true,
  },
  {
    name: hy.ANNOTATION_TARGETS.PLAYER,
    parent: 'middle',
    layout: hy.LAYOUT_TYPES.ABSOLUTE,
    aspect: hy.DEFAULT_ASPECT_RATIO * 100,
    physicalWidth: hy.BASE_WIDTH,
    _target: true,
  },
  {
    name: hy.ANNOTATION_TARGETS.ADJACENT_RIGHT,
    parent: 'middle',
    layout: hy.LAYOUT_TYPES.FILL,
    width: HORIZONTAL_DEFAULT,
    _dimension: 'width',
    _direction: 'right',
    _target: true,
  },
  {
    name: hy.ANNOTATION_TARGETS.ADJACENT_BOTTOM,
    parent: 'root',
    layout: hy.LAYOUT_TYPES.FILL,
    height: VERTICAL_DEFAULT,
    _dimension: 'height',
    _direction: 'bottom',
    _target: true,
  },
];
