import { ColorPicker, Checkbox, CustomDropdown, Input } from '../hapyak-ui-toolkit';
import logger from './logger';
import { stateController } from './stateController';
import { interpolate } from './utils';

const valueToOption = (v: any) => ({
  key: v,
  value: v,
  text: v
});
// @ts-expect-error TS(7031): Binding element 'text' implicitly has an 'any' typ... Remove this comment to see the full error message
const arrayToOption = ([text, v]) => {
  return { key: v, value: v, text, style: { fontFamily: v } };
};
const STANDARD_UNITS = ['px', '%'].map(valueToOption);
const PIXELS_ONLY = ['px'].map(valueToOption);
const STANDARD_NUMBER_PROPS = { type: 'number', min: 0, max: 1024 };

const ROOT = (window as any).hy.compositionInformation.COMPOSITION_TYPES.ROOT;

class StyleService {
  static get fonts () {
    return [
      ['Andale Mono', 'andale mono,times'],
      ['Arial', 'arial,helvetica,sans-serif'],
      ['Arial Black', 'arial black,avant garde'],
      ['Book Antiqua', 'book antiqua,palatino'],
      ['Comic Sans MS', 'comic sans ms,sans-serif'],
      ['Courier New', 'courier new,courier'],
      ['Georgia', 'georgia,palatino'],
      ['Helvetica', 'helvetica'],
      ['Impact', 'impact,chicago'],
      ['Papyrus', 'papyrus, serif'],
      ['Symbol', 'symbol'],
      ['Tahoma', 'tahoma,arial,helvetica,sans-serif'],
      ['Terminal', 'terminal,monaco'],
      ['Times New Roman', 'times new roman,times'],
      ['Trebuchet MS', 'trebuchet ms,geneva'],
      ['Verdana', 'verdana,geneva'],
      ['Webdings', 'webdings'],
      ['Wingdings', 'wingdings,zapf dingbats'],
    ];
  }

  INPUT_MAP = [
    {
      properties: ['color', 'backgroundColor', 'borderColor', 'primary', 'secondary', 'accent', 'borderLeft'],
      component: ColorPicker,
      valueProp: 'color',
      onChange: (cb: any, value: any) => {
        cb(value);
      },
      props: {
        type: 'color',
      },
    },
    {
      properties: ['borderWidth'],
      component: Input,
      onChange: (cb: any, e: any, {
        value
      }: any) => {
        cb(value);
      },
      props: {
        ...STANDARD_NUMBER_PROPS,
      },
      units: STANDARD_UNITS,
    },
    {
      properties: ['paddingTop', 'paddingLeft', 'paddingBottom', 'paddingRight', 'height'],
      component: Input,
      onChange: (cb: any, e: any, {
        value
      }: any) => {
        cb(value);
      },
      props: {
        ...STANDARD_NUMBER_PROPS,
      },
      units: STANDARD_UNITS,
    },
    {
      properties: ['borderRadius'],
      component: Input,
      onChange: (cb: any, e: any, {
        value
      }: any) => {
        cb(value);
      },
      props: {
        ...STANDARD_NUMBER_PROPS,
      },
      units: STANDARD_UNITS,
    },
    {
      properties: ['opacity'],
      component: Input,
      onChange: (cb: any, e: any, {
        value
      }: any) => {
        cb(value);
      },
      props: {
        ...STANDARD_NUMBER_PROPS,
        max: 1,
        step: 0.1,
      },
    },
    {
      properties: ['fontFamily'],
      component: CustomDropdown,
      font: true,
      onChange: (cb: any, e: any, {
        value
      }: any) => {
        cb(value);
      },
      props: {
        // @ts-expect-error TS(2345): Argument of type '([text, v]: [any, any]) => { key... Remove this comment to see the full error message
        options: StyleService.fonts.map(arrayToOption),
        selection: true,
        placeholder: 'Font',
        className: 'button',
        compact: true,
      },
    },
    {
      properties: ['fontSize'],
      component: Input,
      onChange: (cb: any, e: any, {
        value
      }: any) => {
        cb(value);
      },
      props: {
        ...STANDARD_NUMBER_PROPS,
      },
      units: PIXELS_ONLY,
    },
    {
      properties: ['fontWeight'],
      component: CustomDropdown,
      onChange: (cb: any, e: any, {
        value
      }: any) => {
        cb(value);
      },
      props: {
        options: ['lighter', 'normal', 'bold'].map(valueToOption),
        selection: true,
        placeholder: 'Font Weight',
        className: 'button',
        compact: true,
      },
    },
    {
      properties: ['textShadow'],
      component: Checkbox,
      valueProp: 'checked',
      onChange: (cb: any, e: any, {
        checked
      }: any) => {
        cb(checked ? '-3px 3px 3px rgba(0, 0, 0, 0.4)' : 'none');
      },
      props: {
        toggle: true,
      },
    },
    {
      properties: ['boxShadow'],
      component: Checkbox,
      valueProp: 'checked',
      onChange: (cb: any, e: any, {
        checked
      }: any) => {
        cb(checked ? '-3px 3px 10px rgba(0, 0, 0, 0.7)' : 'none');
      },
      props: {
        toggle: true,
      },
    },
    {
      properties: ['backgroundPosition'],
      component: CustomDropdown,
      onChange: (cb: any, e: any, {
        value
      }: any) => {
        cb(value);
      },
      props: {
        options: [
          'top left',
          'top center',
          'top right',
          'center left',
          'center center',
          'center right',
          'bottom left',
          'bottom center',
          'bottom right',
        ].map(valueToOption),
        selection: true,
        placeholder: 'Image Position',
        className: 'button',
        compact: true,
      },
    },
    {
      properties: ['backgroundRepeat'],
      component: CustomDropdown,
      onChange: (cb: any, e: any, {
        value
      }: any) => {
        cb(value);
      },
      props: {
        options: ['no-repeat', 'repeat', 'repeat-x', 'repeat-y', 'space', 'round'].map(valueToOption),
        selection: true,
        placeholder: 'Image Repeat',
        className: 'button',
        compact: true,
      },
    },
    {
      properties: ['backgroundSize'],
      component: CustomDropdown,
      onChange: (cb: any, e: any, {
        value
      }: any) => {
        cb(value);
      },
      props: {
        options: ['contain', 'cover', 'auto'].map(valueToOption),
        selection: true,
        placeholder: 'Image Size',
        className: 'button',
        compact: true,
      },
    },
  ];

  getInput = (property: any) => {
    const inputDefinition = this.INPUT_MAP.find((d) => d.properties.includes(property));

    if (!inputDefinition) {
      logger.warn('Not implemented yet: Input definition for', property);
      return null;
    }
    return inputDefinition;
  };

  static replaceBrandingVariables = (styles: any): any => {
    const { branding } = stateController.getCurrentData();
    const keys = styles ? Object.keys(styles) : [];
    const _replaced = {};
    keys.forEach((key) => {
      const value = styles[key];
      if (typeof value === 'object' && value !== null) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _replaced[key] = StyleService.replaceBrandingVariables(value);
        return;
      }
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      _replaced[key] = interpolate(value, branding);
    });

    return _replaced;
  };

  static addPositioningStyles = (styles: any, positionStyles: any) => {
    return {
      [ROOT]: { ...styles[ROOT], ...positionStyles },
    };
  };
}

export { StyleService };
