import React, { Component } from 'react';

import _ from 'lodash';

import { VisProcess } from '../Initializers/VisProcess';

type QuickStartServiceProps = {
  allAvailableCombos: any;
  canEdit: boolean;
  handleMenuToggle: any;
  isOpen: boolean;
  localizationProvisioned: any;
  processing: any;
  translating?: any;
}

export class QuickStartService extends Component<QuickStartServiceProps> {
  activeProcessMessage: any;
  setInitialState: any;
  get processes () {
    return {
      captions: false,
      videoText: false,
      annotations: false,
      audioDescription: false,
    };
  }

  setAllProcesses = (bool: any) => {
    return _.mapValues(this.processes, () => bool);
  };

  get quickStart () {
    const { canEdit, localizationProvisioned, translating } = this.props;

    return (
      <VisProcess
        translating={translating}
        localizationProvisioned={localizationProvisioned}
        canEdit={canEdit}
        onClose={this.setInitialState}
        processes={this.setAllProcesses(true)}
        activeProcessMessage={canEdit ? null : this.activeProcessMessage}
      />
    );
  }

  render () {
    return this.quickStart;
  }
}
