import React, { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from 'react';

import { Button, GridLayout } from '@newsela/angelou';
import _ from 'lodash';

import { persistenceClientFactory } from '../../../services/persistence/persistenceClientFactory';
import projectFactory from '../../../services/projectFactory';
import { deleteSave, resetTabPreferences } from '../../../services/saveUtils';
import { assetService, stateController } from '../../../services/stateController';
import { attemptImport, getExportJSON } from '../../../services/stateUtils';
import { updateDefaultImages } from '../../../sharedUtils';
import { getTemplate } from '../../../templates/index';
import { AngelouInput } from '../AngelouComponents/AngelouInput';
import { ProjectTemplate } from './ProjectTemplate';

type NewProjectFormProps = {
  setWorking: Dispatch<SetStateAction<boolean>>;
  setProjectId: Dispatch<SetStateAction<string>>;
  setActive: MutableRefObject<null> | Dispatch<any> | { isActive: any; closeModal: () => void; onEsc: (e: any) => void; triggerRef: MutableRefObject<null>; }
  setModalTitle: Dispatch<SetStateAction<string>>;
  setErrorMsg: Dispatch<SetStateAction<string>>;
  errorMsg: string;
};

export const NewBlankProjectForm = ({
  setWorking,
  setProjectId,
  setActive,
  setModalTitle,
  setErrorMsg,
  errorMsg
}: NewProjectFormProps) => {
  const [templateId, setTemplateId] = useState(1);
  const [isEnabled, setIsEnabled] = useState(false);
  const [title, setTitle] = useState('');

  useEffect(() => {
    setModalTitle(title);
  }, [title]);

  const createProject = async () => {
    setWorking(true);
    deleteSave();

    const template = getTemplate(templateId);
    const { video } = template;

    const projectInputs = {
      title,
      created: Date.now()
    };

    const newProjectData = await projectFactory.createProject(template, projectInputs, video);
    stateController.setProject(newProjectData);
    assetService.createVideo(video);
    updateDefaultImages({ video });

    const project = getExportJSON();
    const validProject = await attemptImport(project);
    if (!validProject) return;

    resetTabPreferences();
    try {
      const client = persistenceClientFactory.getClient();
      const project = await client.create(validProject);
      const proId = _.get(project, 'project.id');
      setProjectId(proId);
      setWorking(false);
    } catch (error) {
      console.error('createProject error:', error);
      setWorking(false);
      setErrorMsg(`There was an error while creating Project. Please try again later. ${error}`);
    }
  };

  const formElements = [
    [
      <AngelouInput
        key={0}
        label='Project/video name (required)'
        errorMsg='Project/video name is required'
        isRequired
        value={title}
        setValue={setTitle}
        isFilled={setIsEnabled}
        css={{
          root: {
            marginBottom: '24px',
          }
        }}
      />
    ],
    [
      <div id='template-title' key={5}>
        Project template (required)
      </div>
    ],
    [
      <ProjectTemplate
        key={6}
        setTemplateId={setTemplateId}
        templateId={templateId}
      />
    ]
  ];

  const errorItem = [
    [
      <p key={0} className='errorMsg'>{errorMsg}</p>
    ]
  ];

  return (
    <div className='create-project-modal'>
      <h1 id='modal-title'>Create a blank project</h1>
      <div className='body'>
        <div className='inner-body'>
          {formElements}
          {errorMsg && <GridLayout>{errorItem[0]}</GridLayout>}
        </div>
      </div>
      <div className='footer'>
        <div className='f-inner'>
          <div className='f-horizontal-separator'>
            <Button
              legacy_flavor={Button.legacy_flavor.solid}
              legacy_statusColor={Button.legacy_statusColor.tertiary}
              legacy_size={Button.legacy_size.medium}
              onClick={() => { if (typeof setActive === 'function') setActive(false); }}
              __classNameFor={{ root: 'tertiary' }}
            >
              Cancel
            </Button>
          </div>
          <div className='f-horizontal-separator'>
            <Button
              legacy_flavor={Button.legacy_flavor.solid}
              legacy_statusColor={Button.legacy_statusColor.primary}
              legacy_size={Button.legacy_size.medium}
              onClick={async () => { await createProject(); }}
              disabled={!isEnabled}
            >
              Create project
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
