import _ from 'lodash';

import { VideoInfo } from 'types/assets';

import DEFAULTS from '../DEFAULTS.json';
import { updateDefaultImages } from '../sharedUtils';
import { annotationService } from './annotationService';
import { comm } from './comm';
import { groupConfig } from './configurationService';
import { LEXILE_LEVEL_DEFAULT } from './lexileService';
import logger from './logger';
import { media } from './mediaController';
import { formatLocalizeTransaction } from './monetaryTransactionService';
import { getPlatformProcesses, pollForProcessResults, startProcess } from './persistence/platformService';
import { assetService, stateController } from './stateController';
import { convertToS3URL } from './utils';

type Error = {
  error: string;
};

type ProcessStats = {
  percentComplete: number;
}

type PollResults = VideoInfo & Error & ProcessStats;

class VisService {
  transcode = async (videoUrl: string, language = 'en') => {
    const payload = {
      videoUrl,
      language,
      transcoding: {
        watermarks: [],
      },
    };

    await startProcess(DEFAULTS.TRANSCODE_KEY, payload);
    await this.fetchTranscodeResult();
  };

  remoteTranscodeUpload = async (videoUrl: string) => {
    const payload = {
      videoUrl
    };

    // @ts-expect-error TS(2339): Property 'statusUrl' does not exist on type 'unkno... Remove this comment to see the full error message
    let { statusUrl } = await startProcess(DEFAULTS.TRANSCODE_REMOTE_UPLOAD_KEY, payload);
    statusUrl = statusUrl.replace('job-results', 'job-temp');
    const mp4Url = statusUrl.replace('results.json', 'remuxed.mp4');
    await this.remoteFetchTranscodeResult();
    return mp4Url;
  };

  remoteFetchTranscodeResult = async () => {
    const results = await pollForProcessResults(DEFAULTS.TRANSCODE_REMOTE_UPLOAD_KEY);
    this.persistPollResults(results, true);
  };

  fetchTranscodeResult = async () => {
    const results = await pollForProcessResults(DEFAULTS.TRANSCODE_KEY);
    this.persistPollResults(results, false);
  };

  persistPollResults = (results: PollResults, remote: boolean) => {
    if (results) {
      if (!results.error) {
        const { videoUrl, hlsOutput, media_info } = results;
        const { width, height, duration_ms, poster = '' } = media_info;
        this.persistTranscodeResults(videoUrl, hlsOutput, width, height, duration_ms, poster, remote);
      } else {
        logger.error(results.error);
      }
    }
  };

  persistTranscodeResults = (
    mp4: string,
    m3u8: string,
    width: number,
    height: number,
    duration: number,
    posterImage: string,
    remuxed = false
  ) => {
    const videoPayload = {
      // since we set this asynchronously we will need to refactor when multiple videos are actually supported
      ...media.video,
      formats: { ...media.video.formats, m3u8, mp4 },
      width,
      height,
      aspectRatio: width / height,
      duration,
    };

    if (remuxed) {
      videoPayload.name = 'remuxed';
    }

    const video = assetService.setVideo(
      videoPayload,
      true
    );

    updateDefaultImages({ video, posterImage });

    comm.trigger('updateMp4');
    comm.trigger('remoteTranscodeComplete');
  };

  monitorTranscodeUpdates () {
    this.fetchTranscodeResult();
  }

  connectToLocalizationService () {
    this.fetchTranslateAssetsResult();
  }

  translateAssets = async ({
    data,
    processInfo,
    type
  }: any) => {
    // TODO - Send this along in the payload
    const projectId = stateController.getCurrentData().project.id;
    const transaction = formatLocalizeTransaction(processInfo, media.video, projectId, type);
    await groupConfig.requestConfig();

    if (type === 'generate') {
      data.lexileLevel = 'MAX';
    }

    const payload = {
      batchEvent: {
        data: {
          media_file_uri: convertToS3URL(media.formats.mp4),
          nativeLanguage: media.video.language,
          tunableParams: _.get(
            groupConfig,
            'configuration.authoring.hapyakTools.platform.localization.tunableParams',
            {}
          ),
          ...data, // { languages, types, lexileLevel }
        },
      },
    };

    await startProcess(DEFAULTS.LOCALIZE_PLATFORM_KEY, payload, transaction);
    await this.fetchTranslateAssetsResult();
  };

  fetchTranslateAssetsResult = async () => {
    try {
      const results = await pollForProcessResults(DEFAULTS.LOCALIZE_PLATFORM_KEY);
      this.onTranslateAssetsResult(results);
    } catch (e) {
      logger.log(`Failed to fetch assets for ${DEFAULTS.LOCALIZE_PLATFORM_KEY}`);
    }
  };

  onTranslateAssetsResult = (response: any) => {
    logger.log('Translate Assets Response:', response);
    if (response.data) {
      this.handleTranslatedAssets(response.data);
    }
  };

  handleTranslatedAssets = (result: any) => {
    const { nonEditableAnnotationsUrl, languages, lexileLevel } = result;

    if (nonEditableAnnotationsUrl) {
      stateController.updateProject('ux', { nonEditableAnnotationsUrl });
      annotationService.resetNonEditableAnnotations();
    }

    if (languages) {
      Object.keys(languages).forEach((language) => {
        const { arb = {}, vtt } = languages[language];
        const { annotations, videoText } = arb;

        if (annotations) {
          assetService.createArb(
            this.stageAsset(language, annotations, DEFAULTS.ARB_ANNOTATION_TEXT, lexileLevel),
            true
          );
        }

        if (videoText) {
          assetService.createArb(
            this.stageAsset(language, videoText, DEFAULTS.ARB_VIDEO_TEXT, lexileLevel),
            true
          );
        }

        if (vtt) {
          assetService.createVtt(this.stageAsset(language, vtt, DEFAULTS.VTT_CAPTIONS, lexileLevel), true);
        }
      });
    }
  };

  stageAsset = (language: any, src: any, type: any, lexileLevel = LEXILE_LEVEL_DEFAULT) => {
    return { isControlled: true, videoId: media.id, language, src, type, lexileLevel };
  };

  get activePlatformProcesses () {
    const processes = getPlatformProcesses();

    return Object.keys(processes).filter((key) => processes[key] && processes[key] !== null);
  }
}

const instance = new VisService();

export default instance;
