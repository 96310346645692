import React from 'react';
import './devices.scss';

const DEFAULT_SIZE = 0.31;

type State = any; // measured to iphone 6

type DeviceWrapperProps = {
  downscale?: number | undefined;
  device: string;
  portrait: boolean;
  hidden?: boolean;
  children?: any;
}

class DeviceWrapper extends React.Component<DeviceWrapperProps, State> {
  center: any;
  inner: any;
  constructor (props: DeviceWrapperProps) {
    super(props);
    this.center = React.createRef();
    this.inner = React.createRef();
    this.state = {
      overrideWidth: '',
    };
  }

  componentDidUpdate (prevProps: DeviceWrapperProps, prevState: State) {
    this.fitToContainer(prevState.overrideWidth);
  }

  fitToContainer = (prevOverrideWidth: any) => {
    const center = this.center.current;
    const inner = this.inner.current;
    const box = inner && inner.getBoundingClientRect();
    const child = center && center.childNodes[0];
    const { downscale } = this.props;

    if (!(child && box)) {
      this.setState({ overrideWidth: '100%' });
    }

    const { height, width } = child.getBoundingClientRect();
    if (height > box.height) {
      const _overrideWidth = (width / height) * box.height;
      const overrideWidth = downscale ? _overrideWidth / downscale : _overrideWidth;
      this.setState({ overrideWidth: overrideWidth + 'px' });
    } else if (prevOverrideWidth !== '100%') {
      this.setState({ overrideWidth: '100%' });
    }
  };

  get style () {
    const { downscale, device, portrait } = this.props;
    if (!downscale) return {};

    let size = DEFAULT_SIZE;

    if (device === 'mobile' && portrait) {
      size = 0.175;
    } else if (device === 'tablet') {
      size = portrait ? 0.096 : 0.138;
    }

    return {
      fontSize: size + 'em',
    };
  }

  render () {
    const { device, portrait, children } = this.props;
    const { overrideWidth } = this.state;
    const contentClassName = 'hy-device-center hy-device-content';

    return (
      <div className={`hy-device hy-device-${device} ${portrait ? 'hy-portrait' : ''}`} style={this.style}>
        <div ref={this.inner} className='hy-device-inner hy-device-center'>
          <div
            ref={this.center}
            style={{ width: overrideWidth, overflow: 'hidden' }}
            className={contentClassName}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export { DeviceWrapper };
