import React, { Component } from 'react';

import { ImageMetadata } from 'types/assets';
import { Bundle, Embed, EmbedType, EmbedTypeOption } from 'types/publishing';

import { Grid } from '../../../hapyak-ui-toolkit';
import { groupConfig } from '../../../services/configurationService';
import { stateController } from '../../../services/stateController';
import { EmbedPreview } from './EmbedPreview';
import { EmbedSettingTabs } from './EmbedSettingTabs';
import { EMBED_TYPE_OPTIONS } from './publishingOptions';

const EMBED_TYPE_DEFAULT = 'customTag';

type State = any;

type EmbedDesignerProps = {
  bundle: Bundle;
  embed: Embed;
}

export class EmbedDesigner extends Component<EmbedDesignerProps, State> {
  constructor (props: EmbedDesignerProps) {
    super(props);

    this.state = {
      embed: props.embed,
    };
  }

  componentDidMount () {
    this.setEmbedDefaults();
  }

  setEmbedDefaults = () => {
    this.updateValues({
      embedType: this.embedType,
      sourceType: 'static',
    });
  };

  get defaultSourceType () {
    const { embed } = this.props;
    const { sourceType } = embed;

    if (sourceType) return sourceType;

    if (!this.canPublishPublicly) return 'static';

    return 'dynamic';
  }

  get canPublishPublicly () {
    return groupConfig.allow('publishing.targets.aws-s3');
  }

  saveChanges = () => {
    stateController.updateProject('publishing', { embed: this.state.embed });
  };

  allowEmbedOption = (option: any) => {
    const { bundle } = this.props;
    const { packaging } = bundle;
    return ['iframe', 'customTag'].includes(option.key) || packaging !== 'complete';
  };

  get filteredEmbedOptions (): EmbedTypeOption[] {
    return EMBED_TYPE_OPTIONS.filter(this.allowEmbedOption);
  }

  get embedType (): EmbedType | undefined {
    const { embed } = this.props;
    const { embedType } = embed;
    const options: EmbedTypeOption[] = this.filteredEmbedOptions;
    const match =
      options.find((option) => option.key === embedType) ||
      options.find((option) => option.value === EMBED_TYPE_DEFAULT);
    return match?.key;
  }

  updateValue = (key: any, value: any) => {
    const { embed } = this.state;
    const updatedEmbed = { embed: { ...embed, [key]: value } };
    this.setState(updatedEmbed, this.saveChanges);
  };

  updateValues = (data = {}) => {
    const { embed } = this.state;
    const updatedEmbed = { embed: { ...embed, ...data } };
    this.setState(updatedEmbed, this.saveChanges);
  };

  handleChange = (key: any, e: any, {
    value
  }: any) => {
    this.updateValue(key, value);
  };

  handleToggle = (key: any, e: any, {
    checked
  }: any) => {
    this.updateValue(key, checked);
  };

  onThumbnailChange = (image: ImageMetadata) => {
    this.updateValue('thumbnail', image.id);
  };

  get embedSettingTabs () {
    const { bundle } = this.props;
    const { embed } = this.state;

    return (
      <EmbedSettingTabs
        className='hy-compact-grid-row'
        bundle={bundle}
        embed={embed}
        filteredEmbedOptions={this.filteredEmbedOptions}
        embedType={this.embedType}
        handleToggle={this.handleToggle}
        handleChange={this.handleChange}
        updateValue={this.updateValue}
        onThumbnailChange={this.onThumbnailChange}
        saveChanges={this.saveChanges}
      />
    );
  }

  get previewComponent () {
    const { bundle } = this.props;
    const { embed } = this.state;
    return <EmbedPreview bundle={bundle} embed={embed} />;
  }

  render () {
    return (
      <Grid>
        <Grid.Row divided columns={2}>
          <Grid.Column>
            <div>
              <div>{this.embedSettingTabs}</div>
            </div>
          </Grid.Column>
          <Grid.Column>
            {this.previewComponent && <Grid.Column>{this.previewComponent}</Grid.Column>}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
