import React, { Component } from 'react';

import _ from 'lodash';

import { Dropdown, Popup } from '../../../../../hapyak-ui-toolkit';
import { getDropdownDisplay } from '../../../../../services/toolService';
import { Annotation } from '../../../../../types/annotations';
import { toOptionComplex } from '../../ActionsEditor/utils';
import { WHITE_LISTED_ANNOTATIONS_AS_ANSWERS } from './questionService';
import { getAnnotationsAppliedToQuizAnswers } from './quizBuilderUtils';

type State = any;

type AnnotationAsAnswerDropdownProps = {
  annotations: Annotation[];
  createAnnotationWithDefaults: any;
  persistChange: any;
};

export class AnnotationAsAnswerDropdown extends Component<AnnotationAsAnswerDropdownProps, State> {
  constructor (props: AnnotationAsAnswerDropdownProps) {
    super(props);
    this.state = { text: _.get(props, 'annotation.properties.value', '') };
  }

  getDefaultValue = (annotation: Annotation) => {
    return _.get(annotation, 'internal.name', annotation.id);
  };

  onDropdownChange = (id: string) => {
    const { persistChange, createAnnotationWithDefaults } = this.props;
    if (!id || typeof persistChange !== 'function' || typeof createAnnotationWithDefaults !== 'function') return;
    const { annotation, updatedAnnotations } = createAnnotationWithDefaults();
    const props = annotation.properties.value ? {} : { properties: { value: this.getDefaultValue(annotation) } };
    const answerAnnotation = { ...annotation, ...props, content: id };
    const targetedAnnotationAsAnswer = { id, actions: [], appliesTo: [annotation.id] };
    persistChange([answerAnnotation, targetedAnnotationAsAnswer], updatedAnnotations);
  };

  get annotationChoices () {
    const { annotations } = this.props;
    const isValidType = (a: Annotation) => WHITE_LISTED_ANNOTATIONS_AS_ANSWERS.includes(a.toolType);
    const takenAnnotationIds = getAnnotationsAppliedToQuizAnswers(annotations);
    const isTaken = (a: Annotation) => takenAnnotationIds.includes(a.id);
    return annotations.filter((a: Annotation) => isValidType(a) && !isTaken(a));
  }

  get options () {
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    return this.annotationChoices.map((a: Annotation) => toOptionComplex(a.id, a.id === null ? 'Select existing annotation' : getDropdownDisplay(a))
    );
  }

  get dropdown () {
    const disabled = !this.options.length;
    return (
      <Dropdown
        disabled={disabled}
        text='Use Existing Annotation'
        icon='plus'
        floating
        labeled
        button
        className='icon'
      >
        <Dropdown.Menu>
          {
            this.options.map((option: any) =>
              <Dropdown.Item
                key={option.value}
                {...option}
                onClick={this.onDropdownChange.bind(this, option.value)}
              />
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  wrap = (element: any) => {
    return (
      <Popup
        content='All available annotations have been associated to answers.'
        trigger={<div>{element}</div>}
      />
    );
  };

  render () {
    const disabled = !this.options.length;
    return disabled ? this.wrap(this.dropdown) : this.dropdown;
  }
}
