import React, { Component } from 'react';

import { Dropdown } from '../../../../hapyak-ui-toolkit';
import { FilterButton } from './FilterButton';

type State = any;

type FilterProps = {
  displayType: string;
  handleChange: (e: any, data: any) => void;
  items: string[];
};

export class Filter extends Component<FilterProps, State> {
  constructor (props: FilterProps) {
    super(props);

    this.state = { activeItem: '' };
  }

  createTagDropdown = (tags: string[]) => {
    const { handleChange } = this.props;
    const options = tags.map((tag: any) => {
      return {
        value: tag.toLowerCase(),
        text: tag,
      };
    });

    return (
      <Dropdown
        placeholder='Select Tag'
        floating
        search
        selection
        clearable
        labeled
        onChange={handleChange}
        options={options}
      />
    );
  };

  onClick = (evt: any, data: any) => {
    const { handleChange } = this.props;
    const tags = evt.currentTarget.innerText.toLowerCase();
    data = { ...data, tags };
    if (typeof handleChange === 'function') handleChange(evt, data);
    this.setState({ activeItem: tags });
  };

  createTagEl = (tag: string, idx: number) => {
    const { activeItem = '' } = this.state;
    return (
      <FilterButton
        isActive={tag.toLowerCase() === activeItem.toLowerCase()}
        text={tag}
        key={idx}
        handleClick={this.onClick}
      />
    );
  };

  resetFilter = (evt: any, data: any) => {
    const { handleChange } = this.props;
    if (typeof handleChange === 'function') handleChange(evt, { ...data, resetFilter: true });
    this.setState({ activeItem: evt.currentTarget.innerText.toLowerCase() });
  };

  clearButton = ({
    key
  }: any) => {
    const { activeItem = '' } = this.state;

    return <FilterButton key={key} isActive={activeItem === 'all'} text='ALL' handleClick={this.resetFilter} />;
  };

  createTagCards = (tags: string[]) => {
    const tagElements = tags && tags.map((tag: string, idx: number) => this.createTagEl(tag, idx));
    tagElements.push(this.clearButton({ key: tagElements.length + 1 }));
    return tagElements;
  };

  get getAvailableTags () {
    const { items, displayType } = this.props;
    return displayType === 'cards' ? this.createTagCards(items) : this.createTagDropdown(items);
  }

  render () {
    const tagElement = this.getAvailableTags;
    return <div>{tagElement}</div>;
  }
}
