import React from 'react';

import { Button, EditHollowSVG, TrashHollowSVG } from '@newsela/angelou';

import './QuizFooter.scss';

type QuizFooterProps = {
  canDelete?: boolean;
  deleteButtonContent: string;
  hideDelete?: boolean;
  isEditing: boolean;
  onDelete: () => void;
  quizContentType: 'question' | 'section';
  secondaryActionButton?: JSX.Element | null;
  setIsEditing: (isEditing?: boolean) => void;
};

export const QuizFooter = ({
  canDelete,
  deleteButtonContent,
  hideDelete,
  isEditing,
  onDelete,
  quizContentType,
  secondaryActionButton,
  setIsEditing
}: QuizFooterProps) => {
  const editButton = (
    <div>
      <Button
        ariaProps={{ 'aria-label': `edit ${quizContentType}` }}
        icon={{
          SvgComponent: EditHollowSVG,
          alignment: Button.iconAlignments.left
        }}
        legacy_size={Button.legacy_size.justified}
        legacy_flavor={Button.legacy_flavor.hollow}
        __classNameFor={{ root: 'internal-tools-button' }}
        onClick={() => setIsEditing(true)}
      >
        Edit this {quizContentType}
      </Button>
    </div>
  );

  const deleteButton = (
    <Button
      ariaProps={{ 'aria-label': `delete ${quizContentType}` }}
      icon={{
        SvgComponent: TrashHollowSVG,
        alignment: Button.iconAlignments.left
      }}
      onClick={() => onDelete()}
      __classNameFor={{ root: 'internal-tools-button' }}
      legacy_size={Button.legacy_size.small}
      legacy_flavor={Button.legacy_flavor.hollow}
      disabled={!canDelete}
    >
      {deleteButtonContent}
    </Button>
  );

  const doneButton = (
    <Button
      ariaProps={{ 'aria-label': 'done with editing' }}
      legacy_flavor={Button.legacy_flavor.solid}
      legacy_statusColor={Button.legacy_statusColor.primary}
      legacy_size={Button.legacy_size.justified}
      onClick={() => setIsEditing(false)}
    >
      Done
    </Button>
  );

  const defaultActions = () => {
    if (!isEditing) return editButton;
    return hideDelete
      ? null
      : deleteButton;
  };

  const secondaryFooter = (
    <div className='secondary-quiz-footer'>
      {doneButton}
    </div>
  );

  return (
    <div>
      <div className={isEditing ? 'quiz-footer is-editing ' : 'quiz-footer'}>
        {secondaryActionButton}
        {defaultActions()}
      </div>
      {isEditing && secondaryFooter}
    </div>
  );
};
