import React from 'react';

import { Grid } from '../../../hapyak-ui-toolkit';

export const PublishedOutputDetails = ({
  customSrc,
  src,
  bundleName
}: any) => (
  <Grid style={{ height: '700px' }}>
    <Grid.Row>
      <Grid.Column>
        <h3>
          <a href={customSrc || src} target='_blank' rel='noopener noreferrer'>
            View Published ({bundleName})
          </a>
        </h3>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
