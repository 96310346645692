import React, { Component } from 'react';

import defaults from '../../DEFAULTS.json';
import { Header, Grid, Button, Label, Modal } from '../../hapyak-ui-toolkit';
const { SKIP_AMOUNTS } = defaults;

type ShortcutListProps = {
  keyMap: any;
  readableMap: any;
  sequence: any;
  onClose: any;
  open: any;
}

// TODO: order into sections based on tool
class ShortcutList extends Component<ShortcutListProps> {
  readableMap = {
    'All tools': {
      Undo: ['undo'],
      Redo: ['redo'],
      'Exit Modal': ['esc'],
      'Show keyboard shortcuts': ['toggleShowShortcutList'],
    },
    Video: {
      'Play / Pause': ['space'],
      [`Skip ${SKIP_AMOUNTS.SHORT} seconds`]: ['skipShort'],
      [`Skip ${SKIP_AMOUNTS.NORMAL} seconds`]: ['skipNormal'],
      [`Skip ${SKIP_AMOUNTS.LONG} seconds`]: ['skipLong'],
      [`Rewind ${SKIP_AMOUNTS.SHORT} seconds`]: ['rewindShort'],
      [`Rewind ${SKIP_AMOUNTS.NORMAL} seconds`]: ['rewindNormal'],
      [`Rewind ${SKIP_AMOUNTS.LONG} seconds`]: ['rewindLong'],
      'Next annotation': ['nextAnnotation'],
      'Previous annotation': ['previousAnnotation'],
    },
    'UX Tool': {
      'Snap to Grid': ['ctrlDown', 'commandDown'],
      'Drag off of video': ['optionDown'],
      'Select Multiple': ['ctrlDown', 'commandDown', 'shiftDown'],
      'Lock movement / resizing': ['shiftDown'],
    },
    'UX Tool - Selection': {
      Rectangle: ['r'],
      Ellipse: ['e'],
      Lasso: ['l'],
      'Force equal aspect ratio': ['shiftDown'],
      'Select from center': ['ctrlDown', 'commandDown'],
    },
  };

  get shortcuts () {
    return Object.keys(this.readableMap).map((sectionKey) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const section = this.readableMap[sectionKey];
      const contents = Object.keys(section).map((key) => {
        const commands = section[key];

        return (
          <Grid.Row columns={2} key={key}>
            <Grid.Column>{key}:</Grid.Column>
            <Grid.Column textAlign='right'>{commands.map(this.getCommandText)}</Grid.Column>
          </Grid.Row>
        );
      });
      return (
        <Grid.Column key={sectionKey}>
          <Header>{sectionKey}</Header>
          <Grid padded>{contents}</Grid>
        </Grid.Column>
      );
    });
  }

  getCommandText = (name: any, index: any) => {
    const command = this.props.keyMap[name];
    const or = index > 0 ? ' or ' : '';
    return (
      <span key={name}>
        {or}
        <Label>{command.sequence || command}</Label>
      </span>
    );
  };

  render () {
    return (
      <Modal dimmer open={this.props.open} onClose={this.props.onClose} centered={false}>
        <Modal.Header>Keyboard shortcuts</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Grid>
              <Grid.Row columns={2}>{this.shortcuts}</Grid.Row>
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={this.props.onClose}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export { ShortcutList };
