import React from 'react';

import { Checkbox } from '@newsela/angelou';
import _ from 'lodash';
import '../index.scss';

import { CustomDropdown, Header, Icon, Pagination, Table } from '../../../../hapyak-ui-toolkit';
import { SearchBox } from '../../../../hapyak-ui-toolkit/SearchBox';
import { comm } from '../../../../services/comm';
import { media } from '../../../../services/mediaController';
import { getActiveVideoProcesses } from '../../../../services/processRunner';
import { getMeaningfulDisplay } from '../../../../services/toolService';
import { sortByNumber, toOption } from '../../../../services/utils';
import { Annotation } from '../../../../types/annotations';
import { AnnotationRow } from './AnnotationRow';
import './index.scss';
import { UpDownArrow } from './ReverseArrow';

const DEFAULT_ORDER = 'start';
const DEFAULT_DROPDOWN_VALUE = 'All';

type State = any;

type AnnotationListProps = {
  activeAnnotation?: string | Annotation;
  annotations: Annotation[];
  button?: any;
  category: string;
  check?: any;
  checkable?: any;
  checkAll?: any;
  checkAllIcon?: any;
  currentTime?: number;
  edit?: any;
  exclusiveFrom?: any;
  float?: any;
  forceIncludeRows?: any;
  includesAnnotations?: any[];
  isChapterMenu?: boolean;
  layoutTargets?: any[];
  minimal?: boolean;
  onSectionSelection?: any;
  pageSize?: number;
  paginated?: boolean;
  permitRowHeightVariation?: boolean;
  processing?: any;
  quickAddButton?: any;
  section?: any;
  select?: any;
  selectedAnnotations?: string[];
  selectedSectionId?: any;
  showAllElementsAsCheckBox?: boolean;
  showSelectedCount?: boolean;
  typeAsIcon?: boolean;
  update: any;
  visibleAnnotations: Annotation[];
}

class AnnotationList extends React.Component<AnnotationListProps, State> {
  filteredButtonElement: any;
  handleToggleShowVisible: any;
  constructor (props: AnnotationListProps) {
    super(props);

    this.state = {
      activePage: 1, // Pagination component activePage starts at 1
      sortAnnotations: true,
      orderedByKey: DEFAULT_ORDER,
      descending: false,
      lastClickedAnnotation: null,
      lastShiftSelection: [],
      search: '',
      overrideActiveAnnotationId: null,
      blockUpdates: false,
      disablePagination: false,
    };
  }

  componentDidUpdate (prevProps: AnnotationListProps) {
    const { currentTime } = this.props;
    if (prevProps.currentTime !== currentTime) {
      this.onEditorTimeUpdate(currentTime);
    }
  }

  onEditorTimeUpdate = (currentTime: any) => {
    this.setState({ blockUpdates: false }, () => {
      const selected = this.selectedSection;
      if (!selected) return;
      const inbounds = currentTime >= selected.start && currentTime <= selected.end;

      if (!inbounds) {
        this.handleFilterChange(null, { value: DEFAULT_DROPDOWN_VALUE });
      }
    });
  };

  evaluateChange = () => {
    const selected = this.selectedSection;

    if (!selected) {
      this.setState({ blockUpdates: false });
      return;
    }

    const time = _.get(media, 'editorInstance.currentTime');
    const inbounds = time >= selected.start && time <= selected.end;

    if (inbounds) {
      this.setState({ blockUpdates: false });
    }

    comm.trigger('setCurrentTime', selected.start);
  };

  handleFilterChange = async (evt: any, {
    value
  }: any) => {
    const { onSectionSelection } = this.props;
    const { blockUpdates } = this.state;

    if (blockUpdates) return;

    this.setState({ blockUpdates: true }, async () => {
      if (typeof onSectionSelection === 'function') {
        await onSectionSelection(value);
      }

      this.evaluateChange();
    });
  };

  get sections () {
    const { annotations } = this.props;
    const isTypeSection = (a: Annotation) => a.toolType === 'section' && a.type === 'bookmark';
    return annotations.filter(isTypeSection);
  }

  get hasSections () {
    return !!this.sections.length;
  }

  get filterDropdown () {
    if (!this.hasSections) return null;
    const { blockUpdates } = this.state;

    const asOption = (section: any) => {
      const { content, id } = section;
      return { key: id, value: id, text: content };
    };

    const defaultValue = toOption(DEFAULT_DROPDOWN_VALUE);
    const options = [defaultValue, ...[...this.sections].map(asOption)];
    const selected = this.selectedSection;
    const value = selected ? selected.id : defaultValue.value;

    return (
      <div className='hy-small-dropdown'>
        <CustomDropdown
          selection
          value={value}
          options={options}
          disabled={blockUpdates}
          label='Section'
          onChange={this.handleFilterChange}
        />
      </div>
    );
  }

  filterByStartEnd = (annotations: Annotation[], start: number, end: number) => {
    const isWithinSection = (annotation: Annotation) => (annotation.start || 0) >= start && (annotation.end || 0) <= end;
    return annotations.filter(isWithinSection);
  };

  get selectedSection () {
    const { annotations, selectedSectionId } = this.props;
    if (!this.hasSections || !selectedSectionId) return null;

    const findById = (id: string) => annotations.find((a: Annotation) => a.id === id);
    return findById(selectedSectionId) || null;
  }

  filterBySection () {
    const { annotations } = this.props;
    const selected = this.selectedSection;
    return !selected ? annotations : this.filterByStartEnd(annotations, selected.start, selected.end);
  }

  get annotationRows () {
    return this.sortedAnnotations.map(this.createAnnotationRow);
  }

  handleRowClick = (annotation: Annotation, shift: any, command: any) => {
    this.select(annotation.id, shift, command);
  };

  select = (id: any, shift: any, command: any) => {
    const { select, selectedAnnotations } = this.props;
    const { lastClickedAnnotation, lastShiftSelection } = this.state;
    let lastClickIndex = this.sortedAnnotations.findIndex((a: Annotation) => a.id === lastClickedAnnotation);
    if (lastClickIndex === -1) lastClickIndex = 0;
    const clickIndex = this.sortedAnnotations.findIndex((a: Annotation) => a.id === id);

    if (shift) {
      const indices = [clickIndex, lastClickIndex].sort(sortByNumber);
      const filtered = this.sortedAnnotations.filter((a: Annotation, index: number) => {
        return index >= indices[0] && index <= indices[1];
      });
      const shiftSelection = filtered.map((a: Annotation) => a.id);
      const minusLastShiftSelection = selectedAnnotations ? selectedAnnotations.filter((id: any) => !lastShiftSelection.includes(id)) : [];
      const minusLastClicked = minusLastShiftSelection.filter((id: any) => lastClickedAnnotation !== id);
      const withNewShiftSelection = [...minusLastClicked, ...shiftSelection];
      comm.trigger('selectAnnotations', withNewShiftSelection);
      this.setState({ lastShiftSelection: shiftSelection });
    } else {
      select && select(id, command, true);
      this.setState({ lastClickedAnnotation: id, lastShiftSelection: [] });
    }
  };

  isOrphaned = (target: any) => {
    const { layoutTargets } = this.props;
    return layoutTargets && !layoutTargets.includes(target);
  };

  setOverrideActiveAnnotation = (id: any) => {
    this.setState({
      overrideActiveAnnotationId: id,
    });
  };

  onTimeUpdate = () => {
    return new Promise((res) => {
      // @ts-expect-error TS(2345): Argument of type 'void' is not assignable to param... Remove this comment to see the full error message
      this.setState({ sortAnnotations: false }, res());
    });
  };

  createAnnotationRow = (annotation: Annotation, index: number) => {
    const {
      activeAnnotation,
      button,
      category,
      check,
      checkable,
      edit,
      exclusiveFrom,
      includesAnnotations,
      isChapterMenu,
      permitRowHeightVariation,
      selectedAnnotations = [],
      typeAsIcon,
      update,
      visibleAnnotations
    } = this.props;

    const unique = exclusiveFrom ? !exclusiveFrom.find((a: any) => annotation.id === a.id) : true;

    return (
      <AnnotationRow
        active={activeAnnotation === annotation.id}
        annotation={annotation}
        button={button} typeAsIcon={typeAsIcon}
        category={category}
        check={check}
        checkable={checkable}
        edit={edit}
        editingChapterMenu={isChapterMenu}
        includedInActive={includesAnnotations && includesAnnotations.includes(annotation.id)}
        index={index}
        key={(index ? index + '_' : '') + 'annotation_row_' + annotation.id}
        onClick={this.handleRowClick}
        onTimeUpdate={this.onTimeUpdate}
        orphan={this.isOrphaned(annotation.target)}
        overrideActiveAnnotationId={annotation.id === this.state.overrideActiveAnnotationId}
        permitRowHeightVariation={permitRowHeightVariation}
        selected={selectedAnnotations.includes(annotation.id)}
        setOverrideActiveAnnotation={this.setOverrideActiveAnnotation}
        unique={unique}
        update={update}
        visible={visibleAnnotations.includes(annotation)}
      />
    );
  };

  changeOrderedByKey = (orderedByKey: any) => {
    this.setState({ sortAnnotations: true });
    if (this.state.orderedByKey === orderedByKey) {
      this.setState({ descending: !this.state.descending });
      return;
    }

    this.setState({
      orderedByKey,
      descending: false,
    });
  };

  orderBy = (key: any) => {
    // return sort callback using specified key:
    const num = (s: any) => +s;
    const useNum = ['start', 'end'].includes(key);

    return (a: any, b: any) => {
      const direction = this.state.descending ? -1 : 1;
      const _a = useNum ? num(a[key]) : a[key];
      const _b = useNum ? num(b[key]) : b[key];
      return _a < _b ? -direction : _a > _b ? direction : 0;
    };
  };

  filterBySearch = (annotation: Annotation) => {
    const { search } = this.state;
    const { toolType, internal } = annotation;
    const { name } = internal;
    const content = getMeaningfulDisplay(annotation);
    const checks = [toolType, name, content];
    return checks.some((check) => {
      if (!check || typeof check.includes !== 'function') return false;
      const value = check.toLowerCase();
      return value.includes(search.toLowerCase());
    });
  };

  search = ({
    target
  }: any) => {
    this.setState({ search: target.value });
  };

  get sortedAnnotations () {
    const { orderedByKey, search, sortAnnotations } = this.state;
    const filtered = this.filterBySection();
    const searched = search ? filtered.filter(this.filterBySearch) : filtered;
    return sortAnnotations ? searched.sort(this.orderBy(orderedByKey)) : searched;
  }

  checkAll = () => {
    this.props.checkAll(this.sortedAnnotations);
  };

  handlePaginationChange = (e: any, {
    activePage
  }: any) => {
    this.setState({ activePage: activePage });
  };

  get activePageRows () {
    const { activePage, disablePagination } = this.state;
    const start = (activePage - 1) * this.pageSize; // - 1 to account for Pagination activePage not being zero-indexed

    if (disablePagination) {
      return this.annotationRows;
    }

    return this.annotationRows.slice(start, start + this.pageSize);
  }

  get pageSize () {
    return this.props.pageSize || 10;
  }

  get totalPages () {
    const x = this.annotationRows.length / this.pageSize;
    return Math.ceil(x);
  }

  get footerColCount () {
    let colCount = 5;
    const { checkable, button, isChapterMenu } = this.props;

    if (checkable) colCount += 1;
    if (button) colCount += 1;
    if (isChapterMenu) colCount += 1;

    return colCount;
  }

  get togglePaginationCheckBox () {
    return (
      <Checkbox
        __cssFor={{ root: { marginLeft: '7px' } }}
        label='show all interactive elements'
        checked={this.state.disablePagination}
        onChange={() => this.setState({ disablePagination: !this.state.disablePagination, activePage: 1 })}
      />
    );
  }

  get filteredCheckBoxElement () {
    return (
      <Checkbox
        __cssFor={{ root: { marginTop: '7px' } }}
        label='include inactive interactive elements'
        defaultChecked
        onChange={this.handleToggleShowVisible}
      />
    );
  }

  get filterElement () {
    const { showAllElementsAsCheckBox } = this.props;
    const el = showAllElementsAsCheckBox ? this.filterDropdown : this.filteredButtonElement;
    return <div style={{ float: 'right' }}> {el} </div>;
  }

  get activeVideoProcessesRunning () {
    const { processing } = this.props;
    return processing ? getActiveVideoProcesses(processing).length : false;
  }

  render () {
    const {
      checkable,
      checkAllIcon,
      annotations,
      category,
      minimal,
      section,
      selectedAnnotations,
      float,
      showSelectedCount,
      button,
      quickAddButton,
      forceIncludeRows,
      isChapterMenu,
    } = this.props;
    const { activePage, descending, orderedByKey, search, disablePagination } = this.state;
    const className = `hy-annotation-list-wrapper ${section && 'hy-annotation-list-section'}`;
    const headerClassName = 'hy-inline-header';
    const nonFound = <span className={headerClassName}>No {category} found</span>;
    const showPagination = this.totalPages > 1 && !disablePagination;

    let contents = (
      <div className={className}>
        {section && <Header as={section.as} content={section.title} className={headerClassName} />}
        {nonFound}
        {quickAddButton}
      </div>
    );

    if (!minimal || annotations.length !== 0) {
      contents = (
        <div className={className}>
          <div>
            <div style={{ height: '30px', display: 'flex', alignItems: 'center' }}>
              {section && <Header as={section.as} content={section.title} className={headerClassName} />}
              <SearchBox
                search={this.search}
                value={search}
                innerInputStyle={{ border: '1px solid rgba(34, 36, 38, 0.15)' }}
                visible={this.activePageRows.length}
                type={category}
                total={annotations.length}
                float={section || float ? 'right' : 'none'}
              />
              {!checkable && this.filterElement}
              {this.togglePaginationCheckBox}
            </div>
            {showSelectedCount && selectedAnnotations && (
              <Header className={headerClassName} as='h5' floated='right' style={{ lineHeight: '30px' }}>
                {selectedAnnotations.length} {category} selected
              </Header>
            )}
          </div>
          <Table className='hy-annotation-list' color='blue' textAlign='center' celled stackable compact>
            <Table.Header>
              {quickAddButton && (
                <Table.Row>
                  <Table.HeaderCell colSpan={this.footerColCount}>{quickAddButton}</Table.HeaderCell>
                </Table.Row>
              )}
              <Table.Row>
                <Table.HeaderCell textAlign='left'>Type</Table.HeaderCell>

                {checkable && (
                  <Table.HeaderCell>
                    <Icon
                      name={checkAllIcon}
                      onClick={this.checkAll}
                      size='large'
                      className='hy-check-all-icon'
                    />
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell textAlign='left' className='hy-secondary-header'>
                  ID
                </Table.HeaderCell>
                <Table.HeaderCell
                  className='hy-orderable'
                  onClick={this.changeOrderedByKey.bind(this, 'start')}
                >
                  Start {orderedByKey === 'start' && <UpDownArrow desc={descending} />}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className='hy-orderable'
                  onClick={this.changeOrderedByKey.bind(this, 'end')}
                >
                  End {orderedByKey === 'end' && <UpDownArrow desc={descending} />}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='left' width={this.activeVideoProcessesRunning ? 6 : 2}>
                  Edit
                </Table.HeaderCell>
                {isChapterMenu && <Table.HeaderCell width={2}>Edit</Table.HeaderCell>}
                {!!button && <Table.HeaderCell>Actions</Table.HeaderCell>}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {forceIncludeRows}
              {this.activePageRows}
            </Table.Body>

            <Table.Footer fullWidth>
              {showPagination && (
                <Table.Row>
                  <Table.HeaderCell colSpan={this.footerColCount}>
                    <Pagination
                      color='blue'
                      activePage={activePage}
                      onPageChange={this.handlePaginationChange}
                      totalPages={this.totalPages}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              )}
            </Table.Footer>
          </Table>
        </div>
      );
    }

    return contents;
  }
}

export { AnnotationList };
