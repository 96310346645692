import React from 'react';

import { Button } from '@newsela/angelou';

type FileChooserButtonProps = {
  disabled: boolean;
  filetype?: string;
  onChange: (e?: any) => void;
  onClick?: () => void;
};

export function FileChooserButton ({
  disabled,
  filetype,
  onChange,
  onClick
}: FileChooserButtonProps) {
  const fileInput: any = React.createRef();

  const handleChange = (e: any) => {
    if (typeof onChange === 'function') {
      onChange(e.target.files[0]);
      // Resets file so the same file can be chosen again after canceling
      fileInput.current.value = '';
    }
  };

  return (
    <>
      <Button
        disabled={disabled}
        legacy_flavor={Button.legacy_flavor.solid}
        legacy_size={Button.legacy_size.medium}
        onClick={() => {
          fileInput.current.click();
          if (typeof onClick === 'function') {
            onClick();
          }
        }}
        __cssFor={{
          root: {
            height: '48px',
            whiteSpace: 'nowrap',
            marginLeft: '1rem',
            marginTop: '2px'
          }
        }}
        __classNameFor={{
          root: 'internal-tools-button'
        }}
      >
        Select file
      </Button>
      <input
        name='test'
        ref={fileInput}
        type='file'
        accept={filetype}
        hidden
        onChange={handleChange}
        data-testid='hidden-file-input'
      />
    </>
  );
}
