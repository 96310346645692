import React from 'react';

import _ from 'lodash';

import { Button, Modal, Table } from '../../../hapyak-ui-toolkit';
import { getLayout, getLayoutTargetSizes, targetNameFormatted } from '../../../layouts';
import { comm } from '../../../services/comm';
import { stateController } from '../../../services/stateController';
import { requiresRemovalOfCollections } from '../../../services/toolRelationshipService';
import { cascadeRemoveAssociatedAnnotations, deleteAnnotation } from '../../../services/toolService';
import { Annotation } from '../../../types/annotations';

type State = any;

type LayoutConfirmationModalProps = {
  currentTargets: any;
  proposedTargets: any;
  annotationsToUpdateLocationFor: Annotation[];
  chosenLayout: any;
  handleMenuReset: any;
};

export class LayoutConfirmationModal extends React.Component<LayoutConfirmationModalProps, State> {
  close: any;
  constructor (props: LayoutConfirmationModalProps) {
    super(props);
    this.state = { open: true };
  }

  deleteAnnotations = (id: string, notify = true, requiresCascadeDelete = false) => {
    notify && comm.trigger('setActiveAnnotation', null);
    if (requiresCascadeDelete) {
      const { annotations } = stateController.getCurrentData('ux');
      const filteredAnnotations = cascadeRemoveAssociatedAnnotations(id, annotations);
      stateController.updateProject('ux', { annotations: filteredAnnotations });
    } else {
      deleteAnnotation(id);
    }
  };

  applyLayout = (id: any) => {
    const { annotationsToUpdateLocationFor } = this.props;
    stateController.updateProject('project', {
      layoutId: id,
      display: { layout: getLayoutTargetSizes(getLayout(id).layoutTargets) },
    });

    annotationsToUpdateLocationFor.forEach((annotation: Annotation) => {
      const { id, toolType } = annotation;
      const requiresCascadeDelete = requiresRemovalOfCollections(toolType);
      this.deleteAnnotations(id, true, requiresCascadeDelete);
    });
  };

  confirm = () => {
    const { chosenLayout, handleMenuReset } = this.props;
    this.applyLayout(chosenLayout);
    this.setState({ open: false });
    if (handleMenuReset) handleMenuReset();
  };

  get table () {
    const { currentTargets, proposedTargets } = this.props;
    const formatNum = (num: number) => num === 0 ? '-' : _.round(100 * num, 2) + '%';
    const NOT_CONFIRMABLE = ['physicalWidth'];

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Current</Table.HeaderCell>
            <Table.HeaderCell>Proposed</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.keys(currentTargets)
            .filter((key) => !NOT_CONFIRMABLE.includes(key))
            .map((key, idx) => {
              return (
                <Table.Row key={idx}>
                  <Table.Cell>{targetNameFormatted(key)}</Table.Cell>
                  <Table.Cell>{formatNum(currentTargets[key])}</Table.Cell>
                  <Table.Cell>{formatNum(proposedTargets[key])}</Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    );
  }

  render () {
    const { annotationsToUpdateLocationFor, handleMenuReset } = this.props;
    const numToUpdate = annotationsToUpdateLocationFor.length;
    const { open } = this.state;

    return (
      <Modal open={open} size='tiny' onClose={this.close} closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>Change Template</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {numToUpdate > 0 && (
              <>
                <p>
                  If you proceed,
                  <b>
                    {' '}
                    {numToUpdate} annotation{numToUpdate < 2 ? '' : 's'}{' '}
                  </b>
                  will no longer appear.
                </p>
                <br />
              </>
            )}
            <p>Your layout dimensions will reset to the selected layout&apos;s defaults.</p>
            {this.table}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleMenuReset} content='Cancel' />
          <Button primary onClick={this.confirm} content='OK' />
        </Modal.Actions>
      </Modal>
    );
  }
}
