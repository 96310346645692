import React, { useState } from 'react';

import { Container } from 'semantic-ui-react';

import { Form, Button } from '../../hapyak-ui-toolkit';
import { installGoogleDrive } from '../../services/persistence/platformService';

const SCOPE = [
  'https://www.googleapis.com/auth/drive.file',
  'https://www.googleapis.com/auth/drive.install',
  'https://www.googleapis.com/auth/drive.appdata',
];

const TEST_FILE_ID = '10cg69q5hNDSJ2U5GMb_C6c2ZWhpCdmFw';

export const GoogleDriveInstaller = () => {
  const [installed, setInstalled] = useState(false);
  const [installFailed, setInstallFailed] = useState(false);
  const { hostname, pathname, search } = document.location;
  const redirect = `https://${hostname}${pathname}`;
  const query = new URLSearchParams(search);
  const clientId = query.get('clientId') || '';
  const fileId = query.get('fileId') || TEST_FILE_ID;

  if (installFailed) {
    return (
      <Container style={{ margin: 20 }}>
        <h1>Install Failed</h1>
      </Container>
    );
  }

  if (query.get('state')) {
    // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    const state = JSON.parse(query.get('state'));

    if (state.successful) {
      if (!installed) {
        installGoogleDrive({ code: query.get('code') })
          .then(() => setInstalled(true))
          .catch((err) => {
            console.error(err);
            setInstallFailed(true);
          });

        return (
          <Container style={{ margin: 20 }}>
            <h1>Finishing Install...</h1>
          </Container>
        );
      }

      const driveFileId = state.fileId || TEST_FILE_ID;

      return (
        <Container style={{ margin: 20 }}>
          <h1>Application Installed</h1>
          <br />
          <a
            href={`https://drive.google.com/file/d/${driveFileId}/view`}
            target='_blank'
            rel='noopener noreferrer'
          >
            Test File
          </a>
        </Container>
      );
    }
  }

  return (
    <Container style={{ margin: 20 }}>
      <h1 style={{ marginBottom: 20 }}>Google Drive HapYak App Installer</h1>
      <Form action='https://accounts.google.com/o/oauth2/v2/auth'>
        <input type='hidden' name='redirect_uri' value={redirect} />
        <input type='hidden' name='response_type' value='code' />
        <input type='hidden' name='access_type' value='offline' />
        <input type='hidden' name='scope' value={SCOPE.join(' ')} />
        <input type='hidden' name='state' value={JSON.stringify({ successful: true, fileId })} />
        <Form.Field>
          <label>Client ID</label>
          <input name='client_id' required defaultValue={clientId} />
        </Form.Field>
        <Button type='submit'>Install</Button>
      </Form>
    </Container>
  );
};
