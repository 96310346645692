import axios from 'axios';
import _ from 'lodash';

import DEFAULTS from '../DEFAULTS.json';
import { User } from '../types/user';
import urls from '../utility/urls';
import { comm } from './comm';
import logger from './logger';
import { isGoogleDriveOpen } from './navUtils';
import { QueuedHttpClient } from './queuedHttpClient';
import { stateController } from './stateController';

const SessionClientConfig = {
  pingInterval: 6000, // MS between polls
};

class SessionClient {
  _httpClient: any;
  _httpPlatformClient: any;
  _queueClient: any;
  config: any;
  halt: any;
  session: any;
  constructor (config: any) {
    this.session = null;
    this.halt = false;
    this.config = config;
  }

  captureCsrfToken () {
    //
    // Get the cross-site request forgery prevention token from the initial login redirect url hash
    //
    // Must match the csrf value stored in the server session to allow mutating api calls to succeed
    //
    try {
      const hash = urls.hashToObject(window.location.hash);
      if (hash.csrf) {
        window.localStorage.setItem(DEFAULTS.CSRF_KEY, hash.csrf);
      }
      // "clean" the URL to remove the hash fragment
      window.history.replaceState({}, document.title, window.location.pathname + window.location.search);
    } catch (e) {
      logger.warn('Failed to capture CSRF token');
    }
  }

  logout () {
    const loginUrl = `https://${process.env.REACT_APP_HOST}/login`;
    if (window.location.href === loginUrl) {
      return;
    }

    window.location.href = loginUrl;
  }

  clearSession () {
    this.getHttpPlatformClient(false).get('/back-end/auth/logout', {});
  }

  isNewselaUser () {
    const newselaGroupIds = process.env.REACT_APP_NEWSELA_GROUP_IDS || '';

    return newselaGroupIds
      .split(',')
      .map((item: any) => item.trim())
      .includes(String(this.session.user.group.id));
  }

  async getRemoteSession () {
    let result;
    try {
      const sessionRequired = false;
      result = await this.getHttpPlatformClient(sessionRequired).get('/back-end/auth/session', {});
    } catch (error) {
      if ((error as any).response.status === 401) {
        this.logout();
      }
    }

    this.session = result.data;
    if (this.session && this.session.user) {
      comm.trigger('sessionEvent', 'logged-in', this.session);
    } else {
      comm.trigger('sessionEvent', 'logged-out', this.session);
    }
  }

  async getGroupMemberships () {
    const result = await this.getHttpPlatformClient().get('/back-end/auth/session/group-memberships', {});
    return result.data;
  }

  async setCurrentGroup (groupId: any) {
    const result = await this.persistGroupChange(groupId);
    const group = result.data;
    const session = this.session;

    session.user.group = group;
    comm.trigger('sessionEvent', 'logged-in', session);
    comm.trigger('updateAppState');
  }

  async persistGroupChange (groupId: any) {
    return await this.getHttpPlatformClient().post('/back-end/auth/session/current-group', {
      groupId: groupId,
      csrfToken: window.localStorage.getItem(DEFAULTS.CSRF_KEY),
    });
  }

  async addGroupMembership ({
    groupId,
    ...params
  }: any) {
    const result = await this.getHttpClient().post('/auth/session/add-group-membership', {
      groupId,
      csrfToken: window.localStorage.getItem(DEFAULTS.CSRF_KEY),
      ...params,
    });

    const group = result.data;
    const session = this.session;

    session.user.group = group;
    comm.trigger('sessionEvent', 'logged-in', session);
    comm.trigger('updateAppState');
  }

  getCurrentGroup () {
    return _.get(this.session, 'user.group') || {};
  }

  getCurrentUser () {
    return _.get(this.session, 'user.user');
  }

  getCurrentUserDetails (): User {
    const { userEmail, userName, userId } = _.get(this.session, 'user');
    return {
      email: userEmail,
      fullName: userName,
      id: userId
    };
  }

  getUsingGoogleDrive () {
    return this.session?.usingGoogleDrive || false;
  }

  groupSelectionAllowed (location: Location) {
    return !isGoogleDriveOpen(location);
  }

  async getServiceAccessToken (params?: any) {
    const { id } = stateController.getCurrentData('project');
    return await this.getGroupServiceAccessToken({ projectId: id, ...params });
  }

  async getGroupServiceAccessToken (params: any) {
    const body = { csrfToken: window.localStorage.getItem(DEFAULTS.CSRF_KEY), ...params };
    const result = await this.getHttpPlatformClient().post('/back-end/auth/session/service-access-token', body);
    return result.data;
  }

  async getClientConfiguration () {
    const result = await this.getHttpPlatformClient().get('/back-end/configuration', {
      headers: { 'X-CSRF-TOKEN': window.localStorage.getItem(DEFAULTS.CSRF_KEY) },
    });

    return result.data;
  }

  async start () {
    this.halt = false;
    await this.run();
  }

  stop () {
    this.halt = true;
  }

  async run () {
    if (!this.halt) {
      try {
        await this.getRemoteSession();
        this.getHttpClient().queuing = false;
      } catch (e) {
        // ignore the error so that polling continues
        logger.warn(e);
      }
      setTimeout(() => this.run(), this.config.pingInterval);
    }
  }

  getHttpClient (sessionRequired = true) {
    if (!this._httpClient) {
      this._httpClient = axios.create({
        baseURL: `https://${process.env.REACT_APP_DATA_SERVICES_HOST}/api/`,
        timeout: 5000,
        withCredentials: true,
      });
    }

    if (!this._queueClient) {
      this._queueClient = new QueuedHttpClient(this._httpClient);
    }

    return sessionRequired ? this._queueClient : this._httpClient;
  }

  getHttpPlatformClient (sessionRequired = true) {
    if (!this._httpPlatformClient) {
      this._httpPlatformClient = axios.create({
        baseURL: `https://${process.env.REACT_APP_PLATFORM_HOST}`,
        timeout: 5000,
        withCredentials: true,
      });
    }

    return this._httpPlatformClient;
  }
}

// Intentionally creating a single instance here
const sessionClientInstance = new SessionClient(SessionClientConfig);
export default sessionClientInstance;

if (process.env.REACT_APP_DEBUG) {
  (window as any).hapyakSessionClient = sessionClientInstance;
}
