import './StylesheetModal.scss';

import React, { Component } from 'react';

import { Button, Input, RadioButton } from '@newsela/angelou';

import { Message, Modal } from '../../../../hapyak-ui-toolkit';
import signingService from '../../../../services/signingService';
import { assetService, stateController } from '../../../../services/stateController';
import { getFileName } from '../../../../services/utils';
import ErrorBanner from '../../AngelouComponents/ErrorBanner';
import { Upload } from '../../AssetsEditor/Upload';

const TYPE = 'text/css';
const CONTENT_TYPE = 'url';

type State = any;

type StylesheetModalProps = {
  close: () => void;
};

export class StylesheetModal extends Component<StylesheetModalProps, State> {
  constructor (props: StylesheetModalProps) {
    super(props);
    this.state = { ...this.initialState };
  }

  get initialState () {
    return {
      src: '',
      isValid: false,
      deleted: false,
      isControlled: false,
      uploadFromComputer: false,
      uploadFromUrl: false
    };
  }

  onStyleSheetChange = (input: string) => {
    const isValid = this.isValidUrl(input);

    this.setState({
      src: input,
      isControlled: false,
      isValid,
    });
  };

  componentDidMount () {
    const { project } = stateController.getCurrentData();
    const { src = '', isControlled = false } = this.currentStylesheet;

    this.setState({
      src: src ? signingService.unsign(src) : '',
      projectId: project.id,
      isValid: !!src,
      isControlled
    });
  }

  handleUpload = (src: string) => {
    const { id } = stateController.getCurrentData('project');

    this.setState({
      src: src,
      projectId: id,
      isValid: true,
      isControlled: true,
    });
  };

  onBlur = () => {
    const { src = '' } = this.state;
    const { id } = stateController.getCurrentData('project');

    this.setState({
      src: src,
      projectId: id,
      contentType: CONTENT_TYPE,
    });
  };

  isValidUrl = (url: string) => {
    return url.endsWith('.css');
  };

  onAccept = () => {
    const { src = '', projectId, isControlled } = this.state;
    const { close } = this.props;

    if (src) {
      assetService.createCss(
        {
          src: src,
          projectId: projectId,
          type: TYPE,
          mimetype: TYPE,
          contentType: CONTENT_TYPE,
          isControlled: isControlled,
        },
        false
      );
    }

    close();
  };

  onDelete = () => {
    assetService.deleteCss(this.currentStylesheet);
    this.setState({ ...this.initialState });
  };

  isUpload = (src: string) => {
    return src.includes('hapyak-tools-assets');
  };

  toggleUploadFromComputer = () => {
    this.setState({ uploadFromComputer: true, uploadFromUrl: false });
  };

  toggleUploadFromUrl = () => {
    this.setState({ uploadFromComputer: false, uploadFromUrl: true });
  };

  get parsedSrc () {
    const { src = '' } = this.state;
    return src ? `${getFileName(src)}.css` : '';
  }

  get currentStylesheet () {
    return assetService.getCssArray()[0] || {};
  }

  render () {
    const { close } = this.props;
    const { src, isValid, deleted, uploadFromComputer, uploadFromUrl } = this.state;
    const isUpload = this.isUpload(src);
    const inputValue = src && !isUpload ? src : '';

    return (
      <div>
        <Modal className='hy-stylesheet-modal' open size='small' onClose={close}>
          <Modal.Header>Upload CSS</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <div>
                <div className='stylesheet-upload-row'>
                  <div className='stylesheet-upload-row-first-col'>
                    <RadioButton
                      checked={isUpload || (uploadFromComputer && !src)}
                      label='My Computer'
                      value='My Computer'
                      name='CSS Upload'
                      onClick={this.toggleUploadFromComputer}
                      __cssFor={{
                        root: {
                          width: '3rem',
                          marginTop: '4rem'
                        }
                      }}
                      __classNameFor={{
                        labelText: 'hidden-style'
                      }}
                    />
                  </div>
                  <div className='stylesheet-upload-row-second-col'>
                    <Upload
                      afterUpload={this.handleUpload}
                      type={TYPE}
                      filetype={TYPE}
                      label='CSS or text'
                    />
                  </div>
                </div>
                <div>{src && isUpload && this.parsedSrc}</div>
                <div className='stylesheet-upload-row'>
                  <div className='stylesheet-upload-row-first-col'>
                    <RadioButton
                      checked={(!!src && !isUpload) || (uploadFromUrl && !isUpload)}
                      name='CSS Upload'
                      label='CSS URL'
                      value='CSS URL'
                      onClick={this.toggleUploadFromUrl}
                      __cssFor={{
                        root: {
                          width: '3rem',
                          marginTop: '2.5rem'
                        }
                      }}
                      __classNameFor={{
                        labelText: 'hidden-style'
                      }}
                    />
                  </div>
                  <div className='stylesheet-upload-row-second-col'>
                    <Input
                      inputType={Input.inputTypes.url}
                      label='CSS URL'
                      onBlur={this.onBlur}
                      onChange={(e: any) => this.onStyleSheetChange(e.target.value)}
                      placeholder='https://example.com/stylesheet.css'
                      value={inputValue}
                    />
                  </div>
                </div>
              </div>
            </Modal.Description>
            {src && !isValid && (
              <ErrorBanner
                body='URL must end with .css'
                validationError
              />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              legacy_flavor={Button.legacy_flavor.solid}
              onClick={close}
              legacy_size={Button.legacy_size.medium}
              __classNameFor={{ root: 'tertiary' }}
              __cssFor={{ root: { marginLeft: '1rem' } }}
            >
              Cancel
            </Button>
            <Button
              disabled={!isValid && !deleted}
              legacy_flavor={Button.legacy_flavor.hollow}
              onClick={this.onAccept}
              legacy_size={Button.legacy_size.medium}
              legacy_statusColor={Button.legacy_statusColor.secondary}
              __cssFor={{ root: { marginLeft: '1rem' } }}
            >
              Save
            </Button>
            <Button
              disabled={!isValid}
              legacy_flavor={Button.legacy_flavor.solid}
              onClick={this.onDelete}
              legacy_statusColor={Button.legacy_statusColor.primary}
              legacy_size={Button.legacy_size.medium}
              __cssFor={{ root: { marginLeft: '1rem' } }}
            >
              Reset
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
