import './CustomStepTabs.scss';
import React from 'react';

import _ from 'lodash';

import { BasicSection } from '../../components/editors/CommonComponents/Menu/BasicSection';
import { WrappingTabs } from '../../components/editors/CommonComponents/WrappingTabs';
import { Button, Icon, Grid } from '../../hapyak-ui-toolkit';
import { ComponentTab, TabPaneProps } from '../../types/layout/tabs';

type State = any;

type CustomStepTabsProps = TabPaneProps & {
  forceTabIndex: boolean;
  paginate?: boolean;
  tabContent: ComponentTab[];
  wrap?: boolean;
};

class CustomStepTabs extends React.Component<CustomStepTabsProps, State> {
  constructor (props: CustomStepTabsProps) {
    super(props);

    this.state = {
      canTransition: true,
      tabIndex: null,
    };
  }

  componentDidUpdate (prevProps: CustomStepTabsProps, prevState: State, snapshot: any) {
    const { forceTabIndex } = this.props;
    if (forceTabIndex !== prevProps.forceTabIndex && _.isNumber(forceTabIndex)) {
      this.changeTab(forceTabIndex);
    }
  }

  createNavBtn = ({
    position = 'right',
    text = '',
    idx,
    hide,
    disabled = false
  }: any) => {
    const button = (
      <Button
        style={{ marginBottom: '5px', marginTop: '5px' }}
        icon
        labelPosition={position}
        disabled={disabled}
        onClick={this.changeTab.bind(this, idx)}
      >
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        {text} <Icon name={`${position} arrow`} />
      </Button>
    );

    return hide ? null : button;
  };

  getTablinks = (idx: any) => {
    const { tabContent } = this.props;

    if (!tabContent) return [];

    const linkStyle = { margin: '10px' };
    const links = tabContent.map((tab: any, index: number) => {
      const className = 'hy-link' + (tab.disabled ? ' hy-disabled hy-disabled-opacity' : '');
      const link = (
        <div className={className} key={index} style={linkStyle} onClick={this.changeTab.bind(this, index)}>
          skip to {tab.name}
        </div>
      );

      const shunnedIndices = [...Array.from(Array(idx).keys()), idx, idx + 1];
      return shunnedIndices.includes(index) ? null : link;
    });

    const hasLinks = !!links.filter((link: any) => !!link).length;

    return hasLinks ? <div>{links}</div> : null;
  };

  get tabs () {
    const { tabContent, paginate } = this.props;

    if (!tabContent) return [];

    return tabContent.map((tab: any, idx: number) => {
      const nextIdx = idx + 1;
      const isNext = !(tabContent.length - 1 === idx);
      const nextTab = tabContent[nextIdx] || {};
      const nextButton = this.createNavBtn({
        position: 'right',
        text: `Next${isNext ? ', ' + nextTab.name.toString() : ''}`,
        idx: nextIdx,
        hide: !isNext,
        disabled: nextTab.disabled,
      });

      const links = this.getTablinks(idx);
      const showNav = paginate && (links || nextButton);

      return {
        ...tab,
        render: () => {
          return this.wrap(
            idx,
            <div className='hy-compact-grid-row tab-content-and-next-container'>
              {tab.items.map((item: any, idx: number) => {
                return (
                  <div
                    className='main-tab-content'
                    key={idx}
                  >
                    {item}
                  </div>
                );
              })}
              {showNav && (
                <div className='next-button-links'>
                  <div>{nextButton}</div>
                  <div>{links}</div>
                </div>
              )}{' '}
            </div>
          );
        },
      };
    });
  }

  wrap = (idx: number, content: any) => {
    const { wrap } = this.props;
    return wrap ? <BasicSection key={idx} renderedContent={content} /> : content;
  };

  changeTab = (tabIndex: number) => {
    this.setState({ tabIndex });
  };

  onTabChange = (): Promise<boolean> => {
    return new Promise((resolve) => {
      this.setState({ tabIndex: null });
      return resolve(true);
    });
  };

  render () {
    const { type, updateTabCallbackKey, className = '', ignorePreferences } = this.props;
    const { canTransition, tabIndex } = this.state;

    if (!type || !updateTabCallbackKey) return;

    return (
      <div>
        <WrappingTabs
          components={this.tabs}
          className={className}
          forceActiveTabByIndex
          tabIndex={canTransition ? tabIndex : null}
          cancelTabChangeEvent={!canTransition}
          onTabChange={this.onTabChange}
          type={type}
          ignorePreferences={ignorePreferences}
          updateTabCallbackKey={updateTabCallbackKey}
        />
      </div>
    );
  }
}

export { CustomStepTabs };
