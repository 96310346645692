import React, { useState } from 'react';

import { Button, HorizontalDotsSVG, Icon, PopOut, TrashFilledSVG } from '@newsela/angelou';

import { Annotation } from '../../../../../../types/annotations';
import './QuizAnswerRowOptionsPopOut.scss';

type QuizAnswerRowOptionsPopOutProps = {
  addOrRemoveDisabled?: boolean;
  annotations: Annotation[];
  quizAnswerId: string;
  onDelete: (quizAnswerId: string, annotations: Annotation[]) => void;
  removeEnabled: boolean;
};

export const QuizAnswerRowOptionsPopOut = ({
  addOrRemoveDisabled,
  annotations,
  onDelete,
  quizAnswerId,
  removeEnabled = true
}: QuizAnswerRowOptionsPopOutProps) => {
  const [isPopOutVisible, setIsPopOutVisible] = useState(false);
  const showOptions = !addOrRemoveDisabled && removeEnabled;
  const popOutControlId = `${quizAnswerId}-pop-out-control`;

  const togglePopOutVisibility = () => {
    setIsPopOutVisible((isPopOutVisible) => !isPopOutVisible);
  };

  const deleteOptionAndHidePopOut = () => {
    onDelete(quizAnswerId, annotations);
    togglePopOutVisibility();
  };

  return (
    <div className={showOptions ? 'answer-row-pop-out-button' : ''}>
      <Button
        ariaProps={{ 'aria-label': 'pop-out-button' }}
        legacy_flavor={Button.legacy_flavor.incognito}
        onClick={togglePopOutVisibility}
        __cssFor={{
          root: {
            visibility: showOptions ? 'visible' : 'hidden',
            padding: '12px',
            height: '48px',
            width: '48px'
          }
        }}
      >
        <Icon
          SvgComponent={HorizontalDotsSVG}
          isDecorative={false}
          title='answer row options'
          __cssFor={{
            root: {
              width: '2rem',
              height: '2rem',
              paddingBottom: '2px'
            }
          }}
        />
      </Button>
      <PopOut
        ariaControlsId={popOutControlId}
        ariaProps={{ 'aria-labelledby': popOutControlId }}
        isVisible={isPopOutVisible}
        onClickOff={togglePopOutVisibility}
        onEsc={togglePopOutVisibility}
        verticalOffset='10px'
        horizontalPosition='RIGHT'
        __cssFor={{
          root: {
            zIndex: '200'
          }
        }}
      >
        <div className='answer-row-pop-out-menu'>
          <div
            className='option'
            onClick={deleteOptionAndHidePopOut}
          >
            <Icon
              SvgComponent={TrashFilledSVG}
              isDecorative={false}
              title='delete answer row'
              __cssFor={{
                root: {
                  marginLeft: 0,
                  path: {
                    stroke: '#c32113',
                    fill: 'none',
                    strokeWidth: '3'
                  }
                }
              }}
            />
            <span className='option-text'>Delete</span>
          </div>
        </div>
      </PopOut>
    </div>
  );
};
