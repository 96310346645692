import './index.scss';

import React, { useState } from 'react';

import { Button } from '@newsela/angelou';

import DEFAULTS from '../../../../DEFAULTS.json';
import { comm } from '../../../../services/comm';
import { media } from '../../../../services/mediaController';
import { getActiveVideoProcesses, processDisplayMap } from '../../../../services/processRunner';
import { assetService } from '../../../../services/stateController';
import { getFileName } from '../../../../services/utils';
import { VideoFormats } from '../../CommonComponents/AssetList/VideoFormats';
import { VideoMetadata } from '../../CommonComponents/AssetList/VideoMetadata';
import { EditableText } from '../../CommonComponents/EditableText';
import { BasicSection } from '../../CommonComponents/Menu/BasicSection';
import { RunningStatus } from '../../CommonComponents/RunningStatus';
import { VideoUploadModal } from './VideoUploadModal';

type VideoEditorProps = {
  processing: boolean;
}

export const VideoEditor = ({
  processing
}: VideoEditorProps) => {
  const [name, setName] = useState<string>(media.video.name);

  const persistName = (name: string) => {
    assetService.setVideo({ ...media.video, name });
  };

  const beforeUpload = () => {
    assetService.setVideo({ ...media.video, name: name });
  };

  const afterUpload = (src: string) => {
    setName(getFileName(src));
  };

  const videoNameInput = () => {
    return (
      <EditableText
        style={{ marginLeft: '3px' }}
        value={name}
        onBlur={persistName}
        placeholder='Edit Name'
      />
    );
  };

  const getActiveProcess = () => {
    const processes = getActiveVideoProcesses(processing);
    const [process] = processes;
    return process;
  };

  const getIsTranscoding = () => {
    const activeProcess = getActiveProcess();
    return activeProcess === processDisplayMap[DEFAULTS.TRANSCODE_KEY] ||
      activeProcess === processDisplayMap[DEFAULTS.TRANSCODE_REMOTE_UPLOAD_KEY];
  };

  const getActiveProcessStatus = () => {
    const isTranscoding = getIsTranscoding();
    return isTranscoding ? (
      <RunningStatus
        inline
        icon={/* AUTOGENERATED TODO: ANGELOU BREAKING CHANGE v0.26_v0.27! CHECK RENDER PATTERN HERE */
          {
            name: 'sync alternate'
          }
        }
        content='Transcoding process active. Screenshots taken during processing will be available when process ends.'
      />
    ) : null;
  };

  const stopEditing = () => {
    comm.trigger('selectAnnotations', []);
  };

  const stopEditingButton = () => {
    return (
      <Button
        legacy_flavor={Button.legacy_flavor.solid}
        legacy_statusColor={Button.legacy_statusColor.tertiary}
        legacy_size={Button.legacy_size.medium}
        onClick={stopEditing}
        __classNameFor={{ root: 'tertiary' }}
      >
        Done
      </Button>
    );
  };

  const replaceButton = () => {
    return (
      <VideoUploadModal
        activeProcess={getActiveProcess()}
        video={media.video}
        beforeUpload={beforeUpload}
        afterUpload={afterUpload}
      />
    );
  };

  const getInfoSectionContent = () => {
    const src = media.bestAvailableFormat;

    return [
      {
        title: 'Current Source Files',
        renderedContent: <VideoFormats inline />,
      },
      {
        title: 'MetaData',
        renderedContent: (
          <div>
            <div className='video-editor-row-no-padding'>
              <VideoMetadata transcoding={getIsTranscoding()} />
            </div>
          </div>
        ),
      },
      {
        title: 'Current Video',
        renderedContent: (
          <div>
            <div className='video-editor-row-no-padding'>
              <video src={src} controls width='100%' />
            </div>
          </div>
        ),
      },
    ];
  };

  const infoSection = () => {
    const content = getInfoSectionContent();
    const activeProcess = getActiveProcessStatus();

    // @ts-expect-error TS(2345): Argument of type '{ renderedContent: JSX.Element; ... Remove this comment to see the full error message
    if (activeProcess) content.unshift({ renderedContent: activeProcess });

    return <BasicSection renderedContent={content} />;
  };

  return (
    <div className='hy-video-editor hy-absolute'>
      <div className='editor-header'>
        {videoNameInput()}
        <div className='editor-header-buttons'>
          {replaceButton()}
          {stopEditingButton()}
        </div>
      </div>
      <div>
        <div className='editor-content'>
          {infoSection()}
        </div>
      </div>
    </div>
  );
};
