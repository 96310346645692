import React, { Component } from 'react';

import ReactDOMServer from 'react-dom/server';

import { Bundle, Embed } from 'types/publishing';

import { assetService } from '../../../services/stateController';
import { toPercent } from '../../../services/utils';
import { AngelouLoadingSpinner } from '../AngelouComponents/AngelouLoadingSpinner';
import { CopyHelper } from '../AssetsEditor/CopyHelper';
import { EmbedFactory } from './Distributables/EmbedFactory';

type State = any;

type EmbedTextBoxProps = {
  bundle: Bundle;
  className: any;
  embed: Embed;
  secondaryCTA: any;
};

export class EmbedTextBox extends Component<EmbedTextBoxProps, State> {
  constructor (props: EmbedTextBoxProps) {
    super(props);

    this.state = {
      aspectRatio: this.getThumbnailSrc(this.props) ? null : toPercent(9 / 16, 1),
    };
  }

  getThumbnailSrc = (props: EmbedTextBoxProps) => {
    const { bundle } = props;
    return assetService.getImage(bundle.thumbnail).src;
  };

  get embedCode () {
    const { bundle, embed, className = '', secondaryCTA } = this.props;
    const { aspectRatio } = this.state;

    const embedInstance = EmbedFactory.createEmbed(
      {
        ...bundle,
        src: bundle.customSrc || bundle.src,
      },
      embed,
      aspectRatio
    );
    const asMarkup = ReactDOMServer.renderToStaticMarkup(embedInstance?.render(true) || <></>);
    const decodeHtml = (html: any) => {
      const txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    };

    const formatted = decodeHtml(asMarkup);

    return (
      <CopyHelper
        className={className}
        buttonText='Copy Embed'
        value={formatted}
        showText
        as='button'
        readOnly={false}
        rows={5}
        buttonPosition='under'
        float='right'
        secondaryCTA={secondaryCTA}
      />
    );
  }

  onThumbnailLoad = ({
    target
  }: any) => {
    this.setState({ aspectRatio: toPercent(target.clientHeight / target.clientWidth, 1) });
  };

  get hiddenImageForAspectRatio () {
    return (
      <img
        style={{ opacity: 0 }}
        src={this.getThumbnailSrc(this.props)}
        alt='embed thumbnail'
        onLoad={this.onThumbnailLoad}
      />
    );
  }

  get loader () {
    return (
      <>
        {this.hiddenImageForAspectRatio}
        <AngelouLoadingSpinner />
      </>
    );
  }

  render () {
    const { aspectRatio } = this.state;
    if (!aspectRatio) return this.loader;
    return this.embedCode;
  }
}
