import './index.scss';

import React from 'react';

import { Input, RadioButton } from '@newsela/angelou';

import { Dropdown } from '../../../../hapyak-ui-toolkit';
import { assetService } from '../../../../services/stateController';
import { ImagePickerProps } from '../../../../types/assets';
import { Upload } from '../../AssetsEditor/Upload';

type State = any;

type ImageChooserProps = ImagePickerProps & {
  chooseExisting: (val: any) => void;
  setFromSource: (src: any, isControlled?: boolean) => void;
}

export class ImageChooser extends React.Component<ImageChooserProps, State> {
  constructor (props: ImageChooserProps) {
    super(props);

    this.state = {
      checked: false,
      ...this.initialRadioState,
    };
  }

  setFromSource = (src: string, isControlled?: boolean) => this.props.setFromSource(src, isControlled);

  handleUpload = (src: string) => {
    this.setFromSource(src, true);
    this.updateRadios('imageUpload');
  };

  getImageAssets = (filterType: any) => {
    const images = assetService.getImagesArray();
    const filteredByType = filterType ? images.filter(({
      type
    }: any) => type === filterType) : images;
    return filteredByType.map(({
      id,
      src
    }: any) => ({ key: id, image: src, value: id }));
  };

  handleInputChange = (e: any) => {
    this.updateRadios('imageUrl');
    this.setFromSource(e.target.value);
  };

  updateRadios = (selected: any) => {
    const updated = this.initialRadioState;
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    updated[selected] = true;
    this.setState({
      ...updated,
    });
  };

  get initialRadioState () {
    return {
      imageUpload: false,
      imageDropdown: false,
      imageUrl: false,
    };
  }

  handleDropdownChange = (e: any, data: any) => {
    const { value } = data;
    this.updateRadios('imageDropdown');
    if (!value) return;
    this.props.chooseExisting(value);
  };

  render () {
    const { disabled, images, browse, fromUrl, type } = this.props;
    const imageAssets = images || this.getImageAssets(type);
    const { imageUpload, imageDropdown, imageUrl } = this.state;

    return (
      <div className='hy-image-chooser'>
        {browse !== false && (
          <div className='image-upload-row'>
            <div className='image-upload-row-first-col'>
              <RadioButton
                checked={imageUpload}
                name='image upload'
                label=''
                value=''
                __cssFor={{
                  root: {
                    marginTop: '4.25rem',
                    width: '3rem'
                  }
                }}
              />
            </div>
            <div className='image-upload-row-second-col'>
              <Upload
                filetype={type === 'watermark' ? 'image/png' : 'image/*'}
                afterUpload={this.handleUpload}
                disabled={disabled}
                type='image'
                label='Image'
              />
            </div>
          </div>
        )}
        <div className='image-upload-row'>
          <div className='image-upload-row-first-col'>
            <RadioButton
              checked={imageDropdown}
              name='image upload'
              label=''
              value=''
              __cssFor={{
                root: {
                  marginTop: '0.3rem',
                  width: '3rem'
                }
              }}
            />
          </div>
          <div className='image-upload-row-second-col'>
            <Dropdown
              scrolling
              labeled
              button
              text='Choose an existing image'
              openOnFocus
              className='icon hy-thumbnail-upload button'
              onChange={this.handleDropdownChange}
              options={imageAssets}
              disabled={disabled || !imageAssets.length}
            />
          </div>
        </div>
        {fromUrl !== false && (
          <div className='image-upload-row'>
            <div className='image-upload-row-first-col'>
              <RadioButton
                checked={imageUrl}
                name='image upload'
                label=''
                value=''
                __cssFor={{
                  root: {
                    marginTop: '2.5rem',
                    width: '3rem'
                  }
                }}
              />
            </div>
            <div className='image-upload-row-second-col'>
              <Input
                label='Image URL'
                onChange={this.handleInputChange}
                placeholder='https://example.com/screenshot.png'
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
