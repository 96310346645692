import React, { MouseEvent } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

import { getUserPreferencesByKey, updateUserPreferencesByKey } from '../../services/saveUtils';
import signingService from '../../services/signingService';
import { stopEvent } from '../../services/utils';
import { Button, CustomInput, Dropdown, Icon, Image, Label, Modal, SearchBox, Table } from '../index';
import COLUMNS from './columns.json';
import { isDir } from './summaryHelper';

const SEARCHABLE_COLUMNS = Object.keys(COLUMNS).filter((k:string) => COLUMNS[k as keyof typeof COLUMNS].search);

const ROOT = 'ROOT';
const PATH_DELIMITER = '/';

const WIDTH_OFFSET = '39px';

const typeFormats = {
  array: (v: any) => v ? v.join(', ') : '',
  boolean: (v: any) => v && (_.isNumber(v) || _.isBoolean(v)) ? <Icon name='check' color='green' /> : '',
  date: (v: any) => v ? moment(v).format('M/D/YY k:mm') : '',
  image: (v: any) => v ? <Image src={signingService.sign(v)} size='tiny' style={{ margin: '0 auto' }} /> : '',
  number: (v: any) => v,
  string: (v: any) => v || '',
};

const formatColumnValue = (key: string, value: any) => {
  const column = COLUMNS[key as keyof typeof COLUMNS];

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return typeFormats[column.type](value);
};

const formPath = (...parts: any[]) => {
  return parts.filter(Boolean).join(PATH_DELIMITER);
};

const getPathParts = (path: string) => {
  return path.split(PATH_DELIMITER);
};

type FileBrowserState = any;

export type FileBrowserItem = {
  id: string;
  created: number;
  createdBy?: number;
  folder: string;
  isPublished: boolean;
  lastModifiedBy?: number;
  modified: number;
  name: string;
  packages?: any;
  path: string;
  posterImage: any;
  type: string;
};

type FileBrowserProps = {
  createFolder?: any;
  prefKey: string;
  handleDelete: (item: FileBrowserItem) => void;
  handleCopy: (items: FileBrowserItem[], dest: string | null) => void;
  handleMove: (items: FileBrowserItem[], dest: string | null) => void;
  handleOpen: (item: FileBrowserItem) => void;
  handleRename: (item: FileBrowserItem, value: string) => void;
  items: FileBrowserItem[];
  select: (id: string | null, e?: any) => void;
  selected: string[];
};

class FileBrowser extends React.Component<FileBrowserProps, FileBrowserState> {
  constructor (props: FileBrowserProps) {
    super(props);

    this.state = this.getInitialState(props);
  }

  getInitialState = (props: FileBrowserProps) => {
    const prefs = getUserPreferencesByKey(props.prefKey);
    const defaultVisibleColumns = this.getVisibleColumnKeys(COLUMNS);

    return {
      action: null,
      destination: null,
      lastCreatedId: null,
      openPaths: prefs.openPaths || [],
      columns: this.setVisibleColumns(prefs.visibleColumns || defaultVisibleColumns, COLUMNS),
      search: '',
      sortProperty: COLUMNS.modified.key,
      sortOrder: -1, // 1 = ascending, -1 = descending
      forceResetName: null,
    };
  };

  get openPaths () {
    const openPaths = this.state.openPaths.filter((p: string) => this.existingPaths.includes(p));
    return _.uniq([ROOT, ...openPaths]);
  }

  setFileBrowserPrefs = () => {
    const { columns } = this.state;
    return updateUserPreferencesByKey(this.props.prefKey, {
      openPaths: this.openPaths,
      visibleColumns: this.getVisibleColumnKeys(columns),
    });
  };

  getVisibleColumnKeys = (columns: any) => {
    return Object.keys(columns).filter((k) => columns[k].display);
  };

  setVisibleColumns = (visibleColumnKeys: any, columns: any) => {
    Object.keys(columns).forEach((k) => {
      columns[k].display = visibleColumnKeys.includes(k);
    });

    return columns;
  };

  toggleFolderOpenRecursive = (path: any) => {
    if (path === ROOT) {
      this.setFileBrowserPrefs();
      return Promise.resolve();
    }

    const openPaths = _.uniq([...this.state.openPaths, path]);

    return new Promise((resolve) => {
      this.setState({ openPaths }, () => {
        return resolve(this.toggleFolderOpenRecursive(formPath(...getPathParts(path).slice(0, -1))));
      });
    });
  };

  toggleFolderOpen = (path: any) => {
    const openPaths = _.xor(this.state.openPaths, [path]); // add or remove item
    this.setState({ openPaths }, this.setFileBrowserPrefs);
  };

  setSortProperty = (prop: string) => {
    const { sortOrder, sortProperty } = this.state;

    if (prop === sortProperty) {
      this.setState({ sortOrder: sortOrder * -1 });
      return;
    }

    this.setState({ sortProperty: prop });
  };

  get displayedItems () {
    const { search } = this.state;

    if (search) {
      return this.filterBySearch.sort(this.sortByProperty);
    }

    return this.sortItems(this.filterByOpenFolders);
  }

  get filterBySearch () {
    const { items } = this.props;
    return items.filter(this.searchItem);
  }

  get filterByOpenFolders () {
    const { items } = this.props;

    return items.filter((item: FileBrowserItem) => {
      const pathParts = getPathParts(item.path);
      const ancestorPaths = pathParts.reduce((paths: string[], currentPath: string) => {
        const lastPath = paths[paths.length - 1];
        const newPath = formPath(lastPath, currentPath);
        return [...paths, newPath];
      }, []);

      return ancestorPaths.every((path: string) => this.openPaths.includes(path));
    });
  }

  sortItems = (items: FileBrowserItem[]) => {
    this.remainingItems = [...items];
    this.sorted = [];
    this.bruteSort(ROOT);
    return this.sorted;
  };

  remainingItems: FileBrowserItem[] = [];
  sorted: FileBrowserItem[] = [];
  bruteSort = (path: string) => {
    const itemsAtLevel = this.remainingItems.filter((item) => item.path === path);
    const dirsAtLevel = itemsAtLevel.filter(isDir);
    const filesAtLevel = itemsAtLevel.filter((item) => !isDir(item));
    this.remainingItems = this.remainingItems.filter((item) => item.path !== path);
    const sortedDirsAtLevel = dirsAtLevel.sort(this.sortByProperty);
    const sortedFilesAtLevel = filesAtLevel.sort(this.sortByProperty);
    const dirsThenFiles = [...sortedDirsAtLevel, ...sortedFilesAtLevel];

    dirsThenFiles.forEach((item) => {
      this.sorted.push(item);
      if (isDir(item)) {
        this.bruteSort(formPath(item.path, item.id));
      }
    });
  };

  compare = (a: any, b: any, disableOrder?: any) => {
    // Intl.Collator().compare allows comparison for non ascii characters (used in some scripts)
    return new Intl.Collator().compare(a, b) * (disableOrder ? 1 : this.state.sortOrder);
  };

  sortByProperty = (itemA: any, itemB: any) => {
    const { sortProperty } = this.state;
    const [a, b] = this.getComparableProps(itemA, itemB, sortProperty);
    return this.compare(a, b);
  };

  getComparableProps = (itemA: FileBrowserItem, itemB: FileBrowserItem, sortProperty: string) => {
    const a = this.getPropertySortKey(itemA, sortProperty);
    const b = this.getPropertySortKey(itemB, sortProperty);
    return [a, b];
  };

  getPropertySortKey = (item: any, prop: any) => {
    return this.prepForSort(item[prop] || item[COLUMNS.name.key]); // sort by name if item doesn't have a value
  };

  prepForSort = (v: any) => {
    // ensure string sorting will work logically
    if (typeof v === 'boolean') return +v;
    if (_.isArray(v)) v = v.length; // treat as number
    if (_.isString(v)) return _.camelCase(v).toLowerCase(); // remove spaces, which are sorted first, lowercase
    if (_.isNumber(v)) return v.toString().padStart(20, '0');
    return v;
  };

  searchItem = (item: any) => {
    const { search } = this.state;

    if (isDir(item)) return false; // no directories (these are shown in breadcrumbs)

    const match = SEARCHABLE_COLUMNS.some((property) => {
      const prop = item[property];
      const val = property === 'path' ? this.getPathBreadcrumbs(prop) : prop;

      // TODO: handle array search
      return val && _.lowerCase(val).includes(_.lowerCase(search));
    });

    return match;
  };

  get headerRow () {
    return <Table.Row>{this.displayedColumns.map(this.createHeaderCell)}</Table.Row>;
  }

  columnTitle (columnKey: string): string {
    const { columns } = this.state;
    if (columns[columnKey].text) {
      return columns[columnKey].text;
    }
    return _.startCase(columnKey);
  }

  createHeaderCell = (columnKey: string, i: any) => {
    const { sortOrder, sortProperty } = this.state;
    const canSort = columnKey === sortProperty;
    return (
      <Table.HeaderCell key={`header_${i}`} onClick={this.setSortProperty.bind(this, columnKey)}>
        {this.columnTitle(columnKey)}
        {canSort && <Icon name={sortOrder === 1 ? 'caret up' : 'caret down'} size='small' />}
      </Table.HeaderCell>
    );
  };

  get rows () {
    return this.displayedItems.map(this.createTableRow);
  }

  get displayedColumns () {
    const { columns } = this.state;
    return Object.keys(columns).filter((k) => columns[k].display);
  }

  handleItemClick = (id: any, e: any) => {
    const { items, selected } = this.props;
    if (selected.length !== 0) {
      const selectedItem = items.find((item: FileBrowserItem) => item.id === selected[0]);
      const newItem = items.find((item: FileBrowserItem) => item.id === id) as FileBrowserItem;
      if (!selectedItem || selectedItem.path === newItem.path) {
        this.props.select(id, e);
        this.setState({ lastCreatedId: null });
      }
    } else {
      this.props.select(id, e);
      this.setState({ lastCreatedId: null });
    }
  };

  createTableRow = (item: any) => {
    const { selected } = this.props;
    const { path, id, name } = item;
    const dirPath = isDir(item) && formPath(path, id);

    return (
      <Table.Row
        key={`row_${id}_${path}_${name}`} // force react to recognize name changes
        style={{ backgroundColor: selected.includes(item.id) ? '#d1ebff' : '' }}
        onClick={this.handleItemClick.bind(this, id)}
        onDoubleClick={isDir(item) ? this.toggleFolderOpen.bind(this, dirPath) : this.handleOpen.bind(this, id)}
      >
        {this.displayedColumns.map((columnKey, columnIndex) => {
          if (columnKey === COLUMNS.name.key) {
            return this.createNameCell(item, dirPath);
          }

          return this.createTableCell(item, columnKey, columnIndex);
        })}
      </Table.Row>
    );
  };

  createNameCell = (item: FileBrowserItem, dirPath: string | boolean) => {
    const { selected } = this.props;
    const { search, forceResetName, lastCreatedId } = this.state;
    const { path, id } = item;
    const breadcrumbs = search && this.formatPathBreadcrumbs(path);
    const typeIcon = this.getIcon(item, dirPath);
    const nestAmount = !search && getPathParts(path).length - 1; // how deeply nested (search results displayed flat)
    const indent = 10 + (nestAmount ? nestAmount * 20 : 0);

    return (
      <Table.Cell
        key='col_name'
        verticalAlign='middle'
        style={{ paddingLeft: indent + 'px', position: 'relative' }}
      >
        {breadcrumbs}
        {typeIcon}
        <CustomInput
          value={item.name}
          onBlur={this.handleRename.bind(this, item)}
          placeholder={item.type + ' name'}
          outline
          inline
          width={`calc(100% - ${WIDTH_OFFSET})`}
          startDisabled
          disabled={!selected.includes(id)}
          forceFocus={lastCreatedId === id}
          forceResetValue={forceResetName}
        />
      </Table.Cell>
    );
  };

  createTableCell = (item: any, columnKey: any, columnIndex: any) => {
    return (
      <Table.Cell key={`col_${columnIndex}`} verticalAlign='middle' textAlign='center'>
        {formatColumnValue(columnKey, item[columnKey])}
      </Table.Cell>
    );
  };

  formatPathBreadcrumbs = (path: string) => {
    return (
      <div style={{ marginBottom: '10px', opacity: 0.6 }}>
        <Icon name='folder' size='small' />
        {this.getPathBreadcrumbs(path)}
      </div>
    );
  };

  getPathBreadcrumbs = (path: string) => {
    const parts = getPathParts(path);
    const pathParts = parts.slice(0, parts.length);

    const pathNames = pathParts.map((id: any) => {
      const item = this.props.items.find((i: any) => {
        return i && i.id === id;
      });

      return item && item.name;
    });

    return pathNames.join(' / ') + ' /';
  };

  getIcon = (item: FileBrowserItem, dirPath: string | boolean) => {
    const isOpen = isDir(item) && this.openPaths.includes(dirPath);
    const { items } = this.props;

    if (!isDir(item)) {
      const width = WIDTH_OFFSET;
      return <Image src={signingService.sign(item.posterImage)} style={{ display: 'inline-block', width }} />;
    }

    const hasContents = items.some((i: any) => i.path === dirPath);

    return (
      <Icon
        color={hasContents ? 'blue' : 'grey'}
        onClick={this.toggleFolderOpen.bind(this, dirPath)}
        size='big'
        fitted
        // @ts-expect-error TS(2769): No overload matches this call.
        name={'folder' + (hasContents ? '' : ' outline') + (isOpen ? ' open' : '')}
        style={{ width: WIDTH_OFFSET }}
      />
    );
  };

  search = ({
    target
  }: any) => {
    this.setState({ search: target.value });
  };

  get visibleColumns () {
    const { columns } = this.state;

    return Object.keys(columns)
      .filter((k) => !columns[k].hidden && !columns[k].sticky)
      .map((columnKey) => {
        const column = columns[columnKey];
        return {
          key: columnKey,
          text: column.text || _.startCase(columnKey),
          value: columnKey,
          icon: column.display ? 'circle' : '',
          onClick: this.updateVisibleColumns.bind(this, columnKey),
          order: column.popoverOrder
        };
      })
      .sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        }
        return 1;
      });
  }

  updateVisibleColumns = (columnKey: any) => {
    const { columns } = this.state;
    columns[columnKey].display = !columns[columnKey].display;
    this.setState({ columns }, this.setFileBrowserPrefs);
  };

  get selectedItems () {
    const { items, selected } = this.props;
    return items.filter((i: FileBrowserItem) => selected.includes(i.id));
  }

  get existingPaths () {
    const { items } = this.props;
    const dirs = items.filter(isDir);
    return _.uniq([ROOT, ...dirs.map((i: FileBrowserItem) => formPath(i.path, i.id))]);
  }

  get uniqPathOptions () {
    const selectedItem = this.selectedItems[0];
    if (!selectedItem) return [];
    // Sort items so destination options match displayed options
    const sortedItems = this.sortItems(this.filterByOpenFolders);
    const dirs = sortedItems.filter(isDir);
    const allPaths = _.uniq([ROOT, ...dirs.map((i: FileBrowserItem) => formPath(i.path, i.id))]);
    // Prevent moving any item to its own path or the path of any of its own descendants:
    const allowedPaths = allPaths.filter((p) => {
      if (p === selectedItem.path) return false; // current location
      if (p.startsWith(formPath(selectedItem.path, selectedItem.id))) return false; // children
      return true;
    });
    return allowedPaths.map((p) => ({ key: p, value: p, text: this.getPathBreadcrumbs(p) }));
  }

  setAction = (action: string, event: MouseEvent) => {
    event.stopPropagation();
    this.setState({ action });
  };

  setDestination = (e: any, {
    value
  }: any) => {
    e.stopPropagation();
    this.setState({ destination: value });
  };

  get actions () {
    const folders = this.selectedItems.filter(isDir);
    // don't allow move if more than one folder selected; don't allow move if there are no locations to move;
    const moveDisabled = folders.length > 1 || !this.uniqPathOptions.length;
    const copyDisabled = folders.length > 0;
    let deleteDisabled;
    for (let i = 0; i < this.selectedItems.length; i++) {
      const selectedItem = this.selectedItems[i] || {};
      deleteDisabled = !!selectedItem.packages; // don't allow delete of published projects
    }

    return {
      move: {
        text: `Move${moveDisabled ? ' (no move options)' : ''}`,
        method: this.handleMove,
        destination: true,
        disabled: moveDisabled,
      },
      copy: {
        text: 'Copy',
        destination: false,
        method: this.handleCopy,
        disabled: copyDisabled,
      },
      delete: {
        text: `Delete${deleteDisabled ? ' (cannot delete published projects)' : ''}`,
        method: this.handleDelete,
        disabled: deleteDisabled,
        deselect: true,
      },
    };
  }

  get actionOptions () {
    return Object.keys(this.actions).map((action) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const { disabled, text } = this.actions[action];
      return {
        key: action,
        text,
        value: action,
        disabled,
        onClick: disabled ? _.noop : this.setAction.bind(this, action)
      };
    });
  }

  handleDelete = (items: any) => {
    this.props.handleDelete(items);
  };

  handleCopy = (items: any, dest = null) => {
    this.props.handleCopy(items, dest);
  };

  handleMove = async (items: FileBrowserItem[], dest: any) => {
    await this.props.handleMove(items, dest);
    const item = items[0];
    return this.toggleFolderOpenRecursive(isDir(item) ? formPath(dest, item.id) : dest);
  };

  handleRename = (item: any, value: any) => {
    if (item.name === value) return; // no change: do nothing

    // no name: prevent change
    if (!value) {
      this.setState({ forceResetName: Date.now() });
      return;
    }

    // non-unique name for type in folder: prevent change, warn
    const { items } = this.props;
    const itemsInPath = items.filter((i: any) => i.type === item.type && i.path === item.path);
    if (itemsInPath.some((i: any) => i.name === value)) {
      this.setState({ forceResetName: Date.now() });
      alert(`There is already a ${item.type} named "${value}" in this folder. Please choose a different name.`);
      return;
    }

    this.props.handleRename(item, value);
  };

  handleOpen = (item: FileBrowserItem, e: any) => {
    e.stopPropagation();
    this.props.handleOpen(item);
  };

  createFolder = async (e: any) => {
    e.stopPropagation();
    const { createFolder, items, select, selected } = this.props;
    const item = items.find((i: FileBrowserItem) => i.id === selected[0]) || { path: ROOT } as FileBrowserItem;
    const dest = formPath(item.path, isDir(item) && item.id);
    const id = uuid();
    await createFolder({ id, path: dest });
    if (typeof select === 'function') select(id);
    await this.toggleFolderOpenRecursive(dest);
    this.setState({ lastCreatedId: id });
  };

  doAction = async () => {
    const { items, select, selected } = this.props;
    const { destination } = this.state;
    this.setState({ action: null, destination: null });
    const shouldDeselect = this.actionDef.deselect;
    await this.actionDef.method(
      items.filter((i: FileBrowserItem) => selected.includes(i.id)),
      destination
    );
    if (shouldDeselect && typeof select === 'function') select(null);
  };

  get actionDef () {
    const { action } = this.state;
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return action ? this.actions[action] : {};
  }

  render () {
    const { items, selected } = this.props;
    const { destination, search } = this.state;

    return (
      <div>
        <div>
          <Dropdown
            text='Columns'
            icon='columns'
            className='icon'
            floating
            labeled
            button
            options={this.visibleColumns}
            // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | nu... Remove this comment to see the full error message
            value={null}
          />

          <Dropdown
            text='Actions'
            icon='caret square down'
            className='icon'
            floating
            labeled
            button
            options={this.actionOptions}
            disabled={!selected.length}
            // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | nu... Remove this comment to see the full error message
            value={null}
          />

          <Button icon='folder' labelPosition='left' content='Add Folder' onClick={this.createFolder} />

          <SearchBox
            float='right'
            search={this.search}
            value={search}
            visible={this.displayedItems.length} // only count projects?
            type='Content'
            total={items.length}
            innerInputStyle={{ border: '1px solid #eaeaea' }}
            style={{ marginTop: '15px' }}
          />
        </div>

        <Table celled>
          <Table.Header>{this.headerRow}</Table.Header>
          <Table.Body>{this.rows}</Table.Body>
        </Table>

        <Modal
          open={!!this.actionDef.text}
          closeOnDimmerClick
          onClick={stopEvent}
          onClose={() => {
            this.setState({ action: null });
          }}
        >
          <Modal.Header>{this.actionDef.text}</Modal.Header>
          <Modal.Content>
            <div>
              {this.actionDef.text}:
              {selected.map((id: any) => {
                const item = items.find((i: any) => i.id === id);
                if (!item) return null;
                return (
                  <span key={id} className='hy-margin-left'>
                    <Label>
                      {/* @ts-expect-error TS(2554): Expected 1 arguments, but got 2. */}
                      {this.getPathBreadcrumbs(item.path, true)} {item.name}
                    </Label>
                  </span>
                );
              })}
            </div>
            {this.actionDef.destination && (
              <div className='hy-margin-top'>
                To:
                <Dropdown
                  placeholder='Destination'
                  className='hy-margin-left'
                  inline
                  options={this.uniqPathOptions}
                  onChange={this.setDestination}
                />
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              content={this.actionDef.text}
              onClick={this.doAction}
              disabled={this.actionDef.destination && !destination}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export { FileBrowser };
