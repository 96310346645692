// HttpClient wrapper that queues requests until activation
// Assumes standard http verbs as functions exposed on client
export class QueuedHttpClient {
  _queuing: any;
  httpClient: any;
  queue: any;
  constructor (httpClient: any) {
    this.httpClient = httpClient;
    this._queuing = true;
    this.queue = [];
  }

  async get (...args: any[]) {
    return await this.handleRequest(this.httpClient.get, ...args);
  }

  async post (...args: any[]) {
    return await this.handleRequest(this.httpClient.post, ...args);
  }

  async put (...args: any[]) {
    return await this.handleRequest(this.httpClient.put, ...args);
  }

  async delete (...args: any[]) {
    return await this.handleRequest(this.httpClient.delete, ...args);
  }

  async options (...args: any[]) {
    return await this.handleRequest(this.httpClient.options, ...args);
  }

  async head (...args: any[]) {
    return await this.handleRequest(this.httpClient.head, ...args);
  }

  async handleRequest (method: any, ...args: any[]) {
    if (typeof method !== 'function') {
      console.warn('method not callable!');
      return;
    }

    if (this._queuing) {
      return new Promise((resolve, reject) => {
        this.queue.push(async () => {
          try {
            const result = await method(...args);
            resolve(result);
          } catch (e) {
            reject(e);
          }
        });
      });
    } else {
      return await method(...args);
    }
  }

  async flushQueue () {
    let handler = this.queue.pop();

    while (handler) {
      await handler();
      handler = this.queue.pop();
    }
  }

  set queuing (queuing) {
    if (!queuing && !this._queuing) {
      return;
    }

    this._queuing = queuing;

    if (!queuing) {
      this.flushQueue();
    }
  }

  get queuing () {
    return this._queuing;
  }
}
