import React from 'react';

const SIZE = '10';
const HIDE_THRESHOLD = +SIZE * 4;

type State = any;

type CustomDraggableResizeHandleProps = {
  active: boolean;
  canHide: boolean;
  cursor: string;
  dimensions: any[];
  edges: string[];
  h: number;
  w: number;
  resizeStart: (e: any, v: any) => void;
  snap?: any;
};

class CustomDraggableResizeHandle extends React.Component<CustomDraggableResizeHandleProps, State> {
  constructor (props: CustomDraggableResizeHandleProps) {
    super(props);

    this.state = {
      hover: false,
      isDragging: false,
    };
  }

  isHorizontal = (edge: string) => {
    return ['top', 'bottom'].includes(edge);
  };

  get style () {
    const { edges, cursor, active, w, h, canHide } = this.props;
    const { hover, isDragging } = this.state;
    const show = hover || isDragging;

    if (canHide && edges.length === 1) {
      const edge = edges[0];
      if (this.isHorizontal(edge) && w < HIDE_THRESHOLD) return {};
      if (!this.isHorizontal(edge) && h < HIDE_THRESHOLD) return {};
    }

    const style = {
      position: 'absolute',
      width: SIZE + 'px',
      height: SIZE + 'px',
      zIndex: 1,
      cursor: cursor,
      border: active ? (show ? '1px solid #2185d0' : '1px solid #fff') : '1px solid transparent',
      boxShadow: active ? '0 0 3px rgba(0, 0, 0, 0.3)' : 'none',
      backgroundColor: show ? '#2185d0' : '',
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%',
    };

    edges.forEach((edge: string) => {
      const key = this.isHorizontal(edge) ? 'top' : 'left';
      style[key] = ['bottom', 'right'].includes(edge) ? '100%' : '0';
    });

    return style;
  }

  mouseover = () => {
    this.setState({ hover: true });
  };

  mouseleave = () => {
    this.setState({ hover: false });
  };

  // custom draggable
  handleMouseDown = (e: any) => {
    e.stopPropagation();
    const { edges, dimensions } = this.props;
    this.props.resizeStart({ edges, dimensions }, e);
  };

  render () {
    return (
      <div
        style={this.style}
        onMouseOver={this.mouseover}
        onMouseLeave={this.mouseleave}
        onMouseDown={this.handleMouseDown}
      />
    );
  }
}

export { CustomDraggableResizeHandle };
