import React from 'react';

import hash from 'hash-sum';
import _ from 'lodash';

import { Input } from '../../../hapyak-ui-toolkit';
import {
  getDimensionalGroups,
  getLayoutTargetSizes,
  getSizableLayoutTargets,
  targetNameFormatted
} from '../../../layouts';
import { stateController } from '../../../services/stateController';
import { toSentenceCase } from '../../../services/utils';
import WarningBanner from '../AngelouComponents/ErrorBanner';

const MAX_PANEL_PERCENT = 0.9;

type State = any; // 90%, meaning the player must always be at least 10% of either dimension

type SizingEditorProps = {
  display: any;
  hideNoun: boolean;
  layout: any;
};

export class SizingEditor extends React.Component<SizingEditorProps, State> {
  constructor (props: SizingEditorProps) {
    super(props);

    this.state = {
      sizes: this.resolveOverrides(props),
      warnings: [],
    };
  }

  resolveOverrides = (props: any) => {
    return {
      ...getLayoutTargetSizes(this.layoutTargets),
      ...this.getLayoutOverrides(props),
    };
  };

  componentDidUpdate (previousProps: SizingEditorProps) {
    const newLayoutOverrides = this.getLayoutOverrides(this.props);
    const changed = hash(this.getLayoutOverrides(previousProps)) !== hash(newLayoutOverrides);
    if (changed) this.setState({ sizes: { ...this.state.sizes, ...newLayoutOverrides } });
  }

  getLayoutOverrides = (props: any) => {
    const { display } = props;
    return _.get(display, 'layout', {});
  };

  get layoutTargets () {
    const { layout } = this.props;
    return layout.layoutTargets;
  }

  get sizableSections () {
    return getSizableLayoutTargets(this.layoutTargets);
  }

  invalidSizes = (sizes: any) => {
    const groups = getDimensionalGroups(this.layoutTargets);

    return Object.keys(groups)
      .map((dimension) => {
        const group = groups[dimension];
        const directionalTotalWithoutPlayer = group.reduce((sum: any, _name: any) => sum + sizes[_name], 0);
        return directionalTotalWithoutPlayer <= MAX_PANEL_PERCENT ? null : dimension;
      })
      .filter(Boolean);
  };

  get panelsOverThreshold () {
    const groups = getDimensionalGroups(this.layoutTargets);
    return this.state.warnings.reduce((panels: any, dimension: any) => [...panels, ...groups[dimension]], []);
  }

  onChange = (name: any, e: any) => {
    const sizes = { ...this.state.sizes, [name]: +e.target.value / 100 };
    this.setState({ sizes, warnings: this.invalidSizes(sizes) });
  };

  onBlur = () => {
    const { sizes, warnings } = this.state;

    if (!warnings.length) {
      stateController.updateProject('project', {
        display: {
          ...this.props.display,
          layout: sizes,
        },
      });
    }
  };

  createSizingInput = ({
    name
  }: any) => {
    return (
      <div key={name} className='hy-margin-bottom'>
        <Input
          label={{ basic: true, content: targetNameFormatted(name) }}
          labelPosition='left'
          type='number'
          error={this.panelsOverThreshold.includes(name)}
          fluid
          min={0}
          max={MAX_PANEL_PERCENT * 100}
          value={'' + +_.round(100 * this.state.sizes[name], 2)} // no leading 0
          onBlur={this.onBlur}
          onChange={this.onChange.bind(this, name)}
        />
      </div>
    );
  };

  get sizingInputs () {
    return this.sizableSections.map(this.createSizingInput);
  }

  get warning () {
    const { warnings } = this.state;
    if (!warnings.length) return null;
    const allowedPercent = MAX_PANEL_PERCENT * 100;
    const dimensions = warnings.map(toSentenceCase).join(' & ');
    const verb = warnings.length === 1 ? 'exceeds' : 'both exceed';

    return (
      <WarningBanner
        body={`${dimensions} ${verb} ${allowedPercent}%. Decrease panel sizes.`}
      />
    );
  }

  render () {
    return (
      <div className='hy-form-field'>
        {this.sizingInputs}
        {this.warning}
      </div>
    );
  }
}
