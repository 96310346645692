import React from 'react';

import _ from 'lodash';
import { HotKeys } from 'react-hotkeys';

import { comm } from '../../services/comm';
import { gaConfig, ReactGA } from '../../services/gaConfig';
import { stateController } from '../../services/stateController';
import { ChildrenProps } from '../../types/utils';
import { ShortcutList } from './ShortcutList';

type State = any;

class KeyboardShortcuts extends React.Component<ChildrenProps, State> {
  constructor (props: ChildrenProps) {
    super(props);
    this.state = {
      showList: false,
    };
  }

  componentDidMount () {
    comm.register('toggleShowKeyboardShortcuts', this.toggleShowShortcutList);
  }

  componentWillUnmount () {
    comm.unregister('toggleShowKeyboardShortcuts', this.toggleShowShortcutList);
  }

  toggleShowShortcutList = () => {
    this.setState({ showList: !this.state.showList });
  };

  render () {
    const keyMap = {
      // player
      space: 'space',
      skipShort: 'option+right',
      skipNormal: 'right',
      skipLong: ['shift+right', 'shift+right'],
      rewindShort: 'option+left',
      rewindNormal: 'left',
      rewindLong: ['shift+left', 'shift+left'],
      nextAnnotation: ['command+right', 'ctrl+right'],
      previousAnnotation: ['command+left', 'ctrl+left'],
      // modifiers
      shiftDown: { sequence: 'shift', action: 'keydown' },
      shiftUp: { sequence: 'shift', action: 'keyup' },
      optionDown: { sequence: 'option', action: 'keydown' },
      optionUp: { sequence: 'option', action: 'keyup' },
      ctrlDown: { sequence: 'ctrl', action: 'keydown' },
      ctrlUp: { sequence: 'ctrl', action: 'keyup' },
      commandDown: { sequence: 'command', action: 'keydown' },
      commandUp: { sequence: 'command', action: 'keyup' },
      shiftCommandDown: { sequence: 'shift+command', action: 'keydown' },
      commandShiftDown: { sequence: 'command+shift', action: 'keydown' },
      // tools
      esc: 'escape',
      delete: ['del', 'backspace'],
      copy: ['command+c', 'ctrl+c'],
      paste: ['command+v', 'ctrl+v'],
      undo: ['command+z', 'ctrl+z'],
      redo: ['command+shift+z', 'ctrl+shift+z'],
      e: ['e'],
      l: ['l'],
      r: ['r'],
      toggleShowShortcutList: '?',
    };

    const originalHandlers = {
      // player
      space: () => comm.trigger('togglePlayPause'),
      skipShort: () => comm.trigger('skipShort'),
      skipNormal: () => comm.trigger('skipNormal'),
      skipLong: () => comm.trigger('skipLong'),
      rewindShort: () => comm.trigger('rewindShort'),
      rewindNormal: () => comm.trigger('rewindNormal'),
      rewindLong: () => comm.trigger('rewindLong'),
      nextAnnotation: () => comm.trigger('nextAnnotation'),
      previousAnnotation: () => comm.trigger('previousAnnotation'),
      // modifiers
      shiftCommandDown: () => comm.trigger('shiftCommandDown', true),
      commandShiftDown: () => comm.trigger('shiftCommandDown', true),
      shiftDown: () => comm.trigger('shiftDown', true),
      shiftUp: () => comm.trigger('shiftDown', false),
      optionDown: () => comm.trigger('optionDown', true),
      optionUp: () => comm.trigger('optionDown', false),
      ctrlDown: () => comm.trigger('commandDown', true), // intentionally triggering commandDown
      ctrlUp: () => comm.trigger('commandDown', false), // intentionally triggering commandDown
      commandDown: () => comm.trigger('commandDown', true),
      commandUp: () => comm.trigger('commandDown', false),
      // tools
      esc: () => comm.trigger('escape'),
      delete: () => comm.trigger('deletePressed'),
      copy: () => comm.trigger('copy'),
      paste: () => comm.trigger('paste'),
      undo: stateController.undo,
      redo: stateController.redo,
      e: () => comm.trigger('eKey'),
      l: () => comm.trigger('lKey'),
      r: () => comm.trigger('rKey'),
      toggleShowShortcutList: this.toggleShowShortcutList,
    };

    const handlers = {};

    for (const key in originalHandlers) {
      const method = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (typeof originalHandlers[key] !== 'function') return;

        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        originalHandlers[key](e);

        ReactGA.event(
          Object.assign(gaConfig.Analytics.Portal.KeyboardShortcuts.Hotkey, {
            label: 'Hotkey ' + key,
          })
        );
      };

      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      handlers[key] = _.throttle(method);
    }

    return (
    // @ts-expect-error TS(2769): No overload matches this call.
      <HotKeys focused='true' attach={document} keyMap={keyMap} handlers={handlers}>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <ShortcutList open={this.state.showList} keyMap={keyMap} onClose={this.toggleShowShortcutList} />
        {this.props.children}
      </HotKeys>
    );
  }
}

export { KeyboardShortcuts };
