import React, { useEffect } from 'react';

import { Button } from '@newsela/angelou';

import sessionClient from '../../services/sessionClient';

import './index.scss';

export function LoginPage () {
  useEffect(() => {
    sessionClient.stop();
    sessionClient.clearSession();
  }, []);

  const nextURI = `https://${process.env.REACT_APP_HOST}/`;
  const handleClick = () => {
    window.location.href = `https://${process.env.REACT_APP_PLATFORM_HOST
    }/back-end/auth/authorize?delegated_auth=1&next=${encodeURIComponent(nextURI)}`;
  };

  return (
    <div className='login-layout'>
      <Button
        legacy_flavor={Button.legacy_flavor.solid}
        legacy_size={Button.legacy_size.medium}
        legacy_statusColor={Button.legacy_statusColor.primary}
        onClick={handleClick}
      >
        Sign in with Newsela
      </Button>
    </div>
  );
}
