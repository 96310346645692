import React from 'react';

import { DraggableProps } from '../../types/layout/dnd';
import dragController from './DragController';

type State = any;

class Draggable extends React.Component<DraggableProps, State> {
  handleMouseMove: any;
  reference: any;
  constructor (props: DraggableProps) {
    super(props);
    this.reference = React.createRef();
    this.state = this.defaultState;
  }

  get defaultState () {
    return {
      isDragging: false,
    };
  }

  get style () {
    const { active, indicate, style, dropZone, tool, selected } = this.props;
    let { top, left, width, height } = this.props; // using let for subset of props that will change
    const { isDragging } = this.state;
    const show = indicate || active;

    if (dropZone && dropZone.props.fill) {
      top = 0;
      left = 0;
      width = '100%';
      height = '100%';
    }

    let styles = {
      position: tool ? '' : 'absolute',
      top,
      left,
      width,
      height,
      backgroundColor: !tool && show ? 'rgba(204, 226, 254, 0.8)' : '',
      zIndex: 1,
      cursor: isDragging ? 'grabbing' : 'grab',
      border: selected ? '3px dotted #2185d0' : '',
      animationName: isDragging ? 'none' : '',
    };

    if (style) {
      styles = {
        ...styles,
        ...style,
      };
    }

    return styles;
  }

  handleMouseDown = (e: any) => {
    if (window.getSelection) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      window.getSelection().removeAllRanges();
    }
    e.stopPropagation();
  };

  handleDoubleClick = () => {
    const { onDoubleClick, id } = this.props;
    if (typeof onDoubleClick === 'function') onDoubleClick(id);
  };

  handleClick = (e: any) => {
    const { onClick, select, id } = this.props;
    if (typeof onClick === 'function') onClick(id);
    if (typeof select === 'function') select(id, e.metaKey || e.ctrlKey || e.shiftKey);
  };

  activate = () => {
    const { setActive, id } = this.props;
    if (typeof setActive === 'function') setActive(id);
  };

  // native draggable
  dragstart = () => {
    this.activate();
    window.addEventListener('mousemove', this.handleMouseMove);
    dragController.startDragging(this);
    this.setState({ isDragging: true });
    const { onDragStart, id } = this.props;
    if (typeof onDragStart === 'function') onDragStart(id);
  };

  dragend = () => {
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
    dragController.drop();
    this.setState({ isDragging: false });
    const { setActive, onDragEnd } = this.props;
    if (typeof setActive === 'function') setActive(null);
    if (typeof onDragEnd === 'function') onDragEnd();
  };

  render () {
    const { left, top } = this.style;
    if (left === undefined || top === undefined) return null;

    return (
      <div
        className='hy-draggable'
        draggable
        ref={this.reference}
        style={this.style as React.CSSProperties}
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
        onDoubleClick={this.handleDoubleClick}
        // draggable listeners:
        onDragStart={this.dragstart}
        onDragEnd={this.dragend}
      >
        {this.props.children}
      </div>
    );
  }
}

export { Draggable };
