import _ from 'lodash';

import { media } from './mediaController';

// Result is 121 lexileLevels (120 numeric, plus MAX):
// {
//     MAX: 'MAX',
//     '1400L': '1400L',
//     '1390L': '1390L',
//     ...
//     '210L': '210L',
//     '200L': '200L',
// };
export const LEXILE_LEVELS = _.range(200, 1410, 10)
  .reverse()
  .reduce(
    (o, n) => {
      const l = `${n}L`;
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      o[l] = l;
      return o;
    },
    { MAX: 'MAX' }
  );

export const formatComboString = (language: any, lexileLevel: any) => {
  if (!lexileLevel) lexileLevel = LEXILE_LEVEL_DEFAULT;
  return `${language} - ${lexileLevel}`;
};

export const getVideoComboString = () => formatComboString(media.video.language, media.video.lexileLevel);

export const parseComboString = (comboString: any) => {
  const [language, lexileLevel] = comboString.split(' - ');
  return { language, lexileLevel: lexileLevel || LEXILE_LEVEL_DEFAULT };
};

export const filterAssetsByComboString = (localizedAssets: any) => {
  const videoComboString = getVideoComboString();
  return _.uniq(localizedAssets.map((asset: any) => formatComboString(asset.language, asset.lexileLevel))).filter(
    (combo) => combo !== videoComboString
  );
};

export const levelToOption = (l: any) => ({
  key: l,
  value: l,
  text: l
});

export const LEXILE_LEVEL_DEFAULT = LEXILE_LEVELS.MAX;

export const LEXILE_LEVEL_OPTIONS = Object.keys(LEXILE_LEVELS).map(levelToOption);

export const isDefaultLexile = (lexileLevel: any) => lexileLevel === LEXILE_LEVEL_DEFAULT;
