import _ from 'lodash';

import DEFAULTS from '../DEFAULTS.json';
import sessionClient from './sessionClient';
import { stateController } from './stateController';

// set these up here once since they don't change and saving is quite frequent
const _hapyakVersionId = process.env.REACT_APP_VERSION_ID;

// utils:
export const modifyForExport = (data: any) => {
  const parsed = JSON.parse(data);

  if (parsed.project) {
    parsed.project._hapyakVersionId = _hapyakVersionId;
    delete parsed._routeName;
  }

  _.set(parsed, 'assets.bundles', {});

  return parsed;
};

const getParsedFromStorage = (key: any, defaults: any) => {
  const item = localStorage.getItem(key);

  try {
    return item ? JSON.parse(item) : defaults;
  } catch (e) {
    return defaults;
  }
};

// KEY: hy:currentProject
export const loadFromLocalStorage = () => {
  return getParsedFromStorage(DEFAULTS.CURRENT_PROJECT_KEY, {});
};

export const updateLocalStorage = (update: any) => {
  localStorage.setItem(
    DEFAULTS.CURRENT_PROJECT_KEY,
    JSON.stringify({
      ...loadFromLocalStorage(),
      ...update,
    })
  );
};

export const deleteSave = () => {
  stateController.resetData();
  localStorage.removeItem(DEFAULTS.CURRENT_PROJECT_KEY);
};

// SUBKEY: hy:currentProject.<type>Process
export const getProcessSettings = (process: any) => {
  const saveData = loadFromLocalStorage();
  const processing = saveData.processing || {};
  return _.get(processing, process, {});
};

export const setProcessSettings = (process: any, data: any) => {
  updateLocalStorage({ processing: { [process]: data } });
};

export const removeProcessSettings = (process: any) => {
  const saveData = loadFromLocalStorage();
  const processing = saveData.processing || {};
  delete processing[process];
  localStorage.setItem(DEFAULTS.CURRENT_PROJECT_KEY, JSON.stringify(saveData));
};

// KEY: hy:userPreferences:<USER_ID>
const getUserPreferencesKey = () => {
  const user = sessionClient.getCurrentUser();
  return `${DEFAULTS.USER_PREFS_KEY}:${user}`;
};

export const getUserPreferences = () => {
  return getParsedFromStorage(getUserPreferencesKey(), {});
};

export const setUserPreferences = (updates: any) => {
  const prefs = getUserPreferences();
  localStorage.setItem(getUserPreferencesKey(), JSON.stringify({ ...prefs, ...updates }));
};

export const getUserPreferencesByKey = (key: any) => {
  return getUserPreferences()[key] || {};
};

export const updateUserPreferencesByKey = (key: any, updates: any) => {
  const existingPrefs = getUserPreferences()[key];
  setUserPreferences({
    [key]: {
      ...existingPrefs,
      ...updates,
    },
  });
};

// we set a lot of tab preferences, let's make this a little simpler:
export const resetTabPreferences = () => {
  setUserPreferences({ [DEFAULTS.TAB_PREFS_KEY]: {} });
};

export const getTabPreferences = () => {
  return getUserPreferencesByKey(DEFAULTS.TAB_PREFS_KEY);
};

export const setTabPreferences = (updates: any) => {
  updateUserPreferencesByKey(DEFAULTS.TAB_PREFS_KEY, updates);
};

export const getDropdownPreferences = () => {
  return getUserPreferencesByKey(DEFAULTS.DROPDOWN_PREFS_KEY);
};

export const setDropdownPreferences = (updates: any) => {
  updateUserPreferencesByKey(DEFAULTS.DROPDOWN_PREFS_KEY, updates);
};

export const setPreviewPreferences = (updates: any) => {
  updateUserPreferencesByKey(DEFAULTS.PREVIEW_PREFS_KEY, updates);
};
