import React from 'react';

import { media } from '../../../../services/mediaController';
import { downloadToComputer, getExtension } from '../../../../services/utils';
import { Link } from '../../../Link';

type VideoFormatsProps = {
  inline?: boolean;
};

export class VideoFormats extends React.Component<VideoFormatsProps> {
  get formats () {
    const { inline } = this.props;
    const { formats } = media.video;
    const className = inline ? 'hy-inline-block hy-margin-right' : 'hy-margin-bottom';

    return Object.keys(formats)
      .map((format) => {
        const src = formats[format];
        if (!src || format === 'm3u8') return null;

        const extension = getExtension(src);

        return (
          <div key={src} className={className}>
            <Link to={{}} onClick={downloadToComputer.bind(this, src, src)}>
              {extension}
            </Link>
          </div>
        );
      })
      .filter(Boolean);
  }

  render () {
    return this.formats;
  }
}
