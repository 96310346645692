import './index.scss';

import React, { Component } from 'react';

import { Bundle, Embed } from 'types/publishing';

import defaults from '../../../DEFAULTS.json';
import { Grid } from '../../../hapyak-ui-toolkit';
import { getUpdateTabCallbackKey, resolvePlayerHost } from '../../../services/utils';
import { loadScipt } from '../../../sharedUtils';
import { BasicSection } from '../CommonComponents/Menu/BasicSection';
import { WrappingTabs } from '../CommonComponents/WrappingTabs';
import { EmbedDesigner } from './EmbedDesigner';
import { PublishedOutputDetails } from './PublishedOutputDetails';

const { PUBLISHING_TABS_KEY, PUBLISH_DESTINATIONS } = defaults;

type State = any;

type EmbedCreatorProps = {
  bundle: Bundle;
  embed: Embed;
  isPublishing: boolean;
  loader: any;
};

export class EmbedCreator extends Component<EmbedCreatorProps, State> {
  constructor (props: EmbedCreatorProps) {
    super(props);
    this.state = {};
  }

  componentWillUnmount () {
    /*  load index.js when we unmount this component since the window.hy global may
            have been wiped out when loading `embed.js` for the custom tag.  This is a known issue.
        */
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
    loadScipt(resolvePlayerHost() + '/index.js');
  }

  composeGrid = (component: any, styles = {}) => {
    return (
      <Grid style={styles}>
        <Grid.Row>{component && <Grid.Column>{component}</Grid.Column>}</Grid.Row>
      </Grid>
    );
  };

  get embedDesigner (): JSX.Element {
    const { bundle, embed, isPublishing, loader } = this.props;
    return this.composeGrid(isPublishing ? loader : <EmbedDesigner bundle={bundle} embed={embed} />, {
      height: '700px',
    });
  }

  get googleDrivePublishResults () {
    const details = <PublishedOutputDetails {...this.props.bundle} />;
    return <BasicSection className='hy-remove-left-column-gutter' renderedContent={details} />;
  }

  get tabs () {
    const { publishTo } = this.props.bundle;

    if (publishTo === PUBLISH_DESTINATIONS.GOOGLE_DRIVE) {
      return [
        {
          name: 'Details',
          disabled: false,
          render: () => this.googleDrivePublishResults,
        },
      ];
    }

    return [
      {
        name: 'Embed',
        disabled: false,
        render: () => (
          <BasicSection className='hy-remove-left-column-gutter' renderedContent={this.embedDesigner} />
        ),
      },
    ];
  }

  render () {
    return (
      <div className='hy-embed-creator hy-margin-top'>
        <WrappingTabs
          components={this.tabs}
          type={PUBLISHING_TABS_KEY}
          updateTabCallbackKey={getUpdateTabCallbackKey(PUBLISHING_TABS_KEY)}
        />
      </div>
    );
  }
}
