import React from 'react';

import { JobError } from '../../../errors/JobError';
import { Button, Label, Modal, Popup } from '../../../hapyak-ui-toolkit';
import { gaConfig, ReactGA } from '../../../services/gaConfig';
import { PDFUtils } from '../../../services/pdfUtils';
import signingService from '../../../services/signingService';
import { stateController } from '../../../services/stateController';
import { saveRemoteState } from '../../../services/stateUtils';
import { downloadToComputer } from '../../../services/utils';
import { AngelouLoadingSpinner } from '../AngelouComponents/AngelouLoadingSpinner';
import ErrorBanner from '../AngelouComponents/ErrorBanner';

const pdfUtils = new PDFUtils();
const PDF_POLLING_INTERVAL = 2000;
const VIS_TIMEOUT = 60000;

type State = any; // Assumed time that vis will not return a response back

type StoryboardOptionsProps = {
  errorTimeout?: any;
  items: any;
  pollingTimeout?: any;
  pdf: any;
  pdfString: string;
};

class StoryboardOptions extends React.Component<StoryboardOptionsProps, State> {
  constructor (props: StoryboardOptionsProps) {
    super(props);

    this.pollingTimeout = null;
    this.errorTimeout = null;

    this.state = {
      loading: false,
      downloadable: !!this.props.pdf,
      hasError: false,
      error: null,
      open: false,
      pdf: this.props.pdf,
    };
  }

  errorTimeout: any;
  pollingTimeout: any;

  successState () {
    return {
      loading: false,
      downloadable: true,
      hasError: false,
    };
  }

  errorState () {
    return {
      loading: false,
      downloadable: false,
      hasError: true,
    };
  }

  openPreview = () => {
    if (!this.state.pdf || this.isDirty()) this.generate();
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      loading: false,
      open: false,
    });
  };

  persistPDF = () => {
    return pdfUtils.verifyDownloadable(this.state.pdf, this.props.items).then(() => {
      clearTimeout(this.errorTimeout);
      this.setState(this.successState);
    });
  };

  poll = (promise: any, time = 1000) => {
    promise().catch(() => {
      clearTimeout(this.pollingTimeout);
      this.pollingTimeout = setTimeout(() => {
        this.poll(promise, time);
      }, time);
    });
  };

  downloadToComputer = () => {
    downloadToComputer(signingService.sign(this.state.pdf), 'Storyboard.pdf');
    ReactGA.event(gaConfig.Analytics.Portal.Storyboard.PDFDownloaded);
  };

  createErrorTimeout = () => {
    this.errorTimeout = setTimeout(() => {
      const { loading } = this.state;

      if (loading) {
        clearTimeout(this.errorTimeout);
        this.setState(this.errorState);
      }
    }, VIS_TIMEOUT);
  };

  generate = () => {
    this.setState({ loading: true });
    const pdfString = JSON.stringify(this.props.items);

    this.createErrorTimeout();

    stateController
      .updateProject('storyboard', { pdfString })
      .then(saveRemoteState)
      .then(pdfUtils.generate)
      .then((pdf) => {
        this.setState({ pdf, hasError: false }, () => this.poll(this.persistPDF, PDF_POLLING_INTERVAL));
      })
      .catch((err: JobError) => {
        this.setState({ error: err });
        this.setState(this.errorState);
        console.error('stateController error:', err);
      });
  };

  isDirty = () => {
    const saved = this.props.pdfString;
    const current = JSON.stringify(this.props.items);
    return current !== saved;
  };

  retry = () => {
    this.setState({ hasError: false }, this.generate);
  };

  render () {
    const { loading, hasError, pdf, downloadable, open, error } = this.state;
    const signedPDF = signingService.sign(this.state.pdf);
    const preview = hasError ? null : (
      <embed title='PDF Viewer' type='application/pdf' src={signedPDF} height='100%' width='100%' />
    );
    const dirty = this.isDirty();
    const style = hasError ? { height: 'auto', padding: '20px' } : {};

    return (
      <Modal
        open={open}
        onClose={this.handleClose}
        dimmer='inverted'
        size='large'
        trigger={
          <Popup
            trigger={
              <div className='hy-inline-block'>
                <Button primary={dirty} icon='file code' content='PDF' onClick={this.openPreview} />
              </div>
            }
            disabled={dirty}
            content='PDF is up to date'
            position='top center'
            floated='right'
            inverted
          />
        }
      >
        <Modal.Header>
          Storyboard PDF Preview
          {dirty && (
            <Label className='hy-pdf-dirty-message' basic color='red'>
              PDF out of date
            </Label>
          )}
        </Modal.Header>
        <Modal.Content id='hy-storyboard-pdf-preview-modal' style={style}>
          {loading && <AngelouLoadingSpinner text='Generating PDF' />}
          {hasError && (
            <ErrorBanner
              body='There was an issue creating that. Please try again'
              error={error}
            />
          )}
          {!loading && pdf && preview}
        </Modal.Content>
        <Modal.Actions>
          <Button content='Cancel' onClick={this.handleClose} />
          {!hasError && (
            <Button
              primary
              disabled={!downloadable}
              icon='download'
              content='Download'
              onClick={this.downloadToComputer}
            />
          )}
          {hasError && (
            <Button primary onClick={this.retry}>
              Retry
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

export { StoryboardOptions };
