import React from 'react';

import { Button, EditHollowSVG, Icon } from '@newsela/angelou';

type EditButtonProps = {
  handleClick: () => void;
}

export const EditButton = ({
  handleClick
}: EditButtonProps) => {
  return (
    <Button
      ariaProps={{ 'aria-label': 'edit item' }}
      legacy_flavor={Button.legacy_flavor.solid}
      legacy_size={Button.legacy_size.small}
      legacy_statusColor={Button.legacy_statusColor.tertiary}
      onClick={() => handleClick()}
      __classNameFor={{ root: 'tertiary hollow' }}
      __cssFor={{
        root: {
          width: '2rem',
          height: '2rem',
          borderRadius: '4px',
          padding: '2px'
        }
      }}
    >
      <Icon SvgComponent={EditHollowSVG} isDecorative={false} title='edit item' />
    </Button>
  );
};
