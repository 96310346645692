import _ from 'lodash';

// all attrs:
const attrs = [
  'access',
  'className',
  'description',
  'inline',
  'label',
  'max',
  'maxlength',
  'min',
  'multiple',
  'name',
  'options',
  'other',
  'placeholder',
  'required',
  'rows',
  'step',
  'style',
  'subtype',
  'toggle',
  'value',
  'access',
];

// all fields:
const fields = [
  'header',
  'radio-group',
  'checkbox-group',
  'text',
  'file',
  'textarea',
  'select',
  'number',
  'date',
  'hidden',
  'paragraph',
  'button',
];

const controlOrder = [
  'header',
  'radio-group',
  'checkbox-group',
  'text',
  'textarea',
  'select',
  'number',
  'date',
  'hidden',
  'paragraph',
  'button',
];

const defaultFormOptions = {
  fieldRemoveWarn: true,
  scrollToFieldOnAdd: false,
  editOnAdd: true,
  showActionButtons: false,
};

export const attachForm = (toolDefinition: any, form: any) => {
  const { _include, _exclude = {}, ...formDefinition } = form;
  toolDefinition.editor.form = {
    ...defaultFormOptions,
    ...formDefinition,
    controlOrder,
    disabledAttrs: _.difference(attrs, _include.attrs).concat(_exclude.attrs),
    disableFields: _.difference(fields, _include.fields).concat(_exclude.fields),
  };

  return toolDefinition;
};
