import React, { useEffect, useState } from 'react';

import { Button, Icon, UploadSVG } from '@newsela/angelou';

import ErrorBanner from '../../../../components/editors/AngelouComponents/ErrorBanner';
import WarningBanner from '../../../../components/editors/AngelouComponents/WarningBanner';
import DEFAULTS from '../../../../DEFAULTS.json';
import { JobError } from '../../../../errors/JobError';
import { ConfirmModal, Modal } from '../../../../hapyak-ui-toolkit';
import { comm } from '../../../../services/comm';
import { LEXILE_LEVEL_DEFAULT } from '../../../../services/lexileService';
import logger from '../../../../services/logger';
import { uploadVideo } from '../../../../services/uploadUtils';
import { UploadContentType } from '../../../../types/uploadContentType';
import { BasicSection } from '../../CommonComponents/Menu/BasicSection/index';
import { RunningStatus } from '../../CommonComponents/RunningStatus';
import { VideoUploadComponent } from '../../CommonComponents/VideoUploadComponent';
import './index.scss';
import { PercentProgressBar } from './PercentProgressBar';

type VideoUploadModalProps = {
  activeProcess: string;
  afterUpload: (...args: any) => void;
  beforeUpload: (...args: any) => void;
  projectId?: string;
  video?: any;
}

export function VideoUploadModal ({
  activeProcess,
  afterUpload,
  beforeUpload,
  projectId,
}: VideoUploadModalProps) {
  const [uploadFromMyComputer, setUploadFromMyComputer] = useState(false);
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [processFailure, setProcessFailure] = useState<boolean>(false);
  const [fileAvailable, setFileAvailable] = useState(false);
  const [file, setFile] = useState({});
  const [videoLanguage, setVideoLanguage] = useState({ value: 'en', label: 'English [en]' });
  const [lexileLevel, setLexileLevel] = useState(LEXILE_LEVEL_DEFAULT);
  const [videoUploadError, setVideoUploadError] = useState<JobError | null>(null);
  const [uploadPercent, setUploadPercent] = useState(0);

  useEffect(() => {
    setUploadFromMyComputer(false);
    setProcessFailure(false);
  }, []);

  const close = () => {
    setVideoUploadError(null);
    setOpen(false);
    setUploading(false);
    setFileAvailable(false);
    setFile({});
    setVideoLanguage({ value: 'en', label: 'English [en]' });
    setLexileLevel(LEXILE_LEVEL_DEFAULT);
  };

  const onAxiosUploadError = (processName: any, error: JobError, _uploadContentType: UploadContentType) => {
    setVideoUploadError(error);
    setUploading(false);
  };

  const onProcessFailed = (processName: any, responseData: any) => {
    setProcessFailure(true);
    if (processName === DEFAULTS.TRANSCODE_KEY || processName === DEFAULTS.TRANSCODE_REMOTE_UPLOAD_KEY) {
      logger.error(`ERR.pollForProcessResult ${processName}`, responseData);
    }
  };

  const onPercentUploadProgress = (percentCompleted: number) => {
    setUploadPercent(percentCompleted);
  };

  useEffect(() => {
    comm.register('axiosUploadError', onAxiosUploadError);
    comm.register('processFailed', onProcessFailed);
    comm.register('percentUploadProgress', onPercentUploadProgress);
  }, []);

  useEffect(() => {
    return () => {
      comm.unregister('processFailed', onProcessFailed);
      comm.unregister('axiosUploadError', onAxiosUploadError);
      comm.unregister('percentUploadProgress', onPercentUploadProgress);
    };
  }, []);

  const beforeVideoUpload = (...args: any[]) => {
    if (typeof beforeUpload === 'function') beforeUpload(...args);
  };

  const afterVideoUpload = (...args: any[]) => {
    setUploading(false);
    setUploadFromMyComputer(false);
    if (typeof afterUpload === 'function') afterUpload(...args);
    close();
  };

  const handleFileChange = ({ file = {} }) => {
    const hasFile = (file as any).name && (file as any).size;
    setFileAvailable(hasFile);
    setFile(file);
  };

  const startUpload = () => {
    setUploadPercent(0);
    setUploading(true);
    return uploadVideo(projectId, file, videoLanguage.value, lexileLevel)
      .then((res) => {
        afterVideoUpload(res);
      })
      .catch((error) => {
        setVideoUploadError(error.message);
        console.error('startUpload error', error);
      });
  };

  const onLanguageChange = (e: any) => {
    setVideoLanguage(e.selectedItem);
  };

  const toggleUploadFromMyComputer = () => {
    setUploadFromMyComputer(true);
  };

  const uploadingMessage = (
    <RunningStatus
      icon={/* AUTOGENERATED TODO: ANGELOU BREAKING CHANGE v0.26_v0.27! CHECK RENDER PATTERN HERE */
        {
          name: 'upload'
        }
      }
      header="Don't go!"
      content='Stay put while we upload your video file.'
      color='blue'
    />
  );

  const uploadErrorMessage = (
    <ErrorBanner
      title='Video upload error'
      body='Something is wrong with your video.'
      error={videoUploadError}
    />
  );

  const replaceVideoButton = (
    <Button
      legacy_flavor={Button.legacy_flavor.solid}
      onClick={() => setOpen(true)}
      __classNameFor={{ root: 'internal-tools-button' }}
      __cssFor={{ root: { margin: '.5rem' } }}
    >
      Replace video
    </Button>
  );

  const runningStatusMessage = (
    <WarningBanner
      title={`${activeProcess} in progress`}
      body={`${activeProcess} is running. Uploading a new video will stop process.`}
    />
  );

  const runningProcessMessageDisplay = ((!activeProcess || videoUploadError) ? null : runningStatusMessage);
  const uploadingMessageDisplay = (videoUploadError || processFailure) ? null : uploadingMessage;

  return (
    <Modal
      open={open}
      trigger={replaceVideoButton}
      className='hy-thumbnail-modal'
      size='small'
      onClose={close}
      closeOnEscape={!uploading}
      closeOnDimmerClick={!uploading}
    >
      <Modal.Header>Replace Video</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {(!uploading && !processFailure) && runningProcessMessageDisplay}
          {uploading && uploadingMessageDisplay}
          {videoUploadError && uploadErrorMessage}
          <BasicSection
            sectionPadding={false}
            renderedContent={[
              {
                noBorder: true,
                renderedContent: (
                  <VideoUploadComponent
                    afterUpload={afterVideoUpload}
                    beforeUpload={beforeVideoUpload}
                    handleFileChange={handleFileChange}
                    onLanguageChange={onLanguageChange}
                    toggleUploadFromMyComputer={toggleUploadFromMyComputer}
                    uploadFromMyComputer={uploadFromMyComputer}
                    videoLanguage={videoLanguage}
                    error={videoUploadError?.message}
                    uploading={uploading}
                  />
                ),
              },
            ]}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions id='upload-modal-actions-container'>
        {uploading && <PercentProgressBar uploadPercent={uploadPercent} />}
        <Button
          disabled={uploading}
          onClick={close}
          legacy_flavor={Button.legacy_flavor.solid}
          legacy_statusColor={Button.legacy_statusColor.tertiary}
          legacy_size={Button.legacy_size.medium}
          __classNameFor={{ root: 'tertiary' }}
          __cssFor={{ root: { margin: '.5rem' } }}
        >
          Cancel
        </Button>
        <ConfirmModal
          primary
          className='hy-margin-left'
          buttonProps={{
            disabled:
              (uploading || videoUploadError) &&
              (!uploadFromMyComputer || !fileAvailable || uploading || videoUploadError),
            content: 'Upload and continue',
            icon: { iconNode: uploading ? <Icon SvgComponent={UploadSVG} /> : null, isIconBeforeText: true },
            legacy_flavor: Button.legacy_flavor.solid,
            __classNameFor: { root: 'internal-tools-button' },
            __cssFor: { root: { margin: '.5rem' } }
          }}
          confirmCallback={() => startUpload()}
          header='Replace Existing Video'
          content='Are you sure you want to replace the existing video?'
          cancelButton='Keep existing'
          confirmButton='Replace video'
        />
      </Modal.Actions>
    </Modal>
  );
}
