
export const defaultAnnotations = [
  {
    type: 'richText',
    overrides: {
      start: 0,
      end: '100%',
      content:
                '<h2 style="margin: 0px 0px 10px; padding: 0px; font-weight: 400; line-height: 24px; font-family: DauphinPlain; font-size: 24px; background-color: #ffffff;"><span style="font-family: verdana, geneva, sans-serif;">Example Content Here</span></h2>\n<p style="margin: 0px 0px 15px; padding: 0px; text-align: justify; font-family: \'Open Sans\', Arial, sans-serif; font-size: 14px;"><span style="font-family: tahoma, arial, helvetica, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip. </span></p>',
      target: 'adjacent_bottom',
      internal: {
        name: 'Rich Text',
      },
    },
  },
];
