import React from 'react';

import VisibilitySensor from 'react-visibility-sensor';
import { v4 as uuid } from 'uuid';

import './index.scss';
import { ConfirmModal, Draggable, DropZone } from '../../../hapyak-ui-toolkit';
import { comm } from '../../../services/comm';
import { gaConfig, ReactGA } from '../../../services/gaConfig';
import { takePicture } from '../../../services/hapshot';
import { assetService, stateController } from '../../../services/stateController';
import hms from '../../../services/time';
import WarningBanner from '../AngelouComponents/WarningBanner';
import { AutoGenerateModal } from './AutoGenerateModal';
import { getPdfSettings } from './pageSettings';
import { SnapshotButton } from './SnapshotButton';
import { StoryboardItem } from './StoryboardItem';
import { StoryboardOptions } from './StoryboardOptions';

const DEFAULT_DESCRIPTION = '<p>Edit this description.</p>';
const PREFIX = 'zone';
const SCALE = 0.25;
const PADDING = 20;

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const remove = (list: any, startIndex: any) => {
  const result = Array.from(list);
  result.splice(startIndex, 1);
  return result;
};

type StoryboardEditorState = any;

type StoryboardEditorProps = {
  previewExpanded: any;
  items: any;
  pdf: any;
  pdfString: string;
};

class StoryboardEditor extends React.Component<StoryboardEditorProps, StoryboardEditorState> {
  container: any;
  resizeId: any;
  ruler: any;
  scrollable: any;
  constructor (props: StoryboardEditorProps) {
    super(props);
    this.container = React.createRef();
    this.ruler = React.createRef();
    this.scrollable = React.createRef();
    this.resizeId = null;

    this.state = {
      confirmDeleteModalOpen: false,
      draggableIndex: null,
      dropZoneIndex: null,
      pagesPerRow: 1,
      scrollTop: 0,
    };
  }

  componentDidMount () {
    this.onResize();

    window.addEventListener('resize', this.onResize);
    comm.register('pageLayoutTransitionEnd', this.onResize);
    comm.register('addStoryboardPage', this.addItem);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize);
    comm.unregister('pageLayoutTransitionEnd', this.onResize);
    comm.unregister('addStoryboardPage', this.addItem);
  }

  onResize = () => {
    clearTimeout(this.resizeId);
    this.resizeId = setTimeout(this.onResizeFinished, 250);
  };

  onResizeFinished = () => {
    const { previewExpanded } = this.props;
    if (previewExpanded) return;
    if (!this.container.current) return;
    const containerWidth = this.container.current.getBoundingClientRect().width;
    const inchesToPixels = this.ruler.current.getBoundingClientRect().width; const pageWidth = +(getPdfSettings(SCALE) as any).width + PADDING / inchesToPixels;
    if (containerWidth === 0 || inchesToPixels === 0 || pageWidth === 0) return;
    const pagesPerRow = Math.floor((containerWidth + PADDING) / inchesToPixels / pageWidth) || 1; // min 1
    this.setState({ pagesPerRow });
  };

  updateItems = (items: any, merge?: any) => {
    return stateController.updateProject('storyboard', { items }, merge);
  };

  updatePage = (id: any, obj: any) => {
    const items = [...this.props.items];
    const index = items.findIndex((item) => item.id === id);
    items[index] = obj;
    this.updateItems(items);
  };

  addAutoGeneratedPage = ({
    description,
    start
  }: any) => {
    this.addItem({ time: start, description }, true).then((item) => {
      takePicture(false, start).then(({ src }) => {
        const image = assetService.createImage(src);
        this.updateItemImage(item.id, image.id);
      });
    });
  };

  clearAll = () => {
    this.updateItems([]);
    ReactGA.event(gaConfig.Analytics.Portal.Storyboard.Clear);
  };

  updateItemImage = (id: any, image: any) => {
    let { items } = this.props;
    items = items.map((item: any) => item.id === id ? { ...item, image } : item);
    return this.updateItems(items, true);
  };

  addItem = (item: any, merge: any) => {
    const items = this.props.items;
    const { time, description = DEFAULT_DESCRIPTION } = item;

    item = {
      id: 'storyboard_item_' + uuid(),
      device: 'desktop',
      ...item,
      description: `<b>Time:</b> ${hms.secondsToHMS(time)}<p> </p><p>${description}</p>`,
    };

    items.push(item);
    this.updateItems(items, merge);
    return Promise.resolve(item);
  };

  removeItem = (index: any) => {
    this.updateItems(remove([...this.props.items], index));
  };

  onDragEnd = () => {
    const { draggableIndex, dropZoneIndex } = this.state;
    this.setState({ draggableIndex: null, dropZoneIndex: null });
    const { items } = this.props;
    const params = [items, draggableIndex, dropZoneIndex];
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    this.updateItems(reorder(...params));
  };

  onDragStart = (draggableId: any) => {
    const { items } = this.props;
    const draggableIndex = items.findIndex((item: any) => item.id === draggableId);
    if (draggableIndex === undefined) return; // allow 0

    this.setState({ draggableIndex, dropZoneIndex: draggableIndex });
  };

  onDragOver = (dropZoneId: any) => {
    const dropZoneIndex = +dropZoneId.replace(PREFIX, '');

    if (dropZoneIndex === this.state.dropZoneIndex) return;

    this.setState({ dropZoneIndex });
  };

  get dropZones () {
    return this.props.items.map((item: any, index: any) => {
      const key = PREFIX + index;
      return <DropZone key={key} id={key} onDragOver={this.onDragOver} style={this.getZonePosition(index)} />;
    });
  }

  get draggableItems () {
    return this.props.items.map((item: any, index: any) => {
      const { id } = item;
      return (
        <Draggable key={id + index} id={id} type={id} allowedZones='*' onDragStart={this.onDragStart} onDragEnd={this.onDragEnd} style={this.getDraggablePosition(index)}>
          <VisibilitySensor scrollCheck delayedCall partialVisibility offset={{ top: -400, bottom: -400 }}>
            {({ isVisible }) => isVisible ? (<StoryboardItem data={item} updatePage={this.updatePage} delete={this.removeItem.bind(this, index)} index={index} scale={SCALE} isVisible={isVisible} />) : (<div style={{ visibility: 'hidden' }}>.</div>)}
          </VisibilitySensor>
        </Draggable>
      );
    });
  }

  getPagePosition = (index: any) => {
    if (!this.ruler.current) return {};
    const { pagesPerRow } = this.state;
    const row = Math.floor(index / pagesPerRow);
    const column = index % pagesPerRow;

    const inchesToPixels = this.ruler.current.getBoundingClientRect().width; // 1 inch to pixels as measured on device
    const { width, height } = getPdfSettings(SCALE);
    const pageWidth = width * inchesToPixels + PADDING;
    const pageHeight = height * inchesToPixels + PADDING;
    const t = '0.4s';

    return {
      position: 'absolute',
      width: width * inchesToPixels,
      height: height * inchesToPixels,
      left: `${column * pageWidth}px`,
      top: `${row * pageHeight}px`,

      transition: `transform ${t}, top ${t}, left ${t}`,
      transform: `translate(${0}px, ${0}px)`,
    };
  };

  getZonePosition = (index: any) => {
    return this.getPagePosition(index);
  };

  getDraggablePosition = (index: any) => {
    if (!this.ruler.current) return {};
    const { draggableIndex, dropZoneIndex } = this.state;
    const isDragging = draggableIndex !== null;
    const draggingOther = draggableIndex !== index;
    if (isDragging && index > draggableIndex) index -= 1;
    if (isDragging && index >= dropZoneIndex) index += 1;
    const style = { ...this.getPagePosition(index) };
    if (isDragging && draggingOther) (style as any).zIndex = -1;
    if (isDragging && !draggingOther) style.transform = 'scale(0)';
    return style;
  };

  getScrollZoneStyles = (location: string) => {
    const { draggableIndex } = this.state;
    const isDragging = draggableIndex !== null;

    const style: React.CSSProperties = {
      position: 'fixed',
      width: '100%',
      height: '10%',
      left: 0,
      [location]: 0,
    };

    if (!isDragging) style.transform = 'scale(0)';
    return style;
  };

  scroll = (direction: any) => {
    this.scrollable.current.scrollTop += 10 * direction;
  };

  get buttons () {
    const { items, pdf, pdfString } = this.props;

    return (
      <div className='hy-dnd-optional-button'>
        <ConfirmModal
          buttonProps={{ icon: 'bomb', content: 'Clear All' }}
          confirmCallback={this.clearAll}
          header='Delete all pages'
        />
        <SnapshotButton />
        <AutoGenerateModal addPage={this.addAutoGeneratedPage} />
        <StoryboardOptions items={items} pdf={pdf} pdfString={pdfString} />
      </div>
    );
  }

  get items () {
    return (
      <>
        <div ref={this.scrollable} className='hy-dnd-list hy-absolute' style={{ zIndex: 0 }}>
          <div className='hy-dropzones' style={{ position: 'relative', zIndex: 0 }}>
            {this.dropZones}
            {this.draggableItems}
          </div>
        </div>
        <div style={this.getScrollZoneStyles('bottom')} onDragOver={this.scroll.bind(this, 1)} />
      </>
    );
  }

  get message () {
    return (
      <WarningBanner
        title='No pages'
        body='Click "New Page" or "Auto Generate" to add storyboard pages.'
      />
    );
  }

  render () {
    const { items } = this.props;

    return (
      <div className='hy-storyboard-editor'>
        <div ref={this.ruler} style={{ width: '1in' }} />
        <div className='hy-dnd hy-absolute' ref={this.container}>
          <div style={this.getScrollZoneStyles('top')} onDragOver={this.scroll.bind(this, -1)} />
          {this.buttons}
          {items.length ? this.items : this.message}
        </div>
      </div>
    );
  }
}

export { StoryboardEditor };
