import React from 'react';

import { Button } from '@newsela/angelou';
import { ConfirmProps } from 'semantic-ui-react';

import { JobError } from '../../../../errors/JobError';
import { Modal } from '../../../../hapyak-ui-toolkit';
import { comm } from '../../../../services/comm';
import { assetService } from '../../../../services/stateController';
import { ImageMetadata, ImagePickerProps } from '../../../../types/assets';
import { ImageChooser } from './ImageChooser';
import { ImageDisplay } from './ImageDisplay';
import './index.scss';

type State = any;

type AddImageModalProps = ConfirmProps & ImagePickerProps

class AddImageModal extends React.Component<AddImageModalProps, State> {
  constructor (props: AddImageModalProps) {
    super(props);
    this.state = {
      open: false,
      image: null,
      loaded: false,
      hasError: false,
      error: null,
      secondarySource: false,
    };
  }

  componentDidMount () {
    comm.register('axiosUploadError', this.axiosUploadError);
    const { images } = this.props;
    if (images && images.length) {
      this.setState({ secondarySource: true });
    }
  }

  componentWillUnmount (): void {
    comm.unregister('axiosUploadError', this.axiosUploadError);
  }

  axiosUploadError = (contentType: string, error: JobError) => {
    this.setState({
      loaded: false,
      hasError: true,
      error: error
    });
  };

  chooseExisting = (id: string) => {
    const { secondarySource } = this.state;
    this.displayImage(secondarySource ? { src: id } : assetService.getImage(id));
  };

  setFromSource = (src: string, isControlled?: boolean) => {
    const { type } = this.props;
    this.displayImage(assetService.stageImage(src, isControlled, type));
  };

  displayImage = (image: ImageMetadata) => {
    this.setState({ image, hasError: false, loaded: false });
  };

  setImage = () => {
    let { image } = this.state;

    if (!image.id) {
      image = assetService.createImage(image.src, image.isControlled, image.type);
    }

    if (this.props.setImage) {
      this.props.setImage(image);
    }
  };

  onLoad = () => {
    this.setState({ hasError: false, loaded: true });
  };

  onError = () => {
    this.setState({
      loaded: false,
      hasError: true,
    });
  };

  render () {
    const { close, images, browse, fromUrl, type, isCustomSrc } = this.props;
    const { image, loaded, hasError, error } = this.state;
    const { src } = image || {};

    return (
      <Modal open className='hy-thumbnail-modal' size='small' onClose={close}>
        <Modal.Header>Add Image</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <ImageChooser
              images={images}
              chooseExisting={this.chooseExisting}
              setFromSource={this.setFromSource}
              browse={browse}
              fromUrl={fromUrl}
              type={type}
            />
            <ImageDisplay
              isCustomSrc={isCustomSrc}
              src={src}
              onLoad={this.onLoad}
              onError={this.onError}
              loaded={loaded}
              hasError={hasError}
              error={error}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            legacy_flavor={Button.legacy_flavor.hollow}
            onClick={close}
            legacy_size={Button.legacy_size.medium}
            legacy_statusColor={Button.legacy_statusColor.secondary}
            __cssFor={{ root: { marginLeft: '1rem' } }}
          >
            Cancel
          </Button>
          <Button
            disabled={!loaded}
            legacy_flavor={Button.legacy_flavor.solid}
            onClick={this.setImage}
            legacy_size={Button.legacy_size.medium}
            legacy_statusColor={Button.legacy_statusColor.primary}
            __cssFor={{ root: { marginLeft: '1rem' } }}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export { AddImageModal };
