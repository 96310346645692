import React, { Component } from 'react';

import _ from 'lodash';

import { Button, Grid, Input, Popup, Icon } from '../../../../../hapyak-ui-toolkit';
import { stateController } from '../../../../../services/stateController';
import { getCollections, removeAppliesToAssociationFromTargetA } from '../../../../../services/toolRelationshipService';
import { findAnnotationById, TOOLS } from '../../../../../services/toolService';
import { Annotation } from '../../../../../types/annotations';
import { getQuizAnswerRowDisplay } from './quizBuilderUtils';

type State = any;

type AnswerAsAnnotationRowProps = {
  annotation: Annotation;
  idx?: number;
  onDelete: (id: string, annotations: Annotation[]) => void;
  onEdit: (id: any) => void;
  persistChange: any;
};

export class AnswerAsAnnotationRow extends Component<AnswerAsAnnotationRowProps, State> {
  constructor (props: AnswerAsAnnotationRowProps) {
    super(props);
    this.state = { text: _.get(props, 'annotation.properties.value', '') };
  }

  componentDidUpdate (prevProps: AnswerAsAnnotationRowProps) {
    const path = 'annotation.properties.value';
    const textValue = _.get(this.props, path);
    const prevTextValue = _.get(prevProps, path);

    if (textValue !== prevTextValue) {
      this.setState({ text: textValue });
    }
  }

  getDefaultValue = (annotation = this.props.annotation) => {
    return _.get(annotation, 'internal.name', annotation.id);
  };

  onInputBlur = (e: any) => {
    const { persistChange, annotation = {} as Annotation } = this.props;
    const inputVal = e.target.value || '';
    const isBlank = !inputVal.replace(/ /g, '');
    const value = isBlank ? this.getDefaultValue() : inputVal;
    if (typeof persistChange === 'function') persistChange([{ id: annotation.id, properties: { value } }]);
    this.setState({ text: value });
  };

  onInputChange = (e: any, target: any) => {
    this.setState({ text: target.value });
  };

  get hasError () {
    const { annotation } = this.props;
    const { content: aAsTargetId } = annotation;
    if (!aAsTargetId) return false;
    return !findAnnotationById(aAsTargetId).id;
  }

  get label () {
    const { annotation } = this.props;
    const { content: aAsTargetId } = annotation;
    const targetAnnotation = aAsTargetId ? findAnnotationById(aAsTargetId) : null;
    if (!targetAnnotation || !targetAnnotation.id) return 'Annotation Not Found';

    const display = getQuizAnswerRowDisplay(targetAnnotation);
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const icon = TOOLS[targetAnnotation.toolType].icon;
    return (
      <Grid className='hy-compact-grid-column'>
        <Grid.Row>
          <Grid.Column width={4}>
            <Icon name={icon} />
          </Grid.Column>
          <Grid.Column width={12}>{display}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  get input () {
    const { text = '' } = this.state;

    return (
      <Input
        value={text}
        fluid
        className='hy-margin-bottom'
        onBlur={this.onInputBlur.bind(this)}
        onChange={this.onInputChange.bind(this)}
      />
    );
  }

  onDelete = (id: string) => {
    const { onDelete } = this.props;
    const projectAnnotations = stateController.getCurrentData('ux').annotations;
    // @ts-expect-error TS(2339): Property 'annotations' does not exist on type '{ a... Remove this comment to see the full error message
    const { annotations } = removeAppliesToAssociationFromTargetA(id, projectAnnotations);
    if (id && typeof onDelete === 'function') onDelete(id, annotations);
  };

  onEdit = (id: string) => {
    const { onEdit } = this.props;
    if (id && typeof onEdit === 'function') onEdit(id);
  };

  get containsQuickAddAnnotation () {
    const collections = getCollections(this.props.annotation);
    return !!collections.length;
  }

  get autoCreatedIcon () {
    const popup = <Popup content='Quick Add annotation' trigger={<Icon name='magic' />} />;
    return this.containsQuickAddAnnotation ? popup : null;
  }

  get deleteButton () {
    const { annotation = {} as Annotation } = this.props;
    const quickAddedMsg = 'Annotation and answer will be deleted';
    const defaultMsg = 'Answer will be deleted';
    const btn = <Button icon='trash' color='red' onClick={this.onDelete.bind(this, annotation.id)} />;
    const wrap = (btn: any, msg: any) => <Popup content={msg} trigger={btn} />;
    return this.containsQuickAddAnnotation ? wrap(btn, quickAddedMsg) : wrap(btn, defaultMsg);
  }

  render () {
    const { annotation = {} as Annotation, idx } = this.props;

    return (
      <Grid.Row key={idx}>
        {this.hasError && (
          <Grid.Column verticalAlign='middle' width={1}>
            <Popup
              content='Annotation Not Found.'
              trigger={<Icon color='red' name='warning circle' size='large' />}
            />
          </Grid.Column>
        )}
        <Grid.Column verticalAlign='middle' width={this.hasError ? 5 : 6}>
          {this.label}
        </Grid.Column>
        <Grid.Column width={5}>{this.input}</Grid.Column>
        <Grid.Column width={1} verticalAlign='middle'>
          {this.autoCreatedIcon}
        </Grid.Column>
        <Grid.Column width={2}>{this.deleteButton}</Grid.Column>
        <Grid.Column width={2}>
          {!this.hasError && annotation.content && (
            <Button icon='pencil' onClick={this.onEdit.bind(this, annotation.content)} />
          )}
        </Grid.Column>
      </Grid.Row>
    );
  }
}
