import React, { Component } from 'react';

import { Calendar } from '../CommonComponents/DatePicker';

type DateInputProps = {
  className?: string;
  disabled: boolean;
  handleChange: (date: any) => void;
  label: string;
  startDate: any;
};

export class DateInput extends Component<DateInputProps> {
  get date () {
    const { startDate } = this.props;
    return startDate ? new Date(startDate) : '';
  }

  handleDateChange = (date: any) => {
    const { handleChange } = this.props;
    const formattedDate = date ? new Date(new Date(date.toUTCString()).setUTCHours(0, 0, 0, 0)).toUTCString() : ''; // set date to start of day UTC
    if (handleChange) handleChange({ date: formattedDate });
  };

  render () {
    const { label, disabled, className = '' } = this.props;
    return (
      <Calendar
        className={className}
        disabled={disabled}
        label={label}
        startDate={this.date}
        handleChange={this.handleDateChange}
      />
    );
  }
}
