import React from 'react';

import { stateController } from '../../../services/stateController';
import { BasicSection } from '../CommonComponents/Menu/BasicSection/index';
import { StylesheetUpload } from '../CommonComponents/StylesheetUpload';
import { StyleInput } from '../UXEditor/StyleEditor/StyleInput';

class BrandingEditor extends React.Component {
  onChange = (property: any, value: any) => {
    stateController.updateProject('branding', { [property]: value });
  };

  getStyleInput = (property: string) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const value = this.props[property];

    return (
      <StyleInput
        key={property}
        property={property}
        value={value}
        onChange={this.onChange.bind(this, property)}
      />
    );
  };

  render () {
    return (
      <>
        <BasicSection
          renderedContent={[
            {
              title: 'Colors',
              renderedContent: (
                <div>
                  <div className='hy-margin-right hy-margin-bottom hy-inline-block'>
                    {this.getStyleInput('primary')}
                  </div>
                  <div className='hy-margin-right hy-margin-bottom hy-inline-block'>
                    {this.getStyleInput('secondary')}
                  </div>
                  <div className='hy-margin-right hy-inline-block'>
                    {this.getStyleInput('accent')}
                  </div>
                </div>
              ),
            },
            {
              title: 'Font',
              renderedContent: (
                <div>
                  <div className='hy-margin-right hy-margin-bottom hy-inline-block'>
                    {this.getStyleInput('fontFamily')}
                  </div>
                  <div className='hy-margin-right hy-inline-block'>
                    {this.getStyleInput('fontSize')}
                  </div>
                </div>
              ),
            },
            {
              title: 'Stylesheet',
              renderedContent: <StylesheetUpload />,
            },
          ]}
        />
      </>
    );
  }
}

export { BrandingEditor };
