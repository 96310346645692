import React from 'react';

import _ from 'lodash';
import { SemanticWIDTHS } from 'semantic-ui-react';

import { findAnnotationById } from '../../../../../services/toolService';
import { QuizBuilder } from '../QuizBuilder';
import { findCollection } from '../QuizBuilder/quizBuilderUtils';
import { getQuizBuilderConfigByAnnotationToolType } from '../utils';

const QUESTION_TOOL_TYPE = 'question';
const BUILDER_CONFIG = getQuizBuilderConfigByAnnotationToolType(QUESTION_TOOL_TYPE);

export class QuestionBuilder extends QuizBuilder {
  get sectionEditorWidth (): SemanticWIDTHS {
    return 16;
  }

  get builderMenuIsDisabled () {
    return _.get(BUILDER_CONFIG, 'builderMenu.disabled');
  }

  get deleteQuestionDisabled () {
    return _.get(BUILDER_CONFIG, 'footer.delete.disabled');
  }

  get deleteQuestionButtonIsRendered () {
    return _.get(BUILDER_CONFIG, 'footer.delete.displayed');
  }

  get hasQuestionEditorMargin () {
    return _.get(BUILDER_CONFIG, 'editors.question.margin');
  }

  get progressIsDisabled () {
    return _.get(BUILDER_CONFIG, 'progress.disabled');
  }

  createSectionEditors = (id: any) => {
    const { annotations } = this.props;
    const annotation = findAnnotationById(id, annotations);
    const questions = findCollection(annotation);

    const questionEditors = questions.map(this.createQuestionEditors);

    return (
      <div key={id} className='hy-quiz-group'>
        <div>{questions.length ? questionEditors : <div className='hy-margin-bottom' />}</div>
      </div>
    );
  };
}
