import React from 'react';

import _ from 'lodash';
import { SemanticICONS } from 'semantic-ui-react';

import DEFAULTS from '../../DEFAULTS.json';
import { Icon } from '../../hapyak-ui-toolkit';
import { comm } from '../../services/comm';
import { toPath } from '../../services/navUtils';
import { Link } from '../Link';

type NavLinkProps = {
  icon: SemanticICONS;
  link: string;
  location: Location;
  onClick: () => void;
  processing: any;
  startProject: boolean;
  text: string;
};

class NavLink extends React.Component<NavLinkProps> {
  componentDidUpdate (previousProps: NavLinkProps) {
    const { link, processing } = this.props;

    const currentStatus = _.get(processing, `${link}.status`);
    const terminal = [DEFAULTS.SUCCESS_STATUS, DEFAULTS.ERROR_STATUS].includes(currentStatus);

    if (terminal && (this.isActive(this.props) || this.isActive(previousProps))) {
      comm.trigger('setProcessStatus', [link], null);
    }
  }

  isActive = (props: NavLinkProps) => {
    const { location, link } = props;
    return location.pathname === link;
  };

  get statusIcon () {
    const { processing, link } = this.props;

    const status = _.get(processing, `${link}.status`);

    if (status === DEFAULTS.ERROR_STATUS) {
      return <Icon fitted name='warning' color='orange' size='small' />;
    }
    if (status === DEFAULTS.SUCCESS_STATUS) {
      return <Icon fitted name='certificate' color='green' size='small' />;
    }
    if (status === DEFAULTS.FETCHING_STATUS) {
      return <Icon fitted loading name='spinner' color='yellow' size='small' />;
    }

    return null;
  }

  get link () {
    let { text, icon, location, link, onClick, startProject } = this.props;
    let linkObject = { search: location.search, pathname: link };
    const className = `hy-side-nav-link-anchor ${this.isActive(this.props) ? 'hy-active' : ''}`;

    if (startProject) {
      onClick = toPath.bind(this, link.replace('/', ''));
      // @ts-expect-error TS(2739): Type '{}' is missing the following properties from... Remove this comment to see the full error message
      linkObject = {};
    }

    return (
      <Link to={linkObject} onClick={onClick} className={className}>
        <div className='hy-nav-status-label'>{this.statusIcon}</div>

        <div className='hy-side-nav-link-text'>
          <Icon name={icon} size='big' />
          <span>{text}</span>
        </div>
      </Link>
    );
  }

  render () {
    const { text } = this.props;

    return (
      <li className='hy-side-nav-link' title={text}>
        {this.link}
      </li>
    );
  }
}

export { NavLink };
