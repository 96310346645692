import React from 'react';

import defaults from '../../../DEFAULTS.json';
import { comm } from '../../../services/comm';
import { EditModal } from './EditModal';
import { StoryboardOption } from './StoryboardPreview/StoryboardOption';
const { MIN_ANNOTATION_DURATION } = defaults;

type State = any;

type StoryboardItemProps = {
  data: any;
  delete: () => void;
  index: number;
  isVisible: boolean;
  scale: any;
  updatePage: (id: string, obj: any) => void;
};

class StoryboardItem extends React.Component<StoryboardItemProps, State> {
  constructor (props: StoryboardItemProps) {
    super(props);

    this.state = {
      active: false,
      editing: false,
    };
  }

  edit = (e: any) => {
    this.setState({ editing: true });
  };

  close = () => {
    this.setState({ editing: false });
  };

  save = (obj: any) => {
    this.props.updatePage(this.props.data.id, obj);
    this.setState({ data: { ...this.props.data, ...obj } });
  };

  handleClick = () => {
    comm.trigger('setCurrentTime', this.props.data.time + MIN_ANNOTATION_DURATION / 2); // half of min duration to insure the content is visible after seek
    // half of min duration to insure the content is visible after seek
  };

  delete = (e: any) => {
    e.stopPropagation();
    this.props.delete();
  };

  render () {
    const { data, scale, index, isVisible } = this.props;
    const { editing } = this.state;

    if (!isVisible) return null;

    return (
      <div className='hy-storyboard-item'>
        <div onClick={this.handleClick} onDoubleClick={this.edit}>
          <StoryboardOption {...data} scale={scale} index={index} />
        </div>
        {editing && <EditModal data={data} save={this.save} close={this.close} delete={this.delete} />}
      </div>
    );
  }
}

export { StoryboardItem };
