import React, { Component } from 'react';

import _ from 'lodash';

import { TextInputProps } from '../../../types/layout/forms';
import { Form, TextArea } from '../../index';
import './index.scss';

const BASE_HEIGHT = 26;

type State = any;

type CustomTextAreaProps = TextInputProps & {
  id?: string | number;
  fluid?: boolean;
};

export class CustomTextArea extends Component<CustomTextAreaProps, State> {
  onFocus: any;
  textarea: any;
  constructor (props: CustomTextAreaProps) {
    super(props);
    this.textarea = React.createRef();
    this.state = { height: BASE_HEIGHT, value: props.value };
  }

  componentDidMount () {
    this.checkMultiline();
  }

  get formStyle () {
    const { fluid } = this.props;
    return { width: fluid ? '100%' : '' };
  }

  checkMultiline = () => {
    this.setState({ height: BASE_HEIGHT }, this.setHeight);
  };

  setHeight = () => {
    this.setState({ height: _.get(this.textarea, 'current.ref.current.scrollHeight', BASE_HEIGHT) });
  };

  handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ value: e.target.value }, this.checkMultiline);
  };

  handleBlur = () => {
    const { onBlur } = this.props;
    const { value } = this.state;
    if (typeof onBlur === 'function') onBlur(value);
    this.checkMultiline();
  };

  render () {
    const { onDoubleClick, onMouseLeave, placeholder, id = null } = this.props;
    const { height, value } = this.state;

    return (
      <Form style={this.formStyle} className='hy-textarea-form'>
        <TextArea
          style={{ height: height + 'px' }}
          ref={this.textarea}
          className='hy-textarea'
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onDoubleClick={onDoubleClick}
          onFocus={this.onFocus}
          onMouseLeave={onMouseLeave}
          placeholder={placeholder}
          value={value}
          id={id}
        />
      </Form>
    );
  }
}
