import React from 'react';

import { Banner } from '@newsela/angelou';

type InfoBannerProps = {
  title?: string;
  body: string;
  isDismissible? : boolean;
  onClose?: () => void;
  customConfig?: any;
};

export const InfoBanner = ({
  title,
  body,
  isDismissible,
  onClose,
}: InfoBannerProps) => {
  return (
    <Banner
      bannerType={Banner.types.info}
      title={title}
      body={body}
      isDismissible={isDismissible}
      onClose={onClose}
      __classNameFor={{
        root: 'info-banner'
      }}
      __cssFor={{
        root: {
          '--banner-innerContent-paddingRight': 0
        }
      }}
    />
  );
};
