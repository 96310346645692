import React from 'react';

type State = any;

type ProgressLineProps = {
  getTimePercent: any;
  goToTime: any;
  height: number;
  progressPercent: number;
};

export class ProgressLine extends React.Component<ProgressLineProps, State> {
  constructor (props: ProgressLineProps) {
    super(props);
    this.state = {
      dragging: false,
      percent: 0,
    };
  }

  componentWillUnmount () {
    this.removeEventListeners();
  }

  get containerStyle () {
    const { height, progressPercent } = this.props;
    const { dragging, percent } = this.state;

    return {
      left: `${dragging ? percent : progressPercent}%`,
      height: height + 'px',
    };
  }

  get lineStyle () {
    if (!this.state.dragging) return {};

    return {
      width: '2px',
      backgroundColor: '#ff0000',
      boxShadow: '-2px 2px 2px rgba(0, 0, 0, 0.3)',
    };
  }

  handleMouseDown = () => {
    const { progressPercent } = this.props;
    this.setState({ dragging: true, percent: progressPercent * 100 });
    this.addEventListeners();
  };

  handleMouseMove = (e: any) => {
    this.setState({ percent: this.props.getTimePercent(e) * 100 });
  };

  handleMouseUp = (e: any) => {
    if (this.state.dragging) {
      this.props.goToTime(e);
    }
    this.setState({ dragging: false });
    this.removeEventListeners();
  };

  addEventListeners = () => {
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);
  };

  removeEventListeners = () => {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
  };

  render () {
    return (
      <div className='hy-progress-line-container' style={this.containerStyle} onMouseDown={this.handleMouseDown}>
        <div className='hy-progress-line' style={this.lineStyle} />
        <div className='hy-progress-line-handle' />
      </div>
    );
  }
}
