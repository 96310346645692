import { Action } from 'types/actions';

import { createQuizPassingCondition } from './conditionUtils';

export const createQuizPassFailVariations = ({
  quiz,
  finalScreenEnabled,
  retriesEnabled
}: any) => {
  const isPassFailAction = (action: Action) => ['onPass', 'onFail'].includes(action.event);
  const passFailActions = quiz.actions.filter(isPassFailAction);

  const actions = passFailActions.map((action: Action) => {
    const passing = action.event === 'onPass';
    const quizPassFailCondition = createQuizPassingCondition({
      quizId: quiz.id,
      isPassing: passing,
      finalScreen: finalScreenEnabled,
      retriesEnabled,
    });

    const changes = {
      actions: [
        {
          ...action,
          event: 'onComplete',
        },
      ],
    };

    let secondary = null;
    if (retriesEnabled && !finalScreenEnabled && !passing) {
      secondary = {
        changes,
        conditions: [
          // create passing condition without `preSubmit` or `postSubmit` conditions
          createQuizPassingCondition({
            quizId: quiz.id,
            isPassing: passing,
            finalScreen: false,
            retriesEnabled: false,
            isLastQuestion: true,
          }),
        ],
      };
    }

    return [
      {
        changes,
        conditions: [quizPassFailCondition],
      },
      secondary,
    ].filter(Boolean);
  });
  return actions.length ? actions.flat() : actions;
};
