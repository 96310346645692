import './style.scss';

import React from 'react';

import _ from 'lodash';

import { Button, Card, Grid, Header, SearchBox } from '../../../../hapyak-ui-toolkit';
import { CarouselProps } from '../../../../types/layout/carousel';
import { Carousel } from './Carousel';
import { Filter } from './Filter';

type State = any;

type ItemOrganizerProps = CarouselProps & {
  customHeaderButtons?: any;
  customHeaderComponents?: any;
  disableMenuOptions: boolean;
  enableExpandedView: boolean;
  enableMinimization: boolean;
  enableSearch: boolean;
  filter: string;
  handleExpand?: () => void;
  handleMinimized?: () => void;
  itemOrganizerClassName: string;
  itemOrganizerStyles?: any;
  itemType?: string;
  startCompact: boolean;
  title?: string;

};

export class ItemOrganizer extends React.Component<ItemOrganizerProps, State> {
  constructor (props: ItemOrganizerProps) {
    super(props);
    this.state = this.defaultState;
  }

  get defaultState () {
    return {
      carouselView: false,
      bodyMinimized: false, // user should be able to minimize component
      search: '',
      searchTags: '',
      selectedTags: [],
      filteredResults: [],
    };
  }

  UNSAFE_componentWillMount () {
    const { items = [], startCompact } = this.props;

    const searchTagArray = items
      .map((item: any) => {
        const { props = {} } = item;
        return props.item && props.item.tags;
      })
      .filter((v: any, i: any, a: any) => a.indexOf(v) === i);

    const searchTags = searchTagArray.flat && searchTagArray.flat();

    this.setState({
      filteredResults: this.filterResults(),
      carouselView: startCompact === true,
      searchTags,
    });
  }

  toggleSingleRowView = () => {
    const { carouselView } = this.state;
    const { handleExpand } = this.props;
    if (_.isFunction(handleExpand)) handleExpand();
    this.setState({ carouselView: !carouselView, bodyMinimized: false });
  };

  resetFilters = () => {
    this.setState({ ...this.defaultState }, () => {
      this.setState({ filteredResults: this.filterResults() });
    });
  };

  toggleBodyMinimized = () => {
    const { bodyMinimized } = this.state;
    const { handleMinimized } = this.props;
    if (_.isFunction(handleMinimized)) handleMinimized();

    this.setState({ bodyMinimized: !bodyMinimized }, () => {
      if (bodyMinimized) this.resetFilters();
    });
  };

  filterResults = () => {
    let { search, selectedTags } = this.state;
    let { items = [] } = this.props;
    const searchTagReq = !!selectedTags.length;
    const searchMatchReq = !!search.length;

    search = search
      .toLowerCase()
      .split(' ')
      .filter(Boolean);

    if (searchTagReq) {
      items = items.filter((item: any) => {
        const tags = item.props.item.tags.map((tag: any) => tag.toLowerCase());
        return tags.includes(selectedTags);
      });
    }

    if (!searchMatchReq) return items;

    return items.filter((item: any) => {
      const { subDescription = '', header = '', description = '' } = item.props.item;
      const searchableContent = [subDescription, header, description]
        .map((el) => el.toLowerCase())
        .join(' ')
        .split(' ');

      return searchableContent.some((content) => {
        return search.some((searchWord: any) => content.includes(searchWord));
      });
    });
  };

  search = ({
    target
  }: any) => {
    this.setState({ search: target.value }, () => {
      this.setState({ filteredResults: this.filterResults() });
    });
  };

  updateTagSearch = (selectedTags: any) => {
    this.setState(selectedTags, () => this.filterResults());
  };

  handleTagSearch = (evt: any, data: any) => {
    const { resetFilter, tags } = data;
    this.updateTagSearch({ selectedTags: resetFilter ? [] : tags });
  };

  clearTags = () => {
    this.updateTagSearch({ selectedTags: this.defaultState.selectedTags });
  };

  get uniqueTags () {
    const { items = [] } = this.props;
    const mappedItems = items.map((item: any) => {
      const { props = {} } = item;
      return props.item && props.item.tags;
    });

    return !mappedItems.flat
      ? []
      : mappedItems
        .flat()
        .filter((value: any, idx: any, arr: any) => arr.indexOf(value) === idx)
        .filter((val: any) => !!val);
  }

  get filter () {
    const { filter } = this.props;
    const tags = this.uniqueTags;

    return !filter || tags.length === 0 ? null : (
      <Filter items={tags} displayType={filter} handleChange={this.handleTagSearch} />
    );
  }

  get searchBox () {
    const filterResults = this.filterResults();
    const { itemType = '', items } = this.props;
    const { search } = this.state;

    return (
      <SearchBox
        search={this.search}
        value={search}
        visible={filterResults.length}
        type={itemType}
        float='right'
        total={items.length}
      />
    );
  }

  render () {
    const filterResults = this.filterResults();
    const {
      title,
      filter,
      enableSearch,
      enableExpandedView,
      enableMinimization,
      customHeaderButtons,
      customHeaderComponents,
      itemOrganizerClassName = '',
      showCarouselHandles,
      overlayCarouselHandles,
      scrollCount,
      disableMenuOptions,
      itemOrganizerStyles = {},
    } = this.props;
    const { carouselView, bodyMinimized } = this.state;
    const displaySearch = !bodyMinimized && filter && enableSearch;
    const hasHeaderContent = title || customHeaderButtons || customHeaderComponents;

    return (
      <div className='hy-item-organizer' style={{ ...itemOrganizerStyles }}>
        <div className={itemOrganizerClassName}>
          <Grid style={{ padding: '0' }}>
            {!hasHeaderContent ? null : (
              <Grid.Row columns={2}>
                <Grid.Column>{title && <Header>{title}</Header>}</Grid.Column>
                <Grid.Column floated='right'>
                  <Button.Group floated='right' basic size='small'>
                    {customHeaderButtons}
                    {bodyMinimized && enableMinimization && (
                      <Button
                        floated='right'
                        icon='ellipsis vertical'
                        onClick={this.toggleBodyMinimized}
                      />
                    )}
                  </Button.Group>
                  {customHeaderComponents}
                </Grid.Column>
              </Grid.Row>
            )}
            {displaySearch && disableMenuOptions !== true && (
              <Grid.Row columns={3}>
                <Grid.Column floated='right'>{filter && this.uniqueTags && this.filter}</Grid.Column>
                <Grid.Column>{enableSearch && this.searchBox}</Grid.Column>
                <Grid.Column floated='right'>
                  <Button.Group floated='right' basic size='small'>
                    {enableExpandedView && (
                      <Button icon='expand' onClick={this.toggleSingleRowView} />
                    )}
                    {enableMinimization && (
                      <Button
                        floated='right'
                        icon='ellipsis vertical'
                        onClick={this.toggleBodyMinimized}
                      />
                    )}
                  </Button.Group>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </div>
        {displaySearch && (
          <div>
            {carouselView ? (
              <Carousel
                items={filterResults}
                showCarouselHandles={showCarouselHandles}
                overlayCarouselHandles={overlayCarouselHandles}
                scrollCount={scrollCount}
              />
            ) : (
              <Card.Group>{filterResults}</Card.Group>
            )}
          </div>
        )}
      </div>
    );
  }
}
