import { findAssociatedQuizAnnotationIds } from '../components/editors/UXEditor/AnnotationEditor/QuizBuilder/quizBuilderUtils';
import { Annotation } from '../types/annotations';
import { getToolTypeModifierForPlayer } from './packagingServiceModifiers';
import { findAnnotationById } from './toolService';

export const filterAnnotationsForPlayer = (annotation: Annotation, annotations: Annotation[] = []) => {
  if (annotation && annotations) {
    const modifier = getToolTypeModifierForPlayer(annotation.toolType);
    let keepSelf = true;
    if (modifier && typeof modifier.filterSelf === 'function') {
      keepSelf = modifier.filterSelf(annotation, annotations);
    }
    return !!annotation && keepSelf;
  }
};

export const modifyAnnotationForPlayer = (annotation: Annotation, annotations: Annotation[]) => {
  const modifier = getToolTypeModifierForPlayer(annotation.toolType);
  if (modifier && typeof modifier.modify === 'function') {
    annotation = modifier.modify(annotation, annotations);
  }

  return annotation;
};

export const orderQuizAnnotationsForPlayer = (annotations: Annotation[]) => {
  const quizAnnotations = annotations.filter((a: Annotation) => a.type === 'quiz');

  const findAssociated = (quiz: Annotation) => [quiz.id, ...findAssociatedQuizAnnotationIds(quiz)];
  const quizIds = quizAnnotations.map(findAssociated).flat();
  const sortedQuizAnnotations = quizIds.map((id: string) => findAnnotationById(id));

  const isNotInIdArray = (a: Annotation) => !quizIds.includes(a.id);
  const filteredAnnotations = annotations.filter(isNotInIdArray);

  return [...filteredAnnotations, ...sortedQuizAnnotations];
};
