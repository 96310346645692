import './Upload.scss';
import React, { Dispatch, SetStateAction } from 'react';

import { ConfirmProps, SemanticCOLORS } from 'semantic-ui-react';

import { ConfirmModal } from '../../../hapyak-ui-toolkit';
import { gaConfig, ReactGA } from '../../../services/gaConfig';
import { uploadFile } from '../../../services/uploadUtils';
import { AngelouInputReadOnly } from '../AngelouComponents/AngelouInputReadOnly';
import { FileChooserButton } from './FileChooserButton';

type State = any;

type UploadProps = {
  afterUpload: (val: string) => void;
  beforeUpload?: (val: string) => void;
  buttonColor?: SemanticCOLORS;
  confirm?: ConfirmProps;
  disabled?: boolean;
  displayConfirm?: boolean;
  fileName?: string;
  handleFileChange?: (val: any) => void;
  label?: string;
  local?: boolean;
  filetype?: string;
  onClick?: () => void;
  postponeUpload?: boolean;
  projectId?: string;
  setFileName?: Dispatch<SetStateAction<string>>;
  type?: string;
};

export class Upload extends React.Component<UploadProps, State> {
  constructor (props: UploadProps) {
    super(props);
    this.state = { loading: false, file: null };
  }

  afterUploadFile = (url: string) => {
    const { afterUpload } = this.props;
    this.setState({ loading: false });
    if (typeof afterUpload === 'function') afterUpload(url);
  };

  startUpload = async () => {
    const { filetype, type, local, beforeUpload } = this.props;
    const { file } = this.state;
    this.setState({ loading: true });

    if (typeof beforeUpload === 'function') beforeUpload('uploading');
    const url = await uploadFile(file, type, filetype, local);
    this.afterUploadFile(url);
    ReactGA.event(gaConfig.Analytics.Portal.Assets.Upload.StartUpload);
  };

  handleChange = (file: any) => {
    const { handleFileChange, postponeUpload, setFileName } = this.props;
    if (typeof setFileName === 'function') {
      setFileName(file.name);
    }
    this.setState({ file }, () => {
      if (typeof handleFileChange === 'function') handleFileChange({ file });
      if (postponeUpload) return;
      this.startUpload();
    });
  };

  get confirmModal () {
    const { disabled, buttonColor, confirm, displayConfirm } = this.props;
    const { loading, file } = this.state;
    // TODO: change when support for multiple videos is added
    return !displayConfirm ? null : (
      <ConfirmModal
        buttonProps={{
          fluid: true,
          color: buttonColor,
          primary: true,
          disabled: disabled || !file || loading,
          content: 'Upload and continue',
          style: { marginTop: '10px' },
        }}
        confirmCallback={this.startUpload}
        {...confirm}
      />
    );
  }

  render () {
    const { confirm, disabled, filetype, postponeUpload, onClick, type, label, fileName } = this.props;
    const { loading, file } = this.state;

    return (
      <div className='file-uploader'>
        <AngelouInputReadOnly
          label={(label || type) + ' file'}
          value={fileName || (postponeUpload && file ? file.name : '')}
          __cssFor={{
            root: {
              marginBottom: '1rem',
              marginRight: '1rem'
            }
          }}
        />
        <FileChooserButton
          disabled={disabled || loading}
          onChange={this.handleChange}
          filetype={filetype}
          onClick={onClick}
        />
        {postponeUpload && (confirm ? this.confirmModal : null)}
      </div>
    );
  }
}
