import './index.scss';

import React from 'react';

import { Grid, Header, Tab } from '../../../../../hapyak-ui-toolkit';
import { media } from '../../../../../services/mediaController';
import { assetService } from '../../../../../services/stateController';
import { Annotation, AnnotationProperties } from '../../../../../types/annotations';
import { ImagePicker } from '../../../CommonComponents/ImagePicker';
import { ActionsList } from '../../ActionsEditor/ActionsList';
import { QuizBaseEditor, QuizBaseEditorProps } from './QuizBaseEditor';
import { QuizFooter } from './QuizFooter';

/*   NOTE:
 *   Component extends helpers from `QuizBaseEditor`
 */

export class QuizSectionEditor extends QuizBaseEditor {
  constructor (props: QuizBaseEditorProps) {
    super(props);

    const { annotation } = props;

    this.state = {
      content: annotation.content || '',
      properties: {
        description: '',
        shuffleQuestionOrder: false,
        ...annotation.properties,
      },
    };
  }

  deactivate = () => {
    this.setActive(false);
  };

  get title () {
    const { content } = this.state;
    const overrides = {
      className: 'hy-margin-top',
      onChange: this.handleContentInputChange.bind(this, content),
      onBlur: this.persistContentFromState.bind(this, content),
    };
    return this.createInput({ value: content, propertyName: 'title', overrides });
  }

  get description () {
    const { description } = this.state.properties;
    return this.createInput({ value: description, propertyName: 'description' });
  }

  get shuffleQuestionOrder () {
    const { shuffleQuestionOrder } = this.state.properties;
    return this.createCheckbox(shuffleQuestionOrder, 'shuffleQuestionOrder');
  }

  setImage = (property: any, image: any) => {
    const { annotation = {} as Annotation, setProperty } = this.props;

    if (typeof setProperty === 'function') {
      setProperty({ [property]: image.id }, annotation);
    }
  };

  get titleRow () {
    const { content } = this.state;
    const titleAsReadOnly = <Header className='hy-disable-overflow' content={content} />;
    const titleContent = this.isActive ? this.title : titleAsReadOnly;

    return (
      <Grid.Row>
        <Grid.Column width={14} verticalAlign='middle'>
          {titleContent}
        </Grid.Column>
      </Grid.Row>
    );
  }

  get sectionActions () {
    const { annotation, annotations, setFromObject } = this.props;

    return (
      <ActionsList
        annotation={annotation}
        annotations={annotations || []}
        duration={media.duration}
        setFromObject={setFromObject}
      />
    );
  }

  get editor () {
    return this.isActive ? this.completeEditor : this.readOnlyEditor;
  }

  get completeEditor () {
    const { annotation = {} as Annotation } = this.props;
    const { properties = {} as AnnotationProperties } = annotation;
    const { thumbnail } = properties;
    const className = 'hy-form-field hy-compact-grid-row';

    return (
      <Grid className={className}>
        {this.titleRow}
        <Grid.Row>
          <Grid.Column width={14}>{this.description}</Grid.Column>
          <Grid.Column verticalAlign='middle' textAlign='right' width={2}>
            <ImagePicker
              messageAsIcon
              triggerAsLink
              label={false}
              image={assetService.getImage(thumbnail)}
              setImage={this.setImage.bind(this, 'thumbnail')}
              previewPosition='bottom left'
              style={{ left: '-40px' }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>{this.shuffleQuestionOrder}</Grid.Column>
        </Grid.Row>
        <Grid.Row className='hy-margin-bottom'>
          <Grid.Column>{this.sectionActions}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  get readOnlyEditor () {
    const { description } = this.state.properties;
    const className = 'hy-form-field hy-compact-grid-row';
    const descriptionRow = !description ? null : (
      <Grid.Row>
        <Grid.Column width={14} verticalAlign='middle'>
          <Header as='h4' content={description} className='hy-disable-overflow' />
        </Grid.Column>
      </Grid.Row>
    );

    return (
      <Grid className={className}>
        {this.titleRow}
        {descriptionRow}
      </Grid>
    );
  }

  // @ts-expect-error TS(2416): Property 'componentDidUpdate' in type 'QuizSection... Remove this comment to see the full error message
  componentDidUpdate (prevProps: QuizBaseEditorProps, prevState: any) {
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
    super.componentDidUpdate(prevProps, prevState);
    const { isActive } = this.state;

    if (isActive && prevState.isActive !== isActive) {
      const { setMenuData, annotation } = this.props;
      if (typeof setMenuData === 'function') {
        setMenuData({
          annotation,
          offsetHeight: this.elementRef.offsetTop,
        });
      }
    }
  }

  get footer () {
    const { canDeleteSection, onDelete } = this.props;

    return (
      <QuizFooter
        secondaryActionButton={null}
        canDelete={canDeleteSection}
        onDelete={onDelete}
        deleteButtonContent='Delete Section'
        isEditing={Boolean(this.isActive)}
        quizContentType='section'
        setIsEditing={() => null}
      />
    );
  }

  render () {
    const { annotation = {} as Annotation, label = '' } = this.props;

    if (!annotation.id) return null;

    return this.wrapComponent(
      <div className={`hy-quiz-section-editor ${this.isActive}`}>
        <Tab
          panes={[
            {
              menuItem: label,
              render: () => (
                <Tab.Pane>
                  {this.editor}
                  {this.props.children}
                  {this.footer}
                </Tab.Pane>
              ),
            },
          ]}
        />
      </div>
    );
  }
}
