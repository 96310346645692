import React, { Component } from 'react';

import { Bundle, Embed } from 'types/publishing';

import { assetService } from '../../../services/stateController';
import { toPercent } from '../../../services/utils';
import { EmbedFactory } from './Distributables/EmbedFactory';

type State = any;

type EmbedPreviewProps = {
  bundle: Bundle;
  embed: Embed;
}

export class EmbedPreview extends Component<EmbedPreviewProps, State> {
  constructor (props: EmbedPreviewProps) {
    super(props);
    this.state = {};
  }

  getThumbnailSrc = (props: EmbedPreviewProps) => {
    const { bundle } = props;
    return assetService.getImage(bundle.thumbnail).src;
  };

  get embed (): JSX.Element {
    const { bundle, embed } = this.props;
    const aspectRatio = this.getThumbnailSrc(this.props) ? null : toPercent(9 / 16, 1);
    const generated = EmbedFactory.createEmbed(bundle, embed, aspectRatio, true);

    return generated?.render(false) || <></>;
  }

  render () {
    return this.embed;
  }
}
