import React from 'react';

import _ from 'lodash';

import DEFAULTS from '../../../DEFAULTS.json';
import { comm } from '../../../services/comm';
import ErrorBanner from '../AngelouComponents/ErrorBanner';

type ProjectEditorErrorMessageProps = {
  pathname: string;
  processing: any;
}

export function ProjectEditorErrorMessage ({
  pathname,
  processing
}:ProjectEditorErrorMessageProps) {
  const handleClose = () => {
    comm.trigger('setProcessStatus', [pathname], null);
  };

  const process = _.get(processing, pathname);
  if (!process) return null;
  const hasError = process.status === DEFAULTS.ERROR_STATUS;
  return hasError ? <ErrorBanner body={process} isDismissible onClose={handleClose} /> : null;
}
