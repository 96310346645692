import React from 'react';

import { Banner } from '@newsela/angelou';

type WarningBannerProps = {
  title?: string;
  body: string;
  isDismissible? : boolean;
  onClose?: () => void;
};

const WarningBanner = ({ title, body, isDismissible, onClose }: WarningBannerProps) => {
  return (
    <Banner
      bannerType={Banner.types.alert}
      title={title || 'Warning'}
      body={body}
      isDismissible={isDismissible}
      onClose={onClose}
      __classNameFor={{
        root: 'warning-banner'
      }}
      __cssFor={{
        root: {
          '--banner-innerContent-paddingRight': 0
        }
      }}
    />
  );
};

export default WarningBanner;
