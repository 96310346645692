import React from 'react';

import { Input, Grid } from '../../../hapyak-ui-toolkit';
import { PackagingOptions, PackagingOptionsProps } from './PackagingOptions';
import './index.scss';

export class MetaDataOptions extends PackagingOptions<PackagingOptionsProps> {
  get bundleProps () {
    const { bundleProps } = this.props;
    return bundleProps;
  }

  get packageNameInput () {
    const { bundleName = '' } = this.bundleProps;

    return (
      <Input
        style={{ width: '100%' }}
        className='hy-margin-top hy-margin-bottom'
        onChange={this.handleChange.bind(this, 'bundleName')}
        onBlur={this.saveChanges}
        width={6}
        label='Name'
        value={bundleName}
      />
    );
  }

  render () {
    return (
      <Grid style={{ width: '100%' }} className='hy-form-field'>
        <Grid.Row>{this.packageNameInput}</Grid.Row>
      </Grid>
    );
  }
}
