import React from 'react';

import { Icon, Popup } from 'semantic-ui-react';

type InfoIconProps = {
  header: any;
  content: any;
};

export class InfoIcon extends React.Component<InfoIconProps> {
  icon = (<Icon name='info circle' style={{ marginLeft: '5px' }} />);

  render () {
    const { header, content } = this.props;
    return <Popup trigger={this.icon} header={header} content={content} />;
  }
}
