import _ from 'lodash';

import { stateController } from '../../../../services/stateController';
import { findAnnotationById } from '../../../../services/toolService';
import { Annotation } from '../../../../types/annotations';
import { QuizActionInputs } from './QuizActionInputs';

export class QuestionActionInputs extends QuizActionInputs {
  get assessedEvent () {
    const { annotation } = this.props;
    let { event } = this.state;

    if (annotation.toolType === 'question' && event === 'onSpecificAnswer') {
      event = 'question_onSpecificAnswer';
    }

    return event;
  }

  get setupContext () {
    const annotations = stateController.getCurrentData('ux').annotations;
    let { annotation = {} as Annotation } = this.props;
    annotation = findAnnotationById(_.get(annotation, 'collections.quizSection[0]', {}));
    return { annotation, annotations };
  }
}
