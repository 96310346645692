import { Bundle, Embed, EmbedObject } from 'types/publishing';

import signingService from '../../../../services/signingService';
import { CustomTagEmbed } from './CustomTagEmbed';
import { IframeEmbed } from './IframeEmbed';
import { LightboxEmbed } from './LightboxEmbed';

export class EmbedFactory {
  static createEmbed = (bundle: Bundle, embed: Embed, aspectRatio: any, preview?: boolean): EmbedObject | undefined => {
    const { src, thumbnail, customSrc = '' } = bundle;

    const embedMap = {
      lightbox: LightboxEmbed, // thumbnail outside of player embed
      iframe: IframeEmbed, // thumbnail inside of player embed (in config)
      customTag: CustomTagEmbed,
    };

    if (!customSrc && !src) return undefined;

    if (!embed.embedType) return undefined;

    const Embed = embedMap[embed.embedType];

    return new Embed({
      aspectRatio: aspectRatio,
      baseUrl: customSrc || signingService.sign(src),
      embed,
      thumbnail: signingService.sign(thumbnail),
      preview,
    });
  };
}
