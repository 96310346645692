import React, { CSSProperties } from 'react';

import _ from 'lodash';
import { SemanticFLOATS } from 'semantic-ui-react';

import { Icon, TextArea, Form, Button, Grid } from '../../../hapyak-ui-toolkit';

const ANIMATION_TIMEOUT = 2000;

type State = any;

type CopyHelperProps = {
  as: string;
  buttonPosition?: string;
  buttonText?: string | undefined;
  className?: string;
  float?: SemanticFLOATS | undefined;
  onBlur?: (prop: any, value?: string) => void;
  onChange?: (prop: any, e: any, data?: any) => void;
  persistInput?: boolean;
  property?: string;
  readOnly?: boolean;
  rows?: number;
  secondaryCTA?: any;
  showText: boolean;
  style?: any;
  value: string;
};

export class CopyHelper extends React.Component<CopyHelperProps, State> {
  textArea: any;
  constructor (props: CopyHelperProps) {
    super(props);
    this.state = this.initialState;

    this.textArea = React.createRef();
  }

  get initialState () {
    const { buttonText = undefined, value = '' } = this.props; // Must pass `undefined` to SUI copmonent to remove content spacing if not defined

    const state = {
      icon: 'clipboard',
      color: '',
      buttonText,
    };

    return this.persistInput ? { ...state, ...{ value } } : state;
  }

  get persistInput () {
    /*  If `true` component will have state preservation.
            Allows `onChange` and `onBlur` callbacks and `input`.
        */
    const { persistInput } = this.props;
    return !!persistInput;
  }

  get fullWidth (): CSSProperties {
    return { width: '100%' };
  }

  get canCopy () {
    return !!document.queryCommandSupported && !!document.queryCommandSupported('copy');
  }

  get hidden (): CSSProperties {
    return { opacity: 0, height: 0, width: 0, position: 'absolute', marginLeft: '-1000px' };
  }

  onBlur = (prop: any, e: any) => {
    const { onBlur } = this.props;
    if (!this.persistInput) return;

    const value = e.target.value;
    if (onBlur) onBlur(prop, value);
  };

  onChange = (prop: any, e: any, data: any) => {
    const { onChange } = this.props;
    if (!this.persistInput) return;

    this.setState({ value: data.value });
    if (onChange) onChange(prop, e, data);
  };

  animate = () => {
    this.setState({ icon: 'checkmark', color: 'green', buttonText: 'Copied' }, () => {
      setTimeout(() => {
        this.setState(this.initialState);
      }, ANIMATION_TIMEOUT);
    });
  };

  copy = (event: any) => {
    const current = _.get(this, 'textArea.current.ref.current');
    current.select();
    document.execCommand('copy');
    event.target.focus();
    this.animate();
  };

  get textAreaStyle () {
    // pointer-evts not included so user can click and copy text
    const { readOnly = true } = this.props;
    return !readOnly ? {} : { opacity: '0.3' };
  }

  get asButton () {
    const { icon, color = undefined, buttonText } = this.state;
    const { float } = this.props;
    const colorProp = color ? { color: color } : {}; // prevent error from sui
    return <Button {...colorProp} icon={icon} content={buttonText} onClick={this.copy} floated={float} />;
  }

  get asIcon () {
    const { icon, color } = this.state;
    return <Icon color={color} name={icon} onClick={this.copy} />;
  }

  get copyElement () {
    const { as } = this.props;
    return as === 'button' ? this.asButton : this.asIcon;
  }

  get composedCopyElement () {
    const { secondaryCTA } = this.props;

    return (
      <div style={{ display: 'inline', ...this.fullWidth }}>
        {' '}
        {secondaryCTA}
        {this.copyElement}
      </div>
    );
  }

  get form () {
    const { showText, rows = 3, property } = this.props;
    const value = this.persistInput ? this.state.value : this.props.value;
    const formAreaStyle = showText ? this.fullWidth : this.hidden;

    return (
      <div style={formAreaStyle}>
        <Form>
          <TextArea
            onBlur={this.onBlur.bind(this, property)}
            onChange={this.onChange.bind(this, property)}
            rows={rows}
            style={this.textAreaStyle}
            ref={this.textArea}
            value={value}
          />
        </Form>
      </div>
    );
  }

  get defaultGridView () {
    const { style = {}, className = '' } = this.props;

    return (
      <Grid className={className} style={style}>
        <Grid.Row>
          <Grid.Column width={3}>{this.composedCopyElement}</Grid.Column>
          <Grid.Column width={13}>{this.form}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  get gridViewOne () {
    const { style = {}, className = '' } = this.props;

    return (
      <Grid className={className} style={style}>
        <Grid.Row>{this.form}</Grid.Row>
        <Grid.Row>{this.composedCopyElement}</Grid.Row>
      </Grid>
    );
  }

  get gridView () {
    const { buttonPosition } = this.props;
    return !buttonPosition ? this.defaultGridView : this.gridViewOne;
  }

  get compactView () {
    const { className = '' } = this.props;

    return (
      <div className={className}>
        {this.composedCopyElement}
        {this.form}
      </div>
    );
  }

  render () {
    const { showText } = this.props;
    const copyElement = showText ? this.gridView : this.compactView;
    return !this.canCopy ? null : copyElement;
  }
}
