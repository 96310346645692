import moment from 'moment';

const SECONDS_PER_MINUTE = 60;

function enforceLength (length: any, time: any, end?: any) {
  time = time.toString().substring(0, length);

  if (end) {
    return time.padEnd(length, '0');
  }

  return time.padStart(length, '0');
}

function validate (time: any) {
  const regex = /^(((\d{1,2}:)?[0-5]?\d:?)?([0-5]?\d)?(\.\d*)?)?$/;
  const match = time.match(regex);
  return match && match[0] === time;
}

function hmsToSeconds (string: any) {
  const [HMS, mm] = string.split('.');
  const [s, m, h] = HMS.split(':').reverse();

  let seconds = 0;

  if (mm) {
    seconds += +`0.${mm}`;
  }

  if (s) {
    seconds += +s;
  }

  if (m) {
    seconds += +m * 60;
  }

  if (h) {
    seconds += +h * 3600;
  }

  return seconds;
}

function secondsToHMS (time: number, clip?: any): string {
  const SECONDS_PER_HOUR = 3600;
  const SECONDS_PER_MINUTE = 60;

  if (!time || time < 0) {
    time = 0;
  }

  const [hms, mm] = time.toString().split('.');
  const h = Math.floor(+hms / SECONDS_PER_HOUR);
  const ms = +hms % SECONDS_PER_HOUR;
  const m = Math.floor(ms / SECONDS_PER_MINUTE);
  const s = ms % SECONDS_PER_MINUTE;

  let string = '';

  if (h) {
    string += h + ':';
  }

  string += enforceLength(2, m || 0) + ':';
  string += enforceLength(2, s || 0) + (clip ? '' : '.');
  if (!clip) string += enforceLength(3, mm || 0, true);

  return string;
}

const msToNextMinute = (ms: any) => {
  return Math.ceil(ms / 1000 / 60);
};

const secondsToRoundedMins = (seconds: any) => {
  return Math.ceil(seconds / SECONDS_PER_MINUTE);
};

const minutesToMS = (m: any) => {
  return m * 60 * 1000;
};

export const formatDateTime = (d: any) => {
  return moment(d).format('MM/DD/YYYY h:mm:ss a');
};

export default {
  hmsToSeconds,
  validate,
  secondsToHMS,
  msToNextMinute,
  secondsToRoundedMins,
  minutesToMS,
  formatDateTime,
};
