import React from 'react';

import { AxiosResponse } from 'axios';

import { openGoogleDrive } from '../../services/persistence/platformService';
import sessionClient from '../../services/sessionClient';
import { AngelouLoadingSpinner } from '../editors/AngelouComponents/AngelouLoadingSpinner';

type GoogleDriveOpenHandlerProps = {
  location: Location;
};

class GoogleDriveOpenHandler extends React.Component<GoogleDriveOpenHandlerProps> {
  jwt: any;
  constructor (...args: any) {
    // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
    super(...args);

    const { location } = this.props;
    const query = Object.fromEntries(new URLSearchParams(location.search));
    const [id] = JSON.parse(query.state).ids;

    openGoogleDrive({ id })
      .then(async ({ data }: AxiosResponse) => {
        const { projectUrl, hapyakGroup, jwt } = data;
        this.jwt = jwt;
        await this.verifyAccess(hapyakGroup);
        this.openProject(projectUrl);
      })
      .catch((e) => {
        const { response = {} } = e;

        if (response.status === 401) {
          const { code } = response.data;

          if (code === 'missing_google_credentials') {
            sessionClient.logout();
          }
        }

        console.error('error opening Google Drive file', e);
        // not sure what more to do if it fails :shrug:
      });
  }

  async verifyAccess (fileGroup: any) {
    const currentGroup = sessionClient.getCurrentGroup();

    if (currentGroup.id === fileGroup.id) {
      return true;
    }

    const memberships = await sessionClient.getGroupMemberships();

    if (fileGroup.id in memberships) {
      await sessionClient.setCurrentGroup(fileGroup.id);
      return true;
    }

    await sessionClient.addGroupMembership({ driveJWT: this.jwt });

    return true;
  }

  openProject (projectUrl: any) {
    window.location.assign(projectUrl);
  }

  render () {
    return <AngelouLoadingSpinner text='Fetching Project from Google Drive' />;
  }
}

export { GoogleDriveOpenHandler };
