export class Debouncer {
  method: any;
  time: any;
  timeout: any;
  constructor (method: any, time = 1000) {
    this.time = time;
    this.method = method;
    this.timeout = null;
  }

  run = (args: any) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.method(args);
    }, this.time);
  };
}
