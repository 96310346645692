// Logger class so we can better control log levels
// right now it's just a wrapper around the common console logging operations

const LOG_LEVELS = ['info', 'warn', 'error'];
const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL || 'info';

const show = (level: any) => {
  const index = LOG_LEVELS.indexOf(level);
  const threshold = LOG_LEVELS.indexOf(LOG_LEVEL);
  return index >= threshold;
};

const write = (method: any, ...args: any[]) => {
  const frozenArgs = args.map((arg) => {
    try {
      return JSON.parse(JSON.stringify(arg));
    } catch (e) {
      return arg;
    }
  });
  method('[hy]', ...frozenArgs);

  if (process.env.REACT_APP_DEBUG) {
    // eslint-disable-next-line no-console
    console.groupCollapsed('[hy] stack trace for above log'); // collapse so there is less clutter
    // eslint-disable-next-line no-console
    console.trace(); // include trace in verbose output
    // eslint-disable-next-line no-console
    console.groupEnd();
  }
};

class Logger {
  // singleton used to communicate across components
  notImplemented = (...args: any[]) => {
    write(console.error, 'Not implemented:', ...args);
  };

  error = (...args: any[]) => {
    if (!show('error')) return;
    write(console.error, ...args);
  };

  log = (...args: any[]) => {
    if (!show('info')) return;
    // eslint-disable-next-line no-console
    write(console.log, ...args);
  };

  warn = (...args: any[]) => {
    if (!show('warn')) return;
    write(console.warn, ...args);
  };
}

const instance = new Logger();

export default instance;
