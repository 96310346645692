import React from 'react';

import { Grid, Tab } from '../../../../../../hapyak-ui-toolkit';
import { assetService, stateController } from '../../../../../../services/stateController';
import { getCollections } from '../../../../../../services/toolRelationshipService';
import {
  addAnnotationWithAssociations,
  cascadeRemoveAssociatedAnnotations,
  modifyAnnotation
} from '../../../../../../services/toolService';
import { Annotation, AnnotationProperties } from '../../../../../../types/annotations';
import { ImageMetadata } from '../../../../../../types/assets';
import { QuestionType } from '../../../../../../types/questions';
import { GradingRule, GradingRuleOption } from '../../../../../../types/quizUtilityTypes';
import { ImagePicker } from '../../../../CommonComponents/ImagePicker';
import '../index.scss';
import {
  getDefaultAnnotationsByQuestionType,
  getGradingRuleOptionsByQuestionType,
  getQuestionDataByType
} from '../questionService';
import { QuizBaseEditor, QuizBaseEditorProps } from '../QuizBaseEditor';
import { QuizFooter } from '../QuizFooter';
import { QuizGradingRuleDropdown } from './QuizGradingRuleDropdown';
import { QuizQuestionTypeDropdown } from './QuizQuestionTypeDropdown';

export class QuizQuestionEditor extends QuizBaseEditor {
  constructor (props: QuizBaseEditorProps) {
    super(props);

    const { annotation } = props;

    this.state = {
      content: annotation.content || '',
      isEditing: false,
      properties: {
        points: 1,
        question: '',
        description: '',
        shuffleAnswerOrder: false,
        required: false,
        ...annotation.properties,
      },
    };
  }

  get questionType () {
    const { annotation = {} as Annotation } = this.props;
    const { properties = {} as AnnotationProperties } = annotation;
    const { type } = properties;

    return type;
  }

  get question () {
    const { content } = this.state;
    const overrides = {
      onChange: this.handleContentInputChange.bind(this, content),
      onBlur: this.persistContentFromState.bind(this, content),
    };
    return this.createTextArea({ value: content, propertyName: 'question', overrides: overrides });
  }

  get description () {
    const { description } = this.state.properties;
    return this.createTextArea({ value: description, propertyName: 'description', placeholder: 'Write a description' });
  }

  get shuffleAnswerOrderRow () {
    const { shuffleAnswerOrder } = this.state.properties;
    return (
      <div className='quiz-question-editor-row'>
        {this.createCheckbox(shuffleAnswerOrder, 'shuffleAnswerOrder')}
      </div>
    );
  }

  get points () {
    const { points } = this.state.properties;
    return this.createInput({
      value: points,
      propertyName: 'points',
      type: 'number',
      overrides: {
        min: 0,
      },
      label: this.hasGradingRuleOptions ? 'Points per answer' : 'Points'
    });
  }

  get required () {
    const { required } = this.state.properties;
    return this.createCheckbox(required, 'required');
  }

  setIsEditing = (val?: boolean) => {
    this.setState({ isEditing: val });
  };

  setImage = (property: string, image: ImageMetadata) => {
    const { annotation = {} as Annotation, setProperty } = this.props;

    if (typeof setProperty === 'function') {
      setProperty({ [property]: image.id }, annotation);
    }
  };

  get questionRow () {
    return (
      <div className='quiz-question-editor-row'>
        {this.question}
      </div>
    );
  }

  get descriptionRow () {
    return (
      <div className='quiz-question-editor-row'>
        {this.description}
      </div>
    );
  }

  get divider () {
    return (
      <div className='quiz-question-editor-row'>
        <hr />
      </div>
    );
  }

  resetQuizAnswerCollection = (targetId: string, annotations: Annotation[]) => {
    const a = annotations.find((a: Annotation) => a.id === targetId);

    if (a) {
      a.collections = {
        ...a.collections,
        quizAnswer: [],
      };
    }

    return annotations;
  };

  onQuestionTypeChange = (value: QuestionType) => {
    const { setProperty, annotation, annotations = [] } = this.props;
    const { properties = {} as AnnotationProperties } = annotation;
    const prevValue = properties.type;
    const resetAnswers = !(['multipleChoice', 'checkbox'].includes(prevValue) && ['multipleChoice', 'checkbox'].includes(value));

    const { editing } = getQuestionDataByType(prevValue);
    if (editing && editing.isCompatibleWith && editing.isCompatibleWith.includes(value)) {
      setProperty({ type: value }, annotation);
      return;
    }

    const collectionIds = getCollections(annotation);

    let _annotations = [...annotations];

    if (resetAnswers) {
      collectionIds.forEach((id) => {
        _annotations = cascadeRemoveAssociatedAnnotations(id, _annotations);
      });

      _annotations = this.resetQuizAnswerCollection(annotation.id, _annotations);

      const newDefaultAnnotations = getDefaultAnnotationsByQuestionType(value);

      newDefaultAnnotations.forEach((a: Annotation) => {
        const { updatedAnnotations } = addAnnotationWithAssociations({
          appliesTo: annotation.id,
          toolType: a.toolType,
          annotations: _annotations,
          overrides: a.overrides,
        });

        // @ts-expect-error TS(2322): Type 'any[] | undefined' is not assignable to type... Remove this comment to see the full error message
        _annotations = updatedAnnotations;
      });
    }

    _annotations = modifyAnnotation(_annotations, annotation.id, { properties: { type: value } });
    stateController.updateProject('ux', { annotations: _annotations });
  };

  onGradingRuleChange = (value: GradingRule) => {
    const { annotation, setProperty } = this.props;
    setProperty({ gradingRule: value }, annotation);
  };

  get questionTypeDropdown () {
    return <QuizQuestionTypeDropdown value={this.questionType} setValue={this.onQuestionTypeChange} />;
  }

  get gradingRuleDropdown () {
    const { annotation = {} as Annotation } = this.props;
    const { properties = {} as AnnotationProperties } = annotation;
    const { gradingRule, type } = properties;
    const gradingRuleOptions = getGradingRuleOptionsByQuestionType(type);
    const gradingRuleValue = {
      text: 'Any correct option can be selected',
      key: gradingRule,
      value: gradingRule
    };

    return (
      <QuizGradingRuleDropdown
        options={gradingRuleOptions}
        value={gradingRuleValue as GradingRuleOption}
        setValue={this.onGradingRuleChange}
      />
    );
  }

  get questionImagePicker () {
    const { annotation = {} as Annotation } = this.props;
    const { properties = {} as AnnotationProperties } = annotation;
    const { thumbnail } = properties;

    return (
      <Grid.Row className='hy-form-field'>
        <Grid.Column verticalAlign='middle' textAlign='right' width={2}>
          <ImagePicker
            messageAsIcon
            triggerAsLink
            label={false}
            image={assetService.getImage(thumbnail)}
            setImage={this.setImage.bind(this, 'thumbnail')}
            previewPosition='bottom left'
            style={{ left: '-40px' }}
          />
        </Grid.Column>
      </Grid.Row>
    );
  }

  get canShuffleAnswerOrder () {
    return ['trueFalse', 'multipleChoice', 'checkbox'].includes(this.props.annotation.properties.type);
  }

  get hasGradingRuleOptions () {
    return this.questionType === 'checkbox';
  }

  get completeEditor () {
    const { quizAnswerEditor = null } = this.props;
    const { isEditing } = this.state;

    return (
      <Grid className='hy-compact-grid-row'>
        {isEditing && (
          <>
            {this.hasGradingRuleOptions &&
              <div className='quiz-question-editor-top-row-full-width'>
                {this.questionTypeDropdown}
              </div>}
            <div className='quiz-question-editor-row-with-two-columns'>
              <div className='quiz-question-editor-row-first-column'>{this.hasGradingRuleOptions ? this.gradingRuleDropdown : this.questionTypeDropdown}</div>
              <div className='quiz-question-editor-row-second-column'>{this.points}</div>
            </div>
          </>
        )}
        {this.questionRow}
        {this.canShuffleAnswerOrder && this.divider}
        {quizAnswerEditor}
        {this.canShuffleAnswerOrder && this.shuffleAnswerOrderRow}
        {this.canShuffleAnswerOrder && this.divider}
        {this.descriptionRow}
        {this.divider}
      </Grid>
    );
  }

  get readOnlyEditor () {
    const { quizAnswerList } = this.props;
    const { content } = this.state;
    const placeholder = 'Question';

    return (
      <div className='read-only-answer-list-container'>
        <div className='read-only-question'>{content || placeholder}</div>
        <div className='read-only-answer-list'>{quizAnswerList}</div>
      </div>
    );
  }

  get editor () {
    const { isEditing } = this.state;
    return isEditing ? this.completeEditor : this.readOnlyEditor;
  }

  // @ts-expect-error TS(2416): Property 'componentDidUpdate' in type 'QuizQuestio... Remove this comment to see the full error message
  componentDidUpdate (prevProps: any, prevState: any) {
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
    super.componentDidUpdate(prevProps, prevState);
    const { isEditing } = this.state;
    const activeStateChanged = isEditing !== prevState.isEditing;

    if (!activeStateChanged) {
      return;
    }

    if (isEditing) {
      const { setMenuData, annotation } = this.props;
      if (typeof setMenuData === 'function') {
        setMenuData({
          annotation,
          offsetHeight: this.elementRef.offsetTop,
        });
      }
    }
  }

  get footer () {
    const { onDelete, hideDelete } = this.props;
    const { isEditing } = this.state;

    return (
      <QuizFooter
        deleteButtonContent='Delete Question'
        hideDelete={hideDelete}
        isEditing={isEditing}
        onDelete={onDelete}
        quizContentType='question'
        setIsEditing={this.setIsEditing}
      />
    );
  }

  render () {
    const { annotation = {} as Annotation, label = '' } = this.props;
    const { isEditing } = this.state;

    if (!annotation.id) return null;

    return this.wrapComponent(
      <div className={`hy-quiz-question-editor ${isEditing ? 'active' : ''}`}>
        <Tab
          panes={[
            {
              menuItem: label,
              render: () => (
                <Tab.Pane>
                  {this.editor}
                  {this.footer}
                </Tab.Pane>
              ),
            },
          ]}
        />
      </div>
    );
  }
}
