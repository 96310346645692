import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Combobox, Icon, VideoSVG } from '@newsela/angelou';
import './VideoUploadComponent.scss';

import { ISO_DROPDOWN_OPTIONS } from '../AssetsEditor/isoLanguageCodes';
import { Upload } from '../AssetsEditor/Upload';

type VideoUploadComponentProps = {
  afterUpload: (val: string) => void;
  beforeUpload: (val: string) => void;
  handleFileChange: (val: any) => void;
  onLanguageChange: (val: any) => void;
  toggleUploadFromMyComputer: () => void;
  uploadFromMyComputer?: boolean;
  videoLanguage?: { value: string, label: string };
  videoFilename?: string;
  setVideoFilename?: Dispatch<SetStateAction<string>>;
  error?: string | null;
  uploading?: boolean;
}

export const VideoUploadComponent = ({
  afterUpload,
  beforeUpload,
  handleFileChange,
  onLanguageChange,
  toggleUploadFromMyComputer,
  uploadFromMyComputer,
  videoLanguage,
  videoFilename,
  setVideoFilename,
  error,
  uploading
}: VideoUploadComponentProps) => {
  const [hasFile, setHasFile] = useState(false);
  const [videoUploadMessage, setVideoUploadMessage] = useState('Attach a video file below to upload. This is optional to create a project, but required to publish.');
  const filteredLanguages = ISO_DROPDOWN_OPTIONS.filter((l) => l.value === 'en').map((lang) => ({ label: lang.text, value: lang.value }));

  useEffect(() => {
    if (uploadFromMyComputer && hasFile && !error) {
      setVideoUploadMessage('Video file is ready for upload.');
    } else {
      setVideoUploadMessage('Attach a video file to upload. This is optional to create a project, but required to publish.');
    }
  });

  const VideoSVGIcon = (
    <Icon
      SvgComponent={VideoSVG}
      isDecorative
      __cssFor={{
        root: {
          width: '32px',
          marginRight: '1rem'
        }
      }}
    />
  );

  return (
    <div className='body'>
      {!error && !uploading &&
        <div className='upload-message'>
          {VideoSVGIcon} {videoUploadMessage}
        </div>}

      <section
        key={0}
        id='vid-file-select-row'
      >
        <Upload
          key={2}
          displayConfirm={false}
          onClick={toggleUploadFromMyComputer}
          handleFileChange={(file) => { handleFileChange(file); setHasFile(!!file); }}
          postponeUpload
          beforeUpload={beforeUpload}
          afterUpload={afterUpload}
          fileName={videoFilename}
          setFileName={setVideoFilename}
          label='Video'
          filetype='video/mp4,video/avi,video/quicktime'
        />
      </section>
      <section key={6} id='language-select-row'>
        <Combobox
          key={7}
          label='Video language (required if video attached)'
          selectedItem={videoLanguage}
          items={filteredLanguages}
          itemToString={(item: { value: string, label: string } | null) => item ? item.label : ''}
          onSelectedItemChange={onLanguageChange}
          __cssFor={{
            root: {
              marginTop: '2rem',
              width: '50%',
              opacity: uploadFromMyComputer ? '100%' : '50%'
            }
          }}
        />
      </section>
    </div>
  );
};
