import React, { Component } from 'react';

import { Icon } from '../../../../hapyak-ui-toolkit';
import './style.scss';
import { CarouselProps } from '../../../../types/layout/carousel';

type State = any;

class Carousel extends Component<CarouselProps, State> {
  carouselRef: any;
  carouselRefContainer: any;
  delta: any;
  lastResizeTime: any;
  timeout: any;
  constructor (props: CarouselProps) {
    super(props);

    this.carouselRef = React.createRef();
    this.carouselRefContainer = React.createRef();
    this.lastResizeTime = null;
    this.timeout = false;
    this.delta = 200;

    this.state = {
      refs: [],
      marginLeft: 0,
      carouselRefMarginRight: 0,
      carouselRefMarginLeft: 1,
      canNavLeft: false,
    };
  }

  componentDidMount () {
    const { showCarouselHandles, overlayCarouselHandles } = this.props;

    if (showCarouselHandles && !overlayCarouselHandles) {
      const HANDLE_MARGIN = 65;

      this.setState(
        {
          marginLeft: HANDLE_MARGIN,
          carouselRefMarginRight: HANDLE_MARGIN,
        },
        this.initActions
      );
      return;
    }

    this.initActions();
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.resizeHandler);
  }

  initActions = () => {
    window.addEventListener('resize', this.resizeHandler);
    this.updateScrollContainer();
  };

  resizeEnded = () => {
    // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
    if (new Date() - this.lastResizeTime < this.delta) {
      setTimeout(this.resizeEnded, this.delta);
      return;
    }

    this.timeout = false;
    this.updateScrollContainer();
  };

  resizeHandler = () => {
    this.lastResizeTime = new Date();

    if (this.timeout === false) {
      this.timeout = true;
      setTimeout(this.resizeEnded, this.delta);
    }
  };

  get hasOverflow () {
    const ref = this.carouselRef.current;
    if (!ref) return false;
    return ref.scrollWidth > ref.offsetWidth;
  }

  get currentScrollOffset () {
    return this.carouselRefContainer.current.scrollLeft;
  }

  get leftRefValues () {
    const { carouselRefMarginLeft, refs, marginLeft } = this.state;
    return refs
      .map((ref: any) => ref.parentElement.offsetLeft - marginLeft - carouselRefMarginLeft)
      .filter((val: any) => val >= 0);
  }

  get currentIndex () {
    const nextIdx = this.leftRefValues.findIndex((val: any, idx: number, arr: any) => {
      const currentScroll = this.currentScrollOffset;
      return val > currentScroll && currentScroll < arr[idx + 1];
    });

    return nextIdx - 1;
  }

  get prevItemIdx () {
    const { scrollCount = 1 } = this.props;
    return this.currentIndex - scrollCount;
  }

  get nextItemIdx () {
    const { scrollCount = 1 } = this.props;
    return this.currentIndex + scrollCount;
  }

  scrollTo = (val: any) => {
    const ref = this.carouselRefContainer.current;
    if (!ref) return;
    ref.scrollLeft = val;
  };

  scroll = (dir: string) => {
    const idx = dir === 'next' ? this.nextItemIdx : this.prevItemIdx;
    this.scrollTo(this.leftRefValues[idx]);
  };

  scrollLeft = () => this.scroll('prev');
  scrollRight = () => this.scroll('next');

  get items () {
    const { items = [] } = this.props;

    return items.map((item: any) => {
      const styles = (item.props && item.props.cardStyles) || {};

      return React.cloneElement(item, {
        cardStyles: { display: 'inline-block', marginRight: '10px', ...styles },
      });
    });
  }

  updateScrollContainer = () => {
    const BUFFER = 2;
    const ref = this.carouselRefContainer.current;

    if (!ref) return;

    this.setState({
      canNavLeft: ref.scrollLeft > BUFFER,
      canNavRight: Math.floor(ref.scrollLeft + ref.offsetWidth) < Math.floor(ref.scrollWidth) - BUFFER,
    });
  };

  get centerIcon () {
    const carousel = this.carouselRef.current;
    const iconSize = this.iconStyles;

    if (!carousel) return '';
    return carousel.offsetHeight / 2 - iconSize.fontSize / 2;
  }

  get iconStyles () {
    return { fontSize: 45 };
  }

  render () {
    const { className = '', showCarouselHandles } = this.props;
    const mainClass = 'hy-carousel ' + className;
    const items = this.items;
    const { marginLeft, carouselRefMarginLeft, carouselRefMarginRight, canNavLeft, canNavRight } = this.state;
    const margins = { marginLeft, marginRight: carouselRefMarginRight };
    const styles = { zIndex: 7, position: 'absolute', top: this.centerIcon };
    const iconStyles = this.iconStyles;

    if (!items || !items.length) return null;

    return (
      <div className='hy-carousel-container' style={{ position: 'relative' }}>
        {showCarouselHandles && this.hasOverflow && (
          <>
            <div onClick={this.scrollLeft} style={{ ...styles, left: 0 } as React.CSSProperties}>
              <Icon
                style={iconStyles}
                disabled={!canNavLeft}
                size='huge'
                className='carousel-handle'
                name='arrow alternate circle left'
              />
            </div>
            <div onClick={this.scrollRight} style={{ ...styles, right: 0 } as React.CSSProperties}>
              <Icon
                style={iconStyles}
                disabled={!canNavRight}
                size='huge'
                className='carousel-handle'
                name='arrow alternate circle right'
              />
            </div>
          </>
        )}
        <div
          ref={this.carouselRefContainer}
          onScroll={this.updateScrollContainer}
          style={{ ...margins, overflow: 'scroll', scrollBehavior: 'smooth', padding: '10px 20px' }}
        >
          <div
            className={mainClass}
            ref={this.carouselRef}
            style={{ whiteSpace: 'nowrap', marginTop: '1px', marginLeft: carouselRefMarginLeft }}
          >
            {items}
          </div>
        </div>
      </div>
    );
  }
}

export { Carousel };
