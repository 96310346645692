/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import './index.scss';
import { Button, Header } from '../../hapyak-ui-toolkit';
import { Link } from '../Link';

class PageNotFound extends React.Component {
  render () {
    return (
      <div className='hy-absolute' style={{ marginTop: '100px', textAlign: 'center' }}>
        <div className='hy-content'>
          <Header as='h1'>Looks like you're lost</Header>
          {/* @ts-expect-error TS(2322): Type '{ children: Element; to: string; query: stri... Remove this comment to see the full error message */}
          <Link to='/' query={window.location.search}>
            <Button>Go Home</Button>
          </Link>
        </div>
      </div>
    );
  }
}

export { PageNotFound };
