import React, { Component } from 'react';

import _ from 'lodash';

import { Table, Grid, Button, Icon } from '../../../hapyak-ui-toolkit';
import { formatComboString } from '../../../services/lexileService';
import { media } from '../../../services/mediaController';
import tabNames from '../TranslationServices/tabs.json';

const EDITOR_TAB_INDEX = tabNames.tabs.indexOf('review');

type ProcessRowProps = {
  allAvailableCombos: any[];
  canEdit: boolean;
  customSummary?: any;
  disableReview?: boolean;
  edit?: boolean;
  fileExt: any;
  handleClick: (obj: any) => void;
  handleUploadClick: (obj: any) => void;
  processes: any;
  processFiles?: any[];
  processType: any;
  upload?: boolean;
};

export class ProcessRow extends Component<ProcessRowProps> {
  get assetsForProcess () {
    const { processFiles, processType } = this.props;
    return processFiles ? processFiles.filter((file: any) => file.type === processType) : [];
  }

  get hasAssets () {
    return !!this.assetsForProcess.length;
  }

  get sortedByLanguage () {
    return this.assetsForProcess.sort((a: any, b: any) => {
      const keyA = formatComboString(a.language, a.lexileLevel);
      const keyB = formatComboString(b.language, b.lexileLevel);

      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  }

  get processLexileLevels () {
    return _.uniq(this.assetsForProcess.map((a: any) => a.lexileLevel).filter(Boolean));
  }

  get missingLexileLevels () {
    // if en_MAX, en_620L, fr_MAX exist, fr_620L should also exist
    const lexileLevelByLang = this.assetsForProcess.reduce((levelByLang: any, cur: any) => {
      const levels = levelByLang[cur.language] || [];
      levelByLang[cur.language] = _.uniq([...levels, cur.lexileLevel]);
      return levelByLang;
    }, {});

    const missingLevels = this.processLanguages.reduce((missing, language) => {
      const diff = _.difference(this.processLexileLevels, lexileLevelByLang[language]);
      const missingCombos = diff.map((level) => formatComboString(language, level));
      return _.uniq([...missing, ...missingCombos]);
    }, []);

    return (missingLevels as any).length ? missingLevels : null;
  }

  get processLanguages () {
    return _.uniq(this.assetsForProcess.map((a: any) => a.language));
  }

  get allLanguages () {
    const { allAvailableCombos = [] } = this.props;
    return _.uniq(allAvailableCombos.map((c: any) => c.language));
  }

  get missingLanguages () {
    const diff = _.difference(this.allLanguages, this.processLanguages);
    return diff.length ? diff : null;
  }

  onClick = ({
    language,
    lexileLevel
  }: any) => {
    const { handleClick, processType } = this.props;
    if (handleClick) {
      handleClick({
        lexileLevel,
        selectedLanguage: language,
        selectedType: processType,
        tabIndex: EDITOR_TAB_INDEX,
      });
    }
  };

  resultAsLink = (file: any) => {
    const { disableReview } = this.props;
    const content = formatComboString(file.language, file.lexileLevel);
    return (
      <Button key={file.id} onClick={this.onClick.bind(this, file)} content={content} disabled={disableReview} />
    );
  };

  get showContent () {
    const { customSummary } = this.props;
    const fileDisplays = this.sortedByLanguage.map(this.resultAsLink);

    return (customSummary || (
      <Grid>
        <Grid.Row>
          <Grid.Column>{fileDisplays}</Grid.Column>
        </Grid.Row>
        {this.missingLexileLevels && (
          <Grid.Row>
            <Grid.Column>
              {this.showWarning(`missing lexile levels: ${(this as any).missingLexileLevels.join(', ')}`)}
            </Grid.Column>
          </Grid.Row>
        )}
        {this.missingLanguages && (
          <Grid.Row>
            <Grid.Column>
              {this.showWarning(`missing languages: ${this.missingLanguages.join(', ')}`)}
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    ));
  }

  showWarning = (content: any) => {
    return (
      <div>
        <Icon name='warning sign' /> {content}
      </div>
    );
  };

  get summary () {
    const { customSummary } = this.props;
    return this.hasAssets || customSummary ? this.showContent : this.showWarning('No file attached');
  }

  handleUploadClick () {
    const { handleUploadClick, processes = {}, processType = '', fileExt, processFiles } = this.props;
    handleUploadClick && handleUploadClick({ processes, processType, fileExt, processFiles });
  }

  onEditorClick = () => {
    const { handleClick, processType } = this.props;
    if (handleClick) {
      handleClick({
        selectedLanguage: media.video.language,
        selectedType: processType,
        tabIndex: EDITOR_TAB_INDEX,
      });
    }
  };

  render () {
    const { processType, upload = true, canEdit, disableReview } = this.props;

    return (
      <Table.Row>
        <Table.Cell verticalAlign='middle'>{processType}</Table.Cell>
        <Table.Cell verticalAlign='middle'>{this.summary}</Table.Cell>
        <Table.Cell>
          <Grid>
            <Grid.Row columns='equal'>
              <Grid.Column>
                {upload && (
                  <Button
                    disabled={!canEdit}
                    icon='exchange'
                    onClick={this.handleUploadClick.bind(this)}
                  />
                )}
              </Grid.Column>
              <Grid.Column>
                {!disableReview && upload && (
                  <Button disabled={!canEdit} icon='pencil' onClick={this.onEditorClick} />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Table.Cell>
      </Table.Row>
    );
  }
}
