import React from 'react';

import './index.scss';

import { Button } from '@newsela/angelou';

import { Dropdown, Header, Modal } from '../../hapyak-ui-toolkit';
import sessionClient from '../../services/sessionClient';

type State = any;

type ChooseGroupModalProps = {
  handleClose?: () => void;
  modalTitle?: string;
};

class ChooseGroupModal extends React.Component<ChooseGroupModalProps, State> {
  constructor (props: ChooseGroupModalProps) {
    super(props);
    this.state = {
      currentGroup: null,
      chosenGroup: null,
      canChooseGroup: false,
      groupMemberships: [],
    };

    this.getGroupInfo();
  }

  getGroupInfo = async () => {
    const currentGroup = sessionClient.getCurrentGroup() || null;
    const groupMemberships = (await sessionClient.getGroupMemberships()) || [];
    const memberships =
            Object.keys(groupMemberships).length &&
            Object.entries(groupMemberships).map((group) => {
              const [, value] = group;
              return {
                key: (value as any).id,
                text: (value as any).name,
                value: (value as any).id,
              };
            });
    const belongsToOne = (memberships as any).length <= 1;

    if (belongsToOne) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const { value } = memberships[0];

      if (value) {
        this.setState({ chosenGroup: value }, () => {
          this.handleConfirm();
        });
      }
      return;
    }

    this.setState({
      groupMemberships: memberships,
      canChooseGroup: !belongsToOne,
      currentGroup: currentGroup,
    });
  };

  handleClose = () => {
    const { handleClose } = this.props;

    if (handleClose) handleClose();
  };

  handleConfirm = async () => {
    const { chosenGroup } = this.state;

    if (chosenGroup) {
      await sessionClient.persistGroupChange(chosenGroup);
      window.location.reload();
    }
  };

  setChosenGroup = (evt: any, data: any) => this.setState({ chosenGroup: data.value });

  render () {
    const { modalTitle = '' } = this.props;
    const { chosenGroup, canChooseGroup, currentGroup, groupMemberships } = this.state;
    const { id } = currentGroup || {};
    const { text } = (id && groupMemberships && groupMemberships.find((group: any) => group.key === id)) || {};
    const secondaryHeader = modalTitle || (text ? `Current Group: ${text}` : 'Select your group.');

    return (
      <div>
        <Modal open size='tiny' className='hy-user-modal'>
          <Modal.Header>Group Options</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Header>{secondaryHeader}</Header>
              {canChooseGroup && (
                <>
                  <div className='messaging'>
                    <p>We found the following groups that you belong to.</p>
                    <p>You can set your current group in the dropdown below.</p>
                  </div>
                  <Dropdown
                    placeholder='Select Group...'
                    fluid
                    selection
                    onChange={this.setChosenGroup}
                    options={groupMemberships}
                  />
                </>
              )}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              legacy_flavor={Button.legacy_flavor.solid}
              legacy_size={Button.legacy_size.medium}
              legacy_statusColor={Button.legacy_statusColor.tertiary}
              onClick={sessionClient.logout}
              __classNameFor={{ root: 'tertiary hollow' }}
            >
              Logout
            </Button>
            <Button
              legacy_flavor={Button.legacy_flavor.hollow}
              legacy_size={Button.legacy_size.medium}
              legacy_statusColor={Button.legacy_statusColor.secondary}
              onClick={this.handleClose}
              disabled={!id}
              __cssFor={{
                root: {
                  marginLeft: '1rem'
                }
              }}
            >
              Cancel
            </Button>
            <Button
              legacy_flavor={Button.legacy_flavor.solid}
              legacy_size={Button.legacy_size.medium}
              legacy_statusColor={Button.legacy_statusColor.primary}
              onClick={this.handleConfirm}
              disabled={chosenGroup === null}
              __cssFor={{
                root: {
                  marginLeft: '1rem'
                }
              }}
            >
              Done
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export { ChooseGroupModal };
