class DragController {
  activeDraggable = null;
  activeDropZone = null;
  draggables = {};

  addDraggable = (id: any, draggable: any) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    this.draggables[id] = draggable;
  };

  removeDraggable = (id: any) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    delete this.draggables[id];
  };

  isNotAllowed = () => {
    const { activeDraggable, activeDropZone } = this;

    if (!activeDraggable || !activeDropZone) {
      return `Not allowed. Draggable: ${activeDraggable} / DropZone: ${activeDropZone}`;
    }

    if ((activeDraggable as any).props.allowedZones === '*') {
      return;
    }

    const { allowedZones } = (activeDraggable as any).props;
    const { id } = (activeDropZone as any).props;
    const allowed = allowedZones.includes(id);

    if (allowed) return;

    return `DropZone ${id} not in allowed zones: ${allowedZones}`;
  };

  startDragging = (draggableId: any) => {
    this.activeDraggable = draggableId;
  };

  stopDragging = () => {
    this.activeDraggable = null;
    this.activeDropZone = null;
  };

  setActiveDropZone = (dropZone: any) => {
    this.activeDropZone = dropZone;
    return Promise.resolve(!this.isNotAllowed());
  };

  drop = (styles: any) => {
    const err = this.isNotAllowed();

    if (err) {
      this.activeDraggable && (this.activeDraggable as any).props.onDragEnd();
      this.stopDragging();
      return;
    }

    let options = {};
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    const dropZoneId = this.activeDropZone.props.id;
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    const { id, tool } = this.activeDraggable.props;

    if (tool) {
      options = { fromToolbox: true };
    } else if (styles) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      options = { el: this.activeDropZone.reference.current, styles };
    }

    // @ts-expect-error TS(2531): Object is possibly 'null'.
    this.activeDraggable.props.onDragEnd(dropZoneId, id, options);
    this.stopDragging();
  };
}

const instance = new DragController();

export default instance;
