import './ErrorBanner.scss';

import React, { useState } from 'react';

import { Banner, Button } from '@newsela/angelou';

type ErrorBannerProps = {
  title?: string;
  body: string | (string | JSX.Element)[];
  isDismissible? : boolean;
  onClose?: () => void;
  error?: any;
  validationError?: boolean;
};

const ErrorBanner = ({
  title,
  body,
  isDismissible,
  onClose,
  error,
  validationError
}: ErrorBannerProps) => {
  const [errorReportButtonClicked, setErrorReportButtonClicked] = useState(false);
  const copyToClipBoard = () => {
    setErrorReportButtonClicked(true);
    const supportInfo = `Error report:
    url: ${location.href}
    ${error?.message ? 'error: ' + error?.message : ''}
    ${error?.traceId ? 'traceId: ' + error?.traceId : ''}
`;
    navigator.clipboard.writeText(supportInfo);
  };

  return (
    <Banner
      bannerType={Banner.types.error}
      title={title || 'Error'}
      __renderBody={() => {
        return (
          <div className='error-content'>
            <p>{body}</p>
            <p className={(validationError ? 'hidden-style' : '')}>
              For help, click the “Copy error report” button and paste in the Slack channel #content-production.
            </p>
            <div className='error-report-container'>
              <Button
                legacy_flavor={Button.legacy_flavor.hollow}
                legacy_statusColor={Button.legacy_statusColor.tertiary}
                legacy_size={Button.legacy_size.small}
                onClick={() => copyToClipBoard()}
                __cssFor={{
                  root: {
                    display: (validationError ? 'none' : 'inline-block'),
                    marginTop: '16px'
                  }
                }}
              >
                Copy error report
              </Button>
              <p role='alert' className={(errorReportButtonClicked ? '' : 'hidden-style')}>Copied!</p>
            </div>
          </div>
        );
      }}
      isDismissible={isDismissible}
      onClose={onClose}
      __classNameFor={{ root: 'error-banner' }}
      __cssFor={{
        root: { '--banner-innerContent-paddingRight': 0 },
        cta: {
          display: (validationError ? 'none' : 'inline-block')
        }
      }}
    />
  );
};

export default ErrorBanner;
