import { debounce } from 'lodash';

import { AssetService } from './assetService';
import { comm } from './comm';
import { gaConfig, ReactGA, initGA } from './gaConfig';
import { Historian } from './historian';
import logger from './logger';
import { persistenceClientFactory } from './persistence/persistenceClientFactory';
import { exposeGlobal } from './scope';
import signingService from './signingService';
import { hasRemoteState, retrieveRemoteJSON } from './stateUtils';

const DEBOUNCE_TIME = 125;

class StateController {
  _reloadPlayer: any;
  assetService: any;
  historian: any;
  routeName: any;
  // singleton used to communicate across components
  constructor () {
    this.routeName = null;
    this.assetService = new AssetService(
      this.getCurrentData,
      this.updateProject,
      this.updateProjectExtemporaneously,
      signingService
    );

    gaConfig.initGA && gaConfig.Config.id && initGA();
    this._reloadPlayer = debounce(() => comm.trigger('reloadPlayer'), DEBOUNCE_TIME);
  }

  setInitialState = () => {
    this.historian?.reset();
    this.routeName = null;
  };

  setupHistorian = () => {
    this.historian = new Historian();
  };

  // only used for pdf generation
  loadRemoteState = async () => {
    logger.log('Using state from remotestate url', hasRemoteState);
    const foundState = await retrieveRemoteJSON();

    return this.setCurrentData(foundState).then(() => {
      comm.trigger('updateQueryString');
      comm.trigger('updateAppState');
      return Promise.resolve();
    });
  };

  setRouteName = (routeName: any) => {
    if (this.routeName !== routeName) {
      this.routeName = routeName;
      ReactGA && ReactGA.pageview(routeName);
    }
  };

  getCurrentData = (path?: any) => {
    const data = this.historian?.getCurrentEntry();
    return (data && path ? data[path] : data) || {};
  };

  getCurrentDataAsJSON = () => {
    return JSON.stringify(this.historian.getCurrentEntry());
  };

  resetData = () => {
    this.setInitialState();
    comm.trigger('updateAppState');
  };

  setCurrentData = (data: any) => {
    return this.setProject(data);
  };

  setProject = (data: any) => {
    this.historian.replaceCurrentEntry({ ...data, _routeName: this.routeName });
    this.saveProject();
    return Promise.resolve(data);
  };

  setData = (data: any, key: any, changes: any) => {
    return {
      ...data,
      [key]: {
        ...data[key],
        ...changes,
      },
    };
  };

  updateProject = (key: any, changes: any, merge?: boolean) => {
    let data = this.getCurrentData();

    // If testing data object will be empty
    if (Object.keys(data).length === 0) {
      return Promise.resolve();
    }

    // If the route changed, add an extra duplicate entry to make undo/redo more seamless between routes
    if (!merge && data._routeName !== this.routeName) {
      data._routeName = this.routeName;
      this.historian.newEntry(data);
    }

    data = this.setData(data, key, changes);

    if (merge) {
      this.historian.replaceCurrentEntry(data);
    } else {
      this.historian.newEntry(data);
    }

    this.saveProject();
    return Promise.resolve();
  };

  // updateProjectExtemporaneously updates all entries in history, so that the operation cannot be undone
  updateProjectExtemporaneously = (key: any, changes: any) => {
    this.historian.updateAllEntries(key, changes);
    this.updateProject(key, changes); // be sure to add extra entry with _routeName
  };

  saveProject = () => {
    persistenceClientFactory.getClient().update();
    comm.trigger('updateAppState', true);
    this._reloadPlayer();
  };

  undo = () => {
    this.historian.undo();
    this.saveProject();
  };

  redo = () => {
    this.historian.redo();
    this.saveProject();
  };
}

const stateController = new StateController();
const assetService = stateController.assetService;

exposeGlobal('setCurrentData', stateController.setCurrentData);
exposeGlobal('getCurrentData', stateController.getCurrentData);
exposeGlobal('getCurrentDataAsJSON', stateController.getCurrentDataAsJSON);

export { assetService, stateController };
