import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';

import { Annotation } from '../../types/annotations';
import logger from '../logger';
import sessionClient from '../sessionClient';
import signingService from '../signingService';
import { assetService, stateController } from '../stateController';
import { resolvePlatformHost } from '../utils';

const RECONNECT_TIME = 10000; // ms
const DEFAULT_LEVEL = 'MAX';

const createDataservicesPayload = () => {
  const payload = {
    operations: [
      {
        name: 'powerwords',
      },
    ],
  };

  logger.log('payload:', payload);

  return payload;
};

const makeSrcList = (arbMetadatas = {}) => {
  return Object.keys(arbMetadatas).reduce((srcList, key) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (arbMetadatas[key]?.src) {
      // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
      const signedSrc = signingService.sign(arbMetadatas[key].src);
      // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      srcList.push(signedSrc);
    }
    return srcList;
  }, []);
};

const processSrcList = async (srcList: any) => {
  const projectId = stateController.getCurrentData().project.id;
  const outList = [];
  for await (const src of srcList) {
    const { data }: AxiosResponse = await axios({
      url: src,
      method: 'get',
    });
    const scope = data['@@scope'];

    const url = `./powerwords/powerwords-${projectId}-${scope.language}-${scope.level}.arb`;
    outList.push(url);
  }
  return outList;
};

export const collectPowerWordsArb = async (currentState: any, list: any) => {
  const projectId = stateController.getCurrentData().project.id;
  // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
  const arbs = _.clone(currentState.assets.arb, true);
  const arbKeys = Object.keys(arbs);
  let srcList: any = [];
  const absoluteSrcList: any = [];

  arbKeys.forEach((key) => {
    if (
      (arbs[key].type === 'Annotation Text' || arbs[key].type === 'Video Text') &&
            arbs[key].lexileLevel === 'MAX'
    ) {
      delete arbs[key];
    }
  });

  const annotations = currentState.ux.annotations;
  const powerWordsAnnotations = annotations.filter((annotation: Annotation) => annotation.type === 'powerwords');
  if (Object.keys(arbs).length === 0 && powerWordsAnnotations.length) {
    powerWordsAnnotations.forEach((annotation: Annotation, idx: number) => {
      arbs[`inline_${idx}`] = {
        content: annotation.content,
        level: DEFAULT_LEVEL,
      };
    });
    srcList = [`./powerwords/powerwords-${projectId}-en-MAX.arb`];
  }

  if (srcList.length === 0) {
    srcList = await processSrcList(makeSrcList(arbs));
  }

  if (list) {
    let urlRoot = '';
    const asset = list.find((l: any) => l?.src?.includes('overrides.json'));

    urlRoot = asset.src.split('metadata')[0];

    // @ts-expect-error TS(7006): Parameter 'src' implicitly has an 'any' type.
    srcList.forEach((src) => {
      absoluteSrcList.push({
        src: src.replace('./', urlRoot),
        dest: src,
      });
    });
  }

  return {
    arbList: arbs,
    srcList: absoluteSrcList,
  };
};

export const collectPowerWordsArbPUBLISHER = async (currentState: any, list: any) => {
  const projectId = stateController.getCurrentData().project.id;
  // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
  const arbs = _.clone(currentState.assets.arb, true);
  const arbKeys = Object.keys(arbs);
  let srcList = [];
  const absoluteSrcList: any = [];

  arbKeys.forEach((key) => {
    if (
      (arbs[key].type === 'Annotation Text' || arbs[key].type === 'Video Text') &&
            arbs[key].lexileLevel === 'MAX'
    ) {
      delete arbs[key];
    }
  });

  const annotations = currentState.ux.annotations;
  const powerWordsAnnotations = annotations.filter((annotation: Annotation) => annotation.type === 'powerwords');

  // if (Object.keys(arbs).length == 0) {
  powerWordsAnnotations.forEach((annotation: Annotation, idx: number) => {
    arbs[`inline_${idx}`] = {
      content: annotation.content,
      level: DEFAULT_LEVEL,
    };
  });
  srcList = [`./powerwords/powerwords-${projectId}-en-MAX.arb`];
  // }

  if (srcList.length === 0) {
    srcList = await processSrcList(makeSrcList(arbs));
  }

  if (list) {
    let urlRoot = '';
    const asset = list.find((l: any) => l?.src?.includes('overrides.json'));

    urlRoot = asset.src.split('metadata')[0];

    srcList.forEach((src) => {
      absoluteSrcList.push({
        src: src.replace('./', urlRoot),
        dest: src,
      });
    });
  }

  return {
    arbList: arbs,
    srcList: absoluteSrcList,
  };
};

const packageForPowerWords = async (token: any, payload: any, transaction = {}) => {
  return new Promise(async (resolve, reject) => {
    const platformHost = resolvePlatformHost();

    try {
      const response = await axios({
        url: `${platformHost}/back-end/jobs`,
        method: 'post',
        withCredentials: true,
        data: {
          command: 'jobType:StepFunction:PowerWords',
          data: payload,
          transaction,
        },
        headers: {
          Authorization: `Bearer ${token.token}`,
        },
      });

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response);
      }
    } catch (err) {
      reject(err);
    }
  });
};

const createPowerWordsPayload = async (arblist: any, projectId: any) => {
  return {
    arblist,
    projectId,
  };
};

export class PowerWordsPublisher {
  saveBundleWithoutStatusUrl: any;
  transactionData: any;
  _publish = async (arblist: any, projectId: any) => {
    const token = await sessionClient.getServiceAccessToken(createDataservicesPayload());
    const input = await createPowerWordsPayload(arblist, projectId);
    return packageForPowerWords(token, input, this.transactionData);
  };

  _reconnect = async () => {
    const bundle = assetService.getCurrentBundle();
    const statusUrl = bundle.statusUrl;

    if (!statusUrl) {
      return;
    }

    return axios.get(signingService.sign(statusUrl)).then(({ data = null }) => {
      if (data && data.status === 'SUCCESS') {
        return data.data;
      } else if (!data || data.error || data.status === 'FAILURE') {
        throw new Error(data.error || 'Unknown error occurred getting bundle');
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(this._reconnect());
        }, RECONNECT_TIME);
      });
    });
  };

  _stop = () => {
    const bundle = assetService.getCurrentBundle();
    if (bundle.statusUrl) {
      this.saveBundleWithoutStatusUrl(bundle);
    }
    return Promise.resolve();
  };
}
