import React from 'react';

import './index.scss';
import { Button, Modal, Placeholder, Section, TinyMCEEditor } from '../../../hapyak-ui-toolkit';
import { assetService } from '../../../services/stateController';
import { DeviceWrapper } from '../../PreviewSection/DeviceWrapper';
import { ImagePicker } from '../CommonComponents/ImagePicker';
import { getPdfSettings } from './pageSettings';

type State = any;

type EditModalProps = {
  data: any;
  save: (obj: any) => void;
  close: () => void;
  delete: (e: any) => void;
}

class EditModal extends React.Component<EditModalProps, State> {
  constructor (props: EditModalProps) {
    super(props);
    const data = this.props.data || {};
    this.state = { ...data, loaded: false };
  }

  close = () => {
    this.props.close();
  };

  handleSave = () => {
    // noinspection JSUnusedLocalSymbols // IMPORTANT: jshint/pycharm inspection intentionally suppressed
    const { loaded, ...data } = this.state; this.props.save(data);
    this.close();
  };

  handleEditorChange = (content: any) => {
    this.setState({ description: content });
  };

  setImage = (image: any) => {
    this.setState({ image: image.id });
  };

  get imageWidth () {
    const { width, margin } = getPdfSettings(1);
    return width - 2 * margin;
  }

  get imageSectionStyles () {
    const { imageMargin } = getPdfSettings(1, true);
    return {
      backgroundColor: '#eee',
      width: this.imageWidth + 'in',
      padding: imageMargin,
      marginBottom: '20px',
    };
  }

  get placeholderStyles () {
    const height = (this.imageWidth * 9) / 16 + 'in';
    return {
      ...this.imageStyles,
      height,
      maxWidth: 'none',
    };
  }

  get imageStyles () {
    return {
      display: 'block',
      height: '100%',
      width: '100%',
    };
  }

  onImageLoad = () => {
    this.setState({ loaded: true });
  };

  render () {
    const { description, image, device, portrait, loaded } = this.state;
    const imageObject = assetService.getImage(image);
    const imageSrc = imageObject.src;
    const sectionSize = 'h5';

    return (
      <Modal open onClose={this.close} dimmer='inverted' size='large'>
        <Modal.Header>Edit Storyboard</Modal.Header>
        <Modal.Content scrolling>
          <Section title='Image' as={sectionSize}>
            <div style={this.imageSectionStyles}>
              {!loaded && (
                <Placeholder style={this.placeholderStyles}>
                  <Placeholder.Image />
                </Placeholder>)}
              <DeviceWrapper device={device} portrait={portrait} downscale={1}>
                <img src={imageSrc} alt='' style={this.imageStyles} onLoad={this.onImageLoad} />
              </DeviceWrapper>
            </div>
            <ImagePicker label='Page Image' image={imageObject} setImage={this.setImage} />
          </Section>
          <Section title='Content' as={sectionSize} style={{ width: '8.6in' }}>
            <TinyMCEEditor value={description} onEditorChange={this.handleEditorChange} editorConfigType='advanced' />
          </Section>
        </Modal.Content>
        <Modal.Actions>
          <Button negative icon='trash' size='small' onClick={this.props.delete} />
          <Button content='Cancel' size='small' onClick={this.close} />
          <Button primary content='Done' size='small' onClick={this.handleSave} />
        </Modal.Actions>
      </Modal>
    );
  }
}

export { EditModal };
