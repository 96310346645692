import '../index.scss';

import React, { useState } from 'react';

import { Button, PlusSVG, TextArea } from '@newsela/angelou';
import _ from 'lodash';

import { assetService } from '../../../../../../services/stateController';
import { updateAnnotation } from '../../../../../../services/toolService';
import { Annotation, CreateAnnotationResponse } from '../../../../../../types/annotations';
import { QuestionType } from '../../../../../../types/questions';
import {
  QuizAnswerInput,
  QuizAnswerInputField,
  QuizAnswerInputsByAnnotationId
} from '../../../../../../types/quizUtilityTypes';
import { AngelouInput } from '../../../../AngelouComponents/AngelouInput';
import { ImagePicker } from '../../../../CommonComponents/ImagePicker';
import { createOptionLabelByQuestionType, MAX_CHARACTER_INPUT_LENGTH } from '../questionService';
import { addSanitizedKeyValuePair } from '../quizBuilderUtils';
import { QuizAnswerCorrectCheckButton } from './QuizAnswerCorrectCheckButton';
import { QuizAnswerRowOptionsPopOut } from './QuizAnswerRowOptionsPopOut';

const REMOVE_EMPTY_STRINGS_AND_DIGITS = /\s?\d+|^\s+|\s+$\s/g;
const REMOVE_EMPTY_STRINGS = /\s/g;

type QuizAnswerTableProps = {
  addOrRemoveDisabled?: boolean;
  annotations: Annotation[];
  answerIds: string[];
  createAnnotationWithDefaults?: () => CreateAnnotationResponse;
  inputValueType: 'text' | 'number' | '';
  items: QuizAnswerInputsByAnnotationId;
  nonEditableProperties?: string[];
  onAdd?: () => void;
  onDelete: (answerId: string, annotations: Annotation[]) => void;
  onSecondaryAdd?: () => void;
  persistChange?: (sanValues: any) => void;
  questionType: QuestionType;
  removeEnabled: boolean;
  requiredProperties?: string[];
  secondaryAddContent?: string | null;
};

export const QuizAnswerTable = ({
  addOrRemoveDisabled,
  annotations,
  answerIds,
  createAnnotationWithDefaults,
  inputValueType,
  items,
  nonEditableProperties,
  onAdd,
  onDelete,
  onSecondaryAdd,
  persistChange,
  questionType,
  removeEnabled,
  requiredProperties,
  secondaryAddContent
}: QuizAnswerTableProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [itemsInState, setItemsInState] = useState<QuizAnswerInputsByAnnotationId>({});

  const formatFields = (items: QuizAnswerInputsByAnnotationId) => {
    const keys = Object.keys(items);
    if (!keys.length) return [];

    return keys.map((key) => {
      return {
        key: key,
        value: items[key],
      };
    });
  };

  const getFields = () => {
    const quizAnswersByAnnotationId = isEditing ? itemsInState : items;
    return formatFields(quizAnswersByAnnotationId);
  };

  const getAnswerRows = () => {
    const answerInputs = getFields().map((field, idx) => createAnswerRow(field, idx));
    return questionType === 'linearScale'
      ? <div className='min-max-input-row'>{answerInputs}</div>
      : answerInputs;
  };

  const assessValue = (value: string | number, key: string) => {
    if (
      typeof value === 'string' && !value.replace(REMOVE_EMPTY_STRINGS, '') &&
      requiredProperties && requiredProperties.includes(key) &&
      createAnnotationWithDefaults
    ) {
      const { annotation } = createAnnotationWithDefaults();
      const val = annotation.properties[key];
      value = val ? val.replace(REMOVE_EMPTY_STRINGS_AND_DIGITS, '') : '';
    }

    return value;
  };

  const assessMinMaxValues = (id: string, value: string | number) => {
    const [minId, maxId] = answerIds;
    const getValue = (id: string) => +_.get(this, `state.items[${id}].inputs[1].value`, '');

    value = +value < 0 ? 0 : +value;

    if (id === minId) {
      const maxValue = getValue(maxId);
      if (+maxValue <= value) {
        const tempMin = +maxValue - 1;
        value = tempMin < 0 ? 0 : tempMin;
      }
    } else {
      const minValue = getValue(minId);
      if (+minValue >= value) {
        value = +minValue + 1;
      }
    }

    return value;
  };

  const mergeChanges = (annotationId: string, value: string | number, property: string) => {
    const fields = getFields();
    const values = [...fields];
    const targetData = values.find((field) => field.key === annotationId);
    const rowInputs = targetData && targetData.value.inputs;
    const targetRow = rowInputs && rowInputs.find((input: QuizAnswerInput) => input.fieldKey === property);

    if (targetRow) {
      targetRow.value = String(value);
    }

    return values;
  };

  const setFields = (values = [] as QuizAnswerInputField[], persist = false) => {
    const sanitizedValues = values.reduce(addSanitizedKeyValuePair, {});

    if (isEditing) {
      setItemsInState(sanitizedValues);
    }

    if (persist && persistChange) {
      setItemsInState(sanitizedValues);
      persistChange(sanitizedValues);
    }
  };

  const setImage = (property: string, annotationId: string, imageId: string) => {
    const values = mergeChanges(annotationId, imageId, property);
    setFields(values, true);
  };

  const handleChange = (id: string, fieldKey: string, value: string | number) => {
    const values = mergeChanges(id, value, fieldKey);
    setFields(values, false);
  };

  const handleBlur = (id: string, fieldKey: string, inputValue: string) => {
    let value = assessValue(inputValue, fieldKey);

    if (questionType === 'linearScale' && fieldKey === 'option') {
      value = assessMinMaxValues(id, value);
    }

    setIsEditing(false);

    const updatedValues = mergeChanges(id, String(value), fieldKey);
    setFields(updatedValues, true);
  };

  const onInputFocus = () => {
    setIsEditing(true);
    setItemsInState(items);
  };

  const handleCorrectAnswerCheckButtonChange = (annotation: Annotation, correct: boolean) => {
    updateAnnotation(annotation.id, { properties: { ...annotation.properties, correct: correct } });
  };

  const createCorrectAnswerCheckButton = (id: string) => {
    const foundAnnotation = annotations.find((a: Annotation) => a.id === id);
    return foundAnnotation
      ? <QuizAnswerCorrectCheckButton annotation={foundAnnotation} onChange={handleCorrectAnswerCheckButtonChange} />
      : null;
  };

  const createImagePickerButton = (value: any, key: string, annotationId: string, fieldKey: string) => {
    return (
      <div key={key}>
        <Button
          ariaProps={{ 'aria-label': 'upload image' }}
          legacy_flavor={Button.legacy_flavor.incognito}
        >
          <ImagePicker
            messageAsIcon
            triggerAsLink
            label={false}
            image={assetService.getImage(value)}
            setImage={(image) => setImage(fieldKey, annotationId, image.id)}
          />
        </Button>
      </div>
    );
  };

  const createNumberInput = (
    value: string,
    key: string,
    answerId: string,
    fieldKey: string,
    placeholder: string,
    label: string
  ) => {
    return (
      <div key={key} className={fieldKey === 'value' ? 'do-not-display' : 'quiz-answer-row-number-input'}>
        <AngelouInput
          min={fieldKey === 'min' ? 0 : 10}
          label={label}
          value={value}
          onFocus={onInputFocus}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            handleBlur(answerId, fieldKey, e.target.value);
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(answerId, fieldKey, e.target.value);
          }}
          maxLength={MAX_CHARACTER_INPUT_LENGTH}
          placeholder={placeholder}
          inputType='number'
          css={{
            root: {
              maxWidth: '112px',
              display: fieldKey === 'value' ? 'none' : ''
            }
          }}
        />
      </div>
    );
  };

  const createTextAreaInput = (
    value: string,
    key: string,
    answerId: string,
    fieldKey: string,
    placeholder: string,
    label: string
  ) => {
    // const type = fieldKey === 'value' ? inputValueType : 'text';

    return (
      <div key={key} className={fieldKey === 'value' ? 'do-not-display' : ''}>
        <TextArea
          __cssFor={{
            text: {
              minHeight: '72px',
              height: '72px',
            }
          }}
          label={label}
          disabled={nonEditableProperties?.includes(fieldKey)}
          isRequired
          onFocus={onInputFocus}
          onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => {
            handleBlur(answerId, fieldKey, e.target.value);
          }}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            handleChange(answerId, fieldKey, e.target.value);
          }}
          maxLength={MAX_CHARACTER_INPUT_LENGTH}
          placeholder={placeholder}
          resize='none'
          // type={type}
          value={value}
        />
      </div>
    );
  };

  const createRowOptionsPopOut = (idx: number, id: string) => {
    return (
      <QuizAnswerRowOptionsPopOut
        addOrRemoveDisabled={addOrRemoveDisabled}
        annotations={annotations}
        onDelete={onDelete}
        quizAnswerId={id}
        removeEnabled={removeEnabled}
      />
    );
  };

  const createInputElements = (input: QuizAnswerInput, field: QuizAnswerInputField, idx: number) => {
    const { inputType, value = '', placeholder = '', fieldKey } = input;
    const key = `${field.key}-${fieldKey}`;
    const label = createOptionLabelByQuestionType(questionType, idx);

    return inputType === 'image'
      ? createImagePickerButton(value, key, field.key, fieldKey)
      : questionType === 'linearScale'
        ? createNumberInput(value, key, field.key, fieldKey, placeholder, label)
        : createTextAreaInput(value, key, field.key, fieldKey, placeholder, label);
  };

  const createAnswerRow = (field = {} as QuizAnswerInputField, idx: number) => {
    const { inputs } = field.value;
    const rowOptionsPopOutButton = createRowOptionsPopOut(idx, field.key);
    const correctAnswerCheckButton = createCorrectAnswerCheckButton(field.key);
    const inputElements = inputs.map((input: QuizAnswerInput) => createInputElements(input, field, idx));

    if (questionType === 'linearScale') return inputElements;

    return (
      <div key={`${field}-${idx}`} className='quiz-answer-table-row'>
        <div className='quiz-answer-table-row-option'>{inputElements}</div>
        <div className='quiz-answer-row-correct-check-button'>{correctAnswerCheckButton}</div>
        <div className='quiz-answer-row-pop-out-button'>{rowOptionsPopOutButton}</div>
      </div>
    );
  };

  const addOptionButton = () => {
    return (
      <Button
        ariaProps={{ 'aria-label': 'add an answer row' }}
        icon={/* AUTOGENERATED TODO: ANGELOU BREAKING CHANGE v0.26_v0.27! CHECK RENDER PATTERN HERE */
          {
            SvgComponent: PlusSVG,
            alignment: Button.iconAlignments.left
          }
        }
        onClick={onAdd}
        __classNameFor={{ root: 'internal-tools-button' }}
        legacy_size={Button.legacy_size.small}
        legacy_flavor={Button.legacy_flavor.solid}
      >
        Add option
      </Button>
    );
  };

  const addSecondaryContentButton = () => {
    return (
      <Button
        __classNameFor={{ root: 'internal-tools-button' }}
        disabled={!onSecondaryAdd}
        legacy_flavor={Button.legacy_flavor.solid}
        onClick={onSecondaryAdd || undefined}
        legacy_size={Button.legacy_size.small}
      >
        {secondaryAddContent}
      </Button>
    );
  };

  return getFields().length ? (
    <div className='quiz-answer-table'>
      {getAnswerRows()}
      {!addOrRemoveDisabled && (
        <div className='quiz-answer-table-row'>
          {addOptionButton()}
        </div>
      )}
    </div>
  ) : null;
};
