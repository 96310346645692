import { v4 as uuid } from 'uuid';

import { makeInitialDefaultQuizAnnotations } from '../components/editors/UXEditor/AnnotationEditor/QuizBuilder/quizBuilderUtils';
import { getLayoutTargetSizes } from '../layouts';
import { Annotation } from '../types/annotations';
import { ToolType } from '../types/tools';
import { getSchemaDefaults } from './persistence/platformService';
import sessionClient from './sessionClient';
import { updateAnnotationCollectionRelationship } from './toolRelationshipService';
import { stageAnnotation } from './toolService';

const _hapyakVersionId = process.env.REACT_APP_VERSION_ID;

class ProjectFactory {
  defaults: any;
  constructor (defaults: any) {
    this.defaults = defaults;
  }

  annotationAppliesTo = (newAnnotations: Annotation[], overrides: any) => {
    if (!overrides || !overrides.appliesTo) return overrides;

    const annotation = newAnnotations[overrides.appliesTo];
    const id = annotation?.id || overrides.appliesTo;
    return {
      ...overrides,
      appliesTo: [id],
    };
  };

  addNecessaryDefaultQuizAnnotations = (annotations: any) => {
    return annotations
      .filter((a: any) => a.type === 'quiz')
      .reduce((_annotations: any, annotation: any) => {
        return makeInitialDefaultQuizAnnotations(annotation, _annotations);
      }, annotations);
  };

  createAnnotations = (annotationDefaults: Annotation[], video = {}) => {
    const newAnnotations: Annotation[] = [];

    if (!annotationDefaults || !annotationDefaults.length) return newAnnotations;

    // Update `appliesTo` relationships
    annotationDefaults.forEach((annotation: Annotation) => {
      let { type, overrides } = annotation;

      if (overrides.end === '100%' && (video as any).duration) {
        overrides.end = (video as any).duration / 1000;
      }

      if (!annotation?.overrides?.appliesTo) {
        newAnnotations.push(stageAnnotation(type as ToolType, overrides));
        return;
      }

      overrides = this.annotationAppliesTo(newAnnotations, overrides);
      newAnnotations.push(stageAnnotation(type as ToolType, overrides));
    });

    // Update corresponding collection relationship
    newAnnotations.forEach((annotation) => {
      if (!annotation.appliesTo || !annotation.appliesTo.length) return;
      annotation.appliesTo.forEach((id: string) => {
        const appliesTo = newAnnotations.find((a) => a.id === id);
        if (!appliesTo && annotation.toolType === 'progressIndicator') return;

        const result = updateAnnotationCollectionRelationship({
          annotation: appliesTo,
          refAnnotation: annotation,
          operation: 'add',
        });
        if (appliesTo) appliesTo.collections = result.collections;
      });
    });

    const newAnnotationsIncludingDefaultQuizAnnotations = this.addNecessaryDefaultQuizAnnotations(newAnnotations);

    return newAnnotationsIncludingDefaultQuizAnnotations;
  };

  async createProject (template: any, projectInputs: any, video = {}) {
    const projectDefaults = await getSchemaDefaults();
    const { layout } = template;
    const playerDefaults = { ...projectDefaults?.player, ...template?.player };

    const group = sessionClient.getCurrentGroup() || {};

    return {
      ...projectDefaults,
      project: {
        ...projectDefaults.project, // keep .project defaults
        ...this.defaults,
        _createdWithHapyakVersionId: _hapyakVersionId,
        created: Date.now(),
        display: { layout: getLayoutTargetSizes(layout.layoutTargets) },
        groupId: group.id,
        id: uuid(),
        ...projectInputs,
        layoutId: layout.id,
      },
      ux: {
        ...projectDefaults.ux, // keep .ux defaults
        annotations: this.createAnnotations(template.defaultAnnotations, video),
      },
      player: playerDefaults,
    };
  }
}

export default new ProjectFactory({
  title: 'HapYak Project',
});
