import React, { Component } from 'react';

import { Bundle, Embed } from 'types/publishing';

import { Segment } from '../../../hapyak-ui-toolkit';
import { AngelouLoadingSpinner } from '../AngelouComponents/AngelouLoadingSpinner';
import { EmbedCreator } from './EmbedCreator';
import './index.scss';

type EmbedStyleAndLayoutSectionProps = {
  bundle: Bundle;
  bundleOutdated: boolean;
  chooseBundle: any;
  disabled: boolean;
  embed: Embed;
  getPublishingOptions: any;
  isPublishing: boolean;
  packagingOptions: any;
};

export class EmbedStyleAndLayoutSection extends Component<EmbedStyleAndLayoutSectionProps> {
  get bundle () {
    return this.props.bundle;
  }

  get loader () {
    return (
      <Segment style={{ height: '300px' }}>
        <AngelouLoadingSpinner />
      </Segment>
    );
  }

  get embedCreator () {
    const { embed, isPublishing } = this.props;
    return <EmbedCreator bundle={this.bundle} embed={embed} isPublishing={isPublishing} loader={this.loader} />;
  }

  render () {
    if (!this.bundle) return null;
    if (!this.bundle.src && !this.bundle.customSrc) return null;

    return <div>{this.embedCreator}</div>;
  }
}
