import React from 'react';

import hms from '../../../../services/time';
import { getToolFromAnnotation } from '../../../../services/toolService';
import { Annotation } from '../../../../types/annotations';
import { ProgressLine } from './ProgressLine';
import timelineConfig from './timelineConfig.json';
import { TimelineRows } from './TimelineRows';
const { MARGIN_HEIGHT, MARKER_HEIGHT, PADDING_HEIGHT } = timelineConfig;

type State = any;

type ProgressBarProps = {
  activeAnnotation: string;
  annotations: Annotation[];
  duration: number;
  end: any;
  hideMarkers: boolean;
  numRows: number;
  progressPercent: number;
  selected: any;
  shortestAnnotation: Annotation;
  start: any;
  updateTime: any;
};

export class ProgressBar extends React.Component<ProgressBarProps, State> {
  constructor (props: ProgressBarProps) {
    super(props);
    this.timeline = React.createRef();
  }

  timeline: any;

  get progressStyle () {
    return {
      width: `${this.props.progressPercent}%`,
    };
  }

  getTimePercentFromEvent = (e: any) => {
    e.stopPropagation();
    const rect = this.timeline.current.getBoundingClientRect();
    return (e.clientX - rect.left) / rect.width;
  };

  goToTimeFromEvent = (e: any) => {
    this.props.updateTime(this.getTimePercentFromEvent(e) * this.props.duration);
  };

  get annotationMarkers () {
    return this.props.annotations.filter((a: Annotation) => a.id).map(this.annotationMarker);
  }

  annotationMarker = (a: Annotation) => {
    const { activeAnnotation, duration, selected } = this.props;
    const isActive = a.id === activeAnnotation;

    if (!duration || (!!selected.length && !isActive)) {
      return null;
    }

    if (isActive) {
      return this.activeAnnotationMarker(a);
    }

    const style = {
      position: 'absolute',
      width: '2px',
      height: '15px',
      boxShadow: '-1px 1px 1px 0 rgba(0, 0, 0, 0.25)',
      left: `${(a.start / duration) * 100}%`,
      top: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: getToolFromAnnotation(a).color,
      zIndex: 2,
    };

    // @ts-expect-error TS(2322): Type '{ position: string; width: string; height: s... Remove this comment to see the full error message
    return <div style={style} key={`marker-${a.id}`} />;
  };

  activeAnnotationMarker = (a: Annotation) => {
    const { duration } = this.props;
    const h = 20;
    const offset = 3;

    const style: React.CSSProperties = {
      position: 'absolute',
      height: '100%',
      left: `${(a.start / duration) * 100}%`,
      width: `${((a.end - a.start) / duration) * 100}%`,
      transform: 'translateY(-50%)',
      top: '50%',
      borderRadius: '0',
      boxShadow: 'none',
      border: 'none',
      zIndex: 2,
    };

    const backgroundStyle: React.CSSProperties = {
      height: '100%',
      width: '100%',
      backgroundColor: getToolFromAnnotation(a).color,
      opacity: 0.5,
    };

    const startStyle: React.CSSProperties = {
      top: -(h + offset) + 'px',
      left: 0,
    };

    const endStyle: React.CSSProperties = {
      ...startStyle,
      left: '100%',
    };

    const arrowStyle: React.CSSProperties = {
      bottom: -offset + 'px',
    };

    return (
      <div style={style} key={`marker-active-${a.id}`}>
        <div style={backgroundStyle} />
        <div className='hy-timeline-active-annotation-marker' style={startStyle}>
          <span className='hy-timeline-active-annotation-marker-arrow' style={arrowStyle} />
          <div className='hy-timeline-active-annotation-marker-body'>{hms.secondsToHMS(a.start, true)}</div>
        </div>
        <div className='hy-timeline-active-annotation-marker' style={endStyle}>
          <span className='hy-timeline-active-annotation-marker-arrow' style={arrowStyle} />
          <div className='hy-timeline-active-annotation-marker-body'>{hms.secondsToHMS(a.end, true)}</div>
        </div>
      </div>
    );
  };

  get baseHeight () {
    const { selected } = this.props;
    return selected.length * MARKER_HEIGHT;
  }

  get fullHeight () {
    const { selected } = this.props;
    const margin = selected.length ? MARGIN_HEIGHT * 4 - PADDING_HEIGHT : MARGIN_HEIGHT; // x4 for inner and outer margin

    return this.baseHeight + margin;
  }

  get style () {
    return {
      height: this.fullHeight + 'px',
    };
  }

  render () {
    const {
      selected,
      shortestAnnotation,
      duration,
      activeAnnotation,
      updateTime,
      hideMarkers,
      progressPercent,
    } = this.props;

    return (
      <div ref={this.timeline} onClick={this.goToTimeFromEvent} className='hy-timeline' style={this.style}>
        <div className='hy-progress' style={this.progressStyle} />
        {!hideMarkers && this.annotationMarkers}
        {!!selected.length && (
          <TimelineRows
            baseHeight={this.baseHeight}
            start={Math.min(...selected.map((a: Annotation) => a.start))}
            end={Math.max(...selected.map((a: Annotation) => a.end))}
            shortestAnnotation={shortestAnnotation}
            shortestDuration={shortestAnnotation.end - shortestAnnotation.start}
            duration={duration}
            activeAnnotation={activeAnnotation}
            selected={selected}
            updateTime={updateTime}
          />
        )}
        <ProgressLine
          getTimePercent={this.getTimePercentFromEvent}
          goToTime={this.goToTimeFromEvent}
          progressPercent={progressPercent}
          height={this.fullHeight}
        />
      </div>
    );
  }
}
