import React from 'react';

import _ from 'lodash';

import { Action, Condition } from 'types/actions';

import { Checkbox, Dropdown, Input, Grid } from '../../../../hapyak-ui-toolkit';
import { getLayoutTargets } from '../../../../layouts';
import { stateController } from '../../../../services/stateController';
import { getDropdownDisplay, getEligibleTargets } from '../../../../services/toolService';
import { toOption } from '../../../../services/utils';
import { toolsWhiteList } from '../../../../tools/toolsWhiteList';
import { Annotation, AnnotationProperties } from '../../../../types/annotations';
import { TimeInput } from '../../../TimeInput/TimeInput';
import { QUIZ_TOOL_TYPES } from '../AnnotationEditor/QuizBuilder/quizBuilderUtils';
import { actionMap, EXCLUDED_COMPLEX_EVENT_ANNOTATIONS, TO_LINK_TARGETS } from './actionMap';
import { COMPLEX_EVENTS, getComplexEvent } from './inverserized';
import { PERMITTED_ACTIONS } from './permittedActions';
import {
  findSupportedActionsByToolType,
  findSupportedEventsByToolType,
  getSectionOptionsForQuiz,
  toOptionComplex,
} from './utils';
import './index.scss';

const DEFAULT_AVAILABLE_TARGETS = ['new_tab'];
const PASS_FAIL_EVENTS = ['onPass', 'onFail'];

type State = any;

type ActionInputsProps = {
  annotation: Annotation;
  annotations: Annotation[];
  action?: Action;
  args?: any;
  condition?: Condition;
  duration: number;
  event?: any;
  minimal: any;
  update: any;
};

export class ActionInputs extends React.Component<ActionInputsProps, State> {
  displayConditionals: any;
  permitsAnnotationConditionals: any;
  constructor (props: ActionInputsProps) {
    super(props);
    const { event, action, args, condition = {} } = props;
    this.state = event ? { event, action, args, customVariables: {}, condition } : this.defaultState;
    props.update(this.state);
  }

  get defaultState () {
    const action = findSupportedActionsByToolType(this.props.annotation.toolType)[0];
    return {
      condition: {},
      event: findSupportedEventsByToolType(this.props.annotation.toolType)[0],
      action,
      args: this.getArgDefaults(action),
    };
  }

  persistTime = (id: any, type: any, value: any) => {
    this.update({ args: { ...this.state.args, [type]: value } });
  };

  onCheckboxChange = (type: any, e: any, {
    checked
  }: any) => {
    this.update({ args: { ...this.state.args, [type]: checked } });
  };

  onChange = (type: any, e: any) => {
    this.update({ args: { ...this.state.args, [type]: e.target.value } });
  };

  onDropdownChange = (type: any, e: any, {
    value
  }: any) => {
    const { annotation = {} as Annotation } = this.props;
    const { id = '' } = annotation;
    const isSelf = id && id === value;

    this.update({ args: { ...this.state.args, [type]: value, isSelf: isSelf } });
  };

  setEvent = (e: any, {
    value
  }: any) => {
    let additionalChanges = {};

    if (this.displayConditionals) {
      additionalChanges = { condition: {} };
    }

    this.update({ event: value, ...additionalChanges });
  };

  setAction = (e: any, {
    value
  }: any) => {
    this.update({ action: value, args: this.getArgDefaults(value) });
  };

  update = (state: any) => {
    this.setState(state);
    this.props.update(state);
  };

  getActionArgInputs = (action: Action, args: any) => {
    const argDefinitions = this.getArg(action);

    return argDefinitions &&
        argDefinitions.map((arg: any) => {
          return arg.input(arg.key, args[arg.key]);
        });
  };

  getArgDefaults = (action: Action) => {
    const argDefinitions = this.getArg(action);
    const defaults = {};
    argDefinitions &&
          argDefinitions.forEach((arg: any) => {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            defaults[arg.key] = arg.default;
          });
    return defaults;
  };

  getArg = (action: Action) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return this.inputArgs[action];
  };

  get toLinkOptions () {
    const { layoutId } = stateController.getCurrentData('project');
    const availableLayoutTargets = [
      ...DEFAULT_AVAILABLE_TARGETS,
      ...getLayoutTargets(layoutId).map((layout: any) => layout.name),
    ];

    return TO_LINK_TARGETS.filter((target) => availableLayoutTargets.includes(target)).map((item) =>
      toOption(item, { split: 'underscore' })
    );
  }

  get scoreOperators () {
    return [
      {
        key: 'increment',
        value: '+',
        text: 'Increment',
      },
      {
        key: 'decrement',
        value: '-',
        text: 'Decrement',
      },
      {
        key: 'set',
        value: '=',
        text: 'Set',
      },
    ];
  }

  get setPanelOptions () {
    const { args = {} } = this.state;
    const { annotations } = this.props;

    if (args.value) {
      const annotation = annotations.find((a: Annotation) => a.id === args.value);

      if (annotation) {
        const eligibleTargets = getEligibleTargets(annotation.toolType);
        const { layoutId } = stateController.getCurrentData('project');
        const targets = [...getLayoutTargets(layoutId).map((layout: any) => layout.name)];

        return targets.filter((t) => eligibleTargets.includes(t)).map((t) => toOption(t, { split: 'underscore' }));
      }
    }

    return [];
  }

  get classActionArgs () {
    return [
      this.getGenericInputArg({ type: 'text', placeholder: 'Class Name' }),
      {
        key: 'id',
        chooseAnnotation: true,
        input: this.getDropDownArg({
          includeSelf: true,
          className: 'hy-margin-top',
          placeholder: 'Target Annotation',
        }),
        default: null,
      },
    ];
  }

  get toSectionOptions () {
    const { annotation = {} as Annotation } = this.props;
    return getSectionOptionsForQuiz(annotation);
  }

  get inputArgs () {
    return {
      toTime: [
        {
          key: 'value',
          input: (key: any, value: any) => {
            const { duration, minimal } = this.props;

            return (
              <TimeInput
                buttons={minimal}
                fluid={minimal}
                key={key}
                time={value}
                type={key}
                identifier='time'
                min={0}
                max={duration}
                persistTime={this.persistTime}
              />
            );
          },
          default: 0,
        },
      ],
      toAnnotation: [
        {
          key: 'value',
          chooseAnnotation: true,
          input: this.getDropDownArg({
            includeSelf: true,
          }),
          default: null,
        },
      ],
      applyGate: [
        {
          key: 'value',
          chooseAnnotation: true,
          input: this.getDropDownArg({
            includeSelf: true,
          }),
          default: null,
        },
      ],
      releaseGate: [
        {
          key: 'value',
          chooseAnnotation: true,
          input: this.getDropDownArg({
            includeSelf: true,
          }),
          default: null,
        },
      ],
      setVariables: [
        this.getKeyValuePairInputArg(
          { name: 'key', placeholder: 'Name', className: 'hy-margin-bottom' },
          { name: 'value', placeholder: 'Value' }
        ),
      ],
      addClass: this.classActionArgs,
      removeClass: this.classActionArgs,
      setClass: this.classActionArgs,
      enableSeek: [
        {
          key: 'jumpToLastSeek',
          input: (key: any, value: any) => {
            return (
              <Checkbox
                key={key}
                checked={value}
                onChange={this.onCheckboxChange.bind(this, key)}
                label='Jump to last prevented seek'
              />
            );
          },
          default: true,
        },
      ],
      toLink: [
        this.getGenericInputArg({
          type: 'url',
          placeholder: 'Enter Absolute URL',
          fluid: true,
          className: 'hy-margin-bottom',
        }),
        {
          key: 'target',
          input: (key: any, value: any) => {
            return (
              <Dropdown
                selection
                fluid
                key={key}
                options={this.toLinkOptions}
                value={value}
                onChange={this.onDropdownChange.bind(this, key)}
              />
            );
          },
          default: 'new_tab',
        },
      ],
      goToSection: [
        {
          key: 'goToSection',
          input: (key: any, value: any) => {
            return (
              <Dropdown
                selection
                fluid
                key={key}
                options={this.toSectionOptions}
                value={value}
                onChange={this.onDropdownChange.bind(this, key)}
              />
            );
          },
        },
      ],
      sendData: [
        this.getGenericInputArg({
          type: 'url',
          placeholder: 'Enter Absolute Endpoint URL',
          fluid: true,
          className: 'hy-margin-bottom',
        }),
        {
          key: 'method',
          input: (key: any, value: any) => {
            return (
              <Dropdown
                selection
                key={key}
                fluid
                label='Request Method'
                options={['GET', 'POST'].map(toOption)}
                value={value}
                onChange={this.onDropdownChange.bind(this, key)}
              />
            );
          },
          default: 'GET',
        },
      ],
      confirmAction: [this.getGenericInputArg({ type: 'prompt', placeholder: 'User Feedback' })],
      hideAnnotation: [
        {
          key: 'value',
          chooseAnnotation: true,
          input: this.getDropDownArg({
            includeSelf: true,
          }),
          default: null,
        },
      ],
      showAnnotation: [
        {
          key: 'value',
          chooseAnnotation: true,
          input: this.getDropDownArg({
            includeSelf: true,
          }),
          default: null,
        },
      ],
      setPanel: [
        {
          key: 'value',
          chooseAnnotation: true,
          input: this.getDropDownArg({
            includeSelf: true,
          }),
          default: null,
        },
        {
          key: 'target',
          input: (key: any, value: any) => {
            return (
              <Dropdown
                selection
                fluid
                key={key}
                options={this.setPanelOptions}
                value={value}
                onChange={this.onDropdownChange.bind(this, key)}
              />
            );
          },
          default: 'player',
        },
      ],
      updateScore: [
        {
          key: 'operation',
          chooseAnnotation: false,
          input: (key: any, value: any) => {
            return (
              <Dropdown
                selection
                fluid
                key={key}
                options={this.scoreOperators}
                value={value}
                onChange={this.onDropdownChange.bind(this, key)}
              />
            );
          },
          default: this.scoreOperators && this.scoreOperators[0].value,
        },
        this.getGenericInputArg({
          type: 'number',
          className: 'fluid hy-margin-top',
          placeholder: 'Enter Value',
          text: '',
        }),
      ],
      toggleAnnotation: [
        {
          key: 'value',
          chooseAnnotation: true,
          input: this.getDropDownArg({
            includeSelf: true,
          }),
          default: null,
        },
      ],
      toggleVisibility: [
        {
          key: 'value',
          chooseAnnotation: true,
          input: this.getDropDownArg({
            includeSelf: false,
          }),
          default: null,
        },
      ],
      toggleClass: [
        this.getGenericInputArg({ type: 'text', placeholder: 'Class Name' }),
        {
          key: 'id',
          chooseAnnotation: true,
          input: this.getDropDownArg({
            includeSelf: true,
            className: 'hy-margin-top',
            placeholder: 'Target Annotation',
          }),
          default: null,
        },
      ],
    };
  }

  getDropDownArg (opts: any) {
    const { includeSelf = false, className = '', placeholder = 'Choose Annotation' } = opts;
    return (key: any, value: any) => {
      const { annotation = {} as Annotation, annotations, minimal } = this.props;

      if (!annotation.id) annotation.id = 'override';

      const options = annotations
        .filter((a: Annotation) => {
          return a && a.id !== (annotation && annotation.id) && toolsWhiteList.includes(a.toolType);
        })
        .map((a: Annotation) => {
          // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
          return toOptionComplex(a.id, getDropdownDisplay(a));
        });

      includeSelf && options.unshift(toOptionComplex(annotation.id, getDropdownDisplay(annotation, true)));

      return (
        <Dropdown
          fluid={minimal}
          search
          selection
          key={key}
          placeholder={placeholder}
          options={options}
          value={value}
          className={className}
          onChange={this.onDropdownChange.bind(this, key)}
        />
      );
    };
  }

  handleInputChange = (e: any) => {
    this.setState({
      customVariables: {
        ...this.state.customVariables,
        [e.target.name]: e.target.value,
      },
    });

    this.saveVar();
  };

  saveVar = () => {
    const { customVariables } = this.state;

    const value =
          customVariables && customVariables.key ? { [customVariables.key]: customVariables.value || '' } : {};

    this.update({ args: { ...this.state.args, value: value } });
  };

  componentDidMount () {
    const { args = {}, customVariables = {} } = this.state;

    if (args.value && !Object.keys(customVariables).length && typeof args.value === 'object') {
      const newVars = {
        key: Object.keys(args.value)[0],
        value: args.value[Object.keys(args.value)[0]],
      };

      this.setKeyValueDisplayState(newVars);
    }

    const hasOneEvent = this.events.length === 1;
    if (hasOneEvent) this.setFirstEventAsDefault();
  }

  setFirstEventAsDefault = () => {
    const defaultEvent = _.get(this, 'events[0].value');
    this.setEvent(null, { value: defaultEvent });
  };

  getKeyValuePairInputArg (keyProps: any, valueProps: any) {
    return {
      key: 'value',
      input: (key: any, value: any) => {
        const commonProps = {
          type: 'text',
          onChange: this.handleInputChange,
          fluid: true,
        };
        const { customVariables = {} } = this.state;

        const varName = customVariables && customVariables.key ? customVariables.key : '';
        const varValue = customVariables && customVariables.value ? customVariables.value : '';

        return (
          <div key={key}>
            <Input onBlur={this.saveVar} {...keyProps} {...commonProps} value={varName} />
            <Input onBlur={this.saveVar} {...valueProps} {...commonProps} value={varValue} />
          </div>
        );
      },
    };
  }

  setKeyValueDisplayState (newVars: any) {
    this.setState({ ...this.state, customVariables: newVars });
  }

  getGenericInputArg (props: any) {
    return {
      key: 'value',
      input: (key: any, value: any) => {
        const { minimal } = this.props;
        const { fluid } = props;
        return (
          <Input
            fluid={fluid || minimal}
            key={key}
            value={value}
            {...props}
            onChange={this.onChange.bind(this, key)}
          />
        );
      },
      default: '',
    };
  }

  // some events aren't applicable unless other events, actions, or properties are configured
  isPermittedEvent = (eventName: any) => {
    const { annotation = {} as Annotation } = this.props;
    if (!annotation.properties) annotation.properties = {} as AnnotationProperties;
    if (!annotation.actions) annotation.actions = [];

    if (eventName === 'onSpecificAnswer') {
      return this.permitsAnnotationConditionals;
    }

    if (QUIZ_TOOL_TYPES.includes(annotation.toolType)) {
      const isPassFailEvent = PASS_FAIL_EVENTS.includes(eventName);
      const evaluatePassFail = annotation.properties.evaluateQuiz;

      if (isPassFailEvent) return evaluatePassFail;

      return !(evaluatePassFail && eventName === 'onSubmit');
    }

    if (eventName === 'onGate') {
      return annotation.gated;
    }

    if (eventName === 'onConfirm') {
      return annotation.actions.some((a: Action) => a.action === 'confirmAction');
    }

    if (eventName.startsWith('onLink')) {
      return annotation.actions.some(
        (a: Action) => a.action === 'toLink' && !DEFAULT_AVAILABLE_TARGETS.includes(a.args.target)
      );
    }

    return true;
  };

  get events () {
    const { annotation = {} as Annotation } = this.props;
    const supportedEvents = findSupportedEventsByToolType(annotation.toolType);
    const monoEvents = supportedEvents.filter(this.isPermittedEvent);
    const complexOptions = COMPLEX_EVENTS.map((evt) => evt.complexEventName);
    const excludeComplexEvents = EXCLUDED_COMPLEX_EVENT_ANNOTATIONS.includes(annotation.toolType);
    const complexEvents = excludeComplexEvents ? [] : complexOptions;
    return [...monoEvents, ...complexEvents].map(toOption);
  }

  get assessedEvent () {
    return null;
  }

  get actions () {
    const event = this.assessedEvent ? this.assessedEvent : this.state.event;

    const complexEvent = getComplexEvent({ event });
    const isComplexEvent = !!complexEvent;
    const actions = isComplexEvent ? complexEvent.canCauseComplexActions : findSupportedActionsByToolType(this.props.annotation.toolType);
    return actions
      .filter((a: any) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        return PERMITTED_ACTIONS[event] && PERMITTED_ACTIONS[event].includes(a);
      })
      .map(toOption);
  }

  wrap = (content: any) => {
    return <div className='hy-action-inputs'> {content} </div>;
  };

  render () {
    const { event, action, args } = this.state;
    const argumentInputs = action && this.getActionArgInputs(action, args);
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const mappedActionTarget = (actionMap[action] || '').toUpperCase();

    return this.wrap(
      <div>
        <Grid columns={3} className='hy-input-labels'>
          <Grid.Row style={{ paddingBottom: 0 }}>
            <Grid.Column>WHEN THIS HAPPENS</Grid.Column>
            <Grid.Column>DO THIS</Grid.Column>
            <Grid.Column>{mappedActionTarget}</Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column>
              <Dropdown
                search
                selection
                fluid
                label='Event'
                value={event}
                disabled={this.events.length < 2}
                options={this.events}
                onChange={this.setEvent}
              />
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                search
                selection
                fluid
                label='Action'
                value={action}
                options={this.actions}
                onChange={this.setAction}
              />
            </Grid.Column>
            <Grid.Column>{argumentInputs}</Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
