import logger from './logger';

export const exposeGlobal = (name: any, handler: any, preventOverride = true) => {
  if (!name || !handler) {
    logger.warn('Scope error', 'missing required params for exposeGlobal');
    return;
  }

  if (preventOverride && typeof window[name] !== 'undefined') {
    logger.warn('Scope error', 'global already defined');
    return;
  }

  window[name] = handler;
};
