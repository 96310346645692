import './index.scss';

import React, { useState } from 'react';

import { Button, DownloadSVG, Icon, RedoSVG, UndoSVG } from '@newsela/angelou';
import { Link } from 'react-router-dom';

import DEFAULTS from '../../../src/DEFAULTS.json';
import { APP_CONFIG } from '../../APP_CONFIG_DATA';
import { Icon as SemanticUIIcon, Popup } from '../../hapyak-ui-toolkit';
import { comm } from '../../services/comm';
import { gaConfig, ReactGA } from '../../services/gaConfig';
import { isHomeRoute } from '../../services/navUtils';
import { stateController } from '../../services/stateController';
import { exportJSON } from '../../services/stateUtils';
import { EditableText } from '../editors/CommonComponents/EditableText';
import { ChooseGroupModal } from '../User/ChooseGroupModal';
import { CurrentUserPopOut } from './CurrentUserPopOut';
import { LastSavedMessage } from './LastSavedMessage';
import { NavSection } from './NavSection';

const basicSections = ['STUDIO CMS'];
const sections = [...basicSections, 'PROJECT'];

type NavProps = {
  data?: any;
  location: Location;
  pathPrefix?: string;
  processing?: boolean;
  routeDisplay?: string;
  session: any;
};

export const Nav = ({
  data,
  location,
  pathPrefix,
  processing,
  routeDisplay,
  session
}: NavProps) => {
  const [showChooseGroupModal, setShowChooseGroupModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { customVersion } = (data && data.project) || {};

  const closeExpanded = () => setExpanded(false);
  const toggleExpanded = () => setExpanded((expanded) => !expanded);
  const toggleChooseGroupModal = () => setShowChooseGroupModal(!showChooseGroupModal);

  const toggleShowKeyboardShortcuts = () => comm.trigger('toggleShowKeyboardShortcuts');

  const exportJson = () => {
    exportJSON();
    ReactGA.event(gaConfig.Analytics.Portal.Export);
  };

  const undo = () => stateController.undo();
  const redo = () => stateController.redo();
  const setTitle = (value: string) => stateController.updateProject('project', { title: value });

  const editableTitle = () => {
    const { title } = (data && data.project) || {};

    return (
      <span className='hy-nav-top-info-title'>
        <EditableText value={title} onBlur={setTitle} placeholder='Untitled Project' maxWidth={400} />
      </span>
    );
  };

  const getNavSection = (section: string, index: number) => {
    return !data ? null : (
      <NavSection
        key={section}
        index={index}
        section={section}
        pathPrefix={pathPrefix}
        location={location}
        onLinkClick={closeExpanded}
        processing={processing}
      />
    );
  };

  const getBasicNavSection = (section: string, index: number) => {
    return getNavSection(section, index);
  };

  const navSections = () => {
    const isHome = window.location.pathname === '/home';
    return !data ? !isHome && basicSections.map(getBasicNavSection) : sections.map(getNavSection);
  };

  const topButtons = () => {
    const groupMemberships = session && session.user && session.user.groupMemberships;
    const hasManyGroups = groupMemberships && Object.keys(groupMemberships).length > 1;
    const historian = stateController.historian;

    const buttonCss = {
      root: {
        border: 'none',
        height: '38px',
        width: '38px',
        backgroundColor: '#F8F8F8',
        marginLeft: '12px'
      }
    };

    return (
      <div className='hy-nav-top-buttons'>
        {isHomeRoute(location) ? null : (
          <>
            <LastSavedMessage />
            <Popup
              trigger={
                <Button
                  legacy_flavor={Button.legacy_flavor.hollow}
                  onClick={undo}
                  disabled={!historian.canUndo}
                  __cssFor={buttonCss}
                >
                  <Icon SvgComponent={UndoSVG} isDecorative={false} title='undo action' />
                </Button>
              }
              content='Undo'
            />
            <Popup
              trigger={
                <Button
                  legacy_flavor={Button.legacy_flavor.hollow}
                  onClick={redo}
                  disabled={!historian.canRedo}
                  __cssFor={buttonCss}
                >
                  <Icon SvgComponent={RedoSVG} isDecorative={false} title='redo action' />
                </Button>
              }
              content='Redo'
            />
            <Popup
              trigger={
                <Button
                  legacy_flavor={Button.legacy_flavor.hollow}
                  onClick={exportJson}
                  __cssFor={buttonCss}
                >
                  <Icon SvgComponent={DownloadSVG} isDecorative={false} title='export project' />
                </Button>

              }
              content='Export'
            />
          </>
        )}

        <CurrentUserPopOut
          hasManyGroups={hasManyGroups}
          isHomeRoute={isHomeRoute(location)}
          toggleChooseGroupModal={toggleChooseGroupModal}
        />
      </div>
    );
  };

  if (showChooseGroupModal) return <ChooseGroupModal handleClose={toggleChooseGroupModal} />;

  return (
    <div className={`hy-nav ${expanded ? 'hy-nav-expanded' : ''}`}>
      <div className='hy-nav-top'>
        {isHomeRoute(location) ? (
          <Link to={`/${DEFAULTS.HOME_PATH}`}>
            <span className='hy-nav-logo'>{APP_CONFIG.branding}</span>
          </Link>
        ) : null}
        <SemanticUIIcon className='hy-nav-menu-icon' link name='bars' size='large' onClick={toggleExpanded} />
        {isHomeRoute(location) ? null : (
          <div className='hy-nav-top-info'>
            {editableTitle()}
            {customVersion && <span className='hy-nav-top-info-version'> - {customVersion}</span>}
            <span className='hy-nav-top-info-route'> / {routeDisplay}</span>
          </div>
        )}
        {topButtons()}
      </div>

      <div className='hy-nav-side hy-scrollable'>
        <ul className='hy-nav-list'>{navSections()}</ul>
        <div className='hy-nav-side-bottom'>
          <div className='hy-nav-icon-toggle' onClick={toggleShowKeyboardShortcuts}>
            <SemanticUIIcon name='keyboard' />
            <span>Keyboard shortcuts</span>
          </div>
        </div>
      </div>
      <div className='hy-nav-dimmer' onClick={closeExpanded} />
    </div>
  );
};
