import React, { useEffect, useState } from 'react';
import './NewProjectFlow.scss';

import { ArticleEditSVG, ArticleSVG, Button, ExternalLinkSVG, Icon, Modal, useModal } from '@newsela/angelou';

import { JobError } from 'errors/JobError';
import { UploadContentType } from 'types/uploadContentType';

import DEFAULTS from '../../../DEFAULTS.json';
import { comm } from '../../../services/comm';
import { toPath, toPathOnNewTab } from '../../../services/navUtils';
import { persistenceClientFactory } from '../../../services/persistence/persistenceClientFactory';
import { AngelouLoadingSpinner } from '../AngelouComponents/AngelouLoadingSpinner';
import ErrorBanner from '../AngelouComponents/ErrorBanner';
import { ExternalLinksTable } from '../CommonComponents/ExternalLinksTable';
import { NewBlankProjectForm } from './NewBlankProjectForm';
import { NewProjectForm } from './NewProjectForm';

export const NewProjectFlow = () => {
  const [errorMsg, setErrorMsg] = useState<any>('');
  const [working, setWorking] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalWillOpen, setModalWillOpen] = useState(true);
  const [projectId, setProjectId] = useState('');
  const [modalProps, setActive, triggerRef] = useModal(false);
  const [projectType, setProjectType] = useState('normal');
  const [hasVideo, setHasVideo] = useState(false);
  const [hasCaptions, setHasCaptions] = useState(false);
  const [hasAudioDescription, setHasAudioDescription] = useState(false);
  const [hasVideoUploadError, setHasVideoUploadError] = useState(false);
  const [hasCaptionVttUploadError, setHasCaptionVttUploadError] = useState(false);
  const [audioUploadError, setaudioUploadError] = useState(false);
  const [uploadErrorMessageArr, setUploadErrorMessageArr] = useState<any[]>([]);
  const [redErrorMessage, setRedErrorMessage] = useState<any>(null);
  const [error, setError] = useState<Error | null>(null);

  type URLParams = URLSearchParams & {
    create_project?: string;
    create_blank_project?: string;
  };

  useEffect(() => {
    comm.register('axiosUploadError', updateErrMessage);
    comm.register('vttUploadError', updateErrMessage);
    comm.register('processFailed', updateRemoteTranscodingErrorMessage);
    setHasCaptionVttUploadError(false);
    setHasVideoUploadError(false);
    setaudioUploadError(false);
    setUploadErrorMessageArr([]);
    setRedErrorMessage(null);
  }, []);

  const updateRemoteTranscodingErrorMessage = (processName: string) => {
    if (processName === DEFAULTS.TRANSCODE_REMOTE_UPLOAD_KEY || processName === DEFAULTS.TRANSCODE_KEY) {
      const uploadMediaType = 'Video';
      const uploadErrorMessage = 'Transcoding process failed.';
      setUploadErrorMessageArr((errorArray: any[]) => [...errorArray, { uploadMediaType: uploadMediaType, message: uploadErrorMessage }]);
    }
  };

  const updateErrMessage = (uploadContentType: UploadContentType, error: JobError) => {
    setError(error);
    let uploadMediaType: 'Video' | 'Caption file' | 'Audio file' |'File';
    if (uploadContentType === 'video/mp4') {
      uploadMediaType = 'Video';
      setHasVideoUploadError(true);
    } else if (uploadContentType === 'text/vtt') {
      uploadMediaType = 'Caption file';
      setHasCaptionVttUploadError(true);
    } else if (uploadContentType === 'audio/mpeg') {
      uploadMediaType = 'Audio file';
      setaudioUploadError(true);
    } else {
      uploadMediaType = 'File';
    }
    setUploadErrorMessageArr((errorArray: any[]) => [...errorArray, { uploadMediaType: uploadMediaType, message: error.message }]);
  };

  useEffect(() => {
    if (uploadErrorMessageArr.length > 0) {
      updateUploadErrMessages();
    }
  }, [uploadErrorMessageArr]);

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop.toString()),
    });
    if ((params as URLParams).create_project === '1' && modalWillOpen) {
      setProjectType('normal');
      if (typeof setActive === 'function') setActive(true);
      setModalWillOpen(false);
    }
    if ((params as URLParams).create_blank_project === '1' && modalWillOpen) {
      setProjectType('blank');
      if (typeof setActive === 'function') setActive(true);
      setModalWillOpen(false);
    }
  });

  const updateUploadErrMessages = () => {
    const redErrorBox = () => {
      return (
        <ErrorBanner
          title='Upload error'
          body={uploadErrorMessageArr.map((errorItem: any) => {
            return (<li key={errorItem.uploadMediaType}> {errorItem.uploadMediaType} did not process. Open the project and replace the {errorItem.uploadMediaType}. Error: {errorItem.message}<br /></li>);
          })}
          error={error}
        />
      );
    };
    setRedErrorMessage(redErrorBox);
    setWorking(false);
  };

  useEffect(() => {
    return () => {
      comm.unregister('axiosUploadError', updateErrMessage);
      comm.unregister('vttUploadError', updateErrMessage);
      comm.unregister('processFailed', updateRemoteTranscodingErrorMessage);
    };
  }, []);

  const openProject = () => {
    const uxPath = persistenceClientFactory.getClient().getUXPath(projectId);
    toPath(uxPath);
  };

  const createdProjectMessage = () => {
    const messageArray = [`Your project "${modalTitle}" has been created`];
    if (hasVideo && !hasVideoUploadError) {
      messageArray.push('your video is currently being transcoded');
    }
    if (hasCaptions && !hasCaptionVttUploadError) {
      messageArray.push('your caption file is processing');
    }
    if (hasAudioDescription && !audioUploadError) {
      messageArray.push('your audio description file is processing');
    }
    const message = messageArray.join(', ');
    const lastComma = message.lastIndexOf(',');
    if (lastComma === -1) { return message + '.'; }
    return message.substring(0, lastComma) + ', and' + message.substring(lastComma + 1) + '.';
  };

  const project = () => {
    return (
      <div className='create-project-modal'>
        <h1 id='modal-title'>
          "{modalTitle.length > 30 ? modalTitle.substring(0, 29) + '...' : modalTitle}" project has been created
        </h1>
        <div className='body'>
          <section className='new-project-success-messages'>
            <div key={0} className='inner-body'>
              {redErrorMessage}
              <p className='project-created-message'>
                {createdProjectMessage()} Create another project or get started with this one.
              </p>
              <ExternalLinksTable projectId={projectId} creatingProject />
            </div>
          </section>
        </div>
        <div className='footer'>
          <div className='f-inner'>
            <div className='f-horizontal-separator'>
              <Button
                legacy_flavor={Button.legacy_flavor.solid}
                legacy_statusColor={Button.legacy_statusColor.tertiary}
                legacy_size={Button.legacy_size.medium}
                onClick={() => {
                  if (typeof setActive === 'function') setActive(false);
                  setProjectId('');
                  setHasCaptionVttUploadError(false);
                  setHasVideoUploadError(false);
                  setaudioUploadError(false);
                }}
              >
                Return to home
              </Button>
            </div>
            <div className='f-horizontal-separator'>
              <Button
                icon={{ SvgComponent: ExternalLinkSVG }}
                legacy_flavor={Button.legacy_flavor.solid}
                legacy_statusColor={Button.legacy_statusColor.secondary}
                legacy_size={Button.legacy_size.medium}
                onClick={() => {
                  if (projectType === 'normal') {
                    toPathOnNewTab('?create_project=1');
                  } else {
                    toPathOnNewTab('?create_blank_project=1');
                  }
                }}
              >
                Create another project
              </Button>
            </div>
            <div className='f-horizontal-separator'>
              <Button
                legacy_flavor={Button.legacy_flavor.solid}
                legacy_statusColor={Button.legacy_statusColor.primary}
                legacy_size={Button.legacy_size.medium}
                onClick={() => openProject()}
              >
                Work on this project
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const newProject = () => {
    if (projectType === 'normal') {
      return (
        <NewProjectForm
          setWorking={setWorking}
          setProjectId={setProjectId}
          setActive={setActive}
          setModalTitle={setModalTitle}
          setErrorMsg={setErrorMsg}
          errorMsg={errorMsg}
          setHasVideo={setHasVideo}
          setHasCaptions={setHasCaptions}
          setHasAudioDescription={setHasAudioDescription}
        />
      );
    } else {
      return (
        <NewBlankProjectForm
          setWorking={setWorking}
          setProjectId={setProjectId}
          setActive={setActive}
          setModalTitle={setModalTitle}
          setErrorMsg={setErrorMsg}
          errorMsg={errorMsg}
        />
      );
    }
  };

  const IconCSS = {
    width: '32px',
    height: '32px',
    path: {
      stroke: '#04337A'
    }
  };

  return (
    <>
      <div className='flows'>
        <div className='create'>
          <Button
            legacy_flavor={Button.legacy_flavor.solid}
            legacy_statusColor={Button.legacy_statusColor.secondary}
            legacy_size={Button.legacy_size.justified}
            onClick={() => { if (typeof setActive === 'function') setActive(true); setProjectType('normal'); }}
            ref={triggerRef}
          >
            <Icon SvgComponent={ArticleEditSVG} __cssFor={{ root: IconCSS }} /> Create a project with details, video and/or accessibility
          </Button>
        </div>

        <div className='create'>
          <Button
            legacy_flavor={Button.legacy_flavor.solid}
            legacy_statusColor={Button.legacy_statusColor.secondary}
            legacy_size={Button.legacy_size.justified}
            onClick={() => { if (typeof setActive === 'function') setActive(true); setProjectType('blank'); }}
            ref={triggerRef}
          >
            <Icon SvgComponent={ArticleSVG} __cssFor={{ root: IconCSS }} /> Create a blank project
          </Button>
        </div>
      </div>
      <Modal
        {...modalProps}
        labelId='modal-title'
        __classNameFor={{ root: 'modal-create' }}
      >
        {
          working
            ? <AngelouLoadingSpinner text='Creating project' transparent={false} fullScreenDimmer={false} />
            : projectId
              ? project()
              : newProject()
        }
      </Modal>
    </>
  );
};
