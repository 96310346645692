import _ from 'lodash';

import sessionClient from './sessionClient';

class ConfigurationService {
  configuration = {};
  provisioning = {};

  requestConfig = async () => {
    const data = await sessionClient.getClientConfiguration();
    const { configuration, provisioning } = data.result;
    this.configuration = configuration || {};
    this.provisioning = provisioning || {};
  };

  toolsPath = (path: any) => {
    return `hapyakTools.${path}`;
  };

  authoringPath = (path: any) => {
    return `authoring.${this.toolsPath(path)}`;
  };

  get = (path: any, defaultValue: any) => {
    // no need to include 'authoring.hapyakTools' when calling this
    return _.get(this.configuration, this.authoringPath(path), defaultValue);
  };

  allow = (path: any, defaultValue?: any) => {
    // no need to include 'hapyakTools' when calling this
    return _.get(this.provisioning, this.toolsPath(path), defaultValue);
  };
}

const groupConfig = new ConfigurationService();

export { groupConfig };
