import React, { Component } from 'react';

import { Input } from '../../../../hapyak-ui-toolkit';
import './index.scss';

const PADDING = 20;
const DEFAULT_WIDTH = 124; // width of placeholder
const DEFAULT_MAX_WIDTH = 360;

type State = any;

type EditableTextProps = {
  onBlur: (val: any) => void;
  onChange?: () => void;
  maxWidth?: number;
  placeholder: string;
  style?: React.CSSProperties;
  value: string;
};

class EditableText extends Component<EditableTextProps, State> {
  editableRef: any;
  shadowInput: any;
  constructor (props: EditableTextProps) {
    super(props);

    this.editableRef = React.createRef();
    this.shadowInput = React.createRef();

    this.state = { value: props.value, width: DEFAULT_WIDTH };
  }

  componentDidUpdate () {
    const ref = this.shadowInput.current;
    const newWidth = ref.offsetWidth;

    if (newWidth !== this.state.width) {
      this.setState({ width: newWidth });
    }
  }

  onChange = (e: any) => {
    const { onChange } = this.props;
    this.setState({ value: e.target.value });
    if (typeof onChange === 'function') onChange();
  };

  onBlur = () => {
    const { onBlur } = this.props;
    if (typeof onBlur === 'function') onBlur(this.state.value);
  };

  get style () {
    const { maxWidth } = this.props;
    return {
      ...this.sharedStyles,
      maxWidth: `${(maxWidth || DEFAULT_MAX_WIDTH)}px`,
    };
  }

  get sharedStyles (): React.CSSProperties {
    return {
      fontSize: '18px',
      padding: '5px 7px',
      margin: '1px',
      fontFamily: 'Lato',
    };
  }

  get inputField () {
    const { placeholder } = this.props;
    const { value } = this.state;
    return (
      <>
        <Input
          ref={this.editableRef}
          fluid
          style={this.style}
          value={value}
          placeholder={placeholder}
          onChange={this.onChange}
          onBlur={this.onBlur}
        />
        <div
          ref={this.shadowInput}
          style={{ position: 'absolute', top: '-1000px', visibility: 'hidden', ...this.sharedStyles }}
        >
          {value || placeholder}
        </div>
      </>
    );
  }

  render () {
    const { style } = this.props;

    return (
      <div className='hy-inline-editable' style={{ width: this.state.width + PADDING, ...style }}>
        {this.inputField}
      </div>
    );
  }
}

export { EditableText };
