import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import './AccessibilityUploadComponent.scss';

import { Combobox, Icon, PaperHollowSVG, VolumeUpSVG } from '@newsela/angelou';

import { ComboboxItem } from 'types/angelou';
import { Language } from 'types/localization';

import { LexileComboBox } from '../CommonComponents/LexileComboBox';
import { Upload } from './Upload';
import { filteredLanguages } from './uploadHelperUtils';

type AccessibilityUploadComponentProps = {
  audioLanguage: Language;
  captionLanguage: Language;
  hasAudioFile: () => boolean;
  hasCaptionFile: () => boolean;
  lexileLevelObject: ComboboxItem;
  setAudioLanguage: Dispatch<SetStateAction<Language>>;
  setCaptionLanguage: Dispatch<SetStateAction<Language>>;
  setLexileLevelObject: Dispatch<SetStateAction<ComboboxItem>>;
  setMp3File: (file: any) => void;
  setVttFile: (file: any) => void;
  vttFileName: string;
  setVttFileName: Dispatch<SetStateAction<string>>;
  mp3FileName: string;
  setMp3FileName: Dispatch<SetStateAction<string>>;
};

export const AccessibilityUploadComponent = ({
  audioLanguage,
  captionLanguage,
  hasAudioFile,
  hasCaptionFile,
  lexileLevelObject,
  setAudioLanguage,
  setCaptionLanguage,
  setLexileLevelObject,
  setMp3File,
  setVttFile,
  vttFileName,
  setVttFileName,
  mp3FileName,
  setMp3FileName
}: AccessibilityUploadComponentProps) => {
  const [captionUploadMessage, setCaptionUploadMessage] =
    useState('Attach caption file. This is optional to create a project, but required to publish.');
  const [audioUploadMessage, setAudioUploadMessage] =
    useState('Attach audio description file. This is optional to create a project, but required to publish.');
  const [lexileComboBoxEnabled, setLexileComboBoxEnabled] = useState(false);

  useEffect(() => {
    if (hasCaptionFile()) {
      setCaptionUploadMessage('Caption file is ready for upload.');
    } else {
      setCaptionUploadMessage('Attach caption file. This is optional to create a project, but required to publish.');
    }
  }, [hasCaptionFile()]);

  useEffect(() => {
    if (hasAudioFile()) {
      setAudioUploadMessage('Audio description file is ready for upload.');
    } else {
      setAudioUploadMessage('Attach audio description file. This is optional to create a project, but required to publish.');
    }
  }, [hasAudioFile()]);

  useEffect(() => {
    (hasCaptionFile()) ? setLexileComboBoxEnabled(true) : setLexileComboBoxEnabled(false);
  });

  useEffect(() => {
    (hasAudioFile()) ? setLexileComboBoxEnabled(true) : setLexileComboBoxEnabled(false);
  }, [hasAudioFile()]);

  const handleLexileSelectChange = (selectedItem: any) => {
    setLexileLevelObject(selectedItem);
  };

  const SoundSVGIcon = (
    <Icon
      SvgComponent={VolumeUpSVG}
      isDecorative
      __cssFor={{
        root: {
          width: '32px',
          marginRight: '1rem'
        }
      }}
    />
  );

  const PaperHollowSVGIcon = (
    <Icon
      SvgComponent={PaperHollowSVG}
      isDecorative
      __cssFor={{
        root: {
          width: '32px',
          marginRight: '1rem'
        }
      }}
    />
  );

  return (
    <>
      <section className='caption-file'>
        <div className='upload-message'>
          {PaperHollowSVGIcon} {captionUploadMessage}
        </div>
        <section id='caption-file-upload-row'>
          <Upload
            type='vtt'
            label='Caption'
            postponeUpload
            beforeUpload={() => undefined}
            afterUpload={() => undefined}
            handleFileChange={setVttFile}
            displayConfirm={false}
            onClick={() => undefined}
            fileName={vttFileName}
            setFileName={setVttFileName}
            filetype='.vtt'
          />
        </section>
        <section id='language-lexile-select-row'>
          <Combobox
            key={7}
            label='Captions language (required if captions attached)'
            selectedItem={captionLanguage}
            items={filteredLanguages}
            itemToString={(item: Language | null) => item ? item.label : ''}
            onSelectedItemChange={(e: any) => { setCaptionLanguage(e.selectedItem); }}
            __cssFor={{
              root: {
                marginRight: '1rem',
                width: '50%',
                opacity: lexileComboBoxEnabled ? '100%' : '50%'
              }
            }}
          />
          <LexileComboBox
            lexileLevelObject={lexileLevelObject}
            handleLexileSelectChange={handleLexileSelectChange}
            lexileComboBoxEnabled={lexileComboBoxEnabled}
          />
        </section>
      </section>
      <section className='audio-file'>
        <div className='upload-message audio'>
          {SoundSVGIcon} {audioUploadMessage}
        </div>
        <section id='audio-file-upload-row'>
          <Upload
            type='mp3'
            label='Audio description'
            postponeUpload
            beforeUpload={() => undefined}
            afterUpload={() => undefined}
            handleFileChange={setMp3File}
            displayConfirm={false}
            onClick={() => undefined}
            fileName={mp3FileName}
            setFileName={setMp3FileName}
            filetype='audio/mpeg'
          />
        </section>
        <section id='language-lexile-select-row-2'>
          <Combobox
            label='Audio language (required if audio file attached)'
            selectedItem={audioLanguage}
            items={filteredLanguages}
            itemToString={(item: Language | null) => item ? item.label : ''}
            onSelectedItemChange={(e: any) => { setAudioLanguage(e.selectedItem); }}
            __cssFor={{
              root: {
                marginRight: '1rem',
                width: '50%',
                opacity: lexileComboBoxEnabled ? '100%' : '50%'
              }
            }}
          />
        </section>
      </section>
    </>
  );
};
