import React, { Component } from 'react';

import { Checkbox } from '@newsela/angelou';
import _ from 'lodash';

import { ImageMetadata, PackagedImageMetadata } from 'types/assets';
import { Bundle, Embed } from 'types/publishing';

import { CustomDropdown, Grid, Input } from '../../../../hapyak-ui-toolkit';
import { assetService, stateController } from '../../../../services/stateController';
import { toPercent } from '../../../../services/utils';
import { ImagePicker } from '../../CommonComponents/ImagePicker/index';
import { EMBED_DIMENSION_OPTIONS, PRESENTATION_OPTIONS, TEXT_LOCATION_OPTIONS } from '../publishingOptions';

type State = any;

type PackagingEmbedSettingProps = {
  bundle: Bundle;
  embed: Embed;
  onThumbnailChange: (image: ImageMetadata) => void;
  handleChange: any;
  setThumbnailAspectRatio?: any;
  updateValue: any;
  handleToggle: any;
}

class PackagingEmbedSetting extends Component<PackagingEmbedSettingProps, State> {
  constructor (props: PackagingEmbedSettingProps) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount () {
    const { embed, onThumbnailChange } = this.props;
    const { title = '', description = '', width = 640, height = 480 } = embed;
    const { publishing = {} } = stateController.getCurrentData();
    const {
      embed: { thumbnail },
    } = publishing;

    this.setState(
      {
        title,
        description,
        width,
        height,
      },
      () => {
        if (!thumbnail) {
          const image = assetService.createImage(_.get(this, 'packagedImages[0].value'));
          image && onThumbnailChange(image);
        }
      }
    );
  }

  setThumbnailAspectRatio = (aspectRatio: any) => {
    const { setThumbnailAspectRatio } = this.props;
    if (typeof setThumbnailAspectRatio === 'function') {
      setThumbnailAspectRatio(toPercent(1, aspectRatio));
    }
  };

  get presentationOptions () {
    const { embed } = this.props;

    if (embed.embedType !== 'iframe') return PRESENTATION_OPTIONS;

    return PRESENTATION_OPTIONS.map((option) => {
      if (option.value !== 'lightbox') return option;

      return {
        ...option,
        disabled: true,
      };
    });
  }

  handleChange = (prop: any, e: any, {
    value
  }: any) => {
    this.setState({
      [prop]: value,
    });
  };

  onBlur = (prop: any, e: any) => {
    const { updateValue } = this.props;
    const value = e.target.value;

    if (updateValue) updateValue(prop, value);

    this.setState({
      [prop]: value,
    });
  };

  get textLocationOptions () {
    const { embed = { embedType: '' } } = this.props;
    const { embedType } = embed;

    if (embedType !== 'iframe') return TEXT_LOCATION_OPTIONS;

    return TEXT_LOCATION_OPTIONS.map((option) => {
      return {
        ...option,
        disabled: true,
      };
    });
  }

  get isCustomSrc () {
    return !!_.get(this.props, 'bundle.customSrc');
  }

  get packagedImages (): PackagedImageMetadata[] {
    const { bundle } = this.props;
    const { images = [], src, customSrc } = bundle;
    const base = customSrc || src;

    return images.map((img: string) => {
      const path = base + img.replace('./', '');

      return {
        key: path,
        image: path,
        value: path,
      };
    });
  }

  handleCheckboxToggle ({
    key,
    checked
  }: any) {
    const { handleToggle } = this.props;
    checked = !checked;
    handleToggle(key, null, { checked });
  }

  render () {
    const { embed, handleChange, onThumbnailChange } = this.props;
    const {
      dimensions,
      showTitle,
      showDescription,
      playButton = false,
      thumbnail = '',
      presentation = 'inline',
      textLocation,
      embedType,
    } = embed;

    const { title, description, width, height } = this.state;
    const isIframe = embedType === 'iframe';
    const isOverlay = textLocation === 'overlay';

    return (
      <Grid className='hy-packaging-embed-setting'>
        <Grid.Row>
          <Grid.Column>
            <Grid.Row className='simple'>
              <Grid className='hy-compact-grid-row'>
                <Grid.Row>
                  <Grid.Column>
                    <Grid className='hy-compact-grid-row'>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <CustomDropdown
                            openOnFocus
                            selection
                            button
                            value={dimensions}
                            label='Embed Dimensions'
                            options={EMBED_DIMENSION_OPTIONS}
                            onChange={handleChange.bind(this, 'dimensions')}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      {dimensions === 'fixed' && (
                        <Grid.Row
                          columns='equal'
                          style={{ borderBottom: '1px solid rgba(34, 36, 38, 0.15)' }}
                        >
                          <Grid.Column>
                            <Input
                              value={width}
                              type='number'
                              disabled={dimensions !== 'fixed'}
                              fluid
                              label='Width (px)'
                              onChange={this.handleChange.bind(this, 'width')}
                              onBlur={this.onBlur.bind(this, 'width')}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Input
                              value={height}
                              type='number'
                              disabled={dimensions !== 'fixed'}
                              fluid
                              label='height (px)'
                              onChange={this.handleChange.bind(this, 'height')}
                              onBlur={this.onBlur.bind(this, 'height')}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      {!isIframe && (
                        <>
                          <Grid.Row>
                            <CustomDropdown
                              openOnFocus
                              selection
                              button
                              value={presentation}
                              label='Style'
                              options={this.presentationOptions}
                              onChange={handleChange.bind(this, 'presentation')}
                            />
                          </Grid.Row>
                          <Grid.Row>
                            <CustomDropdown
                              openOnFocus
                              selection
                              button
                              value={textLocation}
                              label='Text Location'
                              options={this.textLocationOptions}
                              onChange={handleChange.bind(this, 'textLocation')}
                            />
                          </Grid.Row>
                        </>
                      )}
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Row>

            <Grid.Row>
              <Grid verticalAlign='middle' className='hy-compact-grid-row'>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <Checkbox
                      label='Show Title'
                      checked={showTitle}
                      onChange={this.handleCheckboxToggle.bind(this, { key: 'showTitle', checked: showTitle })}
                    />
                  </Grid.Column>
                  <Grid.Column width={11}>
                    <Input
                      value={title}
                      disabled={!showTitle}
                      fluid
                      onChange={this.handleChange.bind(this, 'title')}
                      onBlur={this.onBlur.bind(this, 'title')}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Row>
            <Grid.Row className='simple hy-margin-bottom'>
              <Grid verticalAlign='middle' className='hy-compact-grid-row'>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <Checkbox
                      label='Show Description'
                      checked={showDescription}
                      disabled={!isIframe && isOverlay}
                      onChange={this.handleCheckboxToggle.bind(this, { key: 'showDescription', checked: showDescription })}
                    />
                  </Grid.Column>
                  <Grid.Column width={11}>
                    <Input
                      value={description}
                      disabled={!showDescription || (!isIframe && isOverlay)}
                      fluid
                      onChange={this.handleChange.bind(this, 'description')}
                      onBlur={this.onBlur.bind(this, 'description')}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Row>
            <Grid.Row className='simple hy-margin-bottom' columns={2}>
              <Checkbox
                label='Show Embed Play Button'
                checked={playButton}
                onChange={this.handleCheckboxToggle.bind(this, { key: 'playButton', checked: playButton })}
              />
            </Grid.Row>
            <Grid.Row>
              <ImagePicker
                label='Thumbnail Override'
                image={this.isCustomSrc ? thumbnail : assetService.getImage(thumbnail)}
                setImage={onThumbnailChange}
                images={this.packagedImages}
                browse={false}
                isCustomSrc={this.isCustomSrc}
                setAspectRatio={this.setThumbnailAspectRatio}
              />
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default PackagingEmbedSetting;
