import { QuestionBuilder } from './QuestionBuilder';
import { QuizBuilder } from './QuizBuilder';

export const editorComponentMap = {
  QuizBuilder,
  QuestionBuilder,
};

export const getEditorComponentByName = (name: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return editorComponentMap[name] || null;
};
