import React, { Component } from 'react';

import { Button } from '../../../../hapyak-ui-toolkit';
import { assetService } from '../../../../services/stateController';
import { downloadToComputer } from '../../../../services/utils';
import { ChildrenProps } from '../../../../types/utils';
import { StylesheetModal } from './StylesheetModal';
import './StylesheetModal.scss';

type State = any;

export class StylesheetUpload extends Component<ChildrenProps, State> {
  constructor (props: ChildrenProps) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  downloadFile = () => {
    const src = this.currentStylesheet.src;
    return downloadToComputer(src, '');
  };

  get currentStylesheet () {
    return assetService.getCssArray()[0] || {};
  }

  render () {
    const { open } = this.state;

    return (
      <div>
        <div className='hy-margin-right hy-inline-block'>
          <Button content='Upload' onClick={this.handleOpen} primary />
        </div>
        <Button
          icon='external'
          onClick={this.downloadFile.bind(this)}
          disabled={!this.currentStylesheet.isControlled}
        />
        {open && <StylesheetModal close={this.handleClose} />}
      </div>
    );
  }
}
