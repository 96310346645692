import React from 'react';

import { omit } from 'lodash';

import defaults from '../../../DEFAULTS.json';
import { CustomDropdown } from '../../../hapyak-ui-toolkit';
import sessionClient from '../../../services/sessionClient';
import { PackagingOptions, PackagingOptionsProps } from './PackagingOptions';
const { PUBLISH_DESTINATIONS } = defaults;

export class PublishDestinationPicker extends PackagingOptions<PackagingOptionsProps> {
  render () {
    const { className, bundleProps } = this.props;
    const { publishTo } = bundleProps;

    return (
      <CustomDropdown
        selection
        button
        value={publishTo}
        label='Destination'
        onChange={this.onChange}
        options={this.destinations}
        className={className}
      />
    );
  }

  onChange = (e: any, {
    value
  }: any) => this.updateValue('publishTo', value);

  get destinations () {
    const { options } = this.props;
    const usingGoogleDrive = sessionClient.getUsingGoogleDrive();

    return options?.filter((o: any) => {
      if (o.key === PUBLISH_DESTINATIONS.GOOGLE_DRIVE && !usingGoogleDrive) {
        console.warn('publish to Google Drive not supported without logging in through Google');
        return false;
      }

      return true;
    })
      .map((o: any) => omit(o, 'settingsBlacklist'));
  }
}
