import _ from 'lodash';

import { ADJACENT_TARGETS } from '../layouts';
import { Annotation } from '../types/annotations';

export const MOBILE_BREAKPOINT = 769; // player rules use exclusive "less than", so for break at 768 we have to use less than 769;

type Config = {
  annotations?: Annotation[];
  display: any;
  [key: string]: any;
}

const DEFAULT_VERTICAL_STACK_ORDER = [
  {
    name: 'adjacent_top',
    order: 0,
    height: 50,
    changes: {
      parent: 'root',
      order: 0,
    },
  },
  {
    name: 'player',
    changes: {
      parent: 'root',
      order: 1,
    },
  },
  {
    name: 'adjacent_bottom',
    changes: {
      parent: 'root',
      height: 50,
      order: 2,
    },
  },
  {
    name: 'adjacent_left',
    changes: {
      parent: 'root',
      height: 50,
      order: 4,
    },
  },
  {
    name: 'adjacent_right',
    changes: {
      parent: 'root',
      height: 50,
      order: 3,
    },
  },
];

const DEFAULT_HORIZONTAL_STACK_ORDER = [
  { name: 'adjacent_left', changes: { parent: 'middle', order: 1 } },
  { name: 'player', changes: { parent: 'middle', order: 2 } },
  { name: 'adjacent_right', changes: { parent: 'middle', order: 3 } },
];

const NEWSELA_RESPONSIVE_VARIATIONS = {
  adjacent_left: {
    width: 100,
    height: 0,
    parent: 'root',
    order: 11,
  },
  player: {
    parent: 'root',
    order: 1,
  },
  adjacent_right: {
    parent: 'root',
    order: 2,
    height: 150,
  },
  adjacent_bottom: {
    parent: 'root',
    order: 10,
    height: 150,
  },
};

const createResponsiveVariation = ({
  changes
}: any) => {
  return [
    {
      conditions: [
        {
          state: 'orientation',
          value: 'portrait',
          assertion: 'equal',
        },
        {
          state: 'deviceType',
          value: 'mobile',
          assertion: 'equal',
        },
      ],
      changes: changes,
    },
    {
      conditions: [
        {
          state: 'viewportWidth',
          value: MOBILE_BREAKPOINT,
          assertion: 'lessThan',
        },
      ],
      changes: changes,
    },
  ];
};

const LAST_POSITION = 10;
const INDUCES_ANNOTATION_STACKING = {
  chapterMenu: {
    changes: {
      parent: 'root',
      height: 100,
      order: LAST_POSITION,
    },
  },
};

const NON_STACKABLE_ANNOTATIONS = ['isi'];

const findNonStackableAnnotations = ({
  annotations
}: any) =>
  annotations
    .map((annotation: Annotation) => NON_STACKABLE_ANNOTATIONS.includes(annotation.toolType) && annotation.target)
    .filter((res: any) => !!res);

const isChaptersWithISI = ({
  annotations
}: any) => {
  return Array.isArray(annotations)
    ? annotations.filter((annotation) => {
      const { toolType, target } = annotation;
      return Object.keys(INDUCES_ANNOTATION_STACKING).includes(toolType) && ADJACENT_TARGETS.includes(target);
    })
    : [];
};

const assessHorizontalZones = ({
  annotations
}: any) => {
  const nonStackables = findNonStackableAnnotations({ annotations });
  const containsNonStackLeft = nonStackables.includes('adjacent_left');
  const containsNonStackRight = nonStackables.includes('adjacent_right');
  return {
    adjacent_left: containsNonStackLeft,
    adjacent_right: containsNonStackRight,
    player: containsNonStackLeft || containsNonStackRight,
  };
};

const addStackingData = ({
  config,
  reqStackables
}: any) => {
  const { annotations, display } = config;
  const { layout } = display;
  const lastPanel = reqStackables.find((annotation: Annotation) => annotation.target);
  const lastPanelTarget = lastPanel.target;
  const createDefaultStackOrder = () => {
    return DEFAULT_VERTICAL_STACK_ORDER.map((panel) => {
      const isLastPanel = panel.name === lastPanelTarget;
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const changes = !isLastPanel ? {} : INDUCES_ANNOTATION_STACKING[lastPanel.toolType];
      return _.merge(panel, changes);
    });
  };

  const horizontalZones = assessHorizontalZones({ annotations });
  const defaultStackOrder = createDefaultStackOrder();
  const getStack = (name: any) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const requiresHorizontal = horizontalZones[name];
    return requiresHorizontal ? DEFAULT_HORIZONTAL_STACK_ORDER : defaultStackOrder;
  };

  const stackableTargets = ['player', ...ADJACENT_TARGETS];

  // Update layout objects as necessary to include change properties
  display.layout = layout.map((l: any) => {
    if (stackableTargets.includes(l.name)) {
      const stack = getStack(l.name);
      const info = stack.find((item) => item.name === l.name);
      const { changes } = info;
      if (changes) {
        l.variations = createResponsiveVariation({ changes });
      }
    }

    return l;
  });

  return config;
};

export const applyResponsiveLayoutChanges = (config = {} as Config) => {
  const { annotations = [] } = config;
  const stackable = isChaptersWithISI({ annotations });

  if (stackable.length) {
    config = addStackingData({ config, reqStackables: stackable });
  }

  const hasComponents = hasNewselaComponents(config);

  if (hasComponents) {
    config = applyNewselaResponsiveChanges(config);
  }

  return config;
};

export const hasNewselaComponents = ({
  annotations
}: any) => {
  return annotations.some((a: Annotation) => a.type === 'activitiespanel' && a.target !== 'player') &&
      annotations.some((a: Annotation) => a.type === 'smartcaptions' && a.target !== 'player');
};

export const applyNewselaResponsiveChanges = ({ annotations = [] as Annotation[], display = {} as any, ...config }) => {
  const activitiesPanel = annotations.find((a: Annotation) => a.type === 'activitiespanel');

  display.layout = display.layout.map((l: any) => {
    if (l.name in NEWSELA_RESPONSIVE_VARIATIONS) {
      l.variations = (l.variations || []).concat(createResponsiveVariation({
        changes: {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...NEWSELA_RESPONSIVE_VARIATIONS[l.name],
        },
      }));
    }
    return l;
  });

  annotations = annotations.map((a) => {
    if (a.type === 'quiz') {
      a.variations = (a.variations || []).concat(createResponsiveVariation({
        changes: {
          target: 'adjacent_right',
          appliesTo: a.appliesTo?.filter((at: any) => at !== (activitiesPanel as any)?.id) || [],
        },
      }));
    }

    if (a.type === 'smartcaptions' && a.target !== 'player') {
      a.variations = (a.variations || []).concat(createResponsiveVariation({
        changes: {
          target: 'adjacent_bottom',
        },
      }));
    }

    if (a.id === (activitiesPanel as any)?.id) {
      a.variations = (a.variations || []).concat(createResponsiveVariation({
        changes: {
          mode: 'invisible',
        },
      }));
    }

    return a;
  });

  return {
    ...config,
    display,
    annotations,
  };
};
