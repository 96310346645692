import { PlayerOnly } from '../../layouts/PlayerOnly';
import { horizontalSampleVideo } from '../defaults/sampleVideos';
import { preview } from './preview';

export const VideoOnly = {
  defaultAnnotations: [],
  layout: PlayerOnly,
  preview,
  video: horizontalSampleVideo,
};
