import React, { Component } from 'react';

import { TranslationServices } from '../TranslationServices';

type State = any;

type LocalizationServicesProps = {
  processing?: any;
};

export class LocalizationServices extends Component<LocalizationServicesProps, State> {
  constructor (props: LocalizationServicesProps) {
    super(props);
    this.state = {};
  }

  get translationServices () {
    const { processing } = this.props;
    return <TranslationServices processing={processing} />;
  }

  render () {
    return <div className=' hy-scrollable'>{this.translationServices}</div>;
  }
}
