import React from 'react';

import { Checkbox } from '@newsela/angelou';

import { PackagingOptions, PackagingOptionsProps } from './PackagingOptions';

export class SeoToggle extends PackagingOptions<PackagingOptionsProps> {
  render () {
    const { seoVideoObjectSchema } = this.bundleProps;

    return (
      <Checkbox
        label='Include Video SEO'
        checked={seoVideoObjectSchema}
        onChange={this.handleUpdate.bind(this, { key: 'seoVideoObjectSchema', checked: seoVideoObjectSchema })}
      />
    );
  }
}
