import React, { Component } from 'react';

import _ from 'lodash';
import { SemanticWIDTHS } from 'semantic-ui-react';

import { InfoBanner } from '../../../../../components/editors/AngelouComponents/InfoBanner';
import { Grid } from '../../../../../hapyak-ui-toolkit';
import { comm } from '../../../../../services/comm';
import { stateController } from '../../../../../services/stateController';
import { cascadeRemoveAssociatedAnnotations, findAnnotationById } from '../../../../../services/toolService';
import { Annotation } from '../../../../../types/annotations';
import { Size } from '../../../../../types/layout/forms';
import { QuestionType } from '../../../../../types/questions';
import { BasicSection } from '../../../CommonComponents/Menu/BasicSection';
import { createQuickAddButton } from '../../utils';
import { getQuizBuilderConfigByAnnotationToolType } from '../utils';
import { getQuestionDataByType } from './questionService';
import { QuizAnswerEditorList } from './QuizAnswerEditor/QuizAnswerEditorList';
import { QuizBuilderMenu } from './quizBuilderMenu';
import { findCollection, getQuizSubAnnotationPath } from './quizBuilderUtils';
import { QuizQuestionEditor } from './QuizQuestionEditor/QuizQuestionEditor';
import { QuizSectionEditor } from './QuizSectionEditor';
import { ReadOnlyAnswerList } from './ReadOnlyAnswerList';

const TOP_OF_GRID = 0;
const QUESTION_TOOL_TYPE = 'quiz';
const BUILDER_CONFIG = getQuizBuilderConfigByAnnotationToolType(QUESTION_TOOL_TYPE);

type State = any;

type QuizBuilderProps = {
  annotation: Annotation;
  annotationId: string;
  annotations: Annotation[];
  edit: any;
  questionType: QuestionType;
  setFromObject: any;
  setProperty: any;
  value: any;
};

export class QuizBuilder extends Component<QuizBuilderProps, State> {
  constructor (props: QuizBuilderProps) {
    super(props);
    this.state = { menuData: {}, newItemId: null };
  }

  componentDidMount () {
    this.setMenuDefaults();
  }

  componentDidUpdate (prevProps: QuizBuilderProps, prevState: State, snapshot: any) {
    const { annotations } = this.props;
    const prevAnnotations = prevProps.annotations;
    const annotationRemoved = annotations.length < prevAnnotations.length;

    if (annotationRemoved) {
      this.setMenuDefaults();
    }
  }

  get sectionEditorWidth (): SemanticWIDTHS {
    return 14;
  }

  get progressIsDisabled () {
    return _.get(BUILDER_CONFIG, 'progress.disabled');
  }

  get deleteQuestionDisabled () {
    return _.get(BUILDER_CONFIG, 'footer.delete.disabled');
  }

  get deleteQuestionButtonIsRendered () {
    return _.get(BUILDER_CONFIG, 'footer.delete.displayed');
  }

  get hasQuestionEditorMargin () {
    return _.get(BUILDER_CONFIG, 'editors.question.margin');
  }

  get builderMenuIsDisabled () {
    return _.get(BUILDER_CONFIG, 'builderMenu.disabled');
  }

  get builderMenu () {
    const { menuData = {} } = this.state;
    const { annotation, annotations } = this.props;

    return (
      <Grid.Column width={2}>
        <QuizBuilderMenu
          onItemAdded={this.onItemAdded}
          annotation={menuData.annotation}
          offsetHeight={menuData.offsetHeight}
          annotations={annotations}
          quizAnnotation={annotation}
        />
      </Grid.Column>
    );
  }

  setMenuDefaults = () => {
    let { annotation } = this.props;
    const sections = findCollection(annotation);

    if (!sections.length) return;

    annotation = findAnnotationById(sections[0]);
    this.setMenuData({ annotation, offsetHeight: TOP_OF_GRID });
  };

  setMenuData = ({
    annotation,
    offsetHeight
  }: any) => {
    const { menuData = {} } = this.state;
    annotation = annotation || menuData.annotation;
    offsetHeight = _.isNumber(offsetHeight) ? offsetHeight : menuData.offsetHeight;
    this.setState({ menuData: { annotation, offsetHeight } });
  };

  onItemAdded = (annotation: Annotation) => {
    const { id } = annotation;
    this.setState({ newItemId: id });
  };

  resetNewItemId = () => {
    this.setState({ newItemId: null });
  };

  deleteAnnotation = (id: string, annotations: Annotation[]) => {
    const { annotation: quiz } = this.props;
    annotations = annotations || this.props.annotations;
    const filteredAnnotations = cascadeRemoveAssociatedAnnotations(id, annotations);
    const firstSection = findAnnotationById(_.get(quiz, 'collections.quizSection[0]'));
    const path = getQuizSubAnnotationPath({ quiz, annotation: firstSection });

    comm.trigger('toSubAnnotation', path);
    stateController.updateProject('ux', { annotations: filteredAnnotations });
  };

  setFromObject = (obj: any, id: any) => {
    const { setFromObject } = this.props;
    if (typeof setFromObject === 'function') setFromObject(obj, id);
  };

  setProperty = (changes: any, annotation: Annotation) => {
    const { setProperty } = this.props;
    if (typeof setProperty === 'function') setProperty(changes, annotation);
  };

  get canDeleteSection () {
    const { annotation } = this.props;
    const collection = findCollection(annotation);
    return collection.length > 1; // Quiz must have 1 section per product
  }

  get comingSoon () {
    return <InfoBanner body='Coming soon.' />;
  }

  createQuestionEditors = (id: any) => {
    const { annotations, annotation: quizAnnotation, edit } = this.props;
    const { newItemId } = this.state;
    const annotation = findAnnotationById(id, annotations);
    const answers = findCollection(annotation);
    const addAnswerButton = createQuickAddButton(annotation, true, 'medium' as Size);
    const questionType = _.get(annotation, 'properties.type');
    const { editing = {}, disabled } = getQuestionDataByType(questionType);
    const quizAnswerEditor = disabled ? (
      this.comingSoon
    ) : (
      <QuizAnswerEditorList
        appliedTo={annotation}
        answerIds={answers}
        annotations={annotations}
        quizAnnotation={quizAnnotation}
        addAnswerButton={addAnswerButton}
        editing={editing}
        deleteAnnotation={this.deleteAnnotation}
        questionType={questionType}
        setFromObject={this.setFromObject}
        setProperty={this.setProperty}
        edit={edit}
      />
    );

    const readOnlyQuizAnswerList = (
      <ReadOnlyAnswerList
        questionType={questionType}
        answerIds={answers}
        annotations={annotations}
      />
    );

    const className = `hy-quiz-question-editor hy-margin-top ${
      this.hasQuestionEditorMargin ? 'hy-margin-left' : ''
    }`;

    return (
      <div key={id} className={className}>
        <QuizQuestionEditor
          hideDelete={!this.deleteQuestionButtonIsRendered}
          canDelete={!this.deleteQuestionDisabled}
          newItemId={newItemId}
          resetNewItemId={this.resetNewItemId}
          setMenuData={this.setMenuData}
          annotation={annotation}
          setFromObject={this.setFromObject}
          setProperty={this.setProperty}
          onDelete={this.deleteAnnotation.bind(this, id)}
          annotations={annotations}
          quizAnswerEditor={quizAnswerEditor}
          quizAnswerList={readOnlyQuizAnswerList}
        />
      </div>
    );
  };

  createSectionEditors = (id: any, idx: number, totalSections: any) => {
    const { annotations } = this.props;
    const annotation = findAnnotationById(id, annotations);
    const questions = findCollection(annotation);
    const label = `Section ${idx + 1} of ${totalSections}`;

    const questionEditors = questions.map((questionId: any) => this.createQuestionEditors(questionId));

    return (
      <div key={id} className='hy-quiz-group'>
        <QuizSectionEditor
          newItemId={this.state.newItemId}
          resetNewItemId={this.resetNewItemId}
          setMenuData={this.setMenuData}
          annotation={annotation}
          annotations={annotations}
          setFromObject={this.setFromObject}
          setProperty={this.setProperty}
          onDelete={this.deleteAnnotation.bind(this, id)}
          canDeleteSection={this.canDeleteSection}
          label={label}
        />
        <div>{questions.length ? questionEditors : <div className='hy-margin-bottom' />}</div>
      </div>
    );
  };

  get editor () {
    const { annotation } = this.props;
    const sections = findCollection(annotation);
    const totalSections = sections.length;

    const sectionEditors = sections.map((id: any, idx: number) => {
      return this.createSectionEditors(id, idx, totalSections);
    });

    return (
      <div className='hy-quiz-editor'>
        <Grid>
          <Grid.Row>
            <Grid.Column width={this.sectionEditorWidth}>
              <BasicSection renderedContent={<div>{sectionEditors}</div>} />
            </Grid.Column>
            {this.builderMenuIsDisabled ? null : this.builderMenu}
          </Grid.Row>
        </Grid>
      </div>
    );
  }

  render () {
    return this.editor;
  }
}
