import './index.scss';

import React, { BaseSyntheticEvent } from 'react';

import { TextArea } from '@newsela/angelou';
import _ from 'lodash';

import { MaturityRange, Project } from 'types/project';

import { Form } from '../../../hapyak-ui-toolkit';
import { assetService, stateController } from '../../../services/stateController';
import { ImageMetadata } from '../../../types/assets';
import { GradeBand } from '../../../types/gradeBand';
import { AngelouInput } from '../AngelouComponents/AngelouInput';
import { GradeBandRadioButtons } from '../AngelouComponents/GradeBandRadioButtons';
import { MaturityRangeSelector } from '../AssetsEditor/MaturityRangeSelector';
import { ExternalLinksTable } from '../CommonComponents/ExternalLinksTable';
import { ImagePicker } from '../CommonComponents/ImagePicker';
import { BasicSection } from '../CommonComponents/Menu/BasicSection';
import { ProjectEditorErrorMessage } from './ProjectEditorErrorMessage';

type State = any;

export type ProjectEditorProps = {
  [key: string]: any;
  project: Project;
  pathname: string;
  processing: any;
};

class ProjectEditor extends React.Component<ProjectEditorProps, State> {
  constructor (props: ProjectEditorProps) {
    super(props);
    this.state = this.getStateFromProps(props);
  }

  componentDidUpdate (previousProps: ProjectEditorProps, previousState: State) {
    const needsUpdate = _.keys(this.state).some((key) => {
      const stateHasNotChanged = previousState[key] === this.state[key];
      const propsChanged = this.props[key] !== previousProps[key];
      const propsDifferentThanState = this.props[key] !== previousState[key];
      return stateHasNotChanged && propsChanged && propsDifferentThanState;
    });
    if (needsUpdate) this.setState(this.getStateFromProps(this.props));
  }

  get projectId () {
    const { project } = stateController.getCurrentData();
    return project?.id;
  }

  get maturityRange () {
    const { maturityRange = [] } = this.state;
    return maturityRange;
  }

  setMaturityRange (value: MaturityRange) {
    this.setState({ maturityRange: value });
    const debouncedUpdate = _.debounce(() => {
      stateController.updateProject('project', { maturityRange: value });
    }, 250);
    debouncedUpdate();
  }

  setGradeBand (value: GradeBand) {
    this.setState({ gradeBand: value !== this.state.gradeBand ? value : '' }, () => {
      stateController.updateProject('project', { gradeBand: this.state.gradeBand });
    });
  }

  getStateFromProps = (props: ProjectEditorProps) => {
    const { title, tags, description, customVersion, refId, maturityRange, gradeBand } = props.project;

    return {
      title,
      tags,
      description,
      customVersion,
      refId,
      maturityRange,
      gradeBand
    };
  };

  updateField = (key: string, e: BaseSyntheticEvent) => {
    this.setState({ [key]: e.target.value });
  };

  saveField = (key: string) => stateController.updateProject('project', { [key]: this.state[key] });
  setImage = (image: ImageMetadata) => {
    stateController.updateProject('project', { posterImage: image.id });
  };

  get descriptionError () {
    const { description } = this.state;
    if (description === '' || description.trim().length === 0) {
      return 'Description is required.';
    } else {
      return '';
    }
  }

  get projectInputFields () {
    const { title, tags, description, customVersion, refId, gradeBand } = this.state;

    return (
      <Form className='hy-project-info-inputs'>
        <AngelouInput
          placeholder='Project/video name'
          label='Project/video name'
          value={title}
          onBlur={this.saveField.bind(this, 'title')}
          onChange={this.updateField.bind(this, 'title')}
          rows={1}
        />
        <TextArea
          label='Description'
          value={description}
          isRequired
          onBlur={this.saveField.bind(this, 'description')}
          onChange={this.updateField.bind(this, 'description')}
          autoAdjustHeight={false}
          error={this.descriptionError}
          rows={4}
          defaultHeight={72}
          touched
        />
        <Form.Group widths='equal'>
          <AngelouInput
            placeholder='Tags'
            label='Tags'
            value={tags}
            isRequired={false}
            onBlur={this.saveField.bind(this, 'tags')}
            onChange={this.updateField.bind(this, 'tags')}
          />
          <AngelouInput
            placeholder='Version'
            label='Version'
            value={customVersion}
            isRequired={false}
            onBlur={this.saveField.bind(this, 'customVersion')}
            onChange={this.updateField.bind(this, 'customVersion')}
          />
          <AngelouInput
            placeholder='Ref ID'
            label='Ref ID'
            value={refId}
            readOnly
            isRequired={false}
            onBlur={this.saveField.bind(this, 'refId')}
            onChange={this.updateField.bind(this, 'refId')}
          />
        </Form.Group>
        <Form.Group widths='equal' className='source'>
          <Form.Field
            width='8'
          >
            <MaturityRangeSelector range={this.maturityRange} setRange={this.setMaturityRange.bind(this)} />
          </Form.Field>
          <Form.Field>
            <GradeBandRadioButtons
              gradeBandRadioDisabled={false}
              handleGradeRadioChange={this.setGradeBand.bind(this)}
              gradeBand={gradeBand}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }

  render () {
    const { project, pathname, processing } = this.props;
    const headerImageThumbnail = assetService.getImage(project.posterImage);
    const { refId } = project;

    return (
      <div>
        <ProjectEditorErrorMessage
          pathname={pathname}
          processing={processing}
        />
        <BasicSection
          renderedContent={[
            {
              title: 'Project Information',
              renderedContent: this.projectInputFields,
            },
            {
              title: 'Links',
              renderedContent: (
                <ExternalLinksTable refId={refId} projectId={this.projectId} />
              )
            },
            {
              title: 'Header Image',
              renderedContent: (
                <ImagePicker
                  label='Header Image'
                  image={headerImageThumbnail}
                  setImage={this.setImage}
                />
              ),
            },
          ]}
        />
      </div>
    );
  }
}

export { ProjectEditor };
