import React from 'react';

import _ from 'lodash';

import { Project } from 'types/project';

import { DropZone } from '../../../hapyak-ui-toolkit';
import { comm } from '../../../services/comm';
import { Debouncer } from '../../../services/Debouncer';
import { media } from '../../../services/mediaController';
import { Annotation } from '../../../types/annotations';
import { DraggableAnnotation } from './DraggableAnnotation';

type State = any;

type TargetSectionProps = {
  activeAnnotation: string;
  activeTargets: any[];
  annotations: Annotation[];
  borderWidth: number;
  direction: string;
  dragStarted: any;
  edit: any;
  highlightedTargets: any;
  isOver: any;
  onClick: (id: string) => void;
  onDragEnd: any;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  project: Project;
  select: any;
  selectedAnnotations: string[];
  selectionTool: string;
  setZoneRef: any;
  target: string;
  transitionTime: number;
  visibleAnnotations: Annotation[];
  zoomOut: boolean;
}

class TargetSection extends React.Component<TargetSectionProps, State> {
  debouncedReset: any;
  selectionTools: any;
  zone: any;
  constructor (props: TargetSectionProps) {
    super(props);
    this.zone = React.createRef();
    this.state = { bounds: {}, playerRendered: 0, showSelectionTools: false };
    this.debouncedReset = new Debouncer(this.resetSelectionTools, 100);
  }

  componentDidMount () {
    const { target, setZoneRef } = this.props;
    setZoneRef(target, this.zone);
    comm.register('playerRendered', this.onPlayerRender);
    this.onPlayerRender();
    window.addEventListener('resize', this.debouncedReset.run);
  }

  componentWillUnmount () {
    comm.unregister('playerRendered', this.onPlayerRender);
    window.removeEventListener('resize', this.debouncedReset.run);
  }

  onMouseEnter = _.noop;
  onMouseLeave = _.noop;

  resetSelectionTools = () => {
    comm.trigger('stageSelection', null);
    this.setState({ showSelectionTools: false }, () => {
      // force rerender
      this.setState({ showSelectionTools: true });
    });
  };

  onPlayerRender = () => {
    const { playerRendered } = this.state;
    this.debouncedReset.run();
    this.setState({ playerRendered: playerRendered + 1 });
  };

  get playerElement () {
    const editorInstance = media.editorInstance;
    if (!(editorInstance && editorInstance.renderer)) return null;
    return editorInstance.renderer.rendered;
  }

  get playerBounds () {
    return this.playerElement ? this.playerElement.getBoundingClientRect() : {};
  }

  handleClick = (e: any) => {
    // prevent triggering window click when clicking this element since it triggers play on mousedown
    if (this.isPlayer) e.stopPropagation();
  };

  handleMouseDown = () => {
    if (this.isPlayer) {
      comm.trigger(media.playing ? 'pause' : 'play');
    }
  };

  get bounds () {
    const { playerRendered } = this.state;
    const { target } = this.props;
    const playerElement = this.playerElement;

    let bounds = {
      top: '',
      left: '',
      bottom: '',
      right: '',
      width: '',
      height: '',
      lineHeight: playerRendered,
    };

    if (!playerElement) return {};

    const playerBounds = this.playerBounds;
    const targetEl =
          playerElement.querySelector('.' + target) ||
          playerElement.querySelector('[data-target=' + target + '_target]');
    const {
      top,
      left,
      width,
      height
    }: any = targetEl ? targetEl.getBoundingClientRect() : {};
    const active = this.displayDraggables.length > 0;

    if (active || this.isPlayer) {
      // @ts-expect-error TS(2739): Type '{ top: string; left: string; width: any; hei... Remove this comment to see the full error message
      bounds = {
        top: top - playerBounds.top + 'px',
        left: left - playerBounds.left + 'px',
        width: width,
        height: height,
      };
    }

    return bounds;
  }

  get styles () {
    return {
      position: 'absolute',
      zIndex: 1,
      ...this.bounds,
    };
  }

  getDraggableAnnotationProps = _.noop;

  get displayDraggables () {
    const { activeAnnotation, visibleAnnotations, edit, onDragEnd, select, selectedAnnotations } = this.props;

    return visibleAnnotations.map((annotation: Annotation) => {
      const { id } = annotation;
      const active = activeAnnotation === id;
      const selected = selectedAnnotations.includes(id);

      return (
        <DraggableAnnotation
          key={id}
          annotation={annotation}
          edit={edit}
          active={active}
          select={select}
          dropZone={this.zone.current}
          selected={selected}
          onDragEnd={onDragEnd}
          // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
          {...this.getDraggableAnnotationProps(id)}
        />
      );
    });
  }

  get isPlayer () {
    const { target } = this.props;
    return target === 'player';
  }

  render () {
    const { target, direction, isOver, highlightedTargets, borderWidth, transitionTime, zoomOut } = this.props;
    const { playerRendered, showSelectionTools } = this.state;
    const highlight = highlightedTargets && (highlightedTargets.includes(target) ? 'rgba(144, 238, 144, 0.5)' : '');

    const canShowSelectionTools = playerRendered && showSelectionTools;

    return (
      <div
        className={`hy-content-target hy-target-${direction} ${isOver ? 'active' : ''}`}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        // @ts-expect-error TS(2322): Type '{ position: string; zIndex: number; }' is no... Remove this comment to see the full error message
        style={this.styles}
        onMouseDown={this.handleMouseDown}
        onClick={this.handleClick}
      >
        <DropZone
          ref={this.zone}
          id={target}
          fill={!this.isPlayer}
          highlight={highlight}
          outline={zoomOut}
          borderWidth={borderWidth}
          transitionTime={transitionTime}
        >
          {canShowSelectionTools && this.selectionTools}
          {this.displayDraggables}
        </DropZone>
      </div>
    );
  }
}

export { TargetSection };
