import axios from 'axios';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'node... Remove this comment to see the full error message
import * as webvtt from 'node-webvtt';

import { VideoAsset } from 'types/assets';
import { UploadContentType } from 'types/uploadContentType';

import DEFAULTS from '../DEFAULTS.json';
import { JobError } from '../errors/JobError';
import { comm } from './comm';
import { media } from './mediaController';
import { persistenceClientFactory } from './persistence/persistenceClientFactory';
import { resetProcess } from './persistence/platformService';
import { monitorProcess, ProcessRunner } from './processRunner';
import { assetService, stateController } from './stateController';
import { getExtension, getFileName, getMimeType } from './utils';
import visService from './visService';

export const cleanFileName = (fileName: string) => {
  return fileName.replace(/[^\w.]+/g, '');
};

const stopAllVideoProcesses = () => {
  // TODO: refactor when multiple videos are supported. We won't want to stop the processes if multiple videos can be uploaded at once
  ProcessRunner.stop(DEFAULTS.UPLOAD_KEY);
  ProcessRunner.stop(DEFAULTS.TRANSCODE_KEY);
  resetProcess(DEFAULTS.LOCALIZE_PLATFORM_KEY);
};

export const uploadVideo = async (
  projectId: string | undefined,
  file: VideoAsset | Record<string, unknown>,
  language: any,
  lexileLevel: any,
):
Promise<string> => {
  stopAllVideoProcesses();

  if (!file.name || typeof file.name !== 'string') {
    return '';
  }

  const ext = getExtension(file.name);
  const contentType = getMimeType(ext);
  const src = await uploadItem(projectId, file, { contentType, mediaKeyPath: 'files/demo/video' });

  if (!src) {
    throw new JobError('Upload failed');
  }
  const videoObj = {
    ...media.video,
    formats: { [ext]: src },
    name: getFileName(src),
    language,
    lexileLevel,
  };

  assetService.setVideo(
    videoObj,
    true
  );

  comm.trigger('updateMp4');

  visService.transcode(src, language);

  return src;
};

export const remoteUploadVideo = async (videoUrl: string) => {
  visService.remoteTranscodeUpload(videoUrl).then(() => {
    comm.trigger('processComplete');
  });
};

export const uploadArb = (projectId: any, file: any, type: any, language: any, options: any) => {
  return updateArbFileLocale(file, type, language, 'arb', options).then((file) => {
    return uploadFileType('arb', projectId, file, 'application/json');
  });
};

export const uploadVtt = (projectId: any, file: any, type: any, language: any, updatedCues?: any, existingSrc?: any) => {
  return updateVttFileLanguage(file, type, language, 'vtt', updatedCues, existingSrc).then((file) => {
    return uploadFileType('vtt', projectId, file, 'text/vtt');
  });
};

export const uploadMp3 = (file: any) => {
  return uploadFile(file, 'mp3', 'audio/mpeg', false);
};

const uploadFileType = async (type: 'vtt' | 'arb', projectId: any, file: any, contentType: UploadContentType) =>
  await uploadItem(projectId, file, { mediaKeyPath: `files/demo/${type}`, contentType });

const getLocalizationFileName = (language: any, type: any, ext: any) => {
  type = !type ? '' : '_' + type.toLowerCase().replace(/ /g, '_');
  return `${language}${type}.${ext}`;
};

const updateParsedFile = ({
  parsedData,
  language,
  lexileLevel
}: any) => {
  const LANGUAGE_PROP = '@@locale';
  parsedData[LANGUAGE_PROP] = language;

  const scope = { language, level: lexileLevel };

  if (parsedData['@@scope']) {
    parsedData['@@scope'] = { ...parsedData['@@scope'], ...scope };
  } else {
    parsedData = { ...{ '@@scope': scope }, ...parsedData };
  }

  return parsedData;
};

const updateArbFileLocale = (file: any, type: any, language: any, ext: any, options: any) => {
  const { lexileLevel = '' } = options;

  return uploadLocal(file).then((file) => {
    try {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      const parsed = JSON.parse(file);
      const updatedData = updateParsedFile({ parsedData: parsed, language, lexileLevel });
      return jsonToFile(updatedData, getLocalizationFileName(language, type, ext));
    } catch (e) {
      return Promise.reject(new Error('something bad happened'));
    }
  });
};

const formatCues = (cues: any) => {
  const hasExistingStyles = (cue: any) => cue.styles && !cue.position;
  const modifiedCues = cues.map((cue: any) => {
    if (hasExistingStyles(cue)) return cue;
    const styles = cue.position === 'top' ? 'line:1' : '';
    return { ...cue, styles };
  });

  return modifiedCues.sort((a: any, b: any) => a.start - b.start); // `node-webvtt` requires a chronalogically sorted list
};

export const replaceCommasWithPeriods = (fileContent: any) => {
  return fileContent.search((((/[0-9]{2}:[0-9]{2}:[0-9]{2},[0-9]+/)))) !== -1
    ? fileContent.replace(/([0-9]{2}:[0-9]{2}:[0-9]{2}),([0-9]+)/g, '$1' + '.' + '$2')
    : fileContent;
};

const formatFromExistingVttData = (fileContent: any, updatedCues: any, LANGUAGE_PROP: any, language: any) => {
  const normalizedFileContent = replaceCommasWithPeriods(fileContent);
  const parsed = webvtt.parse(normalizedFileContent, { meta: true });
  const cues = updatedCues ? formatCues(updatedCues) : parsed.cues;
  const updated = { ...parsed, meta: { ...parsed.meta, [LANGUAGE_PROP]: language }, cues };
  const { strict, errors, ...strippedData } = updated;
  return strippedData;
};

const formatNewVttData = (cues: any, kind: any, language: any) => {
  return {
    cues,
    meta: {
      Kind: kind,
      Language: language,
    },
    valid: true,
  };
};

const updateVttFileLanguage = (file: any, type: any, language: any, ext: any, cues: any, existingSrc: any) => {
  const LANGUAGE_PROP = 'Language';
  return uploadLocal(file).then((file) => {
    try {
      let data;

      if (existingSrc || !cues) {
        data = formatFromExistingVttData(file, cues, LANGUAGE_PROP, language);
      } else {
        data = formatNewVttData(cues, type.toLowerCase(), language);
      }

      const vttText = webvtt.compile(data);
      return textToVttFile(vttText, getLocalizationFileName(language, null, ext));
    } catch (error) {
      console.error('updateVttFileLanguage error:', error);
      return Promise.reject(error);
    }
  });
};

export const jsonToFile = (json: any, name: any) => {
  const file = new Blob([JSON.stringify(json, null, 2)], { type: 'application/json' });
  (file as any).name = name;
  return file;
};

export const textToVttFile = (text: any, name: any) => {
  const file = new Blob([text], { type: 'text/vtt' });
  (file as any).name = name;
  return new File([file], name);
};

export const uploadItem = monitorProcess(
  DEFAULTS.UPLOAD_KEY,
  async (projectId: any, file: any, {
    contentType,
    mediaKeyPath = 'file/demo/video'
  }: any) => {
    const persistenceClient = persistenceClientFactory.getClient();
    const key = `${mediaKeyPath}/${Date.now()}/${cleanFileName(file && file.name)}`;
    const url = await persistenceClient.getUploadAuthorization({ key });
    const blob = file.slice(0, file.size, 'application/octet-stream');
    const [baseUrl] = url.split('?');
    const uploadSettings = {
      headers: {
        'content-type': 'application/octet-stream',
      },
      timeout: 0,
      onUploadProgress (progressEvent: ProgressEvent) {
        if (progressEvent.total > 0) {
          const percentCompleted: number = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          comm.trigger('percentUploadProgress', percentCompleted);
        }
      },
    };

    if (contentType) {
      uploadSettings.headers['content-type'] = contentType;
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      uploadSettings.headers['content-disposition'] = `attachment; filename="${cleanFileName(file && file.name)}"`;
    }

    try {
      await axios.put(url, blob, uploadSettings);
    } catch (err) {
      const error: any = err;
      if (error.request) {
        console.error('axios.put request error. contentType:', contentType, error);
      } else if (error.response) {
        console.error('axios.put response error. contentType:', contentType, error);
      } else {
        console.error('Error occured while setting up the axios.put request. contentType:', contentType, error);
      }
      comm.trigger('axiosUploadError', contentType, error);
      throw error;
    }
    return baseUrl;
  }
);

export const uploadJpeg = async (file: any, type: any, mimetype: any, local: any) => {
  const { id } = stateController.getCurrentData('project');
  const result = await uploadItem(id, file, {
    mediaKeyPath: `files/images/${type}`,
    contentType: 'image/jpeg',
  });

  return result;
};

export const uploadFile = async (file: any, type?: string, mimetype?: string, local?: boolean) => {
  const { id } = stateController.getCurrentData('project');
  const ext = file.name
    .split('.')
    .pop()
    .toLowerCase();
  const mimeMap = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    mp3: 'audio/mpeg',
    gif: 'image/gif',
    svg: 'image/svg+xml',
    pdf: 'application/pdf',
    css: 'text/css',
  };
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const contentType = mimeMap[ext];

  const result = await uploadItem(id, file, {
    mediaKeyPath: `files/${type}`,
    contentType,
  });

  return result;
};

const uploadLocal = (file: any) => {
  return new Promise((resolve) => {
    const fr = new FileReader();

    fr.onload = (e) => {
      resolve(e.target?.result);
    };

    fr.readAsText(file);
  });
};
