import React from 'react';

import _ from 'lodash';
import { SemanticICONS } from 'semantic-ui-react';

import { updateAnnotations } from '../../../../services/toolService';
import { Annotation } from '../../../../types/annotations';
import { ToolbarButton } from '../Toolbar/ToolbarButton';

type TimelineToolbarProps = {
  selected: any;
};

export class TimelineToolbar extends React.Component<TimelineToolbarProps> {
  get isDisabled () {
    return !(this.selected.length > 1);
  }

  get selected () {
    return this.props.selected;
  }

  createMsg = (action: 'Align' | 'Distribute', display: 'start' | 'end') => {
    return `${action} ${display} times${this.isDisabled ? ' (Nothing selected)' : ''}`;
  };

  createAlignButton = (direction: 'left' | 'right', display: 'start' | 'end', callback: () => void) => {
    const tooltip = this.createMsg('Align', display);
    return (
      <ToolbarButton
        disabled={this.isDisabled}
        icon={`align ${direction}` as SemanticICONS}
        tooltip={tooltip}
        onClick={callback}
      />
    );
  };

  createDistributeButton = (callback: any) => {
    const tooltip = this.createMsg('Distribute', 'end');
    return <ToolbarButton disabled={this.isDisabled} icon='barcode' tooltip={tooltip} onClick={callback} />;
  };

  alignLeft = () => {
    const firstStart = _.orderBy(this.selected, 'start')[0].start;

    const updatedAnnotations = this.selected.map((a: Annotation) => {
      return {
        ...a,
        start: firstStart,
      };
    });

    updateAnnotations(updatedAnnotations);
  };

  alignRight = () => {
    const lastEnd = _.orderBy(this.selected, 'end', 'desc')[0].end;

    const updatedAnnotations = this.selected.map((a: any) => {
      return {
        ...a,
        end: lastEnd,
      };
    });

    updateAnnotations(updatedAnnotations);
  };

  distribute = () => {
    if (this.selected.length <= 1) return;
    const updatedAnnotations = _.orderBy(this.selected, 'start').map((a, idx, arr) => {
      return {
        ...a,
        end: arr[idx + 1] !== undefined ? arr[idx + 1].start - 0.01 : a.end,
      };
    });

    updateAnnotations(updatedAnnotations);
  };

  render () {
    return (
      <div className='hy-toolbar'>
        {this.createAlignButton('left', 'start', this.alignLeft)}
        {this.createAlignButton('right', 'end', this.alignRight)}
        {this.createDistributeButton(this.distribute)}
      </div>
    );
  }
}
