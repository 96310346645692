import { templateMap } from './templateMap';

const DEFAULT_ID = 1;

export const getTemplate = (id: number | string) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return JSON.parse(JSON.stringify(templateMap[id || DEFAULT_ID]));
};

export const getTemplates = () => {
  return Object.keys(templateMap).map(getTemplate);
};
