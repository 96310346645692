import sessionClient from '../../../../services/sessionClient';
import { stateController } from '../../../../services/stateController';

export const getLocatorString = () => {
  const { session } = sessionClient;
  const { user = {} } = session;
  const { group = {} } = user;
  const { customerId = '' } = group;
  const { id = '' } = stateController.getCurrentData('project');
  const isInvalid = ![customerId, group.id, id].every((val) => !!val);

  return isInvalid ? '' : ['locator://c=', customerId, ';g=', group.id, ';p=', id].join('');
};
