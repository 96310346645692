import React from 'react';

import { IdleTimerProvider as IdleTimer } from 'react-idle-timer';

import { Modal } from '../../hapyak-ui-toolkit/index';
import sessionClient from '../../services/sessionClient';
import { ChildrenProps } from '../../types/utils';

const TIMEOUT_MINUTES = 7 * 24 * 60; // min
const ONE_SECOND = 1000; // ms
const ONE_MINUTE = 60; // s
const DEBOUNCE_MS = 0.25 * ONE_SECOND; // ms
const TIMEOUT_MS = TIMEOUT_MINUTES * ONE_MINUTE * ONE_SECOND; // ms
const LOGOUT_COUNTDOWN_SECONDS = ONE_MINUTE;

type State = any; // seconds

export class IdleTimeout extends React.Component<ChildrenProps, State> {
  idleTimer: any;
  onAction: any;
  constructor (props: ChildrenProps) {
    super(props);
    this.idleTimer = null;
    this.state = {
      idle: false,
      logoutCountdown: LOGOUT_COUNTDOWN_SECONDS,
    };
  }

  countdown = () => {
    const { idle, logoutCountdown } = this.state;

    if (!idle) return;

    if (logoutCountdown <= 0) {
      sessionClient.logout();
      return;
    }

    setTimeout(() => {
      this.setState({ logoutCountdown: logoutCountdown - 1 }, this.countdown);
    }, ONE_SECOND);
  };

  onActive = () => {
    this.setState({ idle: false, logoutCountdown: LOGOUT_COUNTDOWN_SECONDS });
  };

  onIdle = () => {
    this.setState({ idle: true }, this.countdown);
  };

  render () {
    const { idle, logoutCountdown } = this.state;
    return (
      <div>
        <IdleTimer
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={DEBOUNCE_MS}
          timeout={TIMEOUT_MS}
        />
        {idle && (
          <Modal open dimmer size='small'>
            <Modal.Header>Are you still here?</Modal.Header>
            <Modal.Content>
              <div>You have been idle for {TIMEOUT_MINUTES} minutes.</div>
              <div className='hy-margin-top'>
                You will be logged out in {logoutCountdown} seconds unless you interact with the app.
              </div>
            </Modal.Content>
          </Modal>
        )}
      </div>
    );
  }
}
