import { sortBy } from 'lodash';

import { Maturity, MaturityRange } from 'types/project';

export const removeStartExpanded = (props: any) => {
  const { _startExpanded, ...otherProps } = props;
  return otherProps;
};

export const findMaturityRangeMinMax = (maturityRange: MaturityRange) => {
  if (!maturityRange || !maturityRange.length) return [];

  const sortedMaturityRange = maturityRange.length > 1
    ? sortBy(maturityRange, 'gradeMax')
    : maturityRange;

  const gradeMin = (sortedMaturityRange[0] as Maturity).gradeMin;
  const gradeMax = (sortedMaturityRange[sortedMaturityRange.length - 1] as Maturity).gradeMax;

  return [gradeMin, gradeMax];
};

export const getMaturityRangeForPublication = (maturityRangeUI: Maturity[]): [Maturity, Maturity] | [] => {
  if (!maturityRangeUI || !maturityRangeUI.length) return [];
  const maturityList: Maturity[] = sortBy(maturityRangeUI, 'gradeMin')
    .map((maturity) => ({
      level: maturity.level,
      description: maturity.description,
      gradeMin: maturity.gradeMin,
      gradeMax: maturity.gradeMax
    }));
  return [maturityList[0], maturityList[maturityList.length - 1]];
};
