export const toolsWhiteList = [
  'powerWords',
  'quiz',
  'question',
  'chapterMenu',
  'contentExplorer',
  'button',
  'note',
  'image',
  'openForm',
  'richText',
  'section',
  'iframe',
  'smartCaptions',
  'hotlink',
];
