import './index.scss';

import React, { Component } from 'react';

import { Button, DownloadSVG, Icon, UploadSVG } from '@newsela/angelou';

import DEFAULTS from '../../../DEFAULTS.json';
import { CustomDropdown, Grid } from '../../../hapyak-ui-toolkit';
import { comm } from '../../../services/comm';
import { LEXILE_LEVEL_DEFAULT } from '../../../services/lexileService';
import { media } from '../../../services/mediaController';
import { setDropdownPreferences } from '../../../services/saveUtils';
import { stateController } from '../../../services/stateController';
import {
  createOrderedLanguageOptions,
  createOrderedLexileLevelOptions,
  getFileType,
  getService,
  hashData,
  syncFiles
} from '../../../services/translation/utils';
import { downloadToComputer, toOption } from '../../../services/utils';
import { LocalizationType } from '../../../types/localization';
import { InfoBanner } from '../AngelouComponents/InfoBanner';
import { TrashButton } from '../AngelouComponents/TrashButton';
import WarningBanner from '../AngelouComponents/WarningBanner';
import { ArbUploadModal } from '../AssetsEditor/ArbUploadModal';
import { VttUploadModal } from '../AssetsEditor/VttUploadModal';
import { BasicSection } from '../CommonComponents/Menu/BasicSection';
import { ArbAnnotationEditor } from '../TranslationEditor/ArbAnnotationEditor';
import { ArbEditor } from '../TranslationEditor/ArbEditor';
import { VttEditor } from '../TranslationEditor/VttEditor';

const SUB_PROPS = ['identifier', 'start', 'end', 'position'];
const ALL_PROPS = [...SUB_PROPS, 'text'];
const MANUALLY_CREATED_BASE_FILE_TYPES_ALLOWED = [DEFAULTS.VTT_CAPTIONS]; // limit ability to create base files manually

const LIST_TAB_INDEX = 2;

type State = any;

type LocalizationEditorProps = {
  allAvailableCombos: any;
  baseLanguage: any;
  canEdit: boolean;
  handleMenuToggle: any;
  onUpdate: any;
  selectedLanguage: string;
  selectedType: LocalizationType;
  lexileLevel: string;
  projectArbs: any;
  projectVtts: any;
  updateActiveSettings: any;
};

export class LocalizationEditor extends Component<LocalizationEditorProps, State> {
  constructor (props: LocalizationEditorProps) {
    super(props);

    this.state = {
      editModalOpen: false,
      hash: '',
      lastSavedHash: '',
      subHash: '',
      lastSavedSubHash: '',
      baseLanguage: [],
      translatedLanguage: [],
      isSyncing: false,
      identifiers: [],
      errorMessage: '',
    };
  }

  componentWillUnmount () {
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
    this.beforeDataRemoval();
  }

  get fileType () {
    return getFileType(this.props.selectedType);
  }

  get service () {
    return getService(this.props.selectedType);
  }

  openUploadModal = () => {
    this.setState({ editModalOpen: true });
  };

  downloadFile = () => {
    const src = this.selectedFile.src;
    src && downloadToComputer(src, '');
  };

  getCurrentSrc = () => {
    const { selectedLanguage } = this.props;
    if (!this.hasSelectedFile) return null;

    const item = this.files.find((file) => file.language === selectedLanguage);
    return item && item.src;
  };

  handleUpdate = (warnModalOpen: any) => {
    const { onUpdate } = this.props;
    if (onUpdate) onUpdate({ isDirty: this.isDirty, warnModalOpen: !!warnModalOpen });
  };

  onUpdate = (data: any) => {
    const hashedData = hashData(data, ALL_PROPS);
    const { baseLanguage, translatedLanguage } = data;
    // @ts-expect-error TS(2345): Argument of type '(warnModalOpen: any) => void' is... Remove this comment to see the full error message
    this.setState({ hash: hashedData, baseLanguage, translatedLanguage }, this.handleUpdate);
  };

  onEditorUpdate = (data: any) => {
    const identifiers = data.baseLanguage.map((i: any) => i.identifier);
    const hashedData = hashData(data, ALL_PROPS);
    const subHash = hashData(data, SUB_PROPS);
    const { baseLanguage, translatedLanguage, errorMessage } = data;

    this.setState({
      identifiers,
      subHash,
      lastSavedSubHash: subHash,
      lastSavedHash: hashedData,
      hash: hashedData,
      baseLanguage,
      translatedLanguage,
      errorMessage,
    });
  };

  get editor () {
    const { selectedType, lexileLevel, selectedLanguage } = this.props;

    if (!selectedType || !this.baseFile) return null;

    const editorProperties = {
      onEditorUpdate: this.onEditorUpdate,
      hideTrash: true,
      disableTimeInput: true,
      baseObject: this.baseFile,
      translatedObject: this.selectedFile,
      isEditingTranslation: this.isEditingTranslation,
      onUpdate: this.onUpdate,
      allowsBaseFileCreation: this.allowsBaseFileCreation,
      lexileLevel: lexileLevel,
      language: selectedLanguage,
      type: selectedType,
    };

    const editorComponentMap = {
      [DEFAULTS.ARB_ANNOTATION_TEXT]: <ArbAnnotationEditor {...editorProperties} />,
      [DEFAULTS.ARB_VIDEO_TEXT]: <ArbEditor {...editorProperties} />,
      [DEFAULTS.VTT_CAPTIONS]: <VttEditor {...editorProperties} />,
    };

    return editorComponentMap[selectedType];
  }

  get files () {
    const { projectArbs, projectVtts, selectedType } = this.props;
    return [...projectArbs, ...projectVtts].filter((item) => item.type === selectedType);
  }

  get types () {
    return [
      DEFAULTS.ARB_ANNOTATION_TEXT,
      // Comment out per VIDEO-439
      // DEFAULTS.ARB_VIDEO_TEXT,
      DEFAULTS.VTT_CAPTIONS
    ];
  }

  onClose = () => {
    this.setState({ editModalOpen: false });
  };

  afterUpload = ({
    language,
    lexileLevel,
    args
  }: any) => {
    const { selectedType } = this.props;
    this.service.setAsset({ language, src: args[0], lexileLevel, type: selectedType, videoId: media.id });
    comm.trigger('updatePlayer');
  };

  get saveChangesWarning () {
    return <WarningBanner body='Changes will be deleted. Save changes before continuing.' />;
  }

  get editModal () {
    const { lexileLevel, selectedType, selectedLanguage } = this.props;
    const Component = this.fileType === 'vtt' ? VttUploadModal : ArbUploadModal;
    const selected = this.selectedFile || { language: selectedLanguage, id: 'disabledId' };

    return (
      <Component
        color='green'
        header={this.hasSelectedFile ? 'Update' : 'Add'}
        compactDisplay
        selected={selected}
        type={selectedType}
        lexileLevel={lexileLevel}
        onClose={this.onClose}
        forceOpen
        afterUpload={this.afterUpload}
        message={this.isDirty ? this.saveChangesWarning : null}
      />
    );
  }

  handleTypeChange = (type: any) => {
    this.beforeDataRemoval({ selectedType: type });
    setDropdownPreferences({ [DEFAULTS.LOCALIZATION_EDITOR_TYPE_DROPDOWN]: type });
  };

  handleLanguageChange = (language: any) => {
    this.beforeDataRemoval({ selectedLanguage: language });
    setDropdownPreferences({ [DEFAULTS.LOCALIZATION_EDITOR_LANGUAGE_DROPDOWN]: language });
  };

  handleLexileLevelChange = (lexileLevel: any) => {
    this.beforeDataRemoval({ lexileLevel });
    setDropdownPreferences({ [DEFAULTS.LOCALIZATION_EDITOR_LEXILE_LEVEL_DROPDOWN]: lexileLevel });
  };

  get languageOptions () {
    const { selectedLanguage } = this.props;
    const languages = this.files.map((file) => file.language);
    return createOrderedLanguageOptions(selectedLanguage, languages);
  }

  get lexileLevelOptions () {
    const { lexileLevel } = this.props;
    const lexileLevels = this.files.map((file) => file.lexileLevel);
    return createOrderedLexileLevelOptions(lexileLevel, lexileLevels);
  }

  get baseFile () {
    return this.files.find((file) => file.language === this.props.baseLanguage && file.lexileLevel === this.videoLexileLevel);
  }

  get hasBaseFile () {
    return !!this.baseFile;
  }

  get allowsBaseFileCreation () {
    return MANUALLY_CREATED_BASE_FILE_TYPES_ALLOWED.includes(this.props.selectedType);
  }

  get selectedFile () {
    const { lexileLevel, selectedLanguage } = this.props;
    const file = this.files.find((f) => {
      const languageMatch = f.language === selectedLanguage;
      const lexileLevelMatch = f.lexileLevel === lexileLevel;
      return languageMatch && lexileLevelMatch;
    });
    return file;
  }

  get hasSelectedFile () {
    return !!this.selectedFile;
  }

  get videoLexileLevel () {
    return media.video.lexileLevel || LEXILE_LEVEL_DEFAULT;
  }

  get isEditingTranslation () {
    const { baseLanguage, lexileLevel, selectedLanguage } = this.props;
    const languageMismatch = selectedLanguage !== baseLanguage;
    const lexileLevelMismatch = lexileLevel !== this.videoLexileLevel;
    return languageMismatch || lexileLevelMismatch;
  }

  get isDirty () {
    const { hash, lastSavedHash } = this.state;
    return hash !== lastSavedHash;
  }

  syncLanguages = async ({
    filter
  }: any) => {
    const { lexileLevel } = this.props;
    const projectId = stateController.getCurrentData('project').id;

    const files = this.files.filter((file) => file.language !== filter);

    this.setState({ isSyncing: true });
    await syncFiles(files, this.fileType, this.service, this.baseFile.src, projectId, lexileLevel);
    this.setState({ isSyncing: false });
    comm.trigger('updatePlayer');
  };

  save = async () => {
    const { baseLanguage, translatedLanguage, hash, lastSavedSubHash } = this.state;
    const { lexileLevel, selectedLanguage, selectedType } = this.props;
    const { identifiers } = this.state;
    const subHash = hashData(
      {
        baseLanguage: identifiers.map((i: any) => baseLanguage.find((item: any) => item.identifier === i)),
        translatedLanguage: identifiers.map((i: any) => translatedLanguage.find((item: any) => item.identifier === i)),
      },
      SUB_PROPS
    );

    const requiresFileSync = this.isEditingTranslation ? false : subHash !== lastSavedSubHash;

    this.setState({ lastSavedHash: hash, lastSavedSubHash: subHash }, () =>
      this.handleUpdate(false)
    );

    const items = this.isEditingTranslation
      ? this.service.clearRepeatedTranslatedValues(baseLanguage, translatedLanguage)
      : baseLanguage;
    const src = this.getCurrentSrc();
    const file = await this.service.createFileFromData({ src, items });

    const srcLocation = await this.service.upload({
      projectId: stateController.getCurrentData('project').id,
      lexileLevel,
      file,
      selectedType,
      selectedLanguage,
      items,
      existingSrc: !!src,
    });

    this.service.setAsset({
      language: selectedLanguage,
      lexileLevel,
      src: srcLocation,
      type: selectedType,
      videoId: media.id,
    });

    comm.trigger('updatePlayer');

    if (requiresFileSync) this.syncLanguages({ filter: selectedLanguage });
  };

  reloadLanguage = () => {
    const { selectedType, updateActiveSettings, selectedLanguage } = this.props;
    const { lastSavedHash } = this.state;
    if (updateActiveSettings) {
      updateActiveSettings({ selectedLanguage: '', selectedType: '' }, null).then(() => {
        updateActiveSettings({ selectedLanguage, selectedType }, LIST_TAB_INDEX).then(() => {
          this.setState({ hash: lastSavedHash }, () => {
            this.handleUpdate(false);
          });
        });
      });
    }
  };

  get saveStatus () {
    if (this.isDirty) this.save();
    return null;
  }

  delete = () => {
    (this.service as any).deleteAsset(this.selectedFile);
    this.reloadLanguage();
  };

  beforeDataRemoval = (data: any) => {
    const { updateActiveSettings } = this.props;

    if (this.isDirty) {
      this.handleUpdate(true);
      return null;
    }

    updateActiveSettings(data);
    this.setState(data);
  };

  get menu () {
    const { lexileLevel, selectedType, selectedLanguage } = this.props;
    const { isSyncing, errorMessage } = this.state;
    const disabled = isSyncing;
    return (
      <Grid className='hy-compact-grid-column'>
        <Grid.Row
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div className='accessibility-review-dropdowns'>
            <CustomDropdown
              className='hy-full-width-dropdown'
              selection
              options={this.types.map(toOption)}
              value={selectedType}
              label='Type'
              disabled={disabled}
              onChange={(e: any, {
                value
                // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
              }: any) => this.handleTypeChange(value, 'type')}
              fluid
            />
          </div>
          <div className='accessibility-review-dropdowns'>
            <CustomDropdown
              className='hy-full-width-dropdown'
              selection
              options={this.languageOptions}
              value={selectedLanguage}
              label='Language'
              search
              disabled={disabled}
              onChange={(e: any, {
                value
              }: any) => this.handleLanguageChange(value)}
              fluid
            />
          </div>
          <div className='accessibility-review-dropdowns'>
            <CustomDropdown
              className='hy-full-width-dropdown'
              selection
              options={this.lexileLevelOptions}
              value={lexileLevel}
              label='Lexile Level'
              search
              disabled={disabled}
              onChange={(e: any, {
                value
              }: any) => this.handleLexileLevelChange(value)}
              fluid
            />
          </div>
        </Grid.Row>
        {disabled ? null : (
          <Grid.Row>
            <Grid.Column verticalAlign='middle' style={{ textAlign: 'right' }}>
              {this.hasBaseFile && this.saveStatus}
              {errorMessage &&
                <TrashButton
                  handleClick={this.delete.bind(this)}
                />}
              {this.hasBaseFile &&
                <Button
                  legacy_flavor={Button.legacy_flavor.solid}
                  onClick={this.openUploadModal.bind(this)}
                  __classNameFor={{
                    root: 'internal-tools-button'
                  }}
                  __cssFor={{
                    root: { marginRight: '6px' }
                  }}
                >
                  <Icon
                    SvgComponent={UploadSVG} isDecorative __cssFor={/* AUTOGENERATED TODO: ANGELOU BREAKING CHANGE v0.26_v0.27! CHECK RENDER PATTERN HERE */
                      {
                        root: {
                          alignContent: 'flex-start', // TODO: check alignmentButtons.left
                          path: {
                            strokeWidth: '4'
                          }
                        }
                      }
                    }
                  />
                  Upload {selectedType.toLowerCase()}
                </Button>}
              {this.hasSelectedFile &&
                <Button
                  legacy_flavor={Button.legacy_flavor.solid}
                  onClick={this.downloadFile.bind(this)}
                  __classNameFor={{
                    root: 'internal-tools-button',
                  }}
                >
                  <Icon
                    SvgComponent={DownloadSVG}
                    isDecorative
                    __cssFor={/* AUTOGENERATED TODO: ANGELOU BREAKING CHANGE v0.26_v0.27! CHECK RENDER PATTERN HERE */
                      {
                        root: {
                          alignContent: 'flex-start', // TODO: check equivalent to alignment: Button.iconAlignments.left
                          path: {
                            strokeWidth: '4'
                          }
                        }
                      }
                    }
                  />
                  Download {selectedType.toLowerCase()}
                </Button>}
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }

  get loader () {
    const { selectedType } = this.props;
    return (
      <WarningBanner
        title='Do not leave this page'
        body={`Syncing Translated  ${selectedType}  files...`}
      />
    );
  }

  render () {
    const { editModalOpen, isSyncing } = this.state;
    const { canEdit } = this.props;

    if (!canEdit) {
      return (
        <div className='hy-margin-top'>
          <WarningBanner
            title='Editing Unavailable'
            body='Editing is not allowed until translation processes are complete.'
          />
        </div>
      );
    }

    return (
      <BasicSection
        noPadding
        renderedContent={
          <div className='hy-localization-editor'>
            {this.menu}
            <div className='hy-margin-top'>{isSyncing ? this.loader : this.editor}</div>
            {!this.allowsBaseFileCreation && !this.hasBaseFile && (
              <InfoBanner body='Generate base file to begin' />
            )}
            {editModalOpen && this.editModal}
          </div>
        }
      />
    );
  }
}
