import { gaConfig, ReactGA } from '../../../services/gaConfig';
import { ISO_DROPDOWN_OPTIONS } from './isoLanguageCodes';

export const beforeUpload = (props: any) => {
  const { beforeUpload } = props;
  if (typeof beforeUpload === 'function') beforeUpload();
  ReactGA.event(gaConfig.Analytics.Portal.ImportProject);
};

export const filteredLanguages = ISO_DROPDOWN_OPTIONS.filter((l: any) => l.value === 'en').map((lang) => ({ label: lang.text, value: lang.value }));
