import React from 'react';

import { TargetSection } from './TargetSection';
import { ReactSelect } from './Toolbar/ReactSelect/ReactSelect';

export class PlayerTargetSection extends TargetSection {
  // override TargetSection event handler for onMouseEnter:
  onMouseEnter = () => {
    const { onMouseEnter } = this.props;
    if (typeof onMouseEnter === 'function') onMouseEnter();
  };

  // override TargetSection event handler for onMouseLeave:
  onMouseLeave = () => {
    const { onMouseLeave } = this.props;
    if (typeof onMouseLeave === 'function') onMouseLeave();
  };

  get snapRatio () {
    const dropZone = this.zone.current;
    if (!dropZone) return {};
    const { width } = dropZone.reference.current.getBoundingClientRect();
    const snapAmount = width / 100;

    return {
      x: snapAmount,
      y: snapAmount,
    };
  }

  getDraggableAnnotationProps = (id: any) => {
    const { dragStarted, onClick } = this.props;
    return {
      id,
      dragStarted,
      onPlayer: true,
      snapRatio: this.snapRatio,
      onDoubleClick: () => onClick(id),
    };
  };

  // @ts-expect-error TS(2611): 'selectionTools' is defined as a property in class... Remove this comment to see the full error message
  get selectionTools () {
    const { selectionTool, selectedAnnotations } = this.props;
    const dropZone = this.zone.current;
    if (!dropZone) return null;
    return (
      <ReactSelect
        selectionTool={selectionTool}
        selectedAnnotations={selectedAnnotations}
      />
    );
  }
}
