import React, { Component } from 'react';

import { Card, Placeholder } from '../../../../hapyak-ui-toolkit';

type State = any;

export type CardContentTextAlign = 'center' | 'left' | 'right'

type ItemPreviewProps = {
  cardClassName: string;
  cardContentStyles: any;
  cardContentTextAlign: CardContentTextAlign;
  cardDescription: string;
  cardDescriptionStyle?: any;
  cardHeader?: any;
  cardHeight: string | number;
  cardMeta?: any;
  cardWidth: string | number;
  cardStyles: any;
  handleClick: (e: any) => void;
  isActive?: boolean;
  item?: any;
  previewDisplay: any;
  previewDisplayClassName?: string;
  previewStyle: any;
  systemDescription: boolean;
};

class ItemPreview extends Component<ItemPreviewProps, State> {
  cardRef: any;
  constructor (props: ItemPreviewProps) {
    super(props);

    this.cardRef = React.createRef();

    this.state = {
      loading: false,
    };
  }

  loading = (bool: boolean) => this.setState({ loading: bool });

  getCardRef = () => this.cardRef.current;

  get cardStyles () {
    const { cardHeight = '', cardWidth = '', cardStyles } = this.props;
    return { height: cardHeight, width: cardWidth, ...cardStyles };
  }

  onClick = (event: any, data: any) => {
    const { handleClick, item } = this.props;
    if (typeof handleClick === 'function') handleClick({ event, data, item });
  };

  get previewDisplay () {
    const { previewDisplay = '', previewStyle = {}, previewDisplayClassName = '' } = this.props;
    return (
      <div style={previewStyle} className={previewDisplayClassName}>
        {previewDisplay}
      </div>
    );
  }

  get previewPlaceholder () {
    return (
      <Placeholder>
        <Placeholder.Image square />
      </Placeholder>
    );
  }

  get contentLoadingPlaceholder () {
    return null;
  }

  get cardTextContent () {
    const { cardHeader, cardMeta, cardDescription, cardDescriptionStyle = {}, systemDescription } = this.props;
    const descriptionClassName = systemDescription ? ' hy-system-label ' : '';

    return (
      <>
        {cardHeader && <Card.Header>{cardHeader}</Card.Header>}
        {cardDescription && (
          <Card.Description className={descriptionClassName} style={cardDescriptionStyle}>
            {cardDescription}
          </Card.Description>
        )}
        {cardMeta && (
          <Card.Meta>
            <span className='date'>{cardMeta}</span>
          </Card.Meta>
        )}
      </>
    );
  }

  render () {
    const { loading } = this.state;
    const {
      cardHeader,
      cardMeta,
      cardDescription,
      cardContentTextAlign,
      cardContentStyles = {},
      item,
      cardClassName,
      isActive,
    } = this.props;

    const showContent = cardHeader || cardMeta || cardDescription;

    return (
      <Card
        style={this.cardStyles}
        item={item}
        onClick={this.onClick}
        raised={isActive}
        className={cardClassName}
      >
        <div style={{ height: '100%' }} ref={this.cardRef}>
          {loading ? this.previewPlaceholder : this.previewDisplay}
          {showContent && (
            <Card.Content textAlign={cardContentTextAlign} style={{ ...cardContentStyles }} className=''>
              {loading ? this.contentLoadingPlaceholder : this.cardTextContent}
            </Card.Content>
          )}
        </div>
      </Card>
    );
  }
}

export { ItemPreview };
