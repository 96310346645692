import React from 'react';

import { toolTypeCollects } from '../../../services/toolRelationshipService';
import { toSentenceCase } from '../../../services/utils';
import { Annotation } from '../../../types/annotations';
import { Size } from '../../../types/layout/forms';
import { QuickAddAnnotation } from '../CommonComponents/QuickAddAnnotation';

export const createQuickAddButton = (annotation = {} as Annotation, compact: boolean, size: Size) => {
  if (!annotation.toolType || !annotation.id) return null;

  const toolType = toolTypeCollects(annotation.toolType)[0];
  if (!toolType) return null;

  const label = `Add ${toSentenceCase(toolType)}`;
  return (<QuickAddAnnotation size={size} compact={compact} toolType={toolType} appliedTo={annotation.id} buttonText={label} />);
};
