import React from 'react';

import _ from 'lodash';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'node... Remove this comment to see the full error message
import * as webvtt from 'node-webvtt';

import { JobError } from '../../../errors/JobError';
import { uploadVtt } from '../../../services/uploadUtils';
import { AccessibilityUploadModal } from './AccessibilityUploadModal';
import { Upload } from './Upload';

export class VttUploadModal extends AccessibilityUploadModal {
  assessFile = () => {
    const { file } = this.state;
    const reader = new FileReader();

    reader.readAsText(file, 'UTF-8');

    reader.onload = (evt) => {
      const data = _.get(evt, 'target.result');

      let parsed;
      try {
        parsed = data && webvtt.parse(data, { meta: true });
      } catch (error: any) {
        console.error('VttUploadModal assessFile error:', error.message);
        throw new JobError('Error uploading Vtt file', data?.traceId);
      }

      const fileLanguage = _.get(parsed, 'meta.Language') || 'en';
      this.setState({ fileLanguage });
    };
  };

  // @ts-expect-error TS(2611): 'fileUploadComponent' is defined as a property in ... Remove this comment to see the full error message
  get fileUploadComponent () {
    return (
      <Upload
        type='vtt'
        postponeUpload
        beforeUpload={this.beforeUpload}
        afterUpload={this.afterUpload}
        handleFileChange={this.handleFileChange}
        displayConfirm={false}
        confirm={this.confirmProps}
        label='Caption'
        filetype='.vtt'
      />
    );
  }

  startUpload = () => {
    this.setState({ uploading: true });
    const { file } = this.state;
    const { projectId, type } = this.props;
    return uploadVtt(projectId, file, type, this.selectedLanguage)
      .then(this.afterUpload)
      .catch(this.onUploadError);
  };
}
