import './ProjectTemplate.scss';

import React from 'react';

import { GridLayout } from '@newsela/angelou';

import { getTemplates } from '../../../templates';
import { ProjectTemplateCard } from './ProjectTemplateCard';

export const ProjectTemplate = ({
  setTemplateId,
  templateId
}: any) => {
  const isSelected = (idx: any) => {
    return templateId === (idx + 1);
  };

  const handleTemplateClick = ({
    preview
  }: any) => {
    setTemplateId(preview.id);
  };

  const templates = () => {
    return getTemplates().map((template, idx) => {
      const { preview } = template;
      return (
        <ProjectTemplateCard
          preview={preview}
          onClick={handleTemplateClick}
          key={idx}
          additionalClassNames={isSelected(idx) ? 'selected' : ''}
        />
      );
    });
  };

  return (
    <div>
      {
        templates().length > 0 &&
          <GridLayout __classNameFor={{ root: 'project-template-4-col-grid' }}>
            {templates()}
          </GridLayout>
      }
    </div>
  );
};
