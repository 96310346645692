import React, { Component } from 'react';

import { Checkbox } from '@newsela/angelou';

import { CustomDropdown, Grid } from '../../../../hapyak-ui-toolkit';
import { media } from '../../../../services/mediaController';
import { stateController } from '../../../../services/stateController';
import { Embed } from '../../../../types/publishing';
import { TimeInput } from '../../../TimeInput/TimeInput';
import { ISO_DROPDOWN_OPTIONS } from '../../AssetsEditor/isoLanguageCodes';

type PackagingPlayerSettingProps = {
  embed: Embed;
  handleChange: (a: string, b: any, c: any) => void;
  handleToggle: (a: string, b: any, c: any) => void;
};

export class PackagingPlayerSetting extends Component<PackagingPlayerSettingProps> {
  get currentArbLanguages () {
    const { assets } = stateController.getCurrentData();
    const { arb = {} } = assets;
    return Object.keys(arb).map((key) => arb[key].language);
  }

  get hasAvailableLanguages () {
    return this.currentArbLanguages.length > 0;
  }

  setFromTime = (id: any, type: any, value: any) => {
    const { handleChange } = this.props;
    handleChange('start', undefined, { value });
  };

  get maxStartTime () {
    const { duration } = media.video;
    return duration / 1000;
  }

  get availableLanguages () {
    // Todo: Retrieve available languages from the package config since that is the src of truth
    const arbs = this.currentArbLanguages;
    return !arbs.length ? [] : ISO_DROPDOWN_OPTIONS.filter((lang) => arbs.includes(lang.value));
  }

  handleCheckboxToggle ({
    key,
    checked
  }: any) {
    const { handleToggle } = this.props;
    checked = !checked;
    handleToggle(key, null, { checked });
  }

  render () {
    const { embed, handleChange } = this.props;
    const { language, captions, autoplay, loop, start } = embed;

    return (
      <Grid className='hy-packaging-embed-setting'>
        <Grid.Row>
          <Grid.Column>
            <Grid.Row className='simple'>
              <Grid className='hy-compact-grid-row'>
                <Grid.Row>
                  <Grid.Column>
                    <Grid className='hy-compact-grid-row'>
                      <Grid.Row>
                        <CustomDropdown
                          selection
                          search
                          value={language}
                          label='Language'
                          disabled={!this.hasAvailableLanguages}
                          onChange={handleChange.bind(this, 'language')}
                          options={this.availableLanguages}
                        />
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Row>
            <Grid.Row className='simple hy-margin-bottom' columns={2}>
              <Checkbox
                label='captions'
                checked={captions}
                onChange={this.handleCheckboxToggle.bind(this, { key: 'captions', checked: captions })}
              />
            </Grid.Row>
            <Grid.Row className='simple hy-margin-bottom' columns={2}>
              <Checkbox
                label='auto play'
                checked={autoplay}
                onChange={this.handleCheckboxToggle.bind(this, { key: 'autoplay', checked: autoplay })}
              />
            </Grid.Row>
            <Grid.Row className='simple hy-margin-bottom' columns={2}>
              <Checkbox
                label='loop'
                checked={loop}
                onChange={this.handleCheckboxToggle.bind(this, { key: 'loop', checked: loop })}
              />
            </Grid.Row>
            <Grid.Row className='simple hy-margin-bottom' columns={2}>
              <TimeInput
                time={start}
                min={0}
                max={this.maxStartTime}
                label={{ basic: true, content: 'Start at' }}
                persistTime={this.setFromTime}
              />
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
