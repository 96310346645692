class Comm {
  // singleton used to communicate across components
  callbacks = {};

  register = (name: any, callback: any) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const callbacks = this.callbacks[name] || [];
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    this.callbacks[name] = [...callbacks, callback];
  };

  unregister = (name: any, callback: any) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const callbacks = this.callbacks[name] || [];
    const index = callbacks.indexOf(callback);
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (callbacks.length) this.callbacks[name].splice(index, 1);
  };

  trigger = (name: any, ...params: any[]) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const callbacks = this.callbacks[name];

    if (callbacks && callbacks.length) {
      callbacks.forEach((cb: any) => {
        if (typeof cb === 'function') cb(...params);
      });
    }
  };
}

const comm = new Comm();

export { comm };
