import React, { Component } from 'react';

import { Checkbox } from '@newsela/angelou';
import _ from 'lodash';

import { Grid, Header, Table } from '../../../hapyak-ui-toolkit';
import './SimpleCalculator.scss';

type SimpleCalculatorContentLineItem = {
  selected: boolean;
  onChange: any;
  disabled?: boolean;
  description: string;
  units: number;
  unitName: string;
  component: any;
}

export type SimpleCalculatorContent = {
  title: string;
  unitFormat: string;
  columnHeaders: string[];
  checkable: boolean;
  totalDescription: string;
  sections: {
    title: string;
    lineItems: SimpleCalculatorContentLineItem[];
  }[];
}

export type SimpleCalculatorProps = {
  data: SimpleCalculatorContent;
  additionalComponents?: boolean;
  title?: string;
  checkable?: boolean;
}

export class SimpleCalculator extends Component<SimpleCalculatorProps> {
  sectionTitle = (text = '') => {
    if (!text) return null;

    return (
      <Grid.Row style={{ borderBottom: '1px solid #000', paddingBottom: '5px', paddingTop: '5px' }}>
        {text}
      </Grid.Row>
    );
  };

  lineItem = (lineItemData: any, idx: any) => {
    const { checkable = false } = this.localProps;
    const { additionalComponents = true } = this.props;
    const ariaId = 'ar' + idx;

    const {
      description = '',
      units,
      unitName,
      component = null,
      disabled,
      selected = true,
      onChange = _.noop,
    } = lineItemData;
    const name = units && units > 1 ? ` ${unitName}s` : ` ${unitName}`;
    const multiplier = _.isNumber(units) ? `(${units}${name})` : null;

    return (
      <Table.Row key={idx} className='hy-simple-calculator-line-item'>
        {checkable && (
          <Table.Cell textAlign='center'>
            <Grid.Column style={{ paddingLeft: 0, paddingTop: '5px' }}>
              <Checkbox
                __classNameFor={disabled ? { root: 'hy-disabled-opacity' } : undefined}
                disabled={disabled}
                checked={selected}
                onChange={onChange}
                label=''
                ariaProps={{ 'aria-labelledby': ariaId }}
              />
            </Grid.Column>
          </Table.Cell>
        )}
        <Table.Cell>
          <div id={ariaId}>
            {description} {multiplier}
            {additionalComponents && component && <div style={{ display: 'inline' }}>{component}</div>}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  section = (data = {} as any) => {
    const { lineItems = [] } = data;
    return lineItems.map(this.lineItem);
  };

  get localProps () {
    return this.props.data;
  }

  get title () {
    const { title } = this.localProps;
    const header = (
      <Grid.Row>
        <Header>{title}</Header>
      </Grid.Row>
    );
    return title ? header : null;
  }

  get body () {
    const { sections = [] } = this.localProps;
    if (!sections.length) return null;

    const sectionElements = sections.map(this.section);
    return [...sectionElements];
  }

  gatherLineItems = (data: any) => {
    return _.get(data, 'lineItems', []).map((lineItem: any) => {
      const lineTotal = lineItem.amount * lineItem.units;
      return lineItem.selected ? lineTotal : 0;
    });
  };

  get total () {
    const { sections = [] } = this.localProps;
    if (!sections.length) return null;

    return sections
      .map(this.gatherLineItems)
      .flat()
      .reduce((acc: any, curr: any) => {
        return acc + +curr;
      }, 0);
  }

  get columnHeaders () {
    const { columnHeaders = [] } = this.localProps;

    if (!columnHeaders.length) return null;

    const headers = columnHeaders.map((content: any, idx: any) => {
      return <Table.HeaderCell key={idx}>{content}</Table.HeaderCell>;
    });

    return (
      <Table.Header>
        <Table.Row>{headers}</Table.Row>
      </Table.Header>
    );
  }

  render () {
    return (
      <div className='hy-simple-calculator'>
        {this.title}
        <Table celled>
          {this.columnHeaders}
          <Table.Body>{this.body}</Table.Body>
        </Table>
      </div>
    );
  }
}
