import React from 'react';

import { DraggableProps } from '../../types/layout/dnd';
import { CustomDraggableResizeHandle } from './CustomDraggableResizeHandle';
import dragController from './DragController';
import handles from './handles.json';

const INVISIBLE = ['hotlink'];

type State = any; // annotations that may be invisible but take up area on video surface

type CustomDraggableProps = DraggableProps & {
  drag: any;
  dragStarted: (e: any, id: string, el: any) => void;
  resize?: any;
  snapRatio?: any;
};

class CustomDraggable extends React.Component<CustomDraggableProps, State> {
  canDeselect: any;
  controller: any;
  reference: any;
  constructor (props: CustomDraggableProps) {
    super(props);
    this.reference = React.createRef();
    this.state = this.getStateObj(props);
    this.canDeselect = true;
    this.controller = dragController;
  }

  componentDidMount () {
    this.setState(this.getStateObj(this.props));
    dragController.addDraggable(this.props.id, this);
  }

  componentWillUnmount () {
    dragController.removeDraggable(this.props.id);
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  UNSAFE_componentWillReceiveProps (next: any, nextContext: any) {
    const update = ['top', 'left', 'width', 'height'].some((param) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return next.width !== this.props[param];
    });

    if (update && !this.state.isDragging) this.setState(this.getStateObj(next));
  }

  getStateObj = ({
    top,
    left,
    width,
    height,
    dropZone
  }: any) => {
    const bounds = dropZone ? dropZone.reference.current.getBoundingClientRect() : {};
    const x = this.toPixels(left, bounds.width);
    const y = this.toPixels(top, bounds.height);
    const w = this.toPixels(width, bounds.width);
    const h = this.toPixels(height, bounds.height);

    return {
      hover: false,
      isDragging: false,
      x,
      y,
      _x: x,
      _y: y,
      mouseX: 0,
      mouseY: 0,
      w,
      h,
      _w: w,
      _h: h,
      resizeProps: null,
      bounds,
      lock: false,
      snap: true,
    };
  };

  get style () {
    const { hover, isDragging } = this.state;
    const { type, active, drag, selected } = this.props;
    const opacity = isDragging ? 0.6 : active ? 0.3 : 0;
    const show = hover || active || isDragging;
    const { width, height, top, left } = this.toBoxAsPercents();
    const borderWidth = selected ? '3px' : '1px';
    const invisible = INVISIBLE.includes(type);
    let border = 'none';
    if (invisible) border = `${borderWidth} dotted #2185d0`;
    if (selected) border = `${borderWidth} dotted #2185d0`;
    if (show) border = `${borderWidth} solid #2185d0`;

    const style = {
      position: 'absolute',
      zIndex: show ? 2 : 1,
      top,
      left,
      width,
      height,
      backgroundColor: isDragging ? `rgba(33, 133, 208, ${opacity})` : `rgba(255, 255, 255, ${opacity})`,
      border,
      cursor: isDragging ? 'grabbing' : 'grab',
    };

    if (!drag) {
      style.cursor = 'not-allowed';
    }

    return style;
  }

  handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const { setActive, select, id } = this.props;
    this.setState({ hover: true });
    if (typeof setActive === 'function') setActive(id);
    if (typeof select === 'function') select(id, e.metaKey || e.ctrlKey || e.shiftKey, this.canDeselect);
  };

  handleDoubleClick = () => {
    const { onDoubleClick, id } = this.props;
    if (typeof onDoubleClick === 'function') onDoubleClick(id);
  };

  mouseover = () => {
    this.setState({ hover: true });
    if (this.props.setActive) {
      this.props.setActive(this.props.id);
    }
  };

  mouseleave = () => {
    this.setState({ hover: false });
    if (this.props.setActive && !this.state.isDragging) {
      this.props.setActive(null);
    }
  };

  // custom draggable
  handleMouseDown = (e: any) => {
    e.stopPropagation();
    this.canDeselect = true;
    const { dragStarted, id } = this.props;
    if (typeof dragStarted === 'function') dragStarted(e, id, this);
  };

  handleMouseMove = (e: any) => {
    const { isDragging, resizeProps } = this.state;
    if (!isDragging) return;
    this.canDeselect = false; // don't handle click action if a drag occurred

    this.setState(
      {
        snap: !(e.metaKey || e.ctrlKey),
        lock: e.shiftKey,
      },
      () => {
        resizeProps ? this.resize(e) : this.move(e);
      }
    );
  };

  toBoxAsPercents = () => {
    const { x, y, w, h, bounds } = this.state;

    return {
      width: this.toPercent(w, bounds.width),
      height: this.toPercent(h, bounds.height),
      top: this.toPercent(y, bounds.height),
      left: this.toPercent(x, bounds.width),
    };
  };

  handleMouseUp = (e: any) => {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);

    const { onDragEnd, id } = this.props;
    if (typeof onDragEnd === 'function') onDragEnd(null, id);

    const { x, y, w, h, _w, _h, _x, _y } = this.state;

    const rect = this.toBoxAsPercents();
    if (x !== _x || y !== _y || w !== _w || h !== _h) {
      this.drop(rect);
    }

    this.setState({
      mouseX: 0,
      mouseY: 0,
      isDragging: false,
      resizeProps: null,
      snap: true,
      lock: false,
    });
  };

  startDrag = ({
    clientX,
    clientY
  }: any, resizeProps: any) => {
    if (!resizeProps && !(this.props.drag)) return;

    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);

    const state = {
      mouseX: clientX,
      mouseY: clientY,
      isDragging: true,
      resizeProps: null
    };

    if (resizeProps) state.resizeProps = resizeProps;

    this.setState(state);
  };

  move = ({
    clientX,
    clientY
  }: any) => {
    const { mouseX, mouseY, _x, _y, w, h, lock } = this.state;
    const xAmount = clientX - mouseX;
    const yAmount = clientY - mouseY;

    let x = xAmount + _x;
    let y = yAmount + _y;

    if (lock) {
      Math.abs(xAmount) < Math.abs(yAmount) ? (x = _x) : (y = _y); // lock movement
    }

    this.setState(this.clampPosition({ x, y, w, h }));
  };

  resize = ({
    clientX,
    clientY
  }: any) => {
    const { resizeProps, mouseX, mouseY, _w, _h, _x, _y, lock } = this.state;
    let { x, y, w, h } = this.state;
    const { edges, dimensions } = resizeProps;
    const changed = [];

    // vertical
    if (dimensions.includes('height')) {
      const amount = clientY - mouseY;

      if (edges.includes('top')) {
        // top
        h = _h - amount;
        y = amount + _y;
        changed.push('y');
        changed.push('h');
      } else {
        // bottom
        h = amount + _h;
        changed.push('h');
      }

      if (lock) w = h * (_w / _h);
    }

    // horizontal
    if (dimensions.includes('width')) {
      const amount = clientX - mouseX;

      if (edges.includes('left')) {
        // left
        w = _w - amount;
        x = amount + _x;
        changed.push('x');
        changed.push('w');
      } else {
        // right
        w = amount + _w;
        changed.push('w');
      }

      if (lock) {
        h = w * (_h / _w);
        y = x * (_y / _x);
      }
    }

    this.setState(this.clampDimensions(changed, { x, y, w, h }));
  };

  resizeStart = (resizeProps: any, e: any) => {
    this.startDrag(e, resizeProps);
  };

  clampDimensions = (changed: any, {
    x,
    y,
    w,
    h
  }: any) => {
    const { _x, _y, _w, _h, bounds, snap, lock } = this.state;
    const { snapRatio } = this.props;
    const { x: snapX, y: snapY } = snapRatio;

    if (snap && !lock) {
      if (changed.includes('x')) x = Math.ceil(x / snapX) * snapX;
      if (changed.includes('y')) y = Math.ceil(y / snapY) * snapY;

      if (changed.includes('w')) {
        if (changed.includes('x')) {
          // left
          w = _w + (_x - x);
        } else {
          w = Math.round(w / snapX) * snapX;
        }
      }
      if (changed.includes('h')) {
        if (changed.includes('y')) {
          // top
          h = _h + (_y - y);
        } else {
          h = Math.round(h / snapY) * snapY;
        }
      }
    }

    // use snap ratio for minimum size for now
    const minWidth = snapX;
    const minHeight = snapY;

    if (changed.includes('w')) {
      if (w < minWidth) w = minWidth;
      // left edge move doesn't push box to the right of starting point:
      if (x >= _x + _w - minWidth) x = _x + _w - minWidth;
    }
    if (changed.includes('h')) {
      if (h < minHeight) h = minHeight;
      // top edge move doesn't push box lower than starting point:
      if (y >= _y + _h - minHeight) y = _y + _h - minHeight;
    }

    const outs = this.getOutOfBoundsInfo(bounds, { x, y, w, h });

    if (outs.includes('top')) {
      h = _h + _y;
      y = 0;

      const clamped = h * (_w / _h);
      if (lock && clamped < w) w = clamped;
    }
    if (outs.includes('left')) {
      w = _w + _x;
      x = 0;

      const clamped = w * (_h / _w);
      if (lock && clamped < h) h = clamped;
    }
    if (outs.includes('bottom')) {
      h = bounds.height - y;
      y = bounds.height - h;

      if (lock) {
        const clamped = h * (_w / _h);
        w = Math.min(w, clamped);
      }
    }
    if (outs.includes('right')) {
      w = bounds.width - x;
      x = bounds.width - w;

      if (lock) {
        const clamped = w * (_h / _w);
        h = Math.min(h, clamped);
      }
    }

    return { x, y, w, h };
  };

  clampPosition = ({
    x,
    y
  }: any) => {
    const { w, h, bounds, snap, lock } = this.state;
    const { snapRatio } = this.props;

    if (snap && snapRatio && !lock) {
      x = Math.round(x / snapRatio.x) * snapRatio.x;
      y = Math.round(y / snapRatio.y) * snapRatio.y;
    }

    const outs = this.getOutOfBoundsInfo(bounds, { x, y, w, h });

    if (outs.includes('top')) y = 0;
    if (outs.includes('left')) x = 0;
    if (outs.includes('bottom')) y = bounds.height - h;
    if (outs.includes('right')) x = bounds.width - w;

    return { x, y };
  };

  getOutOfBoundsInfo = (bounds: any, {
    x,
    y,
    w,
    h
  }: any) => {
    const outs = [];

    if (y < 0) outs.push('top');
    if (x < 0) outs.push('left');
    if (y + h > bounds.height) outs.push('bottom');
    if (x + w > bounds.width) outs.push('right');

    return outs;
  };

  toPercent = (n: any, max: any) => {
    if (!n) return '0%';
    return 100 * (n / max) + '%';
  };

  toPixels = (n: any, max: any) => {
    if (!n) return 0;

    if (n.includes('%')) {
      const num = +n.replace('%', '');
      return (num / 100) * max;
    }
    return n;
  };

  drop = (rect: any) => {
    const { dropZone, setActive } = this.props;
    if (!this.state.hover) setActive && setActive(null);
    dragController.startDragging(this);
    dragController.setActiveDropZone(dropZone);
    dragController.drop(rect);
  };

  createResizeHandles = () => {
    const { active, resize } = this.props;
    const { isDragging, w, h, snap } = this.state;

    return handles.map(({
      edges,
      dimensions,
      cursor
    }: any) => {
      const allowed = !dimensions.some((dimension: any) => !resize.includes(dimension));

      if (!allowed) return null;

      return (
        <CustomDraggableResizeHandle
          key={edges.join()}
          snap={snap}
          w={w}
          h={h}
          canHide={resize.length > 1}
          dimensions={dimensions}
          active={active || isDragging}
          edges={edges}
          cursor={cursor}
          resizeStart={this.resizeStart}
        />
      );
    });
  };

  render () {
    const { children, resize } = this.props;

    return (
      <div
        ref={this.reference}
        style={this.style as React.CSSProperties}
        onClick={this.handleClick}
        onDoubleClick={this.handleDoubleClick}
        onMouseOver={this.mouseover}
        onMouseLeave={this.mouseleave}
        onMouseDown={this.handleMouseDown}
      >
        {children}
        {resize && this.createResizeHandles()}
      </div>
    );
  }
}

export { CustomDraggable };
