import React from 'react';

import { Checkbox } from '@newsela/angelou';
import _ from 'lodash';

import { Button, Modal, Form } from '../../../hapyak-ui-toolkit';
import { gaConfig, ReactGA } from '../../../services/gaConfig';
import signingService from '../../../services/signingService';
import { stateController } from '../../../services/stateController';
import { savePlayerConfigOverrides } from '../../../services/stateUtils';
import { TOOLS, getMeaningfulDisplay, exposedAnnotations } from '../../../services/toolService';
import { Annotation } from '../../../types/annotations';
import { ToolType } from '../../../types/tools';
import { AngelouLoadingSpinner } from '../AngelouComponents/AngelouLoadingSpinner';
import { InfoBanner } from '../AngelouComponents/InfoBanner';
import { BasicSection } from '../CommonComponents/Menu/BasicSection/index';

type State = any;

type AutoGenerateModalProps = {
  addPage: (e: any) => void;
  used?: any;
};

class AutoGenerateModal extends React.Component<AutoGenerateModalProps, State> {
  used: any;
  constructor (props: AutoGenerateModalProps) {
    super(props);

    this.used = [];
    this.state = {
      loading: false,
      open: false,
      includedAnnotations: this.createIncludedAnnotationsObject(),
    };
  }

  get currentAnnotationTypes () {
    const annotations = stateController.getCurrentData('ux').annotations;
    return annotations.reduce((acc: any, curr: Annotation) => {
      const included = acc.includes(curr.toolType);
      return included ? acc : [...acc, curr.toolType];
    }, []);
  }

  createIncludedAnnotationsObject = () => {
    const availableToolTypes = _.intersection(exposedAnnotations, this.currentAnnotationTypes);

    return availableToolTypes.reduce((includedAnnotations, name) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const tool = TOOLS[name];
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      includedAnnotations[name] = {
        type: tool.defaults.toolType,
        name: tool.display,
        enabled: true,
      };
      return includedAnnotations;
    }, {});
  };

  reset = () => {
    this.used = [];
  };

  handleOpen = () => {
    this.reset();

    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      loading: false,
      open: false,
    });
  };

  handleToggleAnnotation = (type: any, e: any, {
    checked
  }: any) => {
    const includedAnnotations = this.state.includedAnnotations;
    const obj = includedAnnotations[type];

    this.setState({
      includedAnnotations: {
        ...includedAnnotations,
        [type]: {
          ...obj,
          enabled: checked,
        },
      },
    });
  };

  handleCheckboxToggle ({
    key,
    checked
  }: any) {
    checked = !checked;
    this.handleToggleAnnotation(key, null, { checked });
  }

  generateFromAnnotations = () => {
    return this.filteredAnnotations.map(this.getPageFromAnnotation).filter(Boolean); // remove falsy values
  };

  getPageFromAnnotation = (annotation: Annotation) => {
    return {
      description: this.getAnnotationDescription(annotation),
      start: annotation.start,
    };
  };

  getAnnotationDescription = (annotation: Annotation) => {
    let text = getMeaningfulDisplay(annotation);
    let actions: any = [];
    const properties = annotation.properties || {};
    const onClick = properties.onClick;
    const displayType = this.getAnnotationTypeDisplay(annotation.toolType);

    if (onClick) {
      actions = Object.keys(onClick)
        .map((key) => {
          if (!onClick[key]) return null;
          return this.getAnnotationActionDisplay(key, onClick[key]);
        })
        .filter(Boolean);
    }

    if (annotation.type === 'image') {
      text = signingService.unsign(text);
    }

    text = text ? `<li>Content: '${text}'</li>` : '';
    const actionsText = actions.length ? `<li>Behaviors:</li><ul>${this.toListItems(actions)}</ul>` : '';
    const content = text || actionsText ? `<ul>${text}${actionsText}</ul>` : '';
    return `<b>${displayType}</b>${content}`;
  };

  getAnnotationTypeDisplay = (type: ToolType) => {
    const tool = this.state.includedAnnotations[type];
    return tool && tool.name;
  };

  getAnnotationActionDisplay = (key: any, value: any) => {
    // gotoLink: "http://www.weather.com"
    // gotoTrack: "#10" // need to parse these out to time
    // gotoTrack: "#id=1459164" // need to parse these out to get annotation
    // loopback: "continue"
    // pause: false
    // releaseGate: false
    let action;

    if (key === 'gotoLink') action = `Links to '${value}'`;
    else if (key === 'gotoTrack') return `Jumps to ${this.parseGotoTrack(value)}`;
    else if (key === 'loopback' && value !== 'continue') return `Loops back to time '${value}'`;
    else if (key === 'pause' && value) return 'Pauses video';
    else if (key === 'releaseGate' && value) return 'Releases gate';

    return action;
  };

  parseGotoTrack = (value: any) => {
    if (value.indexOf('id=') === 1) {
      // assume jump to annotation
      const id = value.substring(4);
      const match = this.filteredAnnotations.find((annotation: Annotation) => {
        return annotation.id.toString() === id;
      });
      if (match) {
        const type = this.getAnnotationTypeDisplay(match.type);
        const time = match.start;
        return match ? `${type} annotation at ${time}` : '';
      }
    } else {
      // assume time
      return `time ${value.substr(1)}`;
    }
  };

  get filteredAnnotations () {
    const { annotations } = stateController.getCurrentData('ux');
    return annotations.filter(this.filterIncludedAnnotation);
  }

  filterIncludedAnnotation = (annotation: Annotation) => {
    const tool = this.state.includedAnnotations[annotation.toolType];
    return tool && tool.enabled;
  };

  saveThenGenerate = () => {
    this.generate();
    ReactGA.event(gaConfig.Analytics.Portal.Storyboard.AutoGenerate.Generated);
  };

  generate = () => {
    // first find all times we want to make a page for
    // @ts-expect-error TS(7006): Parameter 'a' implicitly has an 'any' type.
    const times = this.getTimes().sort((a, b) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });

    // then capture all information relevant at that time
    const pages = times.map(this.getPageFromTime);

    savePlayerConfigOverrides().then(() => {
      // @ts-expect-error TS(7031): Binding element 'description' implicitly has an 'a... Remove this comment to see the full error message
      pages.filter(({ description }) => !!description).forEach(this.props.addPage);
      this.handleClose();
    });
  };

  getPageFromTime = (instant: any) => {
    let annotationDescriptions = [];

    const annotations = this.presentAtInstant(this.filteredAnnotations, instant, 'id');
    annotationDescriptions = annotations.length && this.toListItems(annotations.map(this.getAnnotationDescription));

    const annotationText = annotationDescriptions.length
      ? `<p><b>Annotations:</b></p>${annotationDescriptions}<p> </p>`
      : '';

    if (!annotationText) return {};

    return {
      start: instant,
      description: annotationText,
    };
  };

  toListItems = (items: any) => {
    return `<ul>
              ${items.map((item: any) => `<li>${item}</li>`).join('')}
          </ul>`;
  };

  presentAtInstant = (objects: any, instant: any, key: any) => {
    return objects.filter((obj: any) => {
      const notUsed = this.used.indexOf(obj[key]) === -1;
      const present = instant >= obj.start && instant <= obj.end;
      const shouldAdd = notUsed && present;

      shouldAdd && this.used.push(obj[key]);
      return shouldAdd;
    });
  };

  getTimes = () => {
    const times = this.getTimesFromAnnotations();

    return this.filterInstant(times);
  };

  filterInstant = (times: any) => {
    const newTimes: any = [];
    const index = 0;

    times = times.sort(this.orderByStart);

    times.forEach((time: any) => {
      // @ts-expect-error TS(7006): Parameter 'alreadyAdded' implicitly has an 'any' t... Remove this comment to see the full error message
      const overlaps = newTimes.some((alreadyAdded) => {
        // check if instant overlaps with an already added time
        return alreadyAdded >= time[0] && alreadyAdded <= time[1];
      });

      if (!overlaps) newTimes.push(time[index]);
    });

    return newTimes;
  };

  getTimesFromAnnotations = () => {
    // this will get a little complicated with multiple chronologically overlapping annotations.
    return this.filteredAnnotations.map(this.mapTime);
  };

  mapTime = (obj: any) => [+obj.start, +obj.end];

  orderByStart = (a: any, b: any) => {
    return a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0;
  };

  orderByEnd = (a: any, b: any) => {
    return a[1] < b[1] ? -1 : a[1] > b[1] ? 1 : 0;
  };

  renderAnnotationCheckbox = (type: any) => {
    const annotation = this.state.includedAnnotations[type];
    return (
      <Form.Field key={type}>
        <Checkbox
          checked={annotation.enabled}
          label={annotation.name}
          onChange={this.handleCheckboxToggle.bind(this, { key: type, checked: annotation.enabled })}
        />
      </Form.Field>
    );
  };

  render () {
    const hasAnnotations = this.currentAnnotationTypes.length;

    return (
      <Modal
        open={this.state.open}
        onClose={this.handleClose}
        className='auto-generate-modal'
        trigger={<Button icon='sitemap' content='Auto Generate' onClick={this.handleOpen} />}
      >
        <Modal.Header>Auto Generate Options</Modal.Header>
        <Modal.Content>
          {this.state.loading && <AngelouLoadingSpinner text='Generating Pages' />}

          <Form>
            <Form.Group>
              <BasicSection
                renderedContent={[
                  {
                    title: 'Include Annotations',
                    renderedContent: (
                      <>
                        <Form.Field>
                          <div className='auto-generate-annotation-selection'>
                            {Object.keys(this.state.includedAnnotations).map(
                              this.renderAnnotationCheckbox
                            )}
                            {!hasAnnotations && (
                              <InfoBanner
                                body='Add annotations before generating.'
                              />
                            )}
                          </div>
                        </Form.Field>
                      </>
                    ),
                  },
                ]}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button content='Cancel' onClick={this.handleClose} />
          <Button
            primary
            icon='sitemap'
            content='Generate'
            onClick={this.saveThenGenerate}
            disabled={!hasAnnotations}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export { AutoGenerateModal };
