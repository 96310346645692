import React from 'react';

import _ from 'lodash';

import './index.scss';
import { getUserPreferences, setUserPreferences } from '../../../../services/saveUtils';
import { clamp } from '../../../../services/utils';
import { ExpandableContainerSection } from './ExpandableContainerSection';

const SECTIONS_KEY = 'SECTIONS';
const SECTION_SIZE_KEY = 'SECTION_SIZE';

type State = any;

type ExpandableContainerProps = {
  draggable: boolean;
  handle?: any;
  handleSize: any;
  minPx: number;
  nested?: any;
  sections: any[];
  simple: boolean;
  vertical?: any;
};

export class ExpandableContainer extends React.Component<ExpandableContainerProps, State> {
  debouncedSetTotalSize: any;
  reference: any;
  constructor (props: ExpandableContainerProps) {
    super(props);
    this.reference = React.createRef();

    this.debouncedSetTotalSize = _.debounce(this.setTotalSize);

    this.state = {
      ...this.getStateFromPrefs(props),
      totalSize: 1,
      startPosition: 0,
    };
  }

  getStateFromPrefs = (props: any) => {
    const prefs = getUserPreferences();
    const initialSize = 100 / props.sections.length;
    const uxSections = _.get(prefs, `${SECTIONS_KEY}`, {});
    const sizes = props.sections.map((section: any) => _.get(uxSections, `${section.id}.${SECTION_SIZE_KEY}`, initialSize)
    );

    return {
      _sizes: sizes,
      sizes,
    };
  };

  componentDidMount () {
    this.setTotalSize();
    window.addEventListener('resize', this.debouncedSetTotalSize);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.debouncedSetTotalSize);
  }

  saveUserPrefs = () => {
    const { sizes } = this.state;
    const prefs = getUserPreferences();
    const sectionPrefs = this.sections.reduce((updates: any, section: any, i: any) => {
      return {
        ...updates,
        [section.id]: {
          [SECTION_SIZE_KEY]: sizes[i],
        },
      };
    }, {});

    setUserPreferences({
      [SECTIONS_KEY]: {
        ...prefs[SECTIONS_KEY],
        ...sectionPrefs,
      },
    });
  };

  setTotalSize = () => {
    const { vertical } = this.props;
    const current = this.reference.current;
    if (!current) return;
    this.setState({ totalSize: vertical ? current.clientHeight : current.clientWidth });
  };

  get numSections () {
    return this.sections.length;
  }

  get sections () {
    return this.props.sections;
  }

  get sectionsComponents () {
    return this.sections.map(this.createSection);
  }

  get handleSizePercent () {
    const { handleSize } = this.props;
    const { totalSize } = this.state;
    return (handleSize / totalSize) * 100;
  }

  get minPercent () {
    const { minPx } = this.props;
    const { totalSize } = this.state;
    return ((minPx || this.handleSizePercent) / totalSize) * 100;
  }

  get min () {
    return this.minPercent || this.handleSizePercent;
  }

  get max () {
    return 100 - this.min * (this.numSections - 1);
  }

  createSection = (section: any, i: any) => {
    const { sizes, totalSize } = this.state;
    const { draggable, handle, handleSize, nested, simple } = this.props;
    const size = sizes[i];
    const position = sizes[i - 1] || 0;
    const minPercent = (this.min / totalSize) * 100;

    return (
      <ExpandableContainerSection
        key={'expandable-section-' + i}
        size={size}
        position={position}
        first={i === 0}
        last={i === this.sections.length - 1}
        section={section}
        onChange={this.onDragExpand.bind(this, i)}
        onDragStart={this.onDragStart.bind(this, i)}
        toggleExpand={this.onToggleExpand.bind(this, i)}
        simple={simple} handle={handle}
        handleSize={handleSize}
        min={minPercent}
        isMax={size === this.max}
        vertical={this.props.vertical}
        nested={nested}
        draggable={draggable}
      />
    );
  };

  changeSectionSize = (sizes: any, newSize: any, i: any) => {
    const _sizes = [...sizes];
    const aboveSize = _sizes[i - 1]; // UI prevents `i` from being 0
    const size = _sizes[i];
    const max = aboveSize + size;
    const diff = newSize - size;

    _sizes[i - 1] = clamp(this.min, max - this.min, aboveSize - diff);
    _sizes[i] = max - _sizes[i - 1];

    this.setState({ sizes: _sizes }, this.saveUserPrefs);
  };

  onDragStart = (i: any, startPosition: any) => {
    const { sizes } = this.state;
    this.setState({ startPosition, _sizes: sizes });
  };

  onDragExpand = (i: any, clientPosition: any) => {
    const { totalSize, _sizes, startPosition } = this.state;
    const amount = clientPosition - startPosition;
    const percent = (100 * amount) / totalSize;

    this.changeSectionSize(_sizes, _sizes[i] - percent, i);
  };

  onToggleExpand = () => {
    const { sizes } = this.state;
    this.setState({ sizes: sizes.map(() => 100 / sizes.length) }, this.saveUserPrefs);
  };

  render () {
    return (
      <div ref={this.reference} className='hy-expandable-container'>
        {this.sectionsComponents}
      </div>
    );
  }
}
