import React from 'react';

// @ts-expect-error TS(7016): Could not find a declaration file for module 'pret... Remove this comment to see the full error message
import pretty from 'prettysize';

import { Table } from '../../../../hapyak-ui-toolkit';
import signingService from '../../../../services/signingService';
import { downloadToComputer, getFileName } from '../../../../services/utils';
import { Link } from '../../../Link';

type State = any;

type ImageAssetRowProps = {
  src: string;
};

export class ImageAssetRow extends React.Component<ImageAssetRowProps, State> {
  constructor (props: ImageAssetRowProps) {
    super(props);
    this.state = { size: 0 };
  }

  handleLoad = (e: any) => {
    const entries = window.performance.getEntriesByName(e.target.src);
    const entry = entries[0];

    if (entry) {
      const bodySize = (entry as any).decodedBodySize;
      this.setState({ size: bodySize ? pretty(bodySize) : 'unknown' });
    }
  };

  render () {
    const { src } = this.props;
    const unsigned = signingService.unsign(src);

    return (
      <Table.Row>
        <Table.Cell>
          <img alt={unsigned} src={src} width='100%' onLoad={this.handleLoad} />
        </Table.Cell>
        <Table.Cell
          textAlign='left'
          verticalAlign='top'
          style={{ wordBreak: 'break-word' }}
        >
          <Link to={{}} onClick={downloadToComputer.bind(this, src, getFileName(unsigned))}>
            {unsigned}
          </Link>
        </Table.Cell>
        <Table.Cell textAlign='left' verticalAlign='top'>
          {this.state.size}
        </Table.Cell>
      </Table.Row>
    );
  }
}
