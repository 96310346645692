import React from 'react';

import { Editor } from '@tinymce/tinymce-react'; // https://github.com/tinymce/tinymce-react

import defaults from '../../DEFAULTS.json';
import { getConfig } from '../../tinymce_config';
const { tinyMCEAPIKey } = defaults;

type TinyMCEditorProps = {
  editorConfigType: string;
  configOptions?: any;
  onBlur?: () => void;
  onEditorChange: (content: any) => void;
  onInit?: (e: any, editor: any) => void;
  value: any;
};

// just a pass through that will automatically blur itself so keyboard shortcuts aren't interrupted.
class TinyMCEEditor extends React.Component<TinyMCEditorProps> {
  click = (e: any) => e.stopPropagation();

  setup = (editor: any) => {
    editor.on('init', (e: any) => {
      editor.execCommand('mceFocus');
      editor.dom.setStyle(editor.dom.select('p'), 'margin', '0'); // remove extra margins around <p> tags
      editor.selection.select(editor.getBody(), true);

      const { onInit } = this.props;
      if (typeof onInit === 'function') onInit(e, editor);
    });

    editor.on('keydown', (e: any) => {
      if (e.keyCode === 27) {
        // escape key
        const fullscreen = editor.plugins.fullscreen;
        if (fullscreen && typeof fullscreen.isFullscreen === 'function' && fullscreen.isFullscreen()) {
          // exit fullscreen
          editor.execCommand('mcefullscreen');
        } else {
          editor.iframeElement.blur(); // OR blur
        }
      }
    });
  };

  render () {
    const { editorConfigType, configOptions = {} } = this.props;
    const config = getConfig(editorConfigType);

    (config as any).setup = this.setup;

    return (
      <Editor
        apiKey={tinyMCEAPIKey}
        init={{ ...config, ...configOptions }}
        {...this.props}
        onClick={this.click}
      />
    );
  }
}

export { TinyMCEEditor };
