import './index.scss';

import React from 'react';

import { Bundle } from 'types/publishing';

import { Button, Modal } from '../../../hapyak-ui-toolkit';
import { groupConfig } from '../../../services/configurationService';
import { COLLECTION_ITEM_TYPES, createCollectionItem } from '../../../services/persistence/platformService';
import { assetService } from '../../../services/stateController';
import { BasicSection } from '../CommonComponents/Menu/BasicSection';
import { getLocatorString } from '../PackagingEditor/Distributables/embedUtils';
import { CopyHelper } from './CopyHelper';

type State = any;

type SetSourceModalProps = {
  bundle: Bundle;
  onClose: () => void;
  updateBundle: () => void;
};

export class SetSourceModal extends React.Component<SetSourceModalProps, State> {
  constructor (props: SetSourceModalProps) {
    super(props);

    this.state = {
      tempBundleSrc: this.props.bundle.customSrc,
    };
  }

  UNSAFE_componentWillMount () {
    const { customSrc = '', src } = this.props.bundle;
    this.setState({ tempBundleSrc: customSrc || src });
  }

  get readOnlySources () {
    const { tempBundleSrc } = this.state;
    const locatorString = getLocatorString();
    const { includeLandingPage } = this.props.bundle;

    const baseSources = [
      {
        title: 'Static Source',
        renderedContent: (
          <CopyHelper
            value={tempBundleSrc || 'Paste path to Package here'}
            persistInput
            property='tempBundleSrc'
            showText
            as='button'
            readOnly={false}
            onChange={this.updateBundle}
            onBlur={this.updateSource}
          />
        ),
      },
    ];

    if (locatorString && this.canPublishPublicly) {
      baseSources.push({
        title: 'Dynamic Source',
        renderedContent: <CopyHelper value={locatorString} showText as='button' rows={2} />,
      });
    }

    if (includeLandingPage) {
      baseSources.push({
        title: 'Landing Page URL',
        renderedContent: <CopyHelper value={this.landingPageUrl} showText as='button' rows={2} />,
      });
    }

    return baseSources;
  }

  get canPublishPublicly () {
    return groupConfig.allow('publishing.targets.aws-s3');
  }

  get landingPageUrl () {
    const { src } = this.props.bundle;
    const baseUrl = (src || '') + 'player/index.html';
    const { tempBundleSrc } = this.state;

    if (!this.canPublishPublicly) {
      return baseUrl + '?src=' + getLocatorString();
    }

    if (this.canPublishPublicly && tempBundleSrc) {
      return src + 'player/index.html';
    }

    return baseUrl;
  }

  updateBundle = (prop: any, e: any, data = { value: '' }) => {
    const { value } = data;
    this.setState({ tempBundleSrc: value });
  };

  updateSource = (prop: any, value = '') => {
    const { updateBundle } = this.props;
    const customSrc = value.replace(/ /g, '');
    const bundle = { ...this.props.bundle, customSrc };
    assetService.setBundle(bundle, true);

    this.setState({ tempBundleSrc: customSrc || this.props.bundle.src });

    if (groupConfig.allow('platform.enabled', false)) {
      createCollectionItem(COLLECTION_ITEM_TYPES.BUNDLE, bundle).then((res) => {
        if (updateBundle) updateBundle();
      });
    }
  };

  render () {
    return (
      <Modal open className='hy-thumbnail-modal'>
        <Modal.Header>Source Information</Modal.Header>
        <Modal.Content>
          <BasicSection className='hy-form-field hy-remove-left-column-gutter' renderedContent={this.readOnlySources} />
        </Modal.Content>
        <Modal.Actions>
          <Button content='OK' onClick={this.props.onClose} primary />
        </Modal.Actions>
      </Modal>
    );
  }
}
