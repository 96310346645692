import React, { Component } from 'react';

import { Embed } from 'types/publishing';

import { Input, Grid } from '../../../../hapyak-ui-toolkit';

const EMBED_REFID_IS_DISABLED = true;

type State = any;

type PackagingAnalyticsSettingsProps = {
  embed: Embed;
  updateValue: any;
};

export class PackagingAnalyticsSettings extends Component<PackagingAnalyticsSettingsProps, State> {
  constructor (props: PackagingAnalyticsSettingsProps) {
    super(props);
    this.state = { refId: '' };
  }

  componentDidMount () {
    const { embed } = this.props;
    const { refId = '' } = embed;
    this.setState({ refId });
  }

  handleChange = (prop: any, e: any, {
    value
  }: any) => {
    this.setState({
      [prop]: value,
    });
  };

  onBlur = (prop: any, e: any) => {
    const { updateValue } = this.props;
    const value = e.target.value;

    if (updateValue) updateValue(prop, value);

    this.setState({
      [prop]: value,
    });
  };

  render () {
    const { refId } = this.state;

    return (
      <Grid className='hy-packaging-embed-setting'>
        <Grid.Row>
          <Grid.Column>
            <Grid.Row className='simple hy-margin-bottom' columns={2}>
              <Input
                disabled={EMBED_REFID_IS_DISABLED}
                label='Embed Ref Id'
                value={refId}
                onChange={this.handleChange.bind(this, 'refId')}
                onBlur={this.onBlur.bind(this, 'refId')}
              />
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
