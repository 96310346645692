import React, { Component } from 'react';

import { Prompt } from 'react-router-dom';

import DEFAULTS from '../../../DEFAULTS.json';
import { ConfirmModal, CustomStepTabs, Grid, Header, Image, Message } from '../../../hapyak-ui-toolkit';
import sherpa from '../../../img/sherpa-robot-by-kyle.png';
import { comm } from '../../../services/comm';
import { groupConfig } from '../../../services/configurationService';
import { LEXILE_LEVEL_DEFAULT } from '../../../services/lexileService';
import { media } from '../../../services/mediaController';
import { isLocalizationActive, cancelLocalization } from '../../../services/persistence/platformService';
import { getDropdownPreferences } from '../../../services/saveUtils';
import { assetService } from '../../../services/stateController';
import { getUpdateTabCallbackKey } from '../../../services/utils';
import visService from '../../../services/visService';
import ErrorBanner from '../AngelouComponents/ErrorBanner';
import { CancelableStatus } from '../CommonComponents/CancelableStatus';
import { BasicSection } from '../CommonComponents/Menu/BasicSection';
import { LocalizationEditor } from '../LocalizationEditor';
import { LocalizationTableList } from '../LocalizationTableList';
import { QuickStartService } from './QuickStartService';
import tabNames from './tabs.json';

const EDITOR_TAB_INDEX = tabNames.tabs.indexOf('review');

type State = any;

type TranslationServicesProps = {
  processing?: any;
};

export class TranslationServices extends Component<TranslationServicesProps, State> {
  constructor (props: TranslationServicesProps) {
    super(props);

    const prefType = getDropdownPreferences()[DEFAULTS.LOCALIZATION_EDITOR_TYPE_DROPDOWN];
    const prefLanguage = getDropdownPreferences()[DEFAULTS.LOCALIZATION_EDITOR_LANGUAGE_DROPDOWN];
    const prefLexileLevel = getDropdownPreferences()[DEFAULTS.LOCALIZATION_EDITOR_LEXILE_LEVEL_DROPDOWN];

    this.state = {
      isOpen: true,
      canEdit: true,
      inProgress: isLocalizationActive(),
      processError: false,
      canTransition: true,
      warnModal: false,
      selectedType: prefType || DEFAULTS.VTT_CAPTIONS,
      selectedLanguage: prefLanguage || this.baseLanguage,
      lexileLevel: prefLexileLevel || LEXILE_LEVEL_DEFAULT,
      tabIndex: null,
    };

    this.setupProcessWatching();
  }

  componentDidMount () {
    if (this.allAvailableCombos.length > 0) {
      this.setState({ isOpen: false });
    }

    if (isLocalizationActive()) {
      visService.connectToLocalizationService();
    }

    this.setState({
      lexileLevel: 'MAX',
    });
  }

  componentDidUpdate (previousProps: TranslationServicesProps) {
    const canEdit = !isLocalizationActive();

    if (this.state.canEdit !== canEdit) {
      this.setState({ canEdit }, () => {
        // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
        this.updateActiveSettings({
          selectedLanguage: media.video.language,
        });
      });
    }
  }

  componentWillUnmount () {
    this.teardownProcessWatching();
  }

  get localizationProvisioned () {
    return groupConfig.allow('platform.localization.enabled');
  }

  get baseLanguage () {
    return media.video.language;
  }

  onWarnModalClose = () => {
    this.setState({ warnModalOpen: false, tabIndex: EDITOR_TAB_INDEX });
  };

  get warnModal () {
    return (
      <ConfirmModal
        primary
        header='Warning'
        content='Save or Revert changes before continuing.'
        open
        cancelButton={false}
        confirmCallback={this.onWarnModalClose}
      />
    );
  }

  onUpdate = ({
    isDirty,
    warnModalOpen
  }: any) => {
    this.setState({ canTransition: !isDirty, warnModalOpen: warnModalOpen });
  };

  get projectArbs () {
    return assetService.getArbArray() || [];
  }

  get projectVtts () {
    return assetService.getVttArray() || [];
  }

  get projectAudioDescriptions () {
    return assetService.getAudioDescriptionArray() || [];
  }

  get allAvailableCombos () {
    return [...this.projectArbs, ...this.projectVtts, ...this.projectAudioDescriptions].map((f) => {
      return { language: f.language, lexileLevel: f.lexileLevel };
    });
  }

  updateActiveSettings = (state = {}, index: any) => {
    const tabIndex = index || (state as any).tabIndex;
    return new Promise((resolve) => {
      // @ts-expect-error TS(2345): Argument of type '(value: unknown) => void' is not... Remove this comment to see the full error message
      this.setState({ ...state, tabIndex }, resolve);
    });
  };

  get listTab () {
    return (
      <LocalizationTableList
        {...this.props}
        handleClick={this.updateActiveSettings}
        canEdit={this.state.canEdit}
        projectArbs={this.projectArbs}
        projectVtts={this.projectVtts}
        projectAudioDescriptions={this.projectAudioDescriptions}
        allAvailableCombos={this.allAvailableCombos}
        handleMenuToggle={this.handleMenuToggle}
        lexileLevel={this.state.lexileLevel}
      />
    );
  }

  get editorTab () {
    const { lexileLevel, selectedType, selectedLanguage } = this.state;
    return (
      <LocalizationEditor
        {...this.props}
        canEdit={this.state.canEdit}
        projectArbs={this.projectArbs}
        projectVtts={this.projectVtts}
        allAvailableCombos={this.allAvailableCombos}
        handleMenuToggle={this.handleMenuToggle}
        updateActiveSettings={this.updateActiveSettings}
        onUpdate={this.onUpdate}
        selectedType={selectedType}
        selectedLanguage={selectedLanguage}
        lexileLevel={lexileLevel}
        baseLanguage={this.baseLanguage}
      />
    );
  }

  get tabs () {
    const { tabs } = tabNames;
    const availableTabs = {
      create: {
        name: 'Create',
        icon: 'translate',
        render: () => {
          const { isOpen, canEdit } = this.state;
          return (
            <QuickStartService
              canEdit={canEdit}
              allAvailableCombos={this.allAvailableCombos}
              processing={this.props.processing}
              handleMenuToggle={this.handleMenuToggle}
              isOpen={isOpen}
              localizationProvisioned={this.localizationProvisioned}
            />
          );
        },
      },
      manage: {
        name: 'Files',
        icon: 'list',
        render: () => <BasicSection noPadding renderedContent={this.listTab} />,
      },
      review: {
        name: 'Review',
        icon: 'pencil',
        disabled: !this.allAvailableCombos.length,
        render: () => this.editorTab,
      },
      translate: {
        name: 'Translate',
        disabled: !this.allAvailableCombos.length,
        icon: 'translate',
        render: () => {
          const { isOpen, canEdit } = this.state;
          return (
            <QuickStartService
              translating
              canEdit={canEdit}
              allAvailableCombos={this.allAvailableCombos}
              processing={this.props.processing}
              handleMenuToggle={this.handleMenuToggle}
              isOpen={isOpen}
              localizationProvisioned={this.localizationProvisioned}
            />
          );
        },
      },
    };

    return tabs.map((tabName: any) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return availableTabs[tabName];
    });
  }

  handleMenuToggle = ({
    forceIsOpen
  }: any) => {
    const { isOpen } = this.state;
    this.setState({ isOpen: forceIsOpen || !isOpen });
  };

  get headerMessage () {
    return (
      <Message style={{ background: '#FFF' }}>
        <Grid>
          <Grid.Row columns='equal'>
            <Grid.Column>
              <Image
                className='sherpa'
                style={{
                  height: '100%',
                  maxHeight: '85px',
                  width: '100%',
                  maxWidth: 'max-content',
                  margin: 'auto'
                }}
                size='tiny'
                src={sherpa}
              />
            </Grid.Column>
            <Grid.Column width={14} verticalAlign='middle'>
              <Header>Create captions and translate everything in your video.</Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Message>
    );
  }

  setupProcessWatching = () => {
    comm.register('processInitiated', this.onProcessUpdated);
    comm.register('processStarted', this.onProcessUpdated);
    comm.register('processUpdated', this.onProcessUpdated);
    comm.register('processComplete', this.onProcessComplete);
    comm.register('processFailed', this.onProcessFailed);
    comm.register('processCanceled', this.onProcessCanceled);
  };

  teardownProcessWatching = () => {
    comm.unregister('processStarted', this.onProcessUpdated);
    comm.unregister('processUpdated', this.onProcessUpdated);
    comm.unregister('processComplete', this.onProcessComplete);
    comm.unregister('processFailed', this.onProcessFailed);
    comm.unregister('processCanceled', this.onProcessCanceled);
  };

  onProcessUpdated = (processName: any) => processName === DEFAULTS.LOCALIZE_PLATFORM_KEY && this.setState({ inProgress: true });

  onProcessComplete = (processName: any) => {
    if (processName === DEFAULTS.LOCALIZE_PLATFORM_KEY) {
      this.setState({
        inProgress: false,
        tabIndex: EDITOR_TAB_INDEX,
      });
    }
  };

  onProcessFailed = (processName: any) => {
    if (processName === DEFAULTS.LOCALIZE_PLATFORM_KEY) {
      this.setState({
        inProgress: false,
        processError: 'Something went wrong',
      });
    }
  };

  onProcessCanceled = (processName: any) => processName === DEFAULTS.LOCALIZE_PLATFORM_KEY && this.setState({ inProgress: false });

  clearError = () => this.setState({ processError: null });

  get errorDisplay () {
    const { processError } = this.state;
    if (!processError) return null;
    return (
      <ErrorBanner
        body={`Processing Error: ${this.state.processError}`}
        onClose={this.clearError}
        isDismissible
      />
    );
  }

  get stepTabs () {
    return this.tabs.map((tab: any) => {
      return {
        ...tab,
        items: [tab.render()],
      };
    });
  }

  render () {
    const { canTransition, warnModalOpen, tabIndex, inProgress } = this.state;
    const statusOptions = {
      active: inProgress,
      processDescription: 'Processing',
      onCancel: cancelLocalization,
    };

    return (
      <div>
        <CancelableStatus {...statusOptions} />
        {this.errorDisplay}
        {this.headerMessage}
        <CustomStepTabs
          wrap
          forceTabIndex={tabIndex}
          tabContent={this.stepTabs}
          paginate
          type={DEFAULTS.LOCALIZATION_SERVICES_TABS}
          updateTabCallbackKey={getUpdateTabCallbackKey(DEFAULTS.LOCALIZATION_SERVICES_TABS)}
        />
        {warnModalOpen && this.warnModal}
        <Prompt when={!canTransition} message='Changes will be deleted. Do you want to continue?' />
      </div>
    );
  }
}
