import React from 'react';

import { Form, Select } from '../../../../hapyak-ui-toolkit';
import { StyleService } from '../../../../services/styleService';
import { toSentenceCase } from '../../../../services/utils';
import { StyleInputProps } from '../../../../types/layout/forms';

const styleService = new StyleService();

type ParseUnitsResponse = {
  scalar?: string;
  unit?: string;
}

type State = any;

class StyleInput extends React.Component<StyleInputProps, State> {
  constructor (props: StyleInputProps) {
    super(props);
    const { value } = this.props;
    const { scalar, unit } = this.parseUnits(value);
    this.state = { scalar, unit };
  }

  UNSAFE_componentWillReceiveProps (nextProps: StyleInputProps) {
    const { value } = nextProps;
    const { scalar, unit } = this.parseUnits(value);
    this.setState({ scalar, unit });
  }

  parseUnits = (value: string): ParseUnitsResponse => {
    const { property } = this.props;
    const inputDefinition = styleService.getInput(property);
    if (!inputDefinition) return {} as ParseUnitsResponse;
    if (inputDefinition.units) {
      if (value && value.includes('%')) return { scalar: value.replace('%', ''), unit: '%' };
      if (value && value.includes('px')) return { scalar: value.replace('px', ''), unit: 'px' };
      return { scalar: value, unit: inputDefinition.units[0].value };
    }
    return { scalar: value };
  };

  onChangeUnit = (e: any, {
    value
  }: any) => {
    this.setState({ unit: value }, this.persistChange);
  };

  onChangeScalar = (value: string) => {
    this.setState({ scalar: value }, this.persistChange);
  };

  persistChange = () => {
    const { onChange } = this.props;
    const { scalar, unit } = this.state;
    const value = scalar + (unit || '');
    if (typeof onChange === 'function') onChange(value);
  };

  getInputProps = (inputDefinition: any, display: any) => {
    const { scalar } = this.state;
    const { units, props, onChange, valueProp, font } = inputDefinition;

    props.onChange = onChange.bind(this, this.onChangeScalar); // normalize onChange for all input types
    props[valueProp || 'value'] = valueProp === 'checked' ? (scalar === 'none' ? false : !!scalar) : scalar;
    props.label = display;

    if (font) props.style = { fontFamily: scalar, fontWeight: 'normal' };
    if (units) props.action = this.getDropdown(units);

    return props;
  };

  getDropdown = (units: any) => {
    const { unit } = this.state;
    const disabled = units.length <= 1;
    return (
      <Select
        compact
        placeholder='Unit'
        value={unit}
        options={units}
        onChange={this.onChangeUnit}
        disabled={disabled}
      />
    );
  };

  render () {
    const { property } = this.props;
    const display = toSentenceCase(property);
    const inputDefinition = styleService.getInput(property);
    if (!inputDefinition) return null;

    const InputComponent = inputDefinition.component;
    const props = this.getInputProps(inputDefinition, display);

    if (!InputComponent) return null;

    const input = <InputComponent {...props} />;

    return (
      <Form.Field key={property} className='hy-form-field'>
        {input}
      </Form.Field>
    );
  }
}

export { StyleInput };
