import './index.scss';

import React from 'react';

import { Checkbox } from '@newsela/angelou';

import { Button, ColorPicker, CustomDropdown, Grid } from '../../../hapyak-ui-toolkit';
import { comm } from '../../../services/comm';
import { groupConfig } from '../../../services/configurationService';
import { gaConfig, ReactGA } from '../../../services/gaConfig';
import { stateController } from '../../../services/stateController';
import { StyleService } from '../../../services/styleService';
import { getDropdownOptionsFromObject } from '../../../services/utils';
import { BasicSection } from '../CommonComponents/Menu/BasicSection';
import colorSchemes from './color_schemes.json';
import themes from './vjs_themes.json';

const COLUMNS = 2;

const toTemplateLiteral = (str: string) => {
  return '${' + str + '}'; // wrap in template literal syntax for later use in a template literal
};

type PlayerEditorState = any;

type PlayerEditorProps = {
  backgroundColor: string;
  colorScheme: string;
  progressBarColor: string;
  textColor: string;
  theme: any;
  showFullscreenToggle: boolean;
  showProgressBar: boolean;
  showCurrentTime: boolean;
  showControlBar: boolean;
  showPlayButton: boolean;
  showVolumeIndicator: boolean;
  showClosedCaptionButton: boolean;
  showLikeDislikeButtons: boolean;
  showLanguageButton: boolean;
  showLevelButton: boolean;
  showRewindButton: boolean;
  showPlayBackRateButton: boolean;
};

class PlayerEditor extends React.Component<PlayerEditorProps, PlayerEditorState> {
  constructor (props: PlayerEditorProps) {
    super(props);

    this.state = {
      colorScheme: props.colorScheme,
    };
  }

  componentDidMount () {
    this.scrollToView();
  }

  scrollToView = () => {
    comm.trigger('runPlayerScroll');
  };

  handleThemeChange = (e: any, {
    value
  }: any) => {
    const { colorScheme } = this.state;
    const theme = themes[value as keyof typeof themes];

    if (!theme) return;
    if (colorScheme === 'theme') {
      this.setState({ colorScheme: null }, () => {
        // force state reset
        this.handleColorSchemeChange(e, { value: 'theme' });
      });
    }
    stateController.updateProject('player', { theme: value });
    this.scrollToView();

    ReactGA.event(
      Object.assign(gaConfig.Analytics.Portal.Controls.ThemeChange, {
        label: 'Controls / Theme Change / ' + value,
      })
    );
  };

  handleColorSchemeChange = (e: any, {
    value
  }: any) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const colors = this.colorSchemeValues[value] || {};
    this.setState({ colorScheme: value });
    stateController.updateProject('player', { colorScheme: value, ...colors });
    this.scrollToView();
  };

  get colorSchemeValues () {
    return {
      branded: {
        backgroundColor: toTemplateLiteral('secondary'),
        textColor: toTemplateLiteral('primary'),
        progressBarColor: toTemplateLiteral('accent'),
      },
      inverseBranded: {
        backgroundColor: toTemplateLiteral('primary'),
        textColor: toTemplateLiteral('secondary'),
        progressBarColor: toTemplateLiteral('accent'),
      }
    };
  }

  get currentColors () {
    const { textColor, backgroundColor, progressBarColor } = this.props;
    return { textColor, backgroundColor, progressBarColor };
  }

  handleColorChange = (type: any, value: any) => {
    stateController.updateProject('player', { [type]: value });
    this.scrollToView();

    ReactGA.event(
      Object.assign(gaConfig.Analytics.Portal.Controls.ColorChange, {
        label: 'Controls / Color Change / ' + type,
      })
    );
  };

  matchColorsToScheme = () => {
    const cols = this.currentColors;
    const match = colorSchemes.find((scheme: any) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const values = this.colorSchemeValues[scheme.value] || {};
      return Object.keys(cols).every((key) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        return cols[key] === values[key];
      });
    });

    return (match && match.value) || 'custom';
  };

  handleToggle = (type: any, e: any, {
    checked
  }: any) => {
    stateController.updateProject('player', { [type]: checked });
    this.scrollToView();

    ReactGA.event(
      Object.assign(gaConfig.Analytics.Portal.Controls.ToggleSettings, {
        label: 'Controls / Toggle / ' + type,
      })
    );
  };

  get resolvedColors (): { textColor: string; backgroundColor: string; progressBarColor: string; } {
    const { textColor, backgroundColor, progressBarColor } = this.props;
    return StyleService.replaceBrandingVariables({ textColor, backgroundColor, progressBarColor });
  }

  handleCheckboxToggle ({
    key,
    checked
  }: any) {
    checked = !checked;
    this.handleToggle(key, null, { checked });
  }

  get controlBarToggle () {
    const { showControlBar } = this.props;

    return (
      <Checkbox
        checked={showControlBar}
        label=''
        ariaProps={{ 'aria-labelledby': 'showControlBar' }}
        onChange={this.handleCheckboxToggle.bind(this, { key: 'showControlBar', checked: showControlBar })}
      />
    );
  }

  get selectTheme () {
    const { theme } = this.props;

    return (
      <CustomDropdown
        placeholder='Theme'
        openOnFocus
        value={theme}
        selection
        className='button'
        onChange={this.handleThemeChange}
        options={getDropdownOptionsFromObject(themes, 'name')}
      />
    );
  }

  get resetThemeButton () {
    return (
      <Button
        disabled={this.isBranded}
        style={{ float: 'right' }}
        size='mini'
        content='RESET'
        onClick={this.resetTheme}
      />
    );
  }

  resetTheme = () => {
    this.handleColorSchemeChange({}, { value: 'branded' });
  };

  get isBranded () {
    return this.matchColorsToScheme() === 'branded';
  }

  render () {
    const {
      showFullscreenToggle,
      showProgressBar,
      showCurrentTime,
      showControlBar,
      showPlayButton,
      showVolumeIndicator,
      showClosedCaptionButton,
      showLikeDislikeButtons,
      showLanguageButton,
      showLevelButton,
      showRewindButton,
      showPlayBackRateButton,
    } = this.props;

    const { textColor, backgroundColor, progressBarColor } = this.resolvedColors;

    return (
      <div className='hy-controls-editor'>
        <BasicSection
          renderedContent={[
            {
              title: 'Theme',
              renderedContent: this.selectTheme,
            },
            {
              title: 'Styles',
              inlineComponent: this.resetThemeButton,
              renderedContent: (
                <div className='hy-form-field'>
                  <div className='hy-margin-right hy-margin-bottom hy-inline-block'>
                    <ColorPicker
                      label='Text & Icons'
                      color={textColor}
                      onChangeComplete={this.handleColorChange.bind(this, 'textColor')}
                    />
                  </div>
                  <div className='hy-margin-right hy-margin-bottom hy-inline-block'>
                    <ColorPicker
                      label='Background'
                      color={backgroundColor}
                      onChangeComplete={this.handleColorChange.bind(this, 'backgroundColor')}
                    />
                  </div>
                  <div className='hy-margin-right hy-inline-block'>
                    <ColorPicker
                      label='Progress Bar'
                      color={progressBarColor}
                      onChangeComplete={this.handleColorChange.bind(this, 'progressBarColor')}
                    />
                  </div>
                </div>
              ),
            },
            {
              title: 'Control Bar Features',
              id: 'showControlBar',
              inlineComponent: this.controlBarToggle,
              renderedContent: (
                <Grid>
                  {showControlBar && (
                    <Grid.Row columns={COLUMNS}>
                      <Grid.Column>
                        <Checkbox
                          checked={showFullscreenToggle}
                          label='Fullscreen Toggle'
                          onChange={this.handleCheckboxToggle.bind(this, { key: 'showFullscreenToggle', checked: showFullscreenToggle })}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Checkbox
                          checked={showProgressBar}
                          label='Progress Bar'
                          onChange={this.handleCheckboxToggle.bind(this, { key: 'showProgressBar', checked: showProgressBar })}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Checkbox
                          checked={showCurrentTime}
                          label='Current Time'
                          onChange={this.handleCheckboxToggle.bind(this, { key: 'showCurrentTime', checked: showCurrentTime })}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Checkbox
                          checked={showClosedCaptionButton}
                          label='Closed Caption Button'
                          onChange={this.handleCheckboxToggle.bind(this, { key: 'showClosedCaptionButton', checked: showClosedCaptionButton })}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Checkbox
                          checked={showPlayButton}
                          label='Play Button'
                          onChange={this.handleCheckboxToggle.bind(this, { key: 'showPlayButton', checked: showPlayButton })}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Checkbox
                          checked={showVolumeIndicator}
                          label='Volume Indicator'
                          onChange={this.handleCheckboxToggle.bind(this, { key: 'showVolumeIndicator', checked: showVolumeIndicator })}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Checkbox
                          checked={showLikeDislikeButtons}
                          label='Like/Dislike Buttons'
                          onChange={this.handleCheckboxToggle.bind(this, { key: 'showLikeDislikeButtons', checked: showLikeDislikeButtons })}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Checkbox
                          checked={showRewindButton}
                          label='Rewind Button'
                          onChange={this.handleCheckboxToggle.bind(this, { key: 'showRewindButton', checked: showRewindButton })}
                        />
                      </Grid.Column>
                      {groupConfig.allow('platform.localization.enabled') && (
                        <Grid.Column>
                          <Checkbox
                            checked={showLanguageButton}
                            label='Language Button'
                            onChange={this.handleCheckboxToggle.bind(this, { key: 'showLanguageButton', checked: showLanguageButton })}
                          />
                        </Grid.Column>
                      )}
                      <Grid.Column>
                        <Checkbox
                          checked={showLevelButton}
                          label='Level Button'
                          onChange={this.handleCheckboxToggle.bind(this, { key: 'showLevelButton', checked: showLevelButton })}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Checkbox
                          checked={showPlayBackRateButton}
                          label='Playback Rate Button'
                          onChange={this.handleCheckboxToggle.bind(this, { key: 'showPlayBackRateButton', checked: showPlayBackRateButton })}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </Grid>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

export { PlayerEditor };
