import { Condition } from 'types/actions';
import { Annotation } from 'types/annotations';

import { notEmpty } from '../../../../sharedUtils';
import QUIZ_BUILDER_CONFIGS from './quizBuilderConfigs.json';

export const getQuizBuilderConfigByAnnotationToolType = (toolType: 'question' | 'quiz') => {
  return QUIZ_BUILDER_CONFIGS[toolType] || {};
};

export const buildVariations = (updates: any, annotation: Annotation, customConditions: any) => {
  const updateableKeys = Object.keys(updates).filter((key) => Object.keys(updates[key]).length);

  const variations = updateableKeys.map((key) => {
    const currentProps = Object.keys(updates[key]);
    const customConditionKeys = customConditions.map((con: any) => con.id);
    const changes = {};

    currentProps.forEach((prop) => {
      const valuesNotSet = !updates[key][prop] && !annotation[prop];
      const valuesNotChanged = updates[key][prop] === annotation[prop];

      if (valuesNotSet || valuesNotChanged) {
        return; // do not build a variation when changing a value back to the default setting of the annotation
      }

      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      changes[prop] = updates[key][prop];
    });

    if (!Object.keys(changes).length) {
      return null;
    }

    if (key === 'mobilePortrait') {
      return {
        conditions: [
          {
            state: 'deviceType',
            value: 'mobile',
            assertion: 'equal',
          },
          {
            state: 'orientation',
            value: 'portrait',
            assertion: 'equal',
          },
        ],
        changes: changes,
      };
    }

    if (key === 'paused') {
      return {
        conditions: [
          {
            state: 'player',
            value: { paused: true },
            assertion: 'equal',
          },
        ],
        changes: changes,
      };
    }

    if (customConditionKeys.includes(key)) {
      const currentCondition = customConditions.filter((condition: Condition) => condition.id === key)[0];
      const { state, value, assertion } = currentCondition;

      return {
        conditions: [
          {
            state: state,
            value: state === 'score' && isNaN(parseInt(value)) ? parseInt(value) : value,
            assertion: assertion,
          },
        ],
        changes: changes,
      };
    }

    return null;
  });

  return variations.filter(notEmpty);
};

export const distillVariationsToState = (variations: any, customConditionalStates: any) => {
  const result = {
    mobilePortrait: {},
    paused: {},
  };
  const isCustomVariation = (conditions: Condition[], customConditionalStates: any) => {
    let matches = [];
    let conditionId: any;

    conditions.forEach((condition: Condition) => {
      if (condition.assertion && condition.state && condition.value) {
        const { assertion, state, value } = condition;

        if (state !== 'variables') {
          conditionId = `${state} ${assertion} ${value}`;
        } else {
          const variableName = Object.keys(value)[0];
          conditionId = `${state} ${variableName} ${assertion} ${value[variableName as keyof typeof value]}`;
        }
        matches = customConditionalStates.filter((conditional: any) => conditional.id === conditionId);
      }
    });

    return matches.length
      ? { isCustomVariation: true, customVariationId: conditionId }
      : { isCustomVariation: false, customVariationId: null };
  };

  variations.forEach((variation: any) => {
    const { changes, conditions } = variation;
    const values: any = [];
    let containsCustomVariation;

    conditions.forEach((condition: Condition) => {
      values.push(condition.value);
    });

    const isStandardMobileVariation = values.includes('mobile') && values.includes('portrait');

    if (!isStandardMobileVariation) {
      containsCustomVariation = isCustomVariation(variation.conditions, customConditionalStates);
    }

    if (isStandardMobileVariation) {
      result.mobilePortrait = changes;
    } else if (!containsCustomVariation?.isCustomVariation) {
      result.paused = changes;
    } else {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      result[containsCustomVariation.customVariationId] = changes;
    }
  });
  return result;
};
