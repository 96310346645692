import { AxiosResponse } from 'axios';

import { publishGoogleDrive } from '../../persistence/platformService';
import { assetService, stateController } from '../../stateController';
import { AwsS3Publisher } from './AwsS3Publisher';

class GoogleDrivePublisher extends AwsS3Publisher {
  superReconnect = this.reconnect; // reference to parent method unavailable when overriding class properties

  // @ts-expect-error TS(7024): Function implicitly has return type 'any' because ... Remove this comment to see the full error message
  reconnect = async () => {
    // @ts-expect-error TS(7022): 'result' implicitly has type 'any' because it does... Remove this comment to see the full error message
    const result = await this.superReconnect();

    if (result) {
      const { src } = result;
      const { bundleName } = this.bundleProps;
      const { posterImage } = stateController.getCurrentData().project;
      const { src: thumbnail } = assetService.getImage(posterImage);

      // eslint-disable-next-line no-undef
      const { data }: AxiosResponse = await publishGoogleDrive({
        thumbnail,
        name: bundleName,
        location: src,
      });

      const existingBundle = assetService.getCurrentBundle();

      this.saveBundleWithoutStatusUrl({
        ...existingBundle,
        customSrc: data.webViewLink,
      });

      return result;
    }

    return Promise.resolve();
  };
}

export { GoogleDrivePublisher };
