import React from 'react';

import { v4 as uuid } from 'uuid';

import defaults from '../../../../DEFAULTS.json';
import { Button, Form, Icon, Popup, Table, TextArea } from '../../../../hapyak-ui-toolkit';
import { comm } from '../../../../services/comm';
import hms from '../../../../services/time';

import './index.scss';
const { MIN_ANNOTATION_DURATION } = defaults;

type State = any;

type MultiTimeAnnotationListProps = {
  data: any;
  duration: any;
  hideTrash?: boolean;
  items: any;
  showTranslation: any;
  isEditingTranslation: boolean;
  showType?: any;
  lexileLevel?: any;
  type?: any;
  language?: any;
  updateItems: any;
};

class MultiTimeAnnotationList extends React.Component<MultiTimeAnnotationListProps, State> {
  handleScroll: any;
  table: any;
  constructor (props: MultiTimeAnnotationListProps) {
    super(props);

    this.state = {
      _items: [],
      focusRow: '',
      textHeight: 'auto',
    };
  }

  jumpToTime = (time: any) => {
    comm.trigger('setCurrentTime', time + MIN_ANNOTATION_DURATION / 2, true); // half of min duration to insure the content is visible after seek
  };

  getItem = (identifier: any) => {
    const items = [...this.props.items];
    return items.find((item) => item.identifier === identifier);
  };

  onBlur = (identifier: any, key: any, e: any) => {
    const val = e.target.value;
    this.updateItem(identifier, key, val, true);
    this.setState({ focusRow: '', textHeight: 'auto' });
  };

  onFocus = (identifier: any, e: any) => {
    const height = e.target.scrollHeight;
    if (height !== this.state.scrollHeight) {
      this.setState({ focusRow: identifier, textHeight: `${height}px` });
    }
  };

  onChange = (identifier: any, key: any, e: any) => {
    const val = e.target.value;
    this.updateItem(identifier, key, val, false);
    this.onFocus(identifier, e);
  };

  updateItem = (identifier: any, key: any, val: any, onBlur: any) => {
    const items = [...this.props.items];
    const item = this.getItem(identifier);
    item[key] = val;

    if (!onBlur) {
      this.setState({ _items: items });
    } else {
      this.setState({ _items: [] }, () => {
        this.props.updateItems(this.props.data, items);
      });
    }
  };

  firstStartTime = (arr: any) => Math.min(...arr);

  deleteItem = (identifier: any) => {
    const items = this.props.items.filter((item: any) => item.identifier !== identifier);
    this.props.updateItems(this.props.data, items, true);
  };

  isEnglishMaxAnnotationText = () => {
    const { lexileLevel, type, language } = this.props;
    return lexileLevel === 'MAX' && type === 'Annotation Text' && language === 'en';
  };

  createDataRow = (item: any) => {
    const { icon, rowSpan } = item;
    const { isEditingTranslation, showType } = this.props;
    const identifier = item.identifier;
    const defaultJumpTo = this.jumpToTime.bind(this, this.firstStartTime(item.startTimes));
    const isMaxEnglighAnnotationText = this.isEnglishMaxAnnotationText();
    const { focusRow, textHeight } = this.state;

    const row = (
      <Table.Row key={item.identifier} style={{ cursor: 'pointer' }}>
        {this.props.hideTrash ? null : (
          <Table.Cell>
            <Button size='small' icon='trash' onClick={this.deleteItem.bind(this, identifier)} disabled={isEditingTranslation} />
          </Table.Cell>
        )}
        {icon && showType ? (
          <Table.Cell rowSpan={rowSpan || null}>
            <Icon name={icon} />
          </Table.Cell>
        ) : null}
        <Table.Cell textAlign='left'>
          {item.startTimes.map((time: any) => {
            return time === null ? null : (
              <Button className='hy-button-as-link' color='blue' basic key={uuid()} onClick={this.jumpToTime.bind(this, time)} style={{ marginRight: '5px' }}>
                {hms.secondsToHMS(time)}
              </Button>);
          })}
        </Table.Cell>
        <Table.Cell onClick={defaultJumpTo}>
          <Form className='hy-inline-form'>
            <Form.Field>
              <TextArea rows={1} value={item.text} onChange={this.onChange.bind(this, identifier, 'text')} onBlur={this.onBlur.bind(this, identifier, 'text')} disabled={isEditingTranslation || item.editingDisabled || isMaxEnglighAnnotationText} placeholder={item.placeholder || ''} style={{ resize: 'none' }} />
            </Form.Field>
          </Form>
        </Table.Cell>
        <Table.Cell>
          <Form className='hy-inline-form'>
            <Form.Field>
              <TextArea rows={1} value={item.translatedText} onFocus={this.onFocus.bind(this, identifier)} onChange={this.onChange.bind(this, identifier, 'translatedText')} onBlur={this.onBlur.bind(this, identifier, 'translatedText')} disabled={!isEditingTranslation || item.editingDisabled} style={focusRow === identifier ? { height: textHeight } : { height: 'auto' }} />
            </Form.Field>
          </Form>
        </Table.Cell>
      </Table.Row>
    );

    return !isEditingTranslation ? (
      row
    ) : (
      <Popup key={identifier} content='Select native video language to edit disabled fields.' trigger={row} />
    );
  };

  get items () {
    const { items } = this.props;
    const { _items } = this.state;
    return _items.length ? _items : items;
  }

  render () {
    const { showTranslation } = this.props;

    const dataRows = this.items.map((item: any) => this.createDataRow(item));

    return (
      <div ref={this.table} className='hy-multi-time-annotation-list'>
        <Table celled compact color='blue' textAlign='center' onScroll={this.handleScroll}>
          <Table.Header>
            <Table.Row>
              {this.props.hideTrash ? null : <Table.HeaderCell width={1} />}
              {this.props.showType ? <Table.HeaderCell width={1} content='Type' /> : null}
              <Table.HeaderCell width={4}>Detected Times</Table.HeaderCell>
              <Table.HeaderCell textAlign='left'>Text</Table.HeaderCell>
              {showTranslation && <Table.HeaderCell textAlign='left'>Alt</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>{dataRows}</Table.Body>
        </Table>
      </div>
    );
  }
}

export { MultiTimeAnnotationList };
