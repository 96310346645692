import { toolTypeCollects } from '../../../../../services/toolRelationshipService';
import {
  QuestionDefaultDataOption,
  QuestionEditingProperties,
  QuestionType,
  QuestionTypeConfig
} from '../../../../../types/questions';
import { GradingRule } from '../../../../../types/quizUtilityTypes';

export const MAX_CHARACTER_INPUT_LENGTH = 440;
/*  20 words in the average sentence length; 5 characters in the average word; 1 space per word; safety margin is * 2
 *  ((20 * 2) * (5*2)) + ((20*2))
 * */

export const WHITE_LISTED_ANNOTATIONS_AS_ANSWERS = ['button', 'note', 'image'];

const DEFAULT_REQUIRED_VALUES = ['value'];
export const QUIZ_ANSWER_TOOL_TYPE = 'quizAnswer';

const DEFAULT_PLACEHOLDERS = {
  option: 'Write an answer',
  value: 'Value',
  thumbnail: '',
};

export const ANSWER_INPUT_KEYS = Object.keys(DEFAULT_PLACEHOLDERS);
export const QUIZ_ANSWER_DEFAULT_OVERRIDES = { content: '', properties: { value: '', thumbnail: '' } };

export const convertOptionNumberToAlphabeticChar = (num: number) => {
  return String.fromCharCode(num + 65);
};

export const createOptionLabelByQuestionType = (questionType: QuestionType, idx: number) => {
  const ALPHABET_LENGTH = 26;
  const Z_INDEX = 25;
  const optionNumber = idx < ALPHABET_LENGTH ? idx : Z_INDEX;

  switch (questionType) {
    case 'linearScale':
      return idx === 0 ? 'Min' : 'Max';
    default:
      return `Option ${convertOptionNumberToAlphabeticChar(optionNumber)}`;
  }
};

const createOverrides = (option = '', value = '', thumbnail = '', otherProps = {}) => {
  return {
    ...QUIZ_ANSWER_DEFAULT_OVERRIDES,
    content: option,
    properties: {
      ...QUIZ_ANSWER_DEFAULT_OVERRIDES.properties,
      value,
      thumbnail,
      ...otherProps,
    },
  };
};

const MULTI_VALUE_DEFAULTS = createOverrides('Option', 'value');

export const getQuestionDataByType = (type: QuestionType): QuestionTypeConfig => {
  if (!type) return {} as QuestionTypeConfig;
  return QUESTION_DEFAULT_DATA[type] || {};
};

export const getDefaultAnnotationsByQuestionType = (type: QuestionType) => {
  if (!type) return [];
  const { defaultAnnotations = [] } = getQuestionDataByType(type);
  return defaultAnnotations;
};

const INCREMENTAL_ANNOTATION_ANSWER_PROPERTIES = ['option', 'value'];

const incrementAnnotationProps = (defaultAnnotation = {} as any, num: any) => {
  const { properties } = defaultAnnotation;
  const _props = { ...properties };

  INCREMENTAL_ANNOTATION_ANSWER_PROPERTIES.forEach((prop) => {
    if (!_props[prop]) return;
    _props[prop] = `${_props[prop]} ${num}`;
  });

  return {
    ...defaultAnnotation,
    content: createOptionLabelByQuestionType(properties.type, num - 1),
    properties: {
      ...properties,
      ..._props,
    },
  };
};

export const incrementNewAnswerDefaults = (type: QuestionType, num = 1) => {
  const { editing = {} } = getQuestionDataByType(type);
  const { defaultAnnotation = {} } = editing as QuestionEditingProperties;

  if (!defaultAnnotation) return {};

  return incrementAnnotationProps(defaultAnnotation, num);
};

export const getCollectionType = (toolType: any) => {
  const collectionType = toolTypeCollects(toolType);
  return collectionType.length ? collectionType[0] : ''; // default to the first collection type
};

const ANY_CORRECT: GradingRule = 'anyCorrect';
const ALL_CORRECT: GradingRule = 'allCorrect';
const GRADING_RULE_OPTIONS = {
  [ANY_CORRECT]: {
    text: 'Any correct option can be selected',
    value: ANY_CORRECT,
    key: ANY_CORRECT,
  },
  [ALL_CORRECT]: {
    text: 'All correct options must be selected',
    value: ALL_CORRECT,
    key: ALL_CORRECT,
  },
};
const defaultGradingRuleOptions = [GRADING_RULE_OPTIONS[ANY_CORRECT]];
const defaultEditingProps = {
  editor: 'default',
  correctConfigurable: true,
  requiredProperties: DEFAULT_REQUIRED_VALUES,
  gradingRule: defaultGradingRuleOptions,
  inputValueType: 'text',
};

export const QUESTION_DEFAULT_DATA: QuestionDefaultDataOption = {
  multipleChoice: {
    editing: {
      ...defaultEditingProps,
      addOrRemoveDisabled: false,
      iconName: 'circle outline',
      placeholders: DEFAULT_PLACEHOLDERS,
      defaultAnnotation: MULTI_VALUE_DEFAULTS,
      hasOtherOption: true,
      isCompatibleWith: ['checkbox'],
    },
    defaultAnnotations: [
      {
        toolType: QUIZ_ANSWER_TOOL_TYPE,
        overrides: incrementAnnotationProps(MULTI_VALUE_DEFAULTS, 1),
      },
    ],
  },
  checkbox: {
    editing: {
      ...defaultEditingProps,
      addOrRemoveDisabled: false,
      iconName: 'square outline',
      placeholders: DEFAULT_PLACEHOLDERS,
      defaultAnnotation: MULTI_VALUE_DEFAULTS,
      hasOtherOption: true,
      isCompatibleWith: ['multipleChoice'],
      gradingRule: [GRADING_RULE_OPTIONS[ANY_CORRECT], GRADING_RULE_OPTIONS[ALL_CORRECT]],
    },
    defaultAnnotations: [
      {
        toolType: QUIZ_ANSWER_TOOL_TYPE,
        overrides: incrementAnnotationProps(MULTI_VALUE_DEFAULTS, 1),
      },
    ],
  },
  trueFalse: {
    editing: {
      ...defaultEditingProps,
      iconName: 'legal',
      addOrRemoveDisabled: true,
      defaultAnnotation: createOverrides('Option', 'value'),
      nonEditableProperties: ['value'],
      placeholders: {
        option: 'Option',
        value: 'Value',
      },
    },
    defaultAnnotations: [
      {
        toolType: QUIZ_ANSWER_TOOL_TYPE,
        overrides: createOverrides('True', 'true'),
      },
      {
        toolType: QUIZ_ANSWER_TOOL_TYPE,
        overrides: createOverrides('False', 'false'),
      },
    ],
  },
  linearScale: {
    editing: {
      ...defaultEditingProps,
      iconName: 'balance scale',
      addOrRemoveDisabled: true,
      // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      defaultAnnotation: createOverrides(5, 5),
      correctConfigurable: false,
      inputValueType: 'number',
      placeholders: {
        option: 'Label',
        value: 'Number Value',
      },
    },
    defaultAnnotations: [
      {
        toolType: QUIZ_ANSWER_TOOL_TYPE,
        overrides: createOverrides('1', '1'),
      },
      {
        toolType: QUIZ_ANSWER_TOOL_TYPE,
        overrides: createOverrides('10', '10'),
      },
    ],
  },
  annotations: {
    editing: {
      disabled: true,
      iconName: 'hand pointer outline',
      editor: 'answersAsAnnotations',
      gradingRule: defaultGradingRuleOptions,
      defaultAnnotation: createOverrides(),
    },
  },
  openResponse: {
    editing: {
      ...defaultEditingProps,
      editor: 'shortAnswerEditor',
      iconName: 'minus',
      addOrRemoveDisabled: true,
      defaultAnnotation: createOverrides('Open Response', ''),
      nonEditableProperties: ['value'],
      placeholders: {
        option: 'Text Prompt',
        value: '',
      },
    },
    defaultAnnotations: [
      {
        toolType: QUIZ_ANSWER_TOOL_TYPE,
        overrides: createOverrides('Short Text Answer', '', '', { min: 0, max: 50 }),
      },
    ],
  },
};

export const QUESTION_TYPES = Object.keys(QUESTION_DEFAULT_DATA);
export const ENABLED_EDITING_QUESTION_TYPES = Object.keys(QUESTION_DEFAULT_DATA).filter((type) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return !QUESTION_DEFAULT_DATA[type].editing.disabled;
});

export const getGradingRuleOptionsByQuestionType = (questionType: QuestionType) => {
  const { editing = {} as QuestionEditingProperties } = QUESTION_DEFAULT_DATA[questionType] || {};
  const { gradingRule = [] } = editing;
  return gradingRule;
};
