import React from 'react';

import { Button, Checkbox } from '@newsela/angelou';
import _ from 'lodash';

import '../index.scss';

import defaults from '../../../../DEFAULTS.json';
import { CustomTextArea, Icon, Label, Popup, Table } from '../../../../hapyak-ui-toolkit';
import { comm } from '../../../../services/comm';
import { media } from '../../../../services/mediaController';
import { assetService } from '../../../../services/stateController';
import hms from '../../../../services/time';
import { getMeaningfulDisplayInfo, getTool, getToolFromAnnotation } from '../../../../services/toolService';
import { removeDoubleSpaces, stopEvent, toSentenceCase } from '../../../../services/utils';
import { Annotation } from '../../../../types/annotations';
import { TimeInput } from '../../../TimeInput/TimeInput';
import { EditButton } from '../../AngelouComponents/EditButton';
const { MIN_ANNOTATION_DURATION } = defaults;

const MAX_HEIGHT = '45px';

type State = any;

type AnnotationRowProps = {
  active: boolean;
  annotation: Annotation;
  button: any;
  category: string;
  checkable: boolean;
  edit: any;
  editingChapterMenu: any;
  includedInActive: boolean | undefined;
  index: number;
  onClick: any;
  onTimeUpdate: any;
  orphan: boolean | undefined;
  overrideActiveAnnotationId: any;
  permitRowHeightVariation: any;
  selected: boolean;
  setOverrideActiveAnnotation: any;
  typeAsIcon: any;
  unique: boolean;
  update: any;
  visible: boolean;
  check: any;
}

export class AnnotationRow extends React.Component<AnnotationRowProps, State> {
  constructor (props: AnnotationRowProps) {
    super(props);
    this.state = { validationData: {}, stagedAnnotation: null };
  }

  get id () {
    const { annotation } = this.props;
    return annotation.id;
  }

  check = () => {
    this.props.check(this.id);
  };

  handleClick = (e: any) => {
    e.stopPropagation(); // don't trigger window click
    const { annotation, checkable, onClick } = this.props;

    if (checkable) return;
    const shift = e.shiftKey;
    const command = e.metaKey || e.ctrlKey;
    onClick(annotation, shift, command);
    const currentTime = media.playTime;
    if (!(shift || command) && (currentTime < annotation.start || currentTime > annotation.end)) {
      comm.trigger('setCurrentTime', annotation.start + MIN_ANNOTATION_DURATION / 2); // half of min duration to insure the annotation is visible after seek
    }
  };

  handleMouseOver = () => {
    const { checkable, editingChapterMenu, setOverrideActiveAnnotation, annotation } = this.props;

    if (editingChapterMenu) {
      setOverrideActiveAnnotation && setOverrideActiveAnnotation(annotation.id);
      return;
    }

    if (checkable) return;
    comm.trigger('setActiveAnnotation', this.id);
  };

  handleMouseLeave = () => {
    const { checkable, editingChapterMenu, setOverrideActiveAnnotation } = this.props;

    if (editingChapterMenu) {
      setOverrideActiveAnnotation && setOverrideActiveAnnotation(null);
      return;
    }

    if (checkable) return;
    comm.trigger('setActiveAnnotation', null);
  };

  edit = () => {
    const { edit } = this.props;
    edit && edit(this.id);
  };

  getDisplayElement = (modifiedActions: string[] | [], displayArray: any) => {
    const actionEls = modifiedActions.map((action: string, idx: any) => <div key={idx}>{action}</div>);

    return (
      <div style={{ maxHeight: MAX_HEIGHT, overflow: 'auto' }}>
        {[...displayArray.join(' ')]}
        {!actionEls.length ? null : <div style={{ fontSize: '10px' }}>{actionEls}</div>}
      </div>
    );
  };

  get display () {
    const { annotation } = this.props;
    const { displayArray, modifiedActions } = getMeaningfulDisplayInfo(annotation, true);

    const display = this.getDisplayElement(modifiedActions, displayArray);

    if (annotation.type === 'image' && annotation.content) {
      // @ts-expect-error TS(2322): Type 'Element' is not assignable to type 'string'.
      return <img style={{ height: '42px' }} src={assetService.getImage(annotation.content).src} alt={display} />;
    }

    return display;
  }

  persistName = (name: string) => {
    const { annotation, update } = this.props;
    update(this.id, { internal: { ...annotation.internal, name } });
  };

  persistContent = (content: any) => {
    const { update } = this.props;
    update(this.id, { content });
  };

  isValidTiming = (annotation: Annotation) => {
    if (!annotation) return false;
    return (annotation.start || 0) < (annotation.end || 0);
  };

  persistTime = async (id: any, type: any, time: any, exceededTimeWarning: any) => {
    const { update, onTimeUpdate } = this.props;

    const validationData = { [type]: exceededTimeWarning && time !== media.duration };

    this.setState({ validationData }, async () => {
      await onTimeUpdate();

      const stagedAnnotation = this.state.stagedAnnotation || this.props.annotation;
      stagedAnnotation[type] = time;

      if (this.isValidTiming(stagedAnnotation)) {
        const { start, end } = stagedAnnotation;
        await update(id, { start, end });
        this.setState({ stagedAnnotation: null, validationData: {} });
      } else {
        this.setState({ stagedAnnotation });
      }
    });
  };

  get content () {
    return _.get(this.props, 'annotation.content', '');
  }

  get internalName () {
    const internalName = _.get(this.props, 'annotation.internal.name', '');
    const nameWithoutDoubleSpaces = removeDoubleSpaces(internalName);
    const usesOldQuestionNamePattern = internalName.match(/^Question (\d+)$/);
    return usesOldQuestionNamePattern ? `Quiz question ${internalName.slice(-2).trim()}` : nameWithoutDoubleSpaces;
  }

  toolDisplay = (tool: any) => {
    const { annotation, typeAsIcon } = this.props;
    const { icon } = (typeAsIcon && getTool(annotation.toolType)) || {};
    if (icon) return <Icon fitted name={icon} />;
    return tool.display;
  };

  wrapWithPopup = (trigger: any) => {
    return (
      <Popup
        content='End time must be after start time. Field will auto-update if not corrected'
        trigger={trigger}
        open
      />
    );
  };

  render () {
    const {
      annotation,
      active,
      selected,
      unique,
      visible,
      checkable,
      includedInActive,
      button,
      update,
      orphan,
      editingChapterMenu,
      overrideActiveAnnotationId,
    } = this.props;
    const { validationData } = this.state;
    const { start, end, checked, autogenerated } = annotation;
    let className =
            active || overrideActiveAnnotationId ? 'hy-highlight-active' : !visible ? 'hy-highlight-not-visible' : '';
    if (orphan) className += ' hy-highlight-orphan';
    if (selected) className += ' hy-highlight-selected';
    if (includedInActive) className += ' hy-highlight-included';
    if (validationData.start || validationData.end) className += ' hy-highlight-error';
    const showAutogenerated = autogenerated && !checkable;
    const tool = getToolFromAnnotation(annotation);
    const useInput = typeof update === 'function';
    const alt = !unique;
    const startTimeInput = (
      <TimeInput
        simple
        time={start}
        type='start'
        min={0}
        max={validationData.end ? end - MIN_ANNOTATION_DURATION : media.duration - MIN_ANNOTATION_DURATION}
        identifier={annotation.id}
        persistTime={this.persistTime}
        forceFocus={validationData.end}
        evaluateChange={(enteredTime: number) => {
          return end - MIN_ANNOTATION_DURATION < enteredTime;
        }}
      />
    );

    const endTimeInput = (
      <TimeInput
        simple
        time={end}
        type='end'
        min={validationData.start ? start + MIN_ANNOTATION_DURATION : 0}
        max={media.duration}
        identifier={annotation.id}
        persistTime={this.persistTime}
        forceFocus={validationData.start}
        evaluateChange={(enteredTime: any) => {
          return enteredTime <= start + MIN_ANNOTATION_DURATION;
        }}
        endTimeOmitted={annotation.endTimeOmitted || false}
      />
    );

    return (
      <Popup
        trigger={
          <Table.Row
            key={this.id}
            onClick={this.handleClick}
            onDoubleClick={this.edit}
            onMouseOver={this.handleMouseOver}
            onMouseLeave={this.handleMouseLeave}
            className={className}
          >
            <Table.Cell collapsing textAlign='center'>
              <div>{this.toolDisplay(tool)}</div>
            </Table.Cell>

            {checkable && (
              <Table.Cell collapsing>
                <Checkbox
                  checked={checked}
                  onChange={this.check}
                  label=''
                  ariaProps={{ 'aria-labelledby': this.id }}
                />
              </Table.Cell>
            )}
            <Table.Cell className='hy-secondary-cell' textAlign='left'>
              {useInput && (
                <CustomTextArea
                  onBlur={this.persistName}
                  onDoubleClick={stopEvent}
                  onMouseLeave={stopEvent}
                  placeholder='Internal name'
                  value={this.internalName}
                  id={this.id}
                />
              )}
              {!useInput && (
                <div className='annotation-list-cell-center'>
                  {this.internalName}
                </div>
              )}
            </Table.Cell>
            <Table.Cell>
              {showAutogenerated && <Label corner='left' icon='lightbulb' size='mini' color='blue' />}
              {useInput && validationData.end ? this.wrapWithPopup(startTimeInput) : startTimeInput}
              {!useInput && hms.secondsToHMS(start)}
            </Table.Cell>
            <Table.Cell collapsing>
              {useInput && validationData.start ? this.wrapWithPopup(endTimeInput) : endTimeInput}

              {!useInput && hms.secondsToHMS(end)}
            </Table.Cell>
            <Table.Cell className={editingChapterMenu ? 'hy-secondary-cell' : ''} textAlign='left'>
              {editingChapterMenu && useInput && (
                <CustomTextArea
                  onBlur={this.persistContent}
                  onDoubleClick={stopEvent}
                  onMouseLeave={stopEvent}
                  placeholder='Display Name'
                  value={this.content}
                />
              )}
              {!editingChapterMenu && (
                <div className='annotation-list-cell-center'>
                  <EditButton handleClick={this.edit} />
                </div>
              )}
            </Table.Cell>
            {editingChapterMenu && <Table.Cell><EditButton handleClick={this.edit} /></Table.Cell>}
            {!!button && (
              <Table.Cell collapsing>
                <Button
                  legacy_size={Button.legacy_size.small}
                  legacy_statusColor={alt ? Button.legacy_statusColor.secondary : Button.legacy_statusColor.primary}
                  onClick={() => button[alt ? 'altCallback' : 'callback'].bind(this, annotation)}
                >
                  {alt ? button.altText : button.text}
                </Button>
              </Table.Cell>
            )}
          </Table.Row>
        }
        content='Annotation not in Layout. Edit to fix.'
        disabled={!orphan}
        size='mini'
        position='top center'
        inverted
      />
    );
  }
}
