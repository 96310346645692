import { Project } from 'types/project';

import DEFAULTS from '../../DEFAULTS.json';
import logger from '../logger';
import { toPath } from '../navUtils';
import { getCurrentBundle, getUploadAuthorization } from './platformService';

// abstraction of project crud api
export class PersistenceClient {
  // Project CRUD:
  create = (data: any): Promise<Project> => {
    logger.notImplemented('create()', this.constructor.name);
    return Promise.resolve(data);
  };

  update = () => {
    logger.notImplemented('update()', this.constructor.name);
  };

  read = (match: any): Promise<Project> => {
    logger.notImplemented('read()', this.constructor.name);
    return Promise.resolve(match);
  };

  list = () => {
    logger.notImplemented('list()', this.constructor.name);
    return Promise.resolve([]);
  };

  fetchAll = () => {
    logger.notImplemented('fetchAll()', this.constructor.name);
    return Promise.resolve([]);
  };

  getSummary = () => {
    return Promise.resolve(null);
  };

  openProject = (projectId: any) => {
    toPath(this.getUXPath(projectId));
  };

  importProject = (data: any) => {
    logger.notImplemented('importProject()', this.constructor.name);
    return Promise.resolve(data);
  };

  // Folder CRUD:
  createFolder = (data: any) => {
    logger.notImplemented('createFolder()', this.constructor.name);
    return Promise.resolve(data);
  };

  updateFolder = (data: any) => {
    logger.notImplemented('updateFolder()', this.constructor.name);
    return Promise.resolve(data);
  };

  // Utils:
  getHomePath = () => {
    return DEFAULTS.HOME_PATH;
  };

  getUXPath = (projectId: any) => {
    logger.notImplemented('getUXPath()', this.constructor.name);
    return DEFAULTS.UX_PATH;
  };

  getPath = (match: any, route: any) => {
    logger.notImplemented('getPath()', this.constructor.name);
    return '';
  };

  getPathPrefix = () => {
    logger.notImplemented('getPathPrefix()', this.constructor.name);
    return '';
  };

  // Bundle:
  getBundle = () => {
    return getCurrentBundle();
  };

  // Upload:
  // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
  getUploadAuthorization = async (...args: any[]) => getUploadAuthorization(...args);
}
