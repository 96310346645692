import React from 'react';

// @ts-expect-error TS(7016): Could not find a declaration file for module 'jque... Remove this comment to see the full error message
import $ from 'jquery';

import 'jquery-ui-sortable'; // required
import { BasicSection } from '../../CommonComponents/Menu/BasicSection';

(window as any).jQuery = $;
(window as any).$ = $;

require('formBuilder'); // builder
require('formBuilder/dist/form-render.min.js'); // renderer

type FormBuilderProps = {
  form: any;
  saveForm: (formData: any, html: any) => void;
};

export class FormBuilder extends React.Component<FormBuilderProps> {
  formBuilder: any;
  formBuilderElement: any;
  constructor (props: any) {
    super(props);

    this.formBuilderElement = React.createRef();
  }

  get builder () {
    return $(this.formBuilderElement.current);
  }

  get options () {
    const { form, saveForm } = this.props;

    const save = () => {
      const renderer = $('<div/>');
      const formData = this.formBuilder.formData;
      renderer.formRender({ formData });
      if (typeof saveForm === 'function') saveForm(formData, renderer.html());
    };

    const options = {
      onSave: save,
      onCloseFieldEdit: save,
      ...form,
    };

    return options;
  }

  componentDidMount () {
    this.formBuilder = this.builder.formBuilder(this.options);
    (window as any).FORM_BUILDER = this.formBuilder; // debug
    // debug
  }

  render () {
    return (
      <BasicSection
        renderedContent={[
          {
            title: <div style={{ borderTop: '1px solid #d8d8d8', paddingTop: '10px' }} />,
            renderedContent: <div ref={this.formBuilderElement} />,
          },
        ]}
      />
    );
  }
}
