import React from 'react';

import { Button } from '../../../hapyak-ui-toolkit';
import { RunningStatus } from '../CommonComponents/RunningStatus';

const anchorTag = {
  backgroundColor: 'transparent',
  color: 'red',
  border: 'none',
  cursor: 'pointer',
  textDecoration: 'underline',
  display: 'inline',
  margin: 0,
  padding: 0,
  float: 'right',
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const CancelableStatus = ({ active = false, processDescription = '', onCancel = () => {}, ...props }) =>
  active ? (
    <div className='hy-margin-bottom' {...props}>
      <RunningStatus inline icon={{ name: 'sync alternate' }} content={processDescription}>
        <Button style={anchorTag} onClick={onCancel}>
          Cancel Processing
        </Button>
      </RunningStatus>
    </div>
  ) : null;
