import React, { Component } from 'react';

import { Grid, Icon } from '../../../hapyak-ui-toolkit';

type ChecklistProps = {
  items: any[];
};

export class Checklist extends Component<ChecklistProps> {
  itemRow = (item: any, idx: number) => {
    const { title, active } = item;
    const icon = active ? 'check' : 'times';
    const color = active ? 'green' : 'red';

    return (
      <Grid.Row key={idx}>
        <Grid columns={2}>
          <Grid.Column width={4}>
            <Icon name={icon} size='large' color={color} />
          </Grid.Column>
          <Grid.Column width={12}>{title}</Grid.Column>
        </Grid>
      </Grid.Row>
    );
  };

  get items () {
    const { items } = this.props;
    if (!items) return null;
    return items.map(this.itemRow);
  }

  render () {
    return <Grid>{this.items}</Grid>;
  }
}
