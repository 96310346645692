import React from 'react';

import _ from 'lodash';

import './index.scss';
import { groupConfig } from '../../../../services/configurationService';
import { SORTED_TOOLS_LIST, TOOLS } from '../../../../services/toolService';
import { toolsWhiteList } from '../../../../tools/toolsWhiteList';
import { ToolType } from '../../../../types/tools';
import { DraggableTool } from '../DraggableTool';

type EditorToolboxProps = {
  layoutTargets: any;
  onDragEnd: any;
  reportHeight?: (obj: any) => void;
  stageAnnotation: (id: ToolType, target: any) => void;
};

export class EditorToolbox extends React.Component<EditorToolboxProps> {
  columns: any;
  height: any;
  reference: any;
  itemHeight = 60;
  itemWidth = this.itemHeight * 1.6;

  constructor (props: EditorToolboxProps) {
    super(props);

    this.reference = React.createRef();
  }

  componentDidUpdate () {
    const { reportHeight } = this.props;
    reportHeight && reportHeight({ height: this.height });
  }

  draggableToolPosition = ({
    index
  }: any) => {
    const columns = this.columns;
    const itemWidth = this.itemWidth;
    const row = Math.floor(index / columns);
    const col = index % columns;

    return {
      top: row * this.itemHeight + 'px',
      left: col * itemWidth + 'px',
      width: itemWidth + 'px',
      height: this.itemHeight + 'px',
    };
  };

  stageAnnotation = (targets: any, id: any) => {
    const { layoutTargets, stageAnnotation } = this.props;
    const allowedTargets = _.intersection(targets, layoutTargets);
    if (typeof stageAnnotation === 'function') stageAnnotation(id, allowedTargets[0]);
  };

  get draggableTools () {
    const { onDragEnd } = this.props;

    return this.filteredTools.map((name, index) => {
      const position = this.draggableToolPosition({ index });

      return (
        <DraggableTool
          key={name}
          id={name}
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          tool={this.provisionedTools[name]}
          index={index}
          position={position}
          onClick={this.stageAnnotation}
          onDragEnd={onDragEnd}
        />
      );
    });
  }

  get filteredTools () {
    const toolIsAvailable = (tool: string) => toolsWhiteList.includes(tool);
    const provisionedTools = Object.keys(this.provisionedTools);
    const availableTools = provisionedTools.filter(toolIsAvailable);
    const nonSorted = availableTools.filter((tool) => {
      return SORTED_TOOLS_LIST.indexOf(tool) < 0;
    });

    const sorted = SORTED_TOOLS_LIST.filter((tool) => availableTools.includes(tool));
    return [...sorted, ...nonSorted];
  }

  get provisionedTools () {
    const whitelistedAnnotations = groupConfig.allow('annotations', {});

    return Object.keys(TOOLS).reduce((allowed, toolType) => {
      if (whitelistedAnnotations[toolType]) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        allowed[toolType] = TOOLS[toolType];
      }

      return allowed;
    }, {});
  }

  render () {
    return (
      <div ref={this.reference} className='hy-toolbox-wrapper'>
        <div className='hy-toolbox'>
          <div className='hy-toolbox-inner'>{this.draggableTools}</div>
        </div>
      </div>
    );
  }
}
