import React from 'react';

import _ from 'lodash';

import DEFAULTS from '../../../DEFAULTS.json';
import { Button, Table } from '../../../hapyak-ui-toolkit';
import { comm } from '../../../services/comm';
import { groupConfig } from '../../../services/configurationService';
import { media } from '../../../services/mediaController';
import { assetService } from '../../../services/stateController';
import hms from '../../../services/time';
import { downloadToComputer } from '../../../services/utils';
import WarningBanner from '../AngelouComponents/WarningBanner';
import { ArbUploadModal } from '../AssetsEditor/ArbUploadModal';
import { AudioDescriptionUploadModal } from '../AssetsEditor/AudioDescriptionUploadModal';
import { isoCodeToObject } from '../AssetsEditor/isoLanguageCodes';
import { VttUploadModal } from '../AssetsEditor/VttUploadModal';

const uploadModals = {
  arb: ArbUploadModal,
  mp3: AudioDescriptionUploadModal,
  vtt: VttUploadModal,
};

const createMethods = {
  arb: assetService.createArb,
  mp3: assetService.createAudioDescription,
  vtt: assetService.createVtt,
};

const deleteMethods = {
  arb: assetService.deleteArb,
  mp3: assetService.deleteAudioDescription,
  vtt: assetService.deleteVtt,
};

const getArrayMethods = {
  arb: assetService.getArbArray,
  mp3: assetService.getAudioDescriptionArray,
  vtt: assetService.getVttArray,
};

type State = any;

type AccessibilityFileUploaderProps = {
  type: any;
  fileExt: any;
  filterOnType: any;
  uploadButtonContent: any;
}

export class AccessibilityFileUploader extends React.Component<AccessibilityFileUploaderProps, State> {
  activeProcess: any;
  beforeUpload: any;
  constructor (props: AccessibilityFileUploaderProps) {
    super(props);
    this.state = { ...this.initialModalState };
  }

  get initialModalState () {
    return {
      open: false,
      selected: {},
    };
  }

  open = () => {
    this.setState({ open: true });
  };

  edit = (result: any) => {
    this.setState({ open: true, selected: result });
  };

  delete = (result: any) => {
    const { fileExt } = this.props;
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    deleteMethods[fileExt](result);
  };

  downloadFile = (result: any) => {
    const { src } = result;
    src && downloadToComputer(src, '');
  };

  createRow = (result: any, idx: any) => {
    const { language, lexileLevel, type, created, src, id } = result;
    const key = id || idx;
    const downloadButton = !src ? null : <Button icon='download' onClick={this.downloadFile.bind(this, result)} />;
    const updateButton = !src || !id ? null : <Button icon='upload' onClick={this.edit.bind(this, result)} />;
    const deleteButton = !src || !id ? null : <Button icon='remove' onClick={this.delete.bind(this, result)} />;
    const isoLang = isoCodeToObject(language);
    let langDisplay = `[${language}] - ${(isoLang as any).name}`;

    if ((isoLang as any).nativeName) {
      langDisplay = `${langDisplay} - ${(isoLang as any).nativeName}`;
    }

    return (
      <Table.Row key={key} onDoubleClick={this.edit.bind(this, result)}>
        <Table.Cell textAlign='left'>{_.startCase(type)}</Table.Cell>
        <Table.Cell textAlign='left'>{lexileLevel}</Table.Cell>
        <Table.Cell textAlign='left'>{langDisplay}</Table.Cell>
        <Table.Cell textAlign='left'>{`${hms.formatDateTime(created)}`}</Table.Cell>
        <Table.Cell>
          {updateButton} {downloadButton} {deleteButton}
        </Table.Cell>
      </Table.Row>
    );
  };

  get editModalTrigger () {
    const { uploadButtonContent = 'Upload File' } = this.props;
    return (
      <Button floated='left' icon='plus' size='small' content={uploadButtonContent} primary onClick={this.open} />
    );
  }

  onClose = () => {
    this.setState({ ...this.initialModalState });
  };

  afterUpload = ({
    language,
    lexileLevel,
    args
  }: any) => {
    const { type = DEFAULTS.ARB_VIDEO_TEXT, fileExt } = this.props;

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    createMethods[fileExt](
      {
        isControlled: true,
        language,
        lexileLevel,
        src: args[0],
        type,
        videoId: media.id,
      },
      false
    );

    comm.trigger('updatePlayer');
  };

  get editModal () {
    const { open, selected } = this.state;
    const { type = DEFAULTS.ARB_VIDEO_TEXT, fileExt } = this.props;
    const { lexileLevel } = selected;
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const Component = uploadModals[fileExt];

    return (
      <Component
        color='green'
        compactDisplay
        selected={selected}
        type={type}
        lexileLevel={lexileLevel}
        onClose={this.onClose}
        forceOpen={open}
        trigger={this.editModalTrigger}
        activeProcess={this.activeProcess}
        beforeUpload={this.beforeUpload}
        afterUpload={this.afterUpload}
      />
    );
  }

  get assets () {
    const { fileExt } = this.props;
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return getArrayMethods[fileExt]();
  }

  get results () {
    const { type, filterOnType } = this.props;
    const filteredAssets = (type: any) => this.assets.filter((asset: any) => asset.type === type);
    return !type || !filterOnType ? this.assets : filteredAssets(type);
  }

  get rows () {
    return this.results.map(this.createRow);
  }

  get footerActions () {
    return this.editModal;
  }

  render () {
    const isEnabled = groupConfig.allow('platform.localization.enabled');
    if (!isEnabled) {
      return (
        <WarningBanner
          body="We're sorry, this feature is not enabled for your account."
        />
      );
    }
    return (
      <Table celled compact striped color='blue' textAlign='center'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign='left'>Type</Table.HeaderCell>
            <Table.HeaderCell textAlign='left'>Lexile Level</Table.HeaderCell>
            <Table.HeaderCell textAlign='left'>Language</Table.HeaderCell>
            <Table.HeaderCell textAlign='left'>Created</Table.HeaderCell>
            <Table.HeaderCell textAlign='left'>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{this.rows}</Table.Body>

        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan='16'>{this.footerActions}</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
}
