import React from 'react';

import './index.scss';
import { media } from '../../../../services/mediaController';
import { Video } from '../../../Video';

type State = any;

type EmbeddedEditorProps = {
  controlsUnavailable: boolean;
  placeholderAnnotations: any[];
}

class EmbeddedEditor extends React.Component<EmbeddedEditorProps, State> {
  constructor (props: EmbeddedEditorProps) {
    super(props);

    this.state = { lastVolume: this.preview ? this.preview.volume : 1 };
  }

  componentDidMount () {
    this.setState({ lastVolume: this.preview ? this.preview.volume : 1 });
    if (this.preview && this.preview.volume !== null) {
      this.preview.volume = 0;
    }
  }

  componentWillUnmount () {
    const { lastVolume } = this.state;

    if (this.preview && this.preview.volume !== null && lastVolume !== null) {
      this.preview.volume = lastVolume;
    }
  }

  get preview () {
    return media.previewInstance;
  }

  render () {
    const { controlsUnavailable, placeholderAnnotations } = this.props;
    const classes =
            'hy-embedded-editor-outer hy-edit-mode ' + (controlsUnavailable ? 'hy-controls-unavailable' : '');

    return (
      <div className={classes}>
        {/* @ts-expect-error TS(2322): Type '{ placeholderAnnotations: any; }' is not ass... Remove this comment to see the full error message */}
        <Video placeholderAnnotations={placeholderAnnotations} />
      </div>
    );
  }
}

export { EmbeddedEditor };
