type HashObj = {
  [key: string]: string;
}

function hashToObject (hash: string): HashObj {
  //
  // Convert a hash #foo=bar&baz=bif ... into an object {'foo': 'bar', 'bif': 'baz'}
  //

  // Remove leading # (present when doing window.location.hash)
  if (hash.startsWith('#')) {
    hash = hash.substr(1);
  }

  // convert to a [name, value] array of arrays
  const itemPairs = hash.split('&').map((item: string) => item.split('='));

  // convert to object
  return Object.fromEntries(itemPairs);
}

export default {
  hashToObject,
};
