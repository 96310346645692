// determines which persistence method is used (platform or tools)

import _ from 'lodash';

import DEFAULTS from '../../DEFAULTS.json';
import logger from '../logger';
import { isGoogleDriveNew } from '../navUtils';
import { GoogleDrivePersistenceClient } from './googleDrivePersistenceClient';
import { LocalPersistenceClient } from './localPersistenceClient';
import { PlatformPersistenceClient } from './platformPersistenceClient';

const { GOOGLE_DRIVE_NEW } = DEFAULTS;
export const AVAILABLE_CLIENTS = {
  GOOGLE_DRIVE: 'drive',
  PLATFORM: 'platform',
  LOCAL: 'local',
};
const DEFAULT_CLIENT = AVAILABLE_CLIENTS.LOCAL;

type Client =
  | GoogleDrivePersistenceClient
  | LocalPersistenceClient
  | PlatformPersistenceClient;

class PersistenceClientFactory {
  private static _client: Client;

  implementationMap = {
    [AVAILABLE_CLIENTS.GOOGLE_DRIVE]: GoogleDrivePersistenceClient,
    [AVAILABLE_CLIENTS.LOCAL]: LocalPersistenceClient,
    [AVAILABLE_CLIENTS.PLATFORM]: PlatformPersistenceClient,
  };

  getClient = () => {
    return PersistenceClientFactory._client;
  };

  setClient = (groupConfig: any, pageContext: any) => {
    let configuredClient = groupConfig.get('persistence.client.type', DEFAULT_CLIENT);

    if (configuredClient === AVAILABLE_CLIENTS.PLATFORM && pageContext.pathname === GOOGLE_DRIVE_NEW) {
      configuredClient = AVAILABLE_CLIENTS.GOOGLE_DRIVE;
    }

    logger.log(`Using ${configuredClient} persistence client.`);
    const PersistenceClient = _.get(this.implementationMap, configuredClient, LocalPersistenceClient);
    PersistenceClientFactory._client = new PersistenceClient();
  };

  isClientSupportedForRoute ({
    location
  }: any) {
    const client = this.getClient();

    if (client && isGoogleDriveNew(location)) {
      return (client as any).forGoogleDrive;
    }

    return true;
  }
}

// singleton
export const persistenceClientFactory = new PersistenceClientFactory();
