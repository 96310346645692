import React from 'react';

import './ProjectTemplateCard.scss';

import { Card } from '@newsela/angelou';

export const ProjectTemplateCard = ({
  preview,
  onClick,
  additionalClassNames = ''
}: any) => {
  const templateTitle = `${preview.description} Template`;

  const handleClick = () => {
    onClick({ preview });
  };

  return (
    <Card
      ariaProps={{
        'aria-label': templateTitle,
      }}
      additionalClassName={`project-template ${additionalClassNames}`}
      onClick={handleClick}
      // void href makes card focusable for screen readers
      href='#'
    >
      <img src={preview.image} alt={templateTitle} />
      <span className='label'>
        {preview.description}
      </span>
    </Card>
  );
};
