import React, { Component } from 'react';

import '../index.scss';
import { Icon } from '../../../../hapyak-ui-toolkit';

type UpDownArrowProps = {
  desc: string;
}

class UpDownArrow extends Component<UpDownArrowProps> {
  render () {
    return <Icon size='small' name={`caret ${this.props.desc ? 'down' : 'up'}`} />;
  }
}

export { UpDownArrow };
