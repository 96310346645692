import {
  CurrentQuizQuestion,
  QuizQuestionCorrectnessAssertion,
  QuizFormDataAssertion,
  QuizSection,
  QuizPassStatus
} from 'types/quizUtilityTypes';

import { Annotation } from '../../types/annotations';
import { QuestionType } from '../../types/questions';

export const createWhenOnCurrentQuestionCondition = ({
  quizId,
  quizQuestionId
}: CurrentQuizQuestion) => {
  return {
    state: 'quiz',
    assertion: 'equal',
    value: {
      [quizId]: {
        currentQuestion: quizQuestionId,
      },
    },
  };
};

export const createQuizQuestionCorrectnessAssertionCondition = ({
  quizId,
  assertedCorrectness,
  quizQuestionId: quizQuestion
}: QuizQuestionCorrectnessAssertion) => {
  const correctList = assertedCorrectness === 'correct' ? 'answeredCorrectly' : 'answeredIncorrectly';

  return {
    state: 'quiz',
    assertion: 'includes',
    value: {
      [quizId]: {
        [correctList]: quizQuestion,
      },
    },
  };
};

export const createFormDataAssertionCondition = ({
  quizId,
  evaluationType,
  isValue,
  formId
}: QuizFormDataAssertion) => {
  return {
    state: 'formData',
    assertion: evaluationType,
    value: {
      [quizId]: {
        [formId]: isValue,
      },
    },
  };
};

export const createAfterSectionCondition = ({
  quizId,
  sectionId
}: QuizSection) => {
  return {
    state: 'quiz',
    assertion: 'equal',
    value: {
      [quizId]: {
        currentSection: sectionId,
        lastQuestion: true,
      },
    },
  };
};

const getEvaluationByQuestionType = (questionType: QuestionType) => {
  /* `checkbox` means the formData can contain multiple, so we must assert on `includes` */
  return questionType === 'checkbox' ? 'includes' : 'equal';
};

export const getFormEvaluationTypeByAnnotation = (annotation: Annotation) => {
  const { properties } = annotation;
  const { type } = properties;
  return getEvaluationByQuestionType(type);
};

export const createQuizPassingCondition = ({
  quizId,
  isPassing
}: QuizPassStatus) => {
  return {
    state: 'quiz',
    assertion: 'equal',
    value: {
      [quizId]: {
        passing: isPassing,
      },
    },
  };
};

export const createQuizPostSubmitPassingCondition = ({
  quizId,
  isPassing
}: QuizPassStatus) => {
  const passingDefined = typeof isPassing === 'boolean';
  const passing = passingDefined ? { passing: isPassing } : {};

  return {
    state: 'quiz',
    assertion: 'equal',
    value: {
      [quizId]: {
        postSubmit: true,
        ...passing,
      },
    },
  };
};
