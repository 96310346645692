import './index.scss';
import React, { useState, useEffect } from 'react';

import { Checkbox } from '@newsela/angelou';

import { media } from '../../../../../services/mediaController';
import { updateAnnotation } from '../../../../../services/toolService';
import { Annotation } from '../../../../../types/annotations';

type StickyEndTimeProps = {
  annotation: Annotation;
}

export const StickyEndTime = (props: StickyEndTimeProps) => {
  const [isSticky, setIsSticky] = useState(false);
  const { annotation } = props;
  const { id, endTimeOmitted, end } = annotation;
  const { video } = media;

  useEffect(() => {
    setIsSticky(endTimeOmitted);
  }, [endTimeOmitted]);

  const setStickyEndTime = () => {
    setIsSticky(!isSticky);
    updateAnnotation(id, { endTimeOmitted: !isSticky, end: !isSticky ? video.duration / 1000 : end });
  };

  return (
    <div className='hy-annotation-no-end-time'>
      <Checkbox onChange={setStickyEndTime} checked={isSticky} label='no end time' />
    </div>
  );
};
