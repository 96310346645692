// @ts-expect-error TS(7016): Could not find a declaration file for module 'node... Remove this comment to see the full error message
import * as webvtt from 'node-webvtt';

import { filterAssetsByComboString } from '../lexileService';
import logger from '../logger';
import { assetService } from '../stateController';
import { textToVttFile, uploadVtt } from '../uploadUtils';

class VttService {
  read = async (src: any) => {
    const contents = await this.retrieveFile(src);
    return webvtt.parse(contents, { meta: true });
  };

  parseFile = async (src: any) => {
    const { cues = [] } = await this.read(src);
    const cuesWithPosition = cues.map((cue: any) => {
      return {
        ...cue,
        position: cue.styles === 'line:1' ? 'top' : 'bottom',
      };
    });

    return this.checkOrGenerateIdentifiers(cuesWithPosition);
  };

  checkOrGenerateIdentifiers = (cues: any) => {
    if (!cues.length) {
      return cues;
    }

    if (cues[0].identifier) {
      return cues;
    }

    return cues.map((cue: any, index: any) => {
      return {
        ...cue,
        identifier: (index + 1).toString(),
      };
    });
  };

  retrieveFile = async (src: any) => {
    return fetch(src)
      .then((response) => {
        return fetch(response.url);
      })
      .then((data) => {
        return data.text();
      })
      .catch(function (error) {
        logger.log(error);
      });
  };

  createFileFromData = async ({
    src
  }: any) => {
    const response = await fetch(src);
    const data = await response.blob();
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    return textToVttFile(data);
  };

  upload = async ({
    projectId,
    file,
    selectedType,
    selectedLanguage,
    items,
    existingSrc
  }: any) => {
    return await uploadVtt(projectId, file, selectedType, selectedLanguage, items, existingSrc);
  };

  setAsset = ({
    language,
    lexileLevel,
    src,
    type,
    videoId
  }: any) => {
    assetService.createVtt(
      {
        isControlled: true,
        language,
        lexileLevel,
        src,
        type,
        videoId,
      },
      false
    );
  };

  updateItemData = (cues: any, baseCueData: any) => {
    const _cues = [...cues];
    let _baseCueData = [...baseCueData];

    _baseCueData = _baseCueData.map((cue) => {
      const match = _cues.find((c) => c.identifier === cue.identifier);

      if (match) {
        const { text } = match;
        return { ...cue, text };
      }

      return cue;
    });

    return _baseCueData.filter((cue) => !!cue.text);
  };

  get vttLanguages () {
    return filterAssetsByComboString(assetService.getVttArray());
  }

  clearRepeatedTranslatedValues = (baseLanguage: any, translatedLanguage: any) => {
    return translatedLanguage;
  };
}

export const vttService = new VttService();
