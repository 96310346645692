import React from 'react';

import '../EditorControlBar/index.scss';

import defaults from '../../../../DEFAULTS.json';
import { Popup } from '../../../../hapyak-ui-toolkit';
import { getToolFromAnnotation } from '../../../../services/toolService';
import { toPercent, toPixels } from '../../../../services/utils';
import { Annotation } from '../../../../types/annotations';
import timelineConfig from './timelineConfig.json';
const { MIN_ANNOTATION_DURATION } = defaults;
const { MARGIN_HEIGHT, MARGIN_WIDTH, MARKER_HEIGHT, PADDING_HEIGHT } = timelineConfig;

type TimelineRowProps = {
  active: boolean;
  annotation: Annotation;
  boundsWidth: number;
  clamped: string;
  duration: number;
  end: number;
  index: number;
  short: boolean;
  start: number;
};

export class TimelineRow extends React.Component<TimelineRowProps> {
  render () {
    const { active, annotation, boundsWidth, clamped, index, start, end, duration, short } = this.props;

    if (!annotation) return null;

    const color = getToolFromAnnotation(annotation).color;
    const leftPercent = toPercent(annotation.start - start, duration);
    const rightPercent = toPercent(end - annotation.end, duration);
    const left = MARGIN_WIDTH + toPixels(leftPercent, boundsWidth) + 'px';
    const right = MARGIN_WIDTH + toPixels(rightPercent, boundsWidth) + 'px';
    const clamping = clamped === 'short' && short;
    const clampedShadow = clamping ? '0 0 2px 2px #fff, 0 0 3px 3px #f00' : '';
    const activeShadow = active ? '0 0 0 2px #fff' : '';

    const style: React.CSSProperties = {
      position: 'absolute',
      left,
      right,
      backgroundColor: color || '#1892bf',
      top: index === 0 ? MARGIN_HEIGHT : MARGIN_HEIGHT + index * MARKER_HEIGHT + PADDING_HEIGHT + 'px',
      height: MARKER_HEIGHT - PADDING_HEIGHT + 'px',
      minWidth: '1px',
      boxShadow:
                clampedShadow ||
                activeShadow ||
                'rgba(0, 0, 0, 0.5) 0px -2px 5px -3px inset, rgba(255, 255, 255, 0.5) 0px 2px 5px -3px inset',
      borderRadius: '2px',
    };

    return (
      <Popup
        trigger={<div style={style} />}
        position='bottom center'
        open={clamping}
        content={`Annotation duration cannot be less than ${MIN_ANNOTATION_DURATION} seconds`}
        inverted
      />
    );
  }
}
