import React from 'react';

import { SemanticCOLORS } from 'semantic-ui-react';

import { Button } from '../../../../hapyak-ui-toolkit';
import { comm } from '../../../../services/comm';
import { takePicture } from '../../../../services/hapshot';
import { assetService } from '../../../../services/stateController';
import { ImageMetadata } from '../../../../types/assets';

type SnapshotButtonProps = {
  addPage?: boolean;
  className?: string;
  color?: SemanticCOLORS;
  content?: string;
  handler?: () => void;
  loading?: boolean;
  onClick?: () => void;
  onComplete?: (image: ImageMetadata) => void;
  overrides?: any;
  style?: React.CSSProperties;
};

class SnapshotButton extends React.Component<SnapshotButtonProps> {
  onClick = () => {
    const { onClick, handler } = this.props;
    if (onClick) onClick();

    if (handler && typeof handler === 'function') {
      handler();
    } else {
      this.snapshot();
    }
  };

  snapshot = (time?: any) => {
    takePicture(true, time).then((result) => {
      const { overrides, addPage, onComplete } = this.props;
      const image = assetService.createImage(result.src);

      if (typeof onComplete === 'function') onComplete(image);

      if (!addPage) return;

      comm.trigger('addStoryboardPage', {
        image: image.id,
        time: result.time,
        device: 'desktop',
        portrait: false,
        ...overrides,
      });
    });
  };

  render () {
    const { style, className = '', content = 'New Page', color = undefined, loading } = this.props;

    return (
      <Button
        loading={loading}
        color={color}
        className={className}
        icon='camera'
        content={content}
        onClick={this.onClick}
        style={style}
      />
    );
  }
}

export { SnapshotButton };
