import React, { Component } from 'react';

import { Publishing } from 'types/publishing';

import { Table, Button, Modal, Input, Label, Header, Icon, Grid } from '../../../hapyak-ui-toolkit';
import { groupConfig } from '../../../services/configurationService';
import signingService from '../../../services/signingService';
import { assetService, stateController } from '../../../services/stateController';
import { formatDateTime } from '../../../services/time';
import { ExternalLinksTable } from '../CommonComponents/ExternalLinksTable';
import { ImagePicker } from '../CommonComponents/ImagePicker';
import { BasicSection } from '../CommonComponents/Menu/BasicSection';
import { SetSourceModal } from './SetSourceModal';

type State = any;

type PublishingDataTableProps = Publishing & {
  bundles: any;
  handleNewPackageClick: () => void;
  stopPackagingButton?: any;
};
export class PublishingDataTable extends Component<PublishingDataTableProps, State> {
  stopPackagingButton: any;
  constructor (props: PublishingDataTableProps) {
    super(props);

    this.state = {
      ...this.initialModalState,
    };
  }

  get projectId () {
    const { project } = stateController.getCurrentData();
    return project.id;
  }

  get currentBundle () {
    const { bundles } = this.props; // this is a single bundle
    return bundles || {};
  }

  get initialModalState () {
    return {
      showSetSourceModal: false,
      modalOpen: false,
      modalContent: {
        header: null,
        content: null,
        actions: [],
      },
    };
  }

  get packageAndPublishButton () {
    const { isPublishing } = this.props;

    return (
      <Button
        disabled={isPublishing}
        loading={isPublishing}
        onClick={this.openEditingModal}
        primary
        content={this.isValidBundle ? 'Modify Settings' : 'Publish'}
      />
    );
  }

  get canPublishPublicly () {
    return groupConfig.allow('publishing.targets.aws-s3');
  }

  get publishingSettingsButton () {
    const { isPublishing } = this.props;

    return (
      <Button loading={isPublishing} disabled={isPublishing} onClick={this.showPublishConfig} icon='setting' />
    );
  }

  get bundleInfo () {
    const { bundles } = this.props;
    return bundles || {};
  }

  get isValidBundle () {
    return this.currentBundle.created;
  }

  get unsetText () {
    return '-';
  }

  get unsetValueElement () {
    return <div style={{ opacity: 0.3, display: 'inline' }}>{this.unsetText}</div>;
  }

  get src () {
    const { src } = this.bundleInfo;
    return !src ? '' : src + 'config.json';
  }

  resetModal = () => this.setState(this.initialModalState);

  openEditingModal = () => this.setState(this.initialModalState, this.handleNewPackageClick);

  handleNewPackageClick = () => {
    const { handleNewPackageClick } = this.props;
    if (handleNewPackageClick) handleNewPackageClick();
  };

  formatTime = (time: any) => !time ? '' : formatDateTime(time);

  get pubPropertyMap () {
    const { bundleOutdated } = this.props;
    const {
      created,
      expirationDate,
      publishTo,
      whitelist = '',
      seoTitle,
      seoDescription,
      seoUploadDate,
      seoThumbnail,
    } = this.bundleInfo;

    return {
      status: {
        label: 'Status',
        value: created ? 'Published' : 'Unpublished',
      },
      publishedOn: {
        label: 'Published on',
        value: this.formatTime(created),
      },
      publishedTo: {
        label: 'Published To',
        value: this.formatTime(created),
      },
      publishedBy: {
        label: 'Published by',
        value: '',
      },
      hosting: {
        label: 'Hosting',
        value: !this.canPublishPublicly ? 'Self Hosted' : publishTo,
      },
      expiration: {
        label: 'Expiration',
        value: this.formatTime(expirationDate),
      },
      whitelist: {
        label: 'Allowed domains',
        value: whitelist,
      },
      bundleOutdated: {
        label: 'Package',
        value: bundleOutdated,
      },
      title: {
        label: 'SEO Title',
        value: seoTitle,
      },
      description: {
        label: 'SEO Description',
        value: seoDescription,
      },
      seoUploadDate: {
        label: 'SEO Upload Date',
        value: formatDateTime(seoUploadDate),
      },
      thumbnail: {
        label: 'SEO Thumbnail',
        value: seoThumbnail,
      }
    };
  }

  get readOnlyModal () {
    const { modalOpen, modalContent } = this.state;
    const { header, content, actions } = modalContent;

    return !modalOpen ? null : (
      <Modal
        open
        onClose={this.resetModal}
        header={header}
        content={<BasicSection className='hy-form-field' renderedContent={content} />}
        actions={[<Button key='reset' content='Close' onClick={this.resetModal} />, ...actions]}
      />
    );
  }

  openModal = ({
    header,
    content,
    actions = []
  }: any) => {
    this.setState({
      modalOpen: true,
      modalContent: { header, content, actions },
    });
  };

  infoElement = ({ label = '', property = '', key = 0, disabled = true, open = true }) => {
    const value = !property || !property.length ? this.unsetText : property;
    return (
      <Grid columns='equal' stackable key={key}>
        <Grid.Column width={10}>
          <Input
            label={label}
            value={value}
            disabled={disabled}
            readOnly
            fluid
            className='hy-margin-bottom'
          />
        </Grid.Column>
        <Grid.Column style={{ whiteSpace: 'nowrap', paddingLeft: 0 }}>
          {!disabled && <Button onClick={() => { navigator.clipboard.writeText(value); }} primary>Copy link</Button>}
        </Grid.Column>
        <Grid.Column style={{ whiteSpace: 'nowrap' }}>
          {!disabled && open && <Button onClick={() => { window.open(value, '_blank'); }} primary><Icon name='external alternate' /> Open</Button>}
        </Grid.Column>
      </Grid>
    );
  };

  setRestrictionModal = () => {
    if (!this.isValidBundle) return;
    const { expiration, whitelist } = this.pubPropertyMap;
    const content = (
      <>
        {this.infoElement({ label: whitelist.label, property: whitelist.value, key: 0 })}
        {this.infoElement({ label: expiration.label, property: expiration.value, key: 1 })}
      </>
    );

    this.openModal({ header: 'Restrictions', content });
  };

  showPublishConfig = () => {
    const settings = [
      'status',
      'publishedOn',
      'publishedBy',
      'hosting',
      'expiration',
      'whitelist',
      'title',
      'description',
      'seoUploadDate',
      'thumbnail',
    ];
    const settingsBlock = settings.map((item, idx) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const { label, value } = this.pubPropertyMap[item];

      if (item === 'thumbnail') {
        const image = assetService.getImage(value);
        return <ImagePicker key={idx} image={image} label={label} disabled className='hy-margin-bottom' />;
      }

      return (
        <Input
          key={idx}
          label={label}
          value={value || this.unsetText}
          className='hy-margin-bottom'
          disabled
          fluid
        />
      );
    });

    this.openModal({
      header: 'Settings',
      content: (
        <div>
          {' '}
          {settingsBlock} {this.packageAndPublishButton}{' '}
        </div>
      ),
    });
  };

  createPackageStatusElement = ({ key = 0 }) => {
    const { bundleOutdated } = this.pubPropertyMap;
    const isOutdated = bundleOutdated.value;
    const content = isOutdated ? 'Out-of-date' : 'Up-to-date';
    const color = isOutdated ? 'red' : null;
    return (
      <Label
        key={key}
        // @ts-expect-error TS(2322): Type '"red" | null' is not assignable to type 'Sem... Remove this comment to see the full error message
        color={color}
        style={{ paddingBottom: '4px' }}
        className='hy-margin-top'
        content={content}
      />
    );
  };

  get statusContent () {
    const { publishedOn, expiration } = this.pubPropertyMap;
    const statusItems = [publishedOn, expiration];
    const statusElements = statusItems.map((item, idx) => this.infoElement({ label: item.label, property: item.value, key: idx, disabled: (item as any).disabled, open: (item as any).open }));
    statusElements.push(this.createPackageStatusElement({ key: statusItems.length + 1 }));

    return !publishedOn.value ? 'Unpublished' : statusElements;
  }

  get publishingActions () {
    const { zip } = this.bundleInfo;
    const { isPublishing } = this.props;

    return (
      <>
        {this.publishingButton}
        {isPublishing && this.stopPackagingButton}
        {!isPublishing && zip && (
          <a href={signingService.sign(zip)}>
            <Button icon='download' />
          </a>
        )}
      </>
    );
  }

  get publishingButton () {
    return this.isValidBundle ? this.publishingSettingsButton : this.packageAndPublishButton;
  }

  openSetSourceModal = () => {
    this.setState({ showSetSourceModal: true });
  };

  get packageRows () {
    const { id, whitelist, zip, src, bundleName = '' } = this.bundleInfo;
    const actionElement = (prop: any, action: any) =>
      !prop ? this.unsetValueElement : <Button icon='eye' onClick={action} />;

    return (
      <Table.Row key={id}>
        <Table.Cell className='hy-form-field'>
          <Header content={bundleName} />
          {this.statusContent}
        </Table.Cell>
        <Table.Cell>
          {zip || src ? <Button icon='eye' onClick={this.openSetSourceModal} /> : this.unsetValueElement}
        </Table.Cell>
        <Table.Cell>{actionElement(whitelist, this.setRestrictionModal)}</Table.Cell>
        <Table.Cell>{this.publishingActions}</Table.Cell>
      </Table.Row>
    );
  }

  render () {
    const { showSetSourceModal } = this.state;
    const { updateBundle } = this.props;
    const { refId } = stateController.getCurrentData().project;

    return (
      <div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={7}>STATUS</Table.HeaderCell>
              <Table.HeaderCell width={3}>SOURCE INFO</Table.HeaderCell>
              <Table.HeaderCell width={3}>RESTRICTIONS</Table.HeaderCell>
              <Table.HeaderCell width={3} />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.packageRows}
          </Table.Body>
        </Table>

        {refId && (<ExternalLinksTable refId={refId} projectId={this.projectId} />)}

        {this.readOnlyModal}

        {showSetSourceModal && (
          <SetSourceModal updateBundle={updateBundle} bundle={this.currentBundle} onClose={this.resetModal} />
        )}

      </div>
    );
  }
}
