import axios from 'axios';

import { comm } from './comm';
import logger from './logger';
import signingService from './signingService';
import { stateController } from './stateController';

let _nonEditableAnnotations: any = null; // outside of class to ensure it can't be set outside of this file

class AnnotationService {
  get nonEditableAnnotations () {
    return _nonEditableAnnotations;
  }

  setNonEditableAnnotations = async () => {
    const { nonEditableAnnotations, nonEditableAnnotationsUrl } = stateController.getCurrentData('ux');

    if (nonEditableAnnotationsUrl) {
      const signedUrl = signingService.sign(nonEditableAnnotationsUrl);
      const remoteNonEditableAnnotations = await this.fetchAnnotations(signedUrl);

      if (remoteNonEditableAnnotations) {
        _nonEditableAnnotations = remoteNonEditableAnnotations; // use remote annotations
        return _nonEditableAnnotations;
      }
    }

    _nonEditableAnnotations = nonEditableAnnotations; // backwards compatibility

    return _nonEditableAnnotations;
  };

  fetchAnnotations = async (url: any) => {
    return axios
      .get(url)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        logger.warn('Error fetching nonEditableAnnotations', err);
        return null;
      });
  };

  resetNonEditableAnnotations = async () => {
    await annotationService.setNonEditableAnnotations();
    comm.trigger('reloadPlayer');
  };
}

const annotationService = new AnnotationService(); // singleton so we can keep only one copy of nonEditableAnnotations in memory for the whole app

export { annotationService };
