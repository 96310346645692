import React from 'react';

import { Input } from '@newsela/angelou';

type AngelouInputReadOnlyProps = {
  label: string;
  value: string;
  __cssFor?: Record<string, unknown>
  __classNameFor?: Record<string, unknown>
}

export const AngelouInputReadOnly = ({
  label,
  value,
  __cssFor,
  __classNameFor
}: AngelouInputReadOnlyProps) => {
  return (
    <Input
      label={label}
      isRequired={false}
      inputType={Input.inputTypes.text}
      value={value}
      onChange={() => undefined}
      inputAriaProps={{ 'aria-readonly': true, 'aria-required': false }}
      __cssFor={__cssFor}
      __classNameFor={__classNameFor}
    />
  );
};
