import React from 'react';

import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { Action } from 'types/actions';

import { Button, Modal, Popup, Table } from '../../../../hapyak-ui-toolkit';
import hms from '../../../../services/time';
import { findAnnotationById, getAnnotationDisplay } from '../../../../services/toolService';
import { toSentenceCase } from '../../../../services/utils';
import { Annotation } from '../../../../types/annotations';
import { getActionInputByAnnotationToolType, supportsConditionals } from './utils';

const DEFAULT_STATE = { event: null, action: null, args: null };
const actionsWithAnnotationChoice = ['toAnnotation', 'applyGate', 'releaseGate', 'setPanel'];

type State = any;

export type ActionsListProps = {
  actionText?: string;
  annotation: Annotation;
  annotations: Annotation[];
  currentAnnotation?: Annotation;
  disableAddAndEdit?: boolean;
  duration: number;
  setFromObject: any;
};

class ActionsList extends React.Component<ActionsListProps, State> {
  handleClose: any;
  constructor (props: ActionsListProps) {
    super(props);
    this.state = { open: null, ...DEFAULT_STATE };
  }

  open = () => {
    this.setState({ open: 'add', ...DEFAULT_STATE });
  };

  edit = (action: Action) => {
    this.setState({ open: 'edit', ...action });
  };

  close = () => {
    this.setState({ open: null });
  };

  updateState = (state: any) => {
    this.setState(state);
  };

  updateAction = () => {
    const { annotation, setFromObject } = this.props;
    const { event, action, args, id, condition } = this.state;
    const actions = [...annotation.actions.map((a: any) => ({
      ...a
    }))] || []; // copy actions, not just array
    const previousActionConfig = actions.find((a) => a.id === id);
    Object.assign(previousActionConfig, { ...previousActionConfig, action, args, event, condition });
    setFromObject({ actions }, annotation.id);
    this.close();
  };

  addAction = () => {
    const { annotation, setFromObject } = this.props;
    const { event, action, args, condition } = this.state;
    const actions = [...annotation.actions] || [];
    const id = 'action_' + uuid();

    actions.push({
      id,
      event,
      action,
      args,
      condition,
    });

    setFromObject({ actions }, annotation.id);
    this.close();
  };

  delete = (id: any) => {
    const { annotation, setFromObject } = this.props;
    const { actions } = annotation;
    setFromObject({ actions: actions.filter((action: Action) => action.id !== id) }, annotation.id);
  };

  displayArgs = (_action: Action) => {
    const { args, action } = _action;
    let displayKeyValue: any;

    return Object.keys(args).map((key: string) => {
      let value = args[key as keyof typeof args];

      if (key === 'isSelf') {
        return null;
      }

      const { annotations } = this.props;
      if (key === 'target' && action === 'setPanel') {
        value = `${toSentenceCase(value.replace('adjacent_', ''))} Panel`;
      } else if (actionsWithAnnotationChoice.includes(action)) {
        const match = annotations.find((a: Annotation) => a.id === value);
        const annotationDisplay = getAnnotationDisplay(match);
        const selectedValue = action === 'toAnnotation' ? 'None Selected' : 'Current Annotation';
        value = match ? annotationDisplay : selectedValue;
      } else if (action === 'toTime') {
        value = hms.secondsToHMS(value);
      } else if (action === 'setVariables' && value) {
        displayKeyValue = {
          key: Object.keys(value)[0],
          value: value[Object.keys(value)[0]],
        };
      } else if (action === 'goToSection' && value) {
        const match = annotations.find((a: Annotation) => a.id === value);
        const annotationDisplay = getAnnotationDisplay(match);

        displayKeyValue = {
          key: key ? toSentenceCase(key) : '-',
          value: annotationDisplay || '-',
        };
      }

      return value ? (
        <div key={key}>
          {!displayKeyValue && <b>{key}: </b>}
          {!displayKeyValue && value}
          {displayKeyValue && (
            <div>
              <p>
                <b>Name:</b> {displayKeyValue.key}
              </p>
              <p>
                <b>Value:</b> {displayKeyValue.value}
              </p>
            </div>
          )}
        </div>
      ) : null;
    });
  };

  createActionItems = (action: Action) => {
    const { id } = action;

    return (
      <div className='hy-inline-block'>
        <Button size='small' icon='trash' onClick={this.delete.bind(this, id)} />
      </div>
    );
  };

  displayConditions = (action = {} as any) => {
    const { condition = {} } = action;
    const { quizQuestion = '', quizAnswer = '' } = condition;

    if (!quizQuestion) return null;

    const questionAnnotation = findAnnotationById(quizQuestion);
    const quizAnswerAnnotation = findAnnotationById(quizAnswer);

    const questionText = _.get(questionAnnotation, 'content', '-');
    const defaultAnswerText = quizAnswer ? toSentenceCase(quizAnswer) : '-';
    const answerText = _.get(quizAnswerAnnotation, 'properties.value', defaultAnswerText);

    return (
      <div>
        <p>
          <b>Question:</b> {questionText}
        </p>
        <p>
          <b>Answer:</b> {answerText}
        </p>
      </div>
    );
  };

  createRow = (action: Action, idx: number) => {
    const { disableAddAndEdit, annotation } = this.props;
    const { id, event } = action;
    const actionItems = this.createActionItems(action);
    const key = id || idx;
    const showCondition = supportsConditionals(annotation);

    return (
      <Popup
        key={'annotation_action_' + key}
        trigger={
          <Table.Row key={key} style={{ cursor: 'pointer' }} onDoubleClick={this.edit.bind(this, action)}>
            <Table.Cell textAlign='left'>{toSentenceCase(event)}</Table.Cell>
            {showCondition && (
              // @ts-expect-error TS(2554): Expected 0-1 arguments, but got 2.
              <Table.Cell textAlign='left'>{this.displayConditions(action, event)}</Table.Cell>
            )}
            <Table.Cell textAlign='left'>{toSentenceCase(action.action)}</Table.Cell>
            <Table.Cell textAlign='left'>{this.displayArgs(action)}</Table.Cell>
            <Table.Cell>{actionItems}</Table.Cell>
          </Table.Row>
        }
        content='Double click to edit'
        size='mini'
        disabled={disableAddAndEdit}
        position='top right'
        inverted
      />
    );
  };

  get editModal () {
    const { duration, annotation, annotations } = this.props;
    if (!annotation) return;
    const { open, event, action, args, condition } = this.state;
    const add = open === 'add';
    const ActionInputComponent = getActionInputByAnnotationToolType(annotation.toolType);

    return (
      <Modal open={!!open} onClose={this.handleClose} dimmer='inverted' size='large'>
        <Modal.Header>{add ? 'Add' : 'Edit'} Action</Modal.Header>
        <Modal.Content>
          <ActionInputComponent
            event={event}
            action={action}
            condition={condition}
            args={args}
            update={this.updateState}
            duration={duration}
            annotations={annotations}
            annotation={annotation}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button content='Cancel' onClick={this.close} />
          <Button primary content='OK' onClick={add ? this.addAction : this.updateAction} />
        </Modal.Actions>
      </Modal>
    );
  }

  get actions () {
    const { annotation } = this.props;
    return annotation.actions || [];
  }

  get willRenderMainTable () {
    return true;
  }

  get rows () {
    return this.actions.map(this.createRow);
  }

  get tableHeader () {
    return (
      <Table.Row>
        <Table.HeaderCell colSpan='16'>
          <Button className='hy-action-modal-trigger' onClick={this.open}>
            Add Action
          </Button>
        </Table.HeaderCell>
      </Table.Row>
    );
  }

  get secondaryView () {
    return null;
  }

  render () {
    const { actionText = 'Edit', annotation = {} as Annotation } = this.props;
    const { open } = this.state;
    const showCondition = supportsConditionals(annotation);
    return !this.willRenderMainTable ? (
      this.secondaryView
    ) : (
      <Table celled compact striped color='blue' textAlign='center'>
        <Table.Header>
          {this.tableHeader}
          <Table.Row>
            <Table.HeaderCell textAlign='left'>When this happens</Table.HeaderCell>
            {showCondition && <Table.HeaderCell textAlign='left'>Condition</Table.HeaderCell>}
            <Table.HeaderCell textAlign='left'>Do this</Table.HeaderCell>
            <Table.HeaderCell textAlign='left'>Details</Table.HeaderCell>
            <Table.HeaderCell textAlign='left'>{actionText}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{this.rows}</Table.Body>

        {open && this.editModal}
      </Table>
    );
  }
}

export { ActionsList };
