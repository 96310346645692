export const form = {
  _include: {
    attrs: [
      'description',
      'inline',
      'label',
      'max',
      'maxlength',
      'min',
      'multiple',
      'name',
      'options',
      'other',
      'placeholder',
      'required',
      'rows',
      'step',
      'style',
      'subtype',
      'toggle',
      'value',
    ],
    fields: [
      'header',
      'radio-group',
      'checkbox-group',
      'text',
      'textarea',
      'select',
      'number',
      'date',
      'hidden',
      'paragraph',
      'button',
    ],
  },
  _exclude: {
    attrs: ['access', 'className'],
    fields: ['autocomplete', 'file'],
  },
};
