import { assetService, stateController } from './services/stateController';

export const updateDefaultImages = ({
  video,
  posterImage
}: any) => {
  posterImage = posterImage || video.posterImage;
  const image = assetService.createImage(posterImage, true); // use video id to identify this as the video thumbnail
  const { id = null } = image;

  if (id) {
    stateController.updateProject('project', { posterImage: id });
    stateController.updateProject('publishing', { thumbnail: id, seoThumbnail: id });
  }
};

export const loadScipt = (scriptSource: any) => {
  const script = document.createElement('script');
  script.src = scriptSource;
  script.async = true;

  document.body.appendChild(script);
};

export const notEmpty = (item: any) => !!item;
