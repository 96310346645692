import React, { Component } from 'react';

import { Checkbox } from '@newsela/angelou';

import { Button, Grid, Input } from '../../../../hapyak-ui-toolkit';
import { groupConfig } from '../../../../services/configurationService';
import { Embed } from '../../../../types/publishing';
import { BasicSection } from '../../CommonComponents/Menu/BasicSection';

type State = any;

type PackagingAdvancedSettingsProps = {
  embed: Embed;
  handleChange: any;
  updateValue: any;
  handleToggle: any;
};

export class PackagingAdvancedSettings extends Component<PackagingAdvancedSettingsProps, State> {
  constructor (props: PackagingAdvancedSettingsProps) {
    super(props);

    const fields = this.getInitialFields(props);
    this.state = { fields };
  }

  getInitialFields = (props: PackagingAdvancedSettingsProps) => {
    const { embed } = props;
    const { embedVariables = {} } = embed;
    const keys = Object.keys(embedVariables);
    if (keys.length === 0) return [{ k: null, v: null }];

    return Object.keys(embedVariables).map((key) => {
      return {
        k: key,
        v: embedVariables[key],
      };
    });
  };

  handleChange = (i: any, event: any, fieldType: any) => {
    const { fields } = this.state;
    const values = [...fields];

    if (fieldType === 'fieldValue') {
      values[i].v = event.target.value;
    }

    if (fieldType === 'fieldKey') {
      values[i].k = event.target.value;
    }

    this.setFields(values);
  };

  handleAdd = () => {
    const { fields } = this.state;
    const values = [...fields];
    values.push({ k: null, v: null });
    this.setFields(values);
  };

  handleRemove = (i: any) => {
    const { fields } = this.state;
    const values = [...fields];
    values.splice(i, 1);
    this.setFields(values);
  };

  setFields = (values: any) => {
    const { handleChange } = this.props;

    this.setState({ fields: values });

    const sanitizedValues = values.reduce((obj: any, item: any) => {
      if (!item.k || !item.v) return obj;

      return {
        ...obj,
        [item.k]: item.v,
      };
    }, {});

    handleChange('embedVariables', undefined, { value: sanitizedValues });
  };

  createInputPair = (field: any, idx: any) => {
    return (
      <Grid key={`${field}-${idx}`} style={{ width: '100%' }}>
        <Grid.Row columns={2}>
          <Input
            placeholder='key'
            value={field.k || ''}
            onChange={(e) => this.handleChange(idx, e, 'fieldKey')}
          />
          <Input
            placeholder='value'
            value={field.v || ''}
            className='hy-margin-left hy-margin-right'
            onChange={(e) => this.handleChange(idx, e, 'fieldValue')}
          />
          <Button onClick={this.handleRemove.bind(this, idx)} icon='minus' />
        </Grid.Row>
      </Grid>
    );
  };

  get inputs () {
    const { fields = [] } = this.state;
    return fields.map((field: any, idx: any) => this.createInputPair(field, idx));
  }

  get sourceTypeIsLocked () {
    const { embed } = this.props;
    const { sourceType } = embed;
    return sourceType === 'static';
  }

  handleSourceTypeToggle = ({
    checked
  }: any) => {
    checked = !checked;
    const { updateValue } = this.props;
    const value = checked ? 'static' : 'dynamic';
    updateValue('sourceType', value);
  };

  get canPublishPublicly () {
    return groupConfig.allow('publishing.targets.aws-s3');
  }

  handleSeoChange ({
    key,
    checked
  }: any) {
    checked = !checked;
    const { handleToggle } = this.props;
    handleToggle(key, null, { checked });
  }

  render () {
    const { embed } = this.props;
    const { seo } = embed;

    return (
      <div>
        <BasicSection
          className='hy-remove-left-column-gutter'
          renderedContent={[
            {
              title: 'Options',
              renderedContent: (
                <>
                  <Checkbox
                    label='SEO Enabled'
                    checked={seo != null}
                    onChange={this.handleSeoChange.bind(this, { key: 'seo', checked: seo })}
                  />
                  <br />
                  <Checkbox
                    label='Lock embed code to static source'
                    __classNameFor={{ root: 'hy-margin-top' }}
                    checked={this.sourceTypeIsLocked}
                    onChange={this.handleSourceTypeToggle.bind(this, { checked: this.sourceTypeIsLocked })}
                    disabled={!this.canPublishPublicly}
                  />
                </>
              ),
            },

            {
              title: 'Pre-set variables',
              renderedContent: (
                <>
                  {this.inputs}
                  <Button type='button' onClick={this.handleAdd} icon='plus' />
                </>
              ),
            },
          ]}
        />
      </div>
    );
  }
}
