import { EmbedTypeOption } from 'types/publishing';

export const PACKAGING_OPTIONS = [
  {
    key: 'complete',
    text: 'Complete',
    value: 'complete',
    publishing: ['download'],
    extendable: 'true', // boolean not allowed in dropdown options
  },
];

export const PUBLISH_TO_OPTIONS = [
  {
    key: 'download',
    text: 'Download (Coming Soon)',
    value: 'download',
    disabled: true,
  },
];

export const VIDEO_TYPE_OPTIONS = [
  {
    key: 'mp4',
    text: 'MP4',
    value: 'mp4',
  },
  {
    key: 'm3u8',
    text: 'HLS M3U8',
    value: 'm3u8',
  },
];

export const EMBED_TYPE_OPTIONS: EmbedTypeOption[] = [
  {
    key: 'lightbox',
    text: 'Lightbox',
    value: 'lightbox',
  },
  {
    key: 'iframe',
    text: 'Iframe',
    value: 'iframe',
  },
  {
    key: 'customTag',
    text: 'Custom Tag',
    value: 'customTag',
  },
];

export const EMBED_DIMENSION_OPTIONS = [
  {
    text: 'Responsive',
    key: 'responsive',
    value: 'responsive',
  },
  {
    text: 'Fixed',
    key: 'fixed',
    value: 'fixed',
  },
];

export const BUNDLE_PROPS = [
  'packageId',
  'packaging',
  'customSrc',
  'publishTo',
  'seoVideoObjectSchema',
  'thumbnail',
  'videoType',
  'expirationDate',
  'playerNotAuthorizedMessaging',
  'unauthorizedMessageFormat',
  'whitelist',
  'bundleName',
  'seoTitle',
  'seoDescription',
  'seoUploadDate',
  'seoThumbnail',
  'images',
  'downloadable',
  'includeLandingPage',
]; // props to display as bundle metadata

export const PRESENTATION_OPTIONS = [
  {
    key: 'lightbox',
    text: 'Popover',
    value: 'lightbox',
  },
  {
    key: 'inline',
    text: 'Inline',
    value: 'inline',
  },
];

export const TEXT_LOCATION_OPTIONS = [
  {
    key: 'overlay',
    text: 'Overlay',
    value: 'overlay',
  },
  {
    key: 'below',
    text: 'Below',
    value: 'below',
  },
];

export const PUBLISH_SETTINGS_BLACKLIST = {
  'google-drive': ['includeLandingPage'],
};
