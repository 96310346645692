import React from 'react';

type VideoMetadataListItemProps = {
  name: string;
  value: string | number;
}

export const VideoMetadataListItem = (props: VideoMetadataListItemProps) => {
  const { name, value } = props;

  return (
    <ul style={{ listStyleType: 'none' }}>
      <li style={{ marginBottom: '10px' }}>
        <>{name}: </>
        <span style={{ float: 'right' }}>{value}</span>
      </li>
    </ul>
  );
};
