import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { AssetType, ImageMetadata } from 'types/assets';
import { Bundle } from 'types/publishing';

import { VTT } from '../types/vtt';
import { LEXILE_LEVEL_DEFAULT } from './lexileService';
import logger from './logger';
import { getExtension } from './utils';

const DISABLE_UNDO = true; // passed when creating bundle, video since we don't want users to undo the asynch operations that cause the creation

const PATHS = ['formats.mp4', 'formats.m3u8', 'formats.mov', 'formats.avi', 'src'];

export class AssetService {
  getCurrentData: any;
  signingService: any;
  updateProject: any;
  updateProjectExtemporaneously: any;
  constructor (
    getCurrentData = _.noop,
    updateProject = _.noop,
    updateProjectExtemporaneously = _.noop,
    signingService = {}
  ) {
    this.getCurrentData = getCurrentData;
    this.updateProject = updateProject;
    this.updateProjectExtemporaneously = updateProjectExtemporaneously;
    this.signingService = signingService;
  }

  makeSignature = (asset: any, lodashpath: any) => {
    try {
      const src = _.get(asset, lodashpath);
      if (src && asset.isControlled) {
        _.set(asset, lodashpath, this.signingService.sign(src));
      }
    } catch (e) {
      logger.warn('Signing failed', e);
    }
  };

  unsignFormats = (formats: any) => {
    return Object.keys(formats).reduce((unsigned, format) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      unsigned[format] = this.signingService.unsign(formats[format]);
      return unsigned;
    }, {});
  };

  // ALL ASSETS:
  getAllAssets = () => {
    const assets = this.getCurrentData('assets') || {};
    return JSON.parse(JSON.stringify(assets));
  };

  getAssets = (type: string) => {
    const assets = this.getAllAssets()[type] || {};
    return JSON.parse(JSON.stringify(assets));
  };

  setAsset = (type: AssetType, asset: any, merge: any) => {
    const assets = this.getAllAssets();
    const oldAsset = assets[type][asset.id];
    const created = (oldAsset && oldAsset.created) || asset.created;
    const updated = { ...asset, created, updated: Date.now() };
    assets[type][asset.id] = updated;

    const cloned = JSON.parse(JSON.stringify(assets));

    if (merge) {
      this.updateProjectExtemporaneously('assets', cloned);
    } else {
      this.updateProject('assets', cloned);
    }

    return updated;
  };

  removeAsset = (type: AssetType, asset: any) => {
    const assets = this.getAllAssets();
    delete assets[type][asset.id];

    this.updateProject('assets', JSON.parse(JSON.stringify(assets)));

    return asset;
  };

  applySignature (asset: any) {
    PATHS.forEach(this.makeSignature.bind(this, asset));
    return asset;
  }

  getAsset = (type: AssetType, id: string) => {
    let asset = this.getAssets(type)[id] || {};
    asset = this.applySignature(asset);
    return JSON.parse(JSON.stringify(asset));
  };

  getAssetsArray = (type: AssetType) => {
    const assetsOfType = this.getAllAssets()[type] || [];
    Object.keys(assetsOfType).forEach((key) => {
      assetsOfType[key] = this.applySignature(assetsOfType[key]);
    });
    return Object.keys(assetsOfType).map((id) => ({ ...assetsOfType[id] }));
  };

  newAsset = (overrideId?: any) => {
    return {
      id: overrideId || uuid(),
      created: Date.now(),
    };
  };

  // VIDEOS:
  createVideo = ({
    src = '',
    formats = {},
    width = 1200,
    height = 675,
    aspectRatio = 1.7777777778,
    duration,
    name,

    // default to english
    language = 'en'
  }: any) => {
    const { m3u8 = null, mp4 = null } = formats;
    src = src || mp4;
    const type = getExtension(src);

    return this.setVideo(
      {
        ...this.newAsset(),
        formats: {
          m3u8,
          mp4,
          [type]: src || mp4,
        },
        duration,
        width,
        height,
        aspectRatio,
        isControlled: true,
        name,
        processes: {},
        language,
      },
      DISABLE_UNDO
    );
  };

  setVideo = (video: any, merge: any) =>
    this.setAsset('videos', { ...video, formats: this.unsignFormats(video.formats) }, merge);

  getVideo = (id: string) => this.getAsset('videos', id);
  getVideosArray = () => this.getAssetsArray('videos');

  // ARB:
  createArb = (arb: any, merge: any) => {
    const type = arb.type ? _.toLower(arb.type).replace(/ /g, '_') : '';
    const lexileLevel = arb.lexileLevel === LEXILE_LEVEL_DEFAULT ? 'MAX' : arb.lexileLevel; // don't include if default (for backwards compatibility)
    const lexileLevelString = lexileLevel ? `_${lexileLevel}` : '';

    return this.setArb(
      {
        ...this.newAsset(`${arb.videoId}${lexileLevelString}_${arb.language}_${type}`), // unique by videoId, lexileLevel, language code, and type
        ...arb,
      },
      merge
    );
  };

  setArb = (arb: any, merge: any) => this.setAsset('arb', arb, merge);
  getArb = (id: string) => this.getAsset('arb', id);
  deleteArb = (arb: any) => this.removeAsset('arb', arb);
  getArbArray = () => this.getAssetsArray('arb');

  // Audio Description:
  createAudioDescription = (audioDescription: any, merge: any) => {
    const type = audioDescription.type ? _.toLower(audioDescription.type).replace(/ /g, '_') : '';
    const lexileLevel = audioDescription.lexileLevel === LEXILE_LEVEL_DEFAULT ? null : audioDescription.lexileLevel;
    const lexileLevelString = lexileLevel ? `_${lexileLevel}` : '';

    return this.setAudioDescription(
      {
        ...this.newAsset(
          `${audioDescription.videoId}${lexileLevelString}_${audioDescription.language}_${type}`
        ), // unique by videoId, lexileLevel, language code, and type
        ...audioDescription,
      },
      merge
    );
  };

  setAudioDescription = (audioDescription: any, merge: any) => this.setAsset('audioDescription', audioDescription, merge);
  getAudioDescription = (id: string) => this.getAsset('audioDescription', id);
  deleteAudioDescription = (audioDescription: any) => this.removeAsset('audioDescription', audioDescription);
  getAudioDescriptionArray = () => this.getAssetsArray('audioDescription');

  // VTT:
  createVtt = (vtt: VTT, merge: any) => {
    const type = vtt.type ? _.toLower(vtt.type).replace(/ /g, '_') : '';
    const lexileLevel = vtt.lexileLevel === LEXILE_LEVEL_DEFAULT ? null : vtt.lexileLevel; // don't include if default (for backwards compatibility)
    const lexileLevelString = lexileLevel ? `_${lexileLevel}` : '';

    return this.setVtt(
      {
        ...this.newAsset(`${vtt.videoId}${lexileLevelString}_${vtt.language}_${type}`), // unique by videoId, lexileLevel, language code, and type
        ...vtt,
      },
      merge
    );
  };

  setVtt = (vtt: VTT, merge: any) => this.setAsset('vtt', vtt, merge);
  getVtt = (id: string) => this.getAsset('vtt', id);
  deleteVtt = (vtt: any) => this.removeAsset('vtt', vtt);
  getVttArray = () => this.getAssetsArray('vtt');

  // CSS:
  createCss = (css: any, merge: any) => {
    const { src = '' } = css;

    return this.setCss(
      {
        ...this.newAsset(`${css.projectId}`), // unique by projectId
        isControlled: !!css.isControlled,
        src,
      },
      merge
    );
  };

  setCss = (css: any, merge: any) => this.setAsset('css', css, merge);
  getCss = (id: string) => this.getAsset('css', id);
  deleteCss = (css: any) => this.removeAsset('css', css);
  getCssArray = () => this.getAssetsArray('css');

  // IMAGES:
  stageImage = (src: string, isControlled?: boolean, type?: any) => {
    return {
      src,
      isControlled,
      type,
    };
  };

  createImage = (src: any, isControlled: boolean, type?: any, overrideId?: any): ImageMetadata => {
    return this.setImage({
      ...this.newAsset(overrideId),
      ...this.stageImage(src, isControlled, type),
    });
  };

  setImage = (image: any, merge?: any) => this.setAsset('images', image, merge);
  getImage = (id: string) => this.getAsset('images', id);
  getImagesArray = () => this.getAssetsArray('images');

  // BUNDLES:
  createBundle = (src: any, data: any): Bundle => {
    return this.setBundle(
      {
        ...this.newAsset(),
        src,
        type: 'bundle',
        ...data,
      },
      DISABLE_UNDO
    );
  };

  setBundle = (bundle: Bundle, merge: any) => {
    return this.setAsset('bundles', bundle, merge);
  };

  getBundle = (id: string) => this.getAsset('bundles', id);
  deleteBundle = (bundle: Bundle) => this.removeAsset('bundles', bundle);
  getBundlesArray = () => this.getAssetsArray('bundles');
  // TODO: this needs more consideration... especially when we start supporting multiple packages
  getCurrentBundle = () => {
    const bundles = this.getBundlesArray();
    if (!bundles.length) return {};
    return bundles[0];
  };

  cleanBundles = () => {
    const bundles = this.getBundlesArray();
    bundles.forEach(this.deleteBundle);
  };

  getScreenshot = (second: number): ImageMetadata | undefined => {
    const videos = this.getAssetsArray('videos');
    const videoName = videos[0].name;
    if (videoName === 'Sample Video') {
      return undefined;
    }
    const projectId = this.getCurrentData('project').id;
    const groupId = this.getCurrentData('project').groupId;
    const bucket = process.env.REACT_APP_STORAGE_BUCKET;
    const env = bucket?.split('-').pop();

    const url = bucket +
      '.s3.amazonaws.com' +
      '/' + env +
      '/projects' +
      '/' + groupId + '/' +
      projectId +
      '/' + videoName +
      '/screenshots/' +
      videoName + 'jpg.' +
      second.toString().padStart(7, '0') +
      '.jpg';

    const img = this.createImage(url, true);
    return img;
  };
}
