import { StyleService } from './services/styleService';

const fonts = () => {
  return (
    StyleService &&
        StyleService.fonts.map((font) => {
          return font.join('=') + ';';
        })
  );
};

const shared = {
  branding: false,
  entity_encoding: 'raw',
  theme_advanced_fonts: fonts(),
};

const simple = {
  ...shared,
  height: '77px',
  menubar: false,
  toolbar: false,
};

const advanced = {
  ...shared,
  height: '400px',
  plugins: [
    'advlist autolink lists link image charmap',
    'searchreplace help quickbars',
    'table wordcount fullscreen',
  ],
  draggable_modal: true,
  fontsize_formats: '8px 11px 12px 14px 16px 18px 24px 36px 48px 72px 96px 128px 156px',
  toolbar:
        'fontselect fontsizeselect | forecolor backcolor bold italic | align outdent indent | numlist bullist | image | help fullscreen',
};

const getConfig = (type: any) => {
  return type === 'advanced' ? advanced : simple;
};

export { getConfig };
