import React, { useEffect, useState } from 'react';

import { Input } from '@newsela/angelou';

export const AngelouInput = ({
  label,
  errorMsg,
  isRequired,
  value,
  setValue,
  isFilled = () => false,
  onChange,
  onFocus,
  onBlur,
  disabled,
  maxLength,
  inputType,
  css
}: any) => {
  const [error, setError] = useState(null);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (isRequired && (value === '' || value.trim().length === 0)) {
      setError(errorMsg);
      isFilled(false);
    } else {
      setError(null);
      if (touched) {
        isFilled(true);
      }
    }
  }, [touched, value]);

  return (
    <Input
      underInputId='angelou-input-wrapper'
      label={label}
      inputType={inputType || Input.inputTypes.text}
      error={error}
      onChange={onChange || ((e: any) => {
        setValue(e.target.value);
        if (!touched) {
          setTouched(true);
        }
      })}
      touched={touched}
      value={value}
      isRequired={isRequired}
      helperText=''
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      maxLength={maxLength}
      __cssFor={css}
    />
  );
};
