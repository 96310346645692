import { Component } from 'react';

import { Project } from 'types/project';

import type { Bundle } from '../../../types/publishing';

export type PackagingOptionsProps = {
  bundleProps: Bundle;
  className?: string;
  handleChange: (key: any, e: any, { value }: any) => void;
  hidden?: boolean;
  project?: Project;
  options?: any;
  saveChanges: () => void;
  updateValue: (key: string, value: any) => Promise<any>;
  videoTypeOptions?: any;
}

export class PackagingOptions<P extends PackagingOptionsProps> extends Component<P> {
  handleToggle = (key: any, e: any, {
    checked
  }: any) => {
    return new Promise((resolve, reject) => {
      this.updateValue(key, checked)
        .then(this.saveChanges)
        .then(resolve);
    });
  };

  handleUpdate = ({
    key,
    checked
  }: any) => {
    checked = !checked;
    return new Promise((resolve, reject) => {
      this.updateValue(key, checked)
        .then(this.saveChanges)
        .then(resolve);
    });
  };

  get saveChanges () {
    const { saveChanges } = this.props;
    return saveChanges;
  }

  get updateValue () {
    const { updateValue } = this.props;
    return updateValue;
  }

  get handleChange () {
    const { handleChange } = this.props;
    return handleChange;
  }

  get bundleProps () {
    const { bundleProps } = this.props;
    return bundleProps;
  }
}
