import './index.scss';

import React from 'react';

import { v4 as uuid } from 'uuid';

import { Action } from 'types/actions';

import DEFAULTS from '../../../../DEFAULTS.json';
import { Button, Icon, Popup } from '../../../../hapyak-ui-toolkit';
import { getUserPreferences, setUserPreferences } from '../../../../services/saveUtils';
import { toSentenceCase } from '../../../../services/utils';
import { ActionsList, ActionsListProps } from './ActionsList';

const DEFAULT_STATE = { event: null, action: null, args: null };

class SystemGeneratedActions extends ActionsList {
  constructor (props: ActionsListProps) {
    super(props);
    this.state = { dismissedActions: [], open: null, ...DEFAULT_STATE };
  }

  addSuggested = (action: Action) => {
    this.setState({ open: 'add', ...action });
  };

  composeActionIdentifier = ({
    annotationId,
    event,
    action
  }: any) => {
    return `_id:${annotationId},_e:${event},_a:${action}`;
  };

  get dismissedActions () {
    const { DISMISSED_ANNOTATION_DEFAULTS = [] } = getUserPreferences();
    return DISMISSED_ANNOTATION_DEFAULTS;
  }

  actionHasBeenDismissed = (list: any, target: any) => list.includes(target);

  isHidden = (action: Action) => {
    const { currentAnnotation = '' } = this.props;
    const { event } = action;
    const actionIdentifier =
            !!currentAnnotation &&
            this.composeActionIdentifier({ annotationId: currentAnnotation.id, event, action: action.action });
    const isHidden = actionIdentifier && this.actionHasBeenDismissed(this.dismissedActions, actionIdentifier);
    return !isHidden;
  };

  setPreferences = (list: any) => {
    setUserPreferences({ [DEFAULTS.DISMISSED_ANNOTATION_DEFAULTS]: list });
    this.setState({ dismissedActions: list });
  };

  softDelete = (data: any) => {
    const { currentAnnotation = '' } = this.props;
    if (!currentAnnotation) return;
    const { action, event } = data;
    const actionIdentifier = this.composeActionIdentifier({ annotationId: currentAnnotation.id, event, action });

    if (this.dismissedActions.includes(actionIdentifier)) return;

    this.setPreferences([...this.dismissedActions, actionIdentifier]);
  };

  addAction = () => {
    const { annotation, setFromObject, currentAnnotation = annotation } = this.props;
    const { event, action, args } = this.state;
    let actions = [...currentAnnotation.actions] || [];
    actions = actions.filter((action) => !!action.id); // ensure action isn't system generated
    const id = 'action_' + uuid();

    this.softDelete({ event, action });

    actions.push({
      id,
      event,
      action,
      args,
    });
    setFromObject({ actions });
    this.close();
  };

  createActionItems = (action: Action) => {
    return (
      <div className='hy-inline-block'>
        <Button size='small' icon='plus' onClick={this.addSuggested.bind(this, action)} />
        <Button size='small' icon='close' onClick={this.softDelete.bind(this, action)} />
      </div>
    );
  };

  get footerActions () {
    return <div className='hy-margin-top'>{this.editModal}</div>;
  }

  get actions () {
    const { annotation } = this.props;
    const actions = annotation.actions || [];
    return actions.filter(this.isHidden);
  }

  get editModalTrigger () {
    // Hide button for modal in this component
    return null;
  }

  get willRenderMainTable () {
    return this.rows?.length > 0;
  }

  get secondaryView () {
    return null;
  }

  createRow = (action: Action, idx: number) => {
    const { disableAddAndEdit } = this.props;
    const { id, event } = action;
    const key = id || idx;

    return (
      <Popup
        key={'annotation_action_' + key}
        trigger={
          <Button
            key={'annotation_action_' + key}
            onClick={this.addSuggested.bind(this, action)}
            icon
            primary
            labelPosition='right'
            className='hy-chip'
          >
            {toSentenceCase(event)} {'>'} {toSentenceCase(action.action)}
            <Icon name='plus' />
          </Button>
        }
        content='Double click to edit'
        size='mini'
        disabled={disableAddAndEdit}
        position='top right'
        inverted
      />
    );
  };

  get content () {
    return !this.willRenderMainTable ? (
      this.secondaryView
    ) : (
      <>
        {this.rows}
        {this.footerActions}
      </>
    );
  }

  render () {
    return <div className='hy-system-generated-actions'>{this.content}</div>;
  }
}

export default SystemGeneratedActions;
