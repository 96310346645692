import React, { Component } from 'react';

import { Checkbox } from '@newsela/angelou';

import { ImageMetadata } from 'types/assets';
import { Project } from 'types/project';
import { Bundle, Embed, EmbedType, EmbedTypeOption } from 'types/publishing';

import DEFAULTS from '../../../DEFAULTS.json';
import { groupConfig } from '../../../services/configurationService';
import { getUpdateTabCallbackKey } from '../../../services/utils';
import WarningBanner from '../AngelouComponents/WarningBanner';
import { BasicSection } from '../CommonComponents/Menu/BasicSection';
import { WrappingTabs } from '../CommonComponents/WrappingTabs';
import { EmbedTextBox } from './EmbedTextBox';
import { PackagingAdvancedSettings } from './SettingsTabs/PackagingAdvancedSettings';
import { PackagingAnalyticsSettings } from './SettingsTabs/PackagingAnalyticsSettings';
import PackagingEmbedSetting from './SettingsTabs/PackagingEmbedSetting';
import { PackagingPlayerSetting } from './SettingsTabs/PackagingPlayerSetting';

import './index.scss';

type State = any;

type EmbedSettingTabsProps = {
  className: any;
  bundle: Bundle;
  embed: Embed;
  embedType?: EmbedType;
  filteredEmbedOptions: EmbedTypeOption[];
  handleToggle: any;
  handleChange: any;
  updateValue: any;
  onThumbnailChange: (image: ImageMetadata) => void;
  saveChanges: any;
  project?: Project;
}

export class EmbedSettingTabs extends Component<EmbedSettingTabsProps, State> {
  constructor (props: EmbedSettingTabsProps) {
    super(props);
    this.state = {};
  }

  get embedTextBox () {
    const { bundle, embed } = this.props;
    return (
      <EmbedTextBox
        className='hy-compact-grid-row'
        bundle={bundle}
        embed={embed}
        secondaryCTA={this.embedChoice}
      />
    );
  }

  get commonProps () {
    const {
      bundle,
      embed,
      handleToggle,
      handleChange,
      updateValue,
      onThumbnailChange,
      saveChanges,
      project,
      filteredEmbedOptions,
      embedType,
    } = this.props;

    return {
      bundle,
      embed,
      handleToggle,
      handleChange,
      updateValue,
      onThumbnailChange,
      saveChanges,
      project,
      filteredEmbedOptions,
      embedType,
    };
  }

  get embedSettings () {
    return <PackagingEmbedSetting {...this.commonProps} />;
  }

  get playerSettings () {
    return <PackagingPlayerSetting {...this.commonProps} />;
  }

  get analyticsSettings () {
    return <PackagingAnalyticsSettings {...this.commonProps} />;
  }

  get advancedSettings () {
    return <PackagingAdvancedSettings {...this.commonProps} />;
  }

  get tabs () {
    return [
      {
        name: 'Embed Settings',
        render: () => <BasicSection renderedContent={this.embedSettings} />,
      },
      {
        name: 'Player Settings',
        render: () => <BasicSection renderedContent={this.playerSettings} />,
      },
      {
        name: 'Analytics Settings',
        render: () => <BasicSection renderedContent={this.analyticsSettings} />,
      },
      {
        name: 'Advanced Settings',
        render: () => <BasicSection renderedContent={this.advancedSettings} />,
      },
    ];
  }

  handleChange = (property: any, e: any, {
    checked
  }: any) => {
    const { handleChange } = this.props;
    const value = checked;
    handleChange(property, e, { value });
  };

  handleEmbedChange = (property: any, e: any, {
    checked
  }: any) => {
    const { handleChange } = this.props;
    const value = checked ? 'iframe' : 'customTag';
    handleChange('embedType', e, { value });
  };

  handleCheckboxChange = ({
    key,
    checked
  }: any) => {
    checked = !checked;
    this.handleEmbedChange(key, null, { checked });
  };

  get canPublishPublicly () {
    return groupConfig.allow('publishing.targets.aws-s3');
  }

  get needsCustomSrcMsg () {
    return <WarningBanner body='Please enter a custom source.' />;
  }

  get embedChoice () {
    const { embed } = this.props;
    const checked = embed && embed.embedType === 'iframe';

    return (
      <Checkbox
        label='Compatibility Mode (iframe)'
        checked={checked}
        onChange={this.handleCheckboxChange.bind(this, { checked, key: embed.embedType })}
      />
    );
  }

  render () {
    if (!this.canPublishPublicly && !this.props.bundle.customSrc) return this.needsCustomSrcMsg;

    return (
      <div>
        <div>{this.embedTextBox}</div>
        <div className='hy-embed-setting-tabs hy-margin-top'>
          <WrappingTabs
            className='hy-wrapping-tabs-light'
            components={this.tabs}
            type={DEFAULTS.PUBLISHING_EMBED_SETTINGS_TABS}
            updateTabCallbackKey={getUpdateTabCallbackKey(DEFAULTS.PUBLISHING_EMBED_SETTINGS_TABS)}
          />
        </div>
      </div>
    );
  }
}
