import '../EditorControlBar/index.scss';

import React from 'react';

import _ from 'lodash';

import defaults from '../../../../DEFAULTS.json';
import { updateAnnotations } from '../../../../services/toolService';
import { stopEvent, toSeconds } from '../../../../services/utils';
import { Annotation } from '../../../../types/annotations';
import timelineConfig from './timelineConfig.json';
import {
  clampPosition,
  getLatestDimensions,
  getPositioningData,
  move,
  noDragOccurred,
  toBoxAsPercents
} from './timelineHelper';
import { TimelineRow } from './TimelineRow';
const { MIN_ANNOTATION_DURATION } = defaults;
const { MARGIN_HEIGHT, MARGIN_WIDTH, PADDING_HEIGHT } = timelineConfig;

type State = any;

type TimelineRowsProps = {
  selected: any;
  updateTime: any;
  duration: any;
  baseHeight: any;
  start: any;
  end: any;
  activeAnnotation: string;
  shortestAnnotation: string | Annotation;
  shortestDuration: null | number;
}

export class TimelineRows extends React.Component<TimelineRowsProps, State> {
  boundsReference: any;
  constructor (props: TimelineRowsProps) {
    super(props);
    this.boundsReference = React.createRef();
    this.state = this.getPositioningData(props);
  }

  componentDidMount () {
    this.setState(this.getPositioningData(this.props));
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  UNSAFE_componentWillReceiveProps (nextProps: any) {
    const { start, end } = this.state;
    if (nextProps.start !== start || nextProps.end !== end) this.setState(this.getPositioningData(nextProps));
  }

  getPositioningData = (props: any) => {
    return getPositioningData(props, this.boundsWidth);
  };

  onResize = _.debounce(() => {
    this.setState({
      ...this.getPositioningData(this.props),
    });
  }, 100);

  updateSelected = (deltas: any) => {
    const { selected, updateTime } = this.props;
    const updatedAnnotations = selected.map((annotation: Annotation) => {
      const times = {};

      Object.keys(deltas).forEach((key) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        times[key] = annotation[key] - deltas[key];
      });

      if (annotation.endTimeOmitted) {
        annotation.endTimeOmitted = false;
      }

      return { ...annotation, ...times };
    });

    updateAnnotations(updatedAnnotations);

    const newTime = updatedAnnotations[0].start;

    if (newTime) {
      updateTime(newTime + MIN_ANNOTATION_DURATION / 2); // half of min duration to insure the annotation is visible after seek
    }
  };

  get boundsWidth () {
    const boundsEl = this.boundsReference.current;
    return boundsEl && boundsEl.getBoundingClientRect().width;
  }

  move = ({
    clientX
  }: any) => {
    const moved = move(this.state, clientX);
    const { clamped, dimensions } = clampPosition(this.state, this.boundsWidth, moved);

    return new Promise((resolve) => {
      // @ts-expect-error TS(2345): Argument of type '(value: unknown) => void' is not... Remove this comment to see the full error message
      this.setState({ clamped, ...dimensions }, resolve);
    });
  };

  handleMove = (e: any) => {
    e.stopPropagation();
    // @ts-expect-error TS(2554): Expected 3 arguments, but got 1.
    this.dragStart(e.clientX);
  };

  handleResize = (edge: any, e: any) => {
    e.stopPropagation();
    // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
    this.dragStart(e.clientX, edge);
  };

  dragStart = (clientX: any, edge: any, noListen: any) => {
    if (!noListen) {
      window.addEventListener('mousemove', this.handleMouseMove);
      window.addEventListener('mouseup', this.handleMouseUp);
    }

    const state = {
      ...this.getPositioningData(this.props),
      mouseX: clientX,
      edge: edge,
      isDragging: true,
    };

    return new Promise((resolve) => {
      // @ts-expect-error TS(2345): Argument of type '(value: unknown) => void' is not... Remove this comment to see the full error message
      this.setState(state, resolve);
    });
  };

  handleMouseMove = (e: any) => {
    const { isDragging } = this.state;
    if (!isDragging) return;
    this.move(e);
  };

  handleMouseUp = () => {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);

    if (noDragOccurred(this.state)) {
      this.setState({
        edge: null,
        clamped: false,
        isDragging: false,
      });
      return;
    } else {
      const times = this.getTimesFromState();
      this.updateSelected(times);
    }

    requestAnimationFrame(() => {
      // wait a frame so handleContainerClick sees 'isDragging' as true
      this.setState({
        ...getLatestDimensions(this.state),
        mouseX: null,
        edge: null,
        clamped: null,
        isDragging: false,
      });
    });
  };

  getTimesFromState = () => {
    const { duration } = this.props;
    const { edge } = this.state;
    const { deltaLeft, deltaRight } = toBoxAsPercents(this.state, this.boundsWidth);
    const start = toSeconds(deltaLeft, duration);
    const end = toSeconds(deltaRight, duration);

    if (edge === 'left') {
      return { start };
    } else if (edge === 'right') {
      return { end };
    } else {
      return { start, end };
    }
  };

  get sliderStyle () {
    const { baseHeight } = this.props;
    const { isDragging } = this.state;
    const { width, left } = toBoxAsPercents(this.state, this.boundsWidth);

    return {
      zIndex: isDragging ? '1' : '',
      width: `calc(${width} + ${MARGIN_WIDTH * 2}px)`,
      left: `calc(${left} - ${MARGIN_WIDTH}px)`,
      ...this.clampedColor,
      cursor: isDragging ? 'grabbing' : 'grab',
      height: baseHeight + MARGIN_HEIGHT * 2 - PADDING_HEIGHT + 'px',
      margin: `${MARGIN_HEIGHT}px 0`,
    };
  }

  get clampedColor () {
    return {
      backgroundColor: this.state.clamped ? 'rgba(255, 0, 0, 0.3)' : '',
    };
  }

  get timelineRows () {
    const { start, end, duration, activeAnnotation, selected, shortestAnnotation } = this.props;
    const { clamped } = this.state;

    return selected
      .map((annotation: Annotation, index: number) => {
        if (!annotation) return null;

        return (
          <TimelineRow
            key={'t_' + index}
            annotation={annotation}
            start={start}
            end={end}
            duration={duration}
            clamped={clamped}
            active={annotation.id === activeAnnotation}
            short={annotation.id === shortestAnnotation}
            index={index}
            boundsWidth={this.boundsWidth}
          />
        );
      })
      .filter(Boolean);
  }

  get sliderBorder () {
    return (
      <div className='hy-timeline-rows-slider-handles'>
        <div
          className='hy-timeline-rows-slider-handle hy-timeline-rows-slider-handle-left'
          style={this.clampedColor}
          onMouseDown={this.handleResize.bind(this, 'left')}
        />
        <div
          className='hy-timeline-rows-slider-handle hy-timeline-rows-slider-handle-right'
          style={this.clampedColor}
          onMouseDown={this.handleResize.bind(this, 'right')}
        />
      </div>
    );
  }

  render () {
    return (
      <div className='hy-timeline-rows-container' ref={this.boundsReference}>
        <div
          className='hy-timeline-rows-slider'
          style={this.sliderStyle}
          onMouseDown={this.handleMove}
          onClick={stopEvent}
        >
          <div className='hy-timeline-rows'>{this.timelineRows}</div>
          {this.sliderBorder}
        </div>
      </div>
    );
  }
}
