import axios from 'axios';
import hash from 'hash-sum';
import _ from 'lodash';

import { Bundle } from 'types/publishing';

import { Annotation } from '../types/annotations';
import logger from './logger';
import { media } from './mediaController';
import { collectPowerWordsArb } from './publishing/PowerwordsService';
import { filterAnnotationsForPlayer } from './quizUtils';
import { applyResponsiveLayoutChanges } from './responsiveEmbed';
import signingService from './signingService';
import { assetService, stateController } from './stateController';
import { saveJSON } from './stateUtils';
import { vttService } from './translation/vttService';
import { getExtension, resolvePlayerOrigin, splitFolderAndFile } from './utils';
import {
  getStylesheetAnnotation,
  prepareConfigFromTools, prepareForNewselaPublishing, replaceAssetIds
} from './videoConfig';

export const formatCssFilePath = (base: any, file: any) => `${formatBase(base)}/css/${file}`;
export const formatPlayerFilePath = (base: any, file: any) => `${formatBase(base)}/player/${file}`;
export const formatBase = (base: any) => base && base.slice(-1) === '/' ? base.slice(0, -1) : base; const hy = (window as any).hy;
const EXPIRES = 21600; // seconds (6hrs)

const removeSignature = (src: any) => {
  let out = src;
  if (src) {
    out = src.includes('?AWSAccessKeyId') ? src.split('?')[0] : src;
  }
  return out;
};

const sign = (src: any) => {
  return signingService.sign(src, EXPIRES);
};

export const getDestFromReplacements = (src: any, replacements: any) => {
  if (!src || !replacements) return src;

  let file = null;

  if (getExtension(src) === 'm3u8') {
    const [folder, fileName] = splitFolderAndFile(src);
    src = folder;
    file = fileName;
  }

  const replacement = replacements.find((r: any) => {
    return signingService.unsign(r.src) === signingService.unsign(src);
  });

  const inPackage = getDest(replacement);
  if (!inPackage) return src;
  return inPackage && '.' + inPackage + (file ? `/${file}` : ''); // extra '.' because player html file is located under './player/'
};

export const getDest = (replacement = {}) => (replacement as any).dest;

const formatReplacement = (src: any, dest: any) => {
  return { src, dest };
};

const getConfigSource = (src: any) => {
  return formatReplacement(src, './config.json');
};

export const getEmbedCss = () => {
  const src = formatCssFilePath('https://' + window.location.host, 'embed.css');
  const dest = formatCssFilePath('.', 'embed.css');
  return formatReplacement(src, dest);
};

const convertFormatToAsset = (videoAsset: any) => {
  const { videoType } = stateController.getCurrentData('publishing');
  const src = videoAsset.formats[videoType];
  const sourceMp4 = videoAsset.formats.mp4; // needed for transcoding

  if (videoType === 'm3u8' && src) {
    return { id: videoAsset.id, src: splitFolderAndFile(src)[0], directory: true, sourceMp4 };
  }

  return { id: videoAsset.id, src, sourceMp4 };
};

const getVideoSources = (watermark: any) => {
  return assetService
    .getVideosArray()
    .filter(filterControlled)
    .map((asset: any) => prepareAsset('.', '/assets/videos', convertFormatToAsset(asset)))
    .map(applyWatermark.bind(this, watermark));
};

const applyWatermark = (watermark: any, asset: any) => {
  return { ...asset, watermark };
};

export const imageFromPath = (data: any, path: any) => {
  const image = _.get(data, path);
  return [assetService.getImage(image)];
};

export const getAnnotations = (data: any) => _.get(data, 'ux.annotations');

export const getImageSources = (data: any) => {
  const annotations = getAnnotations(data);
  const annotationThumbnails = annotations.filter((a: Annotation) => a.thumbnail).map((a: Annotation) => assetService.getImage(a.thumbnail));
  const annotationContentImages = annotations
    .filter((a: Annotation) => a.type === 'image' && a.content)
    .map((a: Annotation) => assetService.getImage(a.content));
  const posterImage = imageFromPath(data, 'project.posterImage');
  const thumbnail = imageFromPath(data, 'publishing.thumbnail');
  const seoThumbnail = imageFromPath(data, 'publishing.seoThumbnail');
  const images = [...posterImage, ...thumbnail, ...seoThumbnail, ...annotationThumbnails, ...annotationContentImages];
  const unique = _.uniqBy(images.filter(filterControlled), 'src');
  return unique.map((asset) => prepareImageAsset('.', asset));
};

export const getAssetSources = (assets: any, type: any) => {
  return assets.filter(filterControlled).map((asset: any) => prepareAsset('.', `/assets/${type}`, asset));
};

const getManifest = () => {
  // .get(`${resolvePlayerOrigin(hy.version)}/manifest.json?v=${Date.now()}`)
  return axios.get(`${resolvePlayerOrigin(hy.version)}/manifest.json`).then((response) => response.data);
};

const getPlayerSources = async () => {
  const manifest = await getManifest();
  const excludeSourceMaps = (key: any) => !key.endsWith('.map');
  const excludeExamples = (key: any) => !key.endsWith('.json');
  const formatReplacements = (key: any) => formatReplacement(
    `${resolvePlayerOrigin(hy.version)}/${manifest[key]}`,
    formatPlayerFilePath('.', manifest[key])
  );
  return _.keys(manifest)
    .filter(excludeSourceMaps)
    .filter(excludeExamples)
    .map(formatReplacements);
};

export const prepareImageAsset = (base: any, asset: any) => {
  return prepareAsset(base, '/assets/images', asset);
};

const prepareAsset = (base: string, location: string, asset: any) => {
  const { id, src = '', directory, watermark, sourceMp4 } = asset;

  let assetLocation = `${base}${location}/${id}`;

  if (!directory) {
    const extension = getExtension(src);
    assetLocation = `${assetLocation}.${extension}`;
  }

  return { src, dest: assetLocation, directory, sourceMp4, watermark };
};

const filterControlled = (a: any) => a.isControlled;

const setTranscript = async (config: any, vtts: any) => {
  const vtt = vtts.find((asset: any) => asset.language === media.video.language);
  if (!vtt) return config;
  const cues = await vttService.parseFile(vtt.src);
  const transcript = cues.map((cue: any) => cue.text).join('\n');
  return { ...config, project: { ...config.project, transcript } };
};

export const requestAssetsList = async (data: any, bundleProps: Bundle) => {
  const images = getImageSources(data);
  const arbs = getAssetSources(assetService.getArbArray(), 'arb');
  const vtts = getAssetSources(assetService.getVttArray(), 'vtt');
  const cssAssets = getAssetSources(assetService.getCssArray(), 'css');
  const watermark = assetService.getImage(bundleProps.watermark).src;
  const audioDescriptions = getAssetSources(assetService.getAudioDescriptionArray(), 'mp3');
  const videos = getVideoSources(watermark);
  const nonVideoAssets = [...images, ...arbs, ...vtts, ...cssAssets, ...audioDescriptions];
  const catalog = [...videos, ...nonVideoAssets];
  // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
  const config = prepareConfigFromTools(true);
  const configWithTranscript = await setTranscript(config, vtts);
  const configWithSrcs = replaceAssetIds(configWithTranscript);
  const signedPlayerConfig = await prepareConfigForEmblazoning(configWithSrcs, bundleProps);
  const configWithDests = rewriteConfigForPackaging(configWithSrcs, catalog);
  logger.log('Player config:', configWithDests);
  const playerConfig = await saveJSON('overrides.json', configWithDests);
  const playerSources = await getPlayerSources();
  const publishingData = stateController.getCurrentData().publishing;
  let list = [getConfigSource(playerConfig.data), getEmbedCss(), ...nonVideoAssets, ...playerSources];

  list.forEach((asset) => {
    asset.src = removeSignature(asset.src);
  });

  if (!publishingData.includeLandingPage) {
    list = list.filter((item) => item.dest !== './player/index.html');
  }

  const powerWordArbs = await collectPowerWordsArb(stateController.getCurrentData(), list);

  // @ts-expect-error TS(7006): Parameter 'powerWordArb' implicitly has an 'any' t... Remove this comment to see the full error message
  powerWordArbs.srcList.forEach((powerWordArb) => {
    list.push(powerWordArb);
  });

  // VERY bad
  const powerWordProjectId = stateController.getCurrentData().project.id;
  const projectGroupId = stateController.getCurrentData().project.groupId;
  const stageBucket = process.env.REACT_APP_STORAGE_BUCKET;
  const containsPowerWordsAnnotations = !!stateController
    .getCurrentData()
    .ux.annotations.filter((annotation: Annotation) => annotation.type === 'powerwords').length;
  if (containsPowerWordsAnnotations) {
    list = [
      ...list,
      {
        dest: `./powerwords/powerwords-${powerWordProjectId}-en-MAX.arb`,
        src: `https://${stageBucket}.s3.amazonaws.com/project/${projectGroupId}/${powerWordProjectId}/powerwords/powerwords-${powerWordProjectId}-en-MAX.arb`,
      },
    ];
  }
  // VERY bad End

  videos.forEach((asset: any) => {
    asset.src = removeSignature(asset.src);

    if (asset.watermark) {
      asset.watermark = removeSignature(asset.watermark);
    }

    if (asset.sourceMp4) {
      asset.sourceMp4 = removeSignature(asset.sourceMp4);
    }
  });

  return {
    list,
    videos,
    images,
    cssAssets,
    signedPlayerConfig,
  };
};

const prepareConfigForEmblazoning = async (config: any, bundleProps: Bundle) => {
  // prepare secondary config with signed assets if emblazoning is requested
  const languages = bundleProps.emblazonLanguages || [];
  if (languages.length === 0) return null;
  const signedConfig = buildEmblazoningConfig(config.annotations);
  logger.log('Signed player config:', signedConfig);
  const signedPlayerConfig = await saveJSON('overrides.json', signedConfig);
  return signedPlayerConfig.data;
};

const buildEmblazoningConfig = (annotations: Annotation[]) => {
  return {
    project: {},
    annotations: modifyAnnotationsForEmblazoning(addStyleSheets(removeNonEmblazoningAnnotations(annotations))),
  };
};

const removeNonEmblazoningAnnotations = (annotations: Annotation[]) => {
  const dontEmblazon = ['stylesheet', 'languageprovider', 'closedcaptions', 'conditionalsettingsprovider'];
  return annotations.filter((a: Annotation) => {
    if (dontEmblazon.includes(a.type)) {
      return true;
    }

    return !!_.get(a, 'properties.localized');
  });
};

const addStyleSheets = (annotations: Annotation[]) => {
  return [
    ...annotations,
    {
      type: 'stylesheet',
      id: 'notoSansFont',
      target: 'player',
      content: 'https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap',
      contentType: 'url',
    },
    {
      type: 'stylesheet',
      id: 'hideVideoCss',
      start: 0,
      contentType: 'text',
      content: 'video {display: none} body, .hapyak-app, .video-js { background-color: transparent }',
      target: 'player',
      properties: {},
      styles: {},
    },
  ];
};

const modifyAnnotationsForEmblazoning = (annotations: any[]) => {
  const settingsAnnotations = [
    'languageprovider',
    'closedcaptions',
    'conditionalsettingsprovider',
    'audiodescription',
  ];

  return annotations.map((a: any) => {
    if (settingsAnnotations.includes(a.type)) {
      return signAnnotation(a);
    }

    if (_.get(a, 'properties.localized')) {
      return modifyAnnotationColors(a);
    }

    return a;
  });
};

const modifyAnnotationColors = (a: any) => {
  return {
    ...a,
    styles: {
      ...a.styles,
      color: 'rgb(200, 200, 200)',
      backgroundColor: '#000',
    },
  };
};

const signAnnotation = (a: any) => {
  return {
    ...a,
    content: sign(a.content),
    variations: a.variations.map((variation: any) => {
      return {
        ...variation,
        changes: {
          ...variation.changes,
          content: sign(variation.changes.content),
        },
      };
    }),
  };
};

const rewriteConfigForPackaging = (config: any, catalog: any) => {
  config = prepareForNewselaPublishing(config);
  config = applyResponsiveLayoutChanges(config);

  const { project, annotations } = config;

  if (project.videoSource) {
    project.videoSource = removeSignature(getDestFromReplacements(project.videoSource, catalog));
  }

  if (project.posterImage) {
    project.posterImage = removeSignature(getDestFromReplacements(project.posterImage, catalog));
  }

  if (project.thumbnail) {
    project.thumbnail = removeSignature(getDestFromReplacements(project.thumbnail, catalog));
  }

  if (project.seo && project.seo.thumbnail) {
    project.seo.thumbnail = removeSignature(getDestFromReplacements(project.seo.thumbnail, catalog));
  }

  const filteredAnnotations = annotations.filter((annotation: Annotation) => {
    return filterAnnotationsForPlayer(annotation, annotations);
  });

  return {
    ...config,
    project,
    annotations: replaceAnnotationSources(filteredAnnotations, catalog),
  };
};

const replaceAnnotationSources = (annotations: Annotation[], catalog: any) => {
  // swap out sources for relative paths where applicable
  return annotations.map((a: Annotation) => {
    const modified = {} as Annotation;

    if (a.thumbnail) {
      const thumbnail = getDestFromReplacements(a.thumbnail, catalog);
      if (thumbnail) modified.thumbnail = thumbnail;
    }

    if (a.type === 'image' && a.content) {
      const image = getDestFromReplacements(a.content, catalog);
      if (image) modified.content = image;
    }

    if (a.type === 'stylesheet' && a.content) {
      const css = getDestFromReplacements(a.content, catalog);
      if (css) modified.content = css;
    }

    if (
      [
        'languageprovider',
        'closedcaptions',
        'smartcaptions',
        'conditionalsettingsprovider',
        'audiodescription',
      ].includes(a.type)
    ) {
      modified.content = getDestFromReplacements(a.content, catalog);
      modified.variations = a.variations?.map((variation: any) => {
        return {
          ...variation,
          changes: {
            ...variation.changes,
            content: getDestFromReplacements(variation.changes.content, catalog),
          },
        };
      });
    }

    return { ...a, ...modified };
  });
};

export const isBundleOutDated = (bundle = {}) => {
  if (!bundle) return true;
  return false;
};

const getRelevantBundleData = () => {
  const { project, player, ux, publishing } = stateController.getCurrentData();
  const { _hapyakVersionId, _schema, ...relevantProjectData } = project; // remove props that can change without user input
  const { embed, ...relevantPublishingProps } = publishing; // remove props that don't affect package
  const { annotations } = ux;

  const stylesheetAnnotation = { ...getStylesheetAnnotation() };

  return {
    relevantProjectData,
    relevantPublishingProps,
    annotations,
    stylesheetAnnotation,
    player,
  };
};

export const hashBundle = () => {
  const bundleData = getRelevantBundleData();
  return hash(bundleData);
};
