import './TimeInput.scss';
import '../editors/AccessibilityEditor/CaptionsList';

import React from 'react';

import _ from 'lodash';

import { Button, Input } from '../../hapyak-ui-toolkit';
import { media } from '../../services/mediaController';
import hms from '../../services/time';
import { stopEvent } from '../../services/utils';

type State = any;

type TimeInputProps = {
  disabled?: boolean;
  endTimeOmitted?: boolean;
  evaluateChange?: (e: number) => boolean;
  exceededValue?: number;
  forceFocus?: boolean | undefined;
  identifier?: string;
  label?: any;
  max: number;
  min: number;
  onError?: () => void;
  onFocus?: () => void;
  persistTime: (id: string | undefined, type: string | undefined, value: number, exceededTimeWarning?: boolean) => void;
  time: number;
  type?: string | undefined;
  simple?: boolean;
  buttons?: boolean;
  fluid?: boolean;
};

class TimeInput extends React.Component<TimeInputProps, State> {
  form: any;
  inputRef: any;
  constructor (props: TimeInputProps) {
    super(props);

    this.form = React.createRef();
    this.inputRef = React.createRef();

    this.state = {
      displayedTime: hms.secondsToHMS(props.time),
    };
  }

  componentDidUpdate (prevProps: TimeInputProps) {
    const { forceFocus } = this.props;
    if (forceFocus === undefined && prevProps.forceFocus === undefined) {
      return;
    }

    if (forceFocus === true) {
      this.inputRef.current.focus();
      return;
    }

    if (prevProps.forceFocus === true && !forceFocus) {
      this.inputRef.current.inputRef.current.blur();
    }
  }

  updateTime = (e: any) => {
    this.setState({ displayedTime: e.target.value });
  };

  setCurrentTime = () => {
    this.setState(
      {
        displayedTime: hms.secondsToHMS(media.playTime),
      },
      this.persistTime
    );
  };

  bump = (amount: any) => {
    if (!this.isValid) return;

    const { displayedTime } = this.state;
    const newTime = hms.hmsToSeconds(displayedTime);

    this.setState(
      {
        displayedTime: hms.secondsToHMS(newTime + amount),
      },
      this.persistTime
    );
  };

  UNSAFE_componentWillReceiveProps (nextProps: any) {
    if (nextProps.time === this.props.time) return;
    this.setState({
      displayedTime: hms.secondsToHMS(nextProps.time),
    });
  }

  persistTime = () => {
    const { displayedTime } = this.state;
    const { time, min, max, identifier, type, persistTime, onError, evaluateChange } = this.props;
    const displayedTimeInSeconds = hms.hmsToSeconds(displayedTime);
    const evaluatedChange = typeof evaluateChange === 'function' && evaluateChange(displayedTimeInSeconds);

    const newTime = Math.max(min, Math.min(max, displayedTimeInSeconds));

    if (!this.isValid || newTime === time) {
      this.setState({
        displayedTime: hms.secondsToHMS(time),
      });

      if (!this.isValid && typeof onError === 'function') {
        onError();
      }

      if (type && evaluateChange) return persistTime(identifier, type, newTime, evaluatedChange);
      return;
    }

    persistTime(identifier, type, newTime, evaluatedChange);
  };

  get isValid () {
    const { displayedTime } = this.state;
    return hms.validate(displayedTime);
  }

  get onFocus () {
    const { onFocus } = this.props;
    return typeof onFocus === 'function' ? onFocus : _.noop;
  }

  render () {
    const { displayedTime } = this.state;
    const { buttons, fluid, label, simple, time, endTimeOmitted } = this.props;
    const className = `hy-time-input-wrapper ${simple ? 'hy-time-input-wrapper-simple' : ''}`;
    return (
      <div className={className} style={{ display: fluid ? 'block' : 'inline-block' }} onDoubleClick={stopEvent}>
        <Input
          className={`hy-time-input ${fluid ? 'hy-fluid' : ''}`}
          label={label}
          value={time !== null && !endTimeOmitted ? displayedTime : '------'}
          onChange={this.updateTime}
          error={!this.isValid}
          onBlur={this.persistTime}
          onFocus={this.onFocus}
          ref={this.inputRef}
          disabled={endTimeOmitted}
        />
        {buttons && (
          <Button.Group size='mini' fluid={fluid} disabled={!this.isValid}>
            <Button icon='angle double left' onClick={this.bump.bind(this, -10)} />
            <Button icon='angle left' onClick={this.bump.bind(this, -1)} />
            <Button icon='angle right' onClick={this.bump.bind(this, 1)} />
            <Button icon='angle double right' onClick={this.bump.bind(this, 10)} />
          </Button.Group>
        )}
      </div>
    );
  }
}

export { TimeInput };
