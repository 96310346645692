import React from 'react';

import { Embed } from 'types/publishing';

import { assetService } from '../../../../services/stateController';
import { BaseEmbed } from './BaseEmbed';
const hy = (window as any).hy;

export class IframeEmbed extends BaseEmbed {
  _render = (includeScript: any): JSX.Element => {
    const { baseUrl, embed = {} as Embed } = this.definition;
    const { embedVariables = {}, playButton, captions, loop, autoplay, start, seo } = embed;

    const params = {
      src: `${baseUrl}config.json`,
      thumbnail: embed.thumbnail ? assetService.getImage(embed.thumbnail).src : null,
      language: embed.language ? embed.language : null,
      variables: Object.keys(embedVariables).length ? embedVariables : null,
      playButton,
      captions,
      loop,
      autoplay,
      start,
      seo,
    };

    const scrubFalsy = (acc: any, key: any) => {
      const _acc = acc;
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (![undefined, null].includes(params[key])) _acc[key] = params[key];
      return _acc;
    };

    const refinedParams = Object.keys(params).reduce(scrubFalsy, {});
    const generatedEmbed = hy.generateIFrameEmbedString(refinedParams, baseUrl + 'player/');

    return (
      <>
        <div className='hapyak-iframe-embed'>
          <div className='hapyak-player-embed'>
            <div dangerouslySetInnerHTML={{ __html: generatedEmbed }} />
          </div>
        </div>
      </>
    );
  };
}
