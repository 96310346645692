import _ from 'lodash';

import { findAnnotationById, getAnnotationDisplay, getTool } from '../../../../services/toolService';
import { toSentenceCase } from '../../../../services/utils';
import { Annotation } from '../../../../types/annotations';
import { ToolType } from '../../../../types/tools';
import {
  findAssociatedQuizAnnotation,
  findAssociatedQuizAnnotationIds,
  QUIZ_TOOL_TYPES,
} from '../AnnotationEditor/QuizBuilder/quizBuilderUtils';
import { ActionInputs } from './ActionInputs';
import ACTIONS from './actions.json';
import EVENTS from './events.json';
import { QuestionActionInputs } from './QuestionActionInputs';
import { QuizActionInputs } from './QuizActionInputs';

const ACTION_INPUT_COMPONENT_MAP = {
  default: ActionInputs,
  quiz: QuizActionInputs,
  question: QuestionActionInputs,
  quizSection: QuizActionInputs,
};

export const getActionInputByAnnotationToolType = (type: ToolType) => {
  const map = ACTION_INPUT_COMPONENT_MAP;
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return map[type] || map.default;
};

const getAnnotationActionMapKeyName = (toolType: ToolType, hasQuizRelation?: boolean, parentToolType?: string) => {
  let name = toolType;

  if (hasQuizRelation) {
    const isQuizType = QUIZ_TOOL_TYPES.includes(toolType);
    name = isQuizType ? toolType : `${parentToolType}_${toolType}` as ToolType;
  }

  return name;
};

export const findSupportedEventsByToolType = (toolType: ToolType, context: { hasQuizRelation?: boolean, parentToolType?: ToolType } = {}) => {
  const { hasQuizRelation, parentToolType = '' } = context;
  const quizTypeEvents = ['onPass', 'onFail', 'onSubmit', ...EVENTS.filter((e: any) => e !== 'onSubmit')];
  const eventMap = {
    quiz: quizTypeEvents,
    question: ['onSpecificAnswer', ...quizTypeEvents],
    quiz_quizSection: ['onSpecificAnswer', 'afterSection'],
    question_quizSection: ['onSpecificAnswer'],
  };

  const name = getAnnotationActionMapKeyName(toolType, hasQuizRelation, parentToolType);

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return eventMap[name] || EVENTS || [];
};

export const findSupportedActionsByToolType = (toolType: ToolType, hasQuizRelation?: boolean, parentToolType?: string) => {
  const actionMap = {
    quiz_quizSection: ['goToSection', 'submitForm'],
    question_quizSection: ['submitForm'],
  };

  const name = getAnnotationActionMapKeyName(toolType, hasQuizRelation, parentToolType);

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return actionMap[name] || ACTIONS || [];
};

// @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
export const toOptionComplex = (value, text) => ({
  key: value,
  value: value,
  text: toSentenceCase(text)
});

export const getSectionOptionsForQuiz = (annotation: Annotation) => {
  const { id } = annotation;
  const quiz = findAssociatedQuizAnnotation(id);
  const sectionIds = findAssociatedQuizAnnotationIds(quiz, 'sections');
  const sections = sectionIds.map((id: string) => findAnnotationById(id));
  return createQuizActionDropdownOptions(id, sections, 'content');
};

export const createQuizActionDropdownOptions = (annotationId: string, annotations: Annotation[], path: any) => {
  const isNotSelf = (a: Annotation) => a.id && a.id !== annotationId;
  const toDropdownOption = (a: Annotation) => {
    const text = _.get(a, path) || getAnnotationDisplay(a);
    return toOptionComplex(a.id, text);
  };
  return annotations.filter(isNotSelf).map(toDropdownOption);
};

export const supportsConditionals = (annotation = {} as Annotation) => {
  if (!annotation.toolType) return;
  const { editor = {} } = getTool(annotation.toolType);
  return editor.conditionalActions;
};
