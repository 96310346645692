import React, { Component } from 'react';

import { Checkbox, TextArea } from '@newsela/angelou';

import { comm } from '../../../../../services/comm';
import { toSentenceCase } from '../../../../../services/utils';
import { Annotation } from '../../../../../types/annotations';
import { AngelouInput } from '../../../AngelouComponents/AngelouInput';
import { MAX_CHARACTER_INPUT_LENGTH } from './questionService';
import { findAssociatedQuizAnnotation, getQuizSubAnnotationPath } from './quizBuilderUtils';

type State = any;

export type QuizBaseEditorProps = {
  annotation: Annotation;
  annotations?: Annotation[];
  canDelete?: boolean;
  canDeleteSection?: boolean;
  hideDelete?: boolean;
  label?: string;
  newItemId?: any;
  onDelete?: any;
  progressIsDisabled?: any;
  quizAnswerKeyEditor?: any;
  quizAnswerEditor?: any;
  quizAnswerList?: any;
  resetNewItemId?: any;
  setFromObject: any;
  setMenuData?: (val: any) => void;
  setProperty: (obj: any, annotation: Annotation) => void;
};

export class QuizBaseEditor extends Component<QuizBaseEditorProps, State> {
  ref: any;
  constructor (props: QuizBaseEditorProps) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      isActive: false,
    };
  }

  componentDidUpdate (prevProps: QuizBaseEditorProps) {
    const { annotation, newItemId, resetNewItemId } = this.props;
    const isNewItem = newItemId === annotation.id;
    const newItemIdChanged = prevProps.newItemId !== this.props.newItemId;

    if (newItemIdChanged && isNewItem) {
      this.setActive(true);
      if (typeof resetNewItemId === 'function') resetNewItemId();
    }
  }

  componentDidMount () {
    document.addEventListener('mouseup', this.handleClick, false);
  }

  componentWillUnmount () {
    document.removeEventListener('mouseup', this.handleClick, false);
  }

  setActive = (bool: boolean) => {
    const { annotation = {} as Annotation } = this.props;
    const quiz = annotation.type === 'quiz' ? annotation : findAssociatedQuizAnnotation(annotation.id);
    const path = getQuizSubAnnotationPath({ quiz, annotation });

    if (bool && (path as any).id) {
      comm.trigger('toSubAnnotation', path);
    }

    this.setState({ isActive: bool });
  };

  get elementRef () {
    const { current } = this.ref;
    return current || {};
  }

  get isActive () {
    const { isActive } = this.state;
    return isActive ? ' active ' : '';
  }

  isDescendant = (el: any, parentClass: any) => {
    let isChild = false;

    if (el.class === parentClass) {
      isChild = true;
    }

    while ((el = el.parentNode)) {
      const isChild = el.className && el.className.includes && el.className.includes(parentClass);
      if (isChild) return true;
    }

    return isChild;
  };

  handleClick = (e: any) => {
    const { current } = this.ref;
    if (!current) return;

    const modalClicked = this.isDescendant(e.target, 'modal'); // ignore modal clicks

    if (modalClicked) return;

    const refIsActive = current.contains(e.target);
    this.setActive(refIsActive);
  };

  wrapComponent = (component: any) => {
    return <div ref={this.ref}>{component}</div>;
  };

  removeNonDigits = (value: string) => {
    if (!value || !value.replace) return value;
    return value.replace(/[^\d]/g, '');
  };

  handlePropertyInputChange = (type: any, inputType: any, {
    target
  }: any) => {
    let value = target.value;

    if (inputType === 'number') {
      value = this.removeNonDigits(value);
    }

    this.setState(
      {
        properties: {
          ...this.state.properties,
          [type]: value,
        },
      },
      () => this.forceActive
    );
  };

  handleContentInputChange = (type: any, {
    target
  }: any) => {
    this.setState({ content: target.value }, this.forceActive);
  };

  handleCheckbox = ({
    type,
    checked
  }: any) => {
    checked = !checked;
    this.handlePropertyToggle(type, null, { checked });
  };

  handlePropertyToggle = (type: any, e: any, {
    checked
  }: any) => {
    this.setState(
      {
        properties: {
          ...this.state.properties,
          [type]: checked,
        },
      },
      () => {
        this.persistPropertyFromState(type);
      }
    );
  };

  forceActive = () => {
    /*  Todo: Fix before merging to prod 03.24.21
       *  There doesn't seem to be a reliable way to know when Studio `updateProject` AND THEN Player `reload` fires
       * */
    this.setActive(true);
  };

  persistPropertyFromState = (prop: any, type?: any) => {
    const { setProperty, annotation } = this.props;
    const { properties } = this.state;

    let value = properties[prop];

    if (type === 'number') {
      value = this.removeNonDigits(value);
      value = +value;
    }

    if (typeof setProperty === 'function') {
      setProperty({ [prop]: value }, annotation);
      this.forceActive();
    }
  };

  persistContentFromState = () => {
    const { annotation = {} as Annotation, setFromObject } = this.props;

    if (typeof setFromObject === 'function') {
      setFromObject({ content: this.state.content }, annotation.id);
      this.forceActive();
    }
  };

  createInput = ({
    value,
    propertyName,
    disabled,
    type = 'text',
    overrides = {},
    label = ''
  }: any) => {
    return (
      <AngelouInput
        label={label || toSentenceCase(propertyName)}
        value={value}
        disabled={disabled}
        onBlur={this.persistPropertyFromState.bind(this, propertyName, type)}
        onFocus={this.forceActive}
        onChange={this.handlePropertyInputChange.bind(this, propertyName, type)}
        maxLength={MAX_CHARACTER_INPUT_LENGTH}
        {...overrides}
      />
    );
  };

  createTextArea = ({
    value,
    propertyName,
    disabled,
    type = 'text',
    label = '',
    placeholder = '',
    overrides = {}
  }: any) => {
    return (
      <TextArea
        __cssFor={{
          text: {
            minHeight: '72px',
            height: '72px'
          }
        }}
        label={label || toSentenceCase(propertyName)}
        disabled={disabled}
        maxLength={MAX_CHARACTER_INPUT_LENGTH}
        onFocus={this.forceActive}
        onChange={this.handlePropertyInputChange.bind(this, propertyName, type)}
        onBlur={this.persistPropertyFromState.bind(this, propertyName, type)}
        placeholder={placeholder}
        resize='none'
        type={type}
        value={value}
        {...overrides}
      />
    );
  };

  createCheckbox = (checked: boolean, value: string, disabled?: boolean, onChange?: any) => {
    return (
      <Checkbox
        checked={checked}
        label={toSentenceCase(value)}
        disabled={disabled}
        onChange={onChange || this.handleCheckbox.bind(this, { type: value, checked })}
        __cssFor={{
          root: {
            padding: '6px 0'
          }
        }}
      />
    );
  };
}
