import { AdjacentLeftOnly } from '../../layouts/AdjacentLeftOnly';
import { horizontalSampleVideo } from '../defaults/sampleVideos';
import { defaultAnnotations } from './defaultAnnotations';
import { preview } from './preview';

export const SideMenu = {
  defaultAnnotations,
  layout: AdjacentLeftOnly,
  preview,
  video: horizontalSampleVideo,
};
