import _ from 'lodash';

import { Action } from 'types/actions';

import {
  findAssociatedQuizAnnotation,
  findAssociatedQuizAnnotationIds,
} from '../../components/editors/UXEditor/AnnotationEditor/QuizBuilder/quizBuilderUtils';
import { Annotation, AnnotationProperties } from '../../types/annotations';
import { findAnnotationById } from '../toolService';
import { createGoToAnnotationAction, createSubmitQuizAction } from './actionUtils';
import {
  createAfterSectionCondition,
  createFormDataAssertionCondition,
  createQuizQuestionCorrectnessAssertionCondition,
  createWhenOnCurrentQuestionCondition,
  getFormEvaluationTypeByAnnotation,
} from './conditionUtils';
import playerQuizConstants from './playerQuizConstants';

const { PLAYER_QUIZ_EVENTS, PLAYER_QUIZ_ACTIONS } = playerQuizConstants;

const createConditions = ({
  event,
  quizId,
  sectionId,
  value,
  quizQuestionId
}: any) => {
  const whenOnCurrentQuestion = createWhenOnCurrentQuestionCondition({ quizId, quizQuestionId });

  if (event === PLAYER_QUIZ_EVENTS.ON_SPECIFIC_ANSWER && ['correct', 'incorrect'].includes(value)) {
    const assertQuestionCorrectness = createQuizQuestionCorrectnessAssertionCondition({
      quizId,
      assertedCorrectness: value,
      quizQuestionId,
    });

    return [whenOnCurrentQuestion, assertQuestionCorrectness];
  }

  if (event === PLAYER_QUIZ_EVENTS.ON_SPECIFIC_ANSWER) {
    const evaluationType = getFormEvaluationTypeByAnnotation(findAnnotationById(quizQuestionId));

    const assertFormDataCorrectness = createFormDataAssertionCondition({
      quizId,
      evaluationType,
      isValue: value,
      formId: quizQuestionId,
    });

    return [whenOnCurrentQuestion, assertFormDataCorrectness];
  }

  if (event === PLAYER_QUIZ_EVENTS.AFTER_SECTION) {
    const afterSection = createAfterSectionCondition({ quizId, sectionId });
    return [afterSection];
  }

  return [{}];
};

const createAction = ({
  action,
  quizId,
  goToSectionId
}: any) => {
  let createdAction = {};

  if (action === PLAYER_QUIZ_ACTIONS.GO_TO_SECTION) {
    const path = `${quizId}#${goToSectionId}`;
    createdAction = createGoToAnnotationAction(path);
  }

  if (action === PLAYER_QUIZ_ACTIONS.SUBMIT_FORM) {
    createdAction = createSubmitQuizAction(quizId);
  }

  return createdAction;
};

export const sectionActionsToVariations = (associatedQuizId?: any, sectionActions?: any) => {
  const createdVariations = sectionActions.map((item: any) => {
    const { sectionAction, sectionId } = item;
    const { args, condition } = sectionAction;
    const { quizQuestion = '', quizAnswer = '' } = condition;
    const answerAnnotation = findAnnotationById(quizAnswer);
    const answer = answerAnnotation.id ? answerAnnotation : { properties: { value: quizAnswer } };
    const { properties = {} as AnnotationProperties } = answer;
    const { value = '' } = properties;

    const createdAction = createAction({
      action: sectionAction.action,
      quizId: associatedQuizId,
      goToSectionId: args.goToSection,
    });

    const createdCondition = createConditions({
      event: sectionAction.event,
      quizId: associatedQuizId,
      sectionId,
      quizQuestionId: quizQuestion,
      value,
    });

    return [
      {
        conditions: createdCondition.flat(),
        changes: {
          actions: [createdAction],
        },
      },
    ];
  });

  return createdVariations.flat();
};

export const sectionActionsToNextButtonVariations = (a: Annotation, annotations: Annotation[]) => {
  const associatedQuiz = findAssociatedQuizAnnotation(a.id);
  const sectionIds = findAssociatedQuizAnnotationIds(associatedQuiz, 'sections');
  const sections = annotations.filter((a: Annotation) => sectionIds.includes(a.id));
  const sectionActions = sections
    .map((s: any) => {
      return s.actions.map((action: Action) => {
        return { sectionAction: action, sectionId: s.id };
      });
    })
    .flat();
  return sectionActionsToVariations(associatedQuiz ? associatedQuiz.id : null, sectionActions);
};

export const quizOnSpecificAnswerActionToVariations = (associatedQuizId: string, actions: Action[]) => {
  const createdVariations = actions
    .filter((a: Action) => a.event === 'onSpecificAnswer')
  // Filter out null values on a.condition.quizAnswer
  // in the rare case that a user did not select an quizAnswer value
  // when creating an onSpecificAnswer action.
    .filter((a: Action) => a.condition?.quizAnswer !== null)
    .map((item: Action) => {
      const { condition } = item;
      if (typeof condition?.quizAnswer === 'string' && typeof condition?.quizQuestion === 'string') {
        const { quizAnswer = '' } = condition;
        const answerAnnotation = findAnnotationById(quizAnswer);
        const answer = answerAnnotation.id ? answerAnnotation : { properties: { value: quizAnswer } };
        const { properties = {} as AnnotationProperties } = answer;
        const { value = '' } = properties;

        const question = findAnnotationById(condition.quizQuestion);
        const sectionId = _.get(question, 'appliesTo[0]', '');

        const createdCondition = createConditions({
          event: item.event,
          quizId: associatedQuizId,
          sectionId,
          quizQuestionId: question.id,
          value: value,
        }).flat();

        return [
          {
            conditions:
                          createdCondition && createdCondition.length > 1 ? [createdCondition[1]] : createdCondition,
            changes: {
              actions: [{
                action: item.action,
                args: item.args,
                event: 'onComplete'
              }],
            },
          },
        ];
      }
    });

  return createdVariations.flat();
};
