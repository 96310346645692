import React from 'react';

import { Button } from '../../../hapyak-ui-toolkit';
import logger from '../../../services/logger';

type NonReliantPublishButtonProps = {
  disabled: boolean;
  content: string;
  loading?: boolean;
  onClick: () => void;
};

export const NonReliantPublishButton = (props: NonReliantPublishButtonProps) => {
  const handleClick = () => {
    logger.log(props);

    const { onClick } = props;
    if (typeof onClick === 'function') onClick();
  };

  const { loading, content = 'OK', disabled = false } = props;

  return <Button primary loading={loading} disabled={disabled} onClick={handleClick} content={content} />;
};
