import React from 'react';

import { Button } from '@newsela/angelou';
import _ from 'lodash';

import './index.scss';

import { InfoBanner } from '../../../../components/editors/AngelouComponents/InfoBanner';
import DEFAULTS from '../../../../DEFAULTS.json';
import { CustomDropdown, Form, Input, Popup, TextArea, TinyMCEEditor } from '../../../../hapyak-ui-toolkit';
import { comm } from '../../../../services/comm';
import { gaConfig, ReactGA } from '../../../../services/gaConfig';
import { media } from '../../../../services/mediaController';
import { assetService, stateController } from '../../../../services/stateController';
import { requiresRemovalOfCollections } from '../../../../services/toolRelationshipService';
import { getToolFromAnnotation } from '../../../../services/toolService';
import { toSentenceCase } from '../../../../services/utils';
import { getConfig } from '../../../../tinymce_config';
import { Annotation, AnnotationProperties } from '../../../../types/annotations';
import { TimeInput } from '../../../TimeInput/TimeInput';
import { TrashButton } from '../../AngelouComponents/TrashButton';
import { EditableText } from '../../CommonComponents/EditableText';
import { ImagePicker } from '../../CommonComponents/ImagePicker';
import { BasicSection } from '../../CommonComponents/Menu/BasicSection';
import { ActionsList } from '../ActionsEditor/ActionsList';
import { presetActionsByType } from '../ActionsEditor/presetActions';
import SystemGeneratedActions from '../ActionsEditor/SystemGeneratedActions';
import { AppliesToInputs } from '../AppliesTo/AppliesToInputs';
import { getEditorComponentByName } from './editorComponentMap';
import { FormBuilder } from './FormBuilder';
import { findAssociatedQuizAnnotation } from './QuizBuilder/quizBuilderUtils';
import { StickyEndTime } from './Time/StickyEndTime';
const { MIN_ANNOTATION_DURATION } = DEFAULTS;

type State = any;

type AnnotationEditorProps = {
  annotation: Annotation;
  annotations: Annotation[];
  delete: (id: string, bool: boolean, requiresCascadeDelete: boolean) => void;
  dirty?: boolean;
  duration: number;
  edit: (id: string, annotation?: Annotation) => void;
  layoutTargets: any[];
  match: any;
  presets: any[];
  updateAnnotation: (id: string, updatedAnnotation: Annotation) => void;
};

class AnnotationEditor extends React.Component<AnnotationEditorProps, State> {
  contentInput: any;
  tool: any;
  constructor (props: AnnotationEditorProps) {
    super(props);

    const { annotation } = props;
    this.contentInput = React.createRef();
    this.tool = getToolFromAnnotation(annotation);

    this.state = {
      textEditorLoaded: false,
      imageLoaded: false,
      content: annotation.content,
      properties: annotation.properties || {},
      validationData: {},
      stagedAnnotation: null,
    };
  }

  deleteAnnotation = () => {
    const { id, toolType } = this.props.annotation;
    const requiresCascadeDelete = requiresRemovalOfCollections(toolType);

    this.props.delete(id, true, requiresCascadeDelete);

    ReactGA.event(
      Object.assign(gaConfig.Analytics.Portal.UX.Annotation.Added, {
        label: toolType ? `Deleted ${toolType}` : 'Deleted',
      })
    );
  };

  setFromObject = (obj: any, id = this.props.annotation.id) => {
    return this.props.updateAnnotation(id, obj);
  };

  setProperty = (changes: any, annotation = this.props.annotation) => {
    return this.setFromObject({ properties: { ...annotation.properties, ...changes } }, annotation.id);
  };

  isValidTiming = (annotation: Annotation) => {
    if (!annotation) return false;
    return typeof annotation.start !== 'undefined' && typeof annotation.end !== 'undefined' ? annotation.start < annotation.end : false;
  };

  setFromTime = (id: any, type: any, value: any, exceededTimeWarning: any) => {
    const validationData = { [type]: exceededTimeWarning && value !== media.duration };

    this.setState({ validationData }, async () => {
      const stagedAnnotation = this.state.stagedAnnotation || this.props.annotation;
      stagedAnnotation[type] = value;

      if (this.isValidTiming(stagedAnnotation)) {
        const { start, end } = stagedAnnotation;
        await this.setFromObject({ start, end });
        this.setState({ stagedAnnotation: null, validationData: {} });
      } else {
        this.setState({ stagedAnnotation });
      }
    });
  };

  // TODO: utility types for keyed boolean type?
  resetProperties = (properties: string[]): void => {
    const changes: { [key: string]: boolean } =
      properties
        .reduce((acc: { [key: string]: boolean}, prop: string) =>
          ({ ...acc, ...{ [prop]: false } }), {});
    this.setProperty(changes);
  };

  setInternalName = (value: any) => {
    this.setFromObject({ internal: { ...this.props.annotation.internal, ...{ name: value } } });
  };

  // hidden as part of VIDEO-637
  // handlePropertyToggle = ({
  //   type,
  //   checked
  // }: any) => {
  //   checked = !checked;
  //   this.setProperty({ [type]: checked });
  //
  //   const showExpanded = type === 'showExpanded';
  //   showExpanded && checked === false && this.resetProperties(['startExpanded', 'animate', 'showExpanded']);
  //   showExpanded && checked === true && this.setProperty({ animate: true, showExpanded: true });
  // };

  handlePropertyInputChange = (type: any, {
    target
  }: any) => {
    this.setState({
      properties: {
        ...this.state.properties,
        [type]: target.value,
      },
    });
  };

  handleEditorChange = (content: any) => {
    this.setState({ content });
  };

  handleTextChange = ({
    target
  }: any) => {
    this.setState({ content: target.value });
  };

  persistContent = () => {
    this.setFromObject({ content: this.state.content });
  };

  persistPropertyFromState = (type: any) => {
    this.setProperty({ [type]: this.state.properties[type] });
  };

  handleEditorInit = (e: any, editor: any) => {
    this.setState({ textEditorLoaded: true });
  };

  saveForm = (formData: any, content: any) => {
    this.setFromObject({ content, form: { formData } });
  };

  get simpleTextEditor () {
    const { content } = this.state;
    this.focusAfterRender();

    return (
      <Form>
        <TextArea
          ref={this.contentInput}
          value={content}
          onChange={this.handleTextChange}
          onBlur={this.persistContent}
        />
      </Form>
    );
  }

  focusAfterRender = () => {
    if (!this.state.textEditorLoaded) {
      requestAnimationFrame(() => {
        this.setState({ textEditorLoaded: true }, () => {
          const current = this.contentInput.current;
          current && current.ref.current.select();
        });
      });
    }
  };

  get richTextEditor () {
    const { content } = this.state;
    const editorConfigType = this.tool.editor.editorConfig;
    return (
      <TinyMCEEditor
        value={content}
        onEditorChange={this.handleEditorChange}
        onBlur={this.persistContent}
        editorConfigType={editorConfigType}
        onInit={this.handleEditorInit}
      />
    );
  }

  get useRichTextEditor () {
    return this.tool.defaults.contentType === 'htmlstring';
  }

  get textEditor () {
    return this.useRichTextEditor ? this.richTextEditor : this.simpleTextEditor;
  }

  get textEditorPlaceHolderHeight () {
    if (this.useRichTextEditor && !this.state.textEditorLoaded) {
      const editorConfigType = this.tool.editor.editorConfig;
      return getConfig(editorConfigType).height;
    }

    return null;
  }

  setImage = (property: any, image: any) => {
    this.setFromObject({ [property]: image.id });
  };

  stopEditing = () => {
    comm.trigger('selectAnnotations', []);
  };

  get actionTypes () {
    return this.fields && this.fields.actionTypes;
  }

  get systemGeneratedActions () {
    const types = this.actionTypes;
    const { annotation } = this.props;
    const { id } = annotation;

    if (!types || !id) return null;

    const { toolType, contentType, type } = this.tool.defaults;

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    let actions = types.map((type: any) => presetActionsByType[type] || []);
    actions = _.flatten(actions);

    return !actions.length
      ? null
      : {
          id,
          actions,
          type,
          toolType,
          contentType
        };
  }

  get fields () {
    return this.tool && this.tool.editor;
  }

  // hidden as part of VIDEO-637
  // get isAdjacent () {
  //   const { annotation } = this.props;
  //   return annotation ? annotation.target !== 'player' : false;
  // }

  updateInitialState = (e: any, data: any) => {
    this.setProperty({ expandedState: data.value });
  };

  get initialStateDropdown () {
    const { annotation = {} as Annotation } = this.props;
    const options = this.createInitialStateOptions();
    const { properties = {} as AnnotationProperties, target } = annotation;
    const { expandedState = '' } = properties;

    if (annotation.toolType !== 'chapterMenu') {
      return null;
    }

    return (
      <div>
        <CustomDropdown
          openOnFocus
          value={target !== 'player' ? 'expanded' : expandedState}
          label='Initial State'
          selection
          onChange={this.updateInitialState}
          className='button'
          options={options}
          disabled={target !== 'player'}
        />
      </div>
    );
  }

  createInitialStateOptions () {
    const options = ['expanded', 'collapsed'];

    return options.map((option) => {
      return {
        key: option,
        text: _.startCase(option),
        value: option,
      };
    });
  }

  // hidden as part of VIDEO-637
  // createTargetOptions = () => {
  //   const toolTargets = this.tool.targets;
  //   const layoutTargets = this.props.layoutTargets.map((t: any) => t.name);
  //   const allowedTargets = _.intersection(toolTargets, layoutTargets);
  //
  //   return allowedTargets.map((t) => {
  //     return {
  //       key: t,
  //       // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
  //       text: _.startCase(t),
  //       value: t,
  //     };
  //   });
  // };

  get editorComponent () {
    const { annotation, edit } = this.props;
    const editorComponentName = this.fields.editorComponent;
    const EditorComponent = getEditorComponentByName(editorComponentName);

    if (!annotation || !EditorComponent) return null;

    const annotations = stateController.getCurrentData('ux').annotations;

    return (<EditorComponent annotation={annotation} annotations={annotations} deleteAnnotation={this.props.delete} setFromObject={this.setFromObject} setProperty={this.setProperty} edit={edit} />);
  }

  // hidden as part of VIDEO-637
  // get expandableCheckBox () {
  //   const { annotation } = this.props;
  //   if (!annotation) return null;
  //   const { properties = {} as AnnotationProperties } = annotation;
  //   const fields = this.fields;
  //   if (!fields) return null;
  //
  //   return (
  //     <>
  //       <div>
  //         <Checkbox
  //           className='hy-margin-bottom'
  //           checked={properties.showExpanded}
  //           label='Show expand / Collapse control'
  //           onChange={this.handlePropertyToggle.bind(this, { type: 'showExpanded', checked: properties.showExpanded })}
  //         />
  //       </div>
  //       <div>
  //         {properties.showExpanded && fields.startExpanded && (
  //           <>
  //             <Checkbox
  //               checked={properties.startExpanded && properties.showExpanded}
  //               label='Start Expanded'
  //               onChange={this.handlePropertyToggle.bind(this, { type: 'startExpanded', checked: properties.startExpanded })}
  //             />
  //             <Popup
  //               content='Setting is disabled in the editor, use preview to view.'
  //               trigger={
  //                 <Icon
  //                   // @ts-expect-error TS(2339)
  //                   name='info circle small'
  //                   className='hy-margin-left'
  //                 />
  //               }
  //             />
  //           </>
  //         )}
  //       </div>
  //     </>
  //   );
  // }
  //
  // get adjacentContentOptions () {
  //   const { annotation } = this.props;
  //   if (!annotation) return null;
  //   const { properties = {} as AnnotationProperties } = annotation;
  //   const fields = this.fields;
  //   if (!fields) return null;
  //
  //   return (
  //     <>
  //       <div>
  //         <Checkbox
  //           className='hy-margin-bottom'
  //           checked={properties.showExpanded}
  //           label='Show expand / Collapse control'
  //           onChange={this.handlePropertyToggle.bind(this, { type: 'showExpanded', checked: properties.showExpanded })}
  //         />
  //       </div>
  //       <div>
  //         {properties.showExpanded && fields.startExpanded && (
  //           <>
  //             <Checkbox
  //               checked={properties.startExpanded && properties.showExpanded}
  //               label='Start Expanded'
  //               onChange={this.handlePropertyToggle.bind(this, { type: 'startExpanded', checked: properties.startExpanded })}
  //             />
  //             <Popup
  //               content='Setting is disabled in the editor, use preview to view.'
  //               trigger={
  //                 // @ts-expect-error TS(2339)
  //                 <Icon name='info circle small' className='hy-margin-left' />
  //               }
  //             />
  //           </>
  //         )}
  //       </div>
  //     </>
  //   );
  // }

  get noContent () {
    return (
      <div className='hy-margin'>
        <InfoBanner body="There's nothing to edit here." />
      </div>
    );
  }

  createTimeInput = ({
    time,
    min,
    max,
    id,
    label,
    forceFocus,
    exceededValue,
    evaluateChange,
    endTimeOmitted
  }: any) => {
    const className = forceFocus ? 'hy-highlight-error' : 'hy-margin-right';
    const timeInput = (
      <div style={{ display: 'inline' }} className={className}>
        <TimeInput
          time={endTimeOmitted ? null : time}
          type={label}
          identifier={id}
          min={min}
          max={max}
          label={{ basic: true, content: toSentenceCase(label) }}
          persistTime={this.setFromTime}
          forceFocus={forceFocus}
          exceededValue={exceededValue}
          evaluateChange={evaluateChange}
          disabled={endTimeOmitted || false}
        />
      </div>
    );

    const wrapWithPopup = (trigger: any) => {
      return (
        <Popup
          content='End time must be after start time. Field will auto-update if not corrected'
          trigger={trigger}
          open
        />
      );
    };

    return forceFocus ? wrapWithPopup(timeInput) : timeInput;
  };

  get appliesToQuizAnswerId () {
    const { annotation = {} as Annotation, annotations = [] } = this.props;
    const { appliesTo = [] } = annotation;

    if (!appliesTo.length) return null;

    return appliesTo.find((id: string) => {
      const a = annotations.find((a: Annotation) => a.id === id) || {} as Annotation;
      return a.toolType === 'quizAnswer';
    });
  }

  get hasEditableActions () {
    return !this.appliesToQuizAnswerId;
  }

  editAppliesTo = () => {
    const { edit } = this.props;
    const annotation = this.appliesToQuizAnswerId;
    const quiz = findAssociatedQuizAnnotation(annotation);
    if (quiz && typeof edit === 'function') {
      edit(quiz.id);
    }
  };

  get actionSection () {
    const { annotation, annotations, duration } = this.props;
    const systemGenerated = this.systemGeneratedActions;
    return (
      <>
        {systemGenerated && (
          <SystemGeneratedActions
            disableAddAndEdit
            actionText='Include'
            currentAnnotation={annotation}
            annotation={systemGenerated as Annotation}
            annotations={annotations}
            duration={duration}
            setFromObject={this.setFromObject}
          />
        )}

        <ActionsList
          annotation={annotation}
          annotations={annotations}
          duration={duration}
          setFromObject={this.setFromObject}
        />
      </>
    );
  }

  get actionEditingDisabledMessage () {
    // const button = (
    //   <Button
    //     flavor='PRIMARY'
    //     onClick={this.editAppliesTo.bind(this)}
    //   >
    //     Go to quiz to edit
    //   </Button>
    // );
    // TODO: Greg to review app functionality
    return (
      <InfoBanner
        body='Annotation is associated with a quiz'
      />
    );
  }

  get annotationActionsSection () {
    return !this.hasEditableActions ? this.actionEditingDisabledMessage : this.actionSection;
  }

  get startTimeInput () {
    const { validationData } = this.state;
    const { annotation } = this.props;
    const { start, end, id } = annotation;

    return this.createTimeInput({
      time: start,
      min: 0,
      max: validationData.end ? end - MIN_ANNOTATION_DURATION : media.duration - MIN_ANNOTATION_DURATION,
      id,
      label: 'start',
      forceFocus: validationData.end,
      evaluateChange: (enteredTime: number) => {
        return end - MIN_ANNOTATION_DURATION < enteredTime;
      },
    });
  }

  get endTimeInput () {
    const { validationData } = this.state;
    const { annotation } = this.props;
    const { start, end, id, endTimeOmitted } = annotation;

    return this.createTimeInput({
      time: end,
      min: validationData.start ? start + MIN_ANNOTATION_DURATION : 0,
      max: media.duration,
      id,
      label: 'end',
      forceFocus: validationData.start,
      evaluateChange: (enteredTime: number) => {
        return enteredTime <= start + MIN_ANNOTATION_DURATION;
      },
      endTimeOmitted: endTimeOmitted,
    });
  }

  get annotationEditableProperties () {
    const { annotation, annotations, edit, updateAnnotation } = this.props;
    const { content, thumbnail } = annotation;

    const inputProperties = this.state.properties;
    const fields = this.fields;
    const form = annotation.form || fields.form;

    return (
      <div className='editor-content'>
        <div>
          <BasicSection
            renderedContent={
              <div className='hy-form-field'>
                <div className='hy-margin-right hy-margin-bottom'>
                  {this.startTimeInput}
                </div>
                <div className='hy-margin-right hy-margin-bottom'>
                  {this.endTimeInput}
                  <StickyEndTime annotation={annotation} />
                </div>
                <div className='hy-margin-right'>
                  {this.initialStateDropdown}
                </div>
              </div>
            }
          />
        </div>
        <div className='hy-section-wrapper'>
          {!form && fields.content && (
            <BasicSection
              title={fields.contentTitle || 'Content'}
              placeholder={this.textEditorPlaceHolderHeight}
              renderedContent={this.textEditor}
            />)}

          {fields.editorComponent && this.editorComponent}

          {form && (
            <FormBuilder
              form={{ ...fields.form, ...annotation.form }}
              saveForm={this.saveForm}
            />
          )}

          {fields.image && (
            <BasicSection
              title='Image'
              renderedContent={<ImagePicker label={false} image={assetService.getImage(content)} setImage={this.setImage.bind(this, 'content')} />}
            />)}

          {fields.thumbnail && (
            <BasicSection
              title='Thumbnail'
              renderedContent={<ImagePicker label={false} image={assetService.getImage(thumbnail)} setImage={this.setImage.bind(this, 'thumbnail')} />}
            />)}

          {fields.headerDownloadText && fields.headerDownloadUrl && (
            <BasicSection
              title='Header Download Link' renderedContent={
                <>
                  <div className='hy-margin-bottom hy-form-field'>
                    <Input label={{ basic: true, content: 'Header Download Text' }} type='url' className='hy-fluid' value={inputProperties.headerDownloadText} onChange={this.handlePropertyInputChange.bind(this, 'headerDownloadText')} onBlur={this.persistPropertyFromState.bind(this, 'headerDownloadText')} />
                  </div>
                  <div className='hy-margin-bottom hy-form-field'>
                    <Input label={{ basic: true, content: 'Header Download URL' }} type='url' className='hy-fluid' value={inputProperties.headerDownloadUrl} onChange={this.handlePropertyInputChange.bind(this, 'headerDownloadUrl')} onBlur={this.persistPropertyFromState.bind(this, 'headerDownloadUrl')} />
                  </div>
                </>
              }
            />
          )}

          {fields.actions && (
            <BasicSection
              title='User Interaction'
              renderedContent={[{ renderedContent: this.annotationActionsSection }]}
            />)};

          {fields.appliesTo && (
            <BasicSection title='Include In Menus' renderedContent={<AppliesToInputs annotation={annotation} annotations={annotations} update={updateAnnotation} edit={edit} includedIn stageAnnotationUpdate={updateAnnotation} />} />)}

          {fields.collectionTypes && !fields.editorComponent && (
            <BasicSection renderedContent={<AppliesToInputs section={{ title: '' }} annotation={annotation} annotations={annotations} update={updateAnnotation} edit={edit} stageAnnotationUpdate={updateAnnotation} />} />)}
        </div>
      </div>
    );
  }

  get isIncomplete () {
    const { annotation } = this.props;
    return !annotation || !this.fields;
  }

  get annotationName () {
    const { annotation } = this.props;

    if (this.isIncomplete) return null;

    return (
      <EditableText
        value={_.get(annotation, 'internal.name', '')}
        onBlur={this.setInternalName}
        style={{ marginLeft: '3px' }}
        placeholder='Edit Name'
      />
    );
  }

  get deleteButton () {
    return <TrashButton handleClick={this.deleteAnnotation} />;
  }

  get stopEditingButton () {
    return (
      <Button
        legacy_flavor={Button.legacy_flavor.solid}
        legacy_statusColor={Button.legacy_statusColor.primary}
        onClick={this.stopEditing}
        __cssFor={{ root: { marginLeft: '1rem' } }}
      >
        Done
      </Button>
    );
  }

  render () {
    const { annotation } = this.props;

    if (this.isIncomplete) return this.noContent;

    return (
      <div className='hy-annotation-editor hy-absolute'>
        <div className='editor-header'>
          {this.annotationName}
          <div className='editor-header-buttons'>
            {this.deleteButton}
            {this.stopEditingButton}
          </div>
        </div>
        <div>
          {this.fields && annotation && this.annotationEditableProperties}
        </div>
      </div>
    );
  }
}

export { AnnotationEditor };
