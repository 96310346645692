import { AllTargets } from '../../layouts/AllTargets';
import { verticalSampleVideo } from '../defaults/sampleVideos';
import { preview } from './preview';

export const VerticalVideo = {
  defaultAnnotations: [],
  layout: AllTargets,
  preview,
  video: verticalSampleVideo,
};
