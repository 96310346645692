import DEFAULTS from '../DEFAULTS.json';

export const toPath = (path: string) => {
  const { origin } = window.location;

  const publicUrl = process.env.PUBLIC_URL || '';

  window.location.assign(origin + publicUrl + '/' + path);
};

export const toPathOnNewTab = (url: string) => {
  window.open(url, '_blank')?.focus();
};

export const isHomeRoute = (location: Location) => {
  return location.pathname
    ? location.pathname.includes(DEFAULTS.HOME_PATH) || location.pathname.includes(DEFAULTS.GOOGLE_DRIVE_NEW)
    : false;
};

export const isPDFPreviewRoute = (location: Location) => location?.pathname?.includes(DEFAULTS.STORYBOARD_PREVIEW_PATH);

export const isPublishingRoute = (location: Location) => location?.pathname?.includes(DEFAULTS.PUBLISHING_PATH);

export const isGoogleDriveOpen = (location: Location) => location?.pathname?.includes(DEFAULTS.GOOGLE_DRIVE_OPEN);

export const isGoogleDriveNew = (location: Location) => location?.pathname?.includes(DEFAULTS.GOOGLE_DRIVE_NEW);
