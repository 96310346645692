import React from 'react';

import _ from 'lodash';

import { Table } from '../../../../hapyak-ui-toolkit';
import { assetService } from '../../../../services/stateController';
import { Link } from '../../../Link';
import { VideoAssetRow } from './VideoAssetRow';

export class VideoAssetList extends React.Component {
  link = (to: any, text: any) => {
    const style = {
      display: 'block',
      marginBottom: '10px',
    };

    return (
      <Link style={style} to={to}>
        {text}
      </Link>
    );
  };

  createRow = (asset: any) => {
    return <VideoAssetRow key={asset.id} src={_.get(asset, 'formats.mp4')} />;
  };

  get assets () {
    return assetService.getVideosArray().map(this.createRow);
  }

  render () {
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>Preview</Table.HeaderCell>
            <Table.HeaderCell textAlign='left'>Formats</Table.HeaderCell>
            <Table.HeaderCell textAlign='left' width={4}>
              Metadata
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{this.assets}</Table.Body>

        <Table.Footer fullWidth />
      </Table>
    );
  }
}
