import React from 'react';

import { assetService } from '../../../../../services/stateController';
import {
  addAnnotationWithAssociations,
  findAnnotationById,
  getMeaningfulDisplayInfo,
  getTool,
  modifyAnnotation
} from '../../../../../services/toolService';
import { Annotation } from '../../../../../types/annotations';
import { QuestionType } from '../../../../../types/questions';
import {
  QuizAnswerInput,
  QuizAnswerInputsByAnnotationId,
  QuizAnswerPlaceHolder
} from '../../../../../types/quizUtilityTypes';
import {
  ANSWER_INPUT_KEYS,
  getCollectionType,
  incrementNewAnswerDefaults,
  QUESTION_TYPES
} from './questionService';

const DEFAULT_PASS_MESSAGE_TEXT = 'Great job';
const DEFAULT_FAIL_MESSAGE_TEXT = 'You can do better';
const DEFAULT_ANSWERS_COUNT = 4;

export const QUIZ_TOOL_TYPES = ['quiz', 'question'];

export const formatAnswersForInputTable = (answerIds: string[], annotations: Annotation[], placeholders: QuizAnswerPlaceHolder) => {
  const answers = answerIds.map((id: string) => annotations.find((a: Annotation) => a.id === id)).filter(Boolean) as Annotation[];
  return answers.reduce((acc: QuizAnswerInputsByAnnotationId, annotation: Annotation) => {
    const { properties, content } = annotation;
    const { thumbnail, value } = properties;

    acc[annotation.id] = {
      inputs: [
        {
          inputType: 'input',
          placeholder: placeholders.option,
          value: content,
          fieldKey: 'option',
          visible: true
        },
        {
          inputType: 'input',
          placeholder: placeholders.value,
          value: value,
          fieldKey: 'value',
          visible: true
        },
        {
          inputType: 'image',
          value: thumbnail,
          fieldKey: 'thumbnail',
          visible: false
        },
      ].filter((input) => input.visible),
    };

    return acc;
  }, {});
};

export const formatAnswersFromInputTable = (data: QuizAnswerInputsByAnnotationId, annotations: Annotation[]) => {
  const _annotations = [...annotations];

  Object.keys(data).forEach((annotationId) => {
    const find = (value: string) => data[annotationId].inputs.find((a: QuizAnswerInput) => a.fieldKey === value)?.value;
    const annotation = _annotations.find((a) => a.id === annotationId);

    const props = [...ANSWER_INPUT_KEYS].reduce((acc, curr) => {
      const val = find(curr);
      acc[curr] = val !== undefined ? val : '';
      return acc;
    }, {} as any);

    const { option, ...otherProps } = props;

    if (annotation) {
      annotation.content = option;

      annotation.properties = {
        ...annotation.properties,
        ...otherProps,
      };
    }
  });

  return _annotations;
};

export const makeInitialDefaultQuizQuestionWithAnswer = (annotation: Annotation, annotations: Annotation[], atIndex?: any) => {
  const toolType = getCollectionType(annotation.toolType);
  const questionType = QUESTION_TYPES[0] as QuestionType;
  const rootQuiz = annotations.find((a: Annotation) => annotation.appliesTo?.includes(a.id));
  const quizQuestion = addAnnotationWithAssociations({
    appliesTo: annotation.id,
    toolType,
    annotations,
    atIndex,
    overrides: {
      properties: {
        type: questionType,
        points: rootQuiz?.properties?.points
      },
    },
  });

  let updateAnnotationsRet = quizQuestion.updatedAnnotations;
  let quizAnswerRet;

  for (let i = 0; i < DEFAULT_ANSWERS_COUNT; i++) {
    const newAnswerOverrides = incrementNewAnswerDefaults(questionType, i + 1);
    const collectionToolType = getCollectionType(quizQuestion.annotation.toolType);

    // RETURN THE QUIZ_QUESTION ANNOTATION AND THE QUIZ_ANSWER ANNOTATION WHICH ARE CREATED IN THIS FN
    const { updatedAnnotations, annotation: quizAnswer } = addAnnotationWithAssociations({
      appliesTo: quizQuestion.annotation.id,
      toolType: collectionToolType,
      annotations: updateAnnotationsRet,
      overrides: newAnswerOverrides,
    });

    updateAnnotationsRet = updatedAnnotations;
    quizAnswerRet = quizAnswer;
  }

  return {
    updatedAnnotations: updateAnnotationsRet,
    quizAnswer: quizAnswerRet,
    quizQuestion: quizQuestion.annotation,
  };
};

const createQuizFinalMessage = (type = '', content = '') => {
  return {
    toolType: 'quizFinalMessage',
    overrides: {
      content,
      properties: {
        type,
      },
    },
  };
};

export const makeInitialDefaultQuizAnnotations = (annotation: Annotation, annotations: Annotation[]) => {
  const { id } = annotation;

  if (!id) return;

  let quizSection = null;

  const { editor = {} } = getTool('quiz');
  const defaultQuizAnnotations = editor.collectionTypes.map((toolType: string) => {
    return { toolType };
  });

  defaultQuizAnnotations.push(createQuizFinalMessage('quizPassMessage', DEFAULT_PASS_MESSAGE_TEXT));
  defaultQuizAnnotations.push(createQuizFinalMessage('quizFailMessage', DEFAULT_FAIL_MESSAGE_TEXT));

  let _annotations = [...annotations];

  defaultQuizAnnotations.forEach((a: Annotation) => {
    const { toolType, overrides } = a;
    const { annotation, updatedAnnotations } = addAnnotationWithAssociations({
      appliesTo: id,
      toolType,
      annotations: _annotations,
      overrides,
    });

    if (toolType === 'quizSection') {
      quizSection = annotation;
    }

    // @ts-expect-error TS(2488): Type 'any[] | undefined' must have a '[Symbol.iter... Remove this comment to see the full error message
    _annotations = [...updatedAnnotations];
  });

  // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
  const { updatedAnnotations } = makeInitialDefaultQuizQuestionWithAnswer(quizSection, _annotations);

  _annotations = modifyAnnotation(updatedAnnotations, annotation.id, { actions: annotation.actions });

  return _annotations;
};

export const addSanitizedKeyValuePair = (obj: any, item: any) => {
  if (!item.key || !item.value) return obj;

  return {
    ...obj,
    [item.key]: item.value,
  };
};

export const findFirstAppliesToRelation = (annotation: Annotation) => {
  const { appliesTo } = annotation;
  if (appliesTo && appliesTo.length) return appliesTo[0];
};

export const getNextItemIndex = (collectionAnnotation: Annotation, id: any, collectionType: any) => {
  const NEXT = 1;
  if (!collectionAnnotation) return;
  const index = collectionAnnotation.collections[collectionType].indexOf(id);
  return index + NEXT;
};

export const findAssociatedQuizAnnotation = (annotationId: string) => {
  const findAssociatedQuiz = (annotation: Annotation) => {
    if (annotation && QUIZ_TOOL_TYPES.includes(annotation.type)) {
      return annotation;
    }

    if (!annotation.appliesTo || !annotation.appliesTo.length) return;

    let result = null;

    annotation.appliesTo.forEach((id: string) => {
      result = findAssociatedQuiz(findAnnotationById(id));
    });

    return result;
  };

  const annotation = findAnnotationById(annotationId);
  if (QUIZ_TOOL_TYPES.includes(annotation.toolType)) return null;
  return findAssociatedQuiz(annotation);
};

export const findCollection = (annotation: Annotation) => {
  if (!annotation || !annotation.collections) return [];
  const collectionType = getCollectionType(annotation.toolType);
  return collectionType ? annotation.collections[collectionType] : [];
};

export const findAssociatedQuizAnnotationIds = (
  annotation?: Annotation | null,
  filterBy?: string | null,
  projectAnnotations?: Annotation[],
  asOrdered?: boolean
) => {
  // Todo 03.31.21: memoization should be implemented here
  if (!annotation) return [];

  const ordered: any = [];

  const ids = {
    sections: [],
    questions: [],
    answers: [],
  };

  const findCollectionsById = (id: string) => findCollection(findAnnotationById(id, projectAnnotations));

  const sections = findCollection(annotation);
  sections.forEach((sectionId: string, idx: number) => {
    // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    ids.sections.push(sectionId);
    ordered.push(sectionId);

    const questionIds = findCollectionsById(sectionId);
    questionIds.forEach((questionId: any) => {
      const answerIdsArr = findCollectionsById(questionId);
      // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      ids.questions.push(questionId);
      // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      ids.answers.push(answerIdsArr);

      ordered.push([questionId, ...answerIdsArr]);
    });
  });

  const otherCollectionNames = Object.keys(annotation.collections).filter((key) => key !== 'quizSection');
  const getCollectionIds = (collectionName: any) => annotation.collections[collectionName];
  const otherCollectionIds = otherCollectionNames.map(getCollectionIds).flat();

  const quizSectionQuestionAnswerIds = [...ids.sections, ...ids.questions, ...ids.answers.flat()];
  const quizCollections = annotation.collections ? Object.values(annotation.collections) : [];
  const quizCollectionIds = quizCollections && quizCollections.flat();

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (filterBy && ids[filterBy]) return ids[filterBy].flat();

  if (asOrdered) return [...ordered.flat(), ...otherCollectionIds];

  return [...new Set([...quizSectionQuestionAnswerIds, ...quizCollectionIds, ...otherCollectionIds])];
};

export const getAnnotationsAppliedToQuizAnswers = (annotations: any) => {
  if (!annotations) return [];
  const answerAnnotations = annotations.filter((a: any) => QUIZ_TOOL_TYPES.includes(a.toolType));
  const answerIds = answerAnnotations.map((a: any) => findAssociatedQuizAnnotationIds(a, 'answers')).flat();
  return answerIds.map((id: string) => {
    const { content } = findAnnotationById(id);
    return content;
  });
};

export const getQuizAnswerRowDisplay = (a: Annotation) => {
  const { displayArray } = getMeaningfulDisplayInfo(a, true);
  let imageDisplay = null;

  if (a.type === 'image' && a.content) {
    imageDisplay = <img style={{ height: '32px' }} src={assetService.getImage(a.content).src} alt={a.content} />;
  }

  return imageDisplay || displayArray;
};

export const getQuizSubAnnotationPath = ({
  quiz,
  annotation
}: any) => {
  const { toolType, appliesTo, id } = annotation;

  let annotationPath: any = [];

  if (toolType === 'quizSection') {
    annotationPath = [id];
  }

  if (toolType === 'quizQuestion') {
    annotationPath = [appliesTo[0], id];
  }

  if (toolType === 'quizFinalMessage') {
    annotationPath = [annotation.id];
  }

  const path = {
    id: quiz.id,
    path: annotationPath,
    bypassHistory: true,
  };

  return annotationPath.length ? path : {};
};
