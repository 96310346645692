import './PercentProgressBar.scss';
import React from 'react';

type PercentProgressBarProps = {
  uploadPercent: number;
};

export const PercentProgressBar = ({
  uploadPercent
}: PercentProgressBarProps) => {
  return (
    <section id='percent-uploaded-section'>
      <label id='percent-uploaded' htmlFor='progress-bar'>{uploadPercent + ' %'}</label>
      <progress id='percent-progress-bar' value={uploadPercent} max='100' />
    </section>
  );
};
