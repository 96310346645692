import React, { useState } from 'react';

import { Button, CloseCircleHollowSVG, Icon, PopOut, ShapesHollowSVG, UserFilledSVG } from '@newsela/angelou';

import sessionClient from '../../services/sessionClient';

import './index.scss';

type CurrentUserPopOutProps = {
  hasManyGroups: boolean;
  isHomeRoute: boolean;
  toggleChooseGroupModal: () => void;
}

export const CurrentUserPopOut = ({
  hasManyGroups,
  isHomeRoute,
  toggleChooseGroupModal
}: CurrentUserPopOutProps) => {
  const [isPopOutVisible, setIsPopOutVisible] = useState(false);
  const popOutControlId = 'current-user-pop-out';
  const userDetails = sessionClient.getCurrentUserDetails();

  const togglePopOutVisibility = () => {
    setIsPopOutVisible((isPopOutVisible) => !isPopOutVisible);
  };

  return (
    <div className='nav-pop-out-button'>
      <Button
        legacy_flavor={Button.legacy_flavor.incognito}
        onClick={togglePopOutVisibility}
      >
        <Icon SvgComponent={UserFilledSVG} isDecorative={false} title='current user options' />
        <PopOut
          ariaControlsId={popOutControlId}
          ariaProps={{ 'aria-labelledby': popOutControlId }}
          isVisible={isPopOutVisible}
          onClickOff={togglePopOutVisibility}
          onEsc={togglePopOutVisibility}
          verticalOffset='10px'
          horizontalPosition='RIGHT'
          __cssFor={{
            root: {
              zIndex: '200'
            }
          }}
        >
          <div className='nav-pop-out-menu'>
            <div className='read-only-option'>
              {userDetails.fullName && (<div className='name'>{userDetails.fullName}</div>)}
            </div>
            <div className='read-only-option'>
              <hr />
            </div>
            <div
              className='clickable-option'
              onClick={sessionClient.logout}
            >
              <Icon SvgComponent={CloseCircleHollowSVG} isDecorative={false} title='log out' />
              <span className='option-text'>Log out</span>
            </div>
            {
              hasManyGroups && isHomeRoute && (
                <div
                  className='clickable-option'
                  onClick={toggleChooseGroupModal}
                >
                  <Icon SvgComponent={ShapesHollowSVG} isDecorative={false} title='switch groups' />
                  <span className='option-text'>Switch Group</span>
                </div>
              )
            }
          </div>
        </PopOut>
      </Button>
    </div>
  );
};
