import React from 'react';

import { Icon } from '../../../../hapyak-ui-toolkit';
import { sortByTime } from '../../../../services/annotationUtils';
import { comm } from '../../../../services/comm';
import { media } from '../../../../services/mediaController';
import hms from '../../../../services/time';
import { Annotation } from '../../../../types/annotations';
import { TimeInput } from '../../../TimeInput/TimeInput';

type State = any;

type EditorControlBarProps = {
  currentTime: number;
  duration: number;
  annotations: Annotation[];
  height: number;
};

export class EditorControlBar extends React.Component<EditorControlBarProps, State> {
  constructor (props: EditorControlBarProps) {
    super(props);

    this.state = {
      editingCurrentTime: false,
      editedTime: '',
      isPlaying: false,
      isMuted: false,
    };
  }

  componentDidMount () {
    comm.register('play', this.updatePlayState);
    comm.register('pause', this.updatePlayState);
    comm.register('volumeChange', this.updateVolumeState);
  }

  componentWillUnmount () {
    comm.unregister('play', this.updatePlayState);
    comm.unregister('pause', this.updatePlayState);
    comm.unregister('volumeChange', this.updateVolumeState);
  }

  editCurrentTime = () => {
    const { currentTime } = this.props;
    this.setState({ editingCurrentTime: true, editedTime: currentTime });
  };

  stopEditCurrentTime = () => {
    this.setState({ editingCurrentTime: false });
  };

  updatePlayState = () => {
    this.setState({ isPlaying: media.playing });
  };

  updateVolumeState = () => {
    this.setState({ isMuted: media.editorInstance.volume === 0 });
  };

  persistTime = (id: any, type: any, value: any) => {
    this.stopEditCurrentTime();
    comm.trigger('setCurrentTime', value);
  };

  previousAnnotation = (e: any) => {
    e.stopPropagation();
    const { annotations, currentTime } = this.props;
    const sorted = annotations.sort(sortByTime).reverse();
    const prev = sorted.find((a: Annotation) => a.start < currentTime);
    const time = prev ? prev.start : 0;

    comm.trigger('setCurrentTime', time);
  };

  nextAnnotation = (e: any) => {
    e.stopPropagation();
    const { annotations, duration, currentTime } = this.props;
    const sorted = annotations.sort(sortByTime);
    const next = sorted.find((a: Annotation) => a.start > currentTime);
    const time = next ? next.start : duration;

    comm.trigger('setCurrentTime', time);
  };

  togglePlayback = (playState: any, e: any) => {
    e.stopPropagation();
    comm.trigger(playState);
  };

  toggleVolume = () => {
    comm.trigger('toggleMute');
  };

  get eventsToStop () {
    return {
      onMouseDown: (e: any) => e.stopPropagation(),
      onDoubleClick: (e: any) => e.stopPropagation(),
    };
  }

  get muteIcon () {
    return (
      <Icon.Group {...this.eventsToStop} onClick={this.toggleVolume} className='hy-volume hy-muted'>
        <Icon size='big' name='window minimize outline' className='hy-top' />
        <Icon size='big' name='window minimize outline' className='hy-bottom' />
        <Icon size='big' name='volume up' />
      </Icon.Group>
    );
  }

  get amplifiedIcon () {
    return (
      <Icon
        {...this.eventsToStop}
        onClick={this.toggleVolume}
        name='volume up'
        size='big'
        className='hy-volume'
      />
    );
  }

  get volumeIcon () {
    const { isMuted } = this.state;
    return isMuted ? this.muteIcon : this.amplifiedIcon;
  }

  render () {
    const { currentTime, duration, height } = this.props;
    const { isPlaying, editedTime, editingCurrentTime } = this.state;
    const videoDuration = `${hms.secondsToHMS(duration, true)}`;
    const icon = isPlaying ? 'pause' : 'play';

    return (
      <div className='hy-editor-control-bar' style={{ height }}>
        <div className='hy-editor-controls'>
          <div className='hy-editor-control-bar-center-controls'>
            {this.volumeIcon}
            <Icon
              {...this.eventsToStop}
              onClick={this.previousAnnotation}
              name='angle double left'
              size='big'
            />
            <Icon
              {...this.eventsToStop}
              onClick={this.togglePlayback.bind(this, icon)}
              name={`${icon} circle outline`}
              size='huge'
              id='hy-editor-controls-huge-play'
            />
            <Icon
              {...this.eventsToStop}
              onClick={this.nextAnnotation}
              name='angle double right'
              size='big'
            />
          </div>
          <div {...this.eventsToStop} className='hy-editor-current-time'>
            <TimeInput
              time={editingCurrentTime ? editedTime : currentTime}
              min={0}
              max={duration}
              onFocus={this.editCurrentTime}
              persistTime={this.persistTime}
              onError={this.stopEditCurrentTime}
              simple
            />
            / <span className='hy-editor-video-duration'>{videoDuration}</span>
          </div>
        </div>
      </div>
    );
  }
}
