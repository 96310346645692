import './index.scss';

import React from 'react';

import DEFAULTS from '../../DEFAULTS.json';
import { Button, Dropdown, Image, Popup } from '../../hapyak-ui-toolkit';
import internalIcon from '../../img/internal-icon.svg';
import { comm } from '../../services/comm';
import { createManagerInstance } from '../../services/externalpreview/ExternalPreviewManager';
import { media } from '../../services/mediaController';
import { getUserPreferences, setPreviewPreferences } from '../../services/saveUtils';
import { toSentenceCase } from '../../services/utils';
import { SnapshotButton } from '../editors/StoryboardEditor/SnapshotButton';
import { Video } from '../Video';
import deviceslist from './devices.json';
import { DeviceWrapper } from './DeviceWrapper';
import { ScreenshotButton } from './ScreenshotButton';
const { devices } = deviceslist;

const DEFAULT_PREVIEW_SIZE = 'medium';

type State = any;

type PreviewSectionProps = {
  config?: any;
  snapshot?: any;
  snapshotHandler?: any;
}

export class PreviewSection extends React.Component<PreviewSectionProps, State> {
  DEFAULT_DEVICE: any;
  externalPreview: any;
  resizeMobile: any;
  constructor (props: PreviewSectionProps) {
    super(props);
    const [, , desktop] = devices;
    this.DEFAULT_DEVICE = desktop;
    this.state = {
      device: this.DEFAULT_DEVICE,
      portrait: true,
      overrideWidth: null,
      position: 'bottom',
      size: 'medium',
      lastSize: null,
      previewExpanded: false,
      previewExternal: false,
      ...getUserPreferences()[DEFAULTS.PREVIEW_PREFS_KEY], // set from saved preferences if available
      ...this.initialSnapshotProps,
    };
  }

  componentWillUnmount () {
    if (this.state.previewExternal) {
      this.closePopout();
    }
  }

  updateSettings = (updates: any) => {
    this.setState(updates);

    if (!updates.previewExpanded) {
      // don't save fullscreen pref
      setPreviewPreferences(updates);
    }
  };

  expand = () => {
    const { previewExpanded, device = {}, portrait = false } = this.state;

    if (previewExpanded) {
      comm.trigger('pause', true);
      comm.trigger('unlockState', 'viewportWidth');
    } else if (device.name === 'mobile' && portrait) {
      comm.trigger('lockState', 'viewportWidth', 640);
    }

    if (previewExpanded) {
      comm.trigger('changeAnimation', true);
    } else {
      comm.trigger('changeAnimation', false);
    }

    this.updateSettings({ previewExpanded: !previewExpanded });
  };

  setPosition = (position: any) => this.updateSettings({ position });
  setSize = (size: any) => this.updateSettings({ size });

  toggleHidden = () => {
    if (!this.hidden) {
      comm.trigger('pause', true);
      this.hidePreview();
    } else {
      this.restorePreview();
    }
  };

  hidePreview = () => this.updateSettings({ size: 'hidden', lastSize: this.state.size });

  restorePreview = () => {
    const { lastSize } = this.state;
    let size = DEFAULT_PREVIEW_SIZE;

    if (lastSize && lastSize !== 'hidden') {
      size = lastSize;
    }

    this.updateSettings({ size });
  };

  get hidden () {
    return this.state.size === 'hidden';
  }

  togglePortrait = () => {
    const portrait = !this.state.portrait;

    // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
    this.updateSettings({ portrait, overrideWidth: null }, this.resizeMobile);

    if (this.state.device.name === 'mobile' && portrait) {
      comm.trigger('lockState', 'viewportWidth', 640);
    } else {
      comm.trigger('unlockState', 'viewportWidth');
    }
  };

  changePreviewSize = (device: any) => {
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
    this.updateSettings({ device, overrideWidth: null }, this.resizeMobile);

    if (device.name === 'mobile' && this.state.portrait) {
      comm.trigger('lockState', 'viewportWidth', 640);
    } else {
      comm.trigger('unlockState', 'viewportWidth');
    }
  };

  createPreviewDeviceButton = (device: any) => {
    const primary = this.state.device.name === device.name;
    const icon = `${device.name}${device.portrait ? ' alternate' : ''}`;
    return (
      <Button
        key={device.name}
        icon={icon}
        onClick={this.changePreviewSize.bind(this, device)}
        primary={primary}
      />
    );
  };

  popoutPreview = () => {
    this.externalPreview = createManagerInstance();
    this.hidePreview();
    this.setState({ previewExternal: true });
    window.addEventListener('beforeunload', this.closePopout);
    comm.register('externalPreviewClosed', this.onExternalPreviewClosed);
  };

  closePopout = () => this.externalPreview && this.externalPreview.close();

  onExternalPreviewClosed = () => {
    this.restorePreview();
    this.setState({ previewExternal: false });
    comm.unregister('externalPreviewClosed', this.onExternalPreviewClosed);
  };

  get snapshotOverrides () {
    const { device, portrait } = this.state;

    return {
      device: device.name,
      portrait: device.name !== 'desktop' && portrait,
    };
  }

  get dropdown () {
    const { position } = this.state;
    const upwards = position === 'bottom';

    return (
      <Dropdown text='' icon='ellipsis vertical' button className='icon hy-preview-button'>
        <Dropdown.Menu
          style={{ top: upwards ? 'auto' : '120%', bottom: upwards ? '120%' : '', right: 0, left: 'auto' }}
        >
          <Dropdown.Header content='Position' icon='arrows alternate vertical' />
          {this.getDropdownItem('top', 'position', this.setPosition)}
          {this.getDropdownItem('bottom', 'position', this.setPosition)}
          <Dropdown.Divider />
          <Dropdown.Header content='Display' icon='window restore outline' />
          {this.getDropdownItem('hidden', 'size', this.toggleHidden)}
          {this.getDropdownItem('small', 'size', this.setSize)}
          {this.getDropdownItem('medium', 'size', this.setSize)}
          {this.getDropdownItem('large', 'size', this.setSize)}
          {this.getDropdownItem('fullscreen', 'size', this.expand)}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  onComplete = (image: any) => {
    this.setState({
      preloadImage: true,
      src: image.src,
    });
  };

  get initialSnapshotProps () {
    return {
      loading: false,
      src: null,
      preloadImage: false,
    };
  }

  loading = () => this.setState({ loading: true });
  loaded = () => this.setState(this.initialSnapshotProps);

  get snapshot () {
    const { snapshotHandler } = this.props;
    const { loading, previewExpanded } = this.state;

    if (!previewExpanded) {
      return null;
    }

    const button = (
      <SnapshotButton
        loading={loading}
        color='teal'
        className='hy-preview-button icon'
        addPage={false}
        content=''
        handler={snapshotHandler}
        onComplete={this.onComplete}
        onClick={this.loading}
      />
    );

    return <Popup content='Image will appear in assets list' trigger={button} position='top right' />;
  }

  get screenshot () {
    const { snapshotHandler } = this.props;
    const { previewExpanded } = this.state;

    if (!previewExpanded) {
      return null;
    }

    const button = (
      <ScreenshotButton handler={snapshotHandler} />
    );

    return <Popup content='The project poster image is now set to this frame.' trigger={button} position='top right' />;
  }

  get expandButton () {
    const { previewExpanded } = this.state;

    return (
      <Button
        color={previewExpanded ? 'red' : 'blue'}
        onClick={this.expand}
        className='hy-preview-button'
        icon={previewExpanded ? 'close' : 'expand arrows alternate'}
      />
    );
  }

  get collapseButton () {
    return (
      <Button
        color={this.hidden ? 'blue' : 'red'}
        onClick={this.toggleHidden}
        className='hy-preview-button'
        icon={this.hidden ? 'plus' : 'minus'}
      />
    );
  }

  get popoutButton () {
    if (this.state.previewExternal) {
      const fontIcon = {
        display: 'inline-block',
        width: '1.18em',
        height: '1em',
      };

      return (
        <Button color='purple' className='hy-preview-button icon' onClick={this.closePopout}>
          <img className='icon' alt='close external preview' style={fontIcon} src={internalIcon} />
        </Button>
      );
    }

    return <Button color='purple' onClick={this.popoutPreview} className='hy-preview-button' icon='external' />;
  }

  hardReloadPlayer = () => {
    comm.trigger('hardReloadPlayer');
  };

  get hardReloadButton () {
    return <Button color='green' onClick={this.hardReloadPlayer} className='hy-preview-button' icon='refresh' />;
  }

  getLabel = (active: any) => {
    return { color: 'blue', empty: true, circular: true, style: { opacity: active ? 1 : 0 } };
  };

  getDropdownItem = (value: any, type: any, setMethod: any) => {
    const current = this.state[type];
    return (
      <Dropdown.Item
        content={toSentenceCase(value)}
        onClick={setMethod.bind(this, value)}
        label={this.getLabel(current === value)}
      />
    );
  };

  get limitHeight () {
    return media.video.aspectRatio < 1;
  }

  get videoComponent () {
    return <Video preview />;
  }

  get previewButtons () {
    const { previewExternal } = this.state;

    if (previewExternal) {
      return <div className='hy-preview-buttons'>{this.popoutButton}</div>;
    }

    return (
      <div className='hy-preview-buttons'>
        {this.dropdown}
        {this.collapseButton}
        {this.expandButton}
        {this.popoutButton}
        {this.hardReloadButton}
        {this.screenshot}
      </div>
    );
  }

  render () {
    let { device, portrait, position, size, previewExpanded, preloadImage, src } = this.state;
    const className = `hy-page-layout-side hy-page-layout-section ${
      previewExpanded ? 'hy-expanded hy-scrollable' : ''
    }`;
      // set preview styling via classes:
    let previewVideoClassName = 'hy-page-layout-preview-video';
    if (this.limitHeight) previewVideoClassName += ' hy-preview-limit-height';
    previewVideoClassName += ` hy-preview-position-${position}`;
    previewVideoClassName += ` hy-preview-size-${size}`;

    if (!previewExpanded) device = this.DEFAULT_DEVICE;

    const show = previewExpanded || size !== 'hidden';

    return (
      <div className={className}>
        {preloadImage && <Image src={src} onLoad={this.loaded} />}
        <div>
          {previewExpanded && (
            <div className='hy-preview-top-bar'>
              <div className='hy-preview-header'>Preview</div>
              <div className='hy-preview-top-bar-middle'>
                <Button icon='retweet' onClick={this.togglePortrait} disabled={!device.portrait} />
                <Button.Group>{devices.map(this.createPreviewDeviceButton)}</Button.Group>
                {this.hardReloadButton}
                {this.screenshot}
              </div>
              <div className='hy-preview-buttons-right'>
                {this.expandButton}
              </div>
            </div>
          )}

          <div className={previewVideoClassName}>
            {!previewExpanded && this.previewButtons}
            <DeviceWrapper device={device.name} portrait={portrait} hidden={!show}>
              {this.videoComponent}
            </DeviceWrapper>
          </div>
        </div>
      </div>
    );
  }
}
