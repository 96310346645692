import React from 'react';

import _ from 'lodash';

import { MediaInfo, VideoAsset, VideosAsset } from 'types/assets';
import { MaturityRange } from 'types/project';

import DEFAULTS from '../DEFAULTS.json';
import { updateDefaultImages } from '../sharedUtils';
import { getTemplate } from '../templates';
import { VTT } from '../types/vtt';
import { comm } from './comm';
import { media } from './mediaController';
import { persistenceClientFactory } from './persistence/persistenceClientFactory';
import { putProject } from './persistence/platformService';
import projectFactory from './projectFactory';
import { deleteSave, resetTabPreferences } from './saveUtils';
import { assetService, stateController } from './stateController';
import { attemptImport, getExportJSON } from './stateUtils';
import { remoteUploadVideo, uploadMp3, uploadVideo, uploadVtt } from './uploadUtils';
import VisService from './visService';

export default class NewProjectService {
  static urlReady = false;
  static transcodingComplete = false;
  static setWorking: React.Dispatch<React.SetStateAction<boolean>>;

  static createProject = async (
    templateId: number,
    projectInputs: { title: string, description: string, maturityRange: MaturityRange, created?: number },
    setProjectId: React.Dispatch<React.SetStateAction<string>>,
    setWorking: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorMsg: React.Dispatch<React.SetStateAction<string>>
  ) => {
    deleteSave();
    projectInputs.created = Date.now();
    NewProjectService.setWorking = setWorking;

    const template = getTemplate(templateId);
    const { video } = template;

    const newProjectData = await projectFactory.createProject(template, projectInputs, video);
    await stateController.setProject(newProjectData);

    assetService.createVideo(video);
    updateDefaultImages({ video });

    const project = getExportJSON();
    const validProject = await attemptImport(project);
    if (!validProject) return;

    resetTabPreferences();

    try {
      const client = persistenceClientFactory.getClient();
      const project = await client.create(validProject);
      const proId = _.get(project, 'project.id');
      setProjectId(proId);
      await stateController.setProject(project);
    } catch (error) {
      setWorking(false);
      setErrorMsg(`There was an error while creating Project. Please try again later. ${error}`);
    }
  };

  static uploadVideo = async (id: string, file: VideoAsset, language: string, lexileLevel: string) => {
    await stateController.updateProject('project', { shouldUpdateTranscoding: true });
    await uploadVideo(id, file, language, lexileLevel);
  };

  static remoteUploadVideo = async (remoteVideoUploadUrl: string) => {
    await stateController.updateProject('project', { shouldUpdateRemoteTranscoding: true });
    comm.register('processInitiated', NewProjectService.remoteTranscodePersist);
    comm.register('remoteTranscodeComplete', NewProjectService.remoteTranscodeCompletePersist);
    remoteUploadVideo(remoteVideoUploadUrl);
  };

  static remoteTranscodePersist = async (process: string) => {
    const project = stateController.getCurrentData();
    await putProject(project, project.project.id, false);
    NewProjectService.setWorking(false);
  };

  static remoteTranscodeCompletePersist = async () => {
    await stateController.updateProject('project', { shouldUpdateRemoteTranscoding: false });
    const project = stateController.getCurrentData();
    await putProject(project, project.project.id, false);
  };

  static getVideos = () => {
    const video = assetService.getVideosArray()[0] || {};
    const videoId = video.id;
    const videos: VideosAsset = {};
    videos[videoId] = video;
    return videos;
  };

  static setVideo = async () => {
    const videos = NewProjectService.getVideos();
    await stateController.updateProject('assets', { videos });
  };

  static setCompleteVideo = async (media_info: MediaInfo) => {
    const video = assetService.getVideosArray()[0] || {};

    const videoNew = {
      ...video,
      aspectRatio: media_info.width / media_info.height,
      duration: media_info.duration_ms,
      height: media_info.height,
      width: media_info.width
    };

    updateDefaultImages({ video, posterImage: media_info.poster });

    const videos: VideosAsset = {};
    const videoId = video.id;
    videos[videoId] = videoNew;

    await stateController.updateProject('assets', { videos });
  };

  static processStarted = async (processName: string) => {
    if (!NewProjectService.urlReady && processName === 'TranscodeUpload') {
      NewProjectService.urlReady = true;
      const project = stateController.getCurrentData();
      await putProject(project, project.project.id, false);
      NewProjectService.setWorking(false);
    }
  };

  static processComplete = async (processName: string, processData: any) => {
    if (!NewProjectService.transcodingComplete && processName === 'TranscodeUpload') {
      NewProjectService.transcodingComplete = true;
      if (processData?.data) {
        NewProjectService.setCompleteVideo(processData.data.media_info);
      }
      await stateController.updateProject('project', { shouldUpdateTranscoding: false });
      const project = stateController.getCurrentData();
      await putProject(project, project.project.id, false);
    }
  };

  static startPoll = () => {
    VisService.fetchTranscodeResult();
  };

  static startRemoteUpload = () => {
    const processes = stateController.getCurrentData('processes');
    const url = processes[DEFAULTS.TRANSCODE_REMOTE_UPLOAD_KEY];
    remoteUploadVideo(url);
  };

  static uploadVttFile = async (projectId: string, file: any, language: string, lexileLevel: string) => {
    const type = 'Captions';

    await uploadVtt(projectId, file.file.file, type, language)
      .then((src) => NewProjectService.afterCaptionsUpload(src, language, lexileLevel, type))
      .catch((error) => {
        console.error('uploadVtt error: ', error);
        comm.trigger('vttUploadError', 'text/vtt', error.message);
      });

    const project = stateController.getCurrentData();
    await putProject(project, project.project.id, false);
  };

  static afterCaptionsUpload = (src: string, language: string, lexileLevel: string, type: string = DEFAULTS.ARB_VIDEO_TEXT) => {
    assetService.createVtt(
      {
        isControlled: true,
        language,
        lexileLevel,
        src: src,
        type,
        videoId: media.id,
      } as VTT,
      false
    );
  };

  static uploadMp3File = async (file: any, language: string, lexileLevel: string) => {
    const type = 'Audio Description';
    const src = await uploadMp3(file.file.file);
    NewProjectService.afterMp3Upload(src, language, lexileLevel, type);
    const project = stateController.getCurrentData();
    await putProject(project, project.project.id, false)
      .catch((error) => {
        console.error('mp3upload error:', error);
      });
  };

  static afterMp3Upload = (src: string, language: string, lexileLevel: string, type: string = DEFAULTS.ARB_VIDEO_TEXT) => {
    assetService.createAudioDescription(
      {
        isControlled: true,
        language,
        lexileLevel,
        src: src,
        type,
        videoId: media.id,
      },
      false
    );
  };
}
