import React, { useEffect, useState } from 'react';

import { Icon, ImageFilledSVG } from '@newsela/angelou';

import { JobError } from '../../../../errors/JobError';
import signingService from '../../../../services/signingService';
import './index.scss';
import { AngelouLoadingSpinner } from '../../AngelouComponents/AngelouLoadingSpinner';
import ErrorBanner from '../../AngelouComponents/ErrorBanner';

type ImageDisplayProps = {
  loaded: boolean;
  hasError?: boolean;
  error: null | JobError;
  isCustomSrc?: boolean;
  onError: (e?: any) => void;
  onLoad: (e?: any) => void;
  src?: string;
};

export function ImageDisplay ({
  loaded,
  hasError,
  error,
  isCustomSrc,
  onLoad,
  src,
}:ImageDisplayProps) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(!!(src && !loaded && !hasError && !error));
  });

  const imageIcon = (
    <Icon
      SvgComponent={ImageFilledSVG}
      isDecorative
      __cssFor={{
        root: {
          width: '32px',
          marginRight: '1rem'
        }
      }}
    />
  );

  return (
    <div className='hy-image-display'>
      {src && !hasError && !error &&
        <img
          alt='Annotation thumbnail'
          src={isCustomSrc ? src : signingService.sign(src)}
          onLoad={onLoad}
          className='hy-thumbnail'
        />}
      {loading && <AngelouLoadingSpinner inverted={false} />}
      {!src && !error && !hasError && (
        <div className='hy-margin-bottom hy-no-thumbnail upload-message'>
          {imageIcon} No image selected. Upload a new image or choose an existing one.
        </div>
      )}
      {(hasError || error) && (
        <ErrorBanner
          body='An error occurred. Upload a new image or choose an existing one.'
          error={error}
        />
      )}
    </div>

  );
}
