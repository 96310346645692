import React, { Component } from 'react';

import defaults from '../../DEFAULTS.json';
import { JobError } from '../../errors/JobError';
import { comm } from '../../services/comm';
import { media } from '../../services/mediaController';
import { prepareConfigFromTools, replaceAssetIds } from '../../services/videoConfig';
import { Annotation } from '../../types/annotations';
import { VideoStateMessage } from './VideoStateMessage';
const { SKIP_AMOUNTS } = defaults;

const hy = (window as any).hy;
const version = hy.version;

type VideoProps = {
  placeholderAnnotations?: Annotation[];
  preview: boolean;
  config?: any;
}

class Video extends Component<VideoProps> {
  el: any;
  player: any;
  sortByStartTimeReversed: any;
  constructor (props: any) {
    super(props);
    this.player = null;
    this.el = React.createRef();
  }

  get isPreview () {
    return !!this.props.preview;
  }

  get config () {
    return replaceAssetIds(prepareConfigFromTools(false, this.isPreview));
  }

  componentDidMount () {
    comm.register('updatePlayer', this.updatePlayer);
    comm.register('setCurrentTime', this.setCurrentTime);
    comm.register('play', this.play);
    comm.register('pause', this.pause);
    // comm.register('mute', this.mute);
    comm.register('toggleMute', this.toggleMute);
    comm.register('skipShort', this.bumpTime.bind(this, SKIP_AMOUNTS.SHORT));
    comm.register('skipNormal', this.bumpTime.bind(this, SKIP_AMOUNTS.NORMAL));
    comm.register('skipLong', this.bumpTime.bind(this, SKIP_AMOUNTS.LONG));
    comm.register('rewindShort', this.bumpTime.bind(this, -SKIP_AMOUNTS.SHORT));
    comm.register('rewindNormal', this.bumpTime.bind(this, -SKIP_AMOUNTS.NORMAL));
    comm.register('rewindLong', this.bumpTime.bind(this, -SKIP_AMOUNTS.LONG));
    comm.register('nextAnnotation', this.nextAnnotation);
    comm.register('previousAnnotation', this.previousAnnotation);
    comm.register('updateMp4', this.updateMp4);
    comm.register('reloadPlayer', this.reloadPlayer);
    comm.register('lockState', this.lockState);
    comm.register('unlockState', this.unlockState);
    comm.register('changeAnimation', this.changeAnimation);
    comm.register('toSubAnnotation', this.toSubAnnotation);
    comm.register('hardReloadPlayer', this.hardReloadPlayer);
    this.setupViewer();
  }

  componentWillUnmount () {
    comm.unregister('updatePlayer', this.updatePlayer);
    comm.unregister('setCurrentTime', this.setCurrentTime);
    comm.unregister('play', this.play);
    comm.unregister('pause', this.pause);
    // comm.unregister('mute', this.mute);
    comm.unregister('toggleMute', this.toggleMute);
    comm.unregister('skipShort', this.bumpTime.bind(this, SKIP_AMOUNTS.SHORT));
    comm.unregister('skipNormal', this.bumpTime.bind(this, SKIP_AMOUNTS.NORMAL));
    comm.unregister('skipLong', this.bumpTime.bind(this, SKIP_AMOUNTS.LONG));
    comm.unregister('rewindShort', this.bumpTime.bind(this, -SKIP_AMOUNTS.SHORT));
    comm.unregister('rewindNormal', this.bumpTime.bind(this, -SKIP_AMOUNTS.NORMAL));
    comm.unregister('rewindLong', this.bumpTime.bind(this, -SKIP_AMOUNTS.LONG));
    comm.unregister('nextAnnotation', this.nextAnnotation);
    comm.unregister('previousAnnotation', this.previousAnnotation);
    comm.unregister('updateMp4', this.updateMp4);
    comm.unregister('reloadPlayer', this.reloadPlayer);
    comm.unregister('lockState', this.lockState);
    comm.unregister('unlockState', this.unlockState);
    comm.unregister('changeAnimation', this.changeAnimation);
    comm.unregister('toSubAnnotation', this.toSubAnnotation);
    comm.unregister('hardReloadPlayer', this.hardReloadPlayer);
    this.teardown();
  }

  updatePlayer = () => {
    this.teardown().then(this.setupViewer);
  };

  shouldComponentUpdate (nextProps: any) {
    // we should only render the video tag once
    // all subsequent updates should occur through the `player.reload` interface
    return false;
  }

  reloadPlayer = () => {
    const data = this.getData();
    requestAnimationFrame(() => {
      this.player.reload((window as any).hy.RELOAD_TYPES.OVERRIDES_ONLY, JSON.parse(JSON.stringify(data)));
    });
  };

  hardReloadPlayer = () => {
    this.setupViewer();
  };

  hideThumbnailForPreviewWindow = (projectData: any, preview: any) => {
    if (!preview) {
      // preview refers to the exapandable preview window only, NOT the DND UX configuration preview
      projectData.posterImage = '';
    }

    return projectData;
  };

  getData = () => {
    const { placeholderAnnotations = [], preview = false } = this.props;
    const { project, display } = this.config;
    const annotations = this.config.annotations;
    const projectDataNoThumbnail = this.hideThumbnailForPreviewWindow(project, preview);

    return {
      version,
      annotations: [...annotations, ...placeholderAnnotations],
      display,
      project: projectDataNoThumbnail,
    };
  };

  teardown () {
    this.el.current.innerHTML = '';

    if (this.player) {
      return this.player.destroy && this.player.destroy();
    }

    return Promise.resolve();
  }

  play = () => {
    if (this.isPreview) return;
    this.player.play();
  };

  pause = (force: any) => {
    if (force) {
      if (this.isPreview) this.player.pause();
      return;
    }

    if (this.isPreview) return;

    this.player.pause();
  };

  mute = () => {
    if (this.isPreview) return;
    this.player.volume = 0;
  };

  amplify = () => {
    if (this.isPreview) return;
    this.player.volume = 1;
  };

  toggleMute = () => {
    if (this.isPreview) return;
    this.player.volume = this.player.volume > 0 ? 0 : 1;
  };

  setCurrentTime = (time: any, force: any) => {
    if (!force && this.isPreview) return;
    if (this.player) {
      this.player.currentTime = time;
    }
  };

  bumpTime = (amount: any) => {
    if (this.isPreview) return;
    if (this.player) {
      this.player.currentTime = this.player.currentTime + amount;
    }
  };

  previousAnnotation = () => {
    const annotations = this.getData().annotations;
    const currentTime = this.player.currentTime;
    const sorted = annotations.sort(this.sortByStartTimeReversed).reverse();
    const prev = sorted.find((a) => a.start < currentTime);
    const time = prev ? prev.start : 0;

    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    this.setCurrentTime(time);
  };

  nextAnnotation = () => {
    const annotations = this.getData().annotations;
    const duration = this.player.duration;
    const currentTime = this.player.currentTime;
    const sorted = annotations.sort(this.sortByStartTimeReversed);
    const next = sorted.find((a) => a.start > currentTime);
    const time = next ? next.start : duration;

    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    this.setCurrentTime(time);
  };

  toSubAnnotation = (path: any) => {
    if (!path) return;
    this.player.eventBus.emit('annotationEvent.navigateSubElement', path);
  };

  updateMp4 = () => {
    this.teardown().then(this.setupViewer);
  };

  lockState = (stateName: any, value: any) => {
    if (this.player) {
      this.player.lockState(stateName, value);
    }
  };

  unlockState = (stateName: any) => {
    if (this.player) {
      this.player.unlockState(stateName);
    }
  };

  changeAnimation = (shouldAnimate: any) => {
    if (this.player) {
      this.player.animate = shouldAnimate;
    }
  };

  setupViewer = () => {
    const { preview } = this.props;
    let didSetTime = false;
    this.teardown();

    const editorOnlyEvents = {
      // @ts-expect-error TS(7031): Binding element 'time' implicitly has an 'any' typ... Remove this comment to see the full error message
      ontimeupdate: (e: any, { currentTime: time }) => {
        comm.trigger('onTimeUpdate', time);
      },
      oncanplay: () => {
        if (!didSetTime) {
          // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
          this.setCurrentTime(media.playTime);
          if (media.playing) this.play();
          didSetTime = true;
        }
      },
      onplay: () => {
        comm.trigger('play');
      },
      onpause: () => {
        comm.trigger('pause');
      },
      onvolumechange: () => {
        comm.trigger('volumeChange');
      },
      onrender: () => {
        comm.trigger('playerRendered');
      },
    };

    const playerEvents = preview ? {} : editorOnlyEvents;

    const overrides = this.getData();

    const playerConfig = {
      version,
      ...playerEvents,
      onloadedmetadata: () => {
        comm.trigger('onDuration', this.player.duration);
      },
      playerType: 'videojs4',
      overrides,
      container: this.el.current,
      disableAnalytics: true,
    };

    this.player = preview ? hy.player(playerConfig) : hy.editor(playerConfig);

    this.player.eventBus.on('playerEvent.available', () => {
      comm.trigger('onPlayerAvailable', this.player);

      this.player.playerAdapter.player.on('error', (error: any) => {
        const errorMessage = error.target.player.error_.message;
        if (error.type === 'error') {
          comm.trigger('onVideoError', errorMessage);
          comm.trigger('onDuration', 0);
        }
      });
    });

    media.setPlayerInstance(this.player, preview);
  };

  render () {
    return (
      <div>
        <div ref={this.el} className='videoPreviewContainer' style={{ fontSize: '14px' }} />
        <VideoStateMessage message='Transcoding Video' />
      </div>
    );
  }
}

export { Video };
