import React from 'react';

import { CustomDraggable, Draggable } from '../../../hapyak-ui-toolkit';
import { comm } from '../../../services/comm';
import { getToolFromAnnotation } from '../../../services/toolService';

const ROOT = (window as any).hy.compositionInformation.COMPOSITION_TYPES.ROOT;

type State = any;

type DraggableAnnotationProps = {
  active: boolean;
  annotation: any;
  dragStarted?: any;
  dropZone: any;
  edit: any;
  onDragEnd: any;
  onPlayer?: boolean;
  select: any;
  selected: boolean;
  snapRatio?: any;
};

class DraggableAnnotation extends React.Component<DraggableAnnotationProps, State> {
  constructor (props: DraggableAnnotationProps) {
    super(props);

    this.state = {
      active: false,
      optionDown: false,
    };
  }

  componentDidMount () {
    comm.register('optionDown', this.optionDown);
  }

  componentWillUnmount () {
    comm.unregister('optionDown', this.optionDown);
  }

  optionDown = (optionDown: any) => {
    this.setState({ optionDown });
  };

  setActive = (annotationId: any) => {
    comm.trigger('setActiveAnnotation', annotationId);
  };

  onDragEnd = (dropZoneId: any, draggableId: any, options = {}) => {
    comm.trigger('highlightTargets', null);
    comm.trigger('runZoomTargets', false, true);

    if (!dropZoneId) {
      this.props.onDragEnd(null, draggableId);
      return;
    }

    const { annotation } = this.props;
    const { styles } = annotation;
    const rootStyles = { ...styles[ROOT] }; (options as any).styles = { ...styles, [ROOT]: { ...rootStyles, ...((options as any).styles || {}) } };
    this.props.onDragEnd(dropZoneId, draggableId, options);
  };

  get position () {
    const { annotation } = this.props;
    const { styles } = annotation;
    const rootStyles = { ...styles[ROOT], ...styles[ROOT + '.expanded'] }; // hacky: assumes expanded
    const { top, left, width, height } = rootStyles;
    return { top, left, width, height };
  }

  draggingBetweenTargets = (targets: any) => {
    comm.trigger('highlightTargets', targets);
    comm.trigger('runZoomTargets', true, true);
  };

  isAnnotationHidden = () => {
    const { annotation } = this.props;
    const { mode } = annotation;
    return mode === 'invisible';
  };

  render () {
    const { active, edit, annotation, onPlayer, dropZone, snapRatio, select, selected, dragStarted } = this.props;
    const { optionDown } = this.state;
    const { id, type } = annotation;
    const tool = getToolFromAnnotation(annotation);
    const { targets, editor } = tool;
    const { resize, drag } = editor;
    let custom = onPlayer && (drag || resize);
    if (custom && targets.length > 1 && optionDown) custom = false;

    if (editor.noInteractions || this.isAnnotationHidden()) return null;

    const customDraggable = (
      <CustomDraggable
        {...this.position}
        classes='hy-draggable-annotation-overlay'
        key={id}
        id={id}
        onDoubleClick={edit}
        type={type}
        allowedZones={targets}
        dropZone={dropZone}
        snapRatio={snapRatio}
        resize={resize}
        drag={drag}
        setActive={this.setActive}
        active={active}
        select={select}
        selected={selected}
        onDragEnd={this.onDragEnd}
        dragStarted={dragStarted}
      />
    );

    const draggable = (
      <Draggable
        {...this.position}
        classes='hy-draggable-annotation-overlay'
        key={id}
        id={id}
        type={type}
        onDoubleClick={edit}
        allowedZones={targets}
        dropZone={dropZone}
        setActive={this.setActive}
        active={active}
        indicate={optionDown}
        select={select}
        selected={selected}
        onDragEnd={this.onDragEnd}
        onDragStart={this.draggingBetweenTargets.bind(this, targets)}
      >
        <div className='hy-draggable-placeholder' />
      </Draggable>
    );

    return custom ? customDraggable : draggable;
  }
}

export { DraggableAnnotation };
