import React from 'react';

import { Input, Icon } from '../index';

const DEFAULT_HEIGHT = 30;
const SMALL_HEIGHT = 20;

const DEFAULT_WIDTH = 150;
const SMALL_WIDTH = 100;

type SearchBoxProps = {
  float: any;
  innerInputStyle?: React.CSSProperties;
  search: (e: any) => void;
  small?: boolean;
  style?: React.CSSProperties;
  total: number | boolean;
  type: string;
  value: string;
  visible: number | boolean;
};

export class SearchBox extends React.Component<SearchBoxProps> {
  get wrapperStyle (): React.CSSProperties {
    const { small, float } = this.props;
    return {
      float,
      position: 'relative',
      display: 'inline-block',
      width: small ? SMALL_WIDTH : DEFAULT_WIDTH,
      height: '100%',
      fontSize: small ? '0.8em' : '1em',
    };
  }

  get style (): React.CSSProperties {
    const { style = {} as React.CSSProperties } = this.props;
    return {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '100%',
      height: this.height,
      ...style,
    };
  }

  get outerInputStyle (): React.CSSProperties {
    const { small, float } = this.props;
    return {
      float,
      fontSize: small ? '1em' : '0.9em',
      width: '100%',
      height: this.height,
      position: 'absolute',
      top: 0,
      left: 0,
    };
  }

  get innerInputStyle (): React.CSSProperties {
    const { innerInputStyle } = this.props;
    return {
      width: '100%',
      height: this.height,
      border: 'none',
      paddingLeft: this.height,
      ...innerInputStyle,
    };
  }

  get iconStyle (): React.CSSProperties {
    return {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: 0,
      color: this.color,
      boxShadow: 'none',
    };
  }

  get height () {
    const { small } = this.props;
    return (small ? SMALL_HEIGHT : DEFAULT_HEIGHT) + 'px';
  }

  get color () {
    const { total, value, visible } = this.props;
    return total && !visible ? '#de6767' : value ? '#deb145' : '#aaa';
  }

  render () {
    const { type, search, total } = this.props;
    const placeholder = total ? 'Search' : `No ${type}`;

    return (
      <div style={this.wrapperStyle}>
        <div style={this.style}>
          <Input style={this.outerInputStyle} placeholder={placeholder} onChange={search} disabled={!total}>
            <input style={this.innerInputStyle} />
          </Input>
          <Icon name='search' circular style={this.iconStyle} disabled={!total} />
        </div>
      </div>
    );
  }
}
