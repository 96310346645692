import React from 'react';

import { Checkbox } from '@newsela/angelou';

import { PackagingOptions, PackagingOptionsProps } from './PackagingOptions';

export class LandingPageToggle extends PackagingOptions<PackagingOptionsProps> {
  render () {
    const { hidden = false } = this.props;
    const { includeLandingPage } = this.bundleProps;

    return hidden ? null : (
      <Checkbox
        label='Include Landing Page'
        onChange={this.handleUpdate.bind(this, { key: 'includeLandingPage', checked: includeLandingPage })}
        checked={includeLandingPage}
      />
    );
  }
}
