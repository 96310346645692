import './index.scss';
import { LocalizationServices } from './LocalizationServices';
import { PackagingEditor } from './PackagingEditor';
import { SetupEditor } from './SetupEditor';
import { StoryboardEditor } from './StoryboardEditor';
import { UXEditor } from './UXEditor';

export default {
  LocalizationServices,
  PackagingEditor,
  SetupEditor,
  StoryboardEditor,
  UXEditor,
};
