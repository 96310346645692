const onClickToLink = {
  id: '',
  event: 'onClick',
  action: 'toLink',
  args: {
    target: 'new_tab',
  },
};

const onClickToTime = {
  id: '',
  event: 'onClick',
  action: 'toTime',
  args: {
    value: 0,
  },
};

const onSubmitSendData = {
  id: '',
  event: 'onSubmit',
  action: 'sendData',
  args: {
    method: 'POST',
  },
};

const onHoverPauseVideo = {
  id: '',
  event: 'onHover',
  action: 'togglePlayback',
  args: {},
};

export const presetActionsByType = {
  navigation: [onClickToTime],
  link: [onClickToLink],
  overlay: [],
  form: [onSubmitSendData],
  showable: [onHoverPauseVideo],
  defaults: [],
};
