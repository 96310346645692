import React from 'react';

import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';

import App from './App';
import { ErrorBoundary } from './components/ErrorBoundary';
import { KeyboardShortcuts } from './components/KeyboardShortcuts';
import { initializeDatadog } from './datadogRum';
import sessionClient from './services/sessionClient';

sessionClient.captureCsrfToken();
initializeDatadog();

const browserAttrs = {
  className: 'App',
};

if (process.env.REACT_APP_SUB_PATH) {
  (browserAttrs as any).basename = process.env.REACT_APP_SUB_PATH;
}

ReactDOM.render(
  <ErrorBoundary>
    <KeyboardShortcuts>
      <BrowserRouter {...browserAttrs}>
        <Route path='/:projectId?' component={App} />
      </BrowserRouter>
    </KeyboardShortcuts>
  </ErrorBoundary>,
  document.getElementById('root')
);
