import './index.scss';

import React from 'react';

import _ from 'lodash';
import moment from 'moment';

import DEFAULTS from '../../../DEFAULTS.json';
import {
  Grid,
  Image,
} from '../../../hapyak-ui-toolkit';
import { FileBrowser } from '../../../hapyak-ui-toolkit/FileBrowser/FileBrowser';
import defaultImage from '../../../img/default-image.png';
import { persistenceClientFactory } from '../../../services/persistence/persistenceClientFactory';
import signingService from '../../../services/signingService';
import { AngelouLoadingSpinner } from '../AngelouComponents/AngelouLoadingSpinner';
import ErrorBanner from '../AngelouComponents/ErrorBanner';
import WarningBanner from '../AngelouComponents/WarningBanner';
import { ItemOrganizer } from '../CommonComponents/AssetList/ItemOrganizer';
import { ItemPreview, CardContentTextAlign } from '../CommonComponents/AssetList/ItemPreview';
import { BasicSection } from '../CommonComponents/Menu/BasicSection';
import { NewProjectFlow } from './NewProjectFlow';

const CARD_WIDTH = { width: '78%', margin: 'auto' };
const CARD_STYLES = { color: '#000' };
const CARD_DEFAULTS = {
  cardClassName: '',
  cardContentStyles: { ...CARD_WIDTH, whiteSpace: 'nowrap', overflow: 'hidden' },
  cardContentTextAlign: 'center' as CardContentTextAlign,
  cardHeight: 136,
  cardWidth: 175,
  systemDescription: false,
};

const DEFAULT_VALIDATION_ERROR = 'Either the project does not exist, the format is incorrect or you do not have permission to access it.';

type State = any;

type ProjectStarterProps = {
  beforeUpload?: (props: ProjectStarterProps) => void;
  groupId: string;
  history: any;
  location: Location;
  rowStyle?: React.CSSProperties;
  session?: any;
  showRecent?: boolean;
};

class ProjectStarter extends React.Component<ProjectStarterProps, State> {
  persistenceClient: any;
  constructor (props: ProjectStarterProps) {
    super(props);

    this.state = {
      firstLoad: true,
      loading: true,
      validationError: this.handleQueryStringError(props),
      projects: null,
      importableProject: null,
      selectedProjects: [],
      projectList: []
    };

    this.persistenceClient = persistenceClientFactory.getClient();
  }

  async componentDidMount () {
    await Promise.all([
      this.fetchRecentProjects(),
      this.fetchProjects()
    ]);
  }

  handleQueryStringError = (props: any) => {
    const params = new URLSearchParams(props.location.search.replace('?', ''));
    const hasValidationError = params.has('validationError');
    if (hasValidationError) {
      params.delete('validationError');
    }
    props.history.replace({ search: params.toString() });
    return hasValidationError ? DEFAULT_VALIDATION_ERROR : null;
  };

  fetchRecentProjects = async () => {
    return new Promise((resolve) => {
      this.setState({ loading: true }, async () => {
        const projects = await this.persistenceClient.list();
        // @ts-expect-error TS(2345): Argument of type '(value: unknown) => void' is not... Remove this comment to see the full error message
        this.setState({ projects, loading: false, firstLoad: false }, resolve);
      });
    });
  };

  get recentProjects () {
    const { projects } = this.state;
    const { isLocal } = this.persistenceClient;

    if (isLocal) {
      const summary = this.getLocalProjectSummary(projects[0]);
      return summary ? [this.getItemPreview(summary, 'local')] : [];
    }

    if (!projects) return [];

    // projects can contain null items if migration failed on any items
    return projects.filter(Boolean).map((summary: any, idx: any) => {
      return this.getItemPreview(summary, idx);
    });
  }

  getItemPreview = (summary: any, idx: any) => {
    const { posterImage, id, name, modified } = summary;
    const src = signingService.sign(posterImage || defaultImage);
    const previewDisplay = (
      <Image style={{ height: '85px', width: 'auto', margin: 'auto' }} size='tiny' src={src} />
    );
    const subDescription = `${moment(modified).format('M/D/YY k:mm')}`; // k is hr/24
    const description = name ? name.slice(0, 25) + (name.length > 25 ? '...' : '') : '';
    const descriptStyle = { width: '100%', lineHeight: '16px', overflow: 'hidden', textOverflow: 'ellipsis' };

    return (
      <ItemPreview
        previewDisplay={previewDisplay}
        cardDescription={description}
        cardDescriptionStyle={descriptStyle}
        cardMeta={subDescription}
        previewStyle={{ ...CARD_WIDTH, paddingTop: '9px', height: 100 }}
        item={summary}
        key={idx}
        handleClick={this.persistenceClient.openProject.bind(this, id)}
        cardStyles={CARD_STYLES}
        {...CARD_DEFAULTS}
      />
    );
  };

  getLocalProjectSummary = (data: any) => {
    const { id, created, modified, posterImage, title } = _.get(data, 'project', {});

    if (!id) return null;

    return {
      id,
      modified: modified || created,
      name: title,
      posterImage: this.getImageAsset(data, posterImage).src,
    };
  };

  // can't use assetService.getImage since projects aren't loaded yet
  getImageAsset = (data: any, id: any) => {
    return _.get(data, `assets.images.${id}`, {});
  };

  get validationErrorMessage () {
    const { validationError } = this.state;
    if (!validationError) return null;

    return (
      <ErrorBanner
        title='Error: Cannot load project'
        body={validationError}
        isDismissible
        onClose={this.dismissValidationError}
      />
    );
  }

  dismissValidationError = () => this.setState({ validationError: null });

  get rowStyle () {
    return { width: '70%', margin: 'auto', textAlign: 'left' };
  }

  get itemOrganizer () {
    const recentProjects = [...this.recentProjects];

    return (
      <ItemOrganizer
        itemOrganizerClassName=''
        items={recentProjects}
        itemType='Recents'
        filter='cards'
        enableSearch
        enableExpandedView
        enableMinimization
        startCompact={false}
        disableMenuOptions
      />
    );
  }

  get recentProjectsSection () {
    return (
      <div style={this.rowStyle as React.CSSProperties}>
        {this.validationErrorMessage}
        <BasicSection
          sectionPadding={false}
          renderedContent={[
            {
              title: (
                <div>
                  <div>Recent Projects</div>
                </div>
              ),
              renderedContent: this.itemOrganizer,
            },
          ]}
        />
      </div>
    );
  }

  // set 'loading' before running 'fn', run 'fetchRecentProjects' after running 'fn'
  load = (fn: any) => {
    return (...args: any[]) => {
      return new Promise<void>(async (resolve) => {
        this.setState({ loading: true }, async () => {
          await fn(...args); // run actual function
          await this.fetchProjects(true);
          resolve();
        });
      });
    };
  };

  fetchProjects = (changeMade = false) => {
    return new Promise<void>(async (resolve) => {
      const projectList = await this.persistenceClient.fetchAll();
      if (changeMade && typeof this.fetchProjects === 'function') this.fetchProjects();
      this.setState({ projectList, loading: false }, resolve);
    });
  };

  selectProjects = (id: any, e: any) => {
    e && e.stopPropagation && e.stopPropagation();
    if (id) {
      let selectedProjects = this.state.selectedProjects;
      if (selectedProjects.includes(id)) {
        selectedProjects = selectedProjects.filter((item: any) => {
          return item !== id;
        });
      } else {
        selectedProjects.push(id);
      }
      this.setState({ selectedProjects });
    }
  };

  get projectsRowStyle () {
    return {
      textAlign: 'left',
      width: '90%',
      maxWidth: '90%',
      margin: 'auto',
    };
  }

  get fileBrowserStyle () {
    return {
      maxHeight: '70vh',
      overflow: 'scroll',
      border: '0 solid #ccc',
      borderTopWidth: '1px',
      paddingTop: '20px',
      width: '100%'
    };
  }

  render () {
    const { showRecent = true } = this.props;
    const { firstLoad, loading, projectList, selectedProjects } = this.state;

    if (firstLoad && loading) return <AngelouLoadingSpinner />;

    return (
      <Grid>
        <Grid.Row className='get-started'>
          <div style={this.rowStyle as React.CSSProperties}>
            <p>GET STARTED</p>
            <NewProjectFlow />
          </div>
        </Grid.Row>
        {showRecent && (
          <Grid.Row>
            {this.recentProjectsSection}
            {loading && <AngelouLoadingSpinner />}
          </Grid.Row>
        )}
        <Grid.Row centered style={this.rowStyle}>
          {this.persistenceClient.isLocal && (
            <WarningBanner
              body={`During the BETA period you must export all projects before leaving the application, then
                import them for further editing. You can download your project by clicking the export button which will appear in the
                top-right of the header once you start a project.`}
            />
          )}
        </Grid.Row>
        <Grid.Row id='projects' style={this.projectsRowStyle}>
          <div style={this.fileBrowserStyle}>
            <FileBrowser
              items={projectList}
              selected={selectedProjects}
              createFolder={this.load(this.persistenceClient.createFolder)}
              handleCopy={this.load(this.persistenceClient.handleCopy)}
              handleDelete={this.load(this.persistenceClient.handleDelete)}
              handleMove={this.load(this.persistenceClient.handleMove)}
              handleOpen={this.persistenceClient.openProject}
              handleRename={this.load(this.persistenceClient.handleRename)}
              select={this.selectProjects}
              prefKey={`${DEFAULTS.FILE_BROWSER_PREFS_KEY}_${this.props.groupId}`}
            />
          </div>
        </Grid.Row>
      </Grid>
    );
  }
}

export { ProjectStarter };
