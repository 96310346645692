import React from 'react';

import { formatPlayerFilePath, getDest, prepareImageAsset } from '../../../../services/packagingService';
import { assetService } from '../../../../services/stateController';
import { BaseEmbed } from './BaseEmbed';

export class LightboxEmbed extends BaseEmbed {
  _render = (includeScript: boolean): JSX.Element => {
    const { baseUrl, thumbnail } = this.definition;
    const thumbnailAsset = assetService.getImage(thumbnail);

    const src = `${baseUrl}config.json`;
    const helper = formatPlayerFilePath(baseUrl, 'embed.js');
    const thumbnailSrc = thumbnailAsset.src && getDest(prepareImageAsset(baseUrl.slice(0, -1), thumbnailAsset));

    const content = thumbnailSrc ? <img src={thumbnailSrc} alt='thumbnail' /> : <button>Play</button>;

    return (
      <div className='hapyak-player-embed'>
        {includeScript && <script src={helper} />}
        {/* @ts-expect-error TS(2339): Property 'hapyak-embed' does not exist on type 'JS... Remove this comment to see the full error message */}
        <hapyak-embed src={src}>{content}</hapyak-embed>
      </div>
    );
  };
}
