import {
  findAssociatedQuizAnnotation,
  QUIZ_TOOL_TYPES,
} from '../../components/editors/UXEditor/AnnotationEditor/QuizBuilder/quizBuilderUtils';
import { toolsWhiteList } from '../../tools/toolsWhiteList';
import { Annotation, AnnotationProperties } from '../../types/annotations';

const deleteProperty = (a: any, property: any) => {
  const _a = { ...a };
  delete _a[property];
  return _a;
};

const addProperty = (a: any, property: any, value: any) => {
  return {
    ...a,
    [property]: value,
  };
};

const deleteCollectionsProperty = (a: any) => {
  return { ...deleteProperty(a, 'collections') };
};

const setToolType = (a: any, type: any) => {
  const isValidType = toolsWhiteList.includes(type);
  return isValidType ? { ...a, toolType: type } : a;
};

const setIntentProperty = (a: any, str: any) => (!str ? a : { ...a, intent: str });

const setLowercaseType = (str: any) => str && str.toLowerCase();

const findAndReplaceQuizString = (str: any) => str ? str.replace('quiz', '') : '';

export const hasFinalMessageScreen = (annotation: Annotation) => {
  if (annotation) {
    const { properties } = annotation;
    const { showFinalMessageScreen } = properties;
    return !!showFinalMessageScreen;
  }
};

const isNeededForFinalScreen = (annotation: Annotation) => {
  const associatedQuiz = findAssociatedQuizAnnotation(annotation.id);
  return associatedQuiz ? hasFinalMessageScreen(associatedQuiz) : false;
};

const permitsRetries = ({ quiz = {} as Annotation }) => {
  const { properties = {} as AnnotationProperties } = quiz;
  const { allowRetries } = properties;
  return !!allowRetries;
};

const quizIsEvaluated = (quiz: Annotation) => {
  if (quiz) {
    const { properties } = quiz;
    const { evaluateQuiz } = properties;
    return !!evaluateQuiz;
  }
};

const assessQuizSetup = (annotation: any) => {
  const associatedQuiz = QUIZ_TOOL_TYPES.includes(annotation.type)
    ? annotation
    : findAssociatedQuizAnnotation(annotation.id);
  const retriesEnabled = permitsRetries({ quiz: associatedQuiz });
  const evaluateQuiz = quizIsEvaluated(associatedQuiz);
  const hasFinalScreen = hasFinalMessageScreen(associatedQuiz);
  return { retriesEnabled, evaluateQuiz, hasFinalScreen, quizAnnotation: associatedQuiz };
};

export default {
  deleteProperty,
  addProperty,
  deleteCollectionsProperty,
  setToolType,
  setIntentProperty,
  setLowercaseType,
  findAndReplaceQuizString,
  hasFinalMessageScreen,
  isNeededForFinalScreen,
  assessQuizSetup,
};
