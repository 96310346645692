import React from 'react';

import './index.scss';
import { Icon, Label } from '../../hapyak-ui-toolkit';
import { comm } from '../../services/comm';
import { ChildrenProps } from '../../types/utils';

export const LAST_SAVED_MESSAGE_UPDATE_EVENT = 'updateLastSavedMessage';

export const STATUSES = {
  ERROR: 'ERROR',
  RETRYING: 'RETRYING',
  SAVING: 'SAVING',
  SAVED: 'SAVED',
};

export const STATUS_MESSAGES = {
  ERROR: 'Error saving',
  RETRYING: 'Retrying save',
  SAVING: 'Saving changes',
  SAVED: 'All changes saved',
};

export const ICONS = {
  ERROR: 'exclamation triangle',
  RETRYING: 'sync alternate',
  SAVING: 'sync alternate',
  SAVED: '',
};

export const COLORS = {
  ERROR: 'red',
  RETRYING: 'yellow',
  SAVING: 'blue',
  SAVED: 'grey',
};

type State = any;

export class LastSavedMessage extends React.Component<ChildrenProps, State> {
  constructor (props: ChildrenProps) {
    super(props);

    this.state = { status: STATUSES.SAVED };
  }

  onUnload = (event: any) => {
    if (this.isSaved) return;
    event.preventDefault();
    const message = 'Do you want to continue? Your changes may be lost.';
    event.returnValue = message; // Chrome requires returnValue to be set.
    return message;
  };

  componentDidMount () {
    window.addEventListener('beforeunload', this.onUnload);
    comm.register('updateLastSavedMessage', this.updateLastSavedMessage);
  }

  componentWillUnmount () {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  updateLastSavedMessage = (status: any) => {
    this.setState({ status });
  };

  get isSaved () {
    return this.state.status === STATUSES.SAVED;
  }

  get style () {
    return {
      border: this.isSaved ? 'none' : '',
    };
  }

  render () {
    const { status } = this.state;
    if (!status) return null;

    return (

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      <Label basic className='hy-state-dirty-message' color={COLORS[status]} style={this.style}>
        {/* @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
        {!this.isSaved && <Icon name={ICONS[status]} />}
        {/* @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
        {STATUS_MESSAGES[status]}
      </Label>
    );
  }
}
