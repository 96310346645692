import './ExternalLinksTable.scss';

import React, { useEffect, useState } from 'react';

import { Button, CopySVG, ExternalLinkSVG, Icon, TooltipDialog } from '@newsela/angelou';

import { AngelouInputReadOnly } from '../AngelouComponents/AngelouInputReadOnly';
import { tooltipDialogBackgroundGreyExtraLight500, tooltipDialogBackgroundWhite500 } from '../AngelouComponents/helpers/const';

type ExternalLinksTableProps = {
  creatingProject?: boolean;
  refId?: string;
  projectId: string;
}

type ExternalLink = {
  label: string;
  value: string;
  open: boolean;
  disabled?: boolean;
}

type ExternalLinks = {
  [key: string]: ExternalLink;
}

export const ExternalLinksTable = ({
  refId,
  projectId,
  creatingProject = false
}: ExternalLinksTableProps) => {
  const url = 'https://' + process.env.REACT_APP_HOST + '/' + projectId;
  const [externalLinks, setExternalLinks] = useState<ExternalLinks>({});

  useEffect(() => {
    if (refId) {
      setExternalLinks({
        studioLink: {
          label: 'Studio',
          value: `${url}/ux`,
          open: false,
        },
        alexandriaLink: {
          label: 'Alexandria',
          value: process.env.REACT_APP_ALEXANDRIA_BASE_URL + refId,
          open: true
        },
        viewPageLink: {
          label: 'View page',
          value: process.env.REACT_APP_VIEW_PAGE_BASE_URL + refId,
          open: true
        }
      });
    } else {
      setExternalLinks({
        studioLink: {
          label: 'Studio',
          value: `${url}/ux`,
          open: false,
        },
        alexandriaLink: {
          label: 'Alexandria',
          value: 'Link not available. Publish this project to access this link.',
          open: true,
          disabled: true
        },
        viewPageLink: {
          label: 'View page',
          value: 'Link not available. Publish this project to access this link.',
          open: true,
          disabled: true
        }
      });
    }
  }, [refId, projectId]);

  const externalLinkRows = Object.keys(externalLinks).map((key, idx) =>
    <div className='external-links-table-row' key={idx}>
      <AngelouInputReadOnly
        label={externalLinks[key].label}
        value={externalLinks[key].value}
      />
      <div className='external-links-table-buttons'>
        <TooltipDialog
          __cssFor={{
            dialog: {
              root: creatingProject ? tooltipDialogBackgroundGreyExtraLight500 : tooltipDialogBackgroundWhite500
            }
          }}
          buttonContents='Copy'
          buttonProps={{
            legacy_statusColor: Button.legacy_statusColor.primary,
            legacy_flavor: Button.legacy_flavor.solid,
            onClick: () => navigator.clipboard.writeText(externalLinks[key].value),
            legacy_size: Button.legacy_size.medium,
            disabled: externalLinks[key].disabled
          }}
          body={
            <div>
              <Icon SvgComponent={CopySVG} />
              Link has been copied to clipboard.
            </div>
          }
        />
        {
          externalLinks[key].open &&
            <Button
              icon={{
                SvgComponent: ExternalLinkSVG,
                alignment: Button.iconAlignments.left
              }}
              legacy_flavor={Button.legacy_flavor.solid}
              legacy_statusColor={Button.legacy_statusColor.primary}
              legacy_size={Button.legacy_size.medium}
              disabled={externalLinks[key].disabled}
              onClick={() => { window.open(externalLinks[key].value, '_blank'); }}
            >
              Open
            </Button>
        }
      </div>
    </div>
  );

  return (
    (
      Object.keys(externalLinks).length > 0 &&
        <div className='external-links-table'>
          {creatingProject ? externalLinkRows[0] : externalLinkRows}
        </div>) ||
          <></>
  );
};
