import React from 'react';

import { Embed } from 'types/publishing';

import { formatCssFilePath } from '../../../../services/packagingService';

type BaseEmbedDefinition = {
  embed: Embed;
  baseUrl: string;
  preview: any;
  aspectRatio: any;
  thumbnail: string;
}

type BaseEmbedProps = {
  definition: BaseEmbedDefinition;
  render?: (includeScript: boolean) => JSX.Element;
  _render?: (includeScript: boolean) => JSX.Element;
  getStyleSheet: () => JSX.Element;
  getMetaDataContent: () => JSX.Element;
}

export abstract class BaseEmbed implements BaseEmbedProps {
  definition: BaseEmbedDefinition;
  constructor (definition: BaseEmbedDefinition) {
    this.definition = definition;
  }

  getStyleSheet = () => {
    const { baseUrl } = this.definition;
    const href = formatCssFilePath(baseUrl, 'embed.css');
    return <link rel='stylesheet' type='text/css' href={href} />;
  };

  getMetaDataContent = () => {
    const { embed } = this.definition;
    const { showTitle, showDescription, title, description, embedType } = embed;
    const forgoMetaData = ![showTitle, showDescription].some(Boolean) || embedType !== 'iframe';

    return forgoMetaData ? <></> : (
      <div className='hy-embed-info'>
        {showTitle && title && <div>{title}</div>}
        {showDescription && description && <div>{description}</div>}
      </div>
    );
  };

  render = (includeScript: boolean): JSX.Element => {
    const { embed } = this.definition;
    const { embedType = '', layoutType, height, width, dimensions } = embed;
    const className = `hy-embed-container hy-layout-${layoutType} hy-embed-type-${embedType}`;
    const below = layoutType === 'below';
    const isFixed = dimensions === 'fixed';
    const excludeExtraScripts = ['customTag'].includes(embedType);
    const mainScript = this._render(includeScript);
    const responsiveEmbed = (
      <div className={className}>
        {!below && this.getMetaDataContent()}
        {mainScript}
        {below && this.getMetaDataContent()}
      </div>
    );

    if (excludeExtraScripts) return mainScript;

    return (
      <>
        {this.getStyleSheet()}
        {isFixed && (
          <div style={{ width: `${width}px`, height: `${height}px`, overflow: 'hidden' }}>
            {responsiveEmbed}
          </div>
        )}
        {!isFixed && responsiveEmbed}
      </>
    );
  };

  abstract _render: (includeScript: boolean) => JSX.Element;
}
