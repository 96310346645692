import React from 'react';
import './CaptionsList.scss';

import { Button, Icon, PlusSVG } from '@newsela/angelou';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import defaults from '../../../DEFAULTS.json';
import { Form, Popup, Table, TextArea } from '../../../hapyak-ui-toolkit';
import { comm } from '../../../services/comm';
import { media } from '../../../services/mediaController';
import { TimeInput } from '../../TimeInput/TimeInput';
import { TrashButton } from '../AngelouComponents/TrashButton';
const { MIN_ANNOTATION_DURATION } = defaults;

type State = any;

type Cue = {
  identifier: string;
  start: number;
  end: number;
  position: string;
  text: string;
  translatedText: string;
  styles: string;
  [key: string]: string | number;
};

type CaptionsListProps = {
  cues: Cue[];
  duration: number;
  hideAdditionalAction: boolean;
  hideTrash?: boolean;
  isEditingTranslation?: boolean;
  save: boolean;
  showTranslation: boolean;
  updateCues: (cues: Cue[], bool: boolean) => Promise<void>;
};

class CaptionsList extends React.Component<CaptionsListProps, State> {
  table: any;
  constructor (props: CaptionsListProps) {
    super(props);

    this.state = {
      column: null,
      direction: null,
      _cues: [],
      focusRow: '',
      textHeight: 'auto',
    };
  }

  getCue = (identifier: string) => {
    const cues = [...this.props.cues];
    return cues.find((cue) => cue.identifier === identifier);
  };

  JumpToTime = (identifier: string) => {
    const cue = this.getCue(identifier);
    if (cue) {
      comm.trigger('setCurrentTime', cue.start + MIN_ANNOTATION_DURATION / 2, true); // half of min duration to insure the content is visible after seek
    }
  };

  addCue = () => {
    const cues = [...this.props.cues];
    const identifier = uuid();

    cues.push({
      identifier,
      text: '',
      translatedText: '',
      styles: '',
      position: 'bottom',
      start: 0,
      end: 3,
    });

    this.props.updateCues(cues, true).then(() => {
      this.JumpToTime(identifier);
    });
  };

  persistTime = (identifier: string | undefined, key: string | undefined, time: number) => {
    const cues = [...this.props.cues];
    const cue = identifier ? this.getCue(identifier) : null;

    if (cue && key) {
      cue[key] = time;
    }

    this.props.updateCues(cues, true);
  };

  deleteCue = (identifier: string) => {
    const cues = this.props.cues.filter((cue: Cue) => cue.identifier !== identifier);
    this.props.updateCues(cues, true);
  };

  createVttRow = (cue: Cue) => {
    const { isEditingTranslation } = this.props;
    const identifier = cue.identifier;
    const disabledMessage = 'Select native video language to edit disabled fields.';
    const { focusRow, textHeight } = this.state;

    const row = (
      <Table.Row
        key={identifier}
        style={{ cursor: 'pointer' }}
        onClick={this.JumpToTime.bind(this, identifier)}
      >
        <Table.Cell className='caption-time-start-row' data-label='Start time' scope='row'>
          <TimeInput
            time={cue.start}
            min={0}
            max={cue.end - MIN_ANNOTATION_DURATION}
            type='start'
            identifier={identifier}
            persistTime={this.persistTime}
          />
        </Table.Cell>
        <Table.Cell className='caption-time-end-row' data-label='End time'>
          <TimeInput
            time={cue.end}
            min={cue.start + MIN_ANNOTATION_DURATION}
            max={media.duration}
            type='end'
            identifier={identifier}
            persistTime={this.persistTime}
          />
        </Table.Cell>
        <Table.Cell className='caption-main-text-row' data-label='Text'>
          <Form className='hy-inline-form'>
            <Form.Field>
              <TextArea rows={2} value={cue.text} onFocus={this.onFocus.bind(this, identifier)} onChange={this.onChange.bind(this, identifier, 'text')} onBlur={this.onBlur.bind(this, identifier, 'text')} disabled={isEditingTranslation} style={focusRow === identifier ? { height: textHeight } : { height: 'auto' }} />
            </Form.Field>
          </Form>
        </Table.Cell>
        <Table.Cell className='caption-alt-text-row' data-label='Alt text'>
          <Form className='hy-inline-form'>
            <Form.Field>
              <TextArea rows={2} value={cue.translatedText} onFocus={this.onFocus.bind(this, identifier)} onChange={this.onChange.bind(this, identifier, 'translatedText')} onBlur={this.onBlur.bind(this, identifier, 'translatedText')} disabled={!isEditingTranslation} style={focusRow === identifier ? { height: textHeight } : { height: 'auto' }} />
            </Form.Field>
          </Form>
        </Table.Cell>
        {this.props.hideTrash ? null : (
          <Table.Cell className='delete-caption-row-button-container' data-label='Delete item'>
            <TrashButton
              disabled={isEditingTranslation}
              handleClick={this.deleteCue.bind(this, identifier)}
            />
          </Table.Cell>
        )}
      </Table.Row>
    );

    const popup = this.itemAsTrigger(identifier, row, disabledMessage);

    return isEditingTranslation ? popup : row;
  };

  itemAsTrigger = (identifier: string, item: any, disabledMessage = '') => {
    return <Popup key={identifier} content={disabledMessage} trigger={item} />;
  };

  handleSort = (clickedColumn: any) => () => {
    const { cues: data } = this.props;
    const { column, direction } = this.state;
    const reverseDirection = direction === 'ascending' ? 'descending' : 'ascending';

    this.setState({
      column: clickedColumn,
      data: column === clickedColumn ? data.reverse() : _.sortBy(data, [clickedColumn]),
      direction: column === clickedColumn ? reverseDirection : 'ascending',
    });
  };

  get cues () {
    const { cues } = this.props;
    const { _cues } = this.state;
    return _cues.length ? _cues : cues;
  }

  onBlur = (identifier: string, key: string, e: any) => {
    const val = e.target.value;
    this.updateCue(identifier, key, val, true);
    this.setState({ focusRow: '', textHeight: 'auto' });
  };

  onChange = (identifier: string, key: string, e: any) => {
    const val = e.target.value;
    this.updateCue(identifier, key, val, false);
    this.onFocus(identifier, e);
  };

  onFocus = (identifier: string, e: any) => {
    const height = e.target.scrollHeight;
    if (height !== this.state.scrollHeight) {
      this.setState({ focusRow: identifier, textHeight: `${height}px` });
    }
  };

  setCues = (cues: Cue[]) => {
    this.setState({ _cues: [] }, () => {
      this.props.updateCues(cues, false);
    });
  };

  updateCue = (identifier: string, key: string, val: string | number, onBlur: any) => {
    const cues = [...this.props.cues];
    const cue = this.getCue(identifier);
    if (cue) {
      cue[key] = val;
    }
    if (!onBlur) {
      this.setState({ _cues: cues });
    } else {
      this.setCues(cues);
    }
  };

  get addCaptionButton () {
    const { isEditingTranslation } = this.props;
    const disabledMessage = 'Select native video language to add a new caption.';
    const addRowButton = (
      <Table.Row>
        <Table.HeaderCell colSpan='16'>
          <Button
            legacy_flavor={Button.legacy_flavor.solid}
            __classNameFor={{ root: 'internal-tools-button add-caption-row-button' }}
            __cssFor={{ root: { display: 'flex' } }}
            onClick={this.addCue}
            disabled={isEditingTranslation}
          >
            <Icon
              SvgComponent={PlusSVG}
              isDecorative
              __cssFor={
                {
                  root: {
                    path: {
                      strokeWidth: '4'
                    }
                  }
                }
              }
            />
            Add row
          </Button>
        </Table.HeaderCell>
      </Table.Row>
    );

    return isEditingTranslation ? this.itemAsTrigger('add-row', addRowButton, disabledMessage) : addRowButton;
  }

  render () {
    const { showTranslation, hideTrash } = this.props;
    const vttRows = this.cues.map((cue: Cue) => this.createVttRow(cue));

    const { column, direction } = this.state;

    return (
      <div ref={this.table}>
        <Table
          summary='Accessibility text editing table'
          celled
          compact
          color='blue'
          textAlign='center'
          sortable
        >
          <Table.Header className='captions-editor-table-header'>
            <Table.Row>
              <Table.HeaderCell
                scope='col'
                header-label='start time'
                width={2}
                sorted={column === 'start' ? direction : null}
                onClick={this.handleSort('start')}
              >
                Start time
              </Table.HeaderCell>
              <Table.HeaderCell scope='col' header-label='end time' width={2}>End time</Table.HeaderCell>
              <Table.HeaderCell scope='col' header-label='text' width={5} textAlign='left'>Text</Table.HeaderCell>
              {showTranslation && <Table.HeaderCell scope='col' header-label='alt text' width={5} textAlign='left'>Alt text</Table.HeaderCell>}
              {hideTrash ? null : <Table.HeaderCell scope='col' width={1} />}
            </Table.Row>
          </Table.Header>

          <Table.Body>{vttRows}</Table.Body>

          <Table.Footer fullWidth>{this.addCaptionButton}</Table.Footer>
        </Table>
      </div>
    );
  }
}

export { CaptionsList };
