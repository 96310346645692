import React, { Component } from 'react';

import { SimpleCalculator } from './SimpleCalculator';

type PublishSummaryProps = {
  loading: boolean;
  selectedActions: any;
}

export class PublishSummary extends Component<PublishSummaryProps> {
  get calculatedSummary () {
    const { selectedActions } = this.props;

    return {
      title: '',
      unitFormat: 'time',
      checkable: false,
      columnHeaders: ['Action'],
      totalDescription: 'Actions selected',
      sections: selectedActions,
    };
  }

  render () {
    const { loading = false } = this.props;
    // @ts-expect-error TS(2769): No overload matches this call.
    return <SimpleCalculator data={this.calculatedSummary} additionalComponents={false} loading={loading} />;
  }
}
