import _ from 'lodash';

import DEFAULTS from '../DEFAULTS.json';
import { comm } from './comm';
import logger from './logger';
import { removeProcessSettings, setProcessSettings } from './saveUtils';
import signingService from './signingService';
import visService from './visService';

const processRouteMap = {
  [DEFAULTS.PACKAGING_KEY]: ['/publishing'],
  [DEFAULTS.PDF_KEY]: ['/storyboard'],
  [DEFAULTS.UPLOAD_KEY]: ['/ux'],
  [DEFAULTS.TRANSCODE_KEY]: ['/setup', '/ux'],
};

export const processDisplayMap = {
  [DEFAULTS.PACKAGING_KEY]: 'Packaging',
  [DEFAULTS.PDF_KEY]: 'Creating PDF',
  [DEFAULTS.TRANSCODE_KEY]: 'Transcoding',
  [DEFAULTS.TRANSCODE_REMOTE_UPLOAD_KEY]: 'TranscodeRemoteUpload',
};

const VIDEO_PROCESSES = [DEFAULTS.TRANSCODE_KEY, DEFAULTS.TRANSCODE_REMOTE_UPLOAD_KEY];

const getActiveProcesses = (processing: any) => {
  return _.uniq(
    Object.keys(processing)
      .map((route) => {
        const process = processing[route];
        return process && process.status === DEFAULTS.FETCHING_STATUS && process.name;
      })
      .filter(Boolean)
      .concat(visService.activePlatformProcesses)
  );
};

export const getActiveVideoProcesses = (processing: any) => {
  return _.intersection(getActiveProcesses(processing), VIDEO_PROCESSES).map((name) => processDisplayMap[name]);
};

const getErrorProps = (process: any) => {
  return {
    header: `An error occurred during the ${process} process.`,
    list: ['Please try again.'],
  };
};

export class ProcessRunner {
  constructor (process: any, payload: any, callback: any, settings: any) {
    this.process = process;

    payload.result = (response: any) => {
      logger.log(`VIS RESPONSE: (${this.sessionId})`, response);
      this._stop();
      const error = payload.cmds.some((cmd: any) => this.checkFailure(response[cmd]));
      if (error) ProcessRunner.error(this.process);
      else ProcessRunner.success(this.process);
      callback(response);
    };

    this._fetch(payload, settings);
  }

  checkFailure = (cmdResult: any) => {
    return !cmdResult || !cmdResult.success; // any error shows error for all processes.
  };

  _stop = () => {
    ProcessRunner.stop(this.process);
  };

  _fetch = (payload: any, settings: any) => {
    // We are piping the signing service into the visprocessors to allow access to secure buckets for vis data.
    payload.signingService = signingService;
    const routes = processRouteMap[this.process];
    const instance = new (window as any).HapVisProcessor(payload);
    this.sessionId = instance.sessionId;
    logger.log(`VIS PAYLOAD: (${this.sessionId})`, payload);

    comm.trigger('setProcessStatus', routes, { name: this.process, status: DEFAULTS.FETCHING_STATUS });
    setProcessSettings(this.process, { sessionId: instance.sessionId, ...settings });
  };

  static error = (process: any) => {
    const routes = processRouteMap[process];
    comm.trigger('setProcessStatus', routes, { ...getErrorProps(process), status: DEFAULTS.ERROR_STATUS });
  };

  static success = (process: any) => {
    const routes = processRouteMap[process];
    comm.trigger('setProcessStatus', routes, { status: DEFAULTS.SUCCESS_STATUS });
  };

  static stop = (process: any) => {
    const routes = processRouteMap[process];
    comm.trigger('setProcessStatus', routes, null);
    removeProcessSettings(process);
  };

  process: any;
  sessionId: any;
}

export const monitorProcess = (processName: any, processExecutor: any) => async (...args: any[]) => {
  const routes = processRouteMap[processName];

  comm.trigger('setProcessStatus', routes, { name: processName, status: DEFAULTS.FETCHING_STATUS });
  setProcessSettings(processName, {});

  try {
    const result = await processExecutor(...args);
    comm.trigger('setProcessStatus', routes, { status: DEFAULTS.SUCCESS_STATUS });

    return result;
  } catch (error: any) {
    comm.trigger('setProcessStatus', routes, { ...getErrorProps(processName), status: DEFAULTS.ERROR_STATUS });
  }
};
