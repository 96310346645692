const PLAYER_QUIZ_EVENTS = {
  ON_SPECIFIC_ANSWER: 'onSpecificAnswer',
  AFTER_SECTION: 'afterSection',
};

const PLAYER_QUIZ_ACTIONS = {
  GO_TO_SECTION: 'goToSection',
  SUBMIT_FORM: 'submitForm',
};

const ANNOTATION_INTENT = {
  NEXT: 'Next Button',
  BACK: 'Back Button',
  RETRY: 'Retry Button',
  CONFIRMATION: 'Confirmation Button',
  SUBMIT: 'Submit Button',
  PROGRESS: 'Quiz Progress',
  FINAL_MESSAGE: 'Final Message',
  PASS_MESSAGE: 'Passing Message',
  FAIL_MESSAGE: 'Failing Message',
};

const getMessageIntentByType = (type: any) => {
  const map = {
    quizPassMessage: ANNOTATION_INTENT.PASS_MESSAGE,
    quizFailMessage: ANNOTATION_INTENT.FAIL_MESSAGE,
    quizFinalMessage: ANNOTATION_INTENT.FINAL_MESSAGE,
  };

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return map[type] || map.quizFinalMessage;
};

export default {
  PLAYER_QUIZ_EVENTS,
  PLAYER_QUIZ_ACTIONS,
  ANNOTATION_INTENT,
  getMessageIntentByType,
};
