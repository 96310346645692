import React from 'react';

import DEFAULTS from '../../../DEFAULTS.json';
import { Table, Modal, Button } from '../../../hapyak-ui-toolkit';
import { AccessibilityFileUploader } from '../IsoFileEditor';
import { ProcessRow } from './ProcessRow';

type State = any;

type LocalizationTableListProps = {
  allAvailableCombos: any;
  canEdit: boolean;
  handleClick: any;
  handleMenuToggle: any;
  lexileLevel: any;
  projectArbs: any;
  projectAudioDescriptions: any;
  projectVtts: any;
}

export class LocalizationTableList extends React.Component<LocalizationTableListProps, State> {
  constructor (props: LocalizationTableListProps) {
    super(props);
    this.state = this.initialState;
  }

  get initialState () {
    return {
      showUploadModal: false,
      selectedProcessType: '',
      fileExt: '',
    };
  }

  componentDidUpdate (previousProps: LocalizationTableListProps) {
    const { allAvailableCombos, handleMenuToggle } = this.props;

    if ((previousProps as any).allAvailableCombos.length === 0 && allAvailableCombos.length > 0) {
      if (handleMenuToggle) handleMenuToggle({ forceIsOpen: false });
    }

    if ((previousProps as any).allAvailableCombos.length > 0 && allAvailableCombos.length === 0) {
      if (handleMenuToggle) handleMenuToggle({ forceIsOpen: true });
    }
  }

  get processes () {
    return {
      captions: false,
      videoText: false,
      annotations: false,
      audioDescription: false,
    };
  }

  showUploadModal = ({
    processes,
    processType,
    fileExt
  }: any) => {
    this.setState({ showUploadModal: true, processes, selectedProcessType: processType, fileExt });
  };

  setInitialState = () => {
    this.setState(this.initialState);
  };

  get uploadModal () {
    const { selectedProcessType = DEFAULTS.ARB_VIDEO_TEXT, fileExt } = this.state;

    return (
      <Modal
        open
        header={selectedProcessType}
        content={
          <div className='hy-margin-left hy-margin-right hy-margin-top hy-margin-bottom'>
            <AccessibilityFileUploader
              fileExt={fileExt}
              type={selectedProcessType}
              filterOnType
              uploadButtonContent='Add File'
              {...this.props}
            />
          </div>
        }
        actions={[<Button key='upload' content='Close' onClick={this.setInitialState} />]}
      />
    );
  }

  get commonProps () {
    const { allAvailableCombos, canEdit, handleClick } = this.props;

    return {
      handleClick,
      canEdit,
      handleUploadClick: this.showUploadModal,
      allAvailableCombos,
    };
  }

  get captionsProcess () {
    const { projectVtts } = this.props;

    return (
      <ProcessRow
        processType={DEFAULTS.VTT_CAPTIONS}
        processes={{ ...this.processes, captions: true }}
        processFiles={projectVtts}
        fileExt='vtt'
        {...this.commonProps}
      />
    );
  }

  get annotationText () {
    const { projectArbs } = this.props;

    return (
      <ProcessRow
        processType={DEFAULTS.ARB_ANNOTATION_TEXT}
        processes={{ ...this.processes, annotations: true }}
        processFiles={projectArbs}
        fileExt='arb'
        {...this.commonProps}
      />
    );
  }

  get videoText () {
    const { projectArbs } = this.props;
    return (
      <ProcessRow
        processType={DEFAULTS.ARB_VIDEO_TEXT}
        processes={{ ...this.processes, videoText: true }}
        edit={false}
        processFiles={projectArbs}
        fileExt='arb'
        {...this.commonProps}
      />
    );
  }

  get audioDescription () {
    const { projectAudioDescriptions } = this.props;
    return (
      <ProcessRow
        processType={DEFAULTS.AUDIO_DESCRIPTION}
        processes={{ ...this.processes, audioDescription: true }}
        edit={false}
        processFiles={projectAudioDescriptions}
        fileExt='mp3'
        disableReview
        {...this.commonProps}
      />
    );
  }

  render () {
    const { showUploadModal } = this.state;

    return (
      <div className='hy-scrollable hy-margin-top'>
        <Table
          className='accessibility-manage-process-table'
          celled
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content='Accessibility File' />
              <Table.HeaderCell content='Summary' />
              <Table.HeaderCell content='Actions' />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.captionsProcess}
            {this.annotationText}
            {/* Commented out per VIDEO-439 */}
            {/* {this.videoText} */}
            {this.audioDescription}
          </Table.Body>
        </Table>
        {showUploadModal && this.uploadModal}
      </div>
    );
  }
}
