import React, { Component } from 'react';

import _ from 'lodash';

import { Grid } from '../../../../../hapyak-ui-toolkit';
import { stateController } from '../../../../../services/stateController';
import { findAnnotationById, mergeChangesToAnnotations } from '../../../../../services/toolService';
import { Annotation, AnnotationProperties } from '../../../../../types/annotations';
import { AnnotationAsAnswerDropdown } from './AnnotationAsAnswerDropdown';
import { AnswerAsAnnotationRow } from './AnswerAsAnnotationRow';
import { QuickAddNewAnswerAsAnnotationDropdown } from './QuickAddNewAnswerAsAnnotationDropdown';

type QuizAnswersAsAnnotationsProps = {
  annotation?: Annotation;
  annotations: Annotation[];
  answerIds: string[];
  appliesTo?: any;
  createAnnotationWithDefaults: any;
  edit: any;
  onDelete: any;
  persistChange: any;
  quizAnnotation: Annotation;
};

export class QuizAnswersAsAnnotations extends Component<QuizAnswersAsAnnotationsProps> {
  persistChange = (changes: any, projectAnnotations: Annotation[]) => {
    const _changes = changes.map((a: Annotation) => {
      const { annotations } = this.props;
      const { id, content, properties = {} as AnnotationProperties, appliesTo } = a;
      const { value } = properties;

      const annotation = annotations.find((a: Annotation) => a.id === id);

      if (!annotation) return a;

      const appliesToChange = appliesTo ? { appliesTo } : {};
      return {
        ...a,
        ...appliesToChange,
        content: content || annotation.content,
        properties: { ...properties, value: value || _.get(annotation, 'properties.value') },
      };
    });

    projectAnnotations = projectAnnotations || stateController.getCurrentData('ux').annotations;
    const annotations = mergeChangesToAnnotations(projectAnnotations, _changes, true);
    stateController.updateProject('ux', { annotations });
  };

  get answerAnnotations () {
    const { answerIds = [] } = this.props;
    return answerIds.map((id: string) => findAnnotationById(id));
  }

  get answerRows () {
    const { onDelete, edit } = this.props;
    return this.answerAnnotations.map((annotation: Annotation, idx: number) => {
      return (
        <AnswerAsAnnotationRow
          persistChange={this.persistChange}
          onDelete={onDelete}
          annotation={annotation}
          onEdit={edit}
          key={idx}
        />
      );
    });
  }

  render () {
    const { createAnnotationWithDefaults, quizAnnotation, annotations } = this.props;

    return (
      <Grid style={{ width: '100%' }} className='hy-quiz-answer-table'>
        <Grid.Row>
          <Grid.Column width={6}>Option</Grid.Column>
          <Grid.Column width={10}>Value</Grid.Column>
        </Grid.Row>
        {this.answerRows}
        <Grid.Row color='grey'>
          <Grid.Column width={8}>
            <AnnotationAsAnswerDropdown
              persistChange={this.persistChange}
              annotations={annotations}
              createAnnotationWithDefaults={createAnnotationWithDefaults}
            />
          </Grid.Column>
          <Grid.Column textAlign='right' verticalAlign='middle' width={8}>
            <QuickAddNewAnswerAsAnnotationDropdown
              createAnnotationWithDefaults={createAnnotationWithDefaults}
              quizAnnotation={quizAnnotation}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
