import React, { Component } from 'react';
import './index.scss';

type PageLayoutProps = {
  location: Location;
}
class PageLayout extends Component<PageLayoutProps> {
  render () {
    return (
      <div className='hy-page-layout' data-path-name={this.props.location.pathname}>
        {this.props.children}
      </div>
    );
  }
}

export { PageLayout };
