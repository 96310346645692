import React from 'react';

import { Checkbox } from 'semantic-ui-react';

// just a pass through that will automatically blur itself so keyboard shortcuts aren't interrupted.

type AutoBlurCheckboxProps = {
  checked?: boolean;
  label?: string;
  onChange: (event: Event, checkbox: any) => void;
};
class AutoBlurCheckbox extends React.Component<AutoBlurCheckboxProps> {
  checkbox: any;
  constructor (props: any) {
    super(props);

    this.checkbox = React.createRef();
  }

  autoBlur = (e: any, checkbox: any) => {
    this.props.onChange(e, checkbox);
    this.checkbox.current.inputRef.current.blur();
  };

  render () {
    return <Checkbox ref={this.checkbox} {...this.props} onChange={this.autoBlur} />;
  }
}

export { AutoBlurCheckbox };
