import React, { useState, useEffect } from 'react';

import { Combobox } from '@newsela/angelou';

import { toSentenceCase } from '../../../../../../services/utils';
import { QuestionType } from '../../../../../../types/questions';
import { ENABLED_EDITING_QUESTION_TYPES } from '../questionService';
import './QuizQuestionTypeDropdown.scss';

type QuizQuestionTypeDropdownProps = {
  setValue: (e: QuestionType) => void;
  value: QuestionType;
};

export const QuizQuestionTypeDropdown = ({
  setValue,
  value
}: QuizQuestionTypeDropdownProps) => {
  const [questionTypes, setQuestionTypes] = useState<string[]>([]);
  const [defaultQuestionType, setDefaultQuestionType] = useState('');

  useEffect(() => {
    setQuestionTypes(ENABLED_EDITING_QUESTION_TYPES);
    setDefaultQuestionType(questionTypes[0]);
  }, []);

  const setLabel = (option: string) => {
    switch (option) {
      case 'multipleChoice':
        return 'Single-select multiple choice';
      case 'checkbox':
        return 'Multi-select multiple choice';
      default:
        return toSentenceCase(option);
    }
  };

  return (
    <div className='question-type-dropdown'>
      <Combobox
        items={ENABLED_EDITING_QUESTION_TYPES}
        itemToString={(option: string | null) => option?.length ? setLabel(option) : ''}
        label='Question type'
        selectedItem={value || defaultQuestionType}
        onSelectedItemChange={(selectEvent: any) => setValue(selectEvent.selectedItem)}
      />
    </div>
  );
};
