import React from 'react';

import { Combobox } from '@newsela/angelou';

import { LEXILE_LEVEL_OPTIONS } from '../../../services/lexileService';

type LexileComboBoxProps = {
  lexileLevelObject: { label: string, value: string };
  lexileComboBoxEnabled: boolean;
  handleLexileSelectChange: (selectedItem: unknown) => void;
}

export const LexileComboBox = ({
  lexileLevelObject,
  lexileComboBoxEnabled,
  handleLexileSelectChange
}: LexileComboBoxProps) => {
  const filteredLexileLevels = LEXILE_LEVEL_OPTIONS.map((lexLev) => ({ label: lexLev.text, value: lexLev.value }));

  return (
    <>
      <Combobox
        label='Lexile (required if captions attached)'
        selectedItem={lexileLevelObject}
        items={filteredLexileLevels}
        itemToString={(item: {label: string, value: string} | null) => item ? item.label : ''}
        onSelectedItemChange={(e) => handleLexileSelectChange(e.selectedItem)}
        __cssFor={{
          root: {
            width: '50%',
            zIndex: 1,
            opacity: lexileComboBoxEnabled ? '100%' : '50%'
          }
        }}
      />
    </>
  );
};
