import React from 'react';

import { groupConfig } from '../../../services/configurationService';
import { media } from '../../../services/mediaController';
import hms from '../../../services/time';
import { VideoTypeOption } from '../../../types/publishing';
import './index.scss';
import { PackagingOptions, PackagingOptionsProps } from './PackagingOptions';
import { SimpleCalculator, SimpleCalculatorContent } from './SimpleCalculator';

export type OptimizationOptionsProps = PackagingOptionsProps & {
  onSelectedActionChange: (tabName: string, data: any) => void;
  tabName: string;
  videoTypeOptions: VideoTypeOption[];
};

export class OptimizationOptions extends PackagingOptions<OptimizationOptionsProps> {
  constructor (props: OptimizationOptionsProps) {
    super(props);
    this.state = { hasWatermark: !!this.bundleProps.watermark };
  }

  componentDidMount () {
    this.onSelectedActionChange();
  }

  findVideoOptionType = (type: any) => {
    const { videoTypeOptions } = this.props;
    return type && videoTypeOptions.find((option: any) => option.value.toLowerCase() === type);
  };

  get hlsOption () {
    return this.findVideoOptionType('m3u8');
  }

  get hlsIsActive () {
    const { videoType } = this.bundleProps;
    return videoType?.toLowerCase() === 'm3u8';
  }

  get defaultVideoType () {
    return this.findVideoOptionType('mp4'); // else find first object of not m3u8
  }

  toggleHlsActive = () => {
    const { value } = this.hlsIsActive ? this.defaultVideoType : this.hlsOption;
    this.updateValue('videoType', value)
      .then(this.saveChanges)
      .then(this.onSelectedActionChange);
  };

  get mediaDurationInSeconds () {
    // return next whole minute in seconds format
    const { duration } = media.video;
    const roundedDurationToNextMinute = hms.msToNextMinute(duration);
    return hms.minutesToMS(roundedDurationToNextMinute) / 1000;
  }

  onSelectedActionChange = () => {
    const { onSelectedActionChange, tabName } = this.props;
    if (onSelectedActionChange) onSelectedActionChange(tabName, this.calculatorContent.sections);
  };

  get calculatorContent (): SimpleCalculatorContent {
    const { emblazonLanguages = [] } = this.bundleProps;
    const UNITS = 1; // we only produce one video at the moment
    const numberOfLanguages = emblazonLanguages.length;

    return {
      title: '',
      unitFormat: 'time',
      columnHeaders: ['', 'Action'],
      checkable: true,
      totalDescription: 'Actions selected',
      sections: [
        {
          title: '',
          lineItems: [
            {
              selected: this.hlsIsActive,
              onChange: this.toggleHlsActive,
              description: 'HLS Transcoding',
              units: UNITS + numberOfLanguages,
              unitName: 'Video',
              component: null,
            },
          ]
        },
      ],
    };
  }

  render () {
    return <SimpleCalculator data={this.calculatorContent} />;
  }
}
