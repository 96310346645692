import axios from 'axios';

import { media } from './mediaController';
import { getRemoteOverridesUrl, savePlayerConfigOverrides } from './stateUtils';

const hapshotUrl = `https://${process.env.REACT_APP_SNAPSHOT_HOST}`;

function resolveConfigurablePlayerEndpoint () {
  const url = `https://${process.env.REACT_APP_PLAYER_ORIGIN}/configurable.html`;
  const overrides = getRemoteOverridesUrl();
  return overrides ? url + `?overridesUrl=${encodeURIComponent(overrides)}` : url;
}

function takePicture (save: any, time: any) {
  if (save) {
    return savePlayerConfigOverrides().then(() => snap(time));
  }

  return snap(time);
}

const snap = (time: any) => {
  const previewInstance = media.previewInstance;
  time = time || previewInstance.currentTime || 0;
  const box = previewInstance.renderer.rendered && previewInstance.renderer.rendered.getBoundingClientRect();
  const { width, height } = clampDimensions(box);

  const now = Date.now();
  const previewUrl = `${resolveConfigurablePlayerEndpoint()}&controls=false&screenshot=true&v=${now}&startTime=${time}`;
  const encodedPreviewUrl = encodeURIComponent(previewUrl);
  const hapshotParams = `?say_cheese=true&width=${width}&height=${height}&unique=${now}&delay=0.001&url=`;
  const url = hapshotUrl + hapshotParams + encodedPreviewUrl;

  return axios.get(url).then(({ data }) => {
    return { src: data, time };
  });
};

const clampDimensions = (dimensions: any) => {
  let { width, height } = dimensions || { width: 0, height: 0 }; // set object defaults if null

  const aspectRatio = width / height;

  const MIN_WIDTH = 500; // chrome min width
  if (width < MIN_WIDTH) {
    width = MIN_WIDTH;
    height = MIN_WIDTH / aspectRatio;
  }

  return { width, height };
};

export { takePicture };
