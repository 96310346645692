import React from 'react';

import './index.scss';
import { LEXILE_LEVEL_DEFAULT } from '../../../services/lexileService';
import { uploadMp3 } from '../../../services/uploadUtils';
import { AccessibilityUploadModal } from './AccessibilityUploadModal';
import { Upload } from './Upload';

export class AudioDescriptionUploadModal extends AccessibilityUploadModal {
  forceLanguage = 'en';
  forceLexileLevel = LEXILE_LEVEL_DEFAULT;

  assessFile = () => {
    this.setState({ fileLocale: this.forceLanguage });
  };

  // @ts-expect-error TS(2611): 'fileUploadComponent' is defined as a property in ... Remove this comment to see the full error message
  get fileUploadComponent () {
    return (
      <Upload
        type='audioDescription'
        postponeUpload
        beforeUpload={this.beforeUpload}
        afterUpload={this.afterUpload}
        handleFileChange={this.handleFileChange}
        displayConfirm={false}
        confirm={this.confirmProps}
        label='Audio description'
        filetype='audio/mpeg'
      />
    );
  }

  startUpload = () => {
    this.setState({ uploading: true });
    const { file } = this.state;
    return uploadMp3(file)
      .then(this.afterUpload)
      .catch(this.onUploadError);
  };
}
