import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import {
  findAssociatedQuizAnnotationIds,
  makeInitialDefaultQuizAnnotations
} from '../components/editors/UXEditor/AnnotationEditor/QuizBuilder/quizBuilderUtils';
import { getSizableLayoutTargets } from '../layouts';
import * as tools from '../tools';
import { Annotation, CreateAnnotationRequest, CreateAnnotationResponse } from '../types/annotations';
import { ToolType } from '../types/tools';
import { comm } from './comm';
import { gaConfig, ReactGA } from './gaConfig';
import { media } from './mediaController';
import { stateController } from './stateController';
import { StyleService } from './styleService';
import {
  addCollectionRelationshipToAnnotation,
  cascadeFindAssociatedAnnotations,
  removeAvailableReferencesToAnnotation
} from './toolRelationshipService';
import { overrideCurrentAnnotationActions, toOption, toSentenceCase } from './utils';

const definitions = (window as any).hy.annotations;

const ANNOTATION_DEFAULTS = {
  content: '',
  internal: { name: '' },
  actions: [],
  appliesTo: [],
  styles: {},
  properties: {},
};

const DEFAULT_ACTIONS = ['defaults'];

const getToolFromAnnotation = (annotation: Annotation, preset?: any) => {
  const { toolType } = annotation;
  return getTool(toolType, preset);
};

const getTool = (toolType: ToolType, preset?: any) => {
  const tool = tools[toolType];
  if (!tool) return null;
  const definition = { ...tool.definition };
  const { defaults, editor } = definition;
  const { actionTypes = [] } = editor;
  const actions = editor.actions ? [...actionTypes, ...DEFAULT_ACTIONS] : [];
  const styles = getToolPreset(toolType, preset);

  return {
    ...tool.definition,
    defaults: {
      ...ANNOTATION_DEFAULTS,
      ...defaults,
      styles,
    },
    editor: {
      ...editor,
      actionTypes: actions,
    },
  };
};

const getEligibleTargets = (toolType: ToolType) => {
  const { targets = [] } = getTool(toolType);
  return targets.concat('hidden');
};

const getPresets = (toolType: string) => {
  const { presets } = stateController.getCurrentData('ux');
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const standardSet = tools[toolType].presets || {};
  const custom = _.get(presets, toolType, {});
  return { ...custom, ...standardSet };
};

const getPresetsNamesOptions = (toolType: string) => {
  return Object.keys(getPresets(toolType)).map(toOption);
};

const getToolPreset = (toolType: string, preset: any) => {
  const presets = getPresets(toolType);
  return presets[preset] || presets[Object.keys(presets)[0]];
};

const getPlayerAnnotationComponent = (toolType: string) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const tool = tools[toolType];
  if (!tool) return null;
  const { definition } = tool;
  return (window as any).hy.annotations[definition.constant];
};

const getDefinition = (annotation: Annotation) => {
  const match = Object.keys(definitions).find((key) => {
    const definition = definitions[key];
    return definition.schemaType === annotation.type;
  });

  // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
  return definitions[match];
};

const getComposition = (annotation: Annotation) => {
  const definition = getDefinition(annotation);
  return (definition && definition.composition) || [];
};

const shouldDisplayToolContent = (toolType: string) => {
  /* SPECIFICALLY TO HIDE GUID IN UI */
  const BLACKLIST = ['image'];
  return !BLACKLIST.includes(toolType);
};

const getDropdownDisplay = (annotation: Annotation, isCurrentAnnotation: boolean) => {
  if (isCurrentAnnotation) return 'Current Annotation';

  const { internal, toolType } = annotation;
  const { name } = internal;
  const { display } = getTool(toolType);
  const content = shouldDisplayToolContent(toolType) ? getContentDisplay(annotation) : '';
  const displayArray = [];

  if (content) {
    displayArray.push(`${getContentDisplay(annotation)}`);
  }
  displayArray.push(` (${display})`);
  if (name) {
    displayArray.push(` [${name}]`);
  }

  return displayArray;
};

const getContentDisplay = (annotation: Annotation) => {
  const { content, contentType } = annotation;
  return contentType === 'htmlstring' ? getRichText(content) : content || '';
};

const getAnnotationDisplay = (annotation?: Annotation) => {
  if (!annotation) return '';
  const { content, internal, toolType, id } = annotation;
  const { name } = internal;
  const { display } = getTool(toolType);
  return name || content || display + ' (' + id + ')';
};

const getAnnotations = () => {
  return stateController.getCurrentData('ux').annotations || [];
};

const findAnnotationById = (id: string, projectAnnotations = getAnnotations()): Annotation => {
  return projectAnnotations.find((a: Annotation) => a.id === id) || {} as Annotation;
};

const getModifiedActions = ({
  annotation
}: any) => {
  const annotations = getAnnotations();
  const { actions = [] } = annotation;

  return actions.map(({
    event,
    action,
    args
  }: any) => {
    let { value } = args;

    if (action === 'setVariables' && value) {
      const name = Object.keys(value)[0];
      const val = value[name];
      return `(${event}: ${action}: Name: ${name}, Value: ${val}})`;
    }

    const match = annotations.find((a: Annotation) => a.id === value);
    if (match) {
      value = getAnnotationDisplay(match);
    }

    return `(${event}: ${action}${value ? ': ' + value : ''})`;
  });
};

const getMeaningfulDisplayInfo = (annotation: Annotation, includeName?: boolean) => {
  const { content, internal, toolType } = annotation;
  const displayArray = [];
  const { display } = getTool(toolType);

  if (content) {
    displayArray.push(getContentDisplay(annotation));
  }

  if (includeName && internal && displayArray.length === 0) {
    const { name } = internal;
    if (name) displayArray.push('[' + name + ']');
  }

  if (displayArray.length === 0) {
    displayArray.push(display);
  }

  return {
    displayArray,
    modifiedActions: getModifiedActions({ annotation }),
  };
};

const getMeaningfulDisplay = (annotation: Annotation, includeName?: boolean) => {
  const { displayArray, modifiedActions } = getMeaningfulDisplayInfo(annotation, includeName);
  return [...displayArray, ...modifiedActions].join(' ');
};

const getRichText = (htmlString: any) => {
  const span = document.createElement('span');
  span.innerHTML = htmlString;
  return span.textContent || span.innerText;
};

const stageAnnotation = (toolType: ToolType, overrides: any): Annotation => {
  const tool = getTool(toolType);
  const defaults = { ...tool.defaults } || {};
  let start = media.playTime || 0;
  let end = media.duration;
  if (defaults.persistent) start = 0; // for now set to 0 if persistent
  if (tool.duration) end = Math.min(start + tool.duration, end);
  const { positionStyles, properties, ...otherOverrides } = overrides;

  const annotation = {
    id: uuid(),
    start,
    end,
    ...defaults,
    properties: {
      ...defaults.properties,
      ...properties,
    },
    styles: positionStyles ? StyleService.addPositioningStyles(defaults.styles, positionStyles) : defaults.styles,
    ...otherOverrides,
  };

  if (toolType === 'chapter' && !annotation.content) {
    annotation.content = 'Chapter ' + (filterProjectAnnotationsByToolType('chapter').length + 1);
  }

  if (toolType === 'section' && !annotation.content) {
    annotation.content = 'Section ' + (filterProjectAnnotationsByToolType('section').length + 1);
  }

  if (!annotation.internal.name) {
    annotation.internal = {
      ...(annotation.internal || {}),
      name: `${toSentenceCase(tool.display)} ${filterProjectAnnotationsByToolType(toolType).length + 1}`,
    };
  }

  return annotation;
};

const addAnnotations = (newAnnotations: Annotation[], copied: any) => {
  const { annotations } = stateController.getCurrentData('ux');
  const _newAnnotations = newAnnotations.map((a: Annotation) => {
    const internal = a.internal;
    let name = internal && internal.name ? internal.name : '';
    const numberInParensRegex = /\((\d*)\)$/;
    const hasNumberInParensMatch = name.match(numberInParensRegex);
    const number = hasNumberInParensMatch && hasNumberInParensMatch[1];

    if (copied && name) {
      name = number ? name.replace(numberInParensRegex, `(${+number + 1})`) : `${name} (1)`;
    }

    return {
      ...overrideCurrentAnnotationActions(a),
      internal: {
        ...a.internal,
        name,
      },
    };
  });

  const fullAnnotations = [...annotations, ..._newAnnotations];

  return stateController.updateProject('ux', { annotations: fullAnnotations });
};

const addCustomAnnotationDefaults = (annotation: Annotation, annotations: Annotation[]) => {
  if (annotation.type !== 'quiz') return annotations;
  if (annotation.collections) return annotations;
  return makeInitialDefaultQuizAnnotations(annotation, annotations);
};

const addAnnotation = (annotation: Annotation, select?: boolean) => {
  const { annotations } = stateController.getCurrentData('ux');
  const clonedAnnotations = [...annotations];
  clonedAnnotations.push(annotation);
  const updatedAnnotations = addCustomAnnotationDefaults(annotation, clonedAnnotations);

  ReactGA.event(
    Object.assign(gaConfig.Analytics.Portal.UX.Annotation.Created, {
      label: 'Created / ' + (annotation.type || ''),
    })
  );

  return stateController.updateProject('ux', { annotations: updatedAnnotations }).then(() => {
    if (select) comm.trigger('selectAnnotation', annotation.id);
    return annotation;
  });
};

export const filterAnnotationsByEditable = (annotations: Annotation[]) => {
  return annotations.filter((a: Annotation) => {
    if (a.type !== 'isi') {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const editingDisabled = TOOLS[a.toolType].editor.editingDisabled === true;
      return !editingDisabled;
    }
  });
};

const cloneQuizAnnotation = (quizAnnotation: Annotation, projectAnnotations: Annotation[]) => {
  if (quizAnnotation.toolType !== 'quiz') return quizAnnotation;
  const associatedIds = [
    quizAnnotation.id,
    ...findAssociatedQuizAnnotationIds(quizAnnotation, null, projectAnnotations),
  ];
  const associatedQuizAnnotations = associatedIds.map((id) => findAnnotationById(id, projectAnnotations));

  const stringifiedAnnotations = JSON.stringify(associatedQuizAnnotations);
  const findAndReplaceId = (string: string, id: string) => string.replace(new RegExp(id, 'gi'), uuid());
  const stringifiedAnnotationsWithNewIds = associatedIds.reduce(findAndReplaceId, stringifiedAnnotations);

  const clonedAnnotations = JSON.parse(stringifiedAnnotationsWithNewIds);
  return clonedAnnotations;
};

const cloneQuizAnnotations = (annotations: Annotation[], projectAnnotations: Annotation[]) => {
  const _annotations = [...filterAnnotationsByToolType('quiz', annotations)];
  const newQuizAnnotations = _annotations.map((annotation) => {
    return cloneQuizAnnotation(annotation, projectAnnotations);
  });
  return newQuizAnnotations.flat();
};

const cloneAnnotations = (annotations: Annotation[], projectAnnotations: Annotation[]) => {
  const isQuiz = (a: Annotation) => a.toolType === 'quiz';
  const notQuiz = (a: Annotation) => !isQuiz(a);
  const _annotations = filterAnnotationsByEditable(annotations);

  const filteredAnnotations = _annotations.filter(notQuiz);
  const clonedNonQuizAnnotations = filteredAnnotations.map((annotation: Annotation) => ({
    ...annotation,
    id: uuid()
  })); // change id

  const quizAnnotations = _annotations.filter(isQuiz);
  const clonedQuizAnnotations = cloneQuizAnnotations(quizAnnotations, projectAnnotations);

  return [...clonedNonQuizAnnotations, ...clonedQuizAnnotations];
};

const FORGO_REORDER_LIST = ['quizAnswer'];
const updateAnnotationOrdering = ({
  annotations,
  annotation,
  appliesTo,
  atIndex
}: any) => {
  const NEXT = 1;
  const PREVIOUS = 0;
  const appliesToAnnotation = annotations.find((a: Annotation) => a.id === appliesTo);
  const appliesToAnnotationCollectionIds = appliesToAnnotation.collections[annotation.toolType];
  const atCollectionIndex = atIndex - 1 >= 0 ? atIndex - 1 : 1;
  const previousAnnotationId = appliesToAnnotationCollectionIds[atCollectionIndex];

  if (!previousAnnotationId || FORGO_REORDER_LIST.includes(annotation.toolType)) {
    return annotations;
  }

  const adjusted = atCollectionIndex === 1 ? PREVIOUS : NEXT;
  const insertAtAnnotationsIdx = annotations.map((a: Annotation) => a.id).indexOf(previousAnnotationId) + adjusted;
  const filteredAnnotations = annotations.filter((a: Annotation) => a.id !== annotation.id); // temp remove annotation
  filteredAnnotations.splice(insertAtAnnotationsIdx, 0, annotation); // insert annotation at proper idx
  return filteredAnnotations;
};

const addAnnotationWithAssociations = ({
  appliesTo,
  toolType,
  annotations = getAnnotations(),
  overrides = {},
  atIndex
}: CreateAnnotationRequest): CreateAnnotationResponse => {
  const annotation = stageAnnotation(toolType as ToolType, {
    appliesTo: appliesTo ? [appliesTo] : [],
    target: getTool(toolType).targets[0], // for now, assume first option is OK, regardless of layout, since only chapters are currently supported and "hidden" is always an option
    ...overrides,
  });

  const projectAnnotations = [...annotations, annotation];
  let updatedAnnotations = addCollectionRelationshipToAnnotation(
    appliesTo,
    annotation.id,
    projectAnnotations,
    atIndex
  );

  updatedAnnotations = mergeChangesToAnnotations(annotations, updatedAnnotations, true);
  updatedAnnotations = updateAnnotationOrdering({ annotations: updatedAnnotations, appliesTo, atIndex, annotation });

  return { annotation, updatedAnnotations };
};

const modifyAnnotation = (annotations: Annotation[] = [], id: string, changes: any): Annotation[] => {
  annotations = annotations.map((a: Annotation) => ({
    ...a
  })); // copy annotation objects, not just array
  const annotation = annotations.find((a: Annotation) => id === a.id);
  if (annotation) Object.assign(annotation, changes);
  return annotations;
};
const overridePowerWordsCase = (content: string) => {
  return content.toLowerCase();
};

const updateAnnotationEndTimes = (annotations: Annotation[], newEndTime: number) => {
  const updatedAnnotations = annotations.map((a: Annotation) => ({
    ...a
  }));

  updatedAnnotations.forEach((a: Annotation) => {
    if (a.end > newEndTime || (a.endTimeOmitted && (a.end !== newEndTime))) {
      a.end = newEndTime;

      if (a.end < a.start) {
        a.start = 0;
      }
    }
  });

  return updatedAnnotations;
};

const updateAndPersistAnnotationEndTimes = (newEndTime: number) => {
  const { annotations } = stateController.getCurrentData('ux');
  const annotationsWithAccurateEndTimes = updateAnnotationEndTimes(annotations, newEndTime);
  stateController.updateProject('ux', { annotations: annotationsWithAccurateEndTimes }).then(() => {
    comm.trigger('reloadPlayer');
    return annotationsWithAccurateEndTimes;
  });
};

const updateAnnotation = (id: string, changes: any) => {
  const { annotations } = stateController.getCurrentData('ux');
  const _annotations = modifyAnnotation(annotations, id, changes);
  const annotation = _annotations.find((a: Annotation) => id === a.id);

  if (annotation && annotation.type === 'powerwords') {
    annotation.content = annotation.content ? overridePowerWordsCase(annotation.content) : '';
  }

  return stateController.updateProject('ux', { annotations: _annotations }).then(() => {
    return annotation;
  });
};

const mergeChangesToAnnotations = (annotations: Annotation[], changes: any, addNew?: boolean) => {
  annotations = annotations.map((a: Annotation) => ({
    ...a
  })); // copy annotation objects, not just array

  changes.forEach((changedAnnotation: Annotation) => {
    const annotation = annotations.find((a: Annotation) => a.id === changedAnnotation.id);
    if (!annotation) {
      if (!addNew) return;
      annotations.push(changedAnnotation);
      return;
    }
    Object.assign(annotation, changedAnnotation);
  });

  return annotations;
};

const updateAnnotations = async (changes: any, merge?: any) => {
  let { annotations } = stateController.getCurrentData('ux');
  annotations = mergeChangesToAnnotations(annotations, changes);

  return await stateController.updateProject('ux', { annotations }, merge);
};

const deleteAnnotation = async (id: string) => {
  // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
  const updatedAnnotations = [...removeAvailableReferencesToAnnotation(id)];

  let annotations = mergeChangesToAnnotations(getAnnotations(), updatedAnnotations);

  annotations = annotations.filter((a: Annotation) => a.id !== id);

  stateController.updateProject('ux', { annotations });
};

const cascadeRemoveAssociatedAnnotations = (id: string, annotations: Annotation[]) => {
  const annotationIdsToDelete = [id, ...cascadeFindAssociatedAnnotations(id, annotations)];
  return [...removeSelectedAnnotations(annotationIdsToDelete, annotations)];
};

const removeSelectedAnnotations = (selectedAnnotationIds: string[] = [], annotations: Annotation[] = []) => {
  const _ids = [...selectedAnnotationIds];
  let _annotations = [...annotations];

  _ids.forEach((id) => {
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    const updatedAnnotations = [...removeAvailableReferencesToAnnotation(id)];
    _annotations = mergeChangesToAnnotations(_annotations, updatedAnnotations);
    _annotations = _annotations.filter((a: Annotation) => (a.id !== id));
  });

  return _annotations;
};

const filterProjectAnnotationsByToolType = (toolType: ToolType) => {
  const { annotations } = stateController.getCurrentData('ux');
  return filterAnnotationsByToolType(toolType, annotations);
};

export const filterAnnotationsByToolType = (toolType: ToolType, annotations: Annotation[] = []) => {
  return annotations.filter((a: Annotation) => a.toolType === toolType);
};

export const getHiddenPanelAnnotations = (layoutTargets: any, annotations: Annotation[], duration: number) => {
  // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
  const uniqueAnnotationsTargets = _.uniq(annotations, (a: Annotation) => a.target).map((a) => a.target);
  const sizableTargets = getSizableLayoutTargets(layoutTargets).map((t: any) => t.name);
  const emptyTargets = _.difference(sizableTargets, uniqueAnnotationsTargets);

  return emptyTargets.map((target, i) => {
    return {
      id: `hidden_annotation_${i}`,
      start: 0,
      end: duration,
      target,
      properties: {
        internal: {
          name: ''
        }
      },
      actions: [],
      appliesTo: [],
      styles: { 'compositionTypes.root': { opacity: 0 } },
      toolType: 'hidden', // this works, but maybe we should create a "hidden" toolType?
      type: 'pop',
    };
  });
};

const SORTED_TOOLS_LIST = [
  'powerWords',
  'quiz',
  'question',
  'chapterMenu',
  'chapter',
  'contentExplorer',
  'button',
  'note',
  'image',
  'openForm',
  'richText',
  'section',
  'hotlink',
  'iframe',
];

const TOOLS = (() => {
  const toolDefinitions = {};
  Object.keys(tools).forEach((toolType) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    toolDefinitions[toolType] = tools[toolType].definition;
  });
  return toolDefinitions;
})();

const exposedAnnotations = (() => {
  const isExposedOption = (tool: any) => SORTED_TOOLS_LIST.includes(tool);
  return Object.keys(TOOLS).filter(isExposedOption);
})();

export {
  TOOLS,
  SORTED_TOOLS_LIST,
  getTool,
  getMeaningfulDisplayInfo,
  getToolFromAnnotation,
  getPlayerAnnotationComponent,
  getPresetsNamesOptions,
  getPresets,
  getToolPreset,
  getDropdownDisplay,
  getMeaningfulDisplay,
  getDefinition,
  addAnnotation,
  cloneAnnotations,
  addAnnotationWithAssociations,
  addAnnotations,
  stageAnnotation,
  modifyAnnotation,
  updateAnnotation,
  updateAnnotations,
  deleteAnnotation,
  cascadeRemoveAssociatedAnnotations,
  getComposition,
  getContentDisplay,
  getEligibleTargets,
  getAnnotationDisplay,
  findAnnotationById,
  removeSelectedAnnotations,
  mergeChangesToAnnotations,
  exposedAnnotations,
  updateAnnotationEndTimes,
  updateAndPersistAnnotationEndTimes
};
