import './index.scss';

import React from 'react';

import DEFAULTS from '../../DEFAULTS.json';
import routes from '../../routes.json';
import { groupConfig } from '../../services/configurationService';
import { NavLink } from './NavLink';

const SECTION_TITLE_SIZE = 39;

type NavSectionProps = {
  index: number;
  location: Location;
  onLinkClick: () => void;
  pathPrefix?: string;
  processing: any;
  section: any;
};

class NavSection extends React.Component<NavSectionProps> {
  componentDidMount () {
    window.addEventListener('resize', this.updateNavSize);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateNavSize);
  }

  updateNavSize = () => this.forceUpdate();

  getLinkProps = (route: any) => {
    const { location, onLinkClick, pathPrefix, processing } = this.props;
    const homePath = route.path.includes(DEFAULTS.HOME_PATH);
    const link = homePath ? route.path : `${pathPrefix}${route.path}`; // keep path prefix

    return {
      processing,
      onClick: onLinkClick,
      key: route.name,
      icon: route.icon,
      text: route.name,
      link: link,
      startProject: route.startProject,
      blackList: route.blackList,
      location: location,
    };
  };

  get linkProps () {
    const { section } = this.props;
    const filteredRoutes = routes.filter((r: any) => {
      const { provisionedName, provisionedRoute } = r;
      const isProvisioned = provisionedRoute && this.provisionedRoute(provisionedName);
      const isSection = r.section === section;
      return provisionedRoute ? isProvisioned && isSection : isSection;
    });
    return filteredRoutes.map(this.getLinkProps);
  }

  provisionedRoute = (name: any) => {
    return groupConfig.allow(`${name}.enabled`);
  };

  get links () {
    return this.linkProps
      .filter((props: any) => !(props.blackList && props.blackList.includes('sideNav')))
      .map((props: any) => <NavLink {...props} key={props.key.toString()} />);
  }

  get style () {
    const { index } = this.props;
    const amount = (index + 1) * SECTION_TITLE_SIZE;
    return { transform: `translateY(${amount}px)` };
  }

  get titleStyle () {
    const amount = (-SECTION_TITLE_SIZE * 2) / 3;
    return { transform: `translateY(${amount}px)` };
  }

  render () {
    const { section } = this.props;

    return (
      <div className='hy-side-nav-section' style={this.style}>
        <div className='hy-side-nav-section-title' style={this.titleStyle}>
          {section}
        </div>
        <div>{this.links}</div>
      </div>
    );
  }
}

export { NavSection };
