import React from 'react';

import paper from 'paper';

import { comm } from '../../../../../services/comm';

const applyDefaults = (shape: any) => {
  shape.strokeColor = '#00ff00';
  shape.strokeWidth = 1;
  shape.fillColor = 'rgba(0, 255, 0, 0.1)';
  shape.dashArray = [10, 4];
};

type ReactSelectState = any;

type ReactSelectProps = {
  selectedAnnotations: string[];
  selectionTool: string;
}

export class ReactSelect extends React.Component<ReactSelectProps, ReactSelectState> {
  canvas: any;
  lastEvent: any;
  paperInstance: any;
  shape: any;
  tools: any;

  constructor (props: ReactSelectProps) {
    super(props);
    this.canvas = React.createRef();
    this.shape = null;
    this.lastEvent = null;

    this.state = {
      drawing: false,
    };
  }

  componentDidMount () {
    this.attemptSetup();
    this.tools = {};
    comm.register('selectionToolChanged', this.setTool);
    comm.register('escape', this.cancel);
  }

  componentWillUnmount () {
    comm.unregister('selectionToolChanged', this.setTool);
    comm.unregister('escape', this.cancel);

    if (this.paperInstance) {
      this.paperInstance.remove();
    }
  }

  componentDidUpdate (prevProps: ReactSelectProps) {
    const { selectionTool } = this.props;
    if (prevProps.selectionTool !== selectionTool) {
      this.setTool(selectionTool);
    }
  }

  cancel = () => {
    this.setState({ drawing: false });
    this.removeShape();
  };

  attemptSetup = () => {
    requestAnimationFrame(this.setup);
  };

  setup = () => {
    if (!this.canvas.current) {
      this.attemptSetup();
      return;
    }

    const { selectionTool } = this.props;

    this.paperInstance = paper.setup('authoringCanvas');
    this.setupRectangle();
    this.setupEllipse();
    this.setupLasso();
    this.setTool(selectionTool);
  };

  getRectangle = (e: any) => {
    const equal = e.event.shiftKey;
    const centered = e.event.metaKey || e.event.ctrlKey;

    let a = e.downPoint;
    let b = e.point;

    if (equal) {
      const { x, y } = a.subtract(b);
      const val = Math.max(Math.abs(x), Math.abs(y));
      const _a = Math.sign(x) * val;
      const _b = Math.sign(y) * val;
      const p = new paper.Point(_a, _b);
      b = a.subtract(p);
    }

    if (centered) {
      a = a.multiply(2).subtract(b);
    }

    return new paper.Rectangle(a, b);
  };

  setupRectangle = () => {
    const rectangle = new paper.Tool();

    rectangle.onMouseDown = (e: any) => {
      this.onMouseDown(e);
    };

    rectangle.onMouseDrag = (e: any) => {
      this.createRectangle(e);
    };

    rectangle.onMouseUp = (e: any) => {
      this.createRectangle(e);
      this.onSelection(e);
    };

    this.tools.rectangle = rectangle;
  };

  createRectangle = (e: any) => {
    if (!this.state.drawing) return;
    this.lastEvent = e;
    this.removeShape();
    this.shape = new paper.Path.Rectangle(this.getRectangle(e));
    applyDefaults(this.shape);
    comm.trigger('selectFromShape', this.shape);
  };

  setupEllipse = () => {
    const ellipse = new paper.Tool();

    ellipse.onMouseDown = (e: any) => {
      this.onMouseDown(e);
    };

    ellipse.onMouseDrag = (e: any) => {
      this.createEllipse(e);
    };

    ellipse.onMouseUp = (e: any) => {
      this.createEllipse(e);
      this.onSelection(e);
    };

    this.tools.ellipse = ellipse;
  };

  createEllipse = (e: any) => {
    if (!this.state.drawing) return;
    this.lastEvent = e;
    this.removeShape();
    this.shape = new paper.Path.Ellipse(this.getRectangle(e));
    applyDefaults(this.shape);
    comm.trigger('selectFromShape', this.shape);
  };

  setupLasso = () => {
    const lasso = new paper.Tool();

    lasso.onMouseDown = (e: any) => {
      this.onMouseDown(e);
      this.shape = new paper.Path();
      applyDefaults(this.shape);
      this.shape.add(e.point);
    };

    lasso.onMouseDrag = (e: any) => {
      this.shape.add(e.point);
      comm.trigger('selectFromShape', this.shape);
    };

    lasso.onMouseUp = (e: any) => {
      this.shape.closed = true;
      this.onSelection(e);
    };

    this.tools.lasso = lasso;
  };

  setTool = (tool: any) => {
    this.tools[tool].activate();
  };

  removeShape = () => {
    if (this.shape) {
      this.shape.removeSegments();
    }
  };

  get style () {
    return {
      position: 'absolute',
      zIndex: this.state.drawing ? 10 : 1,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    };
  }

  get canvasStyle () {
    return {
      position: 'absolute',
      width: '100%',
      height: '100%',
    };
  }

  onMouseDown = (e: any) => {
    e.stopPropagation();
    this.removeShape();
    this.setState({ drawing: true });
  };

  onSelection = (e: any) => {
    if (!this.shape) return;
    const { selectionTool, selectedAnnotations } = this.props;
    const { drawing } = this.state;

    this.setState({ drawing: false });

    if (Math.abs(this.shape.area) < 1) {
      if (drawing) {
        comm.trigger('selectAnnotations', []);
      }

      comm.trigger('stageSelection', null);
      return;
    }

    if (selectedAnnotations.length) {
      this.removeShape();
    } else {
      const pathData = this.getRelativePath(this.shape);
      comm.trigger('stageSelection', this.shape, pathData, e.event, selectionTool);
    }
  };

  getRelativePath = (shape: any) => {
    const boundsCanvas = document.createElement('canvas');
    boundsCanvas.setAttribute('width', shape.bounds.width);
    boundsCanvas.setAttribute('height', shape.bounds.height);

    const copiedPath = new paper.Path();
    copiedPath.copyContent(shape);
    copiedPath.position.x = copiedPath.bounds.width / 2;
    copiedPath.position.y = copiedPath.bounds.height / 2;

    return copiedPath.pathData;
  };

  render () {
    return (
      // @ts-expect-error TS(2322): Type '{ position: string; zIndex: number; top: num... Remove this comment to see the full error message
      <div style={this.style} className='selection-overlay-container'>
        {/* @ts-expect-error TS(2322): Type '{ position: string; width: string; height: s... Remove this comment to see the full error message */}
        <canvas ref={this.canvas} id='authoringCanvas' resize='true' style={this.canvasStyle} />
      </div>
    );
  }
}
