import { ScreenshotButton } from '../../components/PreviewSection/ScreenshotButton';
import { Video } from '../../components/Video';
import { media } from '../../services/mediaController';
import { comm } from '../comm';

let managerInstance: any;

export class ExternalPreviewManager {
  initialState: any;
  previewWindow: any;
  constructor (initialState: any) {
    this.initialState = initialState;
    this.previewWindow = window.open('./externalpreview', 'preview', this.windowOptions);
    this.previewWindow.addEventListener('message', this.onPreviewMessage);
    comm.register('reloadPlayer', this.onPlayerReload);
  }

  onPlayerReload = () => {
    this.previewWindow &&
          this.previewWindow.postMessage(
            {
              event: 'configUpdate',
              config: this.playerConfig,
            },
            '*'
          );
  };

  onPreviewMessage = ({
    data
  }: any) => {
    if (data && data.event) {
      switch (data.event) {
        case 'previewReady':
          this.previewWindow.addEventListener('beforeunload', this.close);
          this.sendConfig('configAvailable');
          break;

        case 'takeSnapshot':
          new ScreenshotButton({
            onComplete: () => this.sendMessage({ event: 'snapshotComplete' }),
          }).snapshot();
          break;

        case 'timeUpdate':
          if (media.previewInstance) {
            media.previewInstance.currentTime = data.currentTime;
          }
          break;

        case 'changeLanguage':
          if (media.previewInstance) {
            media.previewInstance.language = data.language;
          }
          break;

              // no default
      }
    }
  };

  sendConfig = (event = 'configUpdate') => {
    this.sendMessage({ event, config: this.playerConfig, initialState: this.initialState });
  };

  sendMessage = (message: any) => {
    this.previewWindow && this.previewWindow.postMessage(message, '*');
  };

  broadcastClosed = () => comm.trigger('externalPreviewClosed');

  close = () => {
    this.previewWindow && this.previewWindow.close();
    this.destroy();
    this.broadcastClosed();
  };

  destroy = () => {
    this.previewWindow && this.previewWindow.removeEventListener('beforeunload', this.broadcastClosed);
    comm.unregister('reloadPlayer', this.onPlayerReload);
    delete this.previewWindow;
    managerInstance = null;
  };

  get playerConfig () {
    const { config } = new Video({ preview: true });
    return config;
  }

  get windowOptions () {
    const baseWidth = 720;
    const baseHeight = 480;
    const controlsMargin = 75;

    const options = [
      'left=50',
      'top=50',
      'location=no',
      'resizeable=yes',
      'status=no',
      'toolbar=no',
      'scrollbars=no',
      'titlebar=no',
      'menubar=no',
    ];

    if (media.previewInstance && media.previewInstance.renderer) {
      const { width, height } = media.previewInstance.renderer.options.container.getBoundingClientRect();
      const aspect = width ? height / width : 0.5625;

      options.push(`width=${baseWidth}`);
      options.push(`height=${baseWidth * aspect + controlsMargin}`);
    } else {
      options.push(`width=${baseWidth}`);
      options.push(`height=${baseHeight}`);
    }

    return options.join(',');
  }
}

export const getManagerInstance = () => managerInstance;

export const createManagerInstance = () => {
  if (!managerInstance) {
    const initialState = {};

    if (media.previewInstance) {
      (initialState as any).language = media.previewInstance.data.state.language;
      (initialState as any).currentTime = media.previewInstance.currentTime;
    }

    managerInstance = new ExternalPreviewManager(initialState);
  }

  return managerInstance;
};
