import DEFAULTS from '../../DEFAULTS.json';
import logger from '../../services/logger';
import { annotationService } from '../annotationService';
import { filterAssetsByComboString } from '../lexileService';
import { assetService, stateController } from '../stateController';
import { jsonToFile, uploadArb } from '../uploadUtils';

class ArbService {
  read = async (src: any) => {
    const contents = await this.retrieveFile(src);
    try {
      // @ts-expect-error TS(2345): Argument of type 'string | void' is not assignable... Remove this comment to see the full error message
      return JSON.parse(contents);
    } catch {
      return { errorMessage: "404 Sorry, we can't find this file. To start again, delete this file." };
    }
  };

  parseFile = async (src: any) => {
    const content = await this.read(src);

    if (content.errorMessage) return content;

    const { annotations } = stateController.getCurrentData('ux');
    const allAnnotations = [...annotations, ...annotationService.nonEditableAnnotations];
    const filteredKeys = Object.keys(content).filter((key) => !key.includes('@@'));

    return filteredKeys.map((key) => {
      const { start, end, id } = allAnnotations.find((annotation) => annotation.id === key) || {};
      return {
        start,
        end,
        identifier: id,
        text: content[key],
      };
    });
  };

  retrieveFile = async (src: any) => {
    return fetch(src)
      .then((response) => {
        return fetch(response.url);
      })
      .then((data) => {
        return data.text();
      })
      .catch(function (error) {
        logger.log(error);
      });
  };

  createFileFromData = async ({
    src,
    items: cues
  }: any) => {
    const info = !src
      ? []
      : await fetch(src)
        .then((res) => res.json())
        .then((res) => {
          const infoKeys = Object.keys(res)
            .filter((key) => key.includes('@@'))
            .reduce((acc, curr) => {
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              acc[curr] = res[curr];
              return acc;
            }, {});
          return infoKeys;
        });

    cues = cues.reduce((accumulator: any, current: any) => {
      accumulator[current.identifier] = current.text;
      return accumulator;
    }, {});

    cues = { ...info, ...cues };
    const file = jsonToFile({ ...info, ...cues }, null);
    return file;
  };

  upload = async ({
    projectId,
    file,
    selectedType,
    selectedLanguage,
    items,
    existingSrc,
    lexileLevel
  }: any) => {
    return await uploadArb(projectId, file, selectedType, selectedLanguage, { lexileLevel });
  };

  setAsset = ({
    language,
    lexileLevel,
    src,
    type,
    videoId
  }: any) => {
    assetService.createArb(
      {
        isControlled: true,
        language,
        lexileLevel,
        src,
        type,
        videoId,
      },
      false
    );
  };

  deleteAsset = (asset: any) => {
    if (!asset) return null;
    assetService.deleteArb(asset);
  };

  updateItemData = (translatedCues: any, baseCueData: any) => {
    const _cues = [...translatedCues];
    let _baseCueData = [...baseCueData];

    _baseCueData = _baseCueData.map((cue) => {
      const match = _cues.find((c) => c.identifier === cue.identifier);

      if (match) {
        const { start, end, styles } = cue;
        return { ...match, start, end, styles };
      }

      return cue;
    });

    return _baseCueData;
  };

  get emblazonLanguages () {
    const localizedAssets = assetService.getArbArray().filter((asset: any) => asset.type === DEFAULTS.ARB_VIDEO_TEXT);
    return filterAssetsByComboString(localizedAssets);
  }

  clearRepeatedTranslatedValues = (baseLanguage: any, translatedLanguage: any) => {
    const clearedTranslated = translatedLanguage.map((cue: any) => {
      if (!cue.text) {
        return cue;
      }

      const match = baseLanguage.find((elem: any) => elem.identifier === cue.identifier);

      if (match && match.text === cue.text) {
        return { ...cue, text: '' };
      }

      return cue;
    });

    const filteredEmpty = clearedTranslated.filter((cue: any) => cue.text);

    return filteredEmpty;
  };
}

export const arbService = new ArbService();
