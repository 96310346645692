import React from 'react';

import './index.scss';
import { sortByTime } from '../../../../services/annotationUtils';
import { comm } from '../../../../services/comm';
import { Annotation } from '../../../../types/annotations';
import { ProgressBar } from './ProgressBar';
import { TimelineToolbar } from './TimelineToolbar';

type TimelineProps = {
  activeAnnotation: string;
  annotations: Annotation[];
  currentTime: number;
  duration: number;
  hideMarkers: boolean;
  selectedAnnotations: string[];
};

export class Timeline extends React.Component<TimelineProps> {
  updateTime = (time: any) => {
    comm.trigger('setCurrentTime', time);
  };

  get shortestAnnotation () {
    const selected = this.selected;

    if (!selected.length) return null;

    return selected.reduce((shortest: any, current: any) => {
      const shortestDuration = shortest.end - shortest.start;
      const currentDuration = current.end - current.start;
      return currentDuration < shortestDuration ? current : shortest;
    }, selected[0]);
  }

  get selected () {
    const { annotations, selectedAnnotations } = this.props;
    return selectedAnnotations
      .map((id: any) => annotations.find((a: any) => a.id === id))
      .filter(Boolean)
      .sort(sortByTime);
  }

  get progressPercent () {
    const { duration, currentTime } = this.props;
    return duration ? (currentTime / duration) * 100 : 0;
  }

  render () {
    const { activeAnnotation, annotations, duration, hideMarkers } = this.props;
    const selected = this.selected;

    return (
      <div className='hy-timeline-container'>
        <TimelineToolbar selected={this.selected} />
        <div className='hy-timeline-wrapper'>
          <div className='hy-timeline-inner'>
            <ProgressBar
              annotations={annotations}
              duration={duration}
              selected={this.selected}
              progressPercent={this.progressPercent}
              numRows={this.selected.length}
              updateTime={this.updateTime}
              start={Math.min(...selected.map((a: any) => a.start))}
              end={Math.max(...selected.map((a: any) => a.end))}
              shortestAnnotation={this.shortestAnnotation}
              activeAnnotation={activeAnnotation}
              hideMarkers={hideMarkers}
            />
          </div>
        </div>
      </div>
    );
  }
}
