import './index.scss';

import React from 'react';

import { Icon, Section } from '../../../../../hapyak-ui-toolkit';
import { MODAL_SECTION_SIZE } from '../../../editorConfig';

type State = any;

type BasicSectionProps = {
  className?: string;
  collapsable?: boolean;
  collapsedHandler?: any;
  floatedComponent?: boolean;
  floatedTitleComponent?: any;
  floatIcon?: any;
  id?: string;
  noPadding?: boolean;
  noVerticalPadding?: boolean;
  placeholder?: any;
  renderedContent: any;
  renderedContentFooter?: any;
  renderedContentTitle?: any;
  sectionPadding?: boolean;
  sectionStyles?: any;
  startCollapsed?: boolean;
  style?: any;
  title?: any;
};

export class BasicSection extends React.Component<BasicSectionProps, State> {
  constructor (props: BasicSectionProps) {
    super(props);
    this.state = { collapsed: false };
  }

  UNSAFE_componentWillMount () {
    const { startCollapsed } = this.props;
    this.setState({ collapsed: Boolean(startCollapsed) });
  }

  toggleCollapsed = () => {
    const { collapsed } = this.state;
    const { collapsedHandler } = this.props;
    this.setState({ collapsed: !collapsed });
    if (collapsedHandler) collapsedHandler();
  };

  get toggleIcon () {
    const { floatIcon = '' } = this.props;
    const { collapsed } = this.state;

    return (
      <Icon
        name={collapsed ? 'angle down' : 'angle up'}
        onClick={this.toggleCollapsed}
        style={{ cursor: 'pointer', float: floatIcon }}
      />
    );
  }

  createSectionTitle = (text: any) => {
    const { collapsable, floatedTitleComponent } = this.props;
    const hasContent = text || floatedTitleComponent || collapsable;

    return !hasContent ? '' : (
      <div className='hy-basic-section-title'>
        <div className='hy-basic-section-title-content'>
          {text}
          {collapsable ? this.toggleIcon : null}
          <div style={{ float: 'right' }}>{floatedTitleComponent}</div>
        </div>
      </div>
    );
  };

  get formatedSubSections () {
    const { renderedContent, renderedContentTitle } = this.props;

    return renderedContent.map((sub: any, idx: any) => {
      const { title, id, renderedContent, inlineComponent = null, h = 'h5', noBorder } = sub;
      const removeBorder = noBorder || (idx === 0 && !renderedContentTitle) ? { borderTop: 'none' } : {};

      return (
        <div className='hy-basic-sub-section' style={removeBorder} key={idx}>
          <Section
            headerClassName='hy-basic-sub-section-title'
            as={h}
            title={title}
            id={id}
            inlineComponent={inlineComponent}
          >
            {renderedContent}
          </Section>
        </div>
      );
    });
  }

  formatRenderedContent = ({
    renderedContentTitle,
    content,
    renderedContentFooter
  }: any) => {
    const { renderedContent, noVerticalPadding, sectionPadding, noPadding } = this.props;
    const topPadding = !renderedContentTitle && Array.isArray(renderedContent) ? { paddingTop: 0 } : {};
    const padding = noPadding || sectionPadding === false ? { padding: 0 } : topPadding;
    const className = 'hy-basic-section-content' + (noVerticalPadding ? ' hy-no-vertical-padding' : '');

    return (
      <div className={className} style={padding}>
        {!renderedContentTitle ? null : (
          <div className='hy-basic-sub-section-header'> {renderedContentTitle}</div>
        )}
        {content}
        {renderedContentFooter}
      </div>
    );
  };

  get renderedContent () {
    const { renderedContent } = this.props;
    return Array.isArray(renderedContent) ? this.formatedSubSections : renderedContent;
  }

  get sectionContent () {
    const { renderedContentTitle, renderedContentFooter } = this.props;
    return this.formatRenderedContent({
      renderedContentTitle,
      content: this.renderedContent,
      renderedContentFooter,
    });
  }

  render () {
    const { style = {}, title = '', placeholder = false, sectionStyles = {}, className = '', id = '' } = this.props;
    const { collapsed } = this.state;
    const resetStyles = collapsed ? 'hy-basic-reset' : '';

    return (
      <div key={id} className={`hy-basic-section-wrapper ${resetStyles} ${className}`} style={style}>
        <Section
          style={{ ...sectionStyles }}
          title={this.createSectionTitle(title)}
          as={MODAL_SECTION_SIZE}
          placeholder={placeholder}
        >
          {collapsed ? null : this.sectionContent}
        </Section>
      </div>
    );
  }
}
