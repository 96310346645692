import React from 'react';

import dragController from './DragController';

type State = any;

type DropZoneProps = {
  borderWidth?: number;
  children?: any[];
  fill?: boolean;
  highlight?: string;
  id: string;
  onDragOver?: (id?: string) => void;
  outline?: boolean;
  ref?: any;
  style?: any;
  transitionTime?: number;
};

class DropZone extends React.Component<DropZoneProps, State> {
  reference: any;
  constructor (props: DropZoneProps) {
    super(props);

    this.state = {
      isOver: false,
      canDrop: false,
    };

    this.reference = React.createRef();
  }

  get style () {
    const { style } = this.props;

    let styles = {
      width: '100%',
      height: '100%',
      position: 'relative',
      zIndex: 1,
    };

    if (style) {
      styles = {
        ...styles,
        ...style,
      };
    }

    return styles;
  }

  get innerStyle () {
    const { isOver, canDrop } = this.state;
    const { outline, highlight, borderWidth, transitionTime } = this.props;

    let backgroundColor = '';
    const borderColor = '#999';
    let boxShadow = '';

    if (highlight && outline) {
      backgroundColor = highlight;
      boxShadow = 'inset 0 0 15px rgba(144, 238, 144, 0.8)';
    }

    if (isOver) {
      backgroundColor = canDrop ? 'rgba(144, 238, 144, 1)' : 'rgba(238, 144, 144, 0.4)';
    }

    return {
      width: '100%',
      height: '100%',
      backgroundColor,
      boxShadow,
      borderStyle: outline ? 'dashed' : 'none',
      borderWidth: outline ? `${borderWidth}px` : 0,
      borderColor,
      opacity: outline ? 1 : 0,
      transition: outline ? `opacity 0.1s ease ${(transitionTime || 0) + 0.1}s` : '',
    };
  }

  dragover = (e: any) => {
    e.preventDefault(); // prevent default to allow drop

    dragController.setActiveDropZone(this).then((canDrop) => {
      this.setState({
        isOver: true,
        canDrop,
      });
    });

    const { onDragOver } = this.props;
    if (typeof onDragOver === 'function') onDragOver(this.props.id);
  };

  dragleave = (e: any) => {
    dragController.setActiveDropZone(null);
    this.stopDrag();
  };

  drop = (e: any) => {
    e.preventDefault(); // prevent default action (open as link for some elements)
    this.stopDrag();
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
    dragController.drop();
  };

  stopDrag = () => {
    this.setState({ isOver: false, canDrop: false });
  };

  render () {
    const { children } = this.props;

    return (
      <div
        ref={this.reference}
        className='hy-dropzone'
        onDrop={this.drop}
        onDragLeave={this.dragleave}
        onDragOver={this.dragover}
        // @ts-expect-error TS(2322): Type '{ width: string; height: string; position: s... Remove this comment to see the full error message
        style={this.style}
      >
        <div style={this.innerStyle} />
        {children}
      </div>
    );
  }
}

export { DropZone };
