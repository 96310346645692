import React from 'react';

import { Icon, Draggable } from '../../../hapyak-ui-toolkit';
import { comm } from '../../../services/comm';
import { toSentenceCase } from '../../../services/utils';
import { ToolType } from '../../../types/tools';

type State = any;

type DraggableToolProps = {
  id: string;
  index: number;
  onClick: (targets: any, id: string) => void;
  onDragEnd: any;
  position: any;
  tool: any;
};

class DraggableTool extends React.Component<DraggableToolProps, State> {
  onClick = () => {
    const { id, tool, onClick } = this.props;
    if (typeof onClick === 'function') onClick(tool.targets, id);
  };

  setActive = (toolType: ToolType) => {
    const { tool } = this.props;
    comm.trigger('highlightTargets', toolType && tool.targets);
    comm.trigger('runZoomTargets', !!toolType, true);
  };

  render () {
    const { id, tool, position, onDragEnd } = this.props;

    return (
      <Draggable
        tool
        key={id}
        id={id}
        {...position}
        type={id}
        allowedZones={tool.targets}
        onDragEnd={onDragEnd}
        onClick={this.onClick}
        setActive={this.setActive}
      >
        <div className='hy-tool'>
          <div className='hy-center'>
            <Icon name={tool.icon} rotated={tool.iconRotated} />
            <p className='hy-tool-name'>{tool.display}</p>
          </div>
        </div>
      </Draggable>
    );
  }
}

export { DraggableTool };
