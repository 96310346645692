import defaults from '../../DEFAULTS.json';
import { PlatformPersistenceClient } from './platformPersistenceClient';
const { DRIVE_QUERY_LOCATION } = defaults;

export class GoogleDrivePersistenceClient extends PlatformPersistenceClient {
  superCreate = this.create; // reference to parent method unavailable when overriding class properties

  // @ts-expect-error TS(7024): Function implicitly has return type 'any' because ... Remove this comment to see the full error message
  create = (data: any) => {
    const { folderId } = this._getStateParams();
    const { title, posterImage } = data.project;
    const thumbnail = data.assets.images[posterImage].src;
    const withAdditionalInfo = {
      ...data,
      persistence: {
        googleDrive: {
          folderId,
          thumbnail,
          name: title,
          projectUrl: `${window.location.origin}/${this.getUXPath('<projectid>')}`,
        },
      },
    };

    return this.superCreate(withAdditionalInfo);
  };

  _getStateParams () {
    let search = window.location.search;
    const retainedSearch = localStorage.getItem(DRIVE_QUERY_LOCATION);

    if (retainedSearch) {
      search = retainedSearch;
      localStorage.removeItem(DRIVE_QUERY_LOCATION);
    }

    if (!search) {
      console.warn('Query string is empty! Google Drive file may fail to create!');
    }

    const query = new URLSearchParams(search);
    // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    return JSON.parse(query.get('state'));
  }

  get forGoogleDrive () {
    return true;
  }
}
