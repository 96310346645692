import AWS from 'aws-sdk';

import logger from './logger';
import sessionClient from './sessionClient';
import { parseQueryString } from './utils';

const EXPIRES = 10800; // seconds
const SIGNED_URL_PARAMS = ['AWSAccessKeyId', 'Expires', 'Signature', 'x-amz-security-token'];

class SigningService {
  s3: any;
  sessionClient: any;
  signatureMap: any;
  constructor () {
    this.sessionClient = sessionClient;
    this.signatureMap = {};
  }

  init () {
    if (!this.s3) {
      try {
        const credentials = JSON.parse(atob(this.sessionClient.session.credentials));
        AWS.config.credentials = new AWS.Credentials(
          credentials.accessKeyId,
          credentials.secretAccessKey,
          credentials.sessionToken
        );
        this.s3 = new AWS.S3();
      } catch (e) {
        logger.warn('S3 setup failed', e);
      }
    }
  }

  isExpired (url: any) {
    const expires = parseQueryString(url).get('Expires');
    // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
    return !expires || expires * 1000 < Date.now();
  }

  sign (url: string | undefined, expires?: number): string {
    if (!url) return ''; // return same type
    this.init();

    // if already signed and signature is still valid, return it, unless a specific 'expires' time is passed in
    const alreadySigned = this.signatureMap[url];
    if (alreadySigned && !expires && !this.isExpired(alreadySigned)) return alreadySigned;

    // otherwise sign the url:
    const params = this.urlToBucketKey(url);
    if (this.s3 && params.Bucket && params.Key) {
      const signed = this.s3.getSignedUrl('getObject', { ...params, Expires: expires || EXPIRES });
      this.signatureMap[url] = signed;
      return signed;
    }

    return url;
  }

  unsign (url: any) {
    if (!url) return url; // return same type
    const [base] = url.split('?');
    const originalParams = parseQueryString(url);
    const unsignedParams = new URLSearchParams();
    originalParams.forEach((value, key) => {
      if (!SIGNED_URL_PARAMS.includes(key)) {
        unsignedParams.append(key, value);
      }
    });
    return [...unsignedParams].length ? `${base}?${unsignedParams.toString()}` : base;
  }

  urlToBucketKey (url: any) {
    let bucket = null;
    let key = null;
    if (url.includes('s3://')) {
      bucket = url.replace('s3://', '').split('/')[0];
      key = url.replace('s3://' + bucket, '').substring(1);
    }

    if (url.includes('amazonaws.com')) {
      const protocolless = url
        .replace('https://', '')
        .replace('http://', '')
        .replace('//', '');
      bucket = protocolless.split('.s3.amazonaws.com')[0];
      key = protocolless.replace(bucket + '.s3.amazonaws.com/', '');
      if (key.includes('?')) {
        key = this.unsign(key);
      }
    }

    return {
      Bucket: bucket,
      Key: key,
    };
  }
}

const sessionSigningServiceInstance = new SigningService();
export default sessionSigningServiceInstance;
