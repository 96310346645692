import React from 'react';

import { Button, Form, Input } from '../../../../hapyak-ui-toolkit';
import { stateController } from '../../../../services/stateController';
import { addAnnotationWithAssociations, TOOLS } from '../../../../services/toolService';
import { stopEvent } from '../../../../services/utils';
import './index.scss';
import { Size } from '../../../../types/layout/forms';
import { ToolType } from '../../../../types/tools';

type QuickAddAnnotationProps = {
  appliedTo: any;
  buttonText: string;
  compact?: boolean;
  size?: Size;
  toolType: ToolType;
};

export class QuickAddAnnotation extends React.Component<QuickAddAnnotationProps> {
  input: any;
  constructor (props: QuickAddAnnotationProps) {
    super(props);

    this.input = React.createRef();
  }

  get inputValue () {
    if (!this.input.current) return '';
    return this.input.current.inputRef.current.value;
  }

  resetInput = () => {
    if (!this.input.current) return;
    return (this.input.current.inputRef.current.value = '');
  };

  quickAddAnnotation = (e: any) => {
    stopEvent(e);
    const { appliedTo, toolType } = this.props;
    const input = this.inputValue;
    const content = input ? { content: input } : {};
    const { updatedAnnotations } = addAnnotationWithAssociations({
      appliesTo: appliedTo,
      toolType,
      overrides: {
        ...content,
      },
    });

    stateController.updateProject('ux', { annotations: updatedAnnotations });

    this.resetInput();
  };

  get tool () {
    const { toolType } = this.props;
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return TOOLS[toolType];
  }

  get asButton () {
    const { buttonText = 'Create', size = 'mini' } = this.props;
    return <Button size={size} onClick={this.quickAddAnnotation} content={buttonText} />;
  }

  get asInput () {
    const { buttonText = 'Create', size = 'mini' } = this.props;

    return (
      <div className='hy-quick-add-annotation'>
        <Form className='hy-quick-add-input' onSubmit={this.quickAddAnnotation}>
          <Input
            ref={this.input}
            placeholder={`${this.tool.display} title`}
            size={size}
            action={<Button size={size}>{buttonText}</Button>}
          />
        </Form>
      </div>
    );
  }

  render () {
    const { compact = false } = this.props;
    return compact ? this.asButton : this.asInput;
  }
}
