import _ from 'lodash';

import { AdjacentBottomOnly } from './AdjacentBottomOnly';
import { AdjacentLeftOnly } from './AdjacentLeftOnly';
import { AllTargets } from './AllTargets';
import { Newsela } from './Newsela';
import { PlayerOnly } from './PlayerOnly';

export { AllTargets, AdjacentLeftOnly, AdjacentBottomOnly, PlayerOnly, Newsela };

// source of truth for layout ids:
const _layoutMap = {
  1: AllTargets,
  2: AdjacentLeftOnly,
  3: AdjacentBottomOnly,
  4: PlayerOnly,
  5: Newsela,
};

// with ids attached to layout object:
export const layoutMap = _.forEach(_layoutMap, (layout, id) => {
  (layout as any).id = +id;
  (layout.preview as any).id = +id;
});
