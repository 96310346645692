import './index.scss';
import './toolbar.scss';

import React from 'react';

import hash from 'hash-sum';
import _ from 'lodash';

import { Project } from 'types/project';

import DEFAULTS from '../../../DEFAULTS.json';
import { JobError } from '../../../errors/JobError';
import { Icon } from '../../../hapyak-ui-toolkit';
import { getLayout, getLayoutTargets } from '../../../layouts';
import { comm } from '../../../services/comm';
import { media } from '../../../services/mediaController';
import NewProjectService from '../../../services/newProjectService';
import { getUserPreferences, setUserPreferences } from '../../../services/saveUtils';
import { getAnnotationPositionFromShape, getSelectedAnnotations } from '../../../services/selectionUtils';
import { stateController } from '../../../services/stateController';
import { requiresRemovalOfCollections } from '../../../services/toolRelationshipService';
import {
  addAnnotation,
  addAnnotations,
  cascadeRemoveAssociatedAnnotations,
  cloneAnnotations,
  deleteAnnotation,
  filterAnnotationsByEditable,
  getHiddenPanelAnnotations,
  removeSelectedAnnotations,
  stageAnnotation,
  updateAndPersistAnnotationEndTimes,
  updateAnnotation
} from '../../../services/toolService';
import { getUpdateTabCallbackKey } from '../../../services/utils';
import { Annotation } from '../../../types/annotations';
import { ToolType } from '../../../types/tools';
import { AngelouLoadingSpinner } from '../AngelouComponents/AngelouLoadingSpinner';
import ErrorBanner from '../AngelouComponents/ErrorBanner';
import { BrandingEditor } from '../BrandingEditor';
import { ExpandableContainer } from '../CommonComponents/ExpandableContainer';
import { BasicSection } from '../CommonComponents/Menu/BasicSection';
import { WrappingTabs } from '../CommonComponents/WrappingTabs';
import { PlayerEditor } from '../PlayerEditor';
import { AnnotationEditor } from './AnnotationEditor';
import { AnnotationList } from './AnnotationList';
import { VideoAnnotationRow } from './AnnotationList/VideoAnnotationRow';
import { EditorControlBar } from './EditorControlBar';
import { EmbeddedEditor } from './EmbeddedEditor';
import { LayoutOptions } from './LayoutOptions';
import { PlayerTargetSection } from './PlayerTargetSection';
import { TargetSection } from './TargetSection';
import { Timeline } from './Timeline';
import { EditorToolbar } from './Toolbar';
import { SelectReleaseModal } from './Toolbar/SelectReleaseModal';
import { EditorToolbox } from './Toolbox/EditorToolbox';
import { VideoEditor } from './VideoEditor';

const TRANSITION_TIME = 0.25;
const BORDER_WIDTH = 4;
const PADDING = 10;
const HORIZONTAL_HANDLE_SIZE = 12;
const VERTICAL_HANDLE_SIZE = 65;

const isDevMode = localStorage.getItem('hapyak_developer_mode') === 'true';

type State = any;

type UXEditorProps = {
  annotations: Annotation[];
  button: any;
  check: any;
  checkAll: any;
  checkAllIcon: any;
  duration: number;
  exclusiveFrom: any;
  float: any;
  fromToolbox: any;
  isChapterMenu: any;
  match: any;
  presets: any;
  processing: any;
  project: Project;
  quickAddButton: any;
  section: any;
  styles: any;
};

class UXEditor extends React.Component<UXEditorProps, State> {
  debouncedZoomTargets: any;
  dragging: any;
  hyContentEditorTop: any;
  playerEditor: any;
  playerElement: any;
  reportHeight: any;
  constructor (props: UXEditorProps) {
    super(props);

    this.playerElement = React.createRef();
    this.playerEditor = React.createRef();
    this.hyContentEditorTop = React.createRef();
    this.dragging = false; // not in state because we don't want to update state when this changes
    const prefs = getUserPreferences();

    this.state = {
      activeAnnotation: null,
      editingAnnotationId: null,
      editingVideo: null,
      selectedAnnotations: [],
      copiedAnnotations: [],
      preservedProjectAnnotationsForCopy: [],
      highlightedTargets: null,
      time: 0,
      selectionTool: 'rectangle',
      selection: null,
      zoneRefs: {},
      zoomOut: false,
      controlsUnavailable: false,
      showAllPanels: !!prefs[DEFAULTS.SHOW_PANELS_KEY],
      selectedSectionId: null,
      videoError: null,
      hasTranscodingError: false,
      errorMessageText: {
        title: null,
        body: null,
        mediaType: null
      },
    };

    this.debouncedZoomTargets = _.debounce(this.zoomTargets, 250);

    const {
      shouldUpdateTranscoding,
      shouldUpdateRemoteTranscoding
    } = stateController.getCurrentData('project');

    if (shouldUpdateTranscoding) {
      NewProjectService.startPoll();
      stateController.updateProject('project', { shouldUpdateTranscoding: false });
    }

    if (shouldUpdateRemoteTranscoding) {
      NewProjectService.startRemoteUpload();
      stateController.updateProject('project', { shouldUpdateRemoteTranscoding: false });
    }
  }

  componentDidMount () {
    comm.register('remoteTranscodeComplete', this.updateAnnotationEndTimes);
    comm.register('onTimeUpdate', this.ontimeupdate);
    comm.register('onVideoError', this.onVideoError);
    comm.register('setActiveAnnotation', this.setActiveAnnotation);
    comm.register('highlightTargets', this.highlightTargets);
    comm.register('deletePressed', this.deletePressed);
    comm.register('escape', this.onEscape);
    comm.register('copy', this.copyAnnotations);
    comm.register('paste', this.pasteAnnotations);
    comm.register('eKey', this.setSelectionTool.bind(this, 'ellipse'));
    comm.register('lKey', this.setSelectionTool.bind(this, 'lasso'));
    comm.register('rKey', this.setSelectionTool.bind(this, 'rectangle'));
    comm.register('stageSelection', this.stageSelection);
    comm.register('selectFromShape', this.selectFromShape);
    comm.register('selectAnnotations', this.selectAnnotations);
    comm.register('selectAnnotation', this.selectAnnotation);
    comm.register('runZoomTargets', this.runZoomTargets);
    comm.register('editVideo', this.setEditingVideo);
    comm.register('runPlayerScroll', this.runPlayerScroll);
    comm.register('processFailed', this.onProcessFailed);
    comm.register('processStarted', this.onProcessStarted);

    this.zoomTargets(this.state.showAllPanels);
  }

  componentWillUnmount () {
    comm.unregister('remoteTranscodeComplete', this.updateAnnotationEndTimes);
    comm.unregister('onTimeUpdate', this.ontimeupdate);
    comm.unregister('onVideoError', this.onVideoError);
    comm.unregister('setActiveAnnotation', this.setActiveAnnotation);
    comm.unregister('highlightTargets', this.highlightTargets);
    comm.unregister('deletePressed', this.deletePressed);
    comm.unregister('escape', this.onEscape);
    comm.unregister('copy', this.copyAnnotations);
    comm.unregister('paste', this.pasteAnnotations);
    comm.unregister('eKey', this.setSelectionTool.bind(this, 'ellipse'));
    comm.unregister('lKey', this.setSelectionTool.bind(this, 'lasso'));
    comm.unregister('rKey', this.setSelectionTool.bind(this, 'rectangle'));
    comm.unregister('stageSelection', this.stageSelection);
    comm.unregister('selectFromShape', this.selectFromShape);
    comm.unregister('selectAnnotations', this.selectAnnotations);
    comm.unregister('selectAnnotation', this.selectAnnotation);
    comm.unregister('runZoomTargets', this.runZoomTargets);
    comm.unregister('editVideo', this.setEditingVideo);
    comm.unregister('runPlayerScroll', this.runPlayerScroll);
    comm.unregister('processFailed', this.onProcessFailed);
    comm.unregister('processStarted', this.onProcessStarted);
  }

  onProcessFailed = (processName: any, response: any) => {
    const error = new JobError(response?.error?.message || 'Error transcoding video', response?.traceId);
    this.setState({ hasTranscodingError: true });
    if (processName === DEFAULTS.TRANSCODE_REMOTE_UPLOAD_KEY) {
      this.setState({
        errorMessageText: {
          title: 'Error transcoding video',
          body: 'This video needs Authentication. Replace with a video that can be directly accessed:',
          mediaType: 'URL'
        },
        videoError: error
      });
    } else {
      this.setState({
        errorMessageText: {
          title: `${processName} error`,
          body: 'There is something wrong with this video.',
          mediaType: 'video or URL'
        },
        videoError: error
      });
    }
  };

  onProcessStarted = () => {
    this.setState({ hasTranscodingError: false });
  };

  updateAnnotationEndTimes = () => {
    updateAndPersistAnnotationEndTimes((media.video.duration || 0) / 1000);
  };

  onVideoError = (error: string) => {
    const errorMsg = { message: error };
    this.setState({
      errorMessageText: {
        title: 'Error loading video in player.',
        body: 'Replace the video.',
        mediaType: 'video or URL'
      },
      videoError: errorMsg
    });
  };

  runPlayerScroll = () => {
    const { current } = this.playerEditor;
    const activateScroll = current && current.scrollHeight > current.offsetHeight;

    if (activateScroll) {
      current.scrollTop = current.scrollHeight;
    }
  };

  runZoomTargets = (zoomOut: any, immediate: any) => {
    immediate ? this.zoomTargets(zoomOut) : this.debouncedZoomTargets(zoomOut);
    requestAnimationFrame(() => comm.trigger('reloadPlayer'));
  };

  zoomTargets = (zoomOut: any) => {
    zoomOut = zoomOut || this.state.showAllPanels;
    this.setState({ zoomOut });
  };

  onEscape = () => {
    this.deselectAll();
  };

  setZoneRef = (target: any, zone: any) => {
    const { zoneRefs } = this.state;
    zoneRefs[target] = zone;
    this.setState({ zoneRefs });
  };

  setControlsUnavailable = (controlsUnavailable: any) => {
    this.setState({ controlsUnavailable });
  };

  copyAnnotations = () => {
    const annotations = [...this.annotations];
    const { selectedAnnotations } = this.state;

    this.setState({
      copiedAnnotations: selectedAnnotations
        .map((id: string) => {
          const copy = annotations.find((a) => a.id === id);
          if (!copy) return null;
          return { ...annotations.find((a) => a.id === id) };
        })
        .filter(Boolean),
      preservedProjectAnnotationsForCopy: this.allAnnotations,
    });
  };

  pasteAnnotations = () => {
    const { copiedAnnotations, preservedProjectAnnotationsForCopy } = this.state;
    const clonedAnnotations = cloneAnnotations(copiedAnnotations, preservedProjectAnnotationsForCopy);

    this.setState({ activeAnnotation: null }, () => {
      addAnnotations(clonedAnnotations, true).then(() => {
        this.selectAnnotations(clonedAnnotations.map((a) => a.id));
      });
    });
  };

  deletePressed = () => {
    const { selectedAnnotations } = this.state;

    comm.trigger('setActiveAnnotation', null);

    let annotations = [...this.allAnnotations];

    const collectionAnnotations = annotations
      .filter((a) => requiresRemovalOfCollections(a.toolType) && selectedAnnotations.includes(a.id))
      .map((a) => a.id);

    // Remove associated collection annotations as needed, then remove selected annotations
    const removeAssociated = (acc: any, curr: any) => cascadeRemoveAssociatedAnnotations(curr, acc);
    const annotationsSinCollectionAnnotations = collectionAnnotations.reduce(removeAssociated, annotations);

    annotations = removeSelectedAnnotations(selectedAnnotations, annotationsSinCollectionAnnotations);

    stateController.updateProject('ux', { annotations });
    this.deselectAll();
  };

  handleClick = (e: any) => {
    e.stopPropagation();
  };

  ontimeupdate = (time: any) => {
    this.setState({ time });
  };

  setActiveAnnotation = (activeAnnotation: Annotation | string) => {
    this.setState({ activeAnnotation });
  };

  highlightTargets = (highlightedTargets: any) => {
    this.setState({ highlightedTargets });
  };

  selectFromShape = (shape: any) => {
    if (shape && Math.abs(shape.area) >= 1) {
      const { selectedAnnotations } = this.state;
      const annotations = getSelectedAnnotations(shape, this.playerAnnotations, this.state.zoneRefs);
      if (hash(selectedAnnotations) !== hash(annotations)) {
        this.selectAnnotations(annotations);
      }
    }
  };

  stageSelection = (shape: any, pathData: any, event: any, selectionTool: any) => {
    const { zoneRefs } = this.state;
    let selection = null;

    if (shape && event) {
      const { clientX, clientY } = event;

      const { baseWidth, baseHeight, ...positionStyles } = getAnnotationPositionFromShape(
        zoneRefs.player.current,
        shape
      );

      selection = {
        shape,
        selectionTool,
        annotationProps: {
          properties: {
            svg: {
              pathData,
              baseWidth,
              baseHeight,
            },
          },
          positionStyles,
        },
        modalPosition: {
          top: clientY - 170,
          left: clientX - 12,
        },
      };
    }

    this.setState({ selection });
  };

  get stagedAnnotationEndTime () {
    const { selectedSectionId } = this.state;
    if (!selectedSectionId) return null;

    const start = _.get(media, 'editorInstance.currentTime');
    const annotations = [...this.annotations];
    const annotation = annotations.find((a) => a.id === selectedSectionId);
    return annotation && annotation.end > start ? { end: annotation.end } : {};
  }

  stageAnnotation = (toolType: ToolType, target: any, props?: any) => {
    const end = this.stagedAnnotationEndTime;
    this.highlightTargets(null);
    this.switchTab(DEFAULTS.SIDE_TOOLS_KEY, 0);
    this.switchTab(DEFAULTS.ANNOTATION_EDITOR_KEY, 0);
    const overrides = toolType !== 'question' ? {
      target,
      start: _.get(media, 'editorInstance.currentTime'),
      ...props,
      ...end,
    } : {};
    const stagedAnnotation = stageAnnotation(toolType, overrides);
    return this.setEditingAnnotation(stagedAnnotation.id, stagedAnnotation);
  };

  switchTab = (key: any, index: any) => {
    comm.trigger(getUpdateTabCallbackKey(key), index);
  };

  updateAnnotation = (id: any, changes: any) => {
    return updateAnnotation(id, changes);
  };

  selectAnnotations = (selectedAnnotations: Annotation[], preventStopEditing?: boolean) => {
    this.setState({ selectedAnnotations });
    if (!preventStopEditing) this.stopEditing();
  };

  selectAnnotation = (id: any, multiselect: any, canDeselect: any) => {
    let { editingAnnotationId, selectedAnnotations } = this.state;

    if (multiselect) {
      if (selectedAnnotations.includes(id)) {
        selectedAnnotations = this.deselectAnnotation(id); // remove from multi-selection
      } else {
        selectedAnnotations.push(id);
      }
    } else if (canDeselect || !selectedAnnotations.includes(id)) {
      selectedAnnotations = [id];
    }

    this.switchTab(DEFAULTS.SIDE_TOOLS_KEY, 0);
    this.selectAnnotations(selectedAnnotations, id === editingAnnotationId);
  };

  deselectAnnotation = (id: any) => {
    const { selectedAnnotations } = this.state;
    const index = selectedAnnotations.indexOf(id);
    selectedAnnotations.splice(index, 1);
    return selectedAnnotations;
  };

  deselectAll = () => {
    this.selectAnnotations([]);
  };

  dragStarted = (e: any, id: any, customDraggable: any) => {
    const { selectedAnnotations } = this.state;
    this.dragging = true;

    if (selectedAnnotations.includes(id)) {
      selectedAnnotations.forEach((id: any) => {
        // filter out do active annotation
        const draggable = customDraggable.controller.draggables[id];
        draggable && draggable.startDrag(e);
      });
    } else {
      customDraggable.startDrag(e);
    }
  };

  setEditingVideo = (editingVideo?: any) => {
    this.setState({ editingVideo });
  };

  setEditingAnnotation = (id?: string, stagedAnnotation?: Annotation) => {
    const annotations = [...this.annotations];
    const editingAnnotation = stagedAnnotation || annotations.find((a) => id === a.id);

    if (!editingAnnotation) {
      this.setState({ editingAnnotationId: null, activeAnnotation: null });
      return;
    }

    comm.trigger('pause');

    if (stagedAnnotation) {
      addAnnotation(stagedAnnotation);
    }

    return new Promise((resolve) => {
      // force reset
      this.setState({ editingAnnotationId: null }, () => {
        // @ts-expect-error TS(2345): Argument of type '(value: unknown) => void' is not... Remove this comment to see the full error message
        this.setState({ editingAnnotationId: id, activeAnnotation: null, selectedAnnotations: [id] }, resolve);
      });
    });
  };

  get editingAnnotation () {
    const { editingAnnotationId } = this.state;
    return editingAnnotationId && this.annotations.find((a: Annotation) => a.id === editingAnnotationId);
  }

  get editingVideo () {
    return this.state.editingVideo;
  }

  deleteAnnotation = (id: string, notify = true, requiresCascadeDelete = false) => {
    notify && comm.trigger('setActiveAnnotation', null);

    if (requiresCascadeDelete) {
      const { annotations } = this.props;
      const filteredAnnotations = cascadeRemoveAssociatedAnnotations(id, annotations);
      stateController.updateProject('ux', { annotations: filteredAnnotations });
    } else {
      deleteAnnotation(id);
    }

    notify && this.deselectAll();
  };

  stopEditing = () => {
    this.setEditingAnnotation();
    this.setEditingVideo();
  };

  onDragEnd = (dropZoneId: any, draggableId: any, options: any = {}) => {
    this.dragging = false;

    if (!dropZoneId || !draggableId) return;

    const { fromToolbox, styles } = options;

    if (draggableId === 'chapter' || draggableId === 'section') {
      dropZoneId = 'hidden';
    }

    if (fromToolbox) {
      this.stageAnnotation(draggableId, dropZoneId);
      return;
    }

    let updates = { target: dropZoneId };

    if (dropZoneId === 'player' && styles) {
      // @ts-expect-error TS(2322): Type '{ styles: any; target: any; }' is not assign... Remove this comment to see the full error message
      updates = { ...updates, styles };
    }

    this.updateAnnotation(draggableId, updates);

    requestAnimationFrame(() => comm.trigger('reloadPlayer'));
  };

  getDroppableTargets = () => {
    return this.targetSectionProps.map((props: any, index: any) => {
      if (props.target === 'player') {
        return (
          <PlayerTargetSection
            key={index.toString()}
            ref={this.playerElement}
            {...props}
            onMouseEnter={this.setControlsUnavailable.bind(this, true)}
            onMouseLeave={this.setControlsUnavailable.bind(this, false)}
          />
        );
      }

      return <TargetSection {...props} key={index.toString()} />;
    });
  };

  get allAnnotations () {
    return this.props.annotations;
  }

  get editableAnnotations () {
    return filterAnnotationsByEditable(this.allAnnotations);
  }

  get annotations () {
    return isDevMode ? this.allAnnotations : this.editableAnnotations;
  }

  get targetSectionProps () {
    const annotations = [...this.annotations, ...this.emptyPanelPlaceholderAnnotations];

    return this.layoutTargets
      .filter((target: any) => !target._hidden)
      .map(({
        name,
        _direction
      }: any) => {
        const {
          activeAnnotation,
          time,
          selectedAnnotations,
          selectionTool,
          highlightedTargets,
          zoomOut,
        } = this.state;
        const filtered = this.filterAnnotationsByTarget(annotations, name);
        const visibleAnnotations = this.filterAnnotationsByTime(filtered, time);

        return {
          key: name,
          target: name,
          direction: _direction,
          activeAnnotation,
          selectedAnnotations,
          highlightedTargets,
          annotations,
          filtered,
          visibleAnnotations,
          isOver: false,
          edit: this.setEditingAnnotation,
          select: this.selectAnnotation,
          dragStarted: this.dragStarted,
          project: this.props.project,
          activeTargets: this.activeTargets,
          onDragEnd: this.onDragEnd,
          selectionTool,
          setZoneRef: this.setZoneRef,
          zoomOut,
          transitionTime: TRANSITION_TIME,
          borderWidth: BORDER_WIDTH,
        };
      });
  }

  filterAnnotationsByTime = (annotations: Annotation[], time: any) => annotations.filter((a: Annotation) => time >= a.start && time <= a.end);

  filterAnnotationsByTarget = (annotations: any, target: any) => {
    return annotations.filter((a: Annotation) => a.target === target);
  };

  setSelectionTool = (selectionTool: string) => {
    this.setState({ selectionTool });
  };

  get activeTargets () {
    let targets = [];

    const annotations = [...this.annotations];

    if (annotations) {
      targets = this.filterAnnotationsByTime(annotations, this.state.time).map((a: any) => a.target);
    }

    return targets;
  }

  get annotationEditor () {
    const { duration, presets, match } = this.props;
    const historian = stateController.historian;

    return (
      <AnnotationEditor
        annotation={this.editingAnnotation}
        annotations={this.annotations}
        delete={this.deleteAnnotation}
        dirty={historian.position !== historian.positionBeforeStaging}
        duration={duration}
        edit={this.setEditingAnnotation}
        layoutTargets={this.layoutTargets}
        match={match}
        presets={presets}
        updateAnnotation={this.updateAnnotation}
      />
    );
  }

  get videoEditor () {
    const { processing } = this.props;
    return <VideoEditor processing={processing} />;
  }

  get toolbox () {
    const layoutTargetNames = this.layoutTargets.map((target: any) => target.name);
    return (
      <EditorToolbox
        reportHeight={this.reportHeight}
        layoutTargets={layoutTargetNames}
        stageAnnotation={this.stageAnnotation}
        onDragEnd={this.onDragEnd}
      />
    );
  }

  get playerTab () {
    const { player } = stateController.getCurrentData();
    return <PlayerEditor className='hy-scrollable' {...player} />;
  }

  get brandingTab () {
    const { branding } = stateController.getCurrentData();
    return <BrandingEditor className='hy-scrollable' {...branding} />;
  }

  onSectionSelection = (id: any) => {
    return new Promise((res) => {
      // @ts-expect-error TS(2345): Argument of type '(value: unknown) => void' is not... Remove this comment to see the full error message
      this.setState({ selectedSectionId: id }, res);
    });
  };

  get annotationList () {
    const { processing } = this.props;
    const { editingAnnotationId, selectedAnnotations, selectedSectionId, time } = this.state;
    let { activeAnnotation } = this.state;

    if (editingAnnotationId) activeAnnotation = editingAnnotationId;

    return (
      <BasicSection
        renderedContent={
          <AnnotationList
            category='annotations'
            annotations={this.annotations}
            visibleAnnotations={this.visibleAnnotations}
            selectedAnnotations={selectedAnnotations}
            showSelectedCount={false}
            showAllElementsAsCheckBox
            activeAnnotation={activeAnnotation}
            update={this.updateAnnotation}
            edit={this.setEditingAnnotation}
            select={this.selectAnnotation}
            onSectionSelection={this.onSectionSelection}
            selectedSectionId={selectedSectionId}
            currentTime={time}
            includesAnnotations={this.includesAnnotations}
            forceIncludeRows={<VideoAnnotationRow processing={processing} error={this.showVideoError} />}
            permitRowHeightVariation
            typeAsIcon
            paginated
            minimal
            layoutTargets={this.layoutTargets.map((t: any) => t.name)}
            processing={processing}
          />
        }
      />
    );
  }

  get visibleAnnotations () {
    const { time } = this.state;
    return this.filterAnnotationsByTime(this.annotations, time);
  }

  get playerAnnotations () {
    return this.visibleAnnotations.filter((a: any) => a.target === 'player');
  }

  get includesAnnotations () {
    const { activeAnnotation } = this.state;
    const annotation = this.annotations.find((a: Annotation) => a.id === activeAnnotation);
    if (!annotation) return [];
    return this.annotations.filter((a: Annotation) => a.appliesTo && a.appliesTo.includes(annotation.id)).map((a: Annotation) => a.id);
  }

  get targetsContainerStyles () {
    const { showAllPanels, zoomOut } = this.state;

    return {
      padding: showAllPanels || zoomOut ? PADDING + 'px' : 0,
      transition: `padding ${TRANSITION_TIME}s ease`,
    };
  }

  get sideTools () {
    return (
      <div className='hy-side-sections'>
        <WrappingTabs
          className='hy-wrapping-tabs-light'
          components={this.rightSideTabs}
          type={DEFAULTS.SIDE_TOOLS_KEY}
          updateTabCallbackKey={getUpdateTabCallbackKey(DEFAULTS.SIDE_TOOLS_KEY)}
        />
      </div>
    );
  }

  get annotationsTab () {
    if (this.editingAnnotation) return this.annotationEditor;
    if (this.editingVideo) return this.videoEditor;

    return <div className='hy-side-section-scroll'>{this.annotationList}</div>;
  }

  toggleShowAllPanels = () => {
    const showAllPanels = !this.state.showAllPanels;
    setUserPreferences({ [DEFAULTS.SHOW_PANELS_KEY]: showAllPanels });
    this.setState({ showAllPanels }, () => {
      this.zoomTargets(showAllPanels);
      requestAnimationFrame(() => comm.trigger('reloadPlayer'));
    });
  };

  get layoutTab () {
    const { showAllPanels } = this.state;
    return (<LayoutOptions project={this.props.project} layout={this.layout} toggleShowPanels={this.toggleShowAllPanels} showPanels={showAllPanels} />);
  }

  get layoutId () {
    const { project } = this.props;
    const { layoutId } = project;
    return layoutId;
  }

  get layout () {
    return getLayout(this.layoutId);
  }

  get layoutTargets () {
    return getLayoutTargets(this.layoutId);
  }

  get emptyPanelPlaceholderAnnotations () {
    const { showAllPanels, zoomOut } = this.state;
    const { duration } = this.props;
    if (!(showAllPanels || zoomOut)) return [];
    return getHiddenPanelAnnotations(this.layoutTargets, this.visibleAnnotations, duration);
  }

  get rightSideTabs () {
    return [
      {
        name: `Interactive elements (${this.annotations.length})`,
        render: () => (
          <div className='hy-side-section'>
            <div className='hy-side-section-scroll'>{this.annotationsTab}</div>
          </div>
        ),
      },
      {
        name: 'Template panels',
        render: () => (
          <div className='hy-side-section'>
            <div className='hy-side-section-scroll'>{this.layoutTab}</div>
          </div>
        ),
      },
      {
        name: 'Video player',
        render: () => (
          <div className='hy-side-section'>
            <div className='hy-side-section-scroll'>{this.playerTab}</div>
          </div>
        ),
      },
      {
        name: 'Styles',
        render: () => (
          <div className='hy-side-section'>
            <div className='hy-side-section-scroll'>{this.brandingTab}</div>
          </div>
        ),
      },
    ];
  }

  get leftSideSections () {
    const { duration } = this.props;
    let isEditing = false;
    let {
      activeAnnotation,
      controlsUnavailable,
      editingAnnotationId,
      selectedAnnotations,
      selectionTool,
      time,
    } = this.state;

    if (editingAnnotationId) {
      activeAnnotation = editingAnnotationId;
      isEditing = true;
    }

    const selecting = selectedAnnotations.length !== 0;

    return [
      {
        id: 'LEFT_TOP_SECTION',
        render: () => {
          return (
            <div className='hy-editor-content-section'>
              <EditorToolbar
                setSelectionTool={this.setSelectionTool}
                time={time}
                parentRef={this.hyContentEditorTop}
                selecting={selecting}
                selectionTool={selectionTool}
              />
              <div
                ref={this.playerEditor}
                className='hy-scrollable-targets-wrapper'
                style={{ scrollBehavior: 'smooth' }}
              >
                <div className='hy-targets-container' style={this.targetsContainerStyles}>
                  <div className='hy-targets'>
                    <EmbeddedEditor
                      // @ts-expect-error TS(2322): Type '{ activeTargets: any; controlsUnavailable: a... Remove this comment to see the full error message
                      activeTargets={this.activeTargets}
                      controlsUnavailable={controlsUnavailable}
                      placeholderAnnotations={this.emptyPanelPlaceholderAnnotations}
                    />
                    {this.getDroppableTargets()}
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        id: 'LEFT_BOTTOM_SECTION',
        render: () => {
          return (
            <div className='hy-editor-control-section'>
              <Timeline
                currentTime={time}
                duration={duration}
                annotations={this.annotations}
                activeAnnotation={activeAnnotation}
                selectedAnnotations={selectedAnnotations}
                hideMarkers={isEditing}
              />
            </div>
          );
        },
      },
    ];
  }

  get sections () {
    const { duration } = this.props;
    const { time } = this.state;

    return [
      {
        id: 'LEFT_SIDE',
        render: () => {
          return (
            <div className='hy-editor-left-section'>
              <ExpandableContainer
                sections={this.leftSideSections}
                minPx={200}
                draggable
                simple
                vertical
                handle={
                  <EditorControlBar
                    annotations={this.annotations}
                    duration={duration}
                    currentTime={time}
                    height={VERTICAL_HANDLE_SIZE}
                  />
                }
                handleSize={VERTICAL_HANDLE_SIZE}
              />
            </div>
          );
        },
      },
      {
        id: 'RIGHT_SIDE',
        offset: 50,
        render: () => {
          return <div className='hy-editor-right-section'>{this.sideTools}</div>;
        },
      },
    ];
  }

  get showLoader () {
    return !this.props.duration && !this.state.videoError;
  }

  get showVideoError () {
    return !this.props.duration && this.state.videoError;
  }

  get redErrorMessage () {
    const { videoError, errorMessageText } = this.state;
    const errorBodyArray = [
      errorMessageText.body,
      <li key='list-1'>
        Edit the <Icon name='video' /> Video row under the Annotations tab.
      </li>,
      <li key='list-2'>
        Click the Replace button to open the upload modal.
      </li>,
      <li key='list-3'>
        Add a new {errorMessageText.mediaType} and click continue.
      </li>,
    ];

    return (
      <ErrorBanner
        title={errorMessageText.title}
        body={errorBodyArray}
        error={videoError}
      />
    );
  }

  render () {
    const { duration } = this.props;
    const { selection, hasTranscodingError } = this.state;
    return (
      <div className='hy-absolute hy-content-editor' onClick={this.handleClick}>
        {(this.showVideoError || hasTranscodingError) && this.redErrorMessage}
        {this.showLoader && <AngelouLoadingSpinner />}

        <div className='hy-content-editor-inner' style={{ opacity: this.showLoader ? 0 : 1 }}>
          <div className='hy-content-editor-top' ref={this.hyContentEditorTop}>
            {this.toolbox}
          </div>
          <div className='hy-content-editor-bottom'>
            <ExpandableContainer
              sections={this.sections}
              minPx={400}
              draggable
              simple
              handleSize={HORIZONTAL_HANDLE_SIZE}
            />
          </div>

          {selection && selection.modalPosition && (
            <SelectReleaseModal
              createAnnotation={this.stageAnnotation}
              selection={selection}
              annotations={this.annotations}
              duration={duration}
            />
          )}
        </div>
      </div>
    );
  }
}

export { UXEditor };
