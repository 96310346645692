import DEFAULTS from '../../../DEFAULTS.json';
import { media } from '../../../services/mediaController';
import signingService from '../../../services/signingService';
import { assetService, stateController } from '../../../services/stateController';
import { getContentDisplay, getToolFromAnnotation } from '../../../services/toolService';
import { convertToS3URL } from '../../../services/utils';
import { Annotation } from '../../../types/annotations';

const findByTypeLanguageLexile = (files: any, type: any, language: any, lexileLevel: any) => {
  return files.find((f: any) => f.type === type && f.language === language && f.lexileLevel === lexileLevel);
};

const getLocalizableAnnotations = () => {
  const annotations = stateController.getCurrentData('ux').annotations || [];

  return annotations.reduce((obj: any, annotation: Annotation) => {
    if (!getToolFromAnnotation(annotation).localizable) {
      return obj;
    }

    obj[annotation.id] = getContentDisplay(annotation);
    return obj;
  }, {});
};

export const formatProcessingData = ({
  languages = [],
  types,
  vttSrc,
  lexileLevel
}: any) => {
  if (!types) {
    return null;
  }

  const nativeLang = media.video.language;
  const arbs = assetService.getArbArray();
  const videoTextArb = findByTypeLanguageLexile(arbs, DEFAULTS.ARB_VIDEO_TEXT, nativeLang, lexileLevel);
  const annotationTextArb = findByTypeLanguageLexile(arbs, DEFAULTS.ARB_ANNOTATION_TEXT, nativeLang, lexileLevel);

  const annotations = getLocalizableAnnotations();
  let willTranslate = false;

  const data = types.reduce((obj: any, type: any) => {
    let processData = null;

    if (!type.generate && !type.translate) {
      return obj;
    }

    if (type.generate) {
      if (type.name === 'annotations') {
        processData = { annotations };
      } else {
        processData = {};
      }
    } else {
      willTranslate = true;

      if (type.name === 'videoText' && videoTextArb && videoTextArb.src) {
        const arb = convertToS3URL(signingService.unsign(videoTextArb.src));
        processData = { arb, translate: true };
      }

      if (type.name === 'captions' && vttSrc) {
        const vtt = convertToS3URL(signingService.unsign(vttSrc));
        processData = { vtt, translate: true };
      }

      if (type.name === 'annotations' && annotationTextArb && annotationTextArb.src) {
        const arb = convertToS3URL(signingService.unsign(annotationTextArb.src));
        processData = { arb, translate: true };
      }

      if (type.name === 'audioDescription') {
        processData = null; // currently we don't support translating audio description
      }
    }

    obj[type.name] = processData;
    return obj;
  }, {});

  return {
    languages: willTranslate ? languages : [], // pass empty array if not translating anything
    types: data,
    lexileLevel,
  };
};
