import presets from '../../../src/tools/ChapterMenu/Presets';

export const defaultAnnotations = [
  {
    type: 'chapterMenu',
    overrides: {
      start: 0,
      end: '100%',
      target: 'adjacent_left',
      internal: {
        name: 'Chapter Menu',
      },
      styles: presets.thick,
    },
  },
  {
    type: 'chapter',
    overrides: {
      start: 0,
      end: 10,
      content: 'Example Chapter 1',
      internal: {
        name: 'Example Chapter 1',
      },
      appliesTo: [0],
      target: 'hidden',
    },
  },
  {
    type: 'chapter',
    overrides: {
      start: 10,
      end: 20,
      content: 'Example Chapter 2',
      internal: {
        name: 'Example Chapter 2',
      },
      appliesTo: [0],
      target: 'hidden',
    },
  },
  {
    type: 'chapter',
    overrides: {
      start: 20,
      end: '100%',
      content: 'Example Chapter 3',
      internal: {
        name: 'Example Chapter 3',
      },
      appliesTo: [0],
      target: 'hidden',
    },
  },
];
