import React from 'react';

import './index.scss';
import { Header, Divider } from 'semantic-ui-react';

type SectionProps = {
  as: string;
  children?: any;
  headerClassName?: string;
  id?: string;
  inlineComponent?: JSX.Element;
  placeholder?: string | boolean | null;
  style?: any;
  title: JSX.Element | string | null;
};

type State = any;

class Section extends React.Component<SectionProps, State> {
  get style () {
    const { placeholder } = this.props;
    return {
      height: placeholder || '',
    };
  }

  get header () {
    const { as, title, headerClassName = '', inlineComponent = null, id = null } = this.props;
    const h = as || 'h1';
    const noContent = [title, inlineComponent].every((val) => !val);

    return noContent ? null : (
      <Header as={h} className={headerClassName} id={id}>
        {title} {inlineComponent}
      </Header>
    );
  }

  render () {
    const { as, placeholder, style, children } = this.props;

    const h = as || 'h1';
    const level = h.replace('h', '');
    const small = +level >= 4;

    return (
      <div className={`hapkit-section-component ${h}`} style={style}>
        {this.header}
        {!small && <Divider />}
        <div
          className={`hy-section-component-content ${placeholder ? 'hy-placeholder' : ''}`}
          style={this.style as React.CSSProperties}
        >
          {children}
        </div>
      </div>
    );
  }
}

export { Section };
