/* eslint-disable @typescript-eslint/no-empty-function */
import _ from 'lodash';

import logger from '../logger';

const DEBUGGER_ENABLED_KEY = 'studio-debugger-enabled';

const DEBUGGER_SERVICE_EVENTS = {
  HEARTBEAT: 'HEARTBEAT',
  DEBUGGER_STARTED: 'DEBUGGER_STARTED',
  PROJECT_JSON_UPDATED: 'PROJECT_JSON_UPDATED',
  SESSION_UPDATED: 'SESSION_UPDATED',
  CONFIG_UPDATED: 'CONFIG_UPDATED',
};

class DebuggerService {
  codec: any;
  transport: any;
  events = DEBUGGER_SERVICE_EVENTS;
  constructor ({
    transport,
    codec,
    enabled
  }: any) {
    if (!enabled) return;

    this.transport = transport;
    this.codec = codec;
    this.send({ event: { name: 'DEBUG_STARTED' } });
    this.scheduleTimer();
  }

  scheduleTimer () {
    window.setTimeout(() => {
      this.send({
        event: {
          name: this.events.HEARTBEAT,
          payload: {
            ts: new Date().toISOString(),
            memory: { usedJSHeapSize: (performance as any).memory.usedJSHeapSize / Math.pow(1000, 2) },
          },
        },
      });
      this.scheduleTimer();
    }, 3000);
  }

  cleanup () {}

  send ({
    event
  }: any) {
    const message = this.codec.encode(event);
    this.transport.send({ message });
  }
}

class JSONCodec {
  encode (event: any) {
    return JSON.stringify(event);
  }
}

class PostMessageTransport {
  window: any;
  constructor ({
    window
  }: any) {
    this.window = window;
  }

  send ({
    message
  }: any) {
    this.window && this.window.postMessage(message, '*');
  }
}

const isEnabled = () => {
  return localStorage.getItem(DEBUGGER_ENABLED_KEY) === 'true';
};

const createDebugger = () => {
  if (isEnabled()) {
    const STUDIO_DASHBOARD_URL = 'https://localhost:3000/dashboard';
    const STUDIO_DASHBOARD_CONTEXT = 'hapyak-studio-dashboard';
    const STUDIO_DASHBOARD_FEATURES = 'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';

    logger.log('Studio debugger enabled with ' + STUDIO_DASHBOARD_URL);

    const targetWindow = window.open(STUDIO_DASHBOARD_URL, STUDIO_DASHBOARD_CONTEXT, STUDIO_DASHBOARD_FEATURES);

    window.addEventListener('message', (event) => {
      if (_.get(event, 'data.source', '').includes('react-devtools')) return;
      if (event.data === 'DASHBOARD READY') {
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        event.source.postMessage('STUDIO READY', '*');
      }
    });

    const transport = new PostMessageTransport({ window: targetWindow });
    const codec = new JSONCodec();

    return new DebuggerService({ transport, codec, enabled: isEnabled() });
  } else {
    return {
      send: () => {},
      cleanup: () => {},
      events: DEBUGGER_SERVICE_EVENTS,
    };
  }
};

export default createDebugger;
