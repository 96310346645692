import { ADJACENT_TARGETS } from '../../../../layouts';

export const TO_LINK_TARGETS = [...ADJACENT_TARGETS, 'player', 'new_tab'];

export const EXCLUDED_COMPLEX_EVENT_ANNOTATIONS = ['quiz', 'quizSection', 'quizQuestion', 'quizAnswer'];

const VERBAGE = {
  ON_SINGLE_ANNOTATION: 'On target annotation',
  ON_PLAYER: 'On player',
};

export const actionMap = {
  toAnnotation: 'Jumping to target annotation',
  toTime: 'Jumping to player time',
  toLink: 'Launch in target',
  applyGate: VERBAGE.ON_SINGLE_ANNOTATION,
  releaseGate: VERBAGE.ON_SINGLE_ANNOTATION,
  disableSeek: VERBAGE.ON_PLAYER,
  enableSeek: VERBAGE.ON_PLAYER,
  play: VERBAGE.ON_PLAYER,
  pause: VERBAGE.ON_PLAYER,
  sendData: 'Endpoint URL',
  confirmAction: 'Overlay Prompt',
  showAnnotation: 'Select Annotation to Show',
  hideAnnotation: 'Select Annotation to Hide',
  setPanel: 'Select Panel and Annotation to Move',
  updateScore: 'update score',
  setVariables: 'Set custom Variable',
  enterFullscreen: 'Initiate fullscreen',
  exitFullscreen: 'End fullscreen',
};
