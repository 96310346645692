import { AwsS3Publisher } from './providers/AwsS3Publisher';
import { GoogleCloudPublisher } from './providers/GoogleCloudPublisher';
import { GoogleDrivePublisher } from './providers/GoogleDrivePublisher';

const publisherMap = {
  'aws-s3': AwsS3Publisher,
  'google-drive': GoogleDrivePublisher,
  'gcp-gcs': GoogleCloudPublisher,
};

export class PublisherFactory {
  static create (publishTo: any) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return publisherMap[publishTo];
  }
}
