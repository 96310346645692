import { AdjacentBottomOnly } from '../../layouts/AdjacentBottomOnly';
import { horizontalSampleVideo } from '../defaults/sampleVideos';
import { defaultAnnotations } from './defaultAnnotations';
import { preview } from './preview';

export const ContentBelow = {
  defaultAnnotations,
  layout: AdjacentBottomOnly,
  preview,
  video: horizontalSampleVideo,
};
