import React from 'react';

import { Button } from '../../../hapyak-ui-toolkit';
import { media } from '../../../services/mediaController';
import { assetService, stateController } from '../../../services/stateController';
import { ImageMetadata } from '../../../types/assets';

type ScreenshotButtonProps = {
  handler?: () => void;
  onClick?: () => void;
  onComplete?: (screenshot?: ImageMetadata) => void;
};

class ScreenshotButton extends React.Component<ScreenshotButtonProps> {
  onClick = () => {
    const { onClick, handler } = this.props;

    if (onClick) {
      onClick();
    }

    if (handler && typeof handler === 'function') {
      handler();
    } else {
      this.snapshot();
    }
  };

  snapshot = () => {
    const { onComplete } = this.props;

    const time = Math.floor(media.previewInstance.currentTime) || 0;
    const screenshot = assetService.getScreenshot(time);

    if (typeof onComplete === 'function') onComplete(screenshot);

    if (!screenshot) return;

    stateController.updateProject('project', { posterImage: screenshot.id });
  };

  render () {
    return (
      <Button
        icon='camera'
        onClick={this.onClick}
      />
    );
  }
}

export { ScreenshotButton };
