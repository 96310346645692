import './index.scss';

import React, { Component } from 'react';

import { Checkbox, Table } from '@newsela/angelou';
import _ from 'lodash';

import DEFAULTS from '../../../../DEFAULTS.json';
import { Confirm, CustomDropdown, Step } from '../../../../hapyak-ui-toolkit';
import { comm } from '../../../../services/comm';
import { LEXILE_LEVEL_DEFAULT } from '../../../../services/lexileService';
import { media } from '../../../../services/mediaController';
import { isLocalizationActive } from '../../../../services/persistence/platformService';
import { assetService, stateController } from '../../../../services/stateController';
import hms from '../../../../services/time';
import { createOrderedLanguageOptions } from '../../../../services/translation/utils';
import visService from '../../../../services/visService';
import WarningBanner from '../../AngelouComponents/WarningBanner';
import { isoCodeToName } from '../../AssetsEditor/isoLanguageCodes';
import { BasicSection } from '../../CommonComponents/Menu/BasicSection';
import { NonReliantPublishButton } from '../../CommonComponents/NonReliantPublishButton';
import { formatProcessingData } from '../../LocalizationTableList/utils';

type State = any;

type VisProcessProps = {
  activeProcessMessage: any;
  annotationsTranslation?: any;
  canEdit: boolean;
  captionsTranslation?: any;
  localizationProvisioned: any;
  onClose: any;
  translating: boolean;
  processes: any;
  videoTextTranslation?: any;
}

export class VisProcess extends Component<VisProcessProps, State> {
  constructor (props: VisProcessProps) {
    super(props);
    this.state = this.initialState;

    comm.register('processCanceled', this.onProcessCanceled);
  }

  componentWillUnmount () {
    comm.unregister('processCanceled', this.onProcessCanceled);
  }

  get initialState () {
    const { translating } = this.props;
    const isTranslating = !!translating;
    const isGenerating = !isTranslating;

    return {
      selectedLanguages: [],
      lexileLevel: (this.lexileLevelOptions[0] || {}).value,
      captionsGeneration: isGenerating,
      captionsTranslation: isTranslating,
      annotationsGeneration: isGenerating,
      annotationsTranslation: isTranslating,
      videoTextGeneration: isGenerating,
      videoTextTranslation: isTranslating,
      audioDescriptionGeneration: isGenerating,
      audioDescriptionTranslation: isTranslating,
      localizationStarted: isLocalizationActive(),
      pendingRestart: false,
      loading: false,
    };
  }

  get processes () {
    const { processes = {} } = this.props;
    return processes;
  }

  get mediaDurationInSeconds () {
    // return next whole minute in seconds format
    const { duration } = media.video;
    const roundedDurationToNextMinute = hms.msToNextMinute(duration);
    return hms.minutesToMS(roundedDurationToNextMinute) / 1000;
  }

  get processInfo () {
    const hasLangFile = (file: any) => file.language === media.video.language;
    const hasType = (assetSearchMethod: any, type: any) =>
      assetSearchMethod()
        .filter((file: any) => file.type === type)
        .find(hasLangFile);
    const hasArb = (type: any) => !!hasType(assetService.getArbArray, type);
    const hasVtt = (type: any) => !!hasType(assetService.getVttArray, type);
    const hasNonEditableAnnotations = () => !!stateController.getCurrentData('ux').annotations.length;

    return [
      {
        name: 'captions',
        sectionLabel: DEFAULTS.VTT_CAPTIONS,
        onChangeGenerationValue: 'captionsGeneration',
        onChangeTranslationValue: 'captionsTranslation',
        label: 'GenerateXXXXXXXXX',
        baseProcessingTime: 0, // TODO: remove all credit/transaction code
        translationProcessingTime: 0,
        hasBaseFile: hasVtt.bind(this, DEFAULTS.VTT_CAPTIONS),
      },
      {
        name: 'annotations',
        sectionLabel: DEFAULTS.ARB_ANNOTATION_TEXT,
        onChangeGenerationValue: 'annotationsGeneration',
        onChangeTranslationValue: 'annotationsTranslation',
        label: 'Generate (included)',
        baseProcessingTime: 0,
        translationProcessingTime: 0,
        hasBaseFile: hasNonEditableAnnotations,
        canGenerate: hasNonEditableAnnotations,
      },
      {
        name: 'videoText',
        sectionLabel: DEFAULTS.ARB_VIDEO_TEXT,
        onChangeGenerationValue: 'videoTextGeneration',
        onChangeTranslationValue: 'videoTextTranslation',
        label: 'Generate',
        baseProcessingTime: 0,
        translationProcessingTime: 0,
        hasBaseFile: hasArb.bind(this, DEFAULTS.ARB_VIDEO_TEXT),
      },
    ];
  }

  get filteredProcessInfo () {
    const displayedProcesses = Object.keys(this.processes).filter((key) => !!this.processes[key]);
    return this.processInfo.filter((process) => !!displayedProcesses.includes(process.name));
  }

  handleToggle = ({
    type,
    checked
  }: any, e: any) => {
    this.setState({ [type]: !checked });
  };

  get assessedState (): any {
    const processes = {};

    this.processInfo.forEach((process) => {
      const gKey = process.onChangeGenerationValue;
      const tKey = process.onChangeTranslationValue;
      const canGenerate = typeof process.canGenerate === 'function' ? process.canGenerate() : true;
      const willGenerate = canGenerate && this.state[gKey];
      const canTranslate = process.hasBaseFile();

      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      processes[gKey] = willGenerate;
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      processes[tKey] = (canTranslate || willGenerate) && this.state[tKey];
    });

    return processes;
  }

  createProcessRow = (info: any) => {
    const { canEdit } = this.props;
    const { canGenerate, onChangeGenerationValue, sectionLabel } = info;
    const disabled = canGenerate && !canGenerate();
    const willGenerate = this.assessedState[onChangeGenerationValue];

    return (
      <Checkbox
        label={sectionLabel}
        checked={willGenerate}
        disabled={disabled || !canEdit}
        onChange={this.handleToggle.bind(this, {
          type: onChangeGenerationValue,
          checked: willGenerate,
        })}
      />
    );
  };

  get captionRow () {
    if (!this.processes.captions) return null;
    return this.createProcessRow(this.processInfo.find((process) => process.name === 'captions'));
  }

  // Commented out per VIDEO-439
  // get videoTextRow () {
  //   if (!this.processes.videoText) return null;
  //   return this.createProcessRow(this.processInfo.find((process) => process.name === 'videoText'));
  // }

  get annotationsRow () {
    if (!this.processes.annotations) return null;
    return this.createProcessRow(this.processInfo.find((process) => process.name === 'annotations'));
  }

  handleClose = () => {
    const { onClose } = this.props;
    onClose && onClose();
  };

  getProcesses = () => {
    const { selectedLanguages, lexileLevel } = this.state;
    const info = this.filteredProcessInfo;

    const results = info.map((process) => {
      return {
        name: process.name,
        generate: this.assessedState[process.onChangeGenerationValue],
        translate: this.assessedState[process.onChangeTranslationValue],
      };
    });

    return {
      languages: selectedLanguages,
      types: results,
      vttSrc: this.getVttSrcByLexileLevel(lexileLevel),
      lexileLevel,
    };
  };

  get dataLanguageRows () {
    const { selectedLanguages } = this.state;
    const mediaDuration = this.mediaDurationInSeconds;

    return selectedLanguages.map((language: any) => {
      return (
        {
          language: isoCodeToName(language),
          duration: hms.secondsToRoundedMins(mediaDuration)
        }
      );
    });
  }

  get languageRows () {
    return (
      <div id='translate'>
        <Table
          columns={[
            {
              accessor: 'language',
              Header: 'Language'
            },
            {
              accessor: 'duration',
              Header: 'Duration (minutes)'
            }
          ]}
          data={this.dataLanguageRows}
          title='Languages'
        />
      </div>
    );
  }

  get processRows () {
    return (
      <>
        {this.captionRow}
        {/* Commented out per video-439 */}
        {/* {this.videoTextRow} */}
        {this.annotationsRow}
      </>
    );
  }

  get summary () {
    const { translating } = this.props;

    return translating ? this.languageRows : this.processRows;
  }

  onLanguageChange = (e: any, data: any) => {
    this.setState({ selectedLanguages: data.value });
  };

  onLexileLevelChange = (e: any, data: any) => {
    this.setState({ lexileLevel: data.value });
  };

  onProcessCanceled = (processName: any) => processName === DEFAULTS.LOCALIZE_PLATFORM_KEY && this.setState({ localizationStarted: false });

  get languages () {
    const native = media.video.language;
    // Can't translate to native language -translation service will fail
    return createOrderedLanguageOptions(native, null).filter(({
      value
    }: any) => value !== native);
  }

  get nativeVtts () {
    const vtts = assetService.getVttArray();
    const native = media.video.language;
    return vtts.filter((a: any) => a.language === native);
  }

  get nativeArbs () {
    const arbs = assetService.getArbArray();
    const native = media.video.language;
    return arbs.filter((a: any) => a.language === native);
  }

  get lexileLevelOptions () {
    const levels = _.uniq([...this.nativeArbs, ...this.nativeVtts].map((a) => a.lexileLevel || LEXILE_LEVEL_DEFAULT));
    return levels.map((l) => ({ key: l, value: l, text: l }));
  }

  getVttSrcByLexileLevel = (lexileLevel = LEXILE_LEVEL_DEFAULT) => {
    const vtt = this.nativeVtts.find((asset: any) => asset.lexileLevel === lexileLevel) || {};
    return vtt.src;
  };

  get languageDropdown () {
    const { canEdit } = this.props;

    return (
      <CustomDropdown
        onChange={this.onLanguageChange}
        disabled={!this.hasTranslation || !canEdit}
        placeholder='Select Language'
        multiple
        search
        fluid
        selection
        options={this.languages}
      />
    );
  }

  get lexileLevelDropdown () {
    const { canEdit } = this.props;

    return (
      <CustomDropdown
        onChange={this.onLexileLevelChange}
        disabled={!this.hasTranslation || !canEdit || !this.lexileLevelOptions.length}
        placeholder='Select Captions Lexile Level'
        fluid
        selection
        value={this.state.lexileLevel}
        options={this.lexileLevelOptions}
      />
    );
  }

  get hasTranslation () {
    const { captionsTranslation, annotationsTranslation, videoTextTranslation } = this.assessedState;
    return !![captionsTranslation, annotationsTranslation, videoTextTranslation].some((val) => !!val);
  }

  restartLocalization = () =>
    this.setState(
      {
        localizationStarted: false,
        pendingRestart: false,
        loading: false,
      },
      () => this.startLocalization()
    );

  cancelRestart = () => this.setState({ pendingRestart: false });

  startLocalization = () => {
    this.toggleLoading();
    const { translating } = this.props;

    if (!this.state.localizationStarted) {
      this.setState({ localizationStarted: true });

      const processInfo = this.getProcesses();
      visService.translateAssets({
        data: formatProcessingData(processInfo),
        processInfo,
        type: translating ? 'translate' : 'generate',
      });
    } else {
      this.setState({ pendingRestart: true });
    }
  };

  toggleLoading = () => {
    // Ensure that react doesn't group `setState` call as one event
    this.setState({ loading: true }, () => {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 500);
    });
  };

  get autoTranslateButton () {
    const { selectedLanguages } = this.state;
    const { localizationProvisioned, translating } = this.props;
    const translateReady = !translating ? true : !this.hasTranslation || !!selectedLanguages.length;
    const processTypes = this.getProcesses().types;

    const processWillRun = processTypes
      .map((process) => [process.generate, process.translate])
      .flat()
      .some(Boolean);

    const disabled = !localizationProvisioned || !translateReady || !processWillRun;

    return (
      <NonReliantPublishButton
        disabled={disabled}
        onClick={this.startLocalization}
        content='Auto-translate'
      />
    );
  }

  get serviceUnavailable () {
    const { localizationProvisioned } = this.props;
    const warning = <WarningBanner body="We're sorry, this feature is not enabled for your account." />;
    return localizationProvisioned ? null : warning;
  }

  render () {
    const { activeProcessMessage, translating } = this.props;
    const { pendingRestart } = this.state;

    return (
      <div className='vis-process'>
        <Step.Group fluid>
          <Step ordered steps>
            <Step.Content>
              {!translating && <Step.Title className='hy-margin-bottom'>Select items to auto-translate</Step.Title>}
              <BasicSection noPadding renderedContent={this.summary} />
            </Step.Content>
          </Step>

          {translating && (
            <Step>
              <Step.Content style={{ marginBottom: '20px' }}>
                <Step.Title className='hy-margin-bottom'>Select lexile level</Step.Title>
                <Step.Description>{this.lexileLevelDropdown}</Step.Description>
              </Step.Content>
              <Step.Content>
                <Step.Title className='hy-margin-bottom'>Select translation languages</Step.Title>
                <Step.Description>{this.languageDropdown}</Step.Description>
              </Step.Content>
            </Step>
          )}

          <Step>
            <Step.Content>
              <Step.Title className='hy-margin-bottom'>Run the process</Step.Title>
              {this.autoTranslateButton}
              {activeProcessMessage}
              {this.serviceUnavailable}
            </Step.Content>
          </Step>
        </Step.Group>
        <Confirm
          header='Cancel Generation?'
          content='Do you want to cancel and start a new job with your current settings?'
          cancelButton='Continue Current Job'
          confirmButton='Start a New Job'
          open={pendingRestart}
          onConfirm={this.restartLocalization}
          onCancel={this.cancelRestart}
        />
      </div>
    );
  }
}
