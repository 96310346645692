import reactGA from 'react-ga';

const canTrack = () => {
  return process.env.REACT_APP_GOOGLE_ANALYTICS_TRACK !== 'false';
};

function initGA () {
  ReactGA.initialize(gaConfig.Config.id, {
    debug: gaConfig.Config.debug,
    titleCase: gaConfig.Config.titleCase,
    testMode: process.env.NODE_ENV === 'test',
  });
}

const gaConfig = {
  initGA: true,
  Config: {
    id: `${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`,
    debug: false,
    titleCase: false,
  },
  Analytics: {
    Exception: {
      category: 'Error',
      action: 'ErrorBoundary',
      label: 'An Error Occurred',

      fatal: 'maybe',
      description: 'An error ocurred',
    },
    Portal: {
      Save: {
        category: 'Portal',
        action: 'Click',
        label: 'Save Remote Overrides Button',
      },
      Export: {
        category: 'Portal',
        action: 'Click',
        label: 'Export Button',
      },
      Reset: {
        category: 'Portal',
        action: 'Click ',
        label: 'Reset History',
      },
      Undo: {
        category: 'Portal',
        action: 'Click ',
        label: 'Undo History',
      },
      Redo: {
        category: 'Portal',
        action: 'Click',
        label: 'Redo History',
      },
      Expand: {
        category: 'Portal',
        action: 'Click',
        label: 'Expand Button',
      },
      ImportProject: {
        category: 'Portal',
        action: 'Click',
        label: 'Import Project',
      },
      NewProject: {
        category: 'Portal',
        action: 'Click',
        label: 'New Project',
      },
      ControlBar: {
        Toggle: {
          category: 'Portal',
          action: 'Click',
          label: 'UX Control Bar / Toggle Button',
        },
        Next: {
          category: 'Portal',
          action: 'Click',
          label: 'UX Control Bar / Next Annotation Button',
        },
        Previous: {
          category: 'Portal',
          action: 'Click',
          label: 'UX Control Bar / Previous Annotation Button',
        },
      },
      Controls: {
        ColorChange: {
          category: 'Portal',
          action: 'Click',
          label: 'Controls / Color Change /',
        },
        ToggleSettings: {
          category: 'Portal',
          action: 'Click',
          label: 'Controls / Toggle /',
        },
        ThemeChange: {
          category: 'Portal',
          action: 'Click',
          label: 'Controls / Theme Change',
        },
      },
      Assets: {
        Upload: {
          StartUpload: {
            category: 'Portal',
            action: 'User Upload',
            label: 'Upload Modal / Upload Video File Start',
          },
        },
      },
      Intelligence: {
        Run: {
          category: 'Portal',
          action: 'Click',
          label: 'Intelligence / Run Video Intelligence',
        },
      },
      UX: {
        Annotation: {
          Added: {
            category: 'Portal',
            action: 'Annotation',
            label: 'Added',
          },
          Deleted: {
            category: 'Portal',
            action: 'Annotation',
            label: 'Deleted',
          },
          Created: {
            category: 'Portal',
            action: 'Click',
            label: 'Created',
          },
          Updated: {},
        },
        Intelligence: {
          Run: {
            category: 'Portal',
            action: 'Click',
            label: 'UX / Run Video Intelligence',
          },
        },
      },
      Accessibility: {}, // Todo
      Storyboard: {
        Clear: {
          category: 'Portal',
          action: 'Click',
          label: 'Cleared Storyboard',
        },
        AutoGenerate: {
          ModalOpened: {},
          Settings: {
            GenerateFrom: {
              category: 'Portal',
              action: 'Click',
              label: 'Generate From',
            },
            IncludeAnnotations: {}, // This seems trivial
          },
          Generated: {
            category: 'Portal',
            action: 'Click',
            label: 'Storyboard Generated',
          },
        },
        TakeSnapshot: {
          category: 'Portal',
          action: 'Click',
          label: 'Took Snapshot',
        },
        PDFDownloaded: {
          category: 'Portal',
          action: 'Click',
          label: 'PDF Downloaded',
        },
        HightlightInvisbleAnnotations: {},
      },
      Distribution: {
        PublishTo: {
          category: 'Portal',
          action: 'Click',
          label: 'Provider',
        },
        PDF: {},
      },
      KeyboardShortcuts: {
        Hotkey: {
          category: 'Portal',
          action: 'Hotkey',
          label: 'Hotkey used',
        },
      },
    },
  },
};

const ReactGA = {
  initialize: (...args: any[]) => {
    if (!canTrack()) return;
    // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
    reactGA.initialize(...args);
  },
  pageview: (...args: any[]) => {
    if (!canTrack()) return;
    // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
    reactGA.pageview(...args);
  },
  event: (...args: any[]) => {
    if (!canTrack()) return;
    // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
    reactGA.event(...args);
  },
  set: (...args: any[]) => {
    if (!canTrack()) return;
    // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
    reactGA.set(...args);
  },
  exception: (...args: any[]) => {
    if (!canTrack()) return;
    // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
    reactGA.exception(...args);
  },
};

export { gaConfig, ReactGA, initGA };
