import { Annotation } from '../types/annotations';
import { toPercent, toPixels } from './utils';

const ROOT = (window as any).hy.compositionInformation.COMPOSITION_TYPES.ROOT;

export const getSelectedAnnotations = (shape: any, annotations: any, zoneRefs: any) => {
  const selected = getIntersectionPointsByAnnotation(annotations, zoneRefs).filter((s: any) => {
    return checkIntersections(shape, s.points);
  });

  return selected.map(({
    id
  }: any) => id);
};

const getIntersectionPointsByAnnotation = (annotations: Annotation[], zoneRefs: any) => {
  return annotations.map(getPointsFromAnnotation.bind(this, zoneRefs));
};

const getPointsFromAnnotation = (zoneRefs: any, {
  id,
  target,
  styles
}: any) => {
  const rootStyles = styles[ROOT];
  const { top, left, width, height } = rootStyles;
  const zone = zoneRefs[target].current;
  const bounds = zone.reference.current.getBoundingClientRect();
  const x = toPixels(left, bounds.width);
  const y = toPixels(top, bounds.height);
  const w = toPixels(width, bounds.width);
  const h = toPixels(height, bounds.height);

  const points = [];
  const NUM_POINTS = 100; // number of points on all edges

  for (let _x = x; _x < x + w; _x += w / NUM_POINTS) {
    points.push({ x: _x, y });
    points.push({ x: _x, y: y + h });
  }

  for (let _y = y; _y < y + h; _y += h / NUM_POINTS) {
    points.push({ x, y: _y });
    points.push({ x: x + w, y: _y });
  }

  return {
    id,
    points,
  };
};

const checkIntersections = (shape: any, points: any) => {
  return points.some((point: any) => {
    return shape.contains(point); // paper.js "contains"
  });
};

export const getAnnotationPositionFromShape = (zone: any, shape: any) => {
  const { left, top, width, height } = shape.bounds;
  const targetBounds = zone.reference.current.getBoundingClientRect();
  return {
    left: toPercent(left, targetBounds.width),
    top: toPercent(top, targetBounds.height),
    width: toPercent(width, targetBounds.width),
    height: toPercent(height, targetBounds.height),
    baseWidth: width,
    baseHeight: height,
  };
};
