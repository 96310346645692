import React from 'react';

import './index.scss';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type CalendarProps = {
  className?: string;
  disabled: boolean;
  handleChange: (date: any) => void;
  label: string;
  startDate: any;
};

class Calendar extends React.Component<CalendarProps> {
  handleChange = (date: any) => {
    const { handleChange } = this.props;
    if (typeof handleChange === 'function') handleChange(date);
  };

  render () {
    const { startDate, label, disabled, className = '' } = this.props;

    return (
      <div className={`ui labeled input ${className}`}>
        <div className='ui label label'>{label}</div>
        <DatePicker disabled={disabled} selected={startDate} onChange={this.handleChange} />
      </div>
    );
  }
}

export { Calendar };
