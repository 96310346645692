import React, { Component } from 'react';

import { Menu, Popup } from '../../../../../hapyak-ui-toolkit';
import { stateController } from '../../../../../services/stateController';
import { toolTypeCollects } from '../../../../../services/toolRelationshipService';
import { addAnnotationWithAssociations, findAnnotationById } from '../../../../../services/toolService';
import { Annotation } from '../../../../../types/annotations';
import {
  findFirstAppliesToRelation,
  getNextItemIndex, makeInitialDefaultQuizQuestionWithAnswer
} from './quizBuilderUtils';

const DEFAULT_INDEX = 0;

type QuizBuilderMenuProps = {
  annotation: Annotation;
  annotations: Annotation[];
  offsetHeight: number;
  onItemAdded: (annotation: Annotation) => void;
  quizAnnotation: Annotation;
};

export class QuizBuilderMenu extends Component<QuizBuilderMenuProps> {
  addSection = () => {
    const { annotation = {} as Annotation, quizAnnotation = {} as Annotation } = this.props;

    if (!quizAnnotation.id) return null;

    const isQuizQuestion = annotation.toolType === 'quizQuestion';
    const appliesToRelation = findAnnotationById(findFirstAppliesToRelation(annotation));
    const associated = isQuizQuestion ? quizAnnotation : appliesToRelation;
    const targetAnnotation = isQuizQuestion ? appliesToRelation : annotation;
    const atIndex = getNextItemIndex(associated, targetAnnotation.id, 'quizSection') || DEFAULT_INDEX;

    const res = addAnnotationWithAssociations({
      appliesTo: quizAnnotation.id,
      toolType: 'quizSection',
      atIndex,
    });

    this.saveAnnotations(res.updatedAnnotations || [], res.annotation);
  };

  addQuestion = () => {
    const { annotation = {} as Annotation, annotations = [] } = this.props;
    const { toolType = '', id } = annotation;
    const isQuizQuestion = toolType === 'quizQuestion';
    const associated = findAnnotationById(findFirstAppliesToRelation(annotation));
    const targetAnnotation = isQuizQuestion ? associated : annotation;
    const atIndex = isQuizQuestion ? getNextItemIndex(associated, id, toolType) : DEFAULT_INDEX;

    const collectionToolType = toolTypeCollects(targetAnnotation.toolType)[0];

    if (!collectionToolType) return null;

    const { updatedAnnotations = [], quizQuestion } = makeInitialDefaultQuizQuestionWithAnswer(
      targetAnnotation,
      annotations,
      atIndex
    );

    this.saveAnnotations(updatedAnnotations, quizQuestion);
  };

  saveAnnotations = (annotations: Annotation[], newAnnotation: Annotation) => {
    const { onItemAdded } = this.props;

    stateController.updateProject('ux', { annotations }).then(() => {
      if (typeof onItemAdded === 'function') onItemAdded(newAnnotation);
    });
  };

  render () {
    const { annotation = {} as Annotation, offsetHeight = 0 } = this.props;

    if (!annotation.id) return null;

    return (
      <Menu
        style={{
          top: `${offsetHeight}px`,
        }}
        fluid
        vertical
        className='hy-quiz-builder-menu-slider'
      >
        <Popup
          content='Add Section'
          position='top right'
          trigger={<Menu.Item className='hy-quiz-menu-item' icon='list' onClick={this.addSection} />}
        />
        <Popup
          content='Add Question'
          position='bottom right'
          trigger={<Menu.Item className='hy-quiz-menu-item' icon='plus circle' onClick={this.addQuestion} />}
        />
      </Menu>
    );
  }
}
