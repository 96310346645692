import { comm } from './comm';
import { assetService } from './stateController';

class MediaController {
  duration: any;
  editorInstance: any;
  playTime: any;
  playing: any;
  previewInstance: any;
  // singleton used to communicate across components
  constructor () {
    this.editorInstance = null;
    this.previewInstance = null;
    this.playTime = 0;
    this.duration = 0;
    this.playing = false;

    comm.register('onTimeUpdate', this.setPlaytime);
    comm.register('onDuration', this.setDuration);
    comm.register('play', this.setPlay);
    comm.register('pause', this.setPause);
    comm.register('togglePlayPause', this.togglePlayPause);
  }

  get id () {
    // until we have the concept of playlist just grab the first (and only) video
    const onlyVideo = assetService.getVideosArray()[0];
    return onlyVideo && onlyVideo.id;
  }

  // video
  get video () {
    return assetService.getVideo(this.id);
  }

  get formats () {
    return this.video.formats || {};
  }

  get bestAvailableFormat () {
    // prefer mp4 over others
    const formats = this.formats;
    return formats.mp4 || formats.avi || formats.mov;
  }

  setPlaytime = (time: any) => {
    this.playTime = time;
  };

  setDuration = (duration: any) => {
    this.duration = duration;
  };

  setPlay = () => {
    this.playing = true;
  };

  setPause = () => {
    this.playing = false;
  };

  togglePlayPause = () => {
    comm.trigger(this.playing ? 'pause' : 'play');
  };

  setPlayerInstance = (instance: any, preview: any) => {
    if (preview) {
      this.previewInstance = instance;
    } else {
      this.editorInstance = instance;
    }
  };
}

const media = new MediaController();

export { media };
