import './scss/_global.scss';

import React from 'react';

import _ from 'lodash';
import { Redirect, Route, Switch } from 'react-router-dom';

import { EditorRoute } from 'types/utils';

import { Editor } from './components/editors';
import { AngelouLoadingSpinner } from './components/editors/AngelouComponents/AngelouLoadingSpinner';
import { InfoBanner } from './components/editors/AngelouComponents/InfoBanner';
import Editors from './components/editors/Editors';
import { Nav } from './components/Nav';
import { PageLayout } from './components/PageLayout';
import { PreviewSection } from './components/PreviewSection';
import DEFAULTS from './DEFAULTS.json';
import routes from './routes.json';
import { annotationService } from './services/annotationService';
import { comm } from './services/comm';
import logger from './services/logger';
import { media } from './services/mediaController';
import { isHomeRoute, isPublishingRoute, toPath } from './services/navUtils';
import { persistenceClientFactory } from './services/persistence/persistenceClientFactory';
import { assetService, stateController } from './services/stateController';

type State = any;

type MainEditorProps = {
  currentRoute: EditorRoute;
  debuggerService: any;
  location: Location;
  match: any;
  session: any;
};

export class MainEditor extends React.Component<MainEditorProps, State> {
  constructor (props: MainEditorProps) {
    super(props);

    this.state = {
      data: null,
      duration: 0,
      fetchedProjectData: false,
      loading: true,
      processing: {},
      shouldUpdatePath: false,
      updatesMade: 0,
    };
  }

  async componentDidMount () {
    comm.register('onDuration', this.onDuration);
    comm.register('setProcessStatus', this.setProcessStatus);
    comm.register('updateAppState', this.updateData);
    await this.getProject();
    this.removeFailedRemoteTranscodeUpload();
  }

  componentWillUnmount () {
    comm.unregister('onDuration', this.onDuration);
    comm.unregister('setProcessStatus', this.setProcessStatus);
    comm.unregister('updateAppState', this.updateData);
  }

  getProject = async () => {
    const { match } = this.props;

    try {
      const client = persistenceClientFactory.getClient();
      const project = await client.read(match);

      if (!project) {
        toPath(persistenceClientFactory.getClient().getHomePath() + '?validationError=1');
        return;
      }

      _.set(project, 'assets.bundles', {});

      await stateController.setProject(project);
      await annotationService.setNonEditableAnnotations();

      this.updateData();
      await this.getBundle();
    } catch (error) {
      console.error('getProject error:', error);
      await this.getBundle();
    }
  };

  removeFailedRemoteTranscodeUpload = () => {
    const {
      shouldUpdateRemoteTranscoding
    } = stateController.getCurrentData('project');
    if (!shouldUpdateRemoteTranscoding) {
      stateController.updateProject('processes', { [DEFAULTS.TRANSCODE_REMOTE_UPLOAD_KEY]: null });
    }
  };

  getBundle = async () => {
    try {
      const result = await persistenceClientFactory.getClient().getBundle();
      this.handleFetchedBundle(result);
    } catch (e) {
      // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
      this.handleFetchedBundle();
    }
  };

  handleFetchedBundle = (result: any) => {
    const bundle = _.get(result, 'data.body.properties', {});

    if (bundle.src || bundle.statusUrl) {
      logger.log('Found platform bundle:', bundle);
      assetService.createBundle(bundle.src, bundle);
    }

    this.setState({ fetchedProjectData: true });
  };

  updateData = (shouldUpdatePath = false) => {
    const data = stateController.getCurrentData();
    this.setState(
      {
        data,
        loading: false,
        shouldUpdatePath,
        updatesMade: this.state.updatesMade + 1,
      },
      () => this.setState({ shouldUpdatePath: false })
    );
    this.props.debuggerService.send({
      event: {
        name: this.props.debuggerService.events.PROJECT_JSON_UPDATED,
        payload: data,
      },
    });
  };

  setProcessStatus = (routes: any, status?: any) => {
    const processing = { ...this.state.processing };
    routes.forEach((route: any) => { processing[route] = status; });
    this.setState({ processing });
  };

  onDuration = (duration: any) => {
    this.setState({ duration });
  };

  getEditorComponent = (route: EditorRoute) => {
    const { data, duration, processing } = this.state;
    const { location, match } = this.props;
    const { project } = data;
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const EditorComponent = Editors[route.component] || null;

    return !EditorComponent ? (
      <div className='hy-margin'>
        <InfoBanner body="There's nothing to edit here." />
      </div>
    ) : (
      <EditorComponent
        match={match}
        project={project}
        duration={duration}
        processing={processing}
        pathname={location.pathname}
        {...data[route.dataType]}
      />
    );
  };

  get editors () {
    const { match } = this.props;
    return (routes as EditorRoute[]).map((route: EditorRoute) => {
      const path = persistenceClientFactory.getClient().getPath(match, route);

      return (
        <Route
          key={path}
          exact
          path={path}
          render={() => route.redirectTo ? <Redirect to={`${match.url}${route.redirectTo}`} /> : <Editor component={this.getEditorComponent(route)} />}
        />
      );
    });
  }

  render () {
    const { data, fetchedProjectData, loading, processing, shouldUpdatePath } = this.state;
    const { currentRoute, location, match, session } = this.props;
    const onHomeRoute = isHomeRoute(location);
    const onPublishingRoute = isPublishingRoute(location);

    if (!data || !fetchedProjectData || (!onHomeRoute && loading)) {
      return <AngelouLoadingSpinner />;
    }

    if (shouldUpdatePath && data._routeName && location.pathname !== data._routeName) {
      return <Redirect to={{ ...location, pathname: data._routeName }} />;
    }

    const formats = media.formats;
    if (!onHomeRoute && !_.keys(formats).some((k) => formats[k])) {
      return null;
    }

    return (
      <PageLayout location={location}>
        <Nav
          location={location}
          data={data}
          pathPrefix={persistenceClientFactory.getClient().getPathPrefix(match)}
          session={session}
          routeDisplay={currentRoute.name}
          processing={processing}
        />
        <div className='hy-app-area'>
          <Switch>{this.editors}</Switch>
          {onPublishingRoute ? null : <PreviewSection snapshot={currentRoute.snapshot} />}
        </div>
      </PageLayout>
    );
  }
}
