import React from 'react';

import { Table } from '../../../../hapyak-ui-toolkit';
import { assetService } from '../../../../services/stateController';
import { ImageMetadata } from '../../../../types/assets';
import { ImageAssetRow } from './ImageAssetsRow';

export class ImageAssetList extends React.Component {
  constructor (props: Record<string, unknown>) {
    super(props);
    assetService.getImagesArray().map(this.fixCorruptUrl);
  }

  fixCorruptUrl = (asset: ImageMetadata) => {
    if (asset.src && asset.src.includes('config.jsonassets')) {
      asset.src = asset.src.replace('config.jsonassets', 'assets');
      assetService.setImage(asset, true);
    }
  };

  createRow = (asset: ImageMetadata) => {
    if (asset.src) {
      return <ImageAssetRow key={asset.id} src={asset.src} />;
    }
  };

  get assets () {
    return assetService.getImagesArray().map(this.createRow);
  }

  render () {
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>Preview</Table.HeaderCell>
            <Table.HeaderCell width={12} textAlign='left'>File</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign='left'>Size</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{this.assets}</Table.Body>

        <Table.Footer fullWidth />
      </Table>
    );
  }
}
