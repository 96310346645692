import React from 'react';

import _ from 'lodash';

import { Icon } from '../../../../hapyak-ui-toolkit';
import { comm } from '../../../../services/comm';
import { getTabPreferences, setTabPreferences } from '../../../../services/saveUtils';
import './index.scss';
import { TabPaneProps, ComponentTab } from '../../../../types/layout/tabs';
import { normalizeTabName } from './utils';

type State = any;

type WrappingTabsProps = TabPaneProps & {
  button?: React.ReactNode;
  components: ComponentTab[];
  cancelTabChangeEvent?: boolean;
  deleteButton?: React.ReactNode;
  nested?: boolean;
  onTabChange?: () => Promise<boolean>;
  tabClassName?: string;
  unpersisted?: boolean;
}
export class WrappingTabs extends React.Component<WrappingTabsProps, State> {
  TAB_INDEX_KEY: any;
  mounted: any;
  updateTabCallbackKey: any;
  constructor (props: WrappingTabsProps) {
    super(props);
    const prefs = props.ignorePreferences ? {} : getTabPreferences();

    this.mounted = false;

    this.updateTabCallbackKey = props.updateTabCallbackKey || _.noop;
    this.TAB_INDEX_KEY = normalizeTabName(props.type);
    this.state = {
      tabIndex: _.get(prefs, this.TAB_INDEX_KEY, 0),
    };
  }

  componentDidMount () {
    this.mounted = true;
    comm.register(this.updateTabCallbackKey, this.chooseTab);
  }

  componentWillUnmount () {
    this.mounted = false;
    comm.unregister(this.updateTabCallbackKey, this.chooseTab);
  }

  componentDidUpdate () {
    const { forceActiveTabByIndex, tabIndex } = this.props;

    if (forceActiveTabByIndex && _.isNumber(tabIndex)) {
      this.chooseTab(tabIndex);
    }
  }

  onTabChange = () => Promise.resolve(true);

  chooseTab = (tabIndex: any) => {
    const { onTabChange = this.onTabChange } = this.props;

    onTabChange().then(() => {
      if (!this.mounted) return;

      const { cancelTabChangeEvent } = this.props;

      if (!cancelTabChangeEvent === false) return;

      this.setState({ tabIndex });
      setTabPreferences({ [this.TAB_INDEX_KEY]: tabIndex });
    });
  };

  get nameTabs () {
    const { components } = this.props;
    // TODO: consider returning null if components is null
    const { tabIndex } = this.state;
    const iconEl = (icon: any) => !icon ? null : <Icon name={icon} />;
    const componentsLength = components?.length;

    const tabs = components.map(({
      name,
      icon,
      disabled = false,
      tabStyle = {}
    }: any, i: any) => {
      let className = `hy-wrapping-tabs-name ${disabled ? 'hy-disabled' : ''}`;
      if (tabIndex === i || (!disabled && componentsLength === 1)) className += ' hy-wrapping-tabs-name-selected';

      return !icon && !name ? null : (
        <div className={className} key={name} onClick={this.chooseTab.bind(this, i)} style={tabStyle}>
          {iconEl(icon)} {name}
        </div>
      );
    });

    return tabs.filter((tab: any) => !!tab).length === 0 ? null : tabs;
  }

  get component () {
    const { components } = this.props;
    const { tabIndex } = this.state;
    return this.getComponent(components, tabIndex);
  }

  getComponent = (components: any, tabIndex: any) => {
    return components[tabIndex] || components[0];
  };

  render () {
    const { button, nested, deleteButton, className = '', tabClassName = '' } = this.props;
    const style = !this.nameTabs && !button && !deleteButton ? { padding: 0 } : {};
    const tabClass = `hy-wrapping-tabs-names ${tabClassName}`;

    return (
      <div className={`hy-wrapping-tabs ${nested ? 'hy-wrapping-tabs-nested' : ''} ${className}`}>
        <div className={tabClass} style={style}>
          {this.nameTabs}
          {button}
          {deleteButton}
        </div>
        {this.component.render()}
      </div>
    );
  }
}
