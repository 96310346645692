import React from 'react';

import { AxiosResponse } from 'axios';

import { viewGoogleDrive } from '../../services/persistence/platformService';
import { AngelouLoadingSpinner } from '../editors/AngelouComponents/AngelouLoadingSpinner';

type GoogleDriveViewHandlerProps = {
  location: Location;
}

class GoogleDriveViewHandler extends React.Component<GoogleDriveViewHandlerProps> {
  constructor (...args: any) {
    // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
    super(...args);

    const { location } = this.props;
    const { code, ...query } = Object.fromEntries(new URLSearchParams(location.search));
    const [id] = JSON.parse(query.state).ids;

    viewGoogleDrive({ id, code })
      // eslint-disable-next-line no-undef
      .then(async ({ data }: AxiosResponse) => {
        const { location } = data;
        this.openRedirect(`${location}player/index.html`);
      })
      .catch((e) => {
        console.error('error opening Google Drive file', e);
        // not sure what more to do if it fails :shrug:
      });
  }

  openRedirect (projectUrl: any) {
    window.location.assign(projectUrl);
  }

  render () {
    return <AngelouLoadingSpinner text='Opening Video' />;
  }
}

export { GoogleDriveViewHandler };
