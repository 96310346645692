import React from 'react';

import _ from 'lodash';

import './index.scss';
import { uploadArb } from '../../../services/uploadUtils';
import { AccessibilityUploadModal } from './AccessibilityUploadModal';
import { Upload } from './Upload';

export class ArbUploadModal extends AccessibilityUploadModal {
  assessFile = () => {
    const { file } = this.state;
    const reader = new FileReader();

    reader.readAsText(file, 'UTF-8');
    reader.onload = (evt) => {
      const data = _.get(evt, 'target.result');
      const jsonData = JSON.parse(data);
      const fileLocale = jsonData && jsonData['@@locale'];

      if (!fileLocale) return;
      this.setState({ fileLocale });
    };
  };

  // @ts-expect-error TS(2611): 'fileUploadComponent' is defined as a property in ... Remove this comment to see the full error message
  get fileUploadComponent () {
    return (
      <Upload
        type='arb'
        postponeUpload
        beforeUpload={this.beforeUpload}
        afterUpload={this.afterUpload}
        handleFileChange={this.handleFileChange}
        displayConfirm={false}
        confirm={this.confirmProps}
        label='Annotation text'
        filetype='.arb'
      />
    );
  }

  startUpload = () => {
    this.setState({ uploading: true });
    const { file } = this.state;
    const { projectId, type, lexileLevel = this.state.lexileLevel } = this.props;
    return uploadArb(projectId, file, type, this.selectedLanguage, { lexileLevel })
      .then(this.afterUpload)
      .catch(this.onUploadError);
  };
}
