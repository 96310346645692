import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { Bundle } from 'types/publishing';

import DEFAULTS from '../../DEFAULTS.json';
import { groupConfig } from '../configurationService';
import logger from '../logger';
import { hashBundle } from '../packagingService';
import { COLLECTION_ITEM_TYPES, createCollectionItem } from '../persistence/platformService';
import { ProcessRunner } from '../processRunner';
import { assetService, stateController } from '../stateController';
import { collectPowerWordsArbPUBLISHER, PowerWordsPublisher } from './PowerwordsService';

export class Publisher {
  bundleProps: Bundle;
  bundledImages: any;
  powerWordsPublisher: any;
  transactionData: any;
  prevent = false;

  createPackageAsset = async () => {
    const bundle = assetService.createBundle(null, {
      ...this.bundleProps,
      id: uuid(),
      hash: hashBundle(),
      images: [],
      customSrc: null, // explicitly set to null to override pre-existing this.bundleProps value
      zip: null, // explicitly set to null to override pre-existing this.bundleProps value
    });

    await this.persistBundleToPlatform(bundle);

    return bundle;
  };

  updatePackageAssetWithStatusUrl = (existingBundle: any, statusUrl: any) => {
    const bundle = { ...existingBundle, statusUrl, images: this.bundledImages };
    assetService.setBundle(bundle, true);
    return this.persistBundleToPlatform(bundle);
  };

  updatePackageAsset = (data: any) => {
    if (!data || data.error) {
      this.stop();
      logger.warn(data);
      throw new Error(`Error packaging: ${data ? JSON.stringify(data.error) : 'UNKNOWN ERROR'}`);
    }

    const { src, zip, publishingUUID, alexandriaId } = data;
    const refId = alexandriaId ? alexandriaId.split(':')[0] : '';
    stateController.updateProject('project', { alexandriaId, refId });
    const existingBundle = assetService.getBundle(publishingUUID);
    const platformIsEnabled = groupConfig.allow('platform.enabled', false);
    const customSrcOverride = !zip ? src : this.bundleProps.customSrc || src; // if no zip, reset to src
    const customSrc = !platformIsEnabled ? null : customSrcOverride;
    const bundle = this.saveBundleWithoutStatusUrl({ ...existingBundle, src, customSrc, zip });
    logger.log('Updated bundle: %o', bundle);
  };

  saveBundleWithoutStatusUrl = async (bundle: any) => {
    const withoutStatusUrl = _.omit(bundle, ['statusUrl']);
    assetService.setBundle(withoutStatusUrl, true);
    await this.persistBundleToPlatform(withoutStatusUrl);
    return withoutStatusUrl;
  };

  persistBundleToPlatform = (bundle: any) => {
    bundle.customSrc = stateController.getCurrentData().configSource;
    bundle.src = stateController.getCurrentData().landingPage;
    assetService.setBundle(bundle, true);
    if (groupConfig.allow('platform.enabled', false)) {
      return createCollectionItem(COLLECTION_ITEM_TYPES.BUNDLE, bundle)
        .then(this.afterPersistingBundle)
        .catch(this.afterPersistingBundle);
    }
  };

  afterPersistingBundle = (result: any) => {
    logger.log('Put platform bundle:', result);
  };

  constructor (bundleProps: Bundle, transactionData = {}) {
    this.transactionData = transactionData;
    this.bundleProps = bundleProps;
    this.bundledImages = [];
  }

  setBundledImages (images: any) {
    this.bundledImages = images.map((asset: any) => asset.dest);
  }

  _publish = () => {
    logger.notImplemented('_publish()', this.constructor.name);
    return Promise.resolve();
  };

  _reconnect = () => {
    logger.notImplemented('_reconnect()', this.constructor.name);
    return Promise.resolve();
  };

  _stop = () => {
    logger.notImplemented('_stop()', this.constructor.name);
    return Promise.resolve();
  };

  publish = async () => {
    this.prevent = false;

    const currentState = stateController.getCurrentData();
    this.powerWordsPublisher = new PowerWordsPublisher();
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    const { arbList } = await collectPowerWordsArbPUBLISHER(currentState);
    await this.powerWordsPublisher._publish(arbList, currentState.project.id);
    await this.powerWordsPublisher._reconnect();

    assetService.cleanBundles(); // remove existing bundles, we only manage one at a time and it comes from the /current endpoint
    const bundle = await this.createPackageAsset();

    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    const data = await this._publish(bundle.id);

    // @ts-expect-error TS(1345): An expression of type 'void' cannot be tested for ... Remove this comment to see the full error message
    if (this.prevent || !data) return Promise.resolve();
    if ((data as any).error) {
      this.stop();
      logger.warn(data);
      throw new Error(`Error packaging: ${data ? JSON.stringify((data as any).error) : 'UNKNOWN ERROR'}`);
    }

    return this.updatePackageAssetWithStatusUrl(bundle, (data as any).statusUrl);
  };

  reconnect = () => {
    logger.log('Listening for process results');
    if (this.prevent) return Promise.resolve();
    return this._reconnect().then((data) => {
      // @ts-expect-error TS(1345): An expression of type 'void' cannot be tested for ... Remove this comment to see the full error message
      if (this.prevent || !data) return Promise.resolve();

      this.updatePackageAsset(data);

      return data;
    });
  };

  stop = () => {
    this.prevent = true;
    ProcessRunner.stop(DEFAULTS.PACKAGING_KEY);
    this._stop();
  };
}
