import { Newsela } from '../../layouts/Newsela';
import { horizontalSampleVideo } from '../defaults/sampleVideos';
import { defaultAnnotations } from './defaultAnnotations';
import { preview } from './preview';

export const NewselaTemplate = {
  defaultAnnotations: defaultAnnotations,
  layout: Newsela,
  preview,
  video: horizontalSampleVideo,
  player: {
    showProgressBar: false,
    showRewindButton: true,
    showLevelButton: true,
    showLanguageButton: true,
    showClosedCaptionButton: true,
    showPlayBackRateButton: true,
    theme: 'simple',
    backgroundColor: 'rgba(0, 0, 0, 1)',
    progressBarColor: 'rgba(0, 0, 0, 1)',
    textColor: 'rgba(255, 255, 255, 1)',
  },
};
