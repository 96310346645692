import axios from 'axios';
import _ from 'lodash';

import DEFAULTS from '../DEFAULTS.json';
import logger from './logger';
import { ProcessRunner } from './processRunner';
import sessionClient from './sessionClient';
import signingService from './signingService';
import { stateController } from './stateController';
import { getRemoteStateUrl } from './stateUtils';
import { resolveAppHost } from './utils';

class PDFUtils {
  verifyDownloadable = (url: any, items: any) => {
    return axios
      .get(signingService.sign(url), {
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      })
      .then(() => {
        return stateController.updateProject('storyboard', {
          pdf: url,
          pdfString: JSON.stringify(items),
        });
      });
  };

  generate = () => {
    return new Promise((resolve, reject) => {
      const pdfUrl = `${resolveAppHost()}/storyboard/preview`;
      const querystring = '?remotestate=' + encodeURIComponent(getRemoteStateUrl());
      logger.log('remotestate url param:', querystring); // to help us debug

      sessionClient.getServiceAccessToken().then((token) => {
        const payload = {
          token,
          media: { media_file_uri: pdfUrl + querystring },
          cmds: ['pdf'],
        };

        const callback = (response: any) => {
          const pdf = _.get(response, 'pdf.data.pdfUrl');

          if (!pdf || !pdf.startsWith('http')) {
            logger.log('Error with PDF generation:', response);
            stateController.updateProject('storyboard', { pdf: null, pdfString: '' });
            reject(new Error('something bad happened'));
            return;
          }
          stateController.updateProject('storyboard', { pdf });
          resolve(pdf);
        };

        // @ts-expect-error TS(2554): Expected 4 arguments, but got 3.
        const runner = new ProcessRunner(DEFAULTS.PDF_KEY, payload, callback);
        // @ts-expect-error TS(2349): This expression is not callable.
        runner();
      });
    });
  };
}

export { PDFUtils };
