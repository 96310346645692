import hash from 'hash-sum';
import _, { unset } from 'lodash';
import { v4 as uuid } from 'uuid';

import { LAST_SAVED_MESSAGE_UPDATE_EVENT, STATUSES } from '../../components/Nav/LastSavedMessage';
import DEFAULTS from '../../DEFAULTS.json';
import { isDir } from '../../hapyak-ui-toolkit/FileBrowser/summaryHelper';
import { Annotation } from '../../types/annotations';
import { comm } from '../comm';
import logger from '../logger';
import { toPath } from '../navUtils';
import { getExportJSON } from '../stateUtils';
import { PersistenceClient } from './persistenceClient';
import {
  COLLECTION_ITEM_TYPES,
  createCollectionItem,
  createFolder,
  createProject,
  deleteCollectionItem,
  deleteFolder,
  deleteProject,
  fetchGroupContent,
  getProject,
  getProjectSummary,
  listProjects,
  putCollectionItem,
  putFolder,
  putProject
} from './platformService';

const TOTAL_RETRIES = 3;
const DEBOUNCE_TIME = 1000;

// abstraction of project crud api
export class PlatformPersistenceClient extends PersistenceClient {
  debouncedUpdate: any;
  hashes: any;
  queue: any;
  queued: any;
  retries: any;
  saving: any;
  allowFileBrowser = true;

  constructor () {
    super();
    this.queued = false;
    this.saving = false;
    this.retries = TOTAL_RETRIES;
    this.hashes = {
      annotations: {},
      project: null,
    };

    this.debouncedUpdate = _.debounce(this._performUpdate, DEBOUNCE_TIME);
  }

  // Project CRUD:
  create = (data: any) => {
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    return createProject(data);
  };

  update = () => {
    this.debouncedUpdate();
  };

  read = (match: any) => {
    const projectId = _.get(match, 'params.projectId');
    return getProject(projectId).then((data) => {
      if (data) this._setupDiffMap(data);
      return data;
    });
  };

  list = () => {
    // TODO: get most recently updated projects (limit to 5 or so)
    return listProjects().then((projects) => {
      return projects;
    });
  };

  fetchAll = () => {
    // TODO: get all projects and folders in a group
    return fetchGroupContent().then((projects) => {
      return projects;
    });
  };

  importProject = async (data: any) => {
    const id = _.get(data, 'project.id');
    await createProject(data, true);
    toPath(this.getUXPath(id));
  };

  // @ts-expect-error TS(2416): Property 'getSummary' in type 'PlatformPersistence... Remove this comment to see the full error message
  getSummary = async (id: any) => {
    return await getProjectSummary(id);
  };

  mockPlatformOperation = async () => {
    return new Promise((resolve) => {
      setTimeout(resolve, 3000);
    });
  };

  handleDelete = async (summaries: any) => {
    for (let i = 0; i < summaries.length; i++) {
      const summary = summaries[i];

      if (isDir(summary)) {
        await deleteFolder(summary.id);
      } else {
        await deleteProject(summary.id);
      }
    }
  };

  handleCopy = async (summaries: any) => {
    for (let i = 0; i < summaries.length; i++) {
      const summary = summaries[i];
      let projectId;
      if ((projectId = summary.id)) {
        const projectData = await getProject(projectId).then((data) => {
          if (data) this._setupDiffMap(data);
          return data;
        });

        if (projectData.project) {
          projectData.project.id = uuid();
          projectData.project.title += ' - Copy';
          projectData.project.alexandriaId = '';
          projectData.project.refId = '';

          // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
          await createProject(projectData);
        }

        // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
        unset(projectId);
      }
    }
  };

  handleMove = async (summaries: any, path: any) => {
    for (let i = 0; i < summaries.length; i++) {
      const summary = summaries[i];

      if (summary.path === path) return;

      if (isDir(summary)) {
        await putFolder({ ...summary, path });
      } else {
        await putProject({ project: { id: summary.id, path } }, summary.id, true);
      }
    }
  };

  handleRename = async (summary: any, name: any) => {
    if (summary.name === name) return;

    if (isDir(summary)) {
      await putFolder({ ...summary, name });
    } else {
      await putProject({ project: { id: summary.id, title: name } }, summary.id, true);
    }
  };

  // Folder CRUD:
  createFolder = (data: any) => {
    return createFolder(data);
  };

  updateFolder = (data: any) => {
    return putFolder(data);
  };

  // Utils:
  getUXPath = (projectId: string) => {
    return `${projectId}/${DEFAULTS.UX_PATH}`;
  };

  getPath = (match: any, route: any) => {
    return `${match.path}${route.path}`; // keep intermediary path
  };

  // @ts-expect-error TS(2416): Property 'getPathPrefix' in type 'PlatformPersiste... Remove this comment to see the full error message
  getPathPrefix = (match: any) => {
    return match.url;
  };

  // Internal Utils:
  _performUpdate = async () => {
    if (!this.hashes.project) {
      // Don't run until project has been read
      return;
    }

    // queue if currently saving
    if (this.saving) {
      this.queued = true;
    }

    // perform update:
    this.saving = true;
    comm.trigger(LAST_SAVED_MESSAGE_UPDATE_EVENT, this.retries < 3 ? STATUSES.RETRYING : STATUSES.SAVING);

    const data = getExportJSON();
    const { annotations, projectData } = this._removeAnnotations(data);

    try {
      await this._updateAnnotations(annotations);
      await this._updateProjectData(projectData);
      comm.trigger(LAST_SAVED_MESSAGE_UPDATE_EVENT, STATUSES.SAVED);
      this.retries = TOTAL_RETRIES;
      this._runQueuedUpdate();
    } catch (error) {
      logger.error(error);
      comm.trigger(LAST_SAVED_MESSAGE_UPDATE_EVENT, STATUSES.ERROR);
      this._retry(error);
    }
  };

  _updateProjectData = async (projectData: any) => {
    // skip if no change has been made
    const pHash = hash(projectData);
    if (pHash === this.hashes.project) {
      this.saving = false;
      this.queue = false;
      return;
    }

    // @ts-expect-error TS(2554): Expected 3 arguments, but got 1.
    await putProject(projectData);
    this.hashes.project = pHash;
  };

  _runQueuedUpdate = () => {
    this.saving = false;
    if (this.queued) {
      this.queued = false;
      this.update();
    }
  };

  _retry = (error: any) => {
    this.saving = false;

    if (this.retries > 0) {
      this.retries -= 1;
      this.update();
    } else {
      logger.error(`${TOTAL_RETRIES} failed attempts to save:`, error);
    }
  };

  _removeAnnotations = (data: any) => {
    const annotations = _.get(data, 'ux.annotations', []);
    const projectData = { ...data, ux: { ...data.ux, annotations: [] } };
    return { annotations, projectData };
  };

  _setupDiffMap = (data: any) => {
    const { annotations, projectData } = this._removeAnnotations(data);

    annotations.forEach((a: any) => {
      this.hashes.annotations[a.id] = hash(a);
    });

    this.hashes.project = hash(projectData);
  };

  _updateAnnotations = async (annotations: Annotation[]) => {
    const existingAnnotationIds = Object.keys(this.hashes.annotations);
    const TYPE = COLLECTION_ITEM_TYPES.ANNOTATION;

    const promises = [];

    for (let i = 0; i < annotations.length; i++) {
      const a = annotations[i];
      const item = { id: a.id, subType: a.toolType, value: a }; // format for platform
      const aHash = hash(a);
      const existingIndex = existingAnnotationIds.indexOf(a.id);

      if (existingIndex === -1) {
        // annotation does not exist: create a new annotation
        promises.push(createCollectionItem(TYPE, item));
        this.hashes.annotations[a.id] = aHash;
      } else {
        // annotation already exists: update it if it has changed
        if (this.hashes.annotations[a.id] !== aHash) {
          promises.push(putCollectionItem(TYPE, item));
          this.hashes.annotations[a.id] = aHash;
        }

        // then remove it from the list of deletable annotations
        existingAnnotationIds.splice(existingIndex, 1); // remove
      }
    }

    // delete annotations that are no longer present in project
    for (let i = 0; i < existingAnnotationIds.length; i++) {
      const id = existingAnnotationIds[i];
      promises.push(deleteCollectionItem(TYPE, id));
      delete this.hashes.annotations[id];
    }

    await Promise.all(promises);
  };
}
