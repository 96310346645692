import _ from 'lodash';

import { Action } from 'types/actions';

import { QUESTION_DEFAULT_DATA } from '../../components/editors/UXEditor/AnnotationEditor/QuizBuilder/questionService';
import { findAssociatedQuizAnnotation } from '../../components/editors/UXEditor/AnnotationEditor/QuizBuilder/quizBuilderUtils';
import { Annotation } from '../../types/annotations';
import { QuestionType } from '../../types/questions';
import { toHyphenated } from '../utils';
import { createQuizPostSubmitPassingCondition } from './conditionUtils';
import constants from './playerQuizConstants';
import { quizOnSpecificAnswerActionToVariations, sectionActionsToNextButtonVariations } from './quizNextButtonUtils';
import modifierUtils from './utils';
import { createQuizPassFailVariations } from './variationUtils';

const {
  deleteProperty,
  addProperty,
  deleteCollectionsProperty,
  setToolType,
  setIntentProperty,
  setLowercaseType,
  findAndReplaceQuizString,
  isNeededForFinalScreen,
  assessQuizSetup,
} = modifierUtils;

const { ANNOTATION_INTENT, getMessageIntentByType } = constants;

const formatQuestionTypeForPlayer = (type: QuestionType) => {
  const specialCaseMap = {
    trueFalse: 'boolean',
  };

  const questionTypes = Object.keys(QUESTION_DEFAULT_DATA);

  const questionTypeMap = questionTypes.reduce((map, type) => {
    return {
      ...map,
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      [type]: specialCaseMap[type] || toHyphenated(type),
    };
  }, {});

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return questionTypeMap[type];
};

class BaseAnnotationPackagingModifier {
  modifyProperties (a: any) {
    let _a = { ...a };
    _a = deleteCollectionsProperty(_a);

    if (_a.type === 'pop') {
      _a = deleteProperty(_a, 'contentType');
    }

    const type = setLowercaseType(a.type);
    return { ..._a, type };
  }

  modifyVariation (a: Annotation, annotations: Annotation[]) {
    return a;
  }

  modifyActions (a: Annotation, annotations: Annotation[]) {
    return a;
  }

  specialAssessments (a: Annotation) {
    return a;
  }

  modify (annotation: Annotation, annotations: Annotation[]) {
    let _a = { ...annotation };
    _a = this.specialAssessments(_a);
    _a = this.modifyVariation(_a, annotations);
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    _a = this.modifyActions(_a);
    _a = this.modifyProperties(_a);
    return _a;
  }

  filterSelf (annotation: Annotation, annotations: Annotation[]): boolean | undefined {
    if (annotation && annotations) {
      return true;
    }
  }
}

class QuizModifier extends BaseAnnotationPackagingModifier {
  modifyProperties (a: Annotation) {
    let _a = { ...a };
    _a = super.modifyProperties(_a);
    const { properties } = _a;

    if (!properties.allowRetries) {
      delete _a.properties.allowedRetries;
    }

    return _a;
  }

  modifyVariation (a: Annotation, annotations: Annotation[]) {
    const { variations = [] } = a;
    const { retriesEnabled, evaluateQuiz, hasFinalScreen } = assessQuizSetup(a);
    let onSpecificAnswerVariations: any[] = [];

    if (a.toolType === 'question') {
      const filteredActions = a.actions.filter((a: Action) => a.event === 'onSpecificAnswer');
      onSpecificAnswerVariations = quizOnSpecificAnswerActionToVariations(a.id, filteredActions);
    }

    if (!evaluateQuiz) {
      return {
        ...a,
        variations: onSpecificAnswerVariations,
      };
    }

    const newVariations = createQuizPassFailVariations({
      quiz: a,
      finalScreenEnabled: hasFinalScreen,
      retriesEnabled,
    });

    return {
      ...a,
      variations: [...variations, ...newVariations, ...onSpecificAnswerVariations],
    };
  }

  modifyActions (a: Annotation, annotations: Annotation[]) {
    const { evaluateQuiz } = assessQuizSetup(a);

    if (!evaluateQuiz) {
      const actions = a.actions.filter((action: Action) => action.event !== 'onSubmit');
      const onSubmitActions = a.actions.filter((action: Action) => action.event === 'onSubmit');
      const onSubmitToOnCompleteActions = onSubmitActions.map((a: any) => {
        return {
          ...a,
          event: 'onComplete',
        };
      });

      return {
        ...a,
        actions: [...actions, ...onSubmitToOnCompleteActions],
      };
    }

    return {
      ...a,
      actions: a.actions.filter((a: Action) => !['onPass', 'onFail', 'onSpecificAnswer'].includes(a.event)),
    };
  }
}

class QuizSectionModifier extends BaseAnnotationPackagingModifier {
  modifyProperties (a: any) {
    let _a = { ...a };
    _a = super.modifyProperties(_a);
    return { ..._a };
  }

  specialAssessments (a: any) {
    return { ...a, actions: [] };
  }
}

class QuizQuestionModifier extends BaseAnnotationPackagingModifier {
  modifyProperties (a: any) {
    let _a = { ...a };
    _a = super.modifyProperties(_a);
    const { properties = {} } = _a;
    const { type, gradingRule } = properties;
    const typeProp = type ? { questionType: formatQuestionTypeForPlayer(type) } : {};
    const gradingRuleProp = gradingRule ? { gradingRule: toHyphenated(gradingRule) } : {};

    return {
      ..._a,
      type: findAndReplaceQuizString(_a.type),
      properties: {
        ..._a.properties,
        ...typeProp,
        ...gradingRuleProp,
      },
    };
  }
}

class QuizAnswerModifier extends BaseAnnotationPackagingModifier {
  modifyProperties (a: any) {
    let _a = { ...a };
    _a = super.modifyProperties(_a);
    const type = findAndReplaceQuizString(_a.type);
    return { ..._a, type };
  }

  specialAssessments (a: any) {
    return a;
  }
}

class QuizNextModifier extends BaseAnnotationPackagingModifier {
  modifyProperties (a: any) {
    let _a = { ...a };
    _a = super.modifyProperties(_a);
    _a = setToolType(_a, 'pop');
    _a = setIntentProperty(_a, ANNOTATION_INTENT.NEXT);
    _a = deleteProperty(_a, 'target'); // delete `{target: 'hidden'}`
    return _a;
  }

  modifyVariation (a: Annotation, annotations: Annotation[]) {
    const { variations = [] } = a;
    const createdVariations = sectionActionsToNextButtonVariations(a, annotations);

    return {
      ...a,
      variations: [...variations, ...createdVariations],
    };
  }
}

class QuizBackModifier extends BaseAnnotationPackagingModifier {
  modifyProperties (a: any) {
    let _a = { ...a };
    _a = super.modifyProperties(_a);
    _a = setToolType(_a, 'pop');
    _a = setIntentProperty(_a, ANNOTATION_INTENT.BACK);
    _a = deleteProperty(_a, 'target'); // delete `{target: 'hidden'}`
    return _a;
  }
}

class QuizRetryModifier extends BaseAnnotationPackagingModifier {
  modifyProperties (a: any) {
    let _a = { ...a };
    _a = super.modifyProperties(_a);
    _a = setToolType(_a, 'pop');
    _a = setIntentProperty(_a, ANNOTATION_INTENT.RETRY);
    _a = deleteProperty(_a, 'target'); // delete `{target: 'hidden'}`
    return _a;
  }

  filterSelf (annotation: Annotation, annotations: Annotation[]) {
    if (annotation && annotations) {
      const { retriesEnabled } = assessQuizSetup(annotation);
      return !!retriesEnabled;
    }
  }
}

class QuizConfirmation extends BaseAnnotationPackagingModifier {
  modifyProperties (a: any) {
    let _a = { ...a };
    _a = super.modifyProperties(_a);
    _a = setToolType(_a, 'pop');
    _a = deleteProperty(_a, 'target'); // delete `{target: 'hidden'}`
    _a = setIntentProperty(_a, ANNOTATION_INTENT.CONFIRMATION);
    return _a;
  }
}

class QuizSubmitModifier extends BaseAnnotationPackagingModifier {
  modifyProperties (a: any) {
    let _a = { ...a };
    _a = super.modifyProperties(_a);
    _a = setToolType(_a, 'pop');
    _a = setIntentProperty(_a, ANNOTATION_INTENT.SUBMIT);
    _a = deleteProperty(_a, 'target'); // delete `{target: 'hidden'}`
    return _a;
  }
}

class QuizFinalMessage extends BaseAnnotationPackagingModifier {
  filterSelf (annotation: Annotation) {
    if (annotation) {
      return isNeededForFinalScreen(annotation);
    }
  }

  modifyProperties (a: any) {
    let _a = { ...a };
    const { properties = {} } = _a;
    const { type } = properties;

    const intent = getMessageIntentByType(type);

    _a = super.modifyProperties(_a);
    _a = setToolType(_a, 'pop');
    _a = setIntentProperty(_a, intent);
    _a = addProperty(_a, 'mode', 'invisible');
    return _a;
  }

  modifyVariation (a: Annotation) {
    const { variations = [] } = a;
    const { type } = a.properties;
    const { evaluateQuiz, quizAnnotation } = assessQuizSetup(a);
    const { id } = quizAnnotation;

    // If we do not evaluate the quiz, then we do not need variations
    if (!evaluateQuiz && ['quizPassMessage', 'quizFailMessage'].includes(type)) {
      return a;
    }

    const isPassing = type ? type === 'quizPassMessage' : null;
    const quizPostSubmitPassingCondition = createQuizPostSubmitPassingCondition({ quizId: id, isPassing });

    return {
      ...a,
      variations: [
        ...variations,
        {
          conditions: [quizPostSubmitPassingCondition],
          changes: {
            mode: '',
          },
        },
      ],
    };
  }
}

class QuizProgressModifier extends BaseAnnotationPackagingModifier {
  modifyProperties (a: any) {
    let _a = { ...a };
    _a = super.modifyProperties(_a);
    _a = setToolType(_a, 'progress');
    _a = setIntentProperty(_a, ANNOTATION_INTENT.PROGRESS);
    return _a;
  }

  filterSelf (annotation: Annotation, annotations: Annotation[]) {
    if (annotation && annotations) {
      const quiz = findAssociatedQuizAnnotation(annotation.id);
      return !!_.get(quiz, 'properties.showProgressBar');
    }
  }
}

export default {
  QuizModifier,
  QuizSectionModifier,
  QuizQuestionModifier,
  QuizAnswerModifier,
  QuizNextModifier,
  QuizBackModifier,
  QuizRetryModifier,
  QuizSubmitModifier,
  QuizProgressModifier,
  QuizFinalMessage,
  QuizConfirmation,
};
