import React from 'react';

import {
  addCollectionRelationshipToAnnotation,
  removeSpecifiedReferenceToAnnotation,
} from '../../../../services/toolRelationshipService';
import { getTool, updateAnnotations } from '../../../../services/toolService';
import { Annotation } from '../../../../types/annotations';
import { AnnotationList } from '../AnnotationList';
import { createQuickAddButton } from '../utils';

type State = any;

type AppliesToInputsProps = {
  annotation: Annotation;
  annotations: Annotation[];
  edit: any;
  includedIn?: any;
  section?: any;
  update: any;
  stageAnnotationUpdate: (id: string, updatedAnnotation: any) => void;
}

class AppliesToInputs extends React.Component<AppliesToInputsProps, State> {
  constructor (props: AppliesToInputsProps) {
    super(props);
    const filtered = [...props.annotations.map((a: Annotation) => ({
      ...a
    }))].filter(this.filter);
    this.state = { list: filtered.map(this.checkOnLoad), open: false };
  }

  componentDidUpdate () {
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
    this.setActiveList();
  }

  setActiveList (forceUpdate: boolean) {
    const filtered = [...this.props.annotations.map((a: Annotation) => ({
      ...a
    }))].filter(this.filter);
    const list = filtered.map(this.checkOnLoad);

    if (forceUpdate || this.state.list.length !== list.length) {
      // added or removed an item
      this.setState({ list });
    }
  }

  checkOnLoad = (a: any) => {
    const { annotation, includedIn } = this.props;
    if (includedIn && annotation.appliesTo && annotation.appliesTo.includes(a.id)) return { checked: true, ...a };
    else if (!includedIn && a.appliesTo && a.appliesTo.includes(annotation.id)) return { checked: true, ...a };
    return { ...a };
  };

  check = (id: string) => {
    const { list } = this.state;
    const annotation = list.find((a: Annotation) => a.id === id);
    annotation.checked = !annotation.checked;
    this.setState({ list }, this.applyList);
  };

  checkAll = (listSubset: any) => {
    const { list } = this.state;
    const newCheckedState = this.checkedState === -1; // if none are checked, check all, otherwise uncheck all
    list.forEach((a: Annotation) => {
      if (listSubset.includes(a)) {
        a.checked = newCheckedState;
      }
    });
    this.setState({ list }, this.applyList);
  };

  get checkedState () {
    const { list } = this.state;
    const checked = list.filter((a: Annotation) => a.checked);
    const n = checked.length;
    return n === 0 ? -1 : n === list.length ? 1 : 0; // -1 for none, 0 for some, 1 for all
  }

  get checkedStateIcon () {
    const s = this.checkedState;
    if (s === 0) return 'minus square';
    if (s === 1) return 'check square';
    return 'square outline';
  }

  filter = (_annotation: Annotation) => {
    const { annotation, includedIn } = this.props;
    let a = annotation;
    let b = _annotation;

    if (includedIn) {
      a = _annotation;
      b = annotation;
    }

    const collectionTypes = getTool(a.toolType).editor.collectionTypes;
    return collectionTypes && collectionTypes.includes(b.toolType === 'section' ? 'chapter' : b.toolType);
  };

  applyList = () => {
    const { annotation, includedIn } = this.props;
    const { list } = this.state;

    list.forEach(async (a: Annotation) => {
      const a1 = includedIn ? a.id : annotation.id;
      const a2 = includedIn ? annotation.id : a.id;

      const updatedAnnotations = a.checked
        ? addCollectionRelationshipToAnnotation(a1, a2)
        : removeSpecifiedReferenceToAnnotation(a2, a1);

      await updateAnnotations(updatedAnnotations);
    });
  };

  get quickAddButton () {
    const { annotation } = this.props;
    // @ts-expect-error TS(2554): Expected 3 arguments, but got 1.
    return createQuickAddButton(annotation);
  }

  filterCurrentSection (list: any) {
    if (this.props.annotation.toolType !== 'section') {
      return list;
    } else {
      const result = list.filter((a: any) => a.id !== this.props.annotation.id);
      return result;
    }
  }

  updateAnnotation = async (id: any, changes: any) => {
    const { update } = this.props;
    await update(id, changes);
    this.setActiveList(true);
  };

  render () {
    const { edit, includedIn, section, annotation } = this.props;
    const { list } = this.state;
    const isChapterMenu = annotation.toolType === 'chapterMenu';
    const filteredAnnotations = this.filterCurrentSection(list);

    return (
      <AnnotationList
        edit={edit}
        minimal
        section={section}
        category={includedIn ? 'menus' : 'annotations'}
        quickAddButton={this.quickAddButton}
        annotations={filteredAnnotations}
        visibleAnnotations={list}
        check={this.check}
        checkAll={this.checkAll}
        checkAllIcon={this.checkedStateIcon}
        checkable
        update={this.updateAnnotation}
        isChapterMenu={isChapterMenu}
      />
    );
  }
}

export { AppliesToInputs };
