import axios from 'axios';
import hash from 'hash-sum';
import _ from 'lodash';

import DEFAULTS from '../DEFAULTS.json';
import { media } from './mediaController';
import { getSchemaDefaults, validateAndMigrate } from './persistence/platformService';
import { modifyForExport } from './saveUtils';
import signingService from './signingService';
import { assetService, stateController } from './stateController';
import { downloadToComputer } from './utils';
import { prepareConfigFromPlayer } from './videoConfig';

const SAVE_JSON_ENDPOINT = `https://${process.env.REACT_APP_PLATFORM_HOST}/back-end/content/save-json`;
const params = new URLSearchParams(window.location.search.replace('?', ''));
let remoteStateUrl: any = null;

export const hasRemoteState = params.has('remotestate');

export const retrieveRemoteJSON = () => {
  if (hasRemoteState) {
    // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    return fetch(params.get('remotestate')).then((res) => res.json());
  }

  return Promise.resolve({});
};

const getSimplifiedStateForPDF = async () => {
  const data = { ...stateController.getCurrentData() };
  const projectDefaults = await getSchemaDefaults();
  const images = {};
  const imagesArray = data.storyboard.items.map((item: any) => assetService.getImage(item.image));

  imagesArray.forEach((image: any) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    images[image.id] = image;
  });

  return modifyForExport(
    JSON.stringify({
      ...projectDefaults,
      project: data.project,
      assets: {
        ...projectDefaults.assets,
        videos: data.assets.videos,
        images,
      },
      storyboard: {
        ...data.storyboard,
        pdfString: '',
        pdf: null,
      },
    })
  );
};

export const saveRemoteState = async () => {
  const pdfState = await getSimplifiedStateForPDF();
  return axios(formatSaveConfigRequest('pdf-state.json', true, pdfState)).then(({ data }) => {
    remoteStateUrl = data;
    return remoteStateUrl;
  });
};

const formatSaveConfigRequest = (name: any, temporary: any, config: any) => {
  const { id } = stateController.getCurrentData('project');

  return {
    url: SAVE_JSON_ENDPOINT,
    method: 'post',
    withCredentials: true,
    data: {
      append: true,
      csrfToken: window.localStorage.getItem(DEFAULTS.CSRF_KEY),
      json: JSON.stringify(config),
      name,
      projectId: id,
      temporary,
    },
  };
};

export const saveJSON = (name: any, json: any, temporary = false) => {
  return axios(formatSaveConfigRequest(name, temporary, json));
};

export const savePlayerConfigOverrides = () => {
  const { overridesHash } = stateController.getCurrentData('project');

  if (!media.previewInstance || overridesMatch(overridesHash)) {
    return Promise.resolve();
  }

  return axios(formatSaveConfigRequest('overrides.json', true, prepareConfigFromPlayer())).then((response) => {
    return stateController.updateProject('project', {
      overridesUrl: response.data,
      overridesHash: hashOverrides(),
    });
  });
};

export const hashOverrides = () => {
  const overrides = prepareConfigFromPlayer();
  return hash(overrides);
};

export const overridesMatch = (hash: any) => {
  return hash === hashOverrides();
};

export const getExportJSON = () => {
  return modifyForExport(stateController.getCurrentDataAsJSON());
};

export const exportJSON = () => {
  const data = getExportJSON();
  const url = 'data:application/json;charset=utf-8;,' + encodeURIComponent(JSON.stringify(data));
  downloadToComputer(url, (data.project.title || 'HapYak Project') + '.json');
};

export const getRemoteOverridesUrl = () => {
  return signingService.sign(stateController.getCurrentData('project').overridesUrl);
};

export const getRemoteStateUrl = () => {
  return signingService.sign(remoteStateUrl);
};

// TODO: enforce group match
export const attemptImport = async (data: any) => {
  if (!data) return null;
  const importedData = await validateAndMigrate(data);
  if (!hasVideo(importedData)) return null;
  return importedData;
};

// refactor when support is added for multiple videos
const hasVideo = (data: any) => {
  if (!data) return null;
  const videos = _.get(data, 'assets.videos');
  const videosArray = Object.keys(videos).map((id) => videos[id]);
  return !!videosArray.length;
};
