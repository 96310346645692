import React from 'react';

import { Checkbox } from '@newsela/angelou';

import './index.scss';
import { Form, Grid, Header, TextArea, TinyMCEEditor } from '../../../hapyak-ui-toolkit';
import { stateController } from '../../../services/stateController';
import { isRegex } from '../../../services/utils';
import { VideoTypeOption } from '../../../types/publishing';
import ErrorBanner from '../AngelouComponents/ErrorBanner';
import { DateInput } from './DateInput';
import { PackagingOptions, PackagingOptionsProps } from './PackagingOptions';

export type RestrictionOptionsProps = PackagingOptionsProps & {
  videoTypeOptions: VideoTypeOption[]
}

export class RestrictionOptions extends PackagingOptions<RestrictionOptionsProps> {
  handleWhitelistChange = (e: any, {
    value
  }: any) => {
    const whitelist = value.length === 0 ? [] : value.split(/\r?\n/);
    this.updateValue('whitelist', whitelist);
  };

  get bundleProps () {
    const { bundleProps } = this.props;
    return bundleProps;
  }

  get whitelistString () {
    return this.bundleProps.whitelist?.join('\n');
  }

  get whitelistInvalid () {
    return this.bundleProps.whitelist?.some((item: any) => !isRegex(item));
  }

  get whitelistInvalidMessage () {
    if (!this.whitelistInvalid) return null;

    return (
      <ErrorBanner
        title='Regex error'
        body='One or more lines contain an invalid regex. If any lines contain an invalid regular expression your video will not play'
        validationError
      />
    );
  }

  get expirationDate () {
    return this.bundleProps.expirationDate;
  }

  handleDateChange = ({
    date
  }: any) => {
    this.updateValue('expirationDate', date).then(this.saveChanges);
  };

  handleEditorChange = (content: any) => {
    this.handleChange('playerNotAuthorizedMessaging', null, { value: content });
    this.handleChange('unauthorizedMessageFormat', null, { value: 'richtext' });
  };

  get restrictionMessageInputElement () {
    const { playerNotAuthorizedMessaging } = this.bundleProps;

    return (
      <TinyMCEEditor
        // @ts-expect-error TS(2769): No overload matches this call.
        disabled={!this.restrictionsEnabled}
        value={playerNotAuthorizedMessaging}
        onEditorChange={this.handleEditorChange}
        onBlur={this.saveChanges}
        editorConfigType='advanced'
        configOptions={{ height: '200' }}
      />
    );
  }

  get restrictionExpirationInputElement () {
    return (
      <DateInput
        disabled={!this.restrictionsEnabled}
        label='Expiration Date'
        startDate={this.expirationDate}
        handleChange={this.handleDateChange}
      />
    );
  }

  get restrictionWhitelist () {
    return (
      <div>
        <Header>Domain Whitelist</Header>
        <Form>
          <TextArea
            disabled={!this.restrictionsEnabled}
            rows={4}
            value={this.whitelistString}
            onChange={this.handleWhitelistChange}
            onBlur={this.saveChanges}
            placeholder='One entry per line. Supports absolute URLs or pattern matching (regular expression)'
          />
        </Form>
        {this.whitelistInvalidMessage}
      </div>
    );
  }

  resetRestrictionValues = () => {
    this.handleWhitelistChange(null, { value: [] });
    this.handleChange('playerNotAuthorizedMessaging', null, { value: '' });
    this.handleChange('unauthorizedMessageFormat', null, { value: 'richtext' });
    this.updateValue('expirationDate', '').then(this.saveChanges);
  };

  handleRestrictionToggle = ({
    key,
    checked,
    e
  }: any) => {
    checked = !checked;
    this.handleToggle(key, e, { checked }).then(() => {
      if (checked === false) this.resetRestrictionValues();
    });
  };

  get restrictionsEnabled () {
    const { restrictionsEnabled } = stateController.getCurrentData('publishing');
    return restrictionsEnabled;
  }

  render () {
    return (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Grid.Row className='hy-margin-bottom'>
              <Checkbox
                label='Enable Restrictions'
                checked={this.restrictionsEnabled}
                onChange={this.handleRestrictionToggle.bind(this, { key: 'restrictionsEnabled', checked: this.restrictionsEnabled, e: false })}
              />
            </Grid.Row>
            <Grid.Row className='hy-margin-bottom'>{this.restrictionExpirationInputElement}</Grid.Row>
            <Grid.Row>{this.restrictionWhitelist}</Grid.Row>
          </Grid.Column>
          <Grid.Column>
            <Grid.Row>
              <Header>Restriction Message</Header>
              {this.restrictionMessageInputElement}
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
