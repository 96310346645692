import React from 'react';

import './index.scss';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { SketchPicker } from 'react-color';
import { Input } from 'semantic-ui-react';

import { StyleInputProps } from '../../../types/layout/forms';

type State = any;

class ColorPicker extends React.Component<StyleInputProps, State> {
  constructor (props: StyleInputProps) {
    super(props);
    this.state = {
      active: false,
    };
  }

  handleClick = (e: any) => {
    this.setState({ active: !this.state.active });
  };

  handleClose = () => {
    this.setState({ active: false });
  };

  handleChangeComplete = ({
    rgb
  }: any) => {
    const { onChangeComplete } = this.props;
    if (typeof onChangeComplete === 'function') onChangeComplete(`rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`);
  };

  handleChange = ({
    rgb
  }: any) => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') onChange(`rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`);
  };

  render () {
    const { label, color } = this.props;
    const { active } = this.state;

    const swatch = (
      <div className='hy-swatch-wrapper' onClick={this.handleClick}>
        <Input
          label={{ basic: true, content: label }}
          action={<div className='hy-color' style={{ backgroundColor: color }} />}
        />
      </div>
    );

    const picker = (
      <div className='hy-popover'>
        <div className='hy-cover' onClick={this.handleClose} />
        <SketchPicker color={this.props.color} onChangeComplete={this.handleChangeComplete} onChange={this.handleChange} />
      </div>
    );

    return (
      <div className='hy-color-picker'>
        {swatch}
        {active ? picker : null}
      </div>
    );
  }
}

export { ColorPicker };
