import React from 'react';

import DEFAULTS from '../../../DEFAULTS.json';
import { getUpdateTabCallbackKey } from '../../../services/utils';
import { AssetsEditor } from '../AssetsEditor';
import { WrappingTabs } from '../CommonComponents/WrappingTabs';
import { ProjectEditor, ProjectEditorProps } from '../ProjectEditor';

class SetupEditor extends React.Component {
  locationKey = DEFAULTS.SETUP_TABS_KEY;

  get tabs () {
    return [
      {
        name: 'PROJECT',
        render: () => <ProjectEditor {...this.props as ProjectEditorProps} />,
      },
      {
        name: 'ASSETS',
        render: () => <AssetsEditor {...this.props} />,
      },
    ];
  }

  render () {
    return (
      <WrappingTabs
        className='hy-wrapping-tabs-light hy-scrollable'
        components={this.tabs}
        type={this.locationKey}
        updateTabCallbackKey={getUpdateTabCallbackKey(this.locationKey)}
      />
    );
  }
}

export { SetupEditor };
