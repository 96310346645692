import React from 'react';

import { v4 as uuid } from 'uuid';

import { Button, Dropdown, Menu } from '../../../../hapyak-ui-toolkit';
import { comm } from '../../../../services/comm';
import { stopEvent, toOption } from '../../../../services/utils';
import { Annotation } from '../../../../types/annotations';
import { ActionInputs } from '../ActionsEditor/ActionInputs';

const ALLOWED_TOOL_TYPES = ['hotlink', 'button', 'note'];
const HOTLINK_ONLY = ['hotlink'];

type State = any;

type SelectReleaseModalProps = {
  annotations: Annotation[];
  createAnnotation: any;
  duration: any;
  selection: any;
};

export class SelectReleaseModal extends React.Component<SelectReleaseModalProps, State> {
  constructor (props: SelectReleaseModalProps) {
    super(props);
    this.state = {
      actionData: null,
      minimalView: false,
      toolType: 'hotlink',
    };
  }

  componentDidMount () {
    comm.register('escape', this.close);
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  componentWillUnmount () {
    comm.unregister('escape', this.close);
  }

  resize () {
    this.setState({ minimalView: window.innerWidth <= 1000 });
  }

  setToolType = (e: any, {
    value
  }: any) => {
    this.setState({ toolType: value });
  };

  stageNewAnnotation = () => {
    const { actionData, toolType } = this.state;
    const { createAnnotation, selection } = this.props;
    const { annotationProps } = selection;

    comm.trigger('selectAnnotations', []);
    createAnnotation(toolType, 'player', {
      actions: [{ id: uuid(), ...actionData }],
      ...annotationProps,
    }).then(this.close);
  };

  close = () => {
    const { selection } = this.props;
    selection.shape.removeSegments();
    comm.trigger('stageSelection', null);
  };

  get style () {
    const { selection } = this.props;
    const { modalPosition } = selection;

    return {
      position: 'fixed',
      zIndex: 1,
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
      ...modalPosition,
    };
  }

  get containerStyle () {
    return {
      position: 'fixed',
      zIndex: 3,
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    };
  }

  updateHotlinkData = (actionData: any) => {
    this.setState({ actionData: { ...this.state.actionData, ...actionData } });
  };

  render () {
    const { annotations, duration, selection } = this.props;
    const { toolType, minimalView } = this.state;
    const options = selection.selectionTool === 'rectangle' ? ALLOWED_TOOL_TYPES : HOTLINK_ONLY;

    return (
    // @ts-expect-error TS(2322): Type '{ position: string; zIndex: number; width: s... Remove this comment to see the full error message
      <div onClick={this.close} style={this.containerStyle}>
        <Menu vertical style={this.style} size='mini' compact>
          <div className={`hy-action-inputs-quick-edit ${minimalView ? 'minimal' : ''}`} onClick={stopEvent}>
            <Dropdown
              selection
              fluid
              label='Type'
              value={toolType}
              options={options.map(toOption)}
              disabled={options.length === 1}
              onChange={this.setToolType}
            />
            <ActionInputs
              minimal
              update={this.updateHotlinkData}
              duration={duration}
              annotation={annotations[0]}
              annotations={annotations}
            />
            <Button fluid size='mini' primary onClick={this.stageNewAnnotation}>
              Create
            </Button>
          </div>
        </Menu>
      </div>
    );
  }
}
