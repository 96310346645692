import React from 'react';

import { Checkbox } from '@newsela/angelou';

import { PackagingOptions, PackagingOptionsProps } from './PackagingOptions';

export type DownloadableToggleProps = PackagingOptionsProps & {
  forceTrue: boolean;
};

export class DownloadToggle extends PackagingOptions<DownloadableToggleProps> {
  render () {
    const { forceTrue } = this.props;
    const { downloadable } = this.bundleProps;

    return (
      <Checkbox
        label='Create Downloadable Package'
        checked={downloadable}
        disabled={forceTrue}
        onChange={this.handleUpdate.bind(this, { key: 'downloadable', checked: downloadable })}
      />
    );
  }
}
