import './GradeBandRadioButtons.scss';

import React, { useEffect, useState } from 'react';

import { RadioButton } from '@newsela/angelou';

import { GradeBand } from 'types/gradeBand';

type GradeBandRadioButtonsProps = {
  handleGradeRadioChange: (grade: GradeBand) => void;
  gradeBand?: GradeBand | '';
  gradeBandRadioDisabled: boolean;
};

type GradLevelArray = {
  label: string;
  value: GradeBand;
}[]

export const GradeBandRadioButtons = ({
  handleGradeRadioChange,
  gradeBand,
  gradeBandRadioDisabled
}:GradeBandRadioButtonsProps) => {
  const [checkedValue, setCheckedValue] = useState(gradeBand);

  useEffect(() => {
    setCheckedValue(gradeBand);
  });

  const gradeBandArray: GradLevelArray = [
    { label: 'K', value: 'GRADE_K' },
    { label: '1', value: 'GRADE_1' },
    { label: '2', value: 'GRADE_2' },
    { label: '3', value: 'GRADE_3' },
    { label: '4', value: 'GRADE_4' },
    { label: '5', value: 'GRADE_5' },
    { label: '6', value: 'GRADE_6' },
    { label: '7', value: 'GRADE_7' },
    { label: '8', value: 'GRADE_8' },
    { label: '9', value: 'GRADE_9' },
    { label: '10', value: 'GRADE_10' },
    { label: '11', value: 'GRADE_11' },
    { label: '12', value: 'GRADE_12' }
  ];

  return (
    <section id='grade-band-radio-button-section'>
      <div className={'internal-tools-form-label ' + (gradeBandRadioDisabled ? 'disabled' : '')}>
        Grade level
      </div>
      <div className='grade-band-radio-buttons-container'>
        {gradeBandArray.map((grade) => (
          <RadioButton
            disabled={gradeBandRadioDisabled}
            name='grade'
            key={grade.label}
            value={grade.value}
            label={grade.label}
            defaultChecked={checkedValue === grade.value}
            onClick={() => {
              handleGradeRadioChange(grade.value);
              setCheckedValue(grade.value !== checkedValue ? grade.value : '');
            }}
            __classNameFor={{
              root: grade.value === checkedValue ? 'grade-band-checked' : '',
              labelText: grade.value === checkedValue ? 'grade-band-checked' : '',
            }}
            __cssFor={{
              root: {
                backgroundColor: '#eaebec',
                border: '1px solid #1d1d1d;',
                borderRadius: '25px',
                height: '36px',
                width: '36px',
                margin: '3px',
              },
              labelText: {
                margin: '0 auto'
              },
              input: {
                '+ span': {
                  display: 'none'
                },
              }
            }}
          />
        ))}
      </div>
    </section>
  );
};
