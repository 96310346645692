import presets from '../../../src/tools/SmartCaptions/Presets/defaultStyle.json';

// Second instance of `smartCaptions` will be retargeted to an external panel during publishing
export const defaultAnnotations = [
  {
    type: 'smartCaptions',
    overrides: {
      start: 0,
      end: '100%',
      target: 'player',
      styles: presets,
    },
  },
  {
    type: 'smartCaptions',
    overrides: {
      start: 0,
      end: '100%',
      target: 'adjacent_bottom',
      styles: {
        ...presets,
        'compositionTypes.root': {
          fontSize: '23px',
        },
      },
    },
  },
  {
    type: 'progressIndicator',
    overrides: {
      start: 0,
      end: '100%',
      content: '',
      target: 'player',
      appliesTo: 2000,
      properties: {},
      styles: { 'compositionTypes.root': {} },
      actions: [],
    },
  },
  {
    type: 'question',
    overrides: {
      content: '[Quiz question 1]',
      internal: { name: 'Quiz question 1' },
      start: 0,
      end: '100%'
    },
  },
  {
    type: 'question',
    overrides: {
      content: '[Quiz question 2]',
      internal: { name: 'Quiz question 2' },
      start: 25,
      end: '100%'
    },
  },
  {
    type: 'question',
    overrides: {
      content: '[Quiz question 3]',
      internal: { name: 'Quiz question 3' },
      start: 50,
      end: '100%'
    },
  },
  {
    type: 'question',
    overrides: {
      content: '[Quiz question 4]',
      internal: { name: 'Quiz question 4' },
      start: 75,
      end: '100%'
    },
  },
  {
    type: 'powerWords',
    overrides: {
      content: '',
      target: 'hidden',
    },
  },
];
