import React from 'react';

import { Badge, typographyStyles } from '@newsela/angelou';

import { findAnnotationById } from '../../../../../services/toolService';
import { Annotation } from '../../../../../types/annotations';
import { QuestionType } from '../../../../../types/questions';
import { convertOptionNumberToAlphabeticChar } from './questionService';
import { getQuizAnswerRowDisplay } from './quizBuilderUtils';
import './ReadOnlyAnswerList.scss';

type ReadOnlyAnswerListProps = {
  annotations: Annotation[];
  answerIds: string[];
  questionType: QuestionType;
};

export const ReadOnlyAnswerList = ({
  annotations,
  answerIds,
  questionType
}: ReadOnlyAnswerListProps) => {
  const targetNotFound = (a: Annotation): boolean => {
    const { content: aAsTargetId } = a;
    if (!aAsTargetId) return false;
    return !findAnnotationById(aAsTargetId).id;
  };

  const createOptionPreview = (a: Annotation) => {
    const notFound = targetNotFound(a);
    const makeWarning = (text: string) => <div style={{ color: 'red' }}>{text}</div>;
    const makePreview = (id: string) => getQuizAnswerRowDisplay(findAnnotationById(id));
    const preview = !notFound && a && a.content ? makePreview(a.content) : makeWarning('Missing Content');
    return notFound ? makeWarning('Annotation Not Found') : preview;
  };

  const correctAnswerBadge = () => {
    return (
      <Badge
        color={Badge.color.green}
        flavor={Badge.flavor.solid}
        hideIcon
        size={Badge.size.md}
        text='Correct'
        __cssFor={{
          root: {
            '&& *': typographyStyles.heading5Obj
          }
        }}
      />
    );
  };

  const answerRows = () => {
    const answers = answerIds.map((id: string) => annotations.find((a: Annotation) => a.id === id)).filter(Boolean);

    return answers.map((a?: Annotation, idx?: number) => {
      const content = a && questionType === 'annotations' ? createOptionPreview(a) : a?.content;
      const isCorrectAnswer = a && a.properties && a.properties.correct;
      const hasMultipleOptions = ['multipleChoice', 'checkbox'].includes(questionType);

      const ALPHABET_LENGTH = 26;
      const Z_INDEX = 25;
      const optionNumber = (idx || 0) < ALPHABET_LENGTH ? (idx || 0) : Z_INDEX;

      return (
        <div aria-label='read-only-answer-row' className={isCorrectAnswer ? 'read-only-answer-row correct-answer-row' : 'read-only-answer-row'} key={a?.id}>
          <div className='read-only-answer-row-first-column'>
            {
              hasMultipleOptions
                ? convertOptionNumberToAlphabeticChar(optionNumber)
                : questionType === 'linearScale'
                  ? idx === 0 ? 'MIN' : 'MAX'
                  : null
            }
          </div>
          <div className='read-only-answer-row-second-column'>
            <div>{content}</div>
            <div>{isCorrectAnswer && correctAnswerBadge()}</div>
          </div>
        </div>
      );
    });
  };

  return <div>{answerRows()}</div>;
};
