import _ from 'lodash';

import { getCollections } from '../../../../services/toolRelationshipService';
import { findAnnotationById, TOOLS } from '../../../../services/toolService';
import { Annotation } from '../../../../types/annotations';
import {
  findAssociatedQuizAnnotation,
  findAssociatedQuizAnnotationIds,
} from '../../UXEditor/AnnotationEditor/QuizBuilder/quizBuilderUtils';

const INCLUDE_SELF = 1;
// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
const getToolTypeByName = (name: string) => TOOLS?.[name]?.defaults?.type;
const menus = [getToolTypeByName('chapterMenu'), getToolTypeByName('contentExplorer')];
const isCollectible = (a: Annotation) => [(TOOLS as any).quiz.defaults.type, ...menus].includes(a.type);
const isQuiz = (a: Annotation) => a.type === 'quiz';
const assessRowSpanLength = (a: Annotation) => {
  return isQuiz(a) ? findAssociatedQuizAnnotationIds(a).length : getCollections(a)?.length + INCLUDE_SELF;
};

const findParentStartTime = ({
  annotation,
  defaultStart
}: any) => {
  const parentId = annotation?.appliesTo?.[0];
  const parentStartTime = parentId && findAnnotationById(parentId)?.start;
  return _.isNumber(parentStartTime) ? parentStartTime : defaultStart;
};

const getUIToolTypeIcon = (a: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const toolInfo = TOOLS[a.toolType];
  return a.type === 'bookmark' || toolInfo?.editor?.editingDisabled ? null : toolInfo?.icon;
};

const getRowSpanData = (a: any) => {
  const annotationIsCollectible = isCollectible(a);
  return annotationIsCollectible ? { rowSpan: assessRowSpanLength(a) } : {};
};

const getEditingDisabledData = (a: any) => {
  return isQuiz(a) ? { editingDisabled: true } : {};
};

const getPlaceholderData = (a: any) => {
  return isQuiz(a) ? { placeholder: a?.internal?.name } : {};
};

const getTableRowAttributes = (a: any) => {
  const icon = getUIToolTypeIcon(a);
  const rowSpan = getRowSpanData(a);
  const editingDisabled = getEditingDisabledData(a);
  const placeholder = getPlaceholderData(a);
  const { type } = a;
  return { icon, rowSpan, editingDisabled, placeholder, type };
};

const getTimingData = (a: any, annotationArbData: any) => {
  const start =
        annotationArbData.start === null ? findAssociatedQuizAnnotation(a.id)?.start : annotationArbData.start;
  const evaluatedStart = a.type === 'bookmark' ? findParentStartTime({ annotation: a, defaultStart: start }) : null;
  const evaluatedTime = _.isNumber(evaluatedStart) ? evaluatedStart : start;
  const startTimes = _.isNumber(start) ? [start] : [];
  return { evaluatedTime, startTimes };
};

export const sculptAnnotationForTableDisplay = (annotationArbData: any) => {
  const { identifier, text, translatedText } = annotationArbData;

  const annotation = findAnnotationById(identifier);
  const { icon, rowSpan, editingDisabled, placeholder, type } = getTableRowAttributes(annotation);
  const { evaluatedTime, startTimes } = getTimingData(annotation, annotationArbData);

  return {
    ...placeholder,
    ...editingDisabled,
    ...rowSpan,
    type,
    evaluatedTime,
    identifier,
    text,
    translatedText,
    icon,
    ids: [identifier],
    startTimes,
  };
};

const annotationSortMap = {
  contents: 1,
  bookmark: 2,
  default: 3,
};

// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
const compareWith = (type: any) => annotationSortMap[type] ? annotationSortMap[type] : annotationSortMap.default;

export const sortByAnnotationType = (a: any, b: any) => compareWith(a.type) - compareWith(b.type);

export const sortByEvaluatedTime = (a: any, b: any) => a.evaluatedTime - b.evaluatedTime;
