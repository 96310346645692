import React from 'react';

import '../index.scss';

import { Popup, Table, Icon, CustomTextArea } from '../../../../hapyak-ui-toolkit';
import { comm } from '../../../../services/comm';
import { media } from '../../../../services/mediaController';
import { getActiveVideoProcesses } from '../../../../services/processRunner';
import { assetService } from '../../../../services/stateController';
import hms from '../../../../services/time';
import { stopEvent } from '../../../../services/utils';
import { horizontalSampleVideo, verticalSampleVideo } from '../../../../templates/defaults/sampleVideos';
import { EditButton } from '../../AngelouComponents/EditButton';
import { RunningStatus } from '../../CommonComponents/RunningStatus';

type VideoAnnotationRowProps = {
  processing: any;
  error: boolean;
}

export class VideoAnnotationRow extends React.Component<VideoAnnotationRowProps> {
  get isSampleVideo () {
    const { formats } = media;
    const sampleVideoSources = [horizontalSampleVideo, verticalSampleVideo]
      .map((item) => item.formats)
      .map((item) => [item.mp4])
      .flat();

    return sampleVideoSources.some((src) => formats.mp4 && formats.mp4.includes(src));
  }

  persistName = (name: any) => {
    assetService.setVideo({ ...media.video, name });
  };

  edit = () => {
    comm.trigger('editVideo', media.video);
  };

  get content () {
    const { processing } = this.props;
    const activeProcesses = getActiveVideoProcesses(processing);

    if (!activeProcesses.length) return null;

    return <RunningStatus inline size='mini' content={activeProcesses[0]} />;
  }

  handleMouseOver = () => {
    comm.trigger('setActiveAnnotation', null);
  };

  render () {
    const video = media.video;
    const { duration } = video;
    const displayedDuration = hms.secondsToHMS(duration / 1000); // ms
    const { error } = this.props;

    const background = error ? '#ff9595' : this.isSampleVideo ? '#faff00' : '';

    return (
      <Popup
        trigger={
          <Table.Row
            className='hy-video-row'
            onMouseOver={this.handleMouseOver}
            onDoubleClick={this.edit}
            style={{ background }}
          >
            <Table.Cell collapsing textAlign='center'>
              <Icon fitted name='video' />
            </Table.Cell>
            <Table.Cell className='hy-secondary-cell' textAlign='left'>
              <CustomTextArea
                onBlur={this.persistName}
                onDoubleClick={stopEvent}
                onMouseLeave={stopEvent}
                placeholder='Internal name'
                value={media.video.name}
              />
            </Table.Cell>
            <Table.Cell collapsing>NA</Table.Cell>
            <Table.Cell collapsing>
              <div className='hy-video-duration'>{displayedDuration}</div>
            </Table.Cell>
            <Table.Cell collapsing textAlign='left'>
              <div className={this.content ? 'annotation-list-cell-transcoding' : 'annotation-list-cell-center'}>
                {this.content}
                <EditButton handleClick={this.edit} />
              </div>
            </Table.Cell>
          </Table.Row>
        }
        content='Edit video source'
        offset={[0, 0]}
        size='mini'
        position='top right'
        inverted
      />
    );
  }
}
